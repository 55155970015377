import gql from "graphql-tag";

const UPDATE_GEOFENCE_MUTATION = gql`
  mutation UpdateGeofence(
    $id: Int!
    $name: String
    $customerId: Int
    $showOnMap: Boolean
    $maxSpeed: Int
    $alertsInputOutput: Boolean
    $geom: String
    $geofenceType: GeofenceTypeEnum
  ) {
    updateGeofence(
      id: $id
      name: $name
      customerId: $customerId
      showOnMap: $showOnMap
      maxSpeed: $maxSpeed
      alertsInputOutput: $alertsInputOutput
      geom: $geom
      geofenceType: $geofenceType
    ) {
      id
    }
  }
`;

export default UPDATE_GEOFENCE_MUTATION;
