import React, { useState } from "react";
import { Button, FormGroup, Grid } from "@material-ui/core";
import { useQuery } from "@apollo/client";
import * as Yup from "yup";
import { format } from "date-fns";
import { Field, Formik } from "formik";

import CustomFormikSelect from "../../../components/Base/CustomFormikSelect";
import CUSTOMERS_QUERY from "../../../graphql/queries/getCustomersQuery";
import COMMTYPE_QUERY from "../../../graphql/queries/getCommTypesQuery";
import GET_DEVICES_QUERY from "../../../graphql/queries/devicesQuery";

import GenericSelector from "../../../components/Selectors/GenericSelector";

import { useTranslation } from "../../../lang";

const LineForm = ({ setIsOpen, createLine, selectedLine, updateLine }) => {
  const [t] = useTranslation();
  const [customers, setCustomers] = useState([]);
  const [commTypes, setCommTypes] = useState([]);
  const [devices, setDevices] = useState([]);
  const [, setCustomerDefault] = useState();
  const [, setDeviceDefault] = useState();

  const initialValues = {
    id: selectedLine?.id,
    lineNumber: selectedLine?.lineNumber,
    SimNumber: selectedLine?.SimNumber,
    customerId: selectedLine?.customerId,
    email: selectedLine?.email,
    commTypeId: selectedLine?.commTypeId,
    deviceId: selectedLine?.deviceId,
  };

  const yupSchema = Yup.object().shape({
    lineNumber: Yup.string().required(t("Requerido")),
    SimNumber: Yup.string().required(t("Requerido")),
    customerId: Yup.string().required(t("Requerido")),
    email: Yup.string().email(t("Email no válido")),
    commTypeId: Yup.string().required(t("Requerido")),
    // deviceId: Yup.string().required("Requerido"),
  });

  const itemStyle = {
    fontSize: "12px",
    color: "white",
  };

  const columnsDevices = [
    {
      dataField: "deviceIdentifier",
      text: t("Identificador"),
      headerStyle: () => ({ width: "15%", fontSize: "12px" }),
      sort: true,
    },
    {
      dataField: "deviceType.description",
      text: t("Tipo Equipo"),
      headerStyle: () => ({ width: "15%", fontSize: "12px" }),
      //sort: true,
    },
    {
      dataField: "model",
      text: t("Modelo"),
      headerStyle: () => ({ width: "10%", fontSize: "12px" }),
      sort: true,
    },
    {
      dataField: "serialNumber",
      text: t("Nro Serie"),
      sort: true,
      headerStyle: () => ({ width: "10%", fontSize: "12px" }),
    },
    {
      dataField: "line",
      text: "Nro Telefono",
      headerStyle: () => ({ width: "10%", fontSize: "12px" }),
      formatter: (cell, row) => {
        if (Array.isArray(row.line)) {
          return row.line.map((l) => l.lineNumber).join(",");
        }

        return null;
      },
      //sort: true,
    },
    {
      dataField: "imei",
      text: t("Imei"),
      headerStyle: () => ({ width: "15%", fontSize: "12px" }),
      sort: true,
    },
    {
      dataField: "createdDate",
      text: t("Fecha Alta"),
      headerStyle: () => ({ width: "10%", fontSize: "12px" }),
      formatter: (cell, row) => {
        if (row.createdDate) {
          const date = format(new Date(row.createdDate), "yyyy-MM-dd HH:mm:ss");
          return date;
        }

        return "";
      },
      sort: true,
    },
  ];

  const columnsCustomers = [
    {
      dataField: "name",
      text: t("Nombre"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
    },
    {
      dataField: "taxId",
      text: t("CUIT"),
      headerStyle: () => ({ width: "15%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
    },
    {
      dataField: "phone",
      text: t("Telefonos"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
    },
    {
      dataField: "celPhone",
      text: t("Celular"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
    },
    {
      dataField: "address",
      text: "Domicilio",
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
    },
  ];

  const {} = useQuery(CUSTOMERS_QUERY, {
    onCompleted(d) {
      setCustomers(d.customers.items);
      if (selectedLine?.customerId) {
        const defaultC = d.customers.items.find(
          (c) => c.id === selectedLine.customerId
        );
        setCustomerDefault(defaultC);
      }
    },
  });

  const {} = useQuery(COMMTYPE_QUERY, {
    onCompleted(d) {
      const commTypes = d?.commTypes.map((dt) => ({
        label: dt.description,
        value: dt.id,
      }));
      setCommTypes(commTypes);
    },
  });

  const {} = useQuery(GET_DEVICES_QUERY, {
    onCompleted(d) {
      setDevices(d.devicesPaginated.items);
      if (selectedLine?.deviceId) {
        const defaultD = d.devicesPaginated.items.find(
          (d) => d.id === selectedLine.deviceId
        );
        setDeviceDefault(defaultD);
      }
    },
  });

  return (
    <>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={yupSchema}
        onSubmit={async (values) => {
          // console.log("values", values);
          if (selectedLine) {
            updateLine({
              variables: values,
            });
          } else {
            createLine({
              variables: values,
            });
          }
        }}
      >
        {(props) => {
          const { errors, handleSubmit, setFieldValue } = props;

          return (
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <label className="formLabelFormik">{t("Número")}</label>
                <Field
                  type="text"
                  name="lineNumber"
                  tabIndex="0"
                  className={
                    errors.lineNumber
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.lineNumber && (
                  <div className="input-feedback">{errors.lineNumber}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">{t("SIM")}</label>
                <Field
                  type="text"
                  name="SimNumber"
                  tabIndex="0"
                  className={
                    errors.SimNumber
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.SimNumber && (
                  <div className="input-feedback">{errors.SimNumber}</div>
                )}
              </FormGroup>
              <FormGroup>
                {customers && customers.length > 0 && (
                  <GenericSelector
                    callbackOptionSelected={(customer) =>
                      setFieldValue("customerId", customer.value)
                    }
                    columns={columnsCustomers}
                    keyField="id"
                    labelField="name"
                    title={t("Cliente")}
                    data={customers}
                    defaultValue={selectedLine?.customerId}
                  />
                )}
              </FormGroup>
              {/* <FormGroup>
                <label className="formLabelFormik">Cliente</label>
                <Field
                  name="customerId"
                  component={CustomFormikSelect}
                  options={customers}
                />
                {errors.customers && (
                  <div className="input-feedback">{errors.customers}</div>
                )}
              </FormGroup> */}
              <FormGroup>
                <label className="formLabelFormik">
                  {t("Tipo Comunicación")}
                </label>
                <Field
                  name="commTypeId"
                  component={CustomFormikSelect}
                  options={commTypes}
                />
                {errors.commTypeId && (
                  <div className="input-feedback">{errors.commTypeId}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">{t("Email")}</label>
                <Field
                  type="text"
                  name="email"
                  tabIndex="0"
                  className={
                    errors.email
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.email && (
                  <div className="input-feedback">{errors.email}</div>
                )}
              </FormGroup>
              <FormGroup>
                {devices && devices.length > 0 && (
                  <GenericSelector
                    callbackOptionSelected={(device) =>
                      device
                        ? setFieldValue("deviceId", device.value)
                        : setFieldValue("deviceId", null)
                    }
                    columns={columnsDevices}
                    keyField="id"
                    labelField="deviceIdentifier"
                    title={t("Equipo")}
                    data={devices}
                    defaultValue={selectedLine?.deviceId}
                  />
                )}
                {errors.deviceId && (
                  <div className="input-feedback">{errors.deviceId}</div>
                )}
              </FormGroup>
              <Grid item lg={12} className="footerSlidePanel">
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  tabIndex="0"
                >
                  {selectedLine ? t("Actualizar") : t("Crear")}
                </Button>
                <Button
                  variant="contained"
                  type="button"
                  tabIndex="0"
                  style={{ marginLeft: "20px" }}
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  {t("Cancelar")}
                </Button>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default LineForm;
