import gql from "graphql-tag";

const GET_USER_PHOTO_QUERY = gql`
  query Users($userId: Int!) {
    getUser(userId: $userId) {
      id
      photo
    }
  }
`;

export default GET_USER_PHOTO_QUERY;
