import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useTranslation } from "../../../lang";
import { MAX_SPEED_FILTER } from "../../../utils/constants";
import FormHeader from "./FormHeader";
import FormNotification from "./FormNotification";
import FormProgramation from "./FormProgramations";
import FormReportFilters from "./FormReportFilters";
import PLANNING_REPORT_BY_ID_QUERY from "../../../graphql/queries/getPlanningReportByIdQuery";
import { useLazyQuery } from "@apollo/client";

const theme = createMuiTheme({});

const _ = require("lodash");

const Form = ({
  isOpen,
  closeModal,
  selectedPlanningReport,
  reportTypes,
  customers,
  handleCreatePlanningReport,
  handleUpdatePlanningReport,
  groups,
  getGroups,
}) => {
  const [t] = useTranslation();
  const [planningReport, setPlanningReport] = useState();
  const [groupsSelected, setGroupsSelected] = useState();

  const [getReportById] = useLazyQuery(PLANNING_REPORT_BY_ID_QUERY, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onCompleted(d) {
      setPlanningReport(d.getPlanningReport);
    },
    onError(error) {
      console.debug("<>There was an error: ", error);
    },
  });

  const initialValues = {
    id: planningReport?.id,
    enabled: planningReport ? planningReport.enabled : true,
    allMovilesActivos: planningReport?.allMovilesActivos || false,
    name: planningReport?.name,
    customerId: planningReport?.customerId,
    typeReportID: planningReport?.typeReportID,
    formatId: planningReport?.formatId,
    creadaPor: planningReport?.userCreator?.userName,
    usersDestinationsId:
      Array.isArray(planningReport?.usersDestinations) &&
      planningReport.usersDestinations.length > 0
        ? planningReport.usersDestinations?.map((ud) => ud.id)
        : [],
    programations: planningReport?.planningReportProgramation || [],
    mailList: planningReport?.mailList || "",
    mailListCco: planningReport?.mailListCco || "",
    datePeriod: planningReport?.datePeriod,
    journeyReportArgs: planningReport?.planningTravelReport || {
      groupBy: 1, // "DIARIO"
    },
    detentionReportArgs: planningReport?.planningDetentionReport || {
      minimumTime: 0,
      mobileStatus: "ALL",
    },
    geofenceReportArgs: planningReport?.planningGeofenceReport || {
      geofenceIds: [],
      permanenceSecond: null,
    },
    velocidadReportArgs: planningReport?.planningVelocityReport
      ? {
          ...planningReport.planningVelocityReport,
          drivers:
            Array.isArray(planningReport.planningVelocityReport.drivers) &&
            planningReport.planningVelocityReport.drivers.length > 0
              ? planningReport.planningVelocityReport.drivers.map((d) => d.id)
              : [],
        }
      : {
          velocidadDesde: 0,
          velocidadHasta:
            process.env.REACT_APP_CUSTOMER === "Reaxium"
              ? MAX_SPEED_FILTER.MPH
              : MAX_SPEED_FILTER.KMH,
          drivers: [],
        },
    obd2ReportArgs: planningReport?.planningOBD2Report || {
      variablesOBD2: null,
    },
    assetsId: planningReport?.assets?.map((a) => a.id),
    stopsDetourReportArgs: {
      id: planningReport?.planningStopsDetourReport?.id,
      roadId: planningReport?.planningStopsDetourReport?.roadId,
    },
    eventsReportArgs: planningReport?.planningEventsReport,
  };

  useEffect(() => {
    if (!selectedPlanningReport) {
      setPlanningReport(null);
      return;
    }

    if (isOpen) {
      getReportById({
        variables: {
          id: selectedPlanningReport.id,
        },
      });
    }
  }, [isOpen, selectedPlanningReport]);

  Yup.addMethod(Yup.object, "atLeastOneEmail", function (list, message) {
    return this.shape(
      list.reduce(
        (acc, field) => ({
          ...acc,
          [field]: this.fields[field].when(_.without(list, field), {
            is: (...values) => {
              const newValues = values.map((oldValue) => {
                if (oldValue === undefined || oldValue === null) {
                  return false;
                }

                if (Array.isArray(oldValue) && oldValue.length === 0) {
                  return false;
                }

                return oldValue;
              });

              return !newValues.some((item) => item);
            },
            then: this.fields[field].required(message),
          }),
        }),
        {}
      ),
      list.reduce(
        (acc, item, idx, all) => [
          ...acc,
          ...all.slice(idx + 1).map((i) => [item, i]),
        ],
        []
      )
    );
  });

  const buildYupShapeFields = (planningReport) => {
    // @TODO return different shapes based on planning report type

    return {
      name: Yup.string().required(t("Requerido")),
      usersDestinationsId: Yup.array().of(Yup.number()),
      mailList: Yup.string(),
      mailListCco: Yup.string(),
      typeReportID: Yup.string().required(t("Requerido")),
      formatId: Yup.number().required(t("Requerido")),
      customerId: Yup.number().required(t("Requerido")),
      // datePeriod: Yup.string().required(t("Requerido")),
    };
  };

  const yupShapeFields = buildYupShapeFields(planningReport);

  const yupSchema = Yup.object()
    .shape(yupShapeFields)
    .atLeastOneEmail(
      ["usersDestinationsId", "mailList", "mailListCco"],
      t("Debe ingresar al menos un destinatario")
    );

  return (
    <ThemeProvider theme={theme}>
      <Dialog open={isOpen} onClose={closeModal} /*fullScreen*/ maxWidth="lg">
        <DialogTitle className="dialogTitle">
          {planningReport
            ? t("pages_manage_reportingplanning_actualizar_por_nombre", {
                name: planningReport.name,
              })
            : t("Crear Reporte Planificado")}
        </DialogTitle>
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={yupSchema}
          onSubmit={async (values) => {
            //console.log("values", values);
            delete values.creadaPor;
            const args = {
              ...values,
              datePeriod: values.datePeriod,
              detentionReportArgs: {
                minimumTime: values.detentionReportArgs.minimumTime
                  ? parseInt(values.detentionReportArgs.minimumTime)
                  : null,
                mobileStatus: values.detentionReportArgs.mobileStatus,
              },
              stopsDetourReportArgs: values.stopsDetourReportArgs,
            };

            if (planningReport) {
              handleUpdatePlanningReport(args);
            } else {
              handleCreatePlanningReport(args);
            }
            closeModal();
          }}
        >
          {(props) => {
            const {
              values,
              errors,
              touched,
              handleSubmit,
              setFieldValue,
            } = props;

            return (
              <form onSubmit={handleSubmit}>
                <DialogContent>
                  <FormHeader
                    errors={errors}
                    touched={touched}
                    reportTypes={reportTypes}
                    values={values}
                    setFieldValue={setFieldValue}
                    initialValues={initialValues}
                    customers={customers}
                    groups={groups}
                    getGroups={getGroups}
                    setGroupsSelected={setGroupsSelected}
                  />
                  <FormNotification
                    errors={errors}
                    setFieldValue={setFieldValue}
                    values={values}
                  />

                  <FormProgramation
                    setFieldValue={setFieldValue}
                    values={values}
                  />

                  <FormReportFilters
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    values={values}
                    groupsSelected={groupsSelected}
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    style={{ marginLeft: "10px" }}
                    onClick={() => closeModal()}
                  >
                    {t("Cancelar")}
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    //onClick={() => closeModal()}
                  >
                    {t("Guardar")}
                  </Button>
                </DialogActions>
              </form>
            );
          }}
        </Formik>
      </Dialog>
    </ThemeProvider>
  );
};

export default Form;
