import gql from "graphql-tag";

const DELETE_PLANNINGREPORT_MUTATION = gql`
  mutation DeletePlanningReport($id: Int!) {
    deletePlanningReport(id: $id) {
      id
    }
  }
`;

export default DELETE_PLANNINGREPORT_MUTATION;
