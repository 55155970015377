import React, { useLayoutEffect, useRef } from "react";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import darkTheme from "@amcharts/amcharts4/themes/dark";
import { roundNumber } from "../../utils/generalUtils";

am4core.useTheme(darkTheme);
am4core.useTheme(am4themes_animated);

const ScoreGaugeChart = ({ data = {} }) => {
  const chart = useRef(null);

  //Ver diferencias entre useEffect y useLayoutEffect
  //useLayoutEffect se dispara de forma sincronica despues de las mutations del DOM
  useLayoutEffect(() => {
    if (chart.current) {
      chart.current.radarContainer.children.each((c) => {
        if (c instanceof am4core.Label) {
          if (c.id === "lblScore") c.text = roundNumber(data.score, 2);
          if (c.id === "lblScoreFleet")
            c.text = roundNumber(data.scoreFleet, 2);
        }
      });

      chart.current.xAxes.each((a) => {
        a.min = data.minScore;
        a.max = data.maxScore;
      });

      chart.current.hands.each((h) => {
        h.value = data.score;
      });
    }
  }, [data]);

  useLayoutEffect(() => {
    let x = am4core.create("scoreGaugeChartContainer", am4charts.GaugeChart);

    let axis = x.xAxes.push(new am4charts.ValueAxis());
    axis.min = data.minScore;
    axis.max = data.maxScore;

    let colorSet = new am4core.ColorSet();

    let gradient = new am4core.LinearGradient();
    gradient.stops.push({ color: am4core.color("green") });
    gradient.stops.push({ color: am4core.color("yellow") });
    gradient.stops.push({ color: am4core.color("red") });

    axis.renderer.line.stroke = gradient;
    axis.renderer.line.strokeWidth = 30;
    axis.renderer.line.strokeOpacity = 1;
    axis.renderer.grid.template.disabled = true;
    axis.renderer.labels.template.disabled = true;

    var label = x.radarContainer.createChild(am4core.Label);
    label.id = "lblScore";
    label.isMeasured = false;
    label.fontSize = "50px";
    //label.x = am4core.percent(50);
    label.fill = am4core.color("gray");
    label.stroke = am4core.color("gray");
    label.paddingBottom = 15;
    label.horizontalCenter = "middle";
    label.verticalCenter = "bottom";

    label.text = data.score;
    //label.fill = am4core.color(matchingGrade.color);

    var label2 = x.radarContainer.createChild(am4core.Label);
    label2.id = "lblScoreFleet";
    label2.isMeasured = false;
    label2.fontSize = "20px";
    label2.horizontalCenter = "middle";
    label2.verticalCenter = "bottom";
    //label2.text = matchingGrade.title.toUpperCase();

    let hand = x.hands.push(new am4charts.ClockHand());
    hand.innerRadius = am4core.percent(55);
    hand.startWidth = 8;
    hand.pin.disabled = true;
    //hand.value = data.score;
    hand.value = 1000;
    hand.fill = am4core.color("#444");
    hand.stroke = am4core.color("#000");

    chart.current = x;

    return () => {
      x.dispose();
    };
  }, []);

  return (
    <>
      <div
        id="scoreGaugeChartContainer"
        style={{ width: "100%", height: "20vh" }}
      />
    </>
  );
};

export default ScoreGaugeChart;
