// eslint-disable react/forbid-prop-types
import React from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import Badge from "@material-ui/core/Badge";

import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";

import "./DropdownButton.scss";

const DropdownButton = ({ handleClick, title, icon, iconBadge }) => {
  const Icon = icon;

  return (
    <Button
      aria-controls="customized-menu"
      aria-haspopup="true"
      onClick={handleClick}
      className="dropdown-button"
    >
      {iconBadge ? (
        <Badge
          badgeContent={iconBadge.cant}
          color={iconBadge.color}
          anchorOrigin={iconBadge.anchor}
          max={iconBadge.max}
        >
          <Icon className="dropdown-button__principal-icon" />
        </Badge>
      ) : (
        <Icon className="dropdown-button__principal-icon" />
      )}

      {title && <div className="navbar-item-button__text">{title}</div>}

      <ExpandMoreOutlinedIcon className="dropdown-button__secondary-icon" />
    </Button>
  );
};

DropdownButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.any.isRequired,
};

export default DropdownButton;
