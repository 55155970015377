import React, { useEffect, useState } from "react";

import { MapContainer, TileLayer, Marker, Tooltip } from "react-leaflet";
import Leaflet from "leaflet";

import "leaflet/dist/leaflet.css";
import "../../components/Map/BaseMap.scss";

import defaultIcon from "leaflet/dist/images/marker-icon.png";
import defaultIconShadow from "leaflet/dist/images/marker-shadow.png";

const Map = ({ point }) => {
  const { pat, nMov } = point || {};
  // const defaultPosition = [-12.050742, -77.04656];
  const defaultPosition = process.env.REACT_APP_DEFAULT_CENTER_MAP.split(",");
  //const position = lat && lon ? [lat, lon] : null;
  const [position, setPosition] = useState();
  const [map, setMap] = useState();

  const tooltipText = `${nMov} - ${pat}`;

  useEffect(() => {
    if (!point || !map) return;

    const { lat, lon, pat, nMov } = point || {};
    setPosition([lat, lon]);
    map.setView([lat, lon], 16);
  }, [point]);

  const icon = Leaflet.icon({
    iconUrl: defaultIcon,
    shadowUrl: defaultIconShadow,
  });

  return (
    <MapContainer
      center={position || defaultPosition}
      zoom={13}
      scrollWheelZoom={false}
      style={{ width: "100%", height: "90vh" }}
      whenCreated={(instance) => setMap(instance)}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {position && (
        <Marker position={position} icon={icon} classNames="marker-container">
          <Tooltip>{tooltipText}</Tooltip>
        </Marker>
      )}
    </MapContainer>
  );
};

export default Map;
