import { connect } from 'react-redux';

// import ClientForm from '../../pages/Manage/Clients/Form';
import ClientList from '../../pages/Manage/Clients/List';

import {
  getClientAction,
  handleShowAddModal,
  handleShowEditModal,
  insertClientAction,
  updateClientAction,
  deleteClientAction
} from '../../redux/manage/clients/actions';

const _mapStateToProps = state => {
  return ({
    clients: state.clientReducer.clients,
    showAddModal: state.clientReducer.showAddModal,
    showEditModal: state.clientReducer.showEditModal,
    loading: state.clientReducer.loading
  });
}

const _mapDispatchToProps = {
    handleGetClients: getClientAction,
    showAddModalHandler: handleShowAddModal,
    showEditModalHandler: handleShowEditModal,
    handleAddClient: insertClientAction,
    handleUpdateClient: updateClientAction,
    handleDeleteClient: deleteClientAction
};

export default connect(_mapStateToProps, _mapDispatchToProps)(ClientList);
