import gql from "graphql-tag";

const GET_HISTORY_POINT_QUERY = gql`
  query getHistoryPoint($id: Int!) {
    getHistoryPoint(id: $id) {
      cur
      dir
      eid
      fGPS
      horo
      mid
      nCh
      nCli
      nInt
      nMov
      odo
      pat
      sOdo
      tDet
      tIgn
      typ
      utx
    }
  }
`;

export default GET_HISTORY_POINT_QUERY;
