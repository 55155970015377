import React from "react";
import { useTranslation } from "../../lang";
import OBD2Engine from "../../public/icons/OBD2Engine";
import useOBD2Modal from "./OBD2Hook";
import OBD2ModalWindow from "./OBD2ModalWindow";
import { Button } from "@material-ui/core";
import "./style.scss";

const OBD2ModalToggleButton = ({ className, markerInfo }) => {
  const [t] = useTranslation();
  const { isOpen, openOBD2Modal, closeOBD2Modal } = useOBD2Modal();
  return (
    <>
      <OBD2ModalWindow
        isOpen={isOpen}
        closeModal={closeOBD2Modal}
        markerInfo={markerInfo}
      />
      <Button
        onClick={openOBD2Modal}
        className={className}
        title={t("datos_OBD2")}
      >
        <OBD2Engine />
      </Button>
    </>
  );
};

export default OBD2ModalToggleButton;
