import { format } from "date-fns";
import React from "react";
import { connect } from "react-redux";
import Table from "../../../components/Base/Table";
import { useTranslation } from "../../../lang";
import { GetDateFormat, secondsToTime } from "../../../utils/generalUtils";

const ReportResult = ({ reportData, user }) => {
  const [t] = useTranslation();
  const columns = [
    {
      dataField: "name",
      text: t("Nombre móvil"),
      sort: true,
      headerStyle: () => ({ width: "20%" }),
    },
    {
      dataField: "pat",
      text: t("Placa"),
      sort: true,
      headerStyle: () => ({ width: "15%" }),
    },
    {
      dataField: "geofenceName",
      text: t("Geocerca"),
      sort: true,
      headerStyle: () => ({ width: "20%" }),
    },
    {
      dataField: "iTime",
      text: t("Entrada"),
      sort: true,
      formatter: (cell, row) => {
        if (row.iTime)
          return format(new Date(row.iTime), GetDateFormat(user.language));
        else return "---";
      },
      headerStyle: () => ({ width: "15%" }),
    },
    {
      dataField: "fTime",
      text: t("Salida"),
      sort: true,
      formatter: (cell, row) => {
        if (row.fTime)
          return format(new Date(row.fTime), GetDateFormat(user.language));
        else return "---";
      },
      headerStyle: () => ({ width: "15%" }),
    },
    {
      dataField: "duration",
      text: t("Duración"),
      sort: true,
      formatter: (cell, row) => secondsToTime(row.duration),
      headerStyle: () => ({ width: "15%" }),
    },
  ];

  return (
    <div style={{ padding: "20px" }}>
      <Table columns={columns} data={reportData} keyField="rowid" />
    </div>
  );
};
const _mapStateToProps = ({ user }) => {
  return {
    user,
  };
};

const _mapDispatchToProps = (dispatch) => {
  return {
    // _setCurrentUser: (param) => dispatch(setCurrentUser(param)),
  };
};

export default connect(_mapStateToProps, _mapDispatchToProps)(ReportResult);
