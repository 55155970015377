import React, { useState, useEffect } from "react";

import { connect } from "react-redux";

import { MapContainer, Marker, TileLayer } from "react-leaflet";
import { GeometryTypes } from "../../utils/generalUtils";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";

import { useLazyQuery } from "@apollo/client";

import _ from "lodash";
import MarkersGroup from "../Map/MarkersGroupV3";
import NavigationMarker from "../Map/NavigationMarker";
import { markersSelector } from "../../redux/markers/selector";

import GET_POINTSBYGROUPS_QUERY from "../../graphql/queries/pointsByGroups";

import ModalPoi from "../Base/ModalPoi";

import L, { latLng } from "leaflet";
import "leaflet-draw/dist/leaflet.draw.js";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw-src.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet-geosearch/dist/geosearch.css";
import "../../components/Map/BaseMap.scss";

import defaultIcon from "leaflet/dist/images/marker-icon.png";
import defaultIconShadow from "leaflet/dist/images/marker-shadow.png";
import "leaflet-draw/dist/leaflet.draw.js";

import { useTranslation } from "../../lang";

const DrawableMap = ({
  handleChangeDraw,
  selectedGeometry,
  typeGeometry,
  selectedGroups,
}) => {
  const [t] = useTranslation();
  // const position = [-12.050742, -77.04656];
  const position = process.env.REACT_APP_DEFAULT_CENTER_MAP.split(",");
  const [points, setPoints] = useState();
  const [map, setMap] = useState();
  const [markers, setMarkers] = useState([]);
  const [showMarkers, setShowMarkers] = useState();

  const [modalPoiOpen, setModalPoiOpen] = useState(false);
  const closeModal = () => setModalPoiOpen(false);

  const sanitizePoints = _.memoize((markers) => {
    const pointsMarkers = markersSelector({ markers: markers, t });
    setMarkers(pointsMarkers);
  });

  const handleSelectionPoi = (poi) => {
    const poiLayers = new L.FeatureGroup();
    map.addLayer(poiLayers);

    poi.forEach((p) => {
      L.circle([p.latitude, p.longitude], p.radio).addTo(map);
    });
  };

  const [getPoints] = useLazyQuery(GET_POINTSBYGROUPS_QUERY, {
    //  GET_MARKERS_QUERY, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      sanitizePoints(data.pointsByGroups);
    },
    onError: (error) => {
      console.log("Error:", error);
    },
  });

  const handleClickShowPositions = () => {
    setShowMarkers(!showMarkers);
    if (!markers || markers.length === 0) {
      if (selectedGroups) {
        const groups =
          selectedGroups.length > 0
            ? selectedGroups.filter(
                (g) => g && !Number.isNaN(Number.parseInt(g))
              )
            : //.join(",")
              [];
        if (Array.isArray(groups) && groups.length > 0) {
          const intGroups = groups.map((g) => parseInt(g, 10));
          getPoints({
            variables: {
              groupsIDs: intGroups,
            },
          });
        }
      }
    }
  };

  const coordsToWkt = (coords) => {
    let wkt = "";

    switch (typeGeometry) {
      case GeometryTypes.POLYGON:
        wkt = "POLYGON((";
        coords.forEach((c) => {
          wkt += `${c.lng} ${c.lat},`;
        });
        break;
      case GeometryTypes.LINE:
        wkt = "LINESTRING(";
        coords.forEach((c) => {
          wkt += `${c.lng} ${c.lat},`;
        });
        break;
      case GeometryTypes.MARKER:
        wkt = "POINT(";
        wkt += `${coords.lng} ${coords.lat}`;
        break;
    }

    //Si es un poligono hay que agregar el primer punto como ultimo para cerrarlo
    if (typeGeometry === GeometryTypes.POLYGON) {
      wkt += `${coords[0].lng} ${coords[0].lat}`;
      wkt += "))";
    } else {
      //Borramos la , sobrante
      wkt = wkt.substring(0, wkt.length - 1);
      wkt += ")";
    }

    return wkt;
  };

  const icon = L.icon({
    iconUrl: defaultIcon,
    shadowUrl: defaultIconShadow,
  });

  useEffect(() => {
    if (!map) return;

    const provider = new OpenStreetMapProvider();
    const searchControl = new GeoSearchControl({
      provider: provider,
      marker: {
        icon: icon,
      },
    });

    map.addControl(searchControl);

    const positionControl = L.Control.extend({
      options: {
        position: "topleft",
      },
      onAdd: (m) => {
        const container = L.DomUtil.create(
          "div",
          "leaflet-bar leaflet-control leaflet-control-custom"
        );
        container.style.backgroundColor = "white";
        container.style.backgroundImage = "url(/assets/img/position.png)";
        container.style.backgroundSize = "32px 32px";
        container.style.width = "32px";
        container.style.height = "32px";
        container.style.cursor = "pointer";
        container.title = t("Ver posiciones");

        container.onclick = function () {
          handleClickShowPositions();
        };

        return container;
      },
    });

    map.addControl(new positionControl());

    const poiControl = L.Control.extend({
      options: {
        position: "topleft",
      },
      onAdd: (m) => {
        const container = L.DomUtil.create(
          "div",
          "leaflet-bar leaflet-control leaflet-control-custom"
        );
        container.style.backgroundColor = "white";
        container.style.backgroundImage = "url(/assets/img/poiIcon.png)";
        container.style.backgroundSize = "32px 32px";
        container.style.width = "32px";
        container.style.height = "32px";
        container.style.cursor = "pointer";
        container.title = t("Ver puntos de interes");

        container.onclick = function () {
          setModalPoiOpen(true);
        };

        return container;
      },
    });

    map.addControl(new poiControl());

    const editableLayers = new L.FeatureGroup();
    map.addLayer(editableLayers);

    const options = {
      position: "topright",
      draw: {
        polyline: typeGeometry === GeometryTypes.LINE,
        polygon: typeGeometry === GeometryTypes.POLYGON,
        rectangle: typeGeometry === GeometryTypes.RECTANGLE,
        circle: typeGeometry === GeometryTypes.CIRCLE,
        marker:
          typeGeometry === GeometryTypes.MARKER
            ? {
                icon: icon,
              }
            : false,
        circlemarker: typeGeometry === GeometryTypes.CIRCLEMARKER,
      },
      edit: {
        featureGroup: editableLayers,
      },
    };

    const drawHandlers = {};

    drawHandlers[GeometryTypes.POLYGON] = (layer) =>
      coordsToWkt(layer._latlngs[0]);
    drawHandlers[GeometryTypes.LINE] = (layer) => coordsToWkt(layer._latlngs);
    drawHandlers[GeometryTypes.MARKER] = (layer) => coordsToWkt(layer._latlng);

    const drawControl = new L.Control.Draw(options);
    map.addControl(drawControl);

    map.on(L.Draw.Event.CREATED, (e) => {
      const layer = e.layer;
      const handler = drawHandlers[typeGeometry];
      if (handler) {
        handleChangeDraw(handler(layer));
      }

      editableLayers.addLayer(layer);
    });

    map.on(L.Draw.Event.EDITED, (e) => {
      const layers = e.layers;
      layers.eachLayer((l) => {
        const handler = drawHandlers[typeGeometry];
        if (handler) {
          handleChangeDraw(handler(l));
        }
      });
    });

    map.on(L.Draw.Event.DELETED, (e) => {
      handleChangeDraw(null);
    });

    //Si viene un geometry hay que crear un layer y agregarlo
    if (selectedGeometry) {
      let geoJsonLayer;
      if (typeGeometry === GeometryTypes.MARKER) {
        //Si no le decis cual es el icono, debido a un bug no toma el icono por defecto y se rompe la imagen
        geoJsonLayer = L.geoJSON(JSON.parse(selectedGeometry), {
          pointToLayer: (feature, latlng) => {
            return L.marker(latlng, { icon: icon });
          },
        });
      } else {
        // console.log("Selected geometry: ", selectedGeometry);
        geoJsonLayer = L.geoJSON(JSON.parse(selectedGeometry));
      }

      //https://github.com/Leaflet/Leaflet.draw/issues/398
      //https://gis.stackexchange.com/questions/203540/how-to-edit-an-existing-layer-using-leaflet

      //Debido a un bug en leaflet-draw en vez de agregar el geoJson hay que agregar los layers hijos de estos que no sean grupos
      //Los que son grupos hay que recorrer sus hijos y agregarlos tambien aunque en este caso no es necesario
      geoJsonLayer.eachLayer((l) => {
        if (!(l instanceof L.LayerGroup)) {
          editableLayers.addLayer(l);
        }
      });

      map.fitBounds(geoJsonLayer.getBounds());
      //geoJsonLayer.
    }
  }, map);

  return (
    <>
      <ModalPoi
        closeModal={closeModal}
        handleSelectionPoi={handleSelectionPoi}
        isOpen={modalPoiOpen}
      />
      <MapContainer
        center={position}
        zoom={8}
        style={{ width: "550px", height: "400px" }}
        whenCreated={(instance) => setMap(instance)}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {showMarkers && (
          <MarkersGroup
            //markers={markers}
            markers={markers.map((m) => (
              <NavigationMarker
                markerInfo={m}
                icon={icon}
                key={`marker-${m.mid}`}
              />
            ))}
            maxZoom={19}
          />
        )}
      </MapContainer>
    </>
  );
};

const _mapStateToProps = ({ groups }) => {
  return groups;
};

export default connect(_mapStateToProps, null)(DrawableMap);
