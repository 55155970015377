import React, { useLayoutEffect, useRef } from "react";
import { connect } from "react-redux";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import darkTheme from "@amcharts/amcharts4/themes/dark";
import { useTranslation } from "../../lang";
import { GetDateFormat } from "../../utils/generalUtils";

am4core.useTheme(darkTheme);
am4core.useTheme(am4themes_animated);

const FuelConsumedChart = ({ data, user }) => {
  const [t] = useTranslation();
  const chart = useRef(null);

  //Ver diferencias entre useEffect y useLayoutEffect
  //useLayoutEffect se dispara de forma sincronica despues de las mutations del DOM
  useLayoutEffect(() => {
    if (chart.current) {
      chart.current.series.each((serie) => {
        serie.data = data;
      });
    }
  }, [data]);

  useLayoutEffect(() => {
    let x = am4core.create("fuelConsumedChartContainer", am4charts.XYChart);

    const title = x.titles.create();
    title.text = t("Combustible Consumido");
    title.fontSize = 25;
    title.marginBottom = 30;
    title.align = "left";

    //Create axis
    x.xAxes.push(new am4charts.DateAxis());
    x.yAxes.push(new am4charts.ValueAxis());

    x.dateFormatter = new am4core.DateFormatter();
    x.dateFormatter.dateFormat = GetDateFormat(user.language, true);

    //Fleet Score serie
    let fleetFuelSerie = x.series.push(new am4charts.LineSeries());
    fleetFuelSerie.dataFields.valueY = "fleetFuel";
    fleetFuelSerie.dataFields.dateX = "date";
    fleetFuelSerie.name = "FleetFuel";
    fleetFuelSerie.tooltipText = `${t("Fecha")}: {dateX}
      ${t("Combustible consumido Flota")}: {valueY} [L]
    `;
    fleetFuelSerie.stroke = am4core.color("#A20F0F");
    fleetFuelSerie.strokeWidth = 2;

    let segmentFS = fleetFuelSerie.segments.template;
    segmentFS.interactionsEnabled = true;

    fleetFuelSerie.data = data;

    //Driver or Asset serie
    let fuelSerie = x.series.push(new am4charts.LineSeries());
    fuelSerie.dataFields.valueY = "fuel";
    fuelSerie.dataFields.dateX = "date";
    fuelSerie.name = "Condtime";
    fuelSerie.stroke = am4core.color("#87C802");
    fuelSerie.strokeWidth = 2;
    fuelSerie.tooltipText = `${t("Fecha")}: {dateX}
      ${t("Combustible consumido")}: {valueY} [L]
    `;

    let segmentScore = fuelSerie.segments.template;
    segmentScore.interactionsEnabled = true;

    fuelSerie.data = data;

    x.cursor = new am4charts.XYCursor();
    x.cursor.maxTooltipDistance = 0;

    chart.current = x;

    return () => {
      x.dispose();
    };
  }, [user.language]);

  return (
    <>
      <div
        id="fuelConsumedChartContainer"
        style={{ width: "100%", height: "40vh" }}
      />
    </>
  );
};

const _mapStateToProps = ({ user }) => {
  return {
    user,
  };
};

const _mapDispatchToProps = (dispatch) => {
  return {
    // _setCurrentUser: (param) => dispatch(setCurrentUser(param)),
  };
};

export default connect(
  _mapStateToProps,
  _mapDispatchToProps
)(FuelConsumedChart);
