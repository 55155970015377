import React from "react";

const CusterIcon = () => {
  return (
    <div style={{ justifyContent: "center" }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path d="M13 18H7v1a1 1 0 01-1 1H5a1 1 0 01-1-1v-1a2 2 0 01-2-2V2c0-1.1.9-2 2-2h12a2 2 0 012 2v14a2 2 0 01-2 2v1a1 1 0 01-1 1h-1a1 1 0 01-1-1v-1zM4 5v6h5V5H4zm7 0v6h5V5h-5zM5 2v1h10V2H5zm.5 14a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm9 0a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"></path>
      </svg>
    </div>
  );
};

export default React.memo(CusterIcon);
