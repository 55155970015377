import gql from "graphql-tag";

const GET_FREQUENCY_QUERY = gql`
  query Frequency {
    frequency {
      id
      description
    }
  }
`;

export default GET_FREQUENCY_QUERY;
