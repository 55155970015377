import React, { useEffect, useState } from "react";

import { useQuery } from "@apollo/client";

import { Button, FormGroup, Grid } from "@material-ui/core";
import * as Yup from "yup";
import { Field, Formik } from "formik";

import CUSTOMERS_QUERY from "../../../graphql/queries/getCustomersQuery";

import CustomFormikSelect from "../../../components/Base/CustomFormikSelect";
import GET_POI_CATEGORIES_QUERY from "../../../graphql/queries/getPoiCategoriesQuery";
import GenericSelector from "../../../components/Selectors/GenericSelector";
import DrawableMap from "../../../components/Base/DrawableMap";
import { GeometryTypes } from "../../../utils/generalUtils";

import { useTranslation } from "../../../lang";

const Form = ({ closeModal, selectedPoi, createPoi, updatePoi }) => {
  const [t] = useTranslation();
  const [customers, setCustomers] = useState([]);
  const [poiCategories, setPoiCategories] = useState([]);
  const [geometry, setGeometry] = useState();

  const getGeometry = () => {
    if (selectedPoi) {
      const geometry = {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [selectedPoi.longitude, selectedPoi.latitude],
        },
        properties: {
          name: selectedPoi.name,
        },
      };
      setGeometry(JSON.stringify(geometry));
    }
  };

  useEffect(() => {
    getGeometry();
  }, [getGeometry]);

  useQuery(GET_POI_CATEGORIES_QUERY, {
    onCompleted(d) {
      setPoiCategories(
        d?.poiCategory?.map((c) => ({
          label: c.poiDescription,
          value: c.id,
        }))
      );
    },
  });

  useQuery(CUSTOMERS_QUERY, {
    onCompleted(d) {
      setCustomers(d.customers.items);
    },
  });

  const initialValues = {
    id: selectedPoi?.id,
    name: selectedPoi?.name,
    customerId: selectedPoi?.customerId,
    latitude: selectedPoi?.latitude,
    longitude: selectedPoi?.longitude,
    radio: selectedPoi?.radio,
    poiCategoryId: selectedPoi?.poiCategoryId,
  };

  const yupSchema = Yup.object().shape({
    name: Yup.string().required(t("Requerido")),
    customerId: Yup.number().required(t("Requerido")),
    latitude: Yup.number().required(t("Requerido")),
    longitude: Yup.number().required(t("Requerido")),
    radio: Yup.number().required(t("Requerido")),
    poiCategoryId: Yup.number().required(t("Requerido")),
  });

  const itemStyle = {
    fontSize: "12px",
    color: "white",
  };

  const columnsCustomers = [
    {
      dataField: "name",
      text: t("Nombre"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
    },
    {
      dataField: "taxId",
      text: t("CUIT"),
      headerStyle: () => ({ width: "15%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
    },
    {
      dataField: "phone",
      text: t("Telefonos"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
    },
    {
      dataField: "celPhone",
      text: t("Celular"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
    },
    {
      dataField: "address",
      text: t("Domicilio"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
    },
  ];

  return (
    <>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={yupSchema}
        onSubmit={async (values) => {
          if (selectedPoi) {
            updatePoi(values);
          } else {
            createPoi(values);
          }
        }}
      >
        {(props) => {
          const { errors, handleSubmit, setFieldValue } = props;

          const setPositionFields = (wkt) => {
            const regExp = /\(([^)]+)\)/;
            const matches = regExp.exec(wkt);
            const pos = matches[1].split(" ");
            setFieldValue("latitude", parseFloat(pos[1]));
            setFieldValue("longitude", parseFloat(pos[0]));
          };

          return (
            <form onSubmit={handleSubmit}>
              <FormGroup>
                {customers && customers.length > 0 && (
                  <GenericSelector
                    callbackOptionSelected={(customer) =>
                      setFieldValue("customerId", customer.value)
                    }
                    columns={columnsCustomers}
                    keyField="id"
                    labelField="name"
                    title={t("Cliente")}
                    data={customers}
                    defaultValue={selectedPoi?.customerId}
                  />
                )}
                {errors.customerId && (
                  <div className="input-feedback">{errors.customerId}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">{t("Nombre")}</label>
                <Field
                  type="text"
                  name="name"
                  tabIndex="0"
                  className={
                    errors.name
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                />
                {errors.name && (
                  <div className="input-feedback">{errors.name}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">{t("Latitud")}</label>
                <Field
                  type="number"
                  name="latitude"
                  tabIndex="0"
                  className={
                    errors.latitude
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                />
                {errors.latitude && (
                  <div className="input-feedback">{errors.latitude}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">{t("Longitude")}</label>
                <Field
                  type="number"
                  name="longitude"
                  tabIndex="0"
                  className={
                    errors.longitude
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                />
                {errors.longitude && (
                  <div className="input-feedback">{errors.longitude}</div>
                )}
              </FormGroup>

              <FormGroup>
                <label className="formLabelFormik">{t("Radio")}</label>
                <Field
                  type="number"
                  name="radio"
                  tabIndex="0"
                  className={
                    errors.radio
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                />
                {errors.radio && (
                  <div className="input-feedback">{errors.radio}</div>
                )}
              </FormGroup>
              <FormGroup style={{ marginBottom: "20px" }}>
                <label className="formLabelFormik">{t("Categoría")}</label>
                <Field
                  name="poiCategoryId"
                  component={CustomFormikSelect}
                  options={poiCategories}
                />
                {errors.poiCategoryId && (
                  <div className="input-feedback">{errors.poiCategoryId}</div>
                )}
              </FormGroup>

              <Grid item lg={12}>
                <DrawableMap
                  handleChangeDraw={(wkt) => setPositionFields(wkt)}
                  selectedGeometry={geometry}
                  typeGeometry={GeometryTypes.MARKER}
                />
              </Grid>
              <Grid
                item
                lg={12}
                style={{ marginTop: "50px" }} /*className="footerSlidePanel"*/
              >
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  tabIndex="0"
                >
                  {selectedPoi ? t("Actualizar") : t("Crear")}
                </Button>
                <Button
                  variant="contained"
                  type="button"
                  tabIndex="0"
                  style={{ marginLeft: "20px" }}
                  onClick={() => {
                    closeModal();
                  }}
                >
                  {t("Cancelar")}
                </Button>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default Form;
