import React from "react";
import { Popup } from "react-leaflet";
import "./style.scss";


const SubPathInformationPopup = ({title, children}) => {
    return (
        <Popup className="route-detour-popup">
            <div className="title">{title}</div>
            <hr />
            <div className="content">
                { children }
            </div>
      </Popup>
    )
}

export default SubPathInformationPopup