import React from "react";
import { Button, FormGroup, Grid } from "@material-ui/core";
import * as Yup from "yup";

import { Field, Formik } from "formik";
import { useTranslation } from "../../../lang";

const TechnicianForm = ({
  setIsOpen,
  createTechnician,
  selectedTechnician,
  updateTechnician,
}) => {
  const [t] = useTranslation();
  const initialValues = {
    id: selectedTechnician?.id,
    code: selectedTechnician?.code,
    firstname: selectedTechnician?.firstname,
    lastname: selectedTechnician?.lastname,
    email: selectedTechnician?.email,
    phoneNumber: selectedTechnician?.phoneNumber,
    mobilePhone: selectedTechnician?.mobilePhone,
  };

  const yupSchema = Yup.object().shape({
    firstname: Yup.string().required(t("Requerido")),
    lastname: Yup.string().required(t("Requerido")),
    phoneNumber: Yup.string().required(t("Requerido")),
    mobilePhone: Yup.string().required(t("Requerido")),
    email: Yup.string().email(t("Email invalido")),
  });

  return (
    <>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={yupSchema}
        onSubmit={async (values) => {
          if (selectedTechnician) {
            updateTechnician({
              variables: values,
            });
          } else {
            createTechnician({
              variables: values,
            });
          }
        }}
      >
        {(props) => {
          const { errors, handleSubmit } = props;

          return (
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <label className="formLabelFormik">{t("Código")}</label>
                <Field
                  type="text"
                  name="code"
                  tabIndex="0"
                  className={
                    errors.code
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.code && (
                  <div className="input-feedback">{errors.code}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">{t("Nombre")}</label>
                <Field
                  type="text"
                  name="firstname"
                  tabIndex="0"
                  className={
                    errors.firstname
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.firstname && (
                  <div className="input-feedback">{errors.firstname}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">{t("Apellido")}</label>
                <Field
                  type="text"
                  name="lastname"
                  tabIndex="0"
                  className={
                    errors.lastname
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.lastname && (
                  <div className="input-feedback">{errors.lastname}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">{t("Email")}</label>
                <Field
                  type="email"
                  name="email"
                  tabIndex="0"
                  className={
                    errors.email
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.email && (
                  <div className="input-feedback">{errors.email}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">{t("Teléfono")}</label>
                <Field
                  type="text"
                  name="phoneNumber"
                  tabIndex="0"
                  className={
                    errors.phoneNumber
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.phoneNumber && (
                  <div className="input-feedback">{errors.phoneNumber}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">{t("Teléfono Móvil")}</label>
                <Field
                  type="text"
                  name="mobilePhone"
                  tabIndex="0"
                  className={
                    errors.mobilePhone
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.mobilePhone && (
                  <div className="input-feedback">{errors.mobilePhone}</div>
                )}
              </FormGroup>

              <Grid item lg={12} className="footerSlidePanel">
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  tabIndex="0"
                >
                  {selectedTechnician ? t("Actualizar") : t("Crear")}
                </Button>
                <Button
                  variant="contained"
                  type="button"
                  tabIndex="0"
                  style={{ marginLeft: "20px" }}
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  {t("Cancelar")}
                </Button>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default TechnicianForm;
