import gql from "graphql-tag";

const CREATE_DRIVER_MUTATION = gql`
  mutation createDriver(
    $firstName: String!
    $lastName: String!
    $customerId: Int!
    $ibutton: String
    $phone: String
    $companyPhone: String
    $cellPhone: String
    $companyCellPhone: String
    $photo: Upload
    $suspended: Boolean
    $comments: String
  ) {
    createDriver(
      firstName: $firstName
      lastName: $lastName
      customerId: $customerId
      ibutton: $ibutton
      phone: $phone
      companyPhone: $companyPhone
      cellPhone: $cellPhone
      companyCellPhone: $companyCellPhone
      photo: $photo
      suspended: $suspended
      comments: $comments
    ) {
      id
    }
  }
`;

export default CREATE_DRIVER_MUTATION;
