import gql from "graphql-tag";

const VELOCITY_REPORT_QUERY = gql`
  query VelocityReport(
    $assetsSelected: [Int]!
    $fechaInicio: Datetime
    $fechaFin: Datetime
    $driversSelected: [Int]
    $velocidadDesde: Float
    $velocidadHasta: Float
    $days: [WeekDaysEnum]
  ) {
    velocityReport(
      assetsSelected: $assetsSelected
      fechaInicio: $fechaInicio
      fechaFin: $fechaFin
      driversSelected: $driversSelected
      velocidadDesde: $velocidadDesde
      velocidadHasta: $velocidadHasta
      days: $days
    ) {
      mid
      licensePlate
      assetName
      velMax
      velMaxMovil
      details {
        id
        fecha
        ubication
        lat
        lon
        vel
      }
    }
  }
`;

export default VELOCITY_REPORT_QUERY;
