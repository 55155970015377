import gql from "graphql-tag";

const ASOCIATE_LINES_MUTATION = gql`
  mutation AsociateLines(
    $linesAsociated: [Int]
    $linesDisAsociated: [Int]
    $deviceId: Int!
  ) {
    asociateLines(
      linesAsociated: $linesAsociated
      linesDisAsociated: $linesDisAsociated
      deviceId: $deviceId
    )
  }
`;

export default ASOCIATE_LINES_MUTATION;
