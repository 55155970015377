import gql from "graphql-tag";

const CLONE_DEVICE_MUTATION = gql`
  mutation CloneDevice(
    $sourceDeviceId: Int!
    $destinationDevices: [String]!
    $clonationType: String
    $eventsToCopy: [Int]
  ) {
    cloneDevice(
      sourceDeviceId: $sourceDeviceId
      destinationDevices: $destinationDevices
      clonationType: $clonationType
      eventsToCopy: $eventsToCopy
    )
  }
`;

export default CLONE_DEVICE_MUTATION;
