import React, { useState } from "react";
import RemoteTable from "../../../components/Base/RemoteTable";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Button, IconButton } from "@material-ui/core";

import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

import ThreeDotMenu from "../../../components/Base/ThreeDotMenu";
import ModalLineas from "./ModalLineas";
import ModalConfiguracionEvento from "./ModalConfiguracionEvento";
import DeviceForm from "./Form";
import ModalClonacionEquipos from "./ModalClonacionEquipos";

import ModalConfirmacion from "../../../components/Base/ModalConfirmacion";
import ReaxiumDeviceSyncButton from "../../../components/ReaxiumDeviceSyncButton";

import { format } from "date-fns";

import { useTranslation } from "../../../lang";
import { hasPermission } from "../../../utils/generalUtils";
import { connect } from "react-redux";
import IconExcel from "../../../public/iconExcel.png";
import IconPDF from "../../../public/iconPDF.png";
const List = ({
  deviceList,
  getDevices,
  deleteDevice,
  paginationSizes,
  total,
  setPaginationState,
  paginationState,
  createDevice,
  updateDevice,
  asociateLines,
  user,
  getDevicesExport,
}) => {
  const [t] = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState();
  const [textToSearch, setTextToSearch] = useState("");
  const [openModalLineas, setOpenModalLineas] = useState(false);
  const [openModalConfEvento, setOpenModalConfEvento] = useState(false);
  const [openModalClonacionEquipos, setOpenModalClonacionEquipos] = useState(
    false
  );

  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const closeModalConfirm = () => setIsOpenConfirm(false);
  const [confirmText, setConfirmText] = useState();
  const [idToDelete, setIdToDelete] = useState();

  React.useEffect(() => {
    getDevices();
  }, []);

  /*const confirmDelete = (id, name) => {
    confirmAlert({
      title: "Eliminar Equipo",
      message: `Está seguro de eliminar el equipo ${name}`,
      buttons: [
        {
          label: "Si",
          onClick: () => {
            deleteDevice({
              variables: {
                id: id,
              },
            });
          },
        },
        {
          label: "No",
          onClick: () => console.log("cancelar"),
        },
      ],
    });
  };*/

  const callbackSelectOption = (option, row) => {
    switch (option) {
      case 1:
        setSelectedDevice(row);
        setOpenModal(true);
        break;
      case 2:
        //confirmDelete(row.id, row.deviceIdentifier);
        setIsOpenConfirm(true);
        setConfirmText(
          t("pages_manage_devices_index_seguro_desea_eliminar_equipo", {
            deviceIdentifier: row.deviceIdentifier,
          })
        );
        setIdToDelete(row.id);
        break;
      case 3:
        setSelectedDevice(row);
        setOpenModalConfEvento(true);
        break;
      case 4:
        setSelectedDevice(row);
        setOpenModalClonacionEquipos(true);
        break;
      case 5:
        setSelectedDevice(row);
        setOpenModalLineas(true);
        break;
    }
  };

  const itemStyle = {
    fontSize: "12px",
  };

  const hightlightIfMatch = (text) => {
    if (
      text &&
      textToSearch &&
      text.toLocaleLowerCase().includes(textToSearch.toLocaleLowerCase())
    ) {
      const newText = text.replace(
        new RegExp(textToSearch, "gi"),
        (match) => `<mark>${match}</mark>`
      );
      return <div dangerouslySetInnerHTML={{ __html: newText }} />;
    } else setTextToSearch("");

    return text;
  };

  const columns = [
    {
      dataField: "id",
      text: "",
      headerStyle: () => ({ width: "50px", fontSize: "12px" }),
      formatter: (cell, row) => {
        return (
          <>
            <ThreeDotMenu
              key={row.id}
              row={row}
              callback={callbackSelectOption}
              options={[
                {
                  label: `${t("Editar")}`,
                  value: 1,
                },
                {
                  label: `${t("Configuracion Evento")}`,
                  value: 3,
                },
                {
                  label: `${t("Clonar")}`,
                  value: 4,
                },
                {
                  label: `${t("Líneas")}`,
                  value: 5,
                },
                {
                  label: `${t("Eliminar")}`,
                  value: 2,
                },
              ]}
            />
          </>
        );
      },
    },
    {
      dataField: "deviceIdentifier",
      text: t("Identificador"),
      headerStyle: () => ({ width: "10%", fontSize: "12px" }),
      style: () => itemStyle,
      formatter: hightlightIfMatch,
      sort: true,
    },
    {
      dataField: "deviceType.description",
      text: t("Tipo Equipo"),
      headerStyle: () => ({ width: "20%", fontSize: "12px" }),
      style: () => itemStyle,
      formatter: hightlightIfMatch,
      //sort: true,
    },
    {
      dataField: "model",
      text: t("Modelo"),
      headerStyle: () => ({ width: "10%", fontSize: "12px" }),
      style: () => itemStyle,
      formatter: hightlightIfMatch,
      sort: true,
    },
    {
      dataField: "serialNumber",
      text: t("Nro Serie"),
      headerStyle: () => ({ width: "15%", fontSize: "12px" }),
      style: () => itemStyle,
      formatter: hightlightIfMatch,
      sort: true,
    },
    {
      dataField: "line",
      text: t("Nro Telefono"),
      headerStyle: () => ({ width: "15%", fontSize: "12px" }),
      style: () => itemStyle,
      formatter: (cell, row) => {
        if (Array.isArray(row.line)) {
          return hightlightIfMatch(row.line.map((l) => l.lineNumber).join(","));
        }

        return null;
      },
      //sort: true,
    },
    {
      dataField: "imei",
      text: t("Imei"),
      headerStyle: () => ({ width: "10%", fontSize: "12px" }),
      style: () => itemStyle,
      formatter: hightlightIfMatch,
      sort: true,
    },
    {
      dataField: "createdDate",
      text: t("Fecha Alta"),
      headerStyle: () => ({ width: "10%", fontSize: "12px" }),
      style: () => itemStyle,
      formatter: (cell, row) => {
        if (row.createdDate) {
          const date = format(new Date(row.createdDate), "yyyy-MM-dd HH:mm:ss");
          return hightlightIfMatch(date);
        }

        return "";
      },
      sort: true,
    },
  ];

  const customTotal = (from, to, size) => (
    <span
      className="react-bootstrap-table-pagination-total customTotalDisplay"
      style={{ marginLeft: "15px" }}
    >
      Mostrando {from} de {to} de {size} Resultados
    </span>
  );

  const options = {
    // Tooltips botones de paginación
    prePageTitle: t("pagina previa"),
    nextPageTitle: t("próxima página"),
    firstPageTitle: t("primer página"),
    lastPageTitle: t("última página"),
    showTotal: true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: paginationSizes,
    totalSize: total,
  };

  const onSearch = (searchText) => {
    setTextToSearch(searchText);
    setPaginationState({
      ...paginationState,
      searchText: searchText,
    });
  };

  const onPaginate = (page, sizePerPage) => {
    setPaginationState({
      ...paginationState,
      pageNumber: page,
      pageSize: sizePerPage,
    });
  };

  const onSort = (sortObj) => {
    setPaginationState({
      ...paginationState,
      sort: sortObj,
    });
  };

  return (
    <>
      <ModalConfirmacion
        isOpen={isOpenConfirm}
        buttonConfirmation={t("Confirmar eliminación")}
        closeModal={closeModalConfirm}
        confirmationText={confirmText}
        handleConfirm={() => {
          deleteDevice({
            variables: {
              id: idToDelete,
            },
          });
        }}
        title={t("IMPORTANTE")}
      />

      <ModalClonacionEquipos
        isOpen={openModalClonacionEquipos}
        closeModal={() => setOpenModalClonacionEquipos(false)}
        deviceId={selectedDevice?.id}
      />

      <ModalLineas
        isOpen={openModalLineas}
        closeModal={() => setOpenModalLineas(false)}
        asociateLines={asociateLines}
        ownLines={selectedDevice?.line}
        deviceId={selectedDevice?.id}
      />
      <ModalConfiguracionEvento
        isOpen={openModalConfEvento}
        closeModal={() => setOpenModalConfEvento(false)}
        deviceId={selectedDevice?.id}
        deviceIdentifier={selectedDevice?.deviceIdentifier}
      />
      <div className="listContainer">
        <SlidingPane
          className="slidingPaneRight"
          isOpen={openModal}
          title={selectedDevice ? t("Actualizar Equipo") : t("Crear Equipo")}
          width="400px"
          onRequestClose={() => {
            setOpenModal(false);
          }}
        >
          <DeviceForm
            setIsOpen={() => setOpenModal(false)}
            createDevice={createDevice}
            selectedDevice={selectedDevice}
            updateDevice={updateDevice}
          />
        </SlidingPane>

        <RemoteTable
          columns={columns}
          data={deviceList}
          keyField="id"
          paginationOptions={options}
          onPaginate={onPaginate}
          onSearch={onSearch}
          onSort={onSort}
          total={total}
          lateralComponent={
            <div className="button-container">
              <IconButton
                title={t("EXCEL")}
                onClick={() => getDevicesExport("EXCEL")}
              >
                <img src={IconExcel} className="exportIcon" />
              </IconButton>
              <IconButton
                title={t("PDF")}
                onClick={() => getDevicesExport("PDF")}
              >
                <img src={IconPDF} className="exportIcon" />
              </IconButton>

              {hasPermission("ReaxiumDeviceSync", user.roles) && (
                <ReaxiumDeviceSyncButton />
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setSelectedDevice(null);
                  setOpenModal(true);
                }}
                style={{ float: "right" }}
              >
                + {t("Equipo")}
              </Button>
            </div>
          }
        />
      </div>
    </>
  );
};

const _mapStateToProps = ({ user }) => {
  return {
    user,
  };
};

export default connect(_mapStateToProps, null)(List);
