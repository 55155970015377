import Leaflet from "leaflet";
import uniq from "lodash/uniq";
import isEmpty from "lodash/isEmpty";
import classNames from "classnames";
import _ from "lodash";

import ClusterIcon from "../components/Map/resources/clusterMarkerSvg";

const ICON_SIZE = 80;
const ANCHOR_POSITION_X = 40;
const ANCHOR_POSITION_Y = 75;
const POPUP_ANCHOR_POSITION = -45; // ICON_SIZE / 2 + 5
const IconSettings = {
  //  shadowUrl: require('../public/marker-shadow.png'),
  iconSize: [ICON_SIZE, ICON_SIZE], // size of the icon
  // shadowSize:   [50, 64], // size of the shadow
  iconAnchor: [ANCHOR_POSITION_X, ANCHOR_POSITION_Y], // point of the icon which will correspond to marker's location
  // shadowAnchor: [4, 62],  // the same for the shadow
  popupAnchor: [0, POPUP_ANCHOR_POSITION], // point from which the popup should open relative to the iconAnchor
};

function getVariants(markers) {
  let existingVariants = [];
  for (var i = 0, n = markers.length; i < n; ++i) {
    const m = markers[i];

    const markerClass = m?.options?.icon?.options?.className;

    if (markerClass) {
      const arrayClass = markerClass.split("--");
      if (arrayClass && arrayClass.length > 0)
        existingVariants.push(arrayClass[1]);
    }
  }
  return existingVariants;
}

const createClusterCustomIcon = (cluster) => {
  const markers = cluster.getAllChildMarkers();
  // let existingVariants = [];

  // markers.map((m) => {
  //   // console.log("markers en la función", m);
  //   const markerClass = m?.options?.icon?.options?.className;
  //   if (markerClass) {
  //     const arrayClass = markerClass.split("--");
  //     if (arrayClass && arrayClass.length > 0)
  //       existingVariants.push(arrayClass[1]);
  //   }
  // });

  // for (var i = 0, n = markers.length; i < n; ++i) {
  //   const m = markers[i];

  //   const markerClass = m?.options?.icon?.options?.className;
  //   if (markerClass) {
  //     const arrayClass = markerClass.split("--");
  //     if (arrayClass && arrayClass.length > 0)
  //       existingVariants.push(arrayClass[1]);
  //   }
  // }

  const getExistingVariants = _.memoize(getVariants);

  let existingVariants = ["far-away-badge"];
  if (markers && markers.length <= 50) {
    existingVariants = getExistingVariants(markers);
    existingVariants = uniq(existingVariants);
  }

  const markerClassNames = classNames("marker-cluster", {
    [`marker-cluster--colors-${existingVariants.sort().join("-")}`]: !isEmpty(
      existingVariants
    ),
  });

  const icon = Leaflet.divIcon({
    className: markerClassNames,
    ...IconSettings,
    html: `
            ${ClusterIcon}
            <div class="marker-cluster__count">
                ${cluster.getChildCount()}
            </div>
        `,
  });

  return icon;
};

// export default _.memoize(createClusterCustomIcon);
export default createClusterCustomIcon;
