import gql from "graphql-tag";
//

const SERVICE_BYASSETS_QUERY = gql`
  query Service($assetIds: [Int], $groupIDs: [Int]) {
    service(assetIds: $assetIds, groupIDs: $groupIDs) {
      id
      asset {
        id
        name
        licensePlate
      }
      serviceType {
        id
        description
      }
      frequency {
        id
        description
      }
      quantityKm
      percentageAlert
      emailsList
      warningDate
      warningKm
      dueDate
      actualOdo
      dueOdometer
      users {
        id
      }
    }
  }
`;

export default SERVICE_BYASSETS_QUERY;
