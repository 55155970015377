import React from "react";
import "./Loading.scss";

const Loading = ({ working }) => {
  return (
    <div
      className="cover-spin"
      style={{ display: working ? "block" : "none" }}
    />
  );
};

export default Loading;
