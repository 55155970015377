/**
 *  @param   {string}     localDateTimeString local time in ISO string format.
 *  @return  {string}     Date time on UTC-0 as ISO string format.
 */
export const localDateTimeStringToUTC = (localDateTimeString) => {
  const localDateTime = new Date(localDateTimeString);
  const utcDateTimeString = localDateTime.toISOString();
  return utcDateTimeString;
};

// Convert string date in Date object without UTC offset
export const stringDateToDateWithoutUTC = (dateString) => {
  const tempDate = new Date(dateString);
  const userTimezoneOffset = tempDate.getTimezoneOffset() * 60000;
  return new Date(tempDate.getTime() - userTimezoneOffset);
};
