import React, { useEffect } from "react";

import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { connect } from "react-redux";
import ChangeProfileInformationForm from "./Form";
import { useTranslation } from "../../lang";
import { setCurrentUser } from "../../redux/user/actions";

const theme = createMuiTheme({});

const ChangeProfileInformationModal = ({
  isOpen,
  closeModal,
  user,
  setCurrentUserAction,
}) => {
  const [t] = useTranslation();

  useEffect(() => {
    if (isOpen) {
      let newUser = JSON.parse(localStorage.getItem("loggedUser"));
      newUser.firstName = user.firstName;
      newUser.lastName = user.lastName;
      newUser.photo = user.photo;

      localStorage.setItem("loggedUser", JSON.stringify(newUser));
    }
  }, [user, isOpen]);

  const handleProfileInformationUpdated = (userProfile) => {
    const updatedUser = {
      ...user,
      ...userProfile,
    };

    setCurrentUserAction(updatedUser);
  };
  return (
    <>
      <ThemeProvider theme={theme}>
        <Dialog open={isOpen} onClose={closeModal} maxWidth="sm" fullWidth>
          <DialogTitle className="dialogTitle">
            {t("Cambiar informacion de perfil")}
          </DialogTitle>
          <DialogContent>
            <ChangeProfileInformationForm
              closeModal={closeModal}
              onUpdated={handleProfileInformationUpdated}
              user={user}
            />
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

const _mapStateToProps = ({ user }) => {
  return {
    user,
  };
};
const _mapDispatchToProps = (dispatch) => {
  return {
    setCurrentUserAction: (user) => dispatch(setCurrentUser({ ...user })),
  };
};

export default connect(
  _mapStateToProps,
  _mapDispatchToProps
)(ChangeProfileInformationModal);
