import React, { useEffect, useRef, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import L from "leaflet";
import "leaflet-measure/dist/leaflet-measure.css";
import "leaflet/dist/leaflet.css";
import _ from "lodash";
import PropTypes from "prop-types";
import { MapContainer as MapL, ZoomControl } from "react-leaflet";
import availableFunctions from "../../enums/functions";
import SearchBarTypes from "../../enums/searchBarTypes";
import GET_GEOFENCE_QUERY from "../../graphql/queries/getGeofencesQuery";
import GET_ALL_ASSETS_IN_RADIUS_NEAR_POINT_QUERY from "../../graphql/queries/getAllAssestsInRadiusNearPointQuery";
import GET_MARKERSBYGROUP_QUERY from "../../graphql/queries/pointsByGroups";
import GET_MARKERS_SUBSCRIPTION from "../../graphql/subscriptions/markersSubscription";
import { markersSelector } from "../../redux/markers/selector";
import clientSubscription from "../Base/apiApolloClientSubscription";
import useInterval from "../Base/useInterval";
import BaseLayersControl from "./BaseLayersControlV3";
import "./BaseMap.scss";
import ChartContainer from "./JourneyComponents/ChartContainer";
import getTrackPlayerInstance from "./JourneyComponents/CustomTrackPlayer";
import MarkersGroup from "./MarkersGroupV3";
import MarkersJourneys from "./MarkersJourneys";
import NavigationMarker from "./NavigationMarker";
import { useParams } from "react-router";
import GET_MARKERS_SUBSCRIPTION_BY_ASSETID from "../../graphql/subscriptions/markersByAssetIdSubscription";
import GET_POINT_QUERY from "../../graphql/queries/getPointQuery";
import { AVAILABLE_LANGS, useTranslation } from "../../lang";
import AssetsInRadiusNearPoint from "./AssetsInAreaSearcher/AssetsInRadiusNearPoint";
import "./leaflet-openweathermap";
import "./leaflet-openweathermap.css";
import AssetInRadiusNearPointSearchModalWindow from "./AssetsInAreaSearcher/AssetInRadiusNearPointSearchModal";
import {
  DISTANCE_UNITS,
  convertDistanceUnit,
} from "./AssetsInAreaSearcher/DistanceUnit";
import { getRadiusDefaultValueByDistanceUnit } from "./AssetsInAreaSearcher/AssetInAreaUtils";
import { useHistory, useLocation } from "react-router-dom";

const AVAILABLE_UPPER_RIGHT_CONTROLS = {
  WEATHER: "WEATHER",
  SEARCH_IN_AREA: "SEARCH_IN_AREA",
};

const upperRightCornerControlPanel = {
  [AVAILABLE_UPPER_RIGHT_CONTROLS.WEATHER]: {
    position: 0,
    control: null,
    container: null,
  },
  [AVAILABLE_UPPER_RIGHT_CONTROLS.SEARCH_IN_AREA]: {
    position: 1,
    control: null,
    container: null,
  },
};

const reRenderUpperRigthCornerControlPanel = (mapInstance, controls) => {
  for (const [key, controlWrapper] of Object.entries(controls)) {
    if (controlWrapper.control) {
      controlWrapper.control.remove();
      mapInstance.addControl(controlWrapper.control);
    }
  }
};

const addUpperRigthCornerControl = (key, control, mapInstance) => {
  if (!(key in AVAILABLE_UPPER_RIGHT_CONTROLS)) return;

  if (upperRightCornerControlPanel[key].control) {
    upperRightCornerControlPanel[key].control.remove();
  }

  upperRightCornerControlPanel[key].control = control;

  reRenderUpperRigthCornerControlPanel(
    mapInstance,
    upperRightCornerControlPanel
  );
};

let showTrace = false;
let showJourney = false;
let showWeather = false;
let isSearchInAreaEnabled = false;
let weatherControl;
let searchInAreaControl;

const measureOptions = {
  position: "bottomright",
  primaryLengthUnit: "primaryLengthUnit", // "meters",
  secondaryLengthUnit: "secondaryLengthUnit", // "kilometers",
  primaryAreaUnit: "primaryAreaUnit", // "sqmeters",
  secondaryAreaUnit: "secondaryAreaUnit", // "acres",
  activeColor: "#db4a29",
  completedColor: "#9b2d14",
};

let mapInstance = null;

const MAX_ZOOM = process.env.REACT_APP_SHOW_PERU_MAPS === "True" ? 22 : 20;
const INITIAL_ZOOM = 6;

const getDistanceUnitBySelectedLanguage = (selectedLanguage) => {
  const unitByLanguage = {
    US: { unit: DISTANCE_UNITS.MILE, symbol: "mil" },
    ES: { unit: DISTANCE_UNITS.METER, symbol: "mts" },
  };
  return unitByLanguage[selectedLanguage] || DISTANCE_UNITS.METER;
};

const BaseMap = (props, ownProps) => {
  const location = useLocation();
  const history = useHistory();

  const { mapId, user, selectedCustomers } = props;
  const { t, i18n } = useTranslation();

  if (i18n.language === AVAILABLE_LANGS.EN_US) {
    require("leaflet-measure/dist/leaflet-measure.en");
  } else {
    require("leaflet-measure/dist/leaflet-measure.es");
  }

  let { assetId } = useParams();

  if (mapId) {
    assetId = mapId;
  }

  const [showJourneyWithoutPanel, setShowJourneyWithoutPanel] = useState(false);
  const [zoom, setZoom] = useState(INITIAL_ZOOM);
  const [maxZoom, setMaxZoom] = useState(MAX_ZOOM);
  const [markers, setMarkers] = useState([]);
  const [journeys, setJourneys] = useState({});
  const [showJourneys, setShowJourneys] = useState(false);
  const [showGeofences, setShowGeofences] = useState(false);
  const [
    shouldDisplayNavigationMarkers,
    setShouldDisplayNavigationMarkers,
  ] = useState(true);

  const [markerRefs, setMarkerRefs] = useState([]);
  const [firstRunQuery, setFirstRunQuery] = useState(true);

  const initialCenter = process.env.REACT_APP_DEFAULT_CENTER_MAP.split(",");
  // const [center, setCenter] = useState(initialCenter);

  const [playbackControl, setPlaybackControl] = useState();
  const [showLayerControl, setShowLayerControl] = useState(false);
  const [layerName, setLayerName] = useState(
    process.env.REACT_APP_SHOW_PERU_MAPS === "True"
      ? "Street Maps"
      : "Default View"
  );

  const [resultState, setResultState] = useState({
    isOpen: false,
    type: "success",
    msg: "",
  });

  // test -- borrar: guardo los markers que se actualizaron la última vez
  const [updatedMarkers, setUpdatedMarkers] = useState();

  const weatherLayer = useRef();
  const refWeatherControl = useRef();
  const appId = process.env.REACT_APP_WEATHER_LAYER_KEY;

  // const searchInAreaLayer = useRef();
  const refSearchInAreaControl = useRef();

  let lastPosition = [];

  // const handleAddMArkerRef = (marker, key) => {
  //   const index = markerRefs.findIndex((m) => m.key === key);
  //   if (index === -1) {
  //     setMarkerRefs([...markerRefs, { key: key, marker: marker }]);
  //   }
  // };

  const [bounds, setBounds] = useState();

  const DEFAULT_ASSET_IN_RADIUS_CENTER = [
    parseFloat(initialCenter[0]),
    parseFloat(initialCenter[1]),
  ];

  // const DEFAUL_IS_SEARCH_IN_AREA_ENABLED = false;

  const setIsSearchInAreaEnabled = (newValue) => {
    isSearchInAreaEnabled = newValue;
  };

  const toggleSearchInArea = (mapInstance) => {
    setIsSearchInAreaEnabled(!isSearchInAreaEnabled);
    const center = mapInstance.getCenter();
    setAssetInRadiusCenter([center.lat, center.lng]);
  };

  const [assetInRadiusCenter, setAssetInRadiusCenter] = useState(
    DEFAULT_ASSET_IN_RADIUS_CENTER
  );

  const [assetInRadiusDistanceUnit, setAssetInRadiusDistanceUnit] = useState(
    getDistanceUnitBySelectedLanguage(user.language)
  );

  const [assetInRadiusRadius, setAssetInRadiusRadius] = useState(
    getRadiusDefaultValueByDistanceUnit(assetInRadiusDistanceUnit)
  );

  const [
    isOpenSearchAssetsInRadiusPanel,
    setIsOpenSearchAssetsInRadiusPanel,
  ] = useState(false);

  const [
    assetInRadiusNearPointResult,
    setAssetInRadiusNearPointResult,
  ] = useState([]);

  useEffect(() => {
    const distanceUnit = getDistanceUnitBySelectedLanguage(user.language);
    setAssetInRadiusDistanceUnit(distanceUnit);
  }, [user.language]);
  // const mapRef = React.useRef();

  // function usePrevious(value) {
  //   const ref = useRef();
  //   useEffect(() => {
  //     ref.current = value;
  //   });
  //   return ref.current;
  // }

  // const prevPc = usePrevious(playbackControl);

  const handleTrackPlayerInstanceReady = () => {
    upperRightCornerControlPanel[
      AVAILABLE_UPPER_RIGHT_CONTROLS.SEARCH_IN_AREA
    ].container.dispatchEvent(new Event("hide-button"));
    upperRightCornerControlPanel[
      AVAILABLE_UPPER_RIGHT_CONTROLS.WEATHER
    ].container.dispatchEvent(new Event("hide-button"));
  };
  const handleTrackPlayerInstanceRemove = () => {
    if (
      upperRightCornerControlPanel[
        AVAILABLE_UPPER_RIGHT_CONTROLS.SEARCH_IN_AREA
      ]?.container
    ) {
      upperRightCornerControlPanel[
        AVAILABLE_UPPER_RIGHT_CONTROLS.SEARCH_IN_AREA
      ].container.dispatchEvent(new Event("display-button"));
    }

    if (
      upperRightCornerControlPanel[AVAILABLE_UPPER_RIGHT_CONTROLS.WEATHER]
        ?.container
    ) {
      upperRightCornerControlPanel[
        AVAILABLE_UPPER_RIGHT_CONTROLS.WEATHER
      ].container.dispatchEvent(new Event("display-button"));
    }

    showJourney = false;
    setShowJourneyWithoutPanel(false);
  };

  const handleTrackPlayerInstanceClosePanel = () => {
    props.closePanel();
    handleTrackPlayerInstanceRemove();
  };

  const prevMarkers2 = useRef(new Map());
  const { isOpen, toggleFillGenericPopup, popupInfo } = props;
  showJourney = showJourneys;
  if (!isOpen) {
    showJourney = false;
  }

  const [fetchGeofencesSearchBar] = useLazyQuery(GET_GEOFENCE_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      const geofences = d.geofence.items.filter(
        (g) => g.showOnMap && g.geomJson
      );
      if (Array.isArray(geofences) && geofences.length > 0) {
        props.addSearchOptions &&
          props.addSearchOptions(
            geofences.map((g) => {
              return {
                type: SearchBarTypes.GEOFENCES,
                value: g.geomJson,
                label: g.name,
              };
            })
          );
      }
    },
  });

  const [fetchAssetsInRadiusNearPoint] = useLazyQuery(
    GET_ALL_ASSETS_IN_RADIUS_NEAR_POINT_QUERY,
    {
      fetchPolicy: "network-only",
      onCompleted(d) {
        setAssetInRadiusNearPointResult(d.getAllAssetsInRadiusNearPoint);
      },
    }
  );

  const [fetchGeofences] = useLazyQuery(GET_GEOFENCE_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      //setTotal(d.geofence.count);
      //setGeofenceList(d.geofence.items);
      const geofences = d.geofence.items.filter(
        (g) => g.showOnMap && g.geomJson
      );
      if (Array.isArray(geofences) && geofences.length > 0) {
        setResultState({
          isOpen: true,
          type: "success",
          msg: t("Geocercas agregadas correctamente"),
        });
        geofences.forEach((g) => {
          const geoJsonLayer = L.geoJSON(JSON.parse(g.geomJson));
          geoJsonLayer.bindTooltip(() => g.name);
          mapInstance.addLayer(geoJsonLayer);
        });
      } else {
        setResultState({
          isOpen: true,
          type: "warning",
          msg: t("El cliente seleccionado no tiene geocercas"),
        });
      }
    },
  });

  const successNewPointCallback = (data) => {
    if (data.data.newPoint /*&& !showJourney*/) {
      const point = markersSelector({
        markers: [data.data.newPoint],
        t,
      });

      const prev = new Map(prevMarkers2.current);
      prev.set(point[0].mid, point[0]);

      prevMarkers2.current = prev;
    }
  };

  const sanitizePoints = _.memoize((markers) => {
    const pointsMarkers = markersSelector({ markers: markers });

    setMarkers(pointsMarkers);
  });

  const manageWeatherControl = (_zoom) => {
    if (mapInstance) {
      var button = document.getElementsByClassName(
        "leaflet-control-weather leaflet-control"
      );
      if (!button[0]) {
        var button = document.getElementsByClassName(
          "leaflet-draw-draw-marker draw-control-disabled"
        );
      }

      if (button && button[0]) {
        if (_zoom < 9) {
          // disable button
          button[0].onclick = () => {
            return false;
          };
          button[0].title = t(
            "Deshabilitado Por favor, aumente el nivel de zoom"
          );
          button[0].style["background-color"] = "#D3D3D3";
          button[0].style["cursor"] = "not-allowed";
        } else {
          button[0].onclick = () => {
            changeWeatherControl(refWeatherControl.current, !showWeather);
            showWeather = !showWeather;
          };
          button[0].title = showWeather
            ? t("Quitar Tiempo")
            : t("Mostrar Tiempo");
          button[0].style["background-color"] = showWeather ? "green" : "white";
          button[0].style["cursor"] = "pointer";
        }
      }
    }
  };

  useEffect(() => {
    if (mapInstance) manageWeatherControl(mapInstance.getZoom());
  }, [mapInstance]);

  const manageSearchInAreaControl = (_zoom) => {
    if (mapInstance) {
      var button = document.getElementsByClassName(
        "leaflet-control-searchinarea leaflet-control"
      );
      if (!button[0]) {
        var button = document.getElementsByClassName(
          "leaflet-draw-draw-marker draw-control-disabled"
        );
      }

      if (button && button[0]) {
        if (_zoom < 9) {
          // disable button
          button[0].onclick = () => {
            return false;
          };
          button[0].title = t(
            "Deshabilitado Por favor, aumente el nivel de zoom"
          );
          button[0].style["background-color"] = "#D3D3D3";
          button[0].style["cursor"] = "not-allowed";
        } else {
          button[0].onclick = () => {
            toggleSearchInArea(mapInstance);
            changeSearchInAreaControl(
              refSearchInAreaControl.current,
              isSearchInAreaEnabled
            );
          };
          button[0].title = isSearchInAreaEnabled
            ? t("Quitar busqueda en area")
            : t("Mostrar busqueda en area");
          button[0].style["background-color"] = isSearchInAreaEnabled
            ? "green"
            : "white";
          button[0].style["cursor"] = "pointer";
        }
      }
    }
  };

  useEffect(() => {
    if (mapInstance) manageSearchInAreaControl(mapInstance.getZoom());
  }, [mapInstance]);

  useEffect(() => {
    if (
      firstRunQuery &&
      Array.isArray(markers) &&
      markers.length > 0 &&
      mapInstance
    ) {
      if (!assetId) {
        // && mapId !== 0
        // console.log("entró zoom 1");
        try {
          // var bounds = _getLatLngBounds(props.markerSelected);
          var bounds = _getLatLngBounds(markers);
          setBounds(bounds);
          var zoomB = mapInstance.getBoundsZoom(bounds);
          //var newCenter = mapInstance.getCenter();
          var newCenter = bounds.getCenter();
          mapInstance.setView([newCenter.lat, newCenter.lng], zoomB);
          console.log(`zoomB: ${zoomB}`);
        } catch (error) {
          console.log("Error in BaseMap: ", error);
        }
        setFirstRunQuery(false);
      } else {
        // console.log("entró zoom 2");
        // console.log("assetId", assetId);
        // console.log("mapId", mapId);
        // console.log("markers", markers);
        if (!mapId)
          mapInstance.setView(
            [markers[0].pos.lat, markers[0].pos.lng],
            MAX_ZOOM
          );
        // else {
        //   var bounds = _getLatLngBounds(markers);
        //   setBounds(bounds);
        //   var zoomB = mapInstance.getBoundsZoom(bounds);
        //   console.log("zoomB", zoomB);
        //   var newCenter = mapInstance.getCenter();
        //   mapInstance.setView([newCenter.lat, newCenter.lng], MAX_ZOOM);
        // }
      }
    } else {
    }
  }, [markers]);

  useEffect(() => {
    //setBounds(_getLatLngBounds(props.markerSelected));
    // console.log("Zoom marker selected useEffect");
    centerOnPoint(props.markerSelected);
  }, [props.markerSelected]);

  const centerOnPoint = (movilID) => {
    const marker = markers.filter((x) => x.mid === movilID)[0];
    if (marker) {
      const { lng, lat } = marker.pos;
      //setShouldGroup(false);
      mapInstance.setView([lat, lng], MAX_ZOOM);
    }
  };

  useEffect(() => {
    if ((!showJourney || !showJourneyWithoutPanel) && mapInstance && !isOpen) {
      // console.log("Zoom showJourney useEffect");
      mapInstance.setView(mapInstance.getCenter(), INITIAL_ZOOM);
      if (playbackControl) mapInstance.removeControl(playbackControl);
    }
  }, [showJourney, showJourneyWithoutPanel, isOpen]);

  const [actualPoint, setActualPoint] = useState();

  const playbackTickHandler = (pointId) => {
    setActualPoint(pointId);
  };

  // Handle JourneyReport Panel after redirect
  const [
    shouldOpenRoutesReportPanelOnRedirect,
    setShouldOpenRoutesReportPanelOnRedirect,
  ] = useState(false);

  useEffect(() => {
    if (
      location.state &&
      location.state.journeyData &&
      location.state.lateralPanel &&
      location.state.lateralPanel.panelType === "JourneyReport" &&
      mapInstance
    ) {
      setFirstRunQuery(false);
      setShowJourneyWithoutPanel(true);
      props.updateMarkerJourney(location.state.journeyData);
      setShouldOpenRoutesReportPanelOnRedirect(true);
    }
  }, [mapInstance]);

  useEffect(() => {
    if (
      shouldOpenRoutesReportPanelOnRedirect &&
      location.state &&
      location.state.lateralPanel &&
      location.state.lateralPanel.panelType === "JourneyReport" &&
      markers.length > 0
    ) {
      props.openPanel();
      props.changePanelType(location.state.lateralPanel.panelType);

      props.setJourneyReportPanelInitialStatus(
        location.state.lateralPanel.panelInitialStatus || {}
      );

      setShouldOpenRoutesReportPanelOnRedirect(false);
      history.replace({
        state: {
          ...location.state,
          lateralPanel: null,
          journeyData: null,
        },
      });
    }
  }, [shouldOpenRoutesReportPanelOnRedirect, markers]);

  // Handle FleetStatus Panel after redirect
  const [
    shouldOpenFleetStatusPanelOnRedirect,
    setShouldOpenFleetStatusPanelOnRedirect,
  ] = useState(false);

  useEffect(() => {
    if (
      location.state &&
      location.state.lateralPanel &&
      location.state.lateralPanel.panelType === "GeneralInfo" &&
      location.state.markerInfo &&
      mapInstance
    ) {
      setFirstRunQuery(false);
      setShowJourneyWithoutPanel(true);
      props.updateMarkerJourney(location.state.journeyData);
      setShouldOpenFleetStatusPanelOnRedirect(true);
    }
  }, [mapInstance]);

  useEffect(() => {
    if (
      shouldOpenFleetStatusPanelOnRedirect &&
      location.state &&
      location.state.lateralPanel &&
      location.state.lateralPanel.panelType === "GeneralInfo"
    ) {
      setShouldDisplayNavigationMarkers(false);
      props.openPanel();
      props.changePanelType(location.state.lateralPanel.panelType);

      props.toggleFillMarkerInfo({ markerInfo: location.state.markerInfo });
      props.setFleetStatusPanelInitialStatus(
        location.state.lateralPanel.panelInitialStatus || {}
      );
      setShouldOpenFleetStatusPanelOnRedirect(false);
      history.replace({
        state: {
          ...location.state,
          lateralPanel: null,
          journeyData: null,
          markerInfo: null,
        },
      });
    }
  }, [shouldOpenFleetStatusPanelOnRedirect, markers]);

  useEffect(() => {
    if (!showJourney && !showJourneyWithoutPanel) {
      setJourneys({});
    }
  }, [showJourney, showJourneyWithoutPanel]);

  useEffect(() => {
    if (
      (showJourney || showJourneyWithoutPanel) &&
      mapInstance &&
      journeys &&
      journeys.daySteps
    ) {
      // // hide weather control
      // mapInstance &&
      //   mapInstance.weatherControl &&
      //   mapInstance.removeControl(mapInstance.weatherControl);

      let track = [];
      let dayStep;
      /*journeys.daySteps.forEach((ds) => {
        console.log("Day step: ", ds);
        ds.steps.forEach((s) => {
          track = [
            ...track,
            ...s.listPos.map((lp) => ({
              lat: lp.lat,
              lng: lp.lon,
              dir: lp.course,
              time: new Date(lp.fGPS).getTime() / 1000,
              info: [{ key: "id", value: lp.id }]
            })),
          ];
        });
      });*/

      if (journeys.daySteps.length > 1) {
        dayStep = journeys.daySteps.filter((ds) => ds.visible);
        if (dayStep.length === 0) dayStep = journeys.daySteps[0];
        if (dayStep.length > 0) dayStep = dayStep[0];
      } else {
        dayStep = journeys.daySteps[0];
      }

      dayStep &&
        dayStep.steps.forEach((s) => {
          track = [
            ...track,
            ...s.listPos.map((lp) => ({
              lat: lp.lat,
              lng: lp.lon,
              dir: lp.course,
              time: new Date(lp.fGPS).getTime() / 1000,
              info: [{ key: "id", value: lp.id }],
            })),
          ];
        });

      if (playbackControl) {
        mapInstance.removeControl(playbackControl);
        setPlaybackControl(null);
      }

      const pc = getTrackPlayerInstance(
        mapInstance,
        track,
        playbackTickHandler,
        handleTrackPlayerInstanceClosePanel,
        props.updateMarkerJourney,
        handleTrackPlayerInstanceReady,
        handleTrackPlayerInstanceRemove
      );
      if (pc) {
        setPlaybackControl(pc);
        pc.addTo(mapInstance);
      }
    }
  }, [showJourney, showJourneyWithoutPanel, journeys]);

  // useEffect(() => {
  //   if (mapInstance && !weatherControl) {
  //     console.log("actualizando icono");
  //     if (!assetId && appId) {
  //       weatherControl = createWeatherControl(t("Mostrar tiempo"), "white");

  //       mapInstance.addControl(new weatherControl());
  //       setShowLayerControl(true);
  //     }
  //   }
  // }, [showJourney, journeys, mapInstance]);

  const subscribeToPoints = (groupsIDs) => {
    let gIds = groupsIDs;
    if (!groupsIDs) {
      const user = JSON.parse(localStorage.getItem("loggedUser"));
      if (user) {
        const dataLS = localStorage.getItem(`groupsSelected_${user.id}`);
        if (dataLS) {
          gIds = dataLS.split(",").filter((g) => g && typeof g === "number");
        }
      }
    }

    let subscription = null;

    if (gIds) {
      const observer = clientSubscription.subscribe({
        query: GET_MARKERS_SUBSCRIPTION,
        variables: {
          groupsIDs: gIds,
        },
      });

      subscription = observer.subscribe({
        next(res) {
          successNewPointCallback(res);
        },
        error(err) {
          console.error("err", err);
        },
      });
    }

    return subscription;
  };

  const subscribeToPointsByAssetId = (assetId) => {
    let subscription = null;

    if (+assetId > 0) {
      const observer = clientSubscription.subscribe({
        query: GET_MARKERS_SUBSCRIPTION_BY_ASSETID,
        variables: {
          assetId: +assetId,
        },
      });

      subscription = observer.subscribe({
        next(res) {
          const adapted = {
            data: { newPoint: res.data.newPointByAssetId },
          };

          successNewPointCallback(adapted);

          // console.log("Nueva posicion", adapted);
          // console.log("ShowTrace", showTrace);

          // Logic to trace journey
          if (lastPosition.length > 0 && showTrace) {
            try {
              const trackCoords = [
                lastPosition,
                [adapted.data.newPoint.lat, adapted.data.newPoint.lon],
              ];
              const track = L.polyline(trackCoords);
              mapInstance.addLayer(track);
            } catch (e) {
              console.log("Error in trace", e);
            }
          }
          lastPosition = [adapted.data.newPoint.lat, adapted.data.newPoint.lon];
          // End logic to tracer journey

          // comentado por tavo
          // mapInstance.setView(
          //   [adapted.data.newPoint.lat, adapted.data.newPoint.lon],
          //   MAX_ZOOM
          // );

          // const bounds = _getLatLngBounds(adapted);
          // setBounds(bounds);

          // const zoomB = mapInstance.getBoundsZoom(bounds);
          // const newCenter = mapInstance.getCenter();
          // mapInstance.setView(newCenter, zoomB);
          // console.log("Zoom markers useEffect");

          // mapInstance.setView(
          //   [res.data.newPointByAssetId.lat, res.data.newPointByAssetId.lon],
          //   MAX_ZOOM
          // );
        },
        error(err) {
          console.error("err", err);
        },
      });
    }

    return subscription;
  };

  const getType = (type) => {
    if (type === 0) return "success";
    else if (type === 1) return "low-connection";
    else if (type === 2) return "danger";
    else if (type === 3) return "alert";
    else if (type === 4) return "inactive";
    else return "person";
  };

  const [getPoints] = useLazyQuery(GET_MARKERSBYGROUP_QUERY, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      sanitizePoints(data.pointsByGroups);
      props.addSearchOptions(
        data.pointsByGroups.map((p) => ({
          value: p.mid,
          label: p.pat,
          estado: getType(p.typ),
          type: SearchBarTypes.ASSETS,
        }))
      );

      /*const groups = props.groupsSelected.filter(
        (g) => g && typeof g === "number"
      );
      subscribeToPoints(groups);*/
    },
    onError: (error) => {
      console.log("Error:", error);
    },
  });

  const [getPoint] = useLazyQuery(GET_POINT_QUERY, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      sanitizePoints(data.getPointApi);
    },
    onError: (error) => {
      console.log("Error:", error);
    },
  });

  useEffect(() => {
    let subscription = null;
    if (props.groupsSelected && !assetId && mapId !== 0) {
      // console.log("entró por el if de groupSelected and !assetId");
      const groups =
        props.groupsSelected.length > 0
          ? props.groupsSelected.filter(
              (g) => g && !Number.isNaN(Number.parseInt(g))
            )
          : //.join(",")
            [];
      if (Array.isArray(groups) && groups.length > 0) {
        // console.log("Cantidad de grupos: ", groups.length);
        const intGroups = groups.map((g) => parseInt(g, 10));
        getPoints({
          variables: {
            groupsIDs: intGroups,
          },
        });
        subscription = subscribeToPoints(intGroups);
      } else {
        subscription = subscribeToPoints("");
        prevMarkers2.current = new Map();
        sanitizePoints([]);
      }
    } else if (assetId && +assetId > 0) {
      // TODO: obtener los datos del punto mediante una query, para que la pantalla levante con el punto ya cargado y no tenga que esperar a que entre el próximo paquete
      getPoint({
        variables: {
          movilesID: [+assetId],
        },
      });
      subscribeToPointsByAssetId(+assetId);
    }

    return () => subscription && subscription.unsubscribe();
  }, [props.groupsSelected]);

  useEffect(() => {
    return () => {
      mapInstance = null;
    };
  }, []);

  // Load functions in search bar
  useEffect(() => {
    props.addSearchOptions &&
      props.addSearchOptions(
        availableFunctions(user?.roles)
          .sort((a, b) => {
            let fa = a.label.toLowerCase(),
              fb = b.label.toLowerCase();

            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          })
          .map((f) => {
            return {
              type: SearchBarTypes.FUNCTIONS,
              value: f.value,
              label: t(f.label),
            };
          })
      );
  }, [user.language]);

  // Load geofences in search bar
  useEffect(() => {
    // Check if the user has permission to show geofences...
    // Load geofences
    fetchGeofencesSearchBar();
  }, []);

  // Show geofences in map
  useEffect(() => {
    if (mapInstance) {
      // Remove all geofences
      mapInstance.eachLayer((layer) => {
        if (
          typeof layer._latlngs !== "undefined" &&
          layer._latlngs.length > 0
        ) {
          layer.remove();
        }
      });

      // Add geofences
      props.geofences &&
        props.geofences.map((g) => {
          const geoJsonLayer = L.geoJSON(JSON.parse(g.geoJson));
          geoJsonLayer.bindTooltip(() => g.name);
          geoJsonLayer.on("contextmenu", function (e) {
            props.removeGeofenceFromMap(g);
            mapInstance.removeLayer(geoJsonLayer);
          });
          let btn = document.createElement("div");
          btn.innerText = t("Remover");
          btn.style.setProperty("cursor", "pointer");
          btn.style.setProperty("font-weight", "600");
          btn.onclick = function () {
            props.removeGeofenceFromMap(g);
            mapInstance.removeLayer(geoJsonLayer);
          };
          geoJsonLayer.bindPopup(btn, { minWidth: "200px" }).openPopup();
          mapInstance.addLayer(geoJsonLayer);
          mapInstance.fitBounds(geoJsonLayer.getBounds());
        });
    }
  }, [props.geofences]);

  const _getLatLngBounds = React.useCallback(
    (movilID) => {
      // const bounds = L.latLngBounds([[-12.050742, -77.04656]]);
      const bounds = L.latLngBounds([initialCenter]);

      const validMarkers = markers.filter(
        (x) =>
          x.pos &&
          x.pos.lat &&
          x.pos.lat > -90 &&
          x.pos.lat < 90 &&
          x.pos.lng &&
          x.pos.lng > -180 &&
          x.pos.lng < 180
      );

      validMarkers.forEach((m) => {
        bounds.extend([m.pos.lat, m.pos.lng]);
      });

      return bounds;
    },
    [markers]
  );

  useInterval(() => {
    try {
      //Logica para que al regresar de un recorrido, setear el historico hasta que vuelvan a entrar las nuevas posiciones
      if (
        Object.keys(journeys).length !== 0 &&
        !showJourney &&
        !showJourneyWithoutPanel
      ) {
        setJourneys({});
        subscribeToPoints();
        toggleFillGenericPopup({ popupInfo: {} });
      }

      if (prevMarkers2.current.size > 0 && !showJourney) {
        const aux = Array.from(prevMarkers2.current.values());

        const updMarkers = [];
        const auxMarkers = [...markers];
        // Lógica para agregar los nuevos puntos a los markers a mostrar en el mapa
        if (Array.isArray(aux) && aux.length > 0) {
          // console.time("iterating");
          for (var i = 0, n = aux.length; i < n; ++i) {
            const markerInfo = aux[i];
            const id = markers.findIndex((e) => {
              return e.mid === markerInfo.mid;
            });

            // si existe, actualizo
            if (id >= 0) {
              auxMarkers[id] = markerInfo;
            } else {
              // sino lo inserto
              auxMarkers.push(markerInfo);
            }

            updMarkers.push(markerInfo);
          } // Fin iterar
          // console.timeEnd("iterating");

          // // Iterar aux
          // console.time("iterating");
          // aux.map((markerInfo, index) => {
          //   const id = markers.findIndex((e) => {
          //     return e.mid === markerInfo.mid;
          //   });

          //   //auxMarkersMap.set(markerInfo.mid, markerInfo);

          //   // si existe, actualizo
          //   if (id >= 0) {
          //     //markers[id] = navigationMarker;
          //     auxMarkers[id] = markerInfo;
          //   } else {
          //     // sino lo inserto
          //     //markers.push(navigationMarker);
          //     auxMarkers.push(markerInfo);
          //   }

          //   updMarkers.push(markerInfo);
          //   //navigationMarker = null;
          // }); // Fin iterar
          // console.timeEnd("iterating");

          // actualizo el state para renderizar los nuevos puntos
          setMarkers(auxMarkers);
          setUpdatedMarkers(updMarkers);
        }

        // reinicializo el buffer
        prevMarkers2.current = new Map();
        aux.length = 0;
      }
    } catch (e) {
      console.log(
        "Error en BaseMap setInterval - Posiblemente se venció la sesión",
        e
      );
    }
  }, 5000);

  useEffect(() => {
    if (
      Object.keys(props.markerJourney).length !== 0 &&
      (props.tabActive === 1 ||
        props.panelType === "JourneyReport" ||
        showJourneyWithoutPanel)
    ) {
      setShowJourneys(true);
      //closeAll();
      //setMarkers([]);
      setJourneys(props.markerJourney);
    } else {
      setShowJourneys(false);
    }
  }, [props.markerJourney]);

  const handlePopupClose = (e) => {
    if (Object.keys(popupInfo).length !== 0) {
      toggleFillGenericPopup({ popupInfo: {} });
    }
  };

  const handleMeasureStart = (evt) => {
    console.log("Measure start: ", evt);
  };

  const handleMeasureFinish = (evt) => {
    console.log("Measure finish: ", evt);
  };

  /*const handleZoomStart = (evt) => {
    console.log("Evento zoom start: ", evt);
    console.log("Evento start: ", evt.target._zoom);
    const zoomMapa = mapInstance.getZoom();
    console.log("Zoom mapa: ", zoomMapa);
    //if(_zoom > 8) {
    //  throw 'zoom disabled';
    //}
  }*/

  const handleZoomEnd = (evt) => {
    const { _zoom } = evt.target;
    setZoom(_zoom);
    manageWeatherControl(_zoom);
    manageSearchInAreaControl(_zoom);

    // For remove/add rather than disabled/enabled...
    // if (zoom <= 8 && mapInstance.weatherControl)
    //   mapInstance.removeControl(mapInstance.weatherControl);
    // else mapInstance.addControl(refWeatherControl.current);
    // setShouldGroup(true);
  };

  const handleBaseLayerChange = (evt) => {
    const { name } = evt;
    const MAX_ZOOMS_BY_LAYER = {
      "Street Maps": 22,
      "Satellite Maps": 17,
      "Satellite View": 17,
      "Default View": 20,
      "Street View": 20,
    };

    const maxZoomLayer = MAX_ZOOMS_BY_LAYER[name] || 19;
    setMaxZoom(maxZoomLayer);
    mapInstance.setMaxZoom(maxZoomLayer);
    setLayerName(name);
    // setMaxZoom(name === "Street Maps" ? MAX_ZOOM : 19);
    // mapInstance.setMaxZoom(name === "Street Maps" ? MAX_ZOOM : 19);
  };

  const handleShowGeofence = () => {
    const user = JSON.parse(localStorage.getItem("loggedUser"));
    const customersStr = localStorage.getItem(`customerSelected_${user.id}`);
    if (customersStr) {
      const customers = JSON.parse(customersStr);
      let customerId;

      if (Array.isArray(customers) && customers.length > 0) {
        customerId = customers[customers.length - 1].value;
        fetchGeofences({
          variables: {
            customerId: customerId,
          },
        });
      }
    }
  };

  const handleFechAssetsInRadiusNearPoint = (center, radius) => {
    const radiusInMeters = convertDistanceUnit(
      radius,
      assetInRadiusDistanceUnit.unit,
      DISTANCE_UNITS.METER
    );
    fetchAssetsInRadiusNearPoint({
      variables: {
        longitud: center[1],
        latitud: center[0],
        radius: Math.floor(radiusInMeters),
        clientId: 1,
        customerIds: selectedCustomers
          ? selectedCustomers.map((customer) => customer.value)
          : [],
      },
    });
  };

  useEffect(() => {
    if (showGeofences) {
      handleShowGeofence();
    } else if (mapInstance) {
      //Quitamos todos los poligonos
      mapInstance.eachLayer((layer) => {
        if (layer instanceof L.Polygon) {
          layer.remove();
        }
      });
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Se ocultaron las geocercas"),
      });
    }
  }, [showGeofences]);

  useEffect(() => {
    if (showGeofences && mapInstance) {
      //Quitamos todos los poligonos
      mapInstance.eachLayer((layer) => {
        if (layer instanceof L.Polygon) {
          layer.remove();
        }
      });

      const { selectedCustomers } = props;
      let customerId;
      if (Array.isArray(selectedCustomers) && selectedCustomers.length > 0) {
        customerId = selectedCustomers[selectedCustomers.length - 1].value;
        fetchGeofences({
          variables: {
            customerId: customerId,
          },
        });
      }
    }
  }, [props.selectedCustomers]);

  const createGeofenceControl = (title, background, showGeo) => {
    const geofenceControl = L.Control.extend({
      options: {
        position: "bottomright",
      },
      onAdd: (m) => {
        const container = L.DomUtil.create(
          "div",
          "leaflet-bar leaflet-control leaflet-control-custom"
        );
        container.style.backgroundColor = background;
        container.style.backgroundImage = "url(/assets/img/Iconografía-05.svg)";
        container.style.backgroundSize = "40px 40px";
        container.style.width = "44px";
        container.style.height = "44px";
        container.style.cursor = "pointer";
        container.title = title;

        container.onclick = function () {
          changeControlState(container, showGeo);
          setShowGeofences(showGeo);
        };

        return container;
      },
    });

    return geofenceControl;
  };

  const createTraceControl = (title, background, enableTrace) => {
    const traceControl = L.Control.extend({
      options: {
        // position: "bottomleft",
      },
      onAdd: (m) => {
        const container = L.DomUtil.create(
          "div",
          "leaflet-control-trace"
          // "leaflet-bar leaflet-control leaflet-control-custom"
        );
        container.style.backgroundColor = background;
        container.style.backgroundImage = "url(/assets/img/track.png)";
        container.style.backgroundSize = "40px 40px";
        container.style.width = "44px";
        container.style.height = "44px";
        container.style.cursor = "pointer";
        container.style.left = "-55px";
        container.style.border = "2px solid rgba(0,0,0,0.2)";
        if (!mapId) container.style.top = "64px";

        container.title = title;

        container.onclick = function () {
          changeTraceControl(container, !showTrace);
          showTrace = !showTrace;
        };

        return container;
      },
    });

    return traceControl;
  };

  const createWeatherControl = (title, background) => {
    const weatherControl = L.Control.extend({
      options: {
        // position: "bottomleft",
      },
      onAdd: function (map) {
        map.weatherControl = this;
        refWeatherControl.current = this;
        const container = L.DomUtil.create("div", "leaflet-control-weather");
        container.style.backgroundColor = background;
        container.style.backgroundImage = "url(/assets/img/weather.png)";
        container.style.backgroundSize = "40px 40px";
        container.style.width = "44px";
        container.style.height = "44px";
        container.style.cursor = "pointer";
        // container.style.left = "-55px";
        container.style.border = "2px solid rgba(0,0,0,0.2)";
        if (!mapId) container.style.top = "64px";

        container.title = title;

        container.onclick = function () {
          changeWeatherControl(container, !showWeather);
          showWeather = !showWeather;
        };

        container.addEventListener("hide-button", (event) => {
          container.style.visibility = "hidden";
          container.style.display = "none";
        });
        container.addEventListener("display-button", (event) => {
          container.style.visibility = "visible";
          container.style.display = "block";
        });
        upperRightCornerControlPanel[
          AVAILABLE_UPPER_RIGHT_CONTROLS.WEATHER
        ].container = container;

        return container;
      },
      onRemove: function (map) {
        // Remove reference from map
        delete map.weatherControl;
      },
    });

    return weatherControl;
  };

  const createSearchInAreaControl = (title, background) => {
    const searchInAreaControl = L.Control.extend({
      options: {
        // position: "bottomleft",
      },
      onAdd: function (map) {
        map.searchInArea = this;
        refSearchInAreaControl.current = this;
        const container = L.DomUtil.create(
          "div",
          "leaflet-control-searchinarea"
        );
        container.style.backgroundColor = background;
        container.style.backgroundImage = "url(/assets/img/search-in-area.svg)";
        container.style.backgroundSize = "40px 40px";
        container.style.width = "44px";
        container.style.height = "44px";
        container.style.cursor = "pointer";
        container.style.borderRadius = "5px";
        container.style.backgroundRepeat = "no-repeat";
        container.style.backgroundPosition = "center";
        container.style.border = "2px solid rgba(0,0,0,0.2)";
        if (!mapId) container.style.top = "64px";

        container.title = title;

        container.onclick = function () {
          toggleSearchInArea(mapInstance);
          changeSearchInAreaControl(container, isSearchInAreaEnabled);
        };

        container.addEventListener("hide-button", (event) => {
          container.style.visibility = "hidden";
          container.style.display = "none";
        });
        container.addEventListener("display-button", (event) => {
          container.style.visibility = "visible";
          container.style.display = "block";
        });
        upperRightCornerControlPanel[
          AVAILABLE_UPPER_RIGHT_CONTROLS.SEARCH_IN_AREA
        ].container = container;

        return container;
      },
      onRemove: function (map) {
        // Remove reference from map
        delete map.searchInArea;
      },
    });

    return searchInAreaControl;
  };

  const changeTraceControl = (control, showTrace) => {
    control.remove();

    const title = showTrace
      ? t("Quitar Dejar Rastro")
      : t("Habilitar Dejar Rastro");
    const backgroundColor = showTrace ? "green" : "white";

    const newControl = createTraceControl(title, backgroundColor, showTrace);
    mapInstance.addControl(new newControl());
  };

  const changeWeatherControl = (control, showWeather) => {
    control.remove();

    const title = showWeather ? t("Quitar Tiempo") : t("Mostrar Tiempo");

    const backgroundColor =
      mapInstance.getZoom() >= 9
        ? showWeather
          ? "green"
          : "white"
        : "#D3D3D3";

    const newControl = createWeatherControl(
      title,
      backgroundColor,
      showWeather
    );

    const weatherControl = new newControl();
    addUpperRigthCornerControl(
      AVAILABLE_UPPER_RIGHT_CONTROLS.WEATHER,
      weatherControl,
      mapInstance
    );

    let city;
    if (showWeather) {
      // const user = JSON.parse(localStorage.getItem("loggedUser"));
      city = L.OWM.current({
        interval: 15,
        lang: user.language === "ES" ? "es" : "en",
        temperatureUnit: user.language === "ES" ? "C" : "F",
        minZoom: 9,
      });
      city.addTo(mapInstance);
      weatherLayer.current = city;
    } else {
      weatherLayer.current.remove();
      weatherLayer.current = null;
    }
  };

  const changeSearchInAreaControl = (control, searchInArea) => {
    control.remove();
    const title = searchInArea
      ? t("Quitar busqueda en area")
      : t("Mostrar busqueda en area");

    const backgroundColor =
      mapInstance.getZoom() >= 9
        ? searchInArea
          ? "green"
          : "white"
        : "#D3D3D3";

    const newControl = createSearchInAreaControl(title, backgroundColor);
    const searchInAreaControl = new newControl();
    addUpperRigthCornerControl(
      AVAILABLE_UPPER_RIGHT_CONTROLS.SEARCH_IN_AREA,
      searchInAreaControl,
      mapInstance
    );
  };

  const changeControlState = (control, showGeo) => {
    control.remove();

    const title = showGeo ? t("Ocultar geocercas") : t("Ver geocercas");
    const backgroundColor = showGeo ? "green" : "white";

    const newControl = createGeofenceControl(title, backgroundColor, !showGeo);
    mapInstance.addControl(new newControl());
  };

  const handleCreateMap = (instance) => {
    //instance.on("zoomstart", handleZoomStart);
    instance.on("zoomend", handleZoomEnd);
    instance.on("baselayerchange", handleBaseLayerChange);

    const measureC = new L.Control.Measure({
      ...measureOptions,
      primaryLengthUnit: t("primaryLengthUnit"),
      secondaryLengthUnit: t("secondaryLengthUnit"),
      primaryAreaUnit: t("primaryAreaUnit"),
      secondaryAreaUnit: t("secondaryAreaUnit"),
      measurestart: handleMeasureStart,
      measurefinish: handleMeasureFinish,
    });
    measureC.addTo(instance);

    const geofenceControl = createGeofenceControl(
      t("Ver geocercas"),
      "white",
      true
    );

    instance.addControl(new geofenceControl());
    setShowLayerControl(true);

    if (!assetId && appId) {
      weatherControl = createWeatherControl(t("Mostrar tiempo"), "white");

      const _weatherControl = new weatherControl();
      addUpperRigthCornerControl(
        AVAILABLE_UPPER_RIGHT_CONTROLS.WEATHER,
        _weatherControl,
        instance
      );
      setShowLayerControl(true);
    }

    if (!assetId) {
      searchInAreaControl = createSearchInAreaControl(
        t("Mostrar busqueda en area"),
        "white"
      );

      const _searchInAreaControl = new searchInAreaControl();
      addUpperRigthCornerControl(
        AVAILABLE_UPPER_RIGHT_CONTROLS.SEARCH_IN_AREA,
        _searchInAreaControl,
        instance
      );

      setShowLayerControl(true);
    }

    if (assetId && !mapId) {
      // Only for individual tracking
      const traceControl = createTraceControl(
        t("Habilitar Dejar rastro"),
        "white",
        false
      );
      instance.addControl(new traceControl());
    }
    mapInstance = instance;
  };

  const handleCloseNotification = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setResultState({
      ...resultState,
      isOpen: false,
    });
  };

  const handleSearchCenterUpdate = (newCenter) => {
    // @todo update center
    setAssetInRadiusCenter(newCenter);
  };
  const handleSearchRadiusUpdate = (newRadius) => {
    setAssetInRadiusRadius(newRadius);
  };
  const handleAssetsInRadiusNearPointSearch = () => {
    handleFechAssetsInRadiusNearPoint(assetInRadiusCenter, assetInRadiusRadius);
  };
  const handleAssetInRadiusSearchMarkerClicked = () => {
    setIsOpenSearchAssetsInRadiusPanel(true);
  };
  const handleSelectAssetInRadiusSearchModal = (selectedAsset) => {
    props.setMarkerSelected(selectedAsset.id);
    setIsOpenSearchAssetsInRadiusPanel(false);
  };

  const handleCloseSearchInAreaModal = () => {
    setIsOpenSearchAssetsInRadiusPanel(false);
  };

  useEffect(() => {
    if (!isOpen) {
      handleTrackPlayerInstanceRemove();
      setShouldDisplayNavigationMarkers(true);
    }
  }, [isOpen]);

  return (
    <>
      <Snackbar
        open={resultState.isOpen}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleCloseNotification}
          severity={resultState.type}
          variant="filled"
        >
          {resultState.msg}
        </MuiAlert>
      </Snackbar>
      <MapL
        id="vizionMap"
        className={
          "base-map " +
          (mapId >= 0 ? "base-map--multiple-tracking" : "base-map--full")
        }
        //bounds={!showJourney ? _getLatLngBounds() : null}
        bounds={!showJourney || showJourneyWithoutPanel ? bounds : null}
        center={initialCenter}
        zoom={zoom}
        maxZoom={maxZoom}
        closePopupOnClick
        zoomControl={false}
        onPopupClose={handlePopupClose}
        whenCreated={handleCreateMap}
      >
        <ZoomControl position="bottomright" zoomInText="" zoomOutText="" />

        {showLayerControl && (
          <BaseLayersControl zoom={zoom} layerName={layerName} />
        )}

        {(!showJourney || showJourneyWithoutPanel) && (
          <MarkersGroup
            markersData={updatedMarkers}
            markers={markers.map((m) => (
              <NavigationMarker
                shouldDisplay={shouldDisplayNavigationMarkers}
                markerInfo={m}
                key={`marker-${m.mid}`}
                markerSelected={props.markerSelected}
                setMarkerSelected={props.setMarkerSelected}
                // isMarkerSelected={props.markerSelected === m.mid}
                viewDetail={assetId ? false : true}
                // setTrace={() => {
                //   console.log("entró", showTrace, !showTrace);
                //   showTrace = !showTrace;
                // }}
                //autoOpenMarker={m.mid === props.markerSelected}
              />
            ))}
            maxZoom={maxZoom}
          />
        )}
        <MarkersJourneys
          journeys={journeys}
          showJourney={(showJourney || showJourneyWithoutPanel) && isOpen}
          toggleFillGenericPopup={toggleFillGenericPopup}
        />
        {/*Object.keys(popupInfo).length !== 0 && (
          <GenericPopup popupInfo={popupInfo} />
        )*/}
        {isSearchInAreaEnabled && (
          <AssetsInRadiusNearPoint
            onDragging={handleSearchCenterUpdate}
            onDragEnd={handleAssetsInRadiusNearPointSearch}
            radius={assetInRadiusRadius}
            distanceUnit={assetInRadiusDistanceUnit}
            center={assetInRadiusCenter}
            onMarkerClicked={handleAssetInRadiusSearchMarkerClicked}
            assetsCount={assetInRadiusNearPointResult.length || 0}
          />
        )}
      </MapL>

      {isSearchInAreaEnabled && (
        <AssetInRadiusNearPointSearchModalWindow
          data={assetInRadiusNearPointResult}
          isOpen={isOpenSearchAssetsInRadiusPanel}
          onCloseModal={handleCloseSearchInAreaModal}
          onAssetSelected={handleSelectAssetInRadiusSearchModal}
          onRadiusChange={handleSearchRadiusUpdate}
          onSearch={handleAssetsInRadiusNearPointSearch}
          onIsSearchInAreaEnabled={handleAssetsInRadiusNearPointSearch}
          radius={assetInRadiusRadius}
          distanceUnit={assetInRadiusDistanceUnit}
          center={assetInRadiusCenter}
          isSearchInAreaEnabled={isSearchInAreaEnabled}
        />
      )}
      {journeys && isOpen && (
        <ChartContainer
          journeys={journeys}
          showJourney={showJourney || showJourneyWithoutPanel}
          actualPoint={actualPoint}
        />
      )}
    </>
  );
};

BaseMap.propTypes = {
  markerJourney: PropTypes.object,
};

BaseMap.defaultProps = {
  markerJourney: {},
};

export default BaseMap;
