import gql from "graphql-tag";

const PLANNING_REPORT_BY_ID_QUERY = gql`
  query GetPlanningReport($id: Int!) {
    getPlanningReport(id: $id) {
      id
      name
      typeReportID
      customerId
      customer {
        name
      }
      userCreatorId
      formatId
      enabled
      mailList
      mailListCco
      datePeriod
      allMovilesActivos
      userCreator {
        id
        firstName
        lastName
        userName
      }
      assets {
        id
        name
        licensePlate
        internalNumber
        typeMobile {
          id
          description
        }
      }
      usersDestinations {
        id
        firstName
        lastName
        userName
        email
      }
      planningReportProgramation {
        id
        isEnabled
        runFrom
        runUntil
        frequency
        executionHour
        executionEveryXHour
        executionInit
        executionEnd
        everyXDay
        allDays
        everyXWeeks
        weekMonday
        weekTuesday
        weekWednesday
        weekThursday
        weekFriday
        weekSaturday
        weekSunday
        everyXMonths
        dayOfMonth
        positionDayOfMonth
        positionDayName
        typeExecution
        monthlyFrequencyType
        everyXMonthsOfPositionDay
      }
      planningTravelReport {
        id
        groupBy
      }
      planningDetentionReport {
        id
        mobileStatus
        minimumTime
      }
      planningVelocityReport {
        id
        velocidadDesde
        velocidadHasta
        drivers {
          id
        }
      }
      planningGeofenceReport {
        id
        geofenceIds
        permanenceSecond
      }
      planningTravelReport {
        id
        groupBy
      }
      planningOBD2Report {
        id
        variablesOBD2
      }
      planningStopsDetourReport {
        id
        roadId
      }
      planningEventsReport {
        id
        typeEventSelected
      }
    }
  }
`;

export default PLANNING_REPORT_BY_ID_QUERY;
