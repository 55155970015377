import gql from "graphql-tag";

const TYPE_NOTIFICATIONS_QUERY = gql`
  query TypeNotifications {
    typeNotifications {
      id
      description
    }
  }
`;

export default TYPE_NOTIFICATIONS_QUERY;
