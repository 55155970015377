import { combineReducers } from "redux";

import { UPDATE_ISLOADING_MARKERS_INITIAL } from "./actions";

const isLoadingMarkersInitial = (state = false, { type, payload }) => {
  let nextState = state;

  if (type === UPDATE_ISLOADING_MARKERS_INITIAL) {
    nextState = payload;
  }

  return nextState;
};

export default combineReducers({
  isLoadingMarkersInitial,
});
