import React, { useState } from "react";

import RemoteTable from "../../../components/Base/RemoteTable";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Button } from "@material-ui/core";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { connect } from "react-redux";

import ThreeDotMenu from "../../../components/Base/ThreeDotMenu";
import GeofenceForm from "./Form";
import MiniMap from "../../../components/Base/MiniMap";
import ImportGeofenceFromKmlFileModal from "../../../components/ImportGeofenceFromKmlFileModal";

import { useTranslation } from "../../../lang";

import { hasPermission } from "../../../utils/generalUtils";

const List = ({
  user,
  geofenceList,
  getGeofences,
  createGeofence,
  updateGeofence,
  deleteGeofence,
  paginationSizes,
  total,
  tableState,
  setTableState,
}) => {
  const [t] = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [selectedGeofence, setSelectedGeofence] = useState();
  const [textToSearch, setTextToSearch] = useState("");
  const [
    isImportGeofenceFromKmlFileModalOpen,
    setIsImportGeofenceFromKmlFileModalOpen,
  ] = useState(false);
  const [miniMapOpen, setMiniMapOpen] = useState(false);
  const closeMiniMap = () => setMiniMapOpen(false);

  React.useEffect(() => {
    getGeofences && getGeofences();
  }, []);

  const confirmDelete = (id, name) => {
    confirmAlert({
      title: t("Eliminar Geocerca"),
      message: t("pages_manage_geofences_esta_seguro_eliminar_geocerca", {
        name,
      }),
      buttons: [
        {
          label: t("Si"),
          onClick: () => {
            deleteGeofence(id);
          },
        },
        {
          label: t("No"),
          onClick: () => console.log("cancelar"),
        },
      ],
    });
  };

  const callbackSelectOption = (option, row) => {
    switch (option) {
      case 1: //Ver geocerca
        const geofenceShow = geofenceList.find((x) => x.id === row.id);
        setSelectedGeofence(geofenceShow);
        setMiniMapOpen(true);
        break;
      case 2: //Editar
        const geofence = geofenceList.find((x) => x.id === row.id);
        setSelectedGeofence(geofence);
        setOpenModal(true);
        break;
      case 3: //Eliminar
        confirmDelete(row.id, row.name);
        break;
    }
  };

  const itemStyle = {
    fontSize: "12px",
  };

  const columns = [
    {
      dataField: "id",
      text: "",
      headerStyle: () => ({ width: "50px", fontSize: "12px" }),
      formatter: (cell, row) => {
        return (
          <>
            <ThreeDotMenu
              key={row.id}
              row={row}
              callback={callbackSelectOption}
              options={[
                {
                  label: `${t("Editar")}`,

                  value: 2,
                },
                {
                  label: `${t("See Geofence")}`,
                  value: 1,
                },
                {
                  label: `${t("Eliminar")}`,
                  value: 3,
                },
              ]}
            />
          </>
        );
      },
    },
    {
      dataField: "name",
      text: t("Nombre"),
      style: () => itemStyle,
      sort: true,
    },
    {
      dataField: "customerName",
      text: t("Cliente"),
      style: () => itemStyle,
      sort: true,
    },
    {
      dataField: "showOnMap",
      text: t("Mostrar en mapa"),
      style: () => itemStyle,
      formatter: (cell, row) => {
        return row.showOnMap ? t("Si") : t("No");
      },
    },
    {
      dataField: "maxSpeed",
      text: t("Velocidad Máxima"),
      style: () => itemStyle,
    },
  ];

  const options = {
    // Tooltips botones de paginación
    prePageTitle: t("pagina previa"),
    nextPageTitle: t("próxima página"),
    firstPageTitle: t("primer página"),
    lastPageTitle: t("última página"),
    showTotal: true,
    //paginationTotalRenderer: customTotal,
    sizePerPageList: paginationSizes,
    totalSize: total,
  };

  const onSearch = (searchText) => {
    if (!searchText) {
      setTableState({
        ...tableState,
        pageNumber: 1,
        searchText: undefined,
      });
    }

    if (searchText.length >= 3) {
      setTableState({
        ...tableState,
        pageNumber: 1,
        searchText: searchText,
      });
    }
  };

  const onPaginate = (page, sizePerPage) => {
    setTableState({
      ...tableState,
      pageNumber: page,
      pageSize: sizePerPage,
    });
  };

  const onSort = (sortObj) => {
    setTableState({
      ...tableState,
      sort: sortObj,
    });
  };

  const openImportGeofenceFromKmlFileModalWindow = () => {
    setIsImportGeofenceFromKmlFileModalOpen(true);
  };

  const handleImportGeofenceFromKmlFileModalWindowClose = () => {
    setIsImportGeofenceFromKmlFileModalOpen(false);
  };

  return (
    <div className="listContainer">
      <MiniMap
        closeModal={closeMiniMap}
        title={t("Ver geocerca")}
        geofence={selectedGeofence}
        isOpen={miniMapOpen}
      />
      <SlidingPane
        className="slidingPaneRight"
        isOpen={openModal}
        title={
          selectedGeofence ? t("Actualizar Geocerca") : t("Crear Geocerca")
        }
        width="600px"
        onRequestClose={() => {
          setOpenModal(false);
        }}
      >
        <GeofenceForm
          closeModal={() => setOpenModal(false)}
          createGeofence={createGeofence}
          selectedGeofence={selectedGeofence}
          updateGeofence={updateGeofence}
        />
      </SlidingPane>

      <RemoteTable
        columns={columns}
        data={geofenceList}
        keyField="id"
        paginationOptions={options}
        onPaginate={onPaginate}
        onSearch={onSearch}
        onSort={onSort}
        total={total}
        lateralComponent={
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setSelectedGeofence(null);
                setOpenModal(true);
              }}
              style={{ float: "right" }}
            >
              {t("+ Geocerca")}
            </Button>
            {hasPermission("ImportGeofenceFromKML", user.roles) && (
              <Button
                variant="contained"
                onClick={() => {
                  openImportGeofenceFromKmlFileModalWindow(true);
                }}
                style={{ float: "right", marginRight: "15px" }}
              >
                {t("+ Import from KML")}
              </Button>
            )}
          </>
        }
      />

      <ImportGeofenceFromKmlFileModal
        isOpen={isImportGeofenceFromKmlFileModalOpen}
        onClose={handleImportGeofenceFromKmlFileModalWindowClose}
      />
    </div>
  );
};

const _mapStateToProps = ({ user }) => {
  return {
    user,
  };
};

export default connect(_mapStateToProps, null)(List);
