import gql from "graphql-tag";

const DELETE_ASSET_MUTATION = gql`
  mutation DeleteAsset($assetId: Int!) {
    deleteAsset(assetId: $assetId) {
      id
    }
  }
`;

export default DELETE_ASSET_MUTATION;
