import gql from "graphql-tag";

const GET_DEVICE_EXPORT_QUERY = gql`
  query DevicesPaginatedExport(
    $customerIds: [Int]
    $searchText: String
    $sort: DeviceSortInput
    $withoutAssign: Boolean
    $format: FormatEnum
  ) {
    devicesPaginatedExport(
      customerIds: $customerIds
      searchText: $searchText
      sort: $sort
      withoutAssign: $withoutAssign
      format: $format
    )
  }
`;

export default GET_DEVICE_EXPORT_QUERY;
