import React, { useState, useEffect } from "react";

import { format } from "date-fns";

import Table from "../../../components/Base/Table";

import { secondsToTime } from "../../../utils/generalUtils";

import { useTranslation } from "../../../lang";

const ReportResult = ({ reportData }) => {
  const [t] = useTranslation();

  const columns = [
    {
      dataField: "opNumber",
      text: t("Nro Operación"),
      sort: true,
    },
    {
      dataField: "customer",
      text: t("Cliente"),
      sort: true,
    },
    {
      dataField: "pat",
      text: t("Placa"),
      sort: true,
    },
    {
      dataField: "maxDetentionDays",
      text: t("Dias Detención Max"),
      sort: true,
    },
    {
      dataField: "actualDetentionDays",
      text: t("Dias Detención Actual"),
      sort: true,
    },
    {
      dataField: "theoreticalAverageDailyKms",
      text: t("Promedio Teorico Km Diarios"),
      sort: true,
    },
    {
      dataField: "realAverageDailyKms",
      text: t("Promedio Real Km Diarios"),
      sort: true,
    },
    {
      dataField: "theoreticalAverageDailyHourmeter",
      text: t("Promedio Horometro Diario Teorico (H)"),
      formatter: (cell, row) =>
        secondsToTime(row.theoreticalAverageDailyHourmeter),
      sort: true,
    },
    {
      dataField: "realAverageDailyHourmeter",
      text: t("Promedio Horometro Diario Real (H)"),
      formatter: (cell, row) => secondsToTime(row.realAverageDailyHourmeter),
      sort: true,
    },
    {
      dataField: "areaOfOperationsEvaluated",
      text: t("Zona Operaciones Evaluada"),
      sort: true,
    },
    {
      dataField: "areaOfOperationsReal",
      text: t("Zona Operaciones Real"),
      sort: true,
    },
  ];

  return (
    <div style={{ padding: "20px" }}>
      <Table columns={columns} data={reportData} keyField="opNumber" />
    </div>
  );
};

export default ReportResult;
