import React, { useEffect, Component } from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
  split,
} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";

import { WebSocketLink } from "@apollo/client/link/ws";
import { onError } from "apollo-link-error";

import { setContext } from "@apollo/client/link/context";
// import refreshTokenLink from "containers/App/auth/auth";
import { createUploadLink } from "apollo-upload-client";

import PageRoutes from "./routes/PageRoutes";

import client, {
  GlobalLoadingIndicator,
} from "./components/Base/apiApolloClient";

/**
 * Import needed for the server to get the image and move it to the actual
 * public folder of the bundle
 */
//import "./public/background.png";

import "./App.scss";

import { lang, LangFromLocalStorage, LangProvider } from "./lang";

import { useTranslation } from "./lang";
/**
 * We often need to declare multiple routes in our page so we create a
 * new file where all routes will be declared.
 */

// console.log("graphql endpoint", process.env.REACT_APP_GRAPHQL_URI);

const PageTitle = () => {
  const [t] = useTranslation();
  const title = process.env.REACT_APP_PAGE_TITLE;

  useEffect(() => {
    document.title = t(title);
  });

  return null;
};

export default class ReactReduxApp extends Component {
  render() {
    return (
      <LangProvider i18n={lang}>
        <ApolloProvider client={client}>
          <PageTitle />
          <GlobalLoadingIndicator />
          <PageRoutes {...this.props} />
          <LangFromLocalStorage />
        </ApolloProvider>
      </LangProvider>
    );
  }
}
