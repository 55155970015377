import React, { useState } from "react";
import { connect } from "react-redux";

import { useQuery } from "@apollo/client";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Typography,
  Checkbox,
  Grid,
  FormGroup,
  FormControlLabel,
  Radio,
  TextField,
  Select,
  MenuItem,
} from "@material-ui/core";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import { format } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";

import Table from "../../../components/Base/Table";
import ThreeDotMenu from "../../../components/Base/ThreeDotMenu";

import GET_ENUMS_QUERY from "../../../graphql/queries/getEnumsQuery";

import { POSITION_DAY_NAME } from "../../../constants";

import { useTranslation } from "../../../lang";
import { GetDateFormat } from "../../../utils/generalUtils";
const theme = createMuiTheme({});

const initialProgramation = {
  id: -1,
  isEnabled: true,
  runFrom: new Date().setHours(0, 0, 0),
  runUntil: null,
  frequency: 1,
  executionHour: "00:00:00",
  executionEveryXHour: 0,
  executionInit: "00:00:00",
  executionEnd: "00:00:00",
  everyXDay: 1,
  allDays: false,
  everyXWeeks: 1,
  weekMonday: false,
  weekTuesday: false,
  weekWednesday: false,
  weekThursday: false,
  weekFriday: false,
  weekSaturday: false,
  weekSunday: false,
  everyXMonths: 1,
  dayOfMonth: 1,
  positionDayOfMonth: null,
  positionDayName: null,
  //everyXMonthsAux: 1,
  typeExecution: 1,
  monthlyFrequencyType: 1,
  everyXMonthsOfPositionDay: 1,
};

const FormProgramation = ({ values, setFieldValue, user }) => {
  const [t] = useTranslation();
  const [expanded, setExpanded] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => setIsOpen(false);
  const [progSelected, setProgSelected] = useState(initialProgramation);
  const [positionDays, setPositionDays] = useState([]);
  const [weekDays, setWeekDays] = useState([]);

  //const [valueExecution, setValueExecution] = useState("oneTime");

  useQuery(GET_ENUMS_QUERY, {
    variables: {
      name: "PositionDay",
    },
    onCompleted(d) {
      setPositionDays(
        d.__type.enumValues.map((ev) => ({
          label: t(ev.description),
          value: ev.value,
        }))
      );
    },
  });

  useQuery(GET_ENUMS_QUERY, {
    variables: {
      name: "WeekDaysEnum",
    },
    onCompleted(d) {
      setWeekDays(
        d.__type.enumValues.map((ev, i) => ({
          label: ev.value,
          value: ev.value,
        }))
      );
    },
  });

  const handleSaveProgramation = () => {
    // Creating a new programation
    if (progSelected.id === -1) {
      const aux = { ...progSelected };
      delete aux.id;
      setFieldValue("programations", [...values.programations, aux]);
    } else {
      // Updating a programation
      const aux = { ...progSelected };
      if (aux.typeExecution === 1) {
        aux.executionEveryXHour = 0;
        aux.executionInit = "00:00:00";
        aux.executionEnd = "00:00:00";
      } else if (aux.typeExecution === 2) {
        aux.executionHour = "0";
      }

      if (aux.monthlyFrequencyType === 1) {
        aux.positionDayName = null; // 0
        aux.positionDayOfMonth = null; // ""
      } else if (aux.monthlyFrequencyType === 2) {
        aux.dayOfMonth = 0;
        aux.everyXMonths = 0;
      }

      const index = values.programations.findIndex((p) => p.id === aux.id);
      const newArray = [
        ...values.programations.slice(0, index),
        aux,
        ...values.programations.slice(index + 1),
      ];
      setFieldValue("programations", newArray);
    }
    setProgSelected(initialProgramation);
  };

  const callbackSelectOption = (option, row) => {
    switch (option) {
      case 1: //Editar
        setProgSelected(row);
        setIsOpen(true);
        break;
      case 2: //Eliminar
        const newProgs = values.programations.filter((x) => x.id !== row.id);
        setFieldValue("programations", newProgs);
        break;
      default:
        break;
    }
  };

  const columns = [
    {
      dataField: "id",
      text: "",
      headerStyle: () => ({ width: "50px", fontSize: "12px" }),
      formatter: (cell, row) => {
        return (
          <>
            <ThreeDotMenu
              row={row}
              callback={callbackSelectOption}
              options={[
                {
                  label: t("Editar"),
                  value: 1,
                },
                {
                  label: t("Eliminar"),
                  value: 2,
                },
              ]}
            />
          </>
        );
      },
    },
    {
      dataField: "runFrom",
      text: t("Inicio"),
      sort: true,
      formatter: (cell, row) =>
        format(new Date(row.runFrom), GetDateFormat(user.language, true)),
    },
    {
      dataField: "runUntil",
      text: t("Fin"),
      formatter: (cell, row) =>
        row.runUntil
          ? format(new Date(row.runUntil), GetDateFormat(user.language, true))
          : "",
      sort: true,
    },
    {
      dataField: "frequency",
      text: t("Frecuencia"),
      sort: true,
      formatter: (cell, row) => {
        switch (row.frequency) {
          case 1:
            return t("Diario");
          case 2:
            return t("Semanal");
          case 3:
            return t("Mensual");
          default:
            break;
        }
      },
    },
    {
      dataField: "executionHour",
      text: t("Ejecución"),
      sort: true,
      //headerStyle: () => ({ width: "85%" }),
      formatter: (cell, row) => {
        const {
          executionHour,
          executionEveryXHour,
          executionInit,
          executionEnd,
          typeExecution,
        } = row;
        //console.log(row);
        const executionDesc =
          typeExecution === 1
            ? t("Sucede una vez a las %at%", { at: executionHour })
            : t("Sucede cada %every% horas desde %from% hasta %to%", {
                every: executionEveryXHour,
                from: executionInit,
                to: executionEnd,
              });
        return executionDesc;
      },
    },
    {
      dataField: "isEnabled",
      text: t("Habilitado"),
      //headerStyle: () => ({ width: "10%" }),
      formatter: (cell, row) => (
        <ThemeProvider theme={theme}>
          <Checkbox color="primary" checked={row.isEnabled} disabled />
        </ThemeProvider>
      ),
    },
  ];

  const handleChangeProgSelected = (field, value) => {
    setProgSelected({
      ...progSelected,
      [field]: value,
    });
  };

  return (
    <>
      <Dialog open={isOpen} onClose={closeModal} maxWidth="lg" fullWidth>
        <DialogTitle className="dialogTitle">
          {progSelected.id === -1
            ? t("Nueva Programación")
            : t("Editar Programación")}
        </DialogTitle>
        <DialogContent>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={progSelected.isEnabled}
                  onChange={(evt) =>
                    handleChangeProgSelected("isEnabled", evt.target.checked)
                  }
                />
              }
              label={t("Habilitada")}
            />
          </FormGroup>
          <FormGroup row>
            <FormControlLabel
              control={
                <Radio
                  color="primary"
                  checked={progSelected.typeExecution === 1}
                  onChange={() => handleChangeProgSelected("typeExecution", 1)}
                />
              }
              label={t("Sucede una vez a las")}
            />
            <TextField
              variant="filled"
              margin="none"
              size="small"
              defaultValue={progSelected.executionHour}
              onChange={(evt) => {
                handleChangeProgSelected("executionHour", evt.target.value);
              }}
              style={{
                width: "100px",
              }}
              inputProps={{
                style: { paddingTop: "5px" },
              }}
            />
          </FormGroup>
          <FormGroup row>
            <FormControlLabel
              control={
                <Radio
                  color="primary"
                  checked={progSelected.typeExecution === 2}
                  onChange={() => handleChangeProgSelected("typeExecution", 2)}
                />
              }
              label={t("Sucede cada")}
            />
            <TextField
              variant="filled"
              style={{ width: "100px" }}
              type="number"
              margin="none"
              size="small"
              defaultValue={progSelected.executionEveryXHour}
              onChange={(evt) => {
                handleChangeProgSelected(
                  "executionEveryXHour",
                  +evt.target.value
                );
              }}
              inputProps={{
                style: { paddingTop: "5px" },
              }}
            />
            <div className="text">{t("Horas")}</div>
            <FormControlLabel
              control={
                <TextField
                  variant="filled"
                  style={{ width: "100px", marginLeft: "15px" }}
                  size="small"
                  value={progSelected.executionInit}
                  onChange={(evt) =>
                    handleChangeProgSelected("executionInit", evt.target.value)
                  }
                  inputProps={{
                    style: { paddingTop: "5px" },
                  }}
                />
              }
              label={t("Inicio")}
              labelPlacement="start"
            />
            <FormControlLabel
              control={
                <TextField
                  variant="filled"
                  style={{ width: "100px", marginLeft: "15px" }}
                  size="small"
                  value={progSelected.executionEnd}
                  onChange={(evt) =>
                    handleChangeProgSelected("executionEnd", evt.target.value)
                  }
                  inputProps={{
                    style: { paddingTop: "5px" },
                  }}
                />
              }
              label={t("Fin")}
              labelPlacement="start"
            />
          </FormGroup>
          <Typography component="h4" style={{ color: "black" }}>
            {t("Frecuencia")}
          </Typography>
          <FormGroup>
            <Grid container>
              <Grid item xs={4}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Radio
                        color="primary"
                        checked={progSelected.frequency === 1}
                        onChange={() =>
                          handleChangeProgSelected("frequency", 1)
                        }
                      />
                    }
                    label={t("Diaria")}
                  />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Radio
                        color="primary"
                        checked={progSelected.frequency === 2}
                        onChange={() =>
                          handleChangeProgSelected("frequency", 2)
                        }
                      />
                    }
                    label={t("Semanal")}
                  />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Radio
                        color="primary"
                        checked={progSelected.frequency === 3}
                        onChange={() =>
                          handleChangeProgSelected("frequency", 3)
                        }
                      />
                    }
                    label={t("Mensual")}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={2} />
              <Grid item xs={6}>
                {progSelected.frequency === 1 && (
                  <>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Radio
                            color="primary"
                            checked={!progSelected.allDays}
                            onChange={() => {
                              handleChangeProgSelected("allDays", false);
                            }}
                          />
                        }
                        label={t("Cada")}
                      />
                      <TextField
                        variant="filled"
                        type="number"
                        style={{ width: "50px", marginLeft: "15px" }}
                        size="small"
                        value={progSelected.everyXDay}
                        onChange={(evt) =>
                          handleChangeProgSelected(
                            "everyXDay",
                            +evt.target.value
                          )
                        }
                        inputProps={{
                          style: { paddingTop: "5px" },
                        }}
                        disabled={progSelected.allDays}
                      />
                      <label
                        style={{
                          fontSize: "1rem",
                          fontFamily:
                            '"Roboto", "Helvetica", "Arial", "sans-serif"',
                          fontWeight: "400",
                          lineHeight: "1.5",
                          letterSpacing: "0.00938em",
                          alignSelf: "center",
                          marginLeft: "10px",
                        }}
                      >
                        {t("dias")}
                      </label>
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Radio
                            color="primary"
                            checked={progSelected.allDays}
                            onChange={() =>
                              handleChangeProgSelected("allDays", true)
                            }
                          />
                        }
                        label={t("Todos los dias de la semana")}
                      />
                    </FormGroup>
                  </>
                )}

                {progSelected.frequency === 2 && (
                  <>
                    <FormGroup row>
                      <label>{t("Repetir cada")}</label>
                      <TextField
                        variant="filled"
                        type="number"
                        style={{ width: "75px", marginLeft: "15px" }}
                        size="small"
                        value={progSelected.everyXWeeks}
                        onChange={(evt) =>
                          handleChangeProgSelected(
                            "everyXWeeks",
                            +evt.target.value
                          )
                        }
                        inputProps={{
                          style: { paddingTop: "5px" },
                          min: 1,
                        }}
                      />
                      <label style={{ marginLeft: "15px" }}>
                        {t("semanas el")}
                      </label>
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={progSelected.weekMonday}
                            onChange={(evt) =>
                              handleChangeProgSelected(
                                "weekMonday",
                                evt.target.checked
                              )
                            }
                          />
                        }
                        label={t("Lunes")}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={progSelected.weekTuesday}
                            onChange={(evt) =>
                              handleChangeProgSelected(
                                "weekTuesday",
                                evt.target.checked
                              )
                            }
                          />
                        }
                        label={t("Martes")}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={progSelected.weekWednesday}
                            onChange={(evt) =>
                              handleChangeProgSelected(
                                "weekWednesday",
                                evt.target.checked
                              )
                            }
                          />
                        }
                        label={t("Miercoles")}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={progSelected.weekThursday}
                            onChange={(evt) =>
                              handleChangeProgSelected(
                                "weekThursday",
                                evt.target.checked
                              )
                            }
                          />
                        }
                        label={t("Jueves")}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={progSelected.weekFriday}
                            onChange={(evt) =>
                              handleChangeProgSelected(
                                "weekFriday",
                                evt.target.checked
                              )
                            }
                          />
                        }
                        label={t("Viernes")}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={progSelected.weekSaturday}
                            onChange={(evt) =>
                              handleChangeProgSelected(
                                "weekSaturday",
                                evt.target.checked
                              )
                            }
                          />
                        }
                        label={t("Sabado")}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={progSelected.weekSunday}
                            onChange={(evt) =>
                              handleChangeProgSelected(
                                "weekSunday",
                                evt.target.checked
                              )
                            }
                          />
                        }
                        label={t("Domingo")}
                      />
                    </FormGroup>
                  </>
                )}

                {progSelected.frequency === 3 && (
                  <>
                    <FormGroup row>
                      <Radio
                        color="primary"
                        checked={progSelected.monthlyFrequencyType === 1}
                        onChange={() => {
                          handleChangeProgSelected("monthlyFrequencyType", 1);
                        }}
                      />
                      <label>{t("El día")}</label>
                      <TextField
                        variant="filled"
                        type="number"
                        style={{ width: "70px", marginLeft: "15px" }}
                        size="small"
                        value={progSelected.dayOfMonth}
                        onChange={(evt) =>
                          handleChangeProgSelected(
                            "dayOfMonth",
                            +evt.target.value
                          )
                        }
                        inputProps={{
                          style: { paddingTop: "5px" },
                          min: 1,
                          max: 31,
                        }}
                        disabled={progSelected.monthlyFrequencyType === 2}
                      />
                      <label style={{ marginLeft: "15px" }}>
                        {t("de cada")}
                      </label>
                      <TextField
                        variant="filled"
                        type="number"
                        style={{ width: "70px", marginLeft: "15px" }}
                        size="small"
                        value={progSelected.everyXMonths}
                        onChange={(evt) =>
                          handleChangeProgSelected(
                            "everyXMonths",
                            +evt.target.value
                          )
                        }
                        inputProps={{
                          style: { paddingTop: "5px" },
                          min: 1,
                          max: 12,
                        }}
                        disabled={progSelected.monthlyFrequencyType === 2}
                      />
                      <label style={{ marginLeft: "15px" }}>{t("meses")}</label>
                    </FormGroup>
                    <FormGroup row>
                      <Radio
                        color="primary"
                        checked={progSelected.monthlyFrequencyType === 2}
                        onChange={() => {
                          handleChangeProgSelected("monthlyFrequencyType", 2);
                        }}
                      />
                      <label className="text">{t("El")}</label>
                      <Select
                        variant="outlined"
                        value={progSelected.positionDayOfMonth}
                        onChange={(evt) =>
                          handleChangeProgSelected(
                            "positionDayOfMonth",
                            evt.target.value
                          )
                        }
                        style={{
                          width: "140px",
                          marginLeft: "15px",
                          height: "30px",
                        }}
                        disabled={progSelected.monthlyFrequencyType === 1}
                      >
                        {positionDays.map((pd) => (
                          <MenuItem value={pd.value} key={pd.value}>
                            {pd.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <Select
                        variant="outlined"
                        value={progSelected.positionDayName}
                        onChange={(evt) =>
                          handleChangeProgSelected(
                            "positionDayName",
                            evt.target.value
                          )
                        }
                        style={{
                          width: "160px",
                          marginLeft: "15px",
                          height: "30px",
                        }}
                        disabled={progSelected.monthlyFrequencyType === 1}
                      >
                        {weekDays?.map((pd) => (
                          <MenuItem
                            value={POSITION_DAY_NAME[pd.value]}
                            key={pd.value}
                          >
                            {pd.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <label style={{ marginLeft: "15px" }}>
                        {t("de cada")}
                      </label>
                      <TextField
                        variant="filled"
                        type="number"
                        style={{ width: "70px", marginLeft: "15px" }}
                        size="small"
                        value={progSelected.everyXMonths}
                        onChange={(evt) =>
                          handleChangeProgSelected(
                            "everyXMonths",
                            +evt.target.value
                          )
                        }
                        inputProps={{
                          style: { paddingTop: "5px" },
                          min: 1,
                          max: 12,
                        }}
                        disabled={progSelected.monthlyFrequencyType === 1}
                      />
                      <label style={{ marginLeft: "15px" }}>{t("meses")}</label>
                    </FormGroup>
                  </>
                )}
              </Grid>
            </Grid>
          </FormGroup>
          <Typography component="h4" style={{ color: "black" }}>
            {t("Intervalo de repetición")}
          </Typography>
          <FormGroup row>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format={GetDateFormat(user.language, true)}
                margin="normal"
                id="date-picker-inline"
                label={t("Comenzar el")}
                value={progSelected.runFrom}
                onChange={(date) => handleChangeProgSelected("runFrom", date)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </FormGroup>
          <FormGroup row>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                clearable
                format={GetDateFormat(user.language, true)}
                margin="normal"
                id="date-picker-inline"
                label={t("Finalizar el")}
                value={progSelected.runUntil}
                onChange={(date) => handleChangeProgSelected("runUntil", date)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => closeModal()}
            variant="contained"
            color="primary"
          >
            {t("Cancelar")}
          </Button>
          <Button
            onClick={() => {
              handleSaveProgramation();
              closeModal();
            }}
            variant="contained"
            color="primary"
          >
            {t("Guardar")}
          </Button>
        </DialogActions>
      </Dialog>
      <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className="summaryTitleForm"
        >
          <Typography>{t("Programaciones")}</Typography>
        </AccordionSummary>
        <AccordionDetails className="reportPlanningSummaryDetail">
          <Table
            columns={columns}
            data={values.programations}
            keyField="id"
            hideSearch
            //paginationOptions={options}
            //total={total}
            //afterSearch={(value) => setTextToSearch(value)}
            lateralComponent={
              <Button
                variant="contained"
                onClick={() => {
                  setProgSelected(initialProgramation);
                  setIsOpen(true);
                }}
                style={{ float: "right" }}
              >
                {t("+ Programación")}
              </Button>
            }
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
};
const _mapStateToProps = ({ user }) => {
  return {
    user,
  };
};

const _mapDispatchToProps = (dispatch) => {
  return {
    // _setCurrentUser: (param) => dispatch(setCurrentUser(param)),
  };
};

export default connect(_mapStateToProps, _mapDispatchToProps)(FormProgramation);
