import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./FlexRow.scss";

const FlexRow = ({
  direction,
  alignment,
  expansion,
  bottomDivider,
  _classNames,
  children,
  handleClick = () => {},
}) => {
  const flexRowClassNames = classNames("flex-div", {
    [`flex-div--${direction}`]: direction,
    [`flex-div--${alignment}`]: alignment,
    [`flex-div--${expansion}`]: expansion,
    [_classNames]: _classNames,
    "flex-div--bot-divider": bottomDivider,
  });

  return (
    <div className={flexRowClassNames} onClick={handleClick}>
      {children}
    </div>
  );
};
FlexRow.propTypes = {
  direction: PropTypes.string,
  alignment: PropTypes.string,
  expansion: PropTypes.string,
  bottomDivider: PropTypes.bool,
  _classNames: PropTypes.string,
  children: PropTypes.node.isRequired,
};

FlexRow.defaultProps = {
  direction: "",
  alignment: "",
  expansion: "",
  bottomDivider: false,
  _classNames: "",
};

export default FlexRow;
