import { useLazyQuery, useQuery } from "@apollo/client";
import { connect } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Input,
  Typography,
} from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import GenericSelector from "../../../components/Selectors/GenericSelector";
// import ASSETS_BYCUSTOMER_QUERY from "../../../graphql/queries/getAssetsByCustomerQuery";
import ASSETS_BYGROUPS_QUERY from "../../../graphql/queries/assetByGroupsQuery";
import DRIVERS_PAGINATED_QUERY from "../../../graphql/queries/getDriversPaginatedQuery";
import GET_ENUMS_QUERY from "../../../graphql/queries/getEnumsQuery";
import GET_GEOFENCES_QUERY from "../../../graphql/queries/getGeofencesQuery";
import ALL_ROADS_BY_CUSTOMER_IDS_QUERY from "../../../graphql/queries/allRoadsByCustomersIDs";
import TYPE_EVENTALL_QUERY from "../../../graphql/queries/typeEventAllQuery";
import { useTranslation } from "../../../lang";
import {
  default as CustomSelectStyle,
  default as CustomSelectStyles,
} from "../../../utils/reactSelectStyle";

const theme = createMuiTheme({});

const DATE_PERIODS = [
  {
    label: "Última hora",
    value: "LASTHOUR",
  },
  {
    label: "Últimas seis horas",
    value: "LASTSIXHOURS",
  },
  {
    label: "Hoy",
    value: "TODAY",
  },
  {
    label: "Ayer",
    value: "YESTERDAY",
  },
  {
    label: "Semana pasada",
    value: "LASTWEEK",
  },
  {
    label: "Mes pasado",
    value: "LASTMONTH",
  },
];

export const useTranslatedDatePeriods = () => {
  const [t] = useTranslation();
  const datePeriods = DATE_PERIODS.map((datePeriod) => {
    return { ...datePeriod, label: t(datePeriod.label) };
  });
  return [datePeriods];
};

const FormReportFilters = ({
  errors,
  touched,
  values,
  setFieldValue,
  selectedCustomers,
  groupsSelected,
}) => {
  const [t] = useTranslation();
  const [datePeriods] = useTranslatedDatePeriods();
  const [expanded, setExpanded] = useState(true);
  const [assets, setAssets] = useState([]);
  const [geofenceList, setGeofenceList] = useState([]);
  const [driverList, setDriverList] = useState([]);
  const [assetStateList, setAssetStateList] = useState([]);
  const [journeyAgrupations, setJourneyAgrupations] = useState([]);
  const [variablesOBD2, setVariablesOBD2] = useState([]);
  const [roadList, setRoadList] = useState([]);
  const [typeEventList, setTypeEventList] = useState([]);

  const {} = useQuery(ALL_ROADS_BY_CUSTOMER_IDS_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      customerIDs:
        selectedCustomers && selectedCustomers.length > 0
          ? selectedCustomers.map((customer) => customer.value)
          : [],
    },
    onCompleted(d) {
      const roads = d.allRoadsByCustomersIDs.map((road) => road);
      setRoadList(roads);
    },
  });

  useQuery(GET_ENUMS_QUERY, {
    variables: {
      name: "DetentionStatus",
    },
    onCompleted(d) {
      setAssetStateList(d.__type.enumValues);
    },
  });

  const [fetchAssets] = useLazyQuery(ASSETS_BYGROUPS_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      const { items } = d.assetsByGroups;
      setAssets(items);
    },
  });

  const [fetchGeofences] = useLazyQuery(GET_GEOFENCES_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      setGeofenceList(d.geofence.items);
    },
  });

  useQuery(GET_ENUMS_QUERY, {
    variables: {
      name: "JourneyGroupEnum",
    },
    onCompleted(d) {
      // console.log("agrupaciones", d);
      // setJourneyAgrupations(d.__type.enumValues);
      const options = d.__type.enumValues.map((e, i) => {
        return { label: t(e.label), value: i + 1 };
      });
      setJourneyAgrupations(options);
    },
  });

  useQuery(GET_ENUMS_QUERY, {
    variables: {
      name: "VariableOBD2Enum",
    },
    onCompleted(d) {
      setVariablesOBD2(
        d.__type.enumValues.map((ev) => ({
          label: ev.description,
          value: ev.value,
        }))
      );
    },
  });
  useQuery(TYPE_EVENTALL_QUERY, {
    onCompleted(d) {
      setTypeEventList([{ label: t("Todos"), value: -1 }, ...d.typeEventAll]);
    },
  });

  const [fetchDrivers] = useLazyQuery(DRIVERS_PAGINATED_QUERY, {
    fetchPolicy: "network-only",
    /*variables: {
      paginate: false,
    },*/
    onCompleted(d) {
      setDriverList(d.driversPaginated.items);
    },
  });

  useEffect(() => {
    if (values.customerId) {
      // fetchAssets({ variables: { customerId: values.customerId } });
      fetchDrivers({
        variables: { customerId: values.customerId, paginate: false },
      });
      fetchGeofences({
        variables: {
          customerId: values.customerId,
          paginate: false,
        },
      });
    }
  }, [values.customerId]);

  useEffect(() => {
    if (Array.isArray(groupsSelected) && groupsSelected.length > 0) {
      fetchAssets({
        groupsIds: groupsSelected,
      });
      return;
    }

    setAssets([]);
  }, [groupsSelected]);

  const columnsAssets = [
    {
      dataField: "name",
      text: t("Nombre"),
      sort: true,
    },
    {
      dataField: "licensePlate",
      text: t("Patente"),
      sort: true,
    },
    {
      dataField: "internalNumber",
      text: t("Interno"),
      sort: true,
    },
    {
      dataField: "driver.firstName",
      text: t("Chofer"),
      sort: true,
    },
    {
      dataField: "typeMobile.description",
      text: t("Tipo"),
      sort: true,
    },
  ];

  const columnsDrivers = [
    {
      dataField: "firstName",
      text: t("Nombre"),
      sort: true,
    },
    {
      dataField: "lastName",
      text: t("Apellido"),
      sort: true,
    },
  ];

  const columnsEvents = [
    {
      dataField: "label",
      text: t("Descripcion"),
      sort: true,
    },
  ];

  const columnsGeofence = [
    {
      dataField: "name",
      text: t("Nombre"),
      sort: true,
    },
    {
      dataField: "customerName",
      text: t("Cliente"),
      sort: true,
    },
    {
      dataField: "showOnMap",
      text: t("Mostrar en mapa"),
      formatter: (cell, row) => {
        return row.showOnMap ? t("Si") : t("No");
      },
    },
    {
      dataField: "maxSpeed",
      text: t("Velocidad Máxima"),
    },
  ];

  const columnsRoads = [
    {
      dataField: "name",
      text: t("Nombre"),
      sort: true,
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className="summaryTitleForm"
        >
          <Typography>{t("Filtros")}</Typography>
        </AccordionSummary>
        <AccordionDetails className="reportPlanningSummaryDetail">
          {/* <>
            <CommonFilters />
            {renderSpecificFilters()}
          </>*/}
          <Grid container>
            <Grid item xs={5}>
              {values.typeReportID !== "POSICIONES" && (
                <>
                  <Select
                    options={datePeriods}
                    value={datePeriods.filter(
                      (x) => x.value === values.datePeriod
                    )}
                    onChange={(opt) => setFieldValue("datePeriod", opt.value)}
                    styles={CustomSelectStyle}
                    menuPlacement="top"
                    placeholder={t("Seleccionar Período")}
                    name="datePeriod"
                  />
                  {(errors.datePeriod || touched.datePeriod) && (
                    <div className="input-feedback">{errors.datePeriod}</div>
                  )}
                </>
              )}
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={5}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={values.allMovilesActivos}
                      onChange={(evt) =>
                        setFieldValue("allMovilesActivos", evt.target.checked)
                      }
                    />
                  }
                  label={t("Todos los móviles activos")}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={5}>
              <FormGroup>
                <GenericSelector
                  callbackOptionSelected={(assetsSelected) =>
                    setFieldValue(
                      "assetsId",
                      assetsSelected.map((a) => a.value)
                    )
                  }
                  columns={columnsAssets}
                  data={assets}
                  keyField="id"
                  labelField="licensePlate"
                  title={t("Móviles")}
                  isMulti
                  defaultValue={
                    Array.isArray(values.assetsId) && values.assetsId.length > 0
                      ? values.assetsId
                      : null
                  }
                />
              </FormGroup>
            </Grid>
            <Grid item xs={7} />
            {values.typeReportID === "DETENCIONES" && (
              <>
                <Grid item xs={5}>
                  <FormGroup>
                    <label className="formLabelFormik">Estado Móvil</label>
                  </FormGroup>
                  <Select
                    value={assetStateList.filter(
                      (x) =>
                        x.value === values.detentionReportArgs?.mobileStatus
                    )}
                    options={assetStateList}
                    onChange={(opt) =>
                      setFieldValue(
                        "detentionReportArgs.mobileStatus",
                        opt.value
                      )
                    }
                    styles={CustomSelectStyles}
                    menuPlacement="top"
                    placeholder={t("Seleccionar_")}
                  />
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={5}>
                  <FormGroup>
                    <label className="formLabelFormik">Tiempo Mínimo (m)</label>
                    <Input
                      type="number"
                      className="form-control"
                      value={values.detentionReportArgs.minimumTime}
                      onChange={(evt) =>
                        setFieldValue(
                          "detentionReportArgs.minimumTime",
                          evt.target.value
                        )
                      }
                    />
                  </FormGroup>
                </Grid>
              </>
            )}

            {values.typeReportID === "VELOCIDADES" && (
              <>
                <Grid item xs={5}>
                  <FormGroup>
                    <label className="formLabelFormik">Velocidad Desde</label>
                    <Input
                      type="number"
                      className="form-control"
                      value={values.velocidadReportArgs.velocidadDesde}
                      onChange={(evt) =>
                        setFieldValue(
                          "velocidadReportArgs.velocidadDesde",
                          evt.target.value
                        )
                      }
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={5}>
                  <FormGroup>
                    <label className="formLabelFormik">
                      {t("Velocidad Hasta")}
                    </label>
                    <Input
                      type="number"
                      className="form-control"
                      value={values.velocidadReportArgs.velocidadHasta}
                      onChange={(evt) =>
                        setFieldValue(
                          "velocidadReportArgs.velocidadHasta",
                          evt.target.value
                        )
                      }
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={5}>
                  <GenericSelector
                    //callbackOptionSelected={callbackDriverSelected}
                    callbackOptionSelected={(driversSelected) =>
                      setFieldValue(
                        "velocidadReportArgs.drivers",
                        driversSelected.map((d) => d.value)
                      )
                    }
                    columns={columnsDrivers}
                    keyField="id"
                    data={driverList}
                    labelField="fullName"
                    title={t("Conductores")}
                    isMulti
                    defaultValue={values.velocidadReportArgs.drivers}
                  />
                </Grid>
                <Grid item xs={7} />
              </>
            )}

            {values.typeReportID === "RECORRIDOS" && (
              <>
                <Grid item xs={5}>
                  <FormGroup>
                    <label className="formLabelFormik">
                      {t("Agrupar por")}
                    </label>
                  </FormGroup>
                  <Select
                    value={journeyAgrupations.filter(
                      (x) => x.value === values.journeyReportArgs?.groupBy
                    )}
                    options={journeyAgrupations}
                    onChange={(opt) =>
                      setFieldValue("journeyReportArgs.groupBy", opt.value)
                    }
                    styles={CustomSelectStyles}
                    menuPlacement="top"
                    placeholder={t("Seleccionar_")}
                  />
                </Grid>
                <Grid item xs={7} />
              </>
            )}

            {values.typeReportID === "EVENTOS" && (
              <>
                <Grid item xs={5}>
                  <FormGroup>
                    <label className="formLabelFormik">{t("Eventos")}</label>
                  </FormGroup>
                  <GenericSelector
                    //callbackOptionSelected={callbackDriverSelected}
                    callbackOptionSelected={(eventSelected) =>
                      setFieldValue(
                        "eventsReportArgs.typeEventSelected",
                        eventSelected.map((d) => d.value)
                      )
                    }
                    columns={columnsEvents}
                    keyField="value"
                    data={typeEventList}
                    labelField="label"
                    title={t("Eventos")}
                    isMulti
                    defaultValue={
                      Array.isArray(
                        values.eventsReportArgs?.typeEventSelected
                      ) && values.eventsReportArgs?.typeEventSelected.length > 0
                        ? values.eventsReportArgs?.typeEventSelected
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={7} />
              </>
            )}

            {values.typeReportID === "OBD2" && (
              <Grid item xs={5}>
                <FormGroup>
                  <label className="formLabelFormik">Variables OBD2</label>
                </FormGroup>
                <Select
                  placeholder={t("Seleccionar_")}
                  options={variablesOBD2}
                  value={variablesOBD2.filter((x) =>
                    values.obd2ReportArgs?.variablesOBD2?.includes(x.value)
                  )}
                  isMulti
                  onChange={(opt) => {
                    setFieldValue(
                      "obd2ReportArgs.variablesOBD2",
                      opt?.map((x) => x.value)
                    );
                  }}
                  styles={CustomSelectStyles}
                />
              </Grid>
            )}

            {values.typeReportID === "GEOESCALAS" && (
              <>
                <Grid item xs={5}>
                  <GenericSelector
                    //callbackOptionSelected={callbackDriverSelected}
                    callbackOptionSelected={(geoFencesSelected) =>
                      setFieldValue(
                        "geofenceReportArgs.geofenceIds",
                        geoFencesSelected?.map((d) => d.value)
                      )
                    }
                    columns={columnsGeofence}
                    keyField="id"
                    data={geofenceList}
                    labelField="name"
                    title={t("Geocercas")}
                    isMulti
                    //defaultValue={values?.geofenceReportArgs?.geofenceIds}
                    defaultValue={
                      Array.isArray(values.geofenceReportArgs?.geofenceIds) &&
                      values.geofenceReportArgs.geofenceIds.length > 0
                        ? values.geofenceReportArgs.geofenceIds
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={5}>
                  <FormGroup>
                    <label className="formLabelFormik">
                      {t("Tiempo Permanencia minimo [s]")}
                    </label>
                    <Input
                      type="number"
                      className="form-control"
                      value={values.geofenceReportArgs.permanenceSecond}
                      onChange={(evt) =>
                        setFieldValue(
                          "geofenceReportArgs.permanenceSecond",
                          evt.target.value && parseInt(evt.target.value, 10)
                        )
                      }
                    />
                  </FormGroup>
                </Grid>
              </>
            )}

            {values.typeReportID === "STOPSDETOUR" && (
              <Grid item xs={5}>
                <GenericSelector
                  callbackOptionSelected={(roadSelected) => {
                    setFieldValue("stopsDetourReportArgs", {
                      ...values.stopsDetourReportArgs,
                      roadId: roadSelected.value,
                    });
                  }}
                  columns={columnsRoads}
                  data={roadList}
                  keyField="id"
                  labelField="name"
                  title={t("Ruta")}
                  defaultValue={values.stopsDetourReportArgs.roadId}
                />
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </ThemeProvider>
  );
};

const _mapStateToProps = ({ groups }) => {
  return {
    selectedCustomers: groups.selectedCustomers,
  };
};

export default connect(_mapStateToProps, null)(FormReportFilters);
