import React, { useState } from "react";
import { Button, FormGroup, Grid } from "@material-ui/core";

import * as Yup from "yup";
import { Field, Formik } from "formik";

import { useTranslation } from "../../../lang";

const EcoTrackingForm = ({
  setIsOpen,
  createEcoTracking,
  selectedAsset,
  updateEcoTracking,
  deleteEcoTracking,
}) => {
  const [t] = useTranslation();
  const [ecoTrackingId, setEcoTrackingId] = useState(
    selectedAsset?.ecoTracking?.id
  );

  const initialValues = {
    assetId: selectedAsset?.id,
    id: selectedAsset?.ecoTracking?.id,
    ConsumptionRangeOne: selectedAsset?.ecoTracking?.ConsumptionRangeOne,
    ConsumptionRangeTwo: selectedAsset?.ecoTracking?.ConsumptionRangeTwo,
    ConsumptionRangeThree: selectedAsset?.ecoTracking?.ConsumptionRangeThree,
    ConsumptionIdle: selectedAsset?.ecoTracking?.ConsumptionIdle,
    CO2Emissions: selectedAsset?.ecoTracking?.CO2Emissions,
  };

  const yupSchema = Yup.object().shape({
    // assetId: Yup.number().required("Requerido"),
    ConsumptionRangeOne: Yup.number().required(t("Requerido")),
    ConsumptionRangeTwo: Yup.number().required(t("Requerido")),
    ConsumptionRangeThree: Yup.number().required(t("Requerido")),
    ConsumptionIdle: Yup.number().required(t("Requerido")),
    CO2Emissions: Yup.number().required(t("Requerido")),
  });

  return (
    <>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={yupSchema}
        onSubmit={async (values) => {
          if (selectedAsset.ecoTracking) {
            updateEcoTracking({
              variables: { ...values, assetId: selectedAsset.id },
            });
          } else {
            const ecoTracking = await createEcoTracking({
              variables: { ...values, assetId: selectedAsset.id },
            });
            setEcoTrackingId(ecoTracking.data.createEcoTracking.id);
          }
        }}
      >
        {(props) => {
          const { values, errors, handleSubmit, setFieldValue } = props;

          return (
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <label className="formLabelFormik">
                  {t("Consumo entre 0 y 50 km/h (l/100km)")}
                </label>
                <Field
                  type="number"
                  name="ConsumptionRangeOne"
                  tabIndex="0"
                  className={
                    errors.ConsumptionRangeOne
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.ConsumptionRangeOne && (
                  <div className="input-feedback">
                    {errors.ConsumptionRangeOne}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">
                  {t("Consumo entre 51 y 100 km/h (l/100km)")}
                </label>
                <Field
                  type="number"
                  name="ConsumptionRangeTwo"
                  tabIndex="0"
                  className={
                    errors.ConsumptionRangeTwo
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.ConsumptionRangeTwo && (
                  <div className="input-feedback">
                    {errors.ConsumptionRangeTwo}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">
                  {t("Consumo mayor a 100 km/h (l/100km)")}
                </label>
                <Field
                  type="number"
                  name="ConsumptionRangeThree"
                  tabIndex="0"
                  className={
                    errors.ConsumptionRangeThree
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.ConsumptionRangeThree && (
                  <div className="input-feedback">
                    {errors.ConsumptionRangeThree}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">
                  Consumo ralentí (l/hora)
                </label>
                <Field
                  type="number"
                  name="ConsumptionIdle"
                  tabIndex="0"
                  className={
                    errors.ConsumptionIdle
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.ConsumptionIdle && (
                  <div className="input-feedback">{errors.ConsumptionIdle}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">
                  {t("Emisión CO2 (gramos/litro)")}
                </label>
                <Field
                  type="number"
                  name="CO2Emissions"
                  tabIndex="0"
                  className={
                    errors.CO2Emissions
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.CO2Emissions && (
                  <div className="input-feedback">{errors.CO2Emissions}</div>
                )}
              </FormGroup>

              <Grid item lg={12} style={{ marginTop: "50px" }}>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  tabIndex="0"
                >
                  {ecoTrackingId ? t("Actualizar") : t("Crear")}
                </Button>
                <Button
                  variant="contained"
                  type="button"
                  tabIndex="0"
                  style={{ marginLeft: "5px" }}
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  {t("Cancelar")}
                </Button>
                {ecoTrackingId && (
                  <Button
                    variant="contained"
                    type="button"
                    tabIndex="0"
                    color="secondary"
                    style={{ marginLeft: "5px" }}
                    onClick={async () => {
                      await deleteEcoTracking({
                        variables: {
                          id: ecoTrackingId,
                          assetId: initialValues.assetId,
                        },
                      });
                      setIsOpen(false);
                    }}
                  >
                    {t("Eliminar")}
                  </Button>
                )}
              </Grid>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default EcoTrackingForm;
