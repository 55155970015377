import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import {
  Button,
  FormGroup,
  Grid,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { useQuery } from "@apollo/client";

import * as Yup from "yup";
import { Field, Formik } from "formik";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import CUSTOMERS_QUERY from "../../../graphql/queries/getCustomersQuery";
import DRIVERS_QUERY from "../../../graphql/queries/driversQuery";
import TYPE_MOBILES_QUERY from "../../../graphql/queries/typeMobilesQuery";

import GenericSelector from "../../../components/Selectors/GenericSelector";
import { ReduxKeyboardDateField as KeyboardDateField } from "../../../components/Base/ReduxFormFields";
import CustomFormikSelect from "../../../components/Base/CustomFormikSelect";
import ImageUpload from "../../../components/Base/ImageUpload";

import { useTranslation } from "../../../lang";
import { GetDateFormat } from "../../../utils/generalUtils";

const theme = createMuiTheme({});

const AssetForm = ({
  setIsOpen,
  createAsset,
  selectedAsset,
  updateAsset,
  photo,
  user,
}) => {
  const [t] = useTranslation();
  const [customers, setCustomers] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [typeMobiles, setTypeMobiles] = useState([]);
  //const [assetPhoto, setAssetPhoto] = useState();
  //const [customerDefault, setCustomerDefault] = useState();
  //const [driverDefault, setDriverDefault] = useState();

  const [errors, setErrors] = useState(null);

  const translateTypeMobiles = (typeMobiles) => {
    const translatedTypeMobiles = typeMobiles.map((type) => ({
      ...type,
      description: t(type.description),
    }));

    return translatedTypeMobiles;
  };

  useEffect(() => {
    const translatedTypeMobiles = translateTypeMobiles(typeMobiles);
    setTypeMobiles(translatedTypeMobiles);
  }, [user.language]);

  const initialValues = {
    assetId: selectedAsset?.id,
    name: selectedAsset?.name || "",
    brand: selectedAsset?.brand || "",
    licensePlate: selectedAsset?.licensePlate || "",
    customerId: selectedAsset?.customer?.id,
    color: selectedAsset?.color || "",
    driverId: selectedAsset?.driver?.id,
    typeMobileId: selectedAsset?.typeMobile?.id,
    internalNumber: selectedAsset?.internalNumber || "",
    fuelCutWiring: selectedAsset?.fuelCutWiring,
    fuelCutWiringDate: selectedAsset?.fuelCutWiringDate,
    cutSolenoidValve: selectedAsset?.cutSolenoidValve,
    electroValveInstallationDate: selectedAsset?.electroValveInstallationDate,
    mainTension: selectedAsset?.mainTension,
    maximumSpeed: selectedAsset?.maximumSpeed || 0,
    initialRealOdometer: selectedAsset?.initialRealOdometer || 0,
    photo: photo,
    icon: selectedAsset?.icon,
    model: selectedAsset?.model || "",
    year: selectedAsset?.year || new Date().getFullYear(),
    fuelTankCapacity: selectedAsset?.fuelTankCapacity || 0,
    fuelTankCapacityMax: selectedAsset?.fuelTankCapacityMax || 0,
    initialRealHorometer: selectedAsset?.initialRealHorometer || 0,
    satPatAlerts: selectedAsset?.satPatAlerts,
    itSupportsOBD2: selectedAsset?.itSupportsOBD2 || false,
  };

  /*useEffect(() => {
    setAssetPhoto(photo);
  }, [photo]);*/

  const yupSchema = Yup.object().shape({
    name: Yup.string().required(t("Requerido")),
    typeMobileId: Yup.number().required(t("Requerido")),
    customerId: Yup.number().required(t("Requerido")),
    // categoryId: Yup.number().required("Requerido"),
  });

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0)
      toast.error(t("errores-continuar"));
  }, [errors]);

  // const itemStyle = {
  //   fontSize: "12px",
  //   color: "white",
  // };

  const columnsCustomers = [
    {
      dataField: "name",
      text: t("Nombre"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      // style: () => itemStyle,
      sort: true,
    },
    {
      dataField: "taxId",
      text: t("CUIT"),
      headerStyle: () => ({ width: "15%", fontSize: "12px" }),
      // style: () => itemStyle,
      sort: true,
    },
    {
      dataField: "phone",
      text: t("Telefonos"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      // style: () => itemStyle,
      sort: true,
    },
    {
      dataField: "celPhone",
      text: t("Celular"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      // style: () => itemStyle,
      sort: true,
    },
    {
      dataField: "address",
      text: t("Domicilio"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      // style: () => itemStyle,
      sort: true,
    },
  ];

  const columnsDrivers = [
    {
      dataField: "firstName",
      text: t("Nombre"),
      sort: true,
    },
    {
      dataField: "lastName",
      text: t("Apellido"),
      sort: true,
    },
    {
      dataField: "phone",
      text: t("Teléfono"),
      sort: true,
    },
    {
      dataField: "companyPhone",
      text: t("Teléfono Empresa"),
      sort: true,
    },
  ];

  const columnsTypeMobile = [
    {
      dataField: "description",
      text: t("Tipo de Móvil"),
      sort: true,
    },
  ];

  const {} = useQuery(CUSTOMERS_QUERY, {
    onCompleted(d) {
      setCustomers(d.customers.items);
      if (selectedAsset?.customerId) {
        const defaultC = d.customers.items.find(
          (c) => c.id === selectedAsset.customerId
        );
        //setCustomerDefault(defaultC);
      }
    },
  });

  const {} = useQuery(TYPE_MOBILES_QUERY, {
    onCompleted(d) {
      const translatedTypeMobiles = translateTypeMobiles(d.typeMobile);
      setTypeMobiles(translatedTypeMobiles);
    },
  });

  const {} = useQuery(DRIVERS_QUERY, {
    onCompleted(d) {
      setDrivers(d.driversPaginated.items);
      if (selectedAsset?.driverId) {
        const defaultD = d.driversPaginated.items.find(
          (c) => c.id === selectedAsset.driverId
        );
        //setDriverDefault(defaultD);
      }
    },
  });

  return (
    <>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={yupSchema}
        onSubmit={async (values, { resetForm }) => {
          // const handleReset = () => {
          //  resetForm({}) ;
          // }

          if (selectedAsset) {
            updateAsset({
              variables: {
                ...values,
                //photo: assetPhoto,
              },
            });
          } else {
            createAsset(values, resetForm);
          }
        }}
      >
        {(props) => {
          const { values, errors, handleSubmit, setFieldValue } = props;

          setErrors(errors);

          return (
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <label className="formLabelFormik">{t("Nombre")}</label>
                <Field
                  type="text"
                  name="name"
                  tabIndex="0"
                  className={
                    errors.name
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.name && (
                  <div className="input-feedback">{errors.name}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">{t("Placa")}</label>
                <Field
                  type="text"
                  name="licensePlate"
                  tabIndex="0"
                  className={
                    errors.licensePlate
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.licensePlate && (
                  <div className="input-feedback">{errors.licensePlate}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">
                  {t("Número de Interno")}
                </label>
                <Field
                  type="text"
                  name="internalNumber"
                  tabIndex="0"
                  className={
                    errors.internalNumber
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.internalNumber && (
                  <div className="input-feedback">{errors.internalNumber}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">{t("Marca")}</label>
                <Field
                  type="text"
                  name="brand"
                  tabIndex="0"
                  className={
                    errors.brand
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.brand && (
                  <div className="input-feedback">{errors.brand}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">{t("Color")}</label>
                <Field
                  type="text"
                  name="color"
                  tabIndex="0"
                  className={
                    errors.color
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.color && (
                  <div className="input-feedback">{errors.color}</div>
                )}
              </FormGroup>
              <FormGroup>
                <ImageUpload
                  onChange={(newPhoto) => {
                    //setAssetPhoto(newPhoto);
                    setFieldValue("photo", newPhoto);
                  }}
                  title={t("Foto")}
                  file={values.photo}
                />
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">{t("Modelo")}</label>
                <Field
                  type="text"
                  name="model"
                  tabIndex="0"
                  className={
                    errors.model
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.model && (
                  <div className="input-feedback">{errors.model}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">{t("Año")}</label>
                <Field
                  type="number"
                  name="year"
                  tabIndex="0"
                  className={
                    errors.year
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.year && (
                  <div className="input-feedback">{errors.year}</div>
                )}
              </FormGroup>
              <FormGroup>
                {typeMobiles && typeMobiles.length > 0 && (
                  <GenericSelector
                    callbackOptionSelected={(typeMobile) =>
                      setFieldValue("typeMobileId", typeMobile?.value)
                    }
                    columns={columnsTypeMobile}
                    keyField="id"
                    labelField="description"
                    title={t("Tipo de Móvil")}
                    data={typeMobiles}
                    //defaultValue={initialValues?.typeMobileId}
                    defaultValue={values.typeMobileId}
                  />
                )}
                {errors.typeMobileId && (
                  <div className="input-feedback">{errors.typeMobileId}</div>
                )}
              </FormGroup>
              <FormGroup>
                {customers && customers.length > 0 && (
                  <GenericSelector
                    callbackOptionSelected={(customer) =>
                      setFieldValue("customerId", customer?.value)
                    }
                    columns={columnsCustomers}
                    keyField="id"
                    labelField="name"
                    title={t("Cliente")}
                    data={customers}
                    defaultValue={values?.customerId}
                  />
                )}
                {errors.customerId && (
                  <div className="input-feedback">{errors.customerId}</div>
                )}
              </FormGroup>
              <FormGroup>
                {drivers && drivers.length > 0 && (
                  <GenericSelector
                    callbackOptionSelected={(driver) =>
                      setFieldValue("driverId", driver?.value)
                    }
                    columns={columnsDrivers}
                    keyField="id"
                    labelField="fullName"
                    title={t("Conductores")}
                    data={drivers}
                    //defaultValue={initialValues?.driverId}
                    defaultValue={values.driverId}
                  />
                )}
              </FormGroup>
              <FormGroup>
                <ThemeProvider theme={theme}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={values.fuelCutWiring}
                        onChange={(evt) =>
                          setFieldValue("fuelCutWiring", evt.target.checked)
                        }
                        name="fuelCutWiring"
                      />
                    }
                    label={t("Corte de Combustible")}
                  />
                </ThemeProvider>
                {errors.fuelCutWiring && (
                  <div className="input-feedback">{errors.fuelCutWiring}</div>
                )}
              </FormGroup>
              <FormGroup>
                <Field
                  component={KeyboardDateField}
                  id="fuelCutWiringDateId"
                  name="fuelCutWiringDate"
                  label={t("Fecha Instalacion Corte de Combustible")}
                  placeholder={t("Seleccionar")}
                  disableToolbar
                  variant="inline"
                  // format="dd/MM/yyyy"
                  format={GetDateFormat(user.language, true)}
                  margin="normal"
                  value={values.fuelCutWiringDate}
                  onChange={(value) => {
                    setFieldValue("fuelCutWiringDate", value);
                  }}
                />
              </FormGroup>
              <FormGroup>
                <ThemeProvider theme={theme}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={values.cutSolenoidValve}
                        onChange={(evt) =>
                          setFieldValue("cutSolenoidValve", evt.target.checked)
                        }
                        name="cutSolenoidValve"
                      />
                    }
                    label={t("Electrovalvula de corte")}
                  />
                </ThemeProvider>
                {errors.cutSolenoidValve && (
                  <div className="input-feedback">
                    {errors.cutSolenoidValve}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Field
                  component={KeyboardDateField}
                  id="electroValveInstallationDateId"
                  name="electroValveInstallationDate"
                  label={t("Fecha Instalacion Electroválvula de Corte")}
                  placeholder={t("Seleccionar_")}
                  disableToolbar
                  variant="inline"
                  // format="dd/MM/yyyy"
                  format={GetDateFormat(user.language, true)}
                  margin="normal"
                  initialvalues={values.electroValveInstallationDate}
                  value={values.electroValveInstallationDate}
                  onChange={(value) => {
                    setFieldValue("electroValveInstallationDate", value);
                  }}
                />
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">
                  {t("Tensión Principal")}
                </label>
                <Field
                  name="mainTension"
                  component={CustomFormikSelect}
                  options={[
                    { label: "12v", value: "12v" },
                    { label: "24v", value: "24v" },
                  ]}
                  placeholder={t("Seleccionar")}
                />
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">
                  {t("Velocidad Máxima")}
                </label>
                <Field
                  type="number"
                  name="maximumSpeed"
                  tabIndex="0"
                  className={
                    errors.maximumSpeed
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.maximumSpeed && (
                  <div className="input-feedback">{errors.maximumSpeed}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">
                  {t("Odómetro Real Inicial")}
                </label>
                <Field
                  type="number"
                  name="initialRealOdometer"
                  tabIndex="0"
                  // value={initialValues?.initialRealOdometer}
                  className={
                    errors.initialRealOdometer
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.initialRealOdometer && (
                  <div className="input-feedback">
                    {errors.initialRealOdometer}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">
                  {t("Capacidad Tanque")}
                </label>
                <Field
                  type="number"
                  name="fuelTankCapacity"
                  tabIndex="0"
                  className={
                    errors.fuelTankCapacity
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.fuelTankCapacity && (
                  <div className="input-feedback">
                    {errors.fuelTankCapacity}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">
                  {t("Capacidad Máxima Tanque")}
                </label>
                <Field
                  type="number"
                  name="fuelTankCapacityMax"
                  tabIndex="0"
                  className={
                    errors.fuelTankCapacityMax
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.fuelTankCapacityMax && (
                  <div className="input-feedback">
                    {errors.fuelTankCapacityMax}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">
                  {t("Horómetro Real Inicial")}
                </label>
                <Field
                  type="number"
                  name="initialRealHorometer"
                  tabIndex="0"
                  className={
                    errors.initialRealHorometer
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.initialRealHorometer && (
                  <div className="input-feedback">
                    {errors.initialRealHorometer}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <ThemeProvider theme={theme}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={values.satPatAlerts}
                        onChange={(evt) =>
                          setFieldValue("satPatAlerts", evt.target.checked)
                        }
                        name="satPatAlerts"
                      />
                    }
                    label={t("Habilitar Alertas")}
                  />
                </ThemeProvider>
                {errors.satPatAlerts && (
                  <div className="input-feedback">{errors.satPatAlerts}</div>
                )}
              </FormGroup>
              <FormGroup>
                <ThemeProvider theme={theme}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={values.itSupportsOBD2}
                        onChange={(evt) =>
                          setFieldValue("itSupportsOBD2", evt.target.checked)
                        }
                        name="itSupportsOBD2"
                      />
                    }
                    label={t("Soporta OBD2")}
                  />
                </ThemeProvider>
                {errors.itSupportsOBD2 && (
                  <div className="input-feedback">{errors.itSupportsOBD2}</div>
                )}
              </FormGroup>

              <Grid item lg={12} style={{ marginTop: "50px" }}>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  tabIndex="0"
                >
                  {selectedAsset ? t("Actualizar") : t("Crear")}
                </Button>
                <Button
                  variant="contained"
                  type="button"
                  tabIndex="0"
                  style={{ marginLeft: "20px" }}
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  {t("Cancelar")}
                </Button>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

const _mapStateToProps = ({ user }) => {
  return {
    user,
  };
};

const _mapDispatchToProps = (dispatch) => {
  return {
    // _setCurrentUser: (param) => dispatch(setCurrentUser(param)),
  };
};

export default connect(_mapStateToProps, _mapDispatchToProps)(AssetForm);
