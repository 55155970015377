import React from "react";

import { Grid } from "@material-ui/core";

import JourneySummary from "./JourneySummary";
import JourneyDetail from "./JourneyDetail";

const JourneyAsset = ({ data, setPositionArguments }) => {
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <JourneySummary summary={data} />
        </Grid>
        <Grid item xs={12}>
          {data.journeyReports.map((jr, index) => (
            <JourneyDetail
              key={`jrDetail_${data.mid}_${index}`}
              movilID={data.mid}
              journey={jr}
              setPositionArguments={setPositionArguments}
              positions={data.positions}
            />
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default JourneyAsset;
