import Client from "../../components/Base/apiApolloClient";
import TOP_NOTIFICATIONS_QUERY from "../../graphql/queries/topNotificationsQuery";
import READ_NOTIFICATION_MUTATION from "../../graphql/mutations/readNotificationMutation";
import TYPE_NOTIFICATIONS_QUERY from "../../graphql/queries/typeNotificationsQuery";
import ACK_ALARMS_MUTATION from "../../graphql/mutations/ackAlarmsMutation";

import { manageGraphqlError } from "../../utils/authentication";

export const SUCCESS_NOTIFICATION = "SUCCESS_NOTIFICATION";
export const ALERT_NOTIFICATION = "ALERT_NOTIFICATION";
export const DANGER_NOTIFICATION = "DANGER_NOTIFICATION";
export const INFO_NOTIFICATION = "INFO_NOTIFICATION";
export const CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION";
export const SELECT_NOTIFICATION = "SELECT_NOTIFICATION";
export const UNSELECT_NOTIFICATION = "UNSELECT_NOTIFICATION";
export const SELECTALL_NOTIFICATION = "SELECTALL_NOTIFICATION";
export const UNSELECTALL_NOTIFICATION = "UNSELECTALL_NOTIFICATION";
export const GET_TOP_NOTIFICATIONS = "GET_TOP_NOTIFICATIONS";
export const SET_READ_NOTIFICATIONS = "SET_READ_NOTIFICATIONS";
export const SET_NEW_NOTIFICATION = "SET_NEW_NOTIFICATION";
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_NOTIFICATIONS_MORE = "GET_NOTIFICATIONS_MORE";
export const GET_TYPE_NOTIFICATIONS = "GET_TYPE_NOTIFICATIONS";
export const NOTIFICATIONS_CHANGE_PARAM = "NOTIFICATIONS_CHANGE_PARAM";
export const NOTIFICATIONS_CHANGE_PARAM_MORE =
  "NOTIFICATIONS_CHANGE_PARAM_MORE";
export const NOTIFICATIONS_BYASSET = "NOTIFICATIONS_BYASSET";
export const SET_MARKER_SELECTED = "SET_MARKER_SELECTED";
export const REMOVE_ALARMS_ACK = "REMOVE_ALARMS_ACK";

const client = Client;

export const setMarkerSelected = (marker) => ({
  type: SET_MARKER_SELECTED,
  payload: marker,
});

export const successNotification = (message) => ({
  type: SUCCESS_NOTIFICATION,
  payload: message,
});

export const alertNotification = (message) => ({
  type: ALERT_NOTIFICATION,
  payload: message,
});

export const dangerNotification = (message) => ({
  type: DANGER_NOTIFICATION,
  payload: message,
});

export const infoNotification = (message) => ({
  type: INFO_NOTIFICATION,
  payload: message,
});

export const clearNotification = () => ({
  type: CLEAR_NOTIFICATION,
});

export const selectNotification = (messageId) => ({
  type: SELECT_NOTIFICATION,
  payload: messageId,
});

export const unselectNotification = (messageId) => ({
  type: UNSELECT_NOTIFICATION,
  payload: messageId,
});

export const selectAllNotification = () => ({
  type: SELECTALL_NOTIFICATION,
  // payload: messages,
});

export const unSelectAllNotification = () => ({
  type: UNSELECTALL_NOTIFICATION,
  // payload: messages,
});

const _getNotifications = (notifications) => ({
  type: GET_TOP_NOTIFICATIONS,
  payload: notifications,
});

const _getNotificationsPaginated = (notifications) => ({
  type: GET_NOTIFICATIONS,
  payload: notifications,
});

const _getNotificationsPaginatedMore = (payload) => ({
  type: GET_NOTIFICATIONS_MORE,
  payload,
});

const _getNotificationsChangeParam = (payload) => ({
  type: NOTIFICATIONS_CHANGE_PARAM,
  payload,
});

const _getNotificationsChangeParamMore = (notifications) => ({
  type: NOTIFICATIONS_CHANGE_PARAM_MORE,
  payload: notifications,
});

const _setReadNotifications = (notifications) => ({
  type: SET_READ_NOTIFICATIONS,
  payload: notifications,
});

const _removeAlarmsACK = (notifications) => ({
  type: REMOVE_ALARMS_ACK,
  payload: notifications,
});

export const ackAlarms = (notifications, filters) => (dispatch) => {
  client
    .mutate({
      mutation: ACK_ALARMS_MUTATION,
      fetchPolicy: "network-only",
      variables: {
        notificationId: notifications,
      },
    })
    .then(() => {
      client
        .query({
          query: TOP_NOTIFICATIONS_QUERY,
          fetchPolicy: "network-only",
          variables: {
            cant: parseInt(process.env.REACT_APP_CANT_NOTIFICATIONS, 10),
            assetid: filters.assetid,
            event: filters.event,
            onlyUnreaded: filters.onlyUnreaded,
            pageNumber: filters.pageNumber,
            pageSize: filters.pageSize,
            typeNotificationId: filters.typeNotification,
            typeAlarm: filters?.typeAlarm,
          },
        })
        .then((res) => {
          if (res && res.data && res.data.notifications) {
            return dispatch(_getNotificationsPaginated(res.data.notifications));
          }
        })
        .catch((err) => {
          console.log("Error get notifications paginated: ", err);
          manageGraphqlError(err);
        });
      return dispatch(_removeAlarmsACK(notifications));

      // return dispatch(__getNotificationsPaginated());
    })
    .catch((err) => {
      console.log("Error ack alarms: ", err);
    });
  console.log("al final de todo");
};

export const readNotifications = (notifications, filters) => (dispatch) => {
  client
    .mutate({
      mutation: READ_NOTIFICATION_MUTATION,
      variables: {
        notificationId: notifications,
      },
    })
    .then(() => {
      /*if (res && res.data && res.data.markNotificationAsRead) {
        return dispatch(_setReadNotifications(res.data.markNotificationAsRead));
      }*/
      // return dispatch(_setReadNotifications(notifications));

      return dispatch(_removeAlarmsACK(notifications));
    })
    .catch((err) => {
      console.log("Error marking a notification as read: ", err);
    });
};

// export const getNotifications = () => (dispatch) => {
//   client
//     .query({
//       query: TOP_NOTIFICATIONS_QUERY,
//       variables: {
//         cant: parseInt(process.env.REACT_APP_CANT_NOTIFICATIONS, 10),
//       },
//     })
//     .then((res) => {
//       if (res && res.data && res.data.notifications) {
//         return dispatch(_getNotifications(res.data.notifications));
//       }
//     })
//     .catch((err) => {
//       //console.log(err.graphQLErrors);
//       manageGraphqlError(err);
//     });
// };

let notificationsSubscription;
// Get notifications to fill top bar counter
export const getNotifications = () => (dispatch) => {
  console.log("executing getNotifications");
  // const freq = parseInt(process.env.REACT_APP_REFRESH_NOTIFICATIONS_FREQ, 1000);
  notificationsSubscription = client
    .watchQuery({
      query: TOP_NOTIFICATIONS_QUERY,
      variables: {
        cant: parseInt(process.env.REACT_APP_CANT_NOTIFICATIONS, 10),
      },
      pollInterval: 30000,
      // pollInterval: freq,
      context: { useApolloNetworkStatus: false },
    })
    .subscribe((res) => {
      if (res && res.data && res.data.notifications) {
        return dispatch(_getNotifications(res.data.notifications));
      }
    });
  // .subscribe({
  //   next: (res) => {
  //     console.log("dispatching new notifications...", new Date());
  //     console.log("res", res);
  //     if (res && res.data && res.data.notifications) {
  //       return dispatch(_getNotifications(res.data.notifications));
  //     }
  //   },
  //   error: (e) => console.error(e),
  // });
};

let notificationsPaginatedSubscription;

// Get notifications to fill alarms table
export const getNotificationsPaginated = ({
  lastId,
  typeNotification,
  onlyUnreaded,
  isScroll,
  assetid,
  typeAlarm,
  event,
  pageNumber,
  pageSize,
}) => (dispatch) => {
  const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
  const typeAlarmStorage = localStorage.getItem(`typeAlarm_${loggedUser.id}`);

  // si no es paginación, que pullee
  if (!lastId) {
    if (notificationsPaginatedSubscription)
      notificationsPaginatedSubscription.unsubscribe();

    if (notificationsSubscription) notificationsSubscription.unsubscribe();

    // const freq = parseInt(
    //   process.env.REACT_APP_REFRESH_NOTIFICATIONS_FREQ,
    //   1000
    // );

    notificationsPaginatedSubscription = client
      .watchQuery({
        query: TOP_NOTIFICATIONS_QUERY,
        fetchPolicy: "network-only",
        variables: {
          cant: parseInt(process.env.REACT_APP_CANT_NOTIFICATIONS, 10),
          // lastId: lastId,
          onlyUnreaded: onlyUnreaded || undefined,
          typeNotificationId:
            typeNotification === -1 ? undefined : typeNotification,
          assetid: assetid,
          typeAlarm:
            event !== undefined
              ? typeAlarm === -1
                ? undefined
                : typeAlarm
              : typeAlarm === -1
              ? undefined
              : typeAlarm,
          pageNumber,
          pageSize,
          event: event,
        },
        pollInterval: 30000,
        context: { useApolloNetworkStatus: false },
      })
      .subscribe((res) => {
        // TODO: Incluir LOGICA DE FILTROS localStorage.setItem(`typeAlarm_${loggedUser.id}`, type);
        // localStorage.getItem(`event_${loggedUser.id}`);
        // typeNotificationId: 1, typeAlarm: "SOFTWARE"}
        // console.log("res en subscribe paginated", res);
        if (res && res.data && res.data.notifications) {
          return dispatch(
            isScroll || isScroll === undefined
              ? _getNotificationsPaginated(res.data.notifications)
              : _getNotificationsChangeParam(res.data.notifications)
            //_getNotificationsPaginated(res.data.notifications));
          );
        }
      });
  } else {
    client
      .query({
        query: TOP_NOTIFICATIONS_QUERY,
        fetchPolicy: "network-only",
        variables: {
          cant: parseInt(process.env.REACT_APP_CANT_NOTIFICATIONS, 10),
          lastId: lastId,
          onlyUnreaded: onlyUnreaded || undefined,
          typeNotificationId:
            typeNotification === -1 ? undefined : typeNotification,
          assetid: assetid,
          // typeAlarm: typeAlarm,
          typeAlarm:
            event !== undefined ? typeAlarm || typeAlarmStorage : undefined,
          event: event,
        },
        // pollInterval: 10000,
        // context: { useApolloNetworkStatus: false },
      })
      .then((res) => {
        // console.log("isScroll", isScroll);
        if (res && res.data && res.data.notifications) {
          return dispatch(
            isScroll
              ? _getNotificationsPaginatedMore(res.data.notifications)
              : _getNotificationsChangeParam(res.data.notifications)
            // : _getNotificationsChangeParamMore(res.data.notifications)
          );
        }
      })
      .catch((err) => {
        console.log("Error get notifications paginated: ", err);
        manageGraphqlError(err);
      });
  }
};

// export const unsubscribeNotificationsPaginated = () => notificationsPaginatedSubscription.unsubscribe();

const _notificationByAsset = (notifications) => ({
  type: NOTIFICATIONS_BYASSET,
  payload: notifications,
});

export const getNotificationsByAsset = ({
  lastId,
  typeNotification,
  onlyUnreaded,
  assetid,
}) => (dispatch) => {
  client
    .query({
      query: TOP_NOTIFICATIONS_QUERY,
      variables: {
        cant: parseInt(process.env.REACT_APP_CANT_NOTIFICATIONS, 10),
        lastId: lastId,
        onlyUnreaded: onlyUnreaded || undefined,
        typeNotificationId:
          typeNotification === -1 ? undefined : typeNotification,
        assetid: assetid,
      },
    })
    .then((res) => {
      if (res && res.data && res.data.notifications) {
        return dispatch(_notificationByAsset(res.data.notifications));
      }
    })
    .catch((err) => {
      manageGraphqlError(err);
      //console.log("Error get notifications paginated: ", err);
    });
};

const _getTypeNotifications = (types) => ({
  type: GET_TYPE_NOTIFICATIONS,
  payload: types,
});

export const getTypeNotifications = () => (dispatch) => {
  client
    .query({
      query: TYPE_NOTIFICATIONS_QUERY,
    })
    .then((res) => {
      if (res && res.data && res.data.typeNotifications) {
        return dispatch(_getTypeNotifications(res.data.typeNotifications));
      }
    });
};

export const setNewNotification = (notification) => ({
  type: SET_NEW_NOTIFICATION,
  payload: notification,
});
