import gql from "graphql-tag";

const GET_ALL_ASSETS_IN_RADIUS_NEAR_POINT_QUERY = gql`
  query GetAllAssetsInRadiusNearPoint(
    $latitud: Float
    $longitud: Float
    $radius: Int
    $clientId: Int
    $customerIds: [Int!]
  ) {
    getAllAssetsInRadiusNearPoint(
      longitud: $longitud
      latitud: $latitud
      radius: $radius
      clientId: $clientId
      customerIds: $customerIds
    ) {
      id
      type
      name
      patente
      nombreMovil
      latitud
      longitud
      fechaGPS
      distance
    }
  }
`;

export default GET_ALL_ASSETS_IN_RADIUS_NEAR_POINT_QUERY;
