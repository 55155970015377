import gql from "graphql-tag";

const GROUPS_OWN_BYCUSTOMER_QUERY = gql`
  query GroupsOwnByCustomer($customerId: Int!) {
    groupsOwnByCustomer(customerId: $customerId) {
      name
      id
      customerId
      parentGroupId
      externalCustomer {
        id
      }
      assets {
        id
        licensePlate
        name
      }
      users {
        id
        firstName
        lastName
        userName
        email
        roles {
          value: id
          label: name
        }
      }
      customer {
        id
        name
      }
    }
  }
`;

export default GROUPS_OWN_BYCUSTOMER_QUERY;
