import { useQuery } from "@apollo/client";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
} from "@material-ui/core";
import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import CustomFormikSelect from "../../../components/Base/CustomFormikSelect";
import CUSTOMERS_QUERY from "../../../graphql/queries/getCustomersQuery";
import GET_ENUMS_QUERY from "../../../graphql/queries/getEnumsQuery";
import { useTranslation } from "../../../lang";
import Map from "./Map";

const DEFAULT_GENERAL_GEOFENCE_MAXIMUM_SPEED = 0;
const GEOFENCE_TYPES = {
  GENERAL: "General",
  NO_TRAVEL_BOUNDARY: "NoTravelBoundaryAlert",
};
const shouldEnableAlertsInputOutputCheckbox = (selectedGeofence) => {
  switch (selectedGeofence?.geofenceType) {
    case GEOFENCE_TYPES.GENERAL:
      return true;
    case GEOFENCE_TYPES.NO_TRAVEL_BOUNDARY:
      return false;
    default:
      return false;
  }
};
const shouldEnableMaximumVelocityCheckbox = (selectedGeofence) => {
  switch (selectedGeofence?.geofenceType) {
    case GEOFENCE_TYPES.GENERAL:
      return true;
    case GEOFENCE_TYPES.NO_TRAVEL_BOUNDARY:
      return false;
    default:
      return false;
  }
};
const Form = ({
  closeModal,
  selectedGeofence,
  createGeofence,
  updateGeofence,
}) => {
  const [t] = useTranslation();
  const [userLogged, setUserLogged] = useState();
  const [isOwner, setIsOwner] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [hasMaxSpeed, setHasMaxSpeed] = useState(!!selectedGeofence?.maxSpeed);
  const [geofenceTypeEnum, setGeofenceTypeEnum] = useState([]);
  const [selectedGeofenceType, setSelectedGeofenceType] = useState(
    selectedGeofence?.geofenceType
  );
  const [
    isAlertsInputOutputCheckboxEnabled,
    setIsAlertsInputOutputCheckboxEnabled,
  ] = useState(shouldEnableAlertsInputOutputCheckbox(selectedGeofence));
  const [
    isHasMaximumVelocityCheckboxEnabled,
    setIsHasMaximumVelocityCheckboxEnabled,
  ] = useState(shouldEnableMaximumVelocityCheckbox(selectedGeofence));

  useEffect(() => {
    if (selectedGeofenceType === GEOFENCE_TYPES.NO_TRAVEL_BOUNDARY) {
      setIsAlertsInputOutputCheckboxEnabled(false);
      setIsHasMaximumVelocityCheckboxEnabled(false);
    }

    if (selectedGeofenceType === GEOFENCE_TYPES.GENERAL) {
      setIsAlertsInputOutputCheckboxEnabled(true);
      setIsHasMaximumVelocityCheckboxEnabled(true);
    }
  }, [selectedGeofenceType]);

  useEffect(() => {
    const userStr = localStorage.getItem("loggedUser");
    if (userStr) {
      const user = JSON.parse(userStr);
      setUserLogged(user);
      setIsOwner(user.roles.some((r) => r.isOwner));
    }
  }, []);

  useQuery(GET_ENUMS_QUERY, {
    variables: {
      name: "GeofenceTypeEnum",
    },
    onCompleted(d) {
      const options = d.__type.enumValues.map((e, i) => {
        return { label: t(e.label), value: e.label };
      });

      setGeofenceTypeEnum(options);
    },
  });

  useQuery(CUSTOMERS_QUERY, {
    onCompleted(d) {
      setCustomers(
        d.customers.items.map((c) => ({
          label: c.name,
          value: c.id,
        }))
      );
    },
  });

  const initialValues = {
    id: selectedGeofence?.id,
    name: selectedGeofence?.name,
    customerId: selectedGeofence
      ? selectedGeofence.customerId
      : isOwner
      ? undefined
      : userLogged?.customer?.id,
    showOnMap: selectedGeofence ? selectedGeofence.showOnMap : true,
    maxSpeed: selectedGeofence ? selectedGeofence.maxSpeed : 0,
    alertsInputOutput: selectedGeofence
      ? selectedGeofence.alertsInputOutput
      : false,
    geom: selectedGeofence?.geomText,
    geomJson: selectedGeofence?.geomJson,
    geofenceType: selectedGeofence?.geofenceType,
  };

  const yupSchema = Yup.object().shape({
    name: Yup.string().required(t("Requerido")),
    customerId: Yup.number().required(t("Requerido")),
    geom: Yup.string().required(t("Requerido")),
    geofenceType: Yup.string().required(t("Requerido")),
  });

  return (
    <>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={yupSchema}
        onSubmit={async (formValues) => {
          delete formValues.geomJson;

          let payloadValues = formValues;
          if (selectedGeofenceType === GEOFENCE_TYPES.NO_TRAVEL_BOUNDARY) {
            payloadValues = {
              ...payloadValues,
              alertsInputOutput: false,
              maxSpeed: 0,
            };
          }

          if (!hasMaxSpeed) {
            payloadValues = {
              ...payloadValues,
              maxSpeed: DEFAULT_GENERAL_GEOFENCE_MAXIMUM_SPEED,
            };
          }

          if (selectedGeofence) {
            updateGeofence(payloadValues);
          } else {
            createGeofence(payloadValues);
          }
        }}
      >
        {(props) => {
          const { values, errors, handleSubmit, setFieldValue } = props;
          return (
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <label className="formLabelFormik">{t("Cliente")}</label>
                {isOwner ? (
                  <>
                    <Field
                      name="customerId"
                      component={CustomFormikSelect}
                      options={customers}
                    />
                    {errors.customerId && (
                      <div className="input-feedback">{errors.customerId}</div>
                    )}
                  </>
                ) : (
                  <label>{userLogged?.customer?.name}</label>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">
                  {t("Nombre Geocerca")}
                </label>
                <Field
                  type="text"
                  name="name"
                  tabIndex="0"
                  className={
                    errors.name
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                />
                {errors.name && (
                  <div className="input-feedback">{errors.name}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">
                  {t("Tipo de Geocerca")}
                </label>
                <Field
                  name="geofenceType"
                  component={CustomFormikSelect}
                  options={geofenceTypeEnum}
                  onChange={(option) => {
                    setSelectedGeofenceType(option.value);
                  }}
                />
                {errors.geofenceType && (
                  <div className="input-feedback">{errors.geofenceType}</div>
                )}
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={values.showOnMap}
                      onChange={(evt) =>
                        setFieldValue("showOnMap", evt.target.checked)
                      }
                      name="showOnMap"
                    />
                  }
                  label={t("Mostrar en mapa")}
                />
              </FormGroup>
              <FormGroup>
                {isAlertsInputOutputCheckboxEnabled && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={values.alertsInputOutput}
                        onChange={(evt) =>
                          setFieldValue("alertsInputOutput", evt.target.checked)
                        }
                        name="showOnMap"
                      />
                    }
                    label={t("Tiene alarmas E/S")}
                  />
                )}
              </FormGroup>
              <FormGroup>
                {isHasMaximumVelocityCheckboxEnabled && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={hasMaxSpeed}
                        onChange={(evt) => setHasMaxSpeed(evt.target.checked)}
                        //name="maxSpeed"
                      />
                    }
                    label={t("Tiene máxima velocidad")}
                  />
                )}
              </FormGroup>
              {hasMaxSpeed && isHasMaximumVelocityCheckboxEnabled && (
                <FormGroup style={{ marginBottom: "20px" }}>
                  <label className="formLabelFormik">
                    {t("Máxima Velocidad")}
                  </label>
                  <Field
                    type="number"
                    name="maxSpeed"
                    tabIndex="0"
                    className={
                      errors.name
                        ? "form-control text-input error"
                        : "form-control text-input"
                    }
                  />
                  {errors.name && (
                    <div className="input-feedback">{errors.name}</div>
                  )}
                </FormGroup>
              )}

              <Grid item lg={12}>
                <Map
                  handleChangeDraw={(wkt) => {
                    // console.log("Wkt: ", wkt);
                    setFieldValue("geom", wkt);
                  }}
                  selectedGeometry={values.geomJson}
                />
                {errors.geom && (
                  <div className="input-feedback">
                    {errors.geom}
                    <br />
                    {t(
                      "Por favor dibuje el poligono correspondiente a la geocerca"
                    )}
                  </div>
                )}
              </Grid>
              <Grid
                item
                lg={12}
                style={{ marginTop: "50px" }} /*className="footerSlidePanel"*/
              >
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  tabIndex="0"
                >
                  {selectedGeofence ? t("Actualizar") : t("Crear")}
                </Button>
                <Button
                  variant="contained"
                  type="button"
                  tabIndex="0"
                  style={{ marginLeft: "20px" }}
                  onClick={() => {
                    closeModal();
                  }}
                >
                  {t("Cancelar")}
                </Button>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default Form;
