import React, { useEffect, useState } from "react";
import { Grid, Snackbar } from "@material-ui/core";
import { useTranslation } from "../../../lang";
import { DownloadExcelButton, DownloadPDFButton } from "./DownloadButtons";
import { useLazyQuery } from "@apollo/client";
import FLEET_STATE_EXPORT_QUERY, {
  SUPPORTED_EXPORT_FILE_TYPES,
} from "../../../graphql/queries/fleetStateExportQuery";
import { downloadExcel, downloadPDF } from "../../../utils/generalUtils";
import MuiAlert from "@material-ui/lab/Alert";

const FlotaStateExportPanel = ({ assets }) => {
  const [t] = useTranslation();
  const [disableExport, setDisableExport] = useState(false);
  const [resultState, setResultState] = useState({
    isOpen: false,
    type: "",
    msg: "",
  });

  const [getExport] = useLazyQuery(FLEET_STATE_EXPORT_QUERY, {
    fetchPolicy: "no-cache",
    onCompleted: (d) => {
      const filename = t("ReporteEstadoFlota");
      switch (d.fleetStatusReportExport.fileType) {
        case SUPPORTED_EXPORT_FILE_TYPES.EXCEL:
          downloadExcel(d.fleetStatusReportExport.filePayload, filename);
          break;
        case SUPPORTED_EXPORT_FILE_TYPES.PDF:
          downloadPDF(d.fleetStatusReportExport.filePayload, filename);
          break;
        default:
          setResultState({
            isOpen: true,
            type: "error",
            msg: t(
              "error_al_intentar_guardar_el_archivo_descargado_formato_no_soportado"
            ),
          });
      }
      setDisableExport(false);
    },
    onError: (error) => {
      setResultState({
        isOpen: true,
        type: "error",
        msg: t("Error al intentar descargar archivo"),
      });
      setDisableExport(false);
    },
  });

  const handleExportBase = (idSelectedAssets, fileType) => {
    setDisableExport(true);
    const promise = getExport({
      variables: {
        fileType: fileType,
        assetsSelected: idSelectedAssets,
      },
    });

    // @WARNING It avoids application freeze after Apollo client throws network errors.
    promise.catch(() => {
      setDisableExport(false);
    });
  };

  const mapAssetsToIdList = (assets = []) => {
    return assets.map((asset) => asset.mid);
  };

  const handleExportPDF = () => {
    const assetsId = mapAssetsToIdList(assets);
    handleExportBase(assetsId, SUPPORTED_EXPORT_FILE_TYPES.PDF);
  };

  const handleExportExcel = () => {
    const assetsId = mapAssetsToIdList(assets);
    handleExportBase(assetsId, SUPPORTED_EXPORT_FILE_TYPES.EXCEL);
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setResultState({
      ...resultState,
      isOpen: false,
    });
  };

  return (
    <>
      <Snackbar
        open={resultState.isOpen}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleCloseNotification}
          severity={resultState.type}
          variant="filled"
        >
          {resultState.msg}
        </MuiAlert>
      </Snackbar>

      <div className="flotaStateExportPanel">
        <Grid container justifyContent="flex-end">
          <DownloadPDFButton
            onClick={handleExportPDF}
            disabled={disableExport}
          />

          <DownloadExcelButton
            onClick={handleExportExcel}
            disabled={disableExport}
          />
        </Grid>
      </div>
    </>
  );
};

export default FlotaStateExportPanel;
