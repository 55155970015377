import React from "react";
import "./style.scss";

const OBD2DisplayGrid = ({children}) => {
    return (
        <div class="obd2-display-grid">
            {children}
        </div>
    )
}

export default OBD2DisplayGrid;