import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ClearIcon from "@material-ui/icons/Clear";
import CloseIcon from "@material-ui/icons/Close";
import classNames from "classnames";
import React from "react";
import InfoContent from "../../components/Map/MarkerInfo/InfoContent";
import AdministrationPanel from "../AdministrationPanel";
import DrivingBehaviorReport from "../DrivingBehaviorReport";
import EstadoFlotaPanel from "../EstadoFlotaPanel";
import JourneyReportPanel from "../JourneyReportPanel";
import NotificationPanel from "../NotificationPanel";
import RevisionPanel from "../RevisionsPanel";
import StatisticsPanel from "../StatisticsPanel";
import { useTranslation } from "../../lang";

const Panel = ({
  panelType,
  openPanel,
  closePanel,
  markerInfo,
  markerJourney,
  openPanelAux,
  closePanelAux,
  onChangeTab,
  toggleFillMarkerJourney,
  toggleFillMarkerJourney2,
  toggleFillMarkerJourneyPersonalizado,
  toggleFillMarkerInfoAux,
  toggleFillGenericPopup,
  tabActive,
  changePanelType,
  title,
  seeAllFleet,
  updateMarkerJourney,
}) => {
  const [t] = useTranslation();
  const expandablePanelClassnames = classNames("expandable-panel", {
    "expandable-panel--open": true,
    "expandable-panel--journeyReport":
      ["JourneyReport", "Mantenimiento"].includes(panelType) ||
      (panelType === "GeneralInfo" && [1, 3].includes(tabActive)),
    "expandable-panel--openDrivingBehavior": panelType == "DrivingBehavior",
    "expandable-panel--openNotification":
      panelType === "Notification" ||
      (panelType === "GeneralInfo" && tabActive === 2),
    [`expandable-panel--left--level-1`]: 1,
  });

  const _closeButton = (
    <div
      onClick={() => {
        //changePanelType("GeneralInfo");
        closePanel();
        // console.log("Closing panel");
        onChangeTab(0);
        updateMarkerJourney({});
      }}
    >
      <IconButton
        //component={Link}
        //to={panelCloseRedirectTo}
        className="expandable-panel__close-btn"
        // {...panelAuxActions}
      >
        <CloseIcon />
      </IconButton>
    </div>
  );

  const renderTitle = () => {
    let panelTitle = "";
    switch (panelType) {
      case "Notification":
        panelTitle = t("Notificaciones");
        break;
      case "Mantenimiento":
        panelTitle = t("Mantenimiento");
        break;
      case "Administration":
        panelTitle = t("Administración");
        break;
      case "JourneyReport":
        panelTitle = t("Reporte de Recorridos");
        break;
      case "EstadoFlota":
        panelTitle = t("Estado de Flota");
        break;
      case "Estadisticas":
        panelTitle = t("Estadísticas");
        break;
      case "DrivingBehavior":
        panelTitle = t("Conductas de Manejo");
        break;
      default:
        panelTitle = markerInfo ? markerInfo.markerInfo.pat : "";
        break;
    }
    return panelTitle;
  };

  const renderContent = () => {
    // console.log("Panel type: ", panelType);
    let content = <div />;
    switch (panelType) {
      case "Notification":
        content = <NotificationPanel />;
        break;
      case "Administration":
        content = <AdministrationPanel />;
        break;
      case "JourneyReport":
        content = <JourneyReportPanel />;
        break;
      case "EstadoFlota":
        content = <EstadoFlotaPanel />;
        break;
      case "Estadisticas":
        content = <StatisticsPanel />;
        break;
      case "DrivingBehavior":
        content = <DrivingBehaviorReport />;
        break;
      case "Mantenimiento":
        content = <RevisionPanel />;
        break;
      case "GeneralInfo":
        content = (
          <InfoContent
            markerInfo={markerInfo.markerInfo}
            markerJourney={markerJourney.markerJourney}
            //markerNotifications={markerNotifications}
            //markerRevisions={markerRevisions}
            toggleActivePanelAux={openPanelAux}
            onChangeTab={onChangeTab}
            //toggleActivePanel={toggleActivePanel}
            toggleFillMarkerInfoAux={toggleFillMarkerInfoAux}
            toggleFillMarkerJourney2={toggleFillMarkerJourney2}
            toggleFillMarkerJourney={toggleFillMarkerJourney}
            toggleFillMarkerJourneyPersonalizado={
              toggleFillMarkerJourneyPersonalizado
            }
            toggleFillGenericPopup={toggleFillGenericPopup}
            updateMarkerJourney={updateMarkerJourney}
          />
        );
    }
    return content;
  };

  return (
    <section className={expandablePanelClassnames}>
      <div className="expandable-panel__title">
        <Typography
          component="div"
          className="expandable-panel__title-text title-format"
        >
          {renderTitle()}
          {panelType === "DrivingBehavior" && title && (
            <>
              <span className="subtitlePanelDB" style={{ marginLeft: "34%" }}>
                {title}
              </span>
              <IconButton
                style={{ padding: 0, marginLeft: "10px" }}
                onClick={() => seeAllFleet(true)}
              >
                <ClearIcon style={{ color: "white" }} />
              </IconButton>
            </>
          )}
        </Typography>
        {_closeButton}
      </div>
      <div
        className={
          "expandable-panel__content" && panelType === "Notification"
            ? "expandable-panel--openNotification"
            : ""
        }
      >
        {/*children*/}
        {renderContent()}
      </div>
    </section>
  );
};

export default Panel;
