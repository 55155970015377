import React, { useState } from "react";

import { useMutation } from "@apollo/client";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormGroup,
  Grid,
  Snackbar,
} from "@material-ui/core";

import MuiAlert from "@material-ui/lab/Alert";

import * as Yup from "yup";

import CustomFormikSelect from "../../../components/Base/CustomFormikSelect";

import { Field, Formik } from "formik";

import CREATE_USER_MUTATION from "../../../graphql/mutations/createUserMutation";

import { useTranslation } from "../../../lang";

const ModalSubUser = ({
  isOpen,
  closeModal,
  user,
  group,
  customerId,
  handleFetchGroups,
}) => {
  const [t] = useTranslation();
  const [resultState, setResultState] = useState({
    isOpen: false,
    type: "success",
    msg: "",
  });

  let availableRoles = [];

  const userStr = localStorage.getItem("loggedUser");
  let userLogged;
  if (userStr) {
    const userLogged = JSON.parse(userStr);
    availableRoles = userLogged.roles.map((r) => ({
      label: r.name,
      value: r.id,
    }));
  }

  const initialValues = {
    userName: user?.userName,
    password: "",
    confirmPassword: "",
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email,
    roles: user?.roles,
  };

  const yupSchema = Yup.object().shape({
    userName: Yup.string().required(t("Requerido")),
    email: Yup.string().email(t("Email invalido")),
    lastName: Yup.string().required(t("Requerido")),
    firstName: Yup.string().required(t("Requerido")),
    roles: Yup.string().required(t("Requerido")),
  });

  const [createUser] = useMutation(CREATE_USER_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Usuario creado correctamente"),
      });
      handleFetchGroups && handleFetchGroups();
    },
    onError(err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: err.message,
      });
    },
  });

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setResultState({
      ...resultState,
      isOpen: false,
    });
  };

  const handleCreateUser = (userPayload) => {
    // console.log("On handleCreateUser");
    createUser({
      variables: userPayload,
    });
  };

  return (
    <>
      <Snackbar
        open={resultState.isOpen}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleCloseNotification}
          severity={resultState.type}
          variant="filled"
        >
          {resultState.msg}
        </MuiAlert>
      </Snackbar>
      <Dialog open={isOpen} onClose={closeModal} maxWidth="sm" fullWidth>
        <DialogTitle className="dialogTitle">
          {user ? t("Editar Usuario") : t("Nuevo Usuario")}
        </DialogTitle>
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={yupSchema}
          onSubmit={async (values) => {
            // console.log("Values: ", values);
            if (user) {
              //Update user
            } else {
              //Create user
              // console.log("Creando usuario");
              if (values.password !== values.confirmPassword) {
                setResultState({
                  isOpen: true,
                  type: "error",
                  msg: t("Las contraseñas no coinciden"),
                });
                return;
              }

              if (!group) {
                setResultState({
                  isOpen: true,
                  type: "error",
                  msg: t("Seleccione un grupo"),
                });
                return;
              }

              delete values.confirmPassword;
              const userStr = localStorage.getItem("loggedUser");
              const loggedUser = JSON.parse(userStr);

              handleCreateUser({
                ...values,
                userName: `${loggedUser.userName}.${values.userName}`,
                roles: [values.roles],
                customerId: customerId,
                groupId: group.id,
              });
            }
          }}
        >
          {(props) => {
            const { values, errors, handleSubmit, setFieldValue } = props;
            return (
              <form onSubmit={handleSubmit}>
                <DialogContent>
                  <Grid container>
                    <Grid item xs={6} className="subUserFormContainer">
                      <FormGroup>
                        <label>{t("Nombre")}</label>
                        <Field
                          type="text"
                          tabIndex={0}
                          name="firstName"
                          autocomplete="off"
                          className={
                            errors.firstName
                              ? "form-control text-input error"
                              : "form-control text-input"
                          }
                        />
                        {errors.firstName && (
                          <div className="input-feedback">
                            {errors.firstName}
                          </div>
                        )}
                      </FormGroup>
                    </Grid>
                    <Grid item xs={6} className="subUserFormContainer">
                      <FormGroup>
                        <label>{t("Nueva contraseña")}</label>
                        <Field
                          type="password"
                          tabIndex={0}
                          name="password"
                          className={
                            errors.password
                              ? "form-control text-input error"
                              : "form-control text-input"
                          }
                        />
                        {errors.password && (
                          <div className="input-feedback">
                            {errors.password}
                          </div>
                        )}
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={6} className="subUserFormContainer">
                      <FormGroup>
                        <label>{t("Apellido")}</label>
                        <Field
                          type="text"
                          tabIndex={0}
                          autocomplete="off"
                          name="lastName"
                          className={
                            errors.lastName
                              ? "form-control text-input error"
                              : "form-control text-input"
                          }
                        />
                        {errors.lastName && (
                          <div className="input-feedback">
                            {errors.lastName}
                          </div>
                        )}
                      </FormGroup>
                    </Grid>
                    <Grid item xs={6} className="subUserFormContainer">
                      <FormGroup>
                        <label>{t("Confirmación de contraseña")}</label>
                        <Field
                          type="password"
                          tabIndex={0}
                          autocomplete="off"
                          name="confirmPassword"
                          className={
                            errors.confirmPassword
                              ? "form-control text-input error"
                              : "form-control text-input"
                          }
                        />
                        {errors.confirmPassword && (
                          <div className="input-feedback">
                            {errors.confirmPassword}
                          </div>
                        )}
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={6} className="subUserFormContainer">
                      <FormGroup>
                        <label>{t("Correo Electrónico")}</label>
                        <Field
                          type="email"
                          tabIndex={0}
                          name="email"
                          autocomplete="off"
                          className={
                            errors.email
                              ? "form-control text-input error"
                              : "form-control text-input"
                          }
                        />
                        {errors.email && (
                          <div className="input-feedback">{errors.email}</div>
                        )}
                      </FormGroup>
                    </Grid>
                    <Grid item xs={6} className="subUserFormContainer">
                      <FormGroup>
                        <label>{t("Perfil")}</label>
                        <Field
                          name="roles"
                          tabIndex={0}
                          component={CustomFormikSelect}
                          isMulti
                          className={
                            errors.roles
                              ? "form-control text-input error"
                              : "form-control text-input"
                          }
                          options={availableRoles}
                        />
                        {errors.roles && (
                          <div className="input-feedback">{errors.roles}</div>
                        )}
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={6} className="subUserFormContainer">
                      <FormGroup>
                        <label>{t("Usuario")}</label>
                        <Field
                          type="text"
                          tabIndex={0}
                          autocomplete="off"
                          name="userName"
                          className={
                            errors.userName
                              ? "form-control text-input error"
                              : "form-control text-input"
                          }
                        />
                        {errors.userName && (
                          <div className="input-feedback">
                            {errors.userName}
                          </div>
                        )}
                      </FormGroup>
                    </Grid>
                    <Grid item xs={6} />
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    onClick={() => closeModal()}
                  >
                    {t("Cancelar")}
                  </Button>
                  <Button type="submit" color="primary" variant="contained">
                    {t("Guardar")}
                  </Button>
                </DialogActions>
              </form>
            );
          }}
        </Formik>
      </Dialog>
    </>
  );
};

export default ModalSubUser;
