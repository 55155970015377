import gql from "graphql-tag";

const TYPE_MOBILES_QUERY = gql`
  query TypeMobileQuery {
    typeMobile {
      id
      description
    }
  }
`;

export default TYPE_MOBILES_QUERY;
