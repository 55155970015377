import * as React from "react";

function MotoIcon(props) {
  return (
    <div style={{ justifyContent: "center" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height={24}
        width={24}
        viewbox="0 0 24 24"
        {...props}
      >
        <path fill="none" />
        <path d="M20 11c-.18 0-.36.03-.53.05L17.41 9H20V6l-3.72 1.86L13.41 5H9v2h3.59l2 2H11l-4 2-2-2H0v2h4c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4l2 2h3l3.49-6.1 1.01 1.01c-.91.73-1.5 1.84-1.5 3.09 0 2.21 1.79 4 4 4s4-1.79 4-4-1.79-4-4-4zM4 17c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm16 0c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" />
      </svg>
    </div>
  );
}

export default React.memo(MotoIcon);
