import React from "react";
import { FeatureGroup, Polygon, Polyline } from "react-leaflet";
import { POLYGON_TYPES } from "./types.d";

export const ROAD_PATH_COLORS = {
  RED: "red",
  PURPLE: "purple",
  BLUE: "blue",
};
const RoadPath = ({ road = null, color = ROAD_PATH_COLORS.RED }) => {
  // console.log("road en RoadPath", road);
  const routePositions =
    road && road.decodedPolyline.length > 0 ? road.decodedPolyline : [];
  const polygonOptions = { color: ROAD_PATH_COLORS.BLUE, weight: 1 };
  const pathOptions = { color, weight: 1 };
  const polygonPositions = road.bufferedDecodedOverviewPolyline || [];
  return (
    <div className="road-path">
      <FeatureGroup>
        {road && polygonOptions.length > 0 && (
          <Polygon
            pathOptions={polygonOptions}
            positions={polygonPositions}
            data={{ type: POLYGON_TYPES.PATH_BUFFER }}
          />
        )}
        {road && routePositions.length > 0 && (
          <Polyline
            pathOptions={pathOptions}
            positions={routePositions}
            data={{ type: POLYGON_TYPES.PATH }}
          />
        )}
      </FeatureGroup>
    </div>
  );
};
export default RoadPath;
