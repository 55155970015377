import React, {useState} from "react";
import "./style.scss";

const useOBD2Modal = () => {
    const [ isOpen, setIsOpen] = useState(false);

    const closeOBD2Modal = () => setIsOpen(false)
    
    const openOBD2Modal = () => setIsOpen(true)

    const toggleOBD2Modal = () => setIsOpen(open => !open)

    return {
        isOpen,
        closeOBD2Modal,
        openOBD2Modal,
        toggleOBD2Modal
    }
}

export default useOBD2Modal;