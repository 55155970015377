import {
  ADD_SEARCH_OPTIONS,
  ADD_GEOFENCE_TO_MAP,
  REMOVE_GEOFENCE_FROM_MAP,
  ADD_ASSET_TO_MULTIPLE_TRACKING,
  REMOVE_ASSET_FROM_MULTIPLE_TRACKING,
} from "./action";

const initialState = {
  searchOptions: [],
  geofences: [],
  assetsMultipleTracking: [],
};

function merge(a, b, prop) {
  var reduced = a.filter(
    (aitem) => !b.find((bitem) => aitem[prop] === bitem[prop])
  );
  return reduced.concat(b);
}

const baseReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_SEARCH_OPTIONS:
      // const ids = new Set(state.searchOptions.map((d) => d.value));

      return {
        ...state,
        searchOptions: merge(state.searchOptions, payload, "value"),
      };
    case ADD_GEOFENCE_TO_MAP:
      if (state.geofences.indexOf(payload) === -1)
        return {
          ...state,
          geofences: [
            ...state.geofences.map((g) => ({ ...g, isNew: false })),
            { ...payload, isNew: true },
          ],
        };
      else return state;
    case REMOVE_GEOFENCE_FROM_MAP:
      return {
        ...state,
        geofences: state.geofences.filter((g) => g.geoJson !== payload.geoJson),
      };
    case ADD_ASSET_TO_MULTIPLE_TRACKING:
      if (state.assetsMultipleTracking.length <= 3)
        return {
          ...state,
          assetsMultipleTracking: [...state.assetsMultipleTracking, payload],
        };
      else return state;

    case REMOVE_ASSET_FROM_MULTIPLE_TRACKING:
      const assetToRemove = state.assetsMultipleTracking.indexOf(payload);

      return {
        ...state,
        assetsMultipleTracking: [
          ...state.assetsMultipleTracking.slice(0, assetToRemove),
          ...state.assetsMultipleTracking.slice(assetToRemove + 1),
        ],
      };

    default:
      return state;
  }
};

export default baseReducer;
