import React from 'react';
import PropTypes from 'prop-types';

import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import ManageNavbar from './ManageNavbar';

import './Structure.scss';

const Structure = ({ children }) => {
    let childComponent = null;

    if (children) {
        childComponent = (
            <Container maxWidth="xl">
                <Card className="pad-4" variant="outlined">
                    <CardContent>{children}</CardContent>
                </Card>
            </Container>
        );
    }

    return (
        <div className="structure">
            <ManageNavbar />

            <div className="structure__body structure__body--internal-scroll">
                {childComponent}
            </div>
        </div>
    );
};

Structure.propTypes = {
    children: PropTypes.node.isRequired
};

export default Structure;
