import gql from "graphql-tag";

const GET_REPORTTYPES_QUERY = gql`
  query GetReportTypes {
    getReportTypes {
      value
      description
    }
  }
`;

export default GET_REPORTTYPES_QUERY;
