import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import List from "./List";

import GET_CONF_NOTIFICATIONS from "../../../graphql/queries/getConfNotificationQuery";
import CREATE_CONFNOTIFICATION_MUTATION from "../../../graphql/mutations/createConfNotificationMutation";
import UPDATE_CONFNOTIFICATION_MUTATION from "../../../graphql/mutations/updateConfNotificationMutation";
import DELETE_CONFNOTIFICATION_MUTATION from "../../../graphql/mutations/deleteConfNotificationMutation";
import { useLazyQuery, useMutation } from "@apollo/client";
import Structure from "../../../components/Base/Structure";
import PageTitle from "../../../components/Base/PageTitle";
import "./Style.scss";
import { useTranslation } from "../../../lang";

const ManageNotificationConfiguration = ({ selectedCustomers }) => {
  const [t] = useTranslation();
  const paginationSizes = [10, 25, 50];
  const [total, setTotal] = useState(0);
  const [confNotificationList, setConfNotificationList] = useState([]);
  const [confNotificationSelected, setConfNotificationSelected] = useState([]);
  const [paginationState, setPaginationState] = useState({
    pageSize: paginationSizes[0],
    pageNumber: 1,
    sort: undefined,
    filter: null,
  });

  const [resultState, setResultState] = useState({
    isOpen: false,
    type: "success",
    msg: "",
  });

  const [getConfNotification] = useLazyQuery(GET_CONF_NOTIFICATIONS, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      const { items, count } = d.getConfigurationNotificationList || {};
      if (items) {
        setConfNotificationList(items);
        setTotal(count);
      }
    },
  });

  const handleGetConfNotifications = () => {
    console.log("Selected customers: ", selectedCustomers);
    getConfNotification({
      variables: {
        ...paginationState,
        customerIds: selectedCustomers.map((c) => c.value),
        paginate: true,
      },
    });
  };

  useEffect(() => {
    if (Array.isArray(selectedCustomers) && selectedCustomers.length > 0) {
      handleGetConfNotifications();
    }
  }, [paginationState, selectedCustomers]);

  const [createConfNotification] = useMutation(
    CREATE_CONFNOTIFICATION_MUTATION,
    {
      onCompleted(d) {
        setResultState({
          isOpen: true,
          type: "success",
          msg: t("ConfNotifCreated"),
        });
        handleGetConfNotifications();
      },
      onError(err) {
        setResultState({
          isOpen: true,
          type: "error",
          msg: err.message,
        });
      },
    }
  );

  const [updateConfNotification] = useMutation(
    UPDATE_CONFNOTIFICATION_MUTATION,
    {
      onCompleted(d) {
        setResultState({
          isOpen: true,
          type: "success",
          msg: t("ConfNotifUpdated"),
        });
        handleGetConfNotifications();
      },
      onError(err) {
        setResultState({
          isOpen: true,
          type: "error",
          msg: err.message,
        });
      },
    }
  );

  const [deleteConfNotification] = useMutation(
    DELETE_CONFNOTIFICATION_MUTATION,
    {
      onCompleted(d) {
        setResultState({
          isOpen: true,
          type: "success",
          msg: t("ConfNotifDeleted"),
        });
        handleGetConfNotifications();
      },
      onError(d) {
        setResultState({
          isOpen: true,
          type: "error",
          msg: t("Ocurrió un error"),
        });
      },
    }
  );

  const handleDeleteConfNotification = (id) => {
    deleteConfNotification({
      variables: { id: id },
    });
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setResultState({
      ...resultState,
      isOpen: false,
    });
  };

  return (
    <Structure>
      <Snackbar
        open={resultState.isOpen}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleCloseNotification}
          severity={resultState.type}
          variant="filled"
        >
          {resultState.msg}
        </MuiAlert>
      </Snackbar>
      <PageTitle sector={t("Administración")} title={t("ConfNotifications")} />
      <List
        getConfNotification={handleGetConfNotifications}
        confNotificationList={confNotificationList}
        paginationSizes={paginationSizes}
        paginationState={paginationState}
        setPaginationState={setPaginationState}
        total={total}
        setResultState={setResultState}
        createConfNotification={createConfNotification}
        updateConfNotification={updateConfNotification}
        deleteConfNotification={handleDeleteConfNotification}
      />
    </Structure>
  );
};
const _mapStateToProps = ({ groups }) => {
  return {
    selectedCustomers: groups.selectedCustomers,
  };
};

export default connect(_mapStateToProps, null)(ManageNotificationConfiguration);
