import {
  CLIENT_GET_STARTED,
  CLIENT_GET_SUCCESS,
  CLIENT_GET_ERROR,
  CLIENT_SHOW_ADD_MODAL,
  CLIENT_SHOW_EDIT_MODAL,
  CLIENT_ADD_STARTED,
  CLIENT_ADD_SUCCESS,
  CLIENT_ADD_ERROR,
  CLIENT_EDIT_STARTED,
  CLIENT_EDIT_SUCCESS,
  CLIENT_EDIT_ERROR,
  CLIENT_DELETE_STARTED,
  CLIENT_DELETE_SUCCESS,
  CLIENT_DELETE_ERROR,
} from "./actions";

export const SUBMIT_CLIENT_FORM = "SUBMIT_CLIENT_FORM";
export const SUBMIT_CLIENT_FORM_STARTED = "SUBMIT_CLIENT_FORM_STARTED";
export const SUBMIT_CLIENT_FORM_SUCCESS = "SUBMIT_CLIENT_FORM_SUCCESS";
export const SUBMIT_CLIENT_FORM_ERROR = "SUBMIT_CLIENT_FORM_ERROR";

const initialState = {
  loading: false,
  error: "",
  clients: [],
  showAddModal: false,
  showEditModal: false,
};

const client = (state = initialState, { type, payload }) => {
  switch (type) {
    case CLIENT_SHOW_ADD_MODAL:
      return {
        ...state,
        showAddModal: !state.showAddModal,
        error: "",
      };
    case CLIENT_SHOW_EDIT_MODAL:
      return {
        ...state,
        showEditModal: !state.showEditModal,
        error: "",
      };
    case CLIENT_DELETE_STARTED:
    case CLIENT_EDIT_STARTED:
    case CLIENT_ADD_STARTED:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case CLIENT_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        clients: state.clients.filter((x) => x._id !== payload),
      };
    case CLIENT_EDIT_SUCCESS:
      const index = state.clients.findIndex((x) => x._id === payload._id);
      if (index === -1) {
        return state;
      } else {
        return {
          ...state,
          loading: false,
          error: "",
          showEditModal: false,
          clients: [
            ...state.clients.slice(0, index),
            payload,
            ...state.clients.slice(index + 1),
          ],
        };
      }
    case CLIENT_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        clients: [...state.clients, payload],
        showAddModal: false,
        error: "",
      };
    case CLIENT_GET_STARTED:
      return {
        ...state,
        loading: true,
        error: "",
        clients: [],
      };
    case CLIENT_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        clients: payload,
      };
    case CLIENT_DELETE_ERROR:
    case CLIENT_EDIT_ERROR:
    case CLIENT_ADD_ERROR:
    case CLIENT_GET_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }

  // return state;
};

export default client;
