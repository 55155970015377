import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import TextField from "@material-ui/core/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import ConnectedUsersButton from "../../containers/NavbarTop/ConnectedUsersButton";
import { setMarkerSelected } from "../../redux/notifications/actions";
import GroupsButton from "../Menu/GroupsButton";
import NotificationsButton from "../Menu/NotificationsButton";
import "./NavbarTop.scss";
import SearchBarTypes from "../../enums/searchBarTypes";
import { addGeofenceToMapFromSearchOptions } from "../../redux/base/action";
import TrackChangesIcon from "@material-ui/icons/TrackChanges";
import { useTranslation } from "../../lang";
import { allFunctions } from "../../enums/functions";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import HubspotForm from "react-hubspot-form";
// import notificationsReducer from "../../redux/notifications/reducer";

const NavbarTop = ({
  options,
  assetsMultipleTracking,
  // markerSelected,
  setMarkerSelected,
  addGeofenceToMap,
}) => {
  const [t] = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const [isOpen, setisOpen] = useState(false);
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: allFunctions.length,
  });

  const pageTitle = process.env.REACT_APP_PAGE_TITLE;
  const [inputValue, setInputValue] = React.useState("");
  const [value, setValue] = React.useState(null);

  return (
    <AppBar position="static" className="navbar-base navbar--top">
      <Typography component="h1" variant="h4" className="navbar__title">
        <Link to="/map">
          <div id="navbar-top-brand-logo">
            <img src="/assets/img/logo.svg" alt={t(pageTitle)} />
          </div>
        </Link>
      </Typography>

      <Toolbar className="navbar__content">
        {location.pathname === "/map" && (
          <Autocomplete
            filterOptions={filterOptions}
            freeSolo
            id="search-bar"
            selectOnFocus
            autoComplete
            autoHighlight
            blurOnSelect="mouse"
            disableClearable
            options={options.sort((a, b) => -b.type.localeCompare(a.type))}
            groupBy={(opt) => opt.type}
            getOptionLabel={(opt) => opt.label || ""}
            clearOnEscape
            value={value}
            inputValue={inputValue}
            clearOnBlur={true}
            clearText="Limpiar"
            classes={{
              listbox: "searchListbox",
              clearIndicator: "searchSeparator",
            }}
            renderGroup={(g) => {
              return [
                <div className="searchCategory" key={g.key}>
                  <label className="catLabel">{t(g.group)}</label>
                </div>,
                g.children,
              ];
            }}
            renderOption={(opt) => {
              return (
                <div className="searchOption" key={opt.value}>
                  <div className={`searchStatus type_${opt.estado}`} />
                  {/* <div style={{ width: "10px", height: "10px", backgroundColor: "red", borderRadius: "50%", display: "inline-block", marginRight: "15px" }} />*/}
                  <label>{opt.label}</label>
                </div>
              );
            }}
            onChange={(evt, args) => {
              if (args) {
                const { label, value, type } = args;
                switch (type) {
                  case SearchBarTypes.FUNCTIONS:
                    history.push(value);
                    break;
                  case SearchBarTypes.ASSETS:
                    setMarkerSelected(value);
                    break;
                  case SearchBarTypes.GEOFENCES:
                    addGeofenceToMap({ name: label, geoJson: value });
                    break;
                  default:
                    break;
                }
                setValue(value);
              }
            }}
            onInputChange={(_, v) => setInputValue(v)}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  id="navbar-search"
                  className="navbar__content-search"
                  label={t("Buscar móviles, funciones, geocercas")}
                  margin="normal"
                />
              );
            }}
          />
        )}

        <div className="navbar__content-menu">
          {assetsMultipleTracking && assetsMultipleTracking.length > 0 && (
            <div
              title={t("Ir a Seguimiento Múltiple")}
              style={{ display: "flex" }}
            >
              <TrackChangesIcon
                style={{ color: "#DE7351" }}
                onClick={() => history.push("/mtracking")}
                cursor="pointer"
              />
              <div
                style={{
                  fontSize: "8px",
                  color: "#DE7351",
                  alignSelf: "flex-end",
                }}
              >
                {assetsMultipleTracking.length}
              </div>
            </div>
          )}
          <Button
            style={{ color: "#FFFFFF", background: "#1111C8" }}
            //color="primary"
            variant="contained"
            onClick={() => setisOpen(true)}
          >
            {t("Get Support")}
          </Button>
          <Dialog
            open={isOpen}
            onClose={() => setisOpen(false)}
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle className="dialogTitle">
              {t("Get Support")}
            </DialogTitle>
            <DialogContent>
              <HubspotForm
                portalId="8807224"
                formId="a5b787e7-f69c-4a26-957f-e960319ad273"
                onSubmit={() => console.log("Submit!")}
                onReady={() => console.log("Form ready!")}
                loading={<div>{t("Cargando")}</div>}
              />
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => setisOpen(false)}
              >
                {t("Cerrar")}
              </Button>
            </DialogActions>
          </Dialog>

          <NotificationsButton />

          <GroupsButton />

          <ConnectedUsersButton />
        </div>
      </Toolbar>
    </AppBar>
  );
};

const _mapStateToProps = ({ base, notifications }) => ({
  options: base.searchOptions,
  assetsMultipleTracking: base.assetsMultipleTracking,
  // markerSelected: notifications.markerSelected,
});

const _mapDispatchToProps = (dispatch) => ({
  setMarkerSelected: (marker) => dispatch(setMarkerSelected(marker)),
  addGeofenceToMap: (geofence) =>
    dispatch(addGeofenceToMapFromSearchOptions(geofence)),
  // removeGeofenceFromMap: (geofence) =>
  //   dispatch(removeGeofenceFromMap(geofence)),
});

export default connect(_mapStateToProps, _mapDispatchToProps)(NavbarTop);
