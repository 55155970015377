import gql from "graphql-tag";

const CREATE_POI_MUTATION = gql`
  mutation CreatePoi(
    $customerId: Int
    $name: String
    $latitude: Float
    $longitude: Float
    $radio: Float
    $poiCategoryId: Int
    $expirationDate: Datetime
    $iconId: Int
  ) {
    createPoi(
      customerId: $customerId
      name: $name
      latitude: $latitude
      longitude: $longitude
      radio: $radio
      poiCategoryId: $poiCategoryId
      expirationDate: $expirationDate
      iconId: $iconId
    ) {
      id
    }
  }
`;

export default CREATE_POI_MUTATION;
