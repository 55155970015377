import React, { useState } from "react";

import { Paper, Button } from "@material-ui/core";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import ModalAssets from "./ModalAssets";
import ThreeDotMenu from "../../../components/Base/ThreeDotMenu";

import { useTranslation } from "../../../lang";
const theme = createMuiTheme({});

const AssetPanel = ({
  group,
  allGroups,
  handleAssignAsset,
  handleUnassignAsset,
  loggedUser,
}) => {
  const [t] = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [assetsSelected, setAssetsSelected] = useState([]);
  const closeModal = () => setIsOpen(false);
  const [assetOver, setAssetOver] = useState();
  const assets = group?.assets || [];

  const thisParent =
    group && allGroups
      ? allGroups.find((x) => x.id === group.parentGroupId)
      : null;
  const assetsAvailable = thisParent?.assets || [];

  //const assetsAvailable = group && allGroups ? allGroups.find(x => x.id === group.parentGroupId).assets : []

  const handleAddAsset = (rows) => {
    handleAssignAsset(group.id, rows);
  };

  const assetActionValues = {
    REMOVE_ASSET: 1,
  };

  const assetActions = [
    {
      label: "Remove asset",
      value: assetActionValues.REMOVE_ASSET,
    },
  ];

  const handleMouseEnterAsset = (assetId) => {
    setAssetOver(assetId);
  };

  const callbackAssetAction = (action, asset) => {
    switch (action) {
      case assetActionValues.REMOVE_ASSET:
        handleUnassignAsset(group, [asset.id]);
        break;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <ModalAssets
        assetsList={assetsAvailable}
        closeModal={closeModal}
        isOpen={isOpen}
        onSelect={handleAddAsset}
      />
      <div className="groupContainer">
        <div>
          <h5 className="groupContainerLabel">{t("Móviles")}</h5>
        </div>
        <div>
          <h6 className="groupContainerLabel">
            {t("pages_manage_groups_moviles_pertenecientes_al_grupo", {
              groupName: group?.name || "",
            })}
          </h6>
        </div>
        <div style={{ paddingBottom: "16px" }}>
          {/* <IconButton
            className="groupActionIcon"
            onClick={() => setIsOpen(true)}
            title={t("Busqueda de móviles")}
            disabled={
              loggedUser?.customer?.id !== group?.customerId &&
              !group?.parentGroupId
            }
          >
            <Add />
          </IconButton>
          <IconButton
            className="groupActionIcon"
            title={t("Quitar movil")}
            onClick={() => {
              if (Array.isArray(assetsSelected) && assetsSelected.length > 0) {
                handleUnassignAsset(group, assetsSelected);
              }
            }}
            disabled={
              loggedUser?.customer?.id !== group?.customerId &&
              !group?.parentGroupId
            }
          >
            <Remove />
          </IconButton> */}
          <Button
            variant="contained"
            color="primary"
            title={t("Quitar todos los móviles")}
            onClick={() => {
              if (Array.isArray(assets) && assets.length > 0) {
                handleUnassignAsset(
                  group,
                  assets.map((a) => a.id)
                );
              }
            }}
            disabled={
              loggedUser?.customer?.id !== group?.customerId &&
              !group?.parentGroupId
            }
          >
            Remove all assets
          </Button>
        </div>
        <Paper elevation={3} className="groupPaper">
          <ul className="listAssets">
            {assets.map((a) => (
              <li
                key={a.id}
                className={assetsSelected.includes(a.id) && "assetSelected"}
                onMouseEnter={() => handleMouseEnterAsset(a.id)}
                onMouseLeave={() => handleMouseEnterAsset(null)}
                onClick={() => {
                  const index = assetsSelected.findIndex((x) => x === a.id);
                  setAssetsSelected(
                    index === -1
                      ? [...assetsSelected, a.id]
                      : assetsSelected.filter((x) => x !== a.id)
                  );
                }}
              >
                {a.name}
                {a.id === assetOver && (
                  <ThreeDotMenu
                    row={a}
                    buttonClass="alignRight"
                    options={assetActions}
                    callback={callbackAssetAction}
                  />
                )}
              </li>
            ))}
          </ul>
        </Paper>
      </div>
    </ThemeProvider>
  );
};

export default AssetPanel;
