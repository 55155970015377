import gql from "graphql-tag";

const UPDATE_ASSET_ECOTRACKING_MUTATION = gql`
  mutation updateEcoTrackingMutation(
    $id: Int!
    $assetId: Int!
    $ConsumptionRangeOne: Int!
    $ConsumptionRangeTwo: Int!
    $ConsumptionRangeThree: Int!
    $ConsumptionIdle: Int!
    $CO2Emissions: Int!
  ) {
    updateEcoTracking(
      id: $id
      assetId: $assetId
      ConsumptionRangeOne: $ConsumptionRangeOne
      ConsumptionRangeTwo: $ConsumptionRangeTwo
      ConsumptionRangeThree: $ConsumptionRangeThree
      ConsumptionIdle: $ConsumptionIdle
      CO2Emissions: $CO2Emissions
    ) {
      id
    }
  }
`;

export default UPDATE_ASSET_ECOTRACKING_MUTATION;
