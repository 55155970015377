import { gql } from "@apollo/client";
import Client from "../../components/Base/apiApolloClient";
import clientSubscription from "../../components/Base/apiApolloClientSubscription";

const client = Client;

export const UPDATE_MARKERS = "UPDATE_MARKERS";
export const UPDATE_MARKERS_INITIAL = "UPDATE_MARKERS_INITIAL";

// const loginFailure = (error)=>({
// type: LOGIN_FAILURE,
// payload:{​​​​error}
// }​​​​);

const GET_MARKERS_QUERY = gql`
  query Points {
    points {
      id
      patente
      nombreMovil
      nombreCliente
      nombreChofer
      latitud
      longitud
      velocidad
      curso
      fechaGPS
      estadoID
      combustible
    }
  }
`;

const GET_MARKERS_SUBSCRIPTION = gql`
  subscription {
    newPoint {
      id
      patente
      nombreMovil
      nombreCliente
      nombreChofer
      latitud
      longitud
      velocidad
      curso
      fechaGPS
      estadoID
      combustible
    }
  }
`;

export const fetchMarkersActions = () => (dispatch, getState) => {
  // const {
  //   user: { token },
  // } = getState();

  clientSubscription
    .subscribe({
      query: GET_MARKERS_SUBSCRIPTION,
      // variables: { repoFullName: repoName },
    })
    .subscribe({
      next(res) {
        let markers = res.data.newPoint;
        if (markers) {
          // debugger;
          dispatch({
            type: UPDATE_MARKERS,
            payload: markers,
          });
        }
      },
      error(err) {
        console.error("err", err);
      },
    });

  // client
  //   .query({
  //     query: GET_MARKERS_QUERY,
  //   })
  //   .then(
  //     (res) => {
  //       let markers = res.data.points;
  //       dispatch({
  //         type: UPDATE_MARKERS,
  //         payload: markers,
  //       });
  //     },
  //     (error) => {
  //       // dispatch(loginFailure(error.toString()));
  //     }
  //   );
};

export const fetchMarkersInitialActions = () => (dispatch, getState) => {
  // const {
  //   user: { token },
  // } = getState();

  // const { subscribeToMore, ...result } = useQuery(
  //   GET_MARKERS_QUERY,
  //   // { variables: { postID: params.postID } }
  //   {
  //     onCompleted(res) {
  //       let markers = res.data.points;
  //       dispatch({
  //         type: UPDATE_MARKERS_INITIAL,
  //         payload: markers,
  //       });
  //     },
  //   }
  // );

  client
    .query({
      query: GET_MARKERS_QUERY,
    })
    .then(
      (res) => {
        let markers = res.data.points;
        dispatch({
          type: UPDATE_MARKERS_INITIAL,
          payload: markers,
        });

        dispatch({
          type: "UPDATE_ISLOADING_MARKERS_INITIAL",
          payload: false,
        });

        clientSubscription
          .subscribe({
            query: GET_MARKERS_SUBSCRIPTION,
            // variables: { repoFullName: repoName },
          })
          .subscribe({
            next(res) {
              let markers = res.data.newPoint;
              if (markers) {
                // debugger;
                dispatch({
                  type: UPDATE_MARKERS,
                  payload: markers,
                });
              }
            },
            error(err) {
              console.error("err", err);
            },
          });
      },
      (error) => {
        // dispatch(loginFailure(error.toString()));
        dispatch({
          type: "UPDATE_ISLOADING_MARKERS_INITIAL",
          payload: false,
        });
      }
    );
};
