import React, { useState, useEffect } from "react";

import { MapContainer, Polygon, TileLayer } from "react-leaflet";
import Leaflet from "leaflet";
import RoadPath from "./RoadPath";
import { POLYGON_TYPES } from "./types.d";

import "leaflet/dist/leaflet.css";
const DEFAULT_CENTER_MAP = process.env.REACT_APP_DEFAULT_CENTER_MAP.split(",");

const GeofenceMap = ({ geofences, road, roadStops }) => {
  const [isPathBufferDeletionEnabled, setIsPathBufferDeltionEnabled] = useState(
    false
  );
  const defaultPosition = DEFAULT_CENTER_MAP;
  const [map, setMap] = useState();

  const cleanMap = () => {
    map.eachLayer((l) => {
      if (l instanceof Leaflet.Polygon) {
        if (
          l.options.data?.type !== POLYGON_TYPES.PATH_BUFFER &&
          l.options.data?.type !== POLYGON_TYPES.STOP
        ) {
          map.removeLayer(l);
        }
        if (
          l.options.data?.type === POLYGON_TYPES.PATH_BUFFER &&
          isPathBufferDeletionEnabled
        ) {
          map.removeLayer(l);
        }
      }
    });
  };

  useEffect(() => {
    if (!map) return;

    cleanMap();
    if (Array.isArray(geofences) && geofences.length > 0) {
      const geofenceGroup = new Leaflet.FeatureGroup();
      geofences.forEach((g) => {
        if (g && g.geomJson) {
          const parsedJson = JSON.parse(g.geomJson);
          const geoJSON = Leaflet.geoJson(parsedJson).addTo(map);
          geofenceGroup.addLayer(geoJSON);
        }
      });
      map.fitBounds(geofenceGroup.getBounds());
    }
  }, [map, geofences]);

  return (
    <MapContainer
      center={defaultPosition}
      zoom={13}
      scrollWheelZoom={false}
      style={{ width: "550px", height: "400px" }}
      whenCreated={(instance) => {
        setMap(instance);
      }}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {/* @TODO refactor polygon mapping into its own component */}
      {roadStops &&
        roadStops.map((stop) => {
          const positions = JSON.parse(stop.geom);
          return (
            <Polygon
              positions={positions}
              data={{ type: POLYGON_TYPES.STOP }}
              key={stop.id}
              color="red"
            />
          );
        })}
      {road && <RoadPath road={road} />}
    </MapContainer>
  );
};

export default GeofenceMap;
