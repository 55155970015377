import { Grid } from "@material-ui/core";
import React from "react";
import { useTranslation } from "../../lang";
import { secondsToTime } from "../../utils/generalUtils";

const JourneySummary = ({ summary }) => {
  const [t] = useTranslation();
  const { velMax, tiempoRecorrido, kmsRec, odo, tRal, name, horo } = summary;

  return (
    <Grid container>
      <Grid
        item
        xs={6}
        className="summaryCard"
        style={{
          borderRight: "1px solid #ffffff99",
          borderBottom: "1px solid #ffffff99",
          borderTop: "1px solid #ffffff99",
        }}
      >
        <label className="summaryTitle">{t("Móvil")}</label>
        <label className="summaryValue">{name}</label>
      </Grid>
      <Grid
        item
        xs={6}
        className="summaryCard"
        style={{
          borderBottom: "1px solid #ffffff99",
          borderTop: "1px solid #ffffff99",
        }}
      >
        <label className="summaryTitle">{t("Velocidad Máxima")}</label>
        <label className="summaryValue">
          {Math.round(velMax)} {t("kmh")}
        </label>
      </Grid>
      <Grid
        item
        xs={6}
        className="summaryCard"
        style={{
          borderRight: "1px solid #ffffff99",
          borderBottom: "1px solid #ffffff99",
        }}
      >
        <label className="summaryTitle">{t("Tiempo recorrido")}</label>
        <label className="summaryValue">{secondsToTime(tiempoRecorrido)}</label>
      </Grid>
      <Grid
        item
        xs={6}
        className="summaryCard"
        style={{ borderBottom: "1px solid #ffffff99" }}
      >
        <label className="summaryTitle">{t("Kilometros recorridos")}</label>
        <label className="summaryValue">
          {Math.round(kmsRec * 100) / 100} {t("km")}
        </label>
      </Grid>
      <Grid
        item
        xs={6}
        className="summaryCard"
        style={{
          borderRight: "1px solid #ffffff99",
          borderBottom: "1px solid #ffffff99",
        }}
      >
        <label className="summaryTitle">{t("Kilometraje")}</label>
        <label className="summaryValue">
          {odo} {t("km")}
        </label>
      </Grid>
      <Grid
        item
        xs={6}
        className="summaryCard"
        style={{
          borderBottom: "1px solid #ffffff99",
        }}
      >
        <label className="summaryTitle">{t("Tiempo ralenti")}</label>
        <label className="summaryValue">{secondsToTime(tRal)}</label>
      </Grid>
      <Grid
        item
        xs={6}
        className="summaryCard"
        style={{ borderRight: "1px solid #ffffff99" }}
      >
        <label className="summaryTitle">{t("Horómetro")}</label>
        <label className="summaryValue">{secondsToTime(horo)}</label>
      </Grid>
      <Grid item xs={6} className="summaryCard">
        {/* <label className="summaryTitle">{t("Tiempo ralenti")}</label>
        <label className="summaryValue">{secondsToTime(tRal)}</label> */}
      </Grid>
    </Grid>
  );
};

export default JourneySummary;
