import React from "react";
import PropTypes from "prop-types";

import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";

import StyledSelect from "../StyledSelect";

export * from "./ReduxDateFields";

export const ReduxTextField = ({
  label,
  placeholder,
  input,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <TextField
    label={label}
    placeholder={placeholder}
    error={touched && invalid}
    helperText={touched && error}
    InputLabelProps={{ shrink: true }}
    {...input}
    {...custom}
  />
);

ReduxTextField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  input: PropTypes.any.isRequired,
  meta: PropTypes.object,
};

ReduxTextField.defaultProps = {
  label: "",
  placeholder: "",
  meta: {},
};

// touched, invalid and error has not been tested yet.
export const ReduxStyledSelectField = ({
  input,
  meta: { touched, invalid },
  ...custom
}) => {
  return (
    <StyledSelect
      error={touched && invalid}
      // helperText={touched && error}
      {...input}
      {...custom}
    />
  );
};

ReduxStyledSelectField.propTypes = {
  input: PropTypes.any.isRequired,
  meta: PropTypes.object,
};

ReduxStyledSelectField.defaultProps = {
  meta: {},
};

export const ReduxCheckbox = ({ input, label }) => (
  <div>
    <FormControlLabel
      control={<Checkbox checked={!!input.value} onChange={input.onChange} />}
      label={label}
    />
  </div>
);

ReduxCheckbox.propTypes = {
  label: PropTypes.string,
  input: PropTypes.any.isRequired,
};

ReduxCheckbox.defaultProps = {
  label: "",
};

export const ReduxRadioButton = ({ input, ...rest }) => (
  <FormControl>
    <RadioGroup {...input} {...rest}>
      <FormControlLabel value="female" control={<Radio />} label="Female" />
      <FormControlLabel value="male" control={<Radio />} label="Male" />
      <FormControlLabel value="other" control={<Radio />} label="Other" />
    </RadioGroup>
  </FormControl>
);

ReduxRadioButton.propTypes = {
  input: PropTypes.any.isRequired,
};

const reduxFromHelper = ({ touched, error }) => {
  if (!(touched && error)) {
    return null;
  }

  return <FormHelperText>{touched && error}</FormHelperText>;
};

reduxFromHelper.propTypes = {
  touched: PropTypes.bool,
  error: PropTypes.bool,
};

reduxFromHelper.defaultProps = {
  touched: false,
  error: false,
};

export const ReduxSelectField = ({
  input,
  label,
  meta: { touched, error },
  children,
  ...custom
}) => (
  <FormControl error={touched && error}>
    <InputLabel htmlFor="age-native-simple">Age</InputLabel>
    <Select
      native
      {...input}
      {...custom}
      inputProps={{
        name: "age",
        id: "age-native-simple",
      }}
    >
      {children}
    </Select>
    {reduxFromHelper({ touched, error })}
  </FormControl>
);

ReduxSelectField.propTypes = {
  label: PropTypes.string,
  input: PropTypes.any.isRequired,
  meta: PropTypes.object,
  children: PropTypes.node,
};

ReduxSelectField.defaultProps = {
  label: "",
  meta: {},
  children: null,
};
