import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { useQuery, useLazyQuery } from "@apollo/client";

//import mockData from "../../__fixtures__/fakeConductaManejoReport.json";

import { AppBar, Tabs, Tab, Grid, withStyles } from "@material-ui/core";

import "./styles.scss";

import Filter from "./Filter";
import AssetTable from "./AssetTable";
import DriverTable from "./DriverTable";
import ChartsPanel from "./ChartsPanel";

import {
  changePanelAuxTitle,
  seeAllFleet,
} from "../../redux/lateralPanel/actions";

import DRIVING_BEHAVIOR_QUERY from "../../graphql/queries/drivingBehaviorQuery";
import ASSETS_BYGROUPS_QUERY from "../../graphql/queries/assetByGroupsQuery";
import DRIVERS_PAGINATED_QUERY from "../../graphql/queries/getDriversPaginatedQuery";
import { useTranslation } from "../../lang";

import { APP_COLORS, SEMANTIC_COLORS } from "./../../Theme";

const DrivingBehaviorReport = ({
  changePanelAuxTitle,
  selectedGroups,
  seeAllFleet,
  allFleetIsVisible,
}) => {
  const [t] = useTranslation();
  const [tabSelected, setTabSelected] = useState(0);
  const [title, setTitle] = useState("Conductores");
  const [selectedData, setSelectedData] = useState([]);
  const [infractions, setInfractions] = useState();
  //const [fleetInfractions, setFleetInfractions] = useState();
  const [scoreData, setScoreData] = useState();

  const [assetList, setAssetList] = useState([]);
  const [driverList, setDriverList] = useState([]);

  const [reportData, setReportData] = useState();

  const [assetTableState, setAssetTableState] = useState({
    searchText: "",
    sort: undefined,
    paginate: false,
  });

  const [fetchReport] = useLazyQuery(DRIVING_BEHAVIOR_QUERY, {
    onCompleted(d) {
      setReportData(d.drivingBehaviorsReport);
    },
  });

  const handleFetchReport = (assets, drivers, fechaInicio, fechaFin, days) => {
    fetchReport({
      variables: {
        assetsSelected: assets,
        driversSelected: drivers,
        fechaInicio: fechaInicio,
        fechaFin: fechaFin,
        days: days,
      },
    });
  };

  const {} = useQuery(ASSETS_BYGROUPS_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      groupIDs: selectedGroups.filter(
        (g) => g && !Number.isNaN(Number.parseInt(g))
      ),
      ...assetTableState,
    },
    onCompleted(d) {
      const { count, items } = d.assetsByGroups;
      //setTotal(count);
      setAssetList(items);
    },
  });

  const {} = useQuery(DRIVERS_PAGINATED_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      paginate: false,
    },
    onCompleted(d) {
      setDriverList(d.driversPaginated.items);
    },
  });

  useEffect(() => {
    if (!allFleetIsVisible || !reportData) return;

    const dates = [...new Set(reportData.details.map((item) => item.date))];

    const driverDetail = dates.map((d) => {
      const thisDayFleet = reportData.details.filter((dAll) => dAll.date === d);
      //In theory thisDayFleet always will have elements
      const promFleetScore =
        thisDayFleet.map((s) => s.score).reduce((a, b) => a + b, 0) /
        thisDayFleet.length;
      const promFleetKmsRec =
        thisDayFleet.map((s) => s.kmsRec).reduce((a, b) => a + b, 0) /
        thisDayFleet.length;
      const promConductionTime =
        thisDayFleet.map((s) => s.conductionTime).reduce((a, b) => a + b, 0) /
        thisDayFleet.length;
      const promFuel =
        thisDayFleet.map((s) => s.cmbConsumed).reduce((a, b) => a + b, 0) /
        thisDayFleet.length;
      const promEficiency =
        thisDayFleet.map((s) => s.eficiency).reduce((a, b) => a + b, 0) /
        thisDayFleet.length;
      return {
        date: d,
        //score: d.score,
        fleetScore: parseInt(promFleetScore),
        //kmsRec: d.kmsRec,
        fleetKmsRec: parseInt(promFleetKmsRec),
        fleetConductionTime: parseFloat(promConductionTime / 3600),
        //conductionTime: parseFloat(d.conductionTime / 3600),
        fleetFuel: parseFloat(promFuel),
        //fuel: d.cmbConsumed,
        fleetEficiency: promEficiency,
        //eficiency: d.eficiency,
      };
    });

    setSelectedData(driverDetail);

    const promAB =
      reportData.summary
        .map((s) => s.suddenAcelerations)
        .reduce((a, b) => a + b, 0) / reportData.summary.length;
    const promGB =
      reportData.summary.map((s) => s.sharpTurn).reduce((a, b) => a + b, 0) /
      reportData.summary.length;
    const promFF =
      reportData.summary.map((s) => s.hardBrakings).reduce((a, b) => a + b, 0) /
      reportData.summary.length;
    const promEV =
      reportData.summary
        .map((s) => s.velocityExcess)
        .reduce((a, b) => a + b, 0) / reportData.summary.length;

    const infractionArray = [
      {
        infraction: "AB",
        //quantity: driverSummary.suddenAccelerations,
        promFleet: promAB,
      },
      {
        infraction: "GB",
        //quantity: driverSummary.sharpTurn,
        promFleet: promGB,
      },
      {
        infraction: "FF",
        //quantity: driverSummary.hardBarkings,
        promFleet: promFF,
      },
      {
        infraction: "EV",
        //quantity: driverSummary.velocityExcess,
        promFleet: promEV,
      },
    ];

    setInfractions(infractionArray);

    const { minScore, maxScore, driverProms } = reportData;

    setScoreData({
      minScore,
      maxScore,
      //score: scoreProm,
      scoreFleet: driverProms.scoreProm,
    });
  }, [allFleetIsVisible, reportData]);

  const assetRowEvents = {
    onClick: (e, row, rowIndex) => {
      const assetSummary = reportData.summary.find(
        (x) => x.assetId === row.assetId
      );

      changePanelAuxTitle(assetSummary.assetName);
      seeAllFleet(false);

      const promAB =
        reportData.summary
          .map((s) => s.suddenAcelerations)
          .reduce((a, b) => a + b, 0) / reportData.summary.length;
      const promGB =
        reportData.summary.map((s) => s.sharpTurn).reduce((a, b) => a + b, 0) /
        reportData.summary.length;
      const promFF =
        reportData.summary
          .map((s) => s.hardBrakings)
          .reduce((a, b) => a + b, 0) / reportData.summary.length;
      const promEV =
        reportData.summary
          .map((s) => s.velocityExcess)
          .reduce((a, b) => a + b, 0) / reportData.summary.length;

      const infractionArray = [
        {
          infraction: "AB",
          quantity: assetSummary.suddenAccelerations,
          promFleet: promAB,
        },
        {
          infraction: "GB",
          quantity: assetSummary.sharpTurn,
          promFleet: promGB,
        },
        {
          infraction: "FF",
          quantity: assetSummary.hardBarkings,
          promFleet: promFF,
        },
        {
          infraction: "EV",
          quantity: assetSummary.velocityExcess,
          promFleet: promEV,
        },
      ];

      setInfractions(infractionArray);

      const { minScore, maxScore, assetProms } = reportData;
      const { scoreProm } = assetSummary;

      setScoreData({
        minScore,
        maxScore,
        score: scoreProm,
        scoreFleet: assetProms.scoreProm,
      });

      const assetData = reportData.details.filter(
        (x) => x.assetId === row.assetId
      );
      const assetName = assetSummary.assetName;
      setTitle(assetName);
      const assetDetail = assetData.map((d) => {
        const thisDayFleet = reportData.details.filter(
          (dAll) => dAll.date === d.date
        );
        //In theory thisDayFleet always will have elements
        const promFleetScore =
          thisDayFleet.map((s) => s.score).reduce((a, b) => a + b, 0) /
          thisDayFleet.length;
        const promFleetKmsRec =
          thisDayFleet.map((s) => s.kmsRec).reduce((a, b) => a + b, 0) /
          thisDayFleet.length;
        const promConductionTime =
          thisDayFleet.map((s) => s.conductionTime).reduce((a, b) => a + b, 0) /
          thisDayFleet.length;
        const promFuel =
          thisDayFleet.map((s) => s.cmbConsumed).reduce((a, b) => a + b, 0) /
          thisDayFleet.length;
        const promEficiency =
          thisDayFleet.map((s) => s.eficiency).reduce((a, b) => a + b, 0) /
          thisDayFleet.length;
        return {
          date: d.date,
          score: d.score,
          fleetScore: parseInt(promFleetScore),
          kmsRec: d.kmsRec,
          fleetKmsRec: parseInt(promFleetKmsRec),
          fleetConductionTime: parseFloat(promConductionTime / 3600),
          conductionTime: parseFloat(d.conductionTime / 3600),
          fleetFuel: parseFloat(promFuel),
          fuel: d.cmbConsumed,
          fleetEficiency: promEficiency,
          eficiency: d.eficiency,
        };
      });
      setSelectedData(assetDetail);
    },
  };

  const driverRowEvents = {
    onClick: (e, row, rowIndex) => {
      const driverSummary = reportData.summary.find(
        (x) => x.driverId === row.driverId
      );

      changePanelAuxTitle(driverSummary.driverName);
      seeAllFleet(false);

      const promAB =
        reportData.summary
          .map((s) => s.suddenAcelerations)
          .reduce((a, b) => a + b, 0) / reportData.summary.length;
      const promGB =
        reportData.summary.map((s) => s.sharpTurn).reduce((a, b) => a + b, 0) /
        reportData.summary.length;
      const promFF =
        reportData.summary
          .map((s) => s.hardBrakings)
          .reduce((a, b) => a + b, 0) / reportData.summary.length;
      const promEV =
        reportData.summary
          .map((s) => s.velocityExcess)
          .reduce((a, b) => a + b, 0) / reportData.summary.length;

      const infractionArray = [
        {
          infraction: "AB",
          quantity: driverSummary.suddenAccelerations,
          promFleet: promAB,
        },
        {
          infraction: "GB",
          quantity: driverSummary.sharpTurn,
          promFleet: promGB,
        },
        {
          infraction: "FF",
          quantity: driverSummary.hardBarkings,
          promFleet: promFF,
        },
        {
          infraction: "EV",
          quantity: driverSummary.velocityExcess,
          promFleet: promEV,
        },
      ];

      setInfractions(infractionArray);

      const { minScore, maxScore, driverProms } = reportData;
      const { scoreProm } = driverSummary;

      setScoreData({
        minScore,
        maxScore,
        score: scoreProm,
        scoreFleet: driverProms.scoreProm,
      });

      const driverData = reportData.details.filter(
        (x) => x.driverId === row.driverId
      );
      const driverName = driverSummary.driverName;
      setTitle(driverName);
      const driverDetail = driverData.map((d) => {
        const thisDayFleet = reportData.details.filter(
          (dAll) => dAll.date === d.date
        );
        //In theory thisDayFleet always will have elements
        const promFleetScore =
          thisDayFleet.map((s) => s.score).reduce((a, b) => a + b, 0) /
          thisDayFleet.length;
        const promFleetKmsRec =
          thisDayFleet.map((s) => s.kmsRec).reduce((a, b) => a + b, 0) /
          thisDayFleet.length;
        const promConductionTime =
          thisDayFleet.map((s) => s.conductionTime).reduce((a, b) => a + b, 0) /
          thisDayFleet.length;
        const promFuel =
          thisDayFleet.map((s) => s.cmbConsumed).reduce((a, b) => a + b, 0) /
          thisDayFleet.length;
        const promEficiency =
          thisDayFleet.map((s) => s.eficiency).reduce((a, b) => a + b, 0) /
          thisDayFleet.length;
        return {
          date: d.date,
          score: d.score,
          fleetScore: parseInt(promFleetScore),
          kmsRec: d.kmsRec,
          fleetKmsRec: parseInt(promFleetKmsRec),
          fleetConductionTime: parseFloat(promConductionTime / 3600),
          conductionTime: parseFloat(d.conductionTime / 3600),
          fleetFuel: parseFloat(promFuel),
          fuel: d.cmbConsumed,
          fleetEficiency: promEficiency,
          eficiency: d.eficiency,
        };
      });
      setSelectedData(driverDetail);
    },
  };

  const StyledTabs = withStyles({
    root: {
      backgroundColor: APP_COLORS.APP_BASE,
    },
  })((props) => <Tabs {...props} />);

  const StyledTab = withStyles({
    root: {
      textTransform: "none",
      "&$selected": {
        backgroundColor: SEMANTIC_COLORS.PRIMARY,
      },
    },
    selected: {},
  })((props) => <Tab {...props} />);

  //style={{ backgroundColor: index === value ? '#2B6CDB' : '#1C3663', textTransform: 'none' }}

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabDB-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <div className="tabPanelContainer">{children}</div>}
      </div>
    );
  };

  return (
    <>
      <Grid container>
        <Grid item xs={5}>
          <Filter
            driverList={driverList}
            assetList={assetList}
            fetchReport={handleFetchReport}
          />
          {reportData && (
            <>
              <AppBar position="static">
                <StyledTabs
                  value={tabSelected}
                  onChange={(evt, newValue) => {
                    setTabSelected(newValue);
                    //changePanelAuxTitle(newValue === 0 ? "Conductores" : "Móviles");
                  }}
                >
                  <StyledTab
                    label={t("Conductores")}
                    id="tab-0"
                    aria-controls="tabDB-0"
                  />
                  <StyledTab
                    label={t("Vehículos")}
                    id="tab-1"
                    aria-controls="tabDB-1"
                  />
                </StyledTabs>
              </AppBar>
              <TabPanel value={tabSelected} index={0}>
                <DriverTable
                  data={reportData}
                  driverRowEvents={driverRowEvents}
                />
              </TabPanel>
              <TabPanel value={tabSelected} index={1}>
                <AssetTable data={reportData} assetRowEvents={assetRowEvents} />
              </TabPanel>
            </>
          )}
        </Grid>
        <Grid item xs={7} style={{ height: "100vh" }}>
          {reportData && (
            <ChartsPanel
              title={title}
              selectedData={selectedData}
              scoreProm={scoreData}
              infractions={infractions}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

const _mapStateToProps = ({ groups, lateralPanel }) => {
  return {
    ...lateralPanel.newPanelReducer,
    selectedGroups: groups.selectedGroups,
  };
};

const _mapDispatchToProps = (dispatch) => {
  return {
    changePanelAuxTitle: (title) => dispatch(changePanelAuxTitle(title)),
    seeAllFleet: (param) => dispatch(seeAllFleet(param)),
  };
};

export default connect(
  _mapStateToProps,
  _mapDispatchToProps
)(DrivingBehaviorReport);
