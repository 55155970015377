import { connect } from "react-redux";

import {
  fetchMarkersActions,
  fetchMarkersInitialActions,
} from "../redux/markers/actions";

import { toggleFillGenericPopup } from "../redux/genericPopup/actions";

import BaseMap from "../components/Map/BaseMap";

import { isloadingMarkersInitialUpdate } from "../redux/loading/actions";
import {
  addSearchOptions,
  addGeofenceToMapFromSearchOptions,
  removeGeofenceFromMap,
  addAssetToMultipleTracking,
  removeAssetFromMultipleTracking,
} from "../redux/base/action";

import { toggleFillMarkerInfo } from "../redux/fillMarkerInfo/actions";

import {
  changePanelType,
  closePanel,
  openPanel,
  setJourneyReportPanelInitialStatus,
  setFleetStatusPanelInitialStatus,
} from "../redux/lateralPanel/actions";
import { _updateMarkerJourney } from "../redux/fillMarkerJourney/actions";
import { setMarkerSelected } from "../redux/notifications/actions";

const _mapStateToProps = (
  {
    loading,
    markerJourney,
    lateralPanel,
    groups,
    popupInfo,
    notifications,
    base,
    user,
  },
  { mapId }
) => {
  return {
    isLoadingMarkersInitial: loading.isLoadingMarkersInitial,
    markerJourney: markerJourney.markerJourney,
    isOpen: lateralPanel.newPanelReducer.isPanelOpen,
    tabActive: lateralPanel.newPanelReducer.tabActive,
    panelType: lateralPanel.newPanelReducer.panelType,
    groupsSelected: groups.selectedGroups,
    selectedCustomers: groups.selectedCustomers,
    popupInfo: popupInfo.popupInfo,
    markerSelected: notifications.markerSelected,
    geofences: base.geofences,
    assetsMultipleTracking: base.assetsMultipleTracking,
    mapId: mapId,
    user: user,
    journeyReportPanelConnectedStatus:
      lateralPanel.newPanelReducer.journeyReportPanelConnectedStatus,
  };
};

const _mapDispatchToProps = (dispatch) => {
  return {
    fetchMarkers: fetchMarkersActions,
    fetchMarkersInitial: fetchMarkersInitialActions,
    isloadingMarkersInitialUpdate,
    toggleFillGenericPopup,
    addSearchOptions: (param) => dispatch(addSearchOptions(param)),
    addGeofenceToMapFromSearchOptions: (param) =>
      dispatch(addGeofenceToMapFromSearchOptions(param)),
    removeGeofenceFromMap,
    addAssetToMultipleTracking,
    removeAssetFromMultipleTracking,
    closePanel: () => dispatch(closePanel()),
    openPanel: () => dispatch(openPanel()),
    changePanelType: (panelType) => dispatch(changePanelType(panelType)),
    updateMarkerJourney: (param) => dispatch(_updateMarkerJourney(param)),
    setMarkerSelected: (marker) => dispatch(setMarkerSelected(marker)),
    setJourneyReportPanelInitialStatus: (status) =>
      dispatch(setJourneyReportPanelInitialStatus(status)),
    setFleetStatusPanelInitialStatus: (status) =>
      dispatch(setFleetStatusPanelInitialStatus(status)),
    toggleFillMarkerInfo: (marker) => dispatch(toggleFillMarkerInfo(marker)),
  };
};

export default connect(_mapStateToProps, _mapDispatchToProps)(BaseMap);
