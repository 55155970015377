import gql from "graphql-tag";

const VELOCITY_REPORTEXCEL_QUERY = gql`
  query VelocityReportExcel(
    $assetsSelected: [Int]!
    $fechaInicio: Datetime
    $fechaFin: Datetime
    $driversSelected: [Int]
    $velocidadDesde: Float
    $velocidadHasta: Float
    $days: [WeekDaysEnum]
  ) {
    velocityReportExcel(
      assetsSelected: $assetsSelected
      fechaInicio: $fechaInicio
      fechaFin: $fechaFin
      driversSelected: $driversSelected
      velocidadDesde: $velocidadDesde
      velocidadHasta: $velocidadHasta
      days: $days
    )
  }
`;

export default VELOCITY_REPORTEXCEL_QUERY;
