import gql from "graphql-tag";

const COMMTYPE_QUERY = gql`
  query CommTypes {
    commTypes {
      id
      description
    }
  }
`;

export default COMMTYPE_QUERY;
