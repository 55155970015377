import React, { useState, useEffect } from "react";

import { useQuery } from "@apollo/client";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import GET_ENUMS_QUERY from "../../../graphql/queries/getEnumsQuery";
import GET_PERMISSION_QUERY from "../../../graphql/queries/getPermissionQuery";

import { useTranslation } from "../../../lang";
const theme = createMuiTheme({});

const ModalAsignarPermisos = ({
  isOpen,
  closeModal,
  roleSelected,
  handleAssignPermission,
}) => {
  const [t] = useTranslation();
  const [categories, setCategories] = useState([]);
  const [permisions, setPermisions] = useState([]);

  const [selectedPermissions, setSelectedPermission] = useState([]);

  useEffect(() => {
    if (!roleSelected) return;

    setSelectedPermission(roleSelected.permission.map((p) => p.id));
  }, [roleSelected]);

  const {} = useQuery(GET_ENUMS_QUERY, {
    variables: {
      name: "PermisionCategory",
    },
    onCompleted(d) {
      // console.log("Categories: ", d.__type.enumValues);
      setCategories(d.__type.enumValues);
    },
  });

  const {} = useQuery(GET_PERMISSION_QUERY, {
    onCompleted(d) {
      // console.log("Permisos: ", d.permission);
      setPermisions(d.permission);
    },
  });

  const handleCheckCategory = (isChecked, value) => {
    const permissionsOfThisCategory = permisions
      .filter((p) => p.category === value)
      .map((x) => x.id);
    //array3 = [...new Set([...array1,...array2])]
    const newPermissionSel = isChecked
      ? [...new Set([...selectedPermissions, ...permissionsOfThisCategory])]
      : selectedPermissions.filter(
          (sp) => !permissionsOfThisCategory.includes(sp)
        );
    setSelectedPermission(newPermissionSel);
  };

  const handleCheckPermission = (isChecked, id) => {
    setSelectedPermission(
      isChecked
        ? [...selectedPermissions, id]
        : selectedPermissions.filter((sp) => sp !== id)
    );
  };

  const isCatIndeterminate = (cat) => {
    const permissionsOfThisCategory = permisions
      .filter((p) => p.category === cat)
      .map((x) => x.id);
    const permissionsSelected = selectedPermissions.filter((x) =>
      permissionsOfThisCategory.includes(x)
    );

    return (
      permissionsOfThisCategory.length !== permissionsSelected.length &&
      permissionsSelected.length > 0
    );
  };

  const isCatChecked = (cat) => {
    const permissionsOfThisCategory = permisions
      .filter((p) => p.category === cat)
      .map((x) => x.id);
    const permissionsSelected = selectedPermissions.filter((x) =>
      permissionsOfThisCategory.includes(x)
    );

    return (
      permissionsOfThisCategory.length === permissionsSelected.length &&
      permissionsSelected.length > 0
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog open={isOpen} onClose={closeModal} maxWidth="sm" fullWidth>
        <DialogTitle className="dialogTitle">
          {t("pages_manage_roleseleccionado", { name: roleSelected?.name })}
        </DialogTitle>
        <DialogContent>
          {categories.map((cat) => (
            <>
              <FormGroup style={{ borderBottom: "1px solid" }}>
                <FormControlLabel
                  key={`fml_${cat.value}`}
                  label={t(cat.description)}
                  control={
                    <Checkbox
                      color="primary"
                      checked={isCatChecked(cat.value)}
                      indeterminate={isCatIndeterminate(cat.value)}
                      onChange={(evt) =>
                        handleCheckCategory(evt.target.checked, cat.value)
                      }
                    />
                  }
                  labelPlacement="end"
                />
                {permisions
                  .filter((p) => p.category === cat.value)
                  .map((p) => (
                    <FormControlLabel
                      key={`permiso_${p.name}`}
                      label={t(p.description)}
                      control={
                        <Checkbox
                          color="primary"
                          checked={selectedPermissions.includes(p.id)}
                          onChange={(evt) =>
                            handleCheckPermission(evt.target.checked, p.id)
                          }
                        />
                      }
                      labelPlacement="end"
                      style={{ paddingLeft: "25px" }}
                    />
                  ))}
              </FormGroup>
            </>
          ))}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => closeModal()}
          >
            {t("Cancelar")}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              handleAssignPermission(roleSelected.id, selectedPermissions);
              closeModal();
            }}
          >
            {t("Guardar")}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default ModalAsignarPermisos;
