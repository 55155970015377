// eslint-disable import/prefer-default-export
export const authHeader = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  let _authHeader = {};

  if (user && user.token) {
    _authHeader = {
      Accept: "application/json; charset=utf-8",
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${user.token}`,
    };
  }

  return _authHeader;
};

export const manageGraphqlError = (error, callback) => {
  const { graphQLErrors } = error;
  if (graphQLErrors) {
    for (let i = 0; i < graphQLErrors.length; i++) {
      const error = graphQLErrors[i];
      if (error.extensions && error.extensions.code === "UNAUTHENTICATED") {
        localStorage.removeItem("user");
        localStorage.removeItem("loggedUser");
        localStorage.removeItem("access_token");
        localStorage.removeItem("showMenu");
        window.location.href = "/";
        break;
      }
    }
  }
};
