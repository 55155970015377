import React, { useEffect, useState } from "react";
import { DatePicker, TimePicker } from "antd";
import moment from "moment";

const { RangePicker } = TimePicker;

export const DATE_FORMATS = {
  MM_DD_YYYY: "MM-DD-YYYY",
  DD_MM_YYYY: "DD-MM-YYYY",
  YYYY_MM_DD: "YYYY-MM-DD",
};

const DEFAULT_COMPONENT_RESULTS_DATE_FORMAT = DATE_FORMATS.YYYY_MM_DD;

const DEFAULT_DATE_FORMAT = DATE_FORMATS.YYYY_MM_DD;
const DEFAULT_TIME_FORMAT = "HH:mm:ss";

const DateWithTimeRangeSelector = ({
  initialDate,
  initialStartTime,
  initialEndTime,
  onChange,
  timeFormat,
  dateFormat,
  resultDateFormat,
}) => {
  const [_resultDateFormat, set_resultDateFormat] = useState(
    resultDateFormat || DEFAULT_COMPONENT_RESULTS_DATE_FORMAT
  );
  const [date, setDate] = useState(initialDate);
  const [startTime, setStartTime] = useState(initialStartTime);
  const [endTime, setEndTime] = useState(initialEndTime);
  const [_timeFormat, set_timeFormat] = useState(
    timeFormat || DEFAULT_TIME_FORMAT
  );
  const [_dateFormat, set_dateFormat] = useState(
    dateFormat || DEFAULT_DATE_FORMAT
  );

  useEffect(() => {
    _emit();
  }, [date, startTime, endTime]);

  useEffect(() => {
    set_dateFormat(dateFormat);
  }, [dateFormat]);

  const buildDateWithTimeRangeObject = () => {
    const dateWithTimeRange = {
      date,
      startTime,
      endTime,
    };
    return dateWithTimeRange;
  };
  const handleDateChange = (_date, dateString) => {
    setDate(moment(dateString, _dateFormat).format(_resultDateFormat));
  };

  const handleTimeRangeChange = (times, timeStrings) => {
    setStartTime(timeStrings[0]);
    setEndTime(timeStrings[1]);
    _emit();
  };

  const _emit = () => {
    if (date && startTime && endTime) {
      onChange(buildDateWithTimeRangeObject());
    }
  };

  return (
    <>
      <DatePicker
        onChange={handleDateChange}
        placeholder="Date"
        defaultValue={moment(date, _resultDateFormat)}
        format={_dateFormat}
      />
      <RangePicker
        onChange={handleTimeRangeChange}
        defaultValue={[
          moment(startTime, _timeFormat),
          moment(endTime, _timeFormat),
        ]}
      />
    </>
  );
};

export default DateWithTimeRangeSelector;
