import { hasPermission } from "../utils/generalUtils";
const availableFunctions = (roles) => {
  const availableFunctions = [];
  if (!roles) return availableFunctions;
  allFunctions.map((f) => {
    if (hasPermission(f.permission, roles))
      availableFunctions.push({
        label: f.label,
        value: f.value,
      });
  });
  return availableFunctions;
};

export const allFunctions = [
  {
    label: "Asignar N° Guía",
    value: "/manage/trackingnumber",
    permission: "AsignarNroGuia",
  },
  {
    label: "Clientes",
    value: "/manage/clients",
    permission: "Clientes",
  },
  {
    label: "Conductores",
    value: "/manage/drivers",
    permission: "Conductores",
  },
  {
    label: "Equipos",
    value: "/manage/devices",
    permission: "Equipos",
  },
  {
    label: "Geocercas",
    value: "/manage/geofences",
    permission: "Geocercas",
  },
  {
    label: "Grupos",
    value: "/manage/groups",
    permission: "Grupos",
  },
  {
    label: "Líneas",
    value: "/manage/lines",
    permission: "Lineas",
  },
  {
    label: "Móviles",
    value: "/manage/assets",
    permission: "Moviles",
  },
  {
    label: "Planificador",
    value: "/manage/reportPlanning",
    permission: "Planificador",
  },
  {
    label: "Puntos de interés",
    value: "/manage/pois",
    permission: "PuntosInteres",
  },
  {
    label: "Roles",
    value: "/manage/roles",
    permission: "Roles",
  },
  {
    label: "Rutas",
    value: "/manage/road",
    permission: "Rutas",
  },
  {
    label: "Técnicos",
    value: "/manage/technicians",
    permission: "Técnicos",
  },
  {
    label: "Usuarios",
    value: "/manage/users",
    permission: "Usuarios",
  },
  {
    label: "Reporte de Consolidados",
    value: "/report/consolidate",
    permission: "Consolidados",
  },
  {
    label: "Reporte de Detenciones",
    value: "/report/detention",
    permission: "Detenciones",
  },
  {
    label: "Reporte de Eco Tracking",
    value: "/report/ecoTracking",
    permission: "EcoTracking",
  },
  {
    label: "Reporte de Eventos",
    value: "/report/event",
    permission: "Eventos",
  },
  {
    label: "Reporte de Geoescalas",
    value: "/report/geoscale",
    permission: "Geoescalas",
  },
  {
    label: "Reporte de Kilometraje de Flota",
    value: "/report/fleetKm",
    permission: "KilometrajeFlota",
  },
  {
    label: "Reporte de OBD2",
    value: "/report/obd2",
    permission: "ReporteOBD2",
  },
  {
    label: "Reporte de Tablero Seguimiento",
    value: "/report/trackingBoard",
    permission: "TableroSeguimiento",
  },
  {
    label: "Reporte de Velocidades",
    value: "/report/velocity",
    permission: "Velocidad",
  },
  {
    label: "Desvios de Ruta",
    value: "/report/routeDetour",
    permission: "ReporteDesvioDeRuta",
  },
  {
    label: "Desvios de Parada",
    value: "/report/stopDetour",
    permission: "ReporteDesvioDeParada",
  },
  {
    label: "Histórico de Paradas",
    value: "/report/stopHistory",
    permission: "StopHistoryComparison",
  },
  {
    label: "Mapa",
    value: "/map",
    permission: "",
  },
];

export default availableFunctions;
