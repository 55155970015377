import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

import NotificationPanel from "../NotificationPanel";

import "./ExpandablePanel.scss";

import { withTranslation } from "../../lang";
class ExpandablePanel extends Component {
  static propTypes = {
    level: PropTypes.oneOf([1, 2]).isRequired,
    isOpen: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    title: PropTypes.string,
    handleClickClosePanel: PropTypes.func,
    toggleActivePanelAux: PropTypes.func,
    panelCloseRedirectTo: PropTypes.string,
    children: PropTypes.node,
    isPanelAuxOpen: PropTypes.bool,
    panelType: PropTypes.string,
  };

  static defaultProps = {
    isOpen: false,

    title: "",
    handleClickClosePanel: () => {},
    toggleActivePanelAux: () => {},
    panelCloseRedirectTo: "",
    children: null,
    isPanelAuxOpen: false,
    panelType: "",
  };

  render() {
    const {
      isOpen,
      level,
      title,
      handleClickClosePanel,
      panelCloseRedirectTo,
      children,
      isPanelAuxOpen,
      toggleActivePanelAux,
      panelType,
      t,
    } = this.props;

    const expandablePanelClassnames = classNames("expandable-panel", {
      "expandable-panel--open": isOpen,
      "expandable-panel--openNotification": panelType === "Notification",
      [`expandable-panel--left--level-${level}`]: level,
    });

    let _closeButton = (
      <IconButton
        className="expandable-panel__close-btn"
        onClick={handleClickClosePanel}
      >
        <CloseIcon />
      </IconButton>
    );

    if (panelCloseRedirectTo) {
      let panelAuxActions = {
        onClick: () => {
          handleClickClosePanel();
        },
      };

      if (isPanelAuxOpen && toggleActivePanelAux) {
        panelAuxActions = {
          onClick: () => {
            toggleActivePanelAux({ isOpen: true });
            handleClickClosePanel();
          },
        };
      }
      _closeButton = (
        <IconButton
          component={Link}
          to={panelCloseRedirectTo}
          className="expandable-panel__close-btn"
          {...panelAuxActions}
        >
          <CloseIcon />
        </IconButton>
      );
    }

    const renderTitle = () => {
      let panelTitle = "";
      switch (panelType) {
        case "Notification":
          panelTitle = t("Notificaciones");
          break;
        default:
          panelTitle = title;
          break;
      }
      return panelTitle;
    };

    const renderContent = () => {
      let content = <div />;
      switch (panelType) {
        case "Notification":
          content = <NotificationPanel />;
          break;
        default:
          content = children;
          break;
      }
      return content;
    };

    return (
      <section className={expandablePanelClassnames}>
        <div
          className={
            "expandable-panel__content" && panelType === "Notification"
              ? "expandable-panel--openNotification"
              : ""
          }
        >
          <div className="expandable-panel__title">
            <Typography
              component="div"
              className="expandable-panel__title-text title-format"
            >
              {renderTitle()}
            </Typography>

            {_closeButton}
          </div>

          {/*children*/}
          {renderContent()}
        </div>
      </section>
    );
  }
}

export default withTranslation()(ExpandablePanel);
