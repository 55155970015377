import { Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import Table from "../../../components/Base/Table";
import ThreeDotMenu from "../../../components/Base/ThreeDotMenu";
import TechnicianModal from "./TechnicianForm";
import { useTranslation } from "../../../lang";

const List = ({
  paginationSizes,
  total,
  technicianList,
  createTechnician,
  updateTechnician,
  getTechnicians,
  deleteTechnician,
}) => {
  const [t] = useTranslation();
  const [openSide, setOpenSide] = useState(false);
  const [selectedItem, setSelectedItem] = useState();

  const [textToSearch, setTextToSearch] = useState("");

  useEffect(() => {
    getTechnicians();
  }, []);

  const confirmDelete = (id, name) => {
    confirmAlert({
      title: t("Eliminar Ténico"),
      message: t("pages_manage_technicians_seguro_eliminar_tecnico", { name }),
      buttons: [
        {
          label: t("Si"),
          onClick: () => {
            deleteTechnician(id);
          },
        },
        {
          label: t("No"),
          onClick: () => console.log("cancelar"),
        },
      ],
    });
  };

  const itemStyle = {
    fontSize: "12px",
  };

  const callbackSelectOption = (option, row) => {
    switch (option) {
      case 1:
        setSelectedItem(row);
        setOpenSide(true);
        break;
      case 2:
        confirmDelete(row.id, `${row.firstname} ${row.lastname}`);
        break;
    }
  };

  const hightlightIfMatch = (text) => {
    if (
      text &&
      textToSearch &&
      text.toLocaleLowerCase().includes(textToSearch.toLocaleLowerCase())
    ) {
      const newText = text.replace(
        new RegExp(textToSearch, "gi"),
        (match) => `<mark>${match}</mark>`
      );
      return <div dangerouslySetInnerHTML={{ __html: newText }} />;
    }

    return text;
  };

  const columns = [
    {
      dataField: "id",
      text: "",
      headerStyle: () => ({ width: "50px", fontSize: "12px" }),
      formatter: (cell, row) => {
        return (
          <>
            <ThreeDotMenu
              key={row.id}
              row={row}
              callback={callbackSelectOption}
              options={[
                {
                  label: t("Editar"),
                  value: 1,
                },
                {
                  label: t("Eliminar"),
                  value: 2,
                },
              ]}
            />
          </>
        );
      },
    },
    {
      dataField: "code",
      text: t("Código"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      style: () => itemStyle,
      formatter: hightlightIfMatch,
      sort: true,
    },
    {
      dataField: "firstname",
      text: t("Nombre"),
      headerStyle: () => ({ width: "15%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
      formatter: hightlightIfMatch,
      // filter: textFilter(),
    },
    {
      dataField: "lastname",
      text: t("Apellido"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
      formatter: hightlightIfMatch,
      // filter: textFilter(),
    },
    {
      dataField: "email",
      text: t("Email"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
      formatter: hightlightIfMatch,
      // filter: textFilter(),
    },
    {
      dataField: "phoneNumber",
      text: t("Teléfono"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
      formatter: hightlightIfMatch,
      // filter: textFilter(),
    },
    {
      dataField: "mobilePhone",
      text: t("Teléfono Móvil"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
      formatter: hightlightIfMatch,
      // filter: textFilter(),
    },
  ];

  const customTotal = (from, to, size) => (
    <span
      className="react-bootstrap-table-pagination-total customTotalDisplay"
      style={{ marginLeft: "15px" }}
    >
      {t("pages_manage_technicians_mostrar_resultados", { from, to, size })}
    </span>
  );

  const options = {
    // Tooltips botones de paginación
    prePageTitle: t("Página previa"),
    nextPageTitle: t("Próxima página"),
    firstPageTitle: t("Primera página"),
    lastPageTitle: t("Última página"),
    showTotal: true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: paginationSizes,
    totalSize: total,
  };

  return (
    <div className="listContainer">
      <SlidingPane
        className="slidingPaneRight"
        isOpen={openSide}
        title={selectedItem ? t("Actualizar Técnico") : t("Crear Técnico")}
        width="400px"
        onRequestClose={() => {
          setOpenSide(false);
        }}
      >
        <TechnicianModal
          setIsOpen={() => setOpenSide(false)}
          createTechnician={createTechnician}
          selectedTechnician={selectedItem}
          updateTechnician={updateTechnician}
        />
      </SlidingPane>
      <Table
        columns={columns}
        data={technicianList}
        keyField="id"
        paginationOptions={options}
        //total={total}
        lateralComponent={
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setSelectedItem(null);
              setOpenSide(true);
            }}
            style={{ float: "right" }}
          >
            {t("+ Técnico")}
          </Button>
        }
      />
    </div>
  );
};

export default List;
