import React, { useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { decode } from "@googlemaps/polyline-codec";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { connect } from "react-redux";
import PageTitle from "../../../components/Base/PageTitle";
import Structure from "../../../components/Base/Structure";
import ASSETS_BYGROUPS_QUERY from "../../../graphql/queries/assetByGroupsQuery";
import ALL_ROADS_BY_CUSTOMER_IDS_QUERY from "../../../graphql/queries/allRoadsByCustomersIDs";
import { useFilterValidation } from "../useFilterValidation";
import Filters from "./Filters";
import ReportResult from "./ReportResult";
import { useTranslation } from "../../../lang";
import STOP_HISTORY_QUERY from "../../../graphql/queries/stopHistoryQuery";

// const DEFAULT_REPORT_RESULT = {
//   roadStart: null,
//   roadEnd: null,
//   pointRoad: [],
//   road: "",
// };
const StopHistoryReport = ({ selectedGroups, selectedCustomers, user }) => {
  const [t] = useTranslation();
  const [assetList, setAssetList] = useState([]);
  const [roadList, setRoadList] = useState([]);
  const [assetTableState] = useState({
    searchText: "",
    sort: undefined,
    paginate: false,
  });

  const [reportResult, setReportResult] = useState();

  const [resultState, setResultState] = useState({
    isOpen: false,
    type: "success",
    msg: "",
  });

  const {} = useQuery(ASSETS_BYGROUPS_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      groupIDs: selectedGroups.filter(
        (g) => g && !Number.isNaN(Number.parseInt(g))
      ),
      ...assetTableState,
    },
    onCompleted(d) {
      const { items } = d.assetsByGroups;
      setAssetList(items);
    },
  });

  const {} = useQuery(ALL_ROADS_BY_CUSTOMER_IDS_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      customerIDs:
        selectedCustomers && selectedCustomers.length > 0
          ? selectedCustomers.map((customer) => customer.value)
          : [],
    },
    onCompleted(d) {
      const roads = d.allRoadsByCustomersIDs.map((road) => road);
      setRoadList(roads);
    },
  });

  const [fetchReport] = useLazyQuery(STOP_HISTORY_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      if (d.stopHistoryComparisonReport) {
        const stopHistoryComparisonReport = {
          ...d.stopHistoryComparisonReport,
          roadReport: {
            ...d.stopHistoryComparisonReport.roadReport,
            decodedPolyline: decode(
              d?.stopHistoryComparisonReport?.roadReport?.overviewPolyline
            ),
            bufferedDecodedOverviewPolyline:
              d?.stopHistoryComparisonReport?.roadReport
                ?.bufferedDecodedOverviewPolyline &&
              JSON.parse(
                d.stopHistoryComparisonReport.roadReport
                  .bufferedDecodedOverviewPolyline
              ),
          },
        };
        setReportResult(stopHistoryComparisonReport);
      } else {
        setResultState({
          isOpen: true,
          type: "info",
          msg: t("No se encontraron datos para su consulta"),
        });
      }
    },
    onError(err) {
      setReportResult();
      setResultState({
        isOpen: true,
        type: "error",
        msg: err.message,
      });
    },
  });

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setResultState({
      ...resultState,
      isOpen: false,
    });
  };

  const handleSearchReportStopHistory = ({
    roadId,
    assetsSelected,
    dateTo,
    dateFrom,
  }) => {
    setReportResult();
    fetchReport({
      variables: {
        roadId,
        assetsSelected,
        dateTo,
        dateFrom,
      },
    });
  };

  const { handleSearchReport, errors: filterErrors } = useFilterValidation(
    handleSearchReportStopHistory
  );

  return (
    <Structure>
      <Snackbar
        open={resultState.isOpen}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleCloseNotification}
          severity={resultState.type}
          variant="filled"
        >
          {resultState.msg}
        </MuiAlert>
      </Snackbar>
      <PageTitle sector={t("Estadísticas")} title={t("Histórico de Paradas")} />
      <Filters
        assets={assetList}
        roads={roadList}
        searchReport={handleSearchReport}
        errors={filterErrors}
        user={user}
      />
      {/* {reportResult && reportResult.pointRoad.length > 0 && ( */}
      {reportResult && <ReportResult reportData={reportResult} />}
      {/* )} */}
    </Structure>
  );
};

const _mapStateToProps = ({ groups, user }) => {
  return {
    selectedGroups: groups.selectedGroups,
    selectedCustomers: groups.selectedCustomers,
    user,
  };
};

export default connect(_mapStateToProps, null)(StopHistoryReport);
