import { gql } from "@apollo/client";
import Client from "../../components/Base/apiApolloClient";
import CURRENT_USER_QUERY from "../../graphql/queries/getCurrentUserQuery";
import { t } from "../../lang";
import history from "../../utils/history";
import { deleteApi, registerApi } from "./api";

export const LOGIN_REQUEST = "@USER/LOGIN_REQUEST";
export const LOGIN_SUCCESS = "@USER/LOGIN_SUCCESS";
export const LOGIN_FAILURE = "@USER/LOGIN_FAILURE";

export const LOGOUT_REQUEST = "@USER/LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "@USER/LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "@USER/LOGOUT_FAILURE";

export const REGISTER_REQUEST = "@USER/REGISTER_REQUEST";
export const REGISTER_SUCCESS = "@USER/REGISTER_SUCCESS";
export const REGISTER_FAILURE = "@USER/REGISTER_FAILURE";

export const DELETE_REQUEST = "@USER/DELETE_REQUEST";
export const DELETE_SUCCESS = "@USER/DELETE_SUCCESS";
export const DELETE_FAILURE = "@USER/DELETE_FAILURE";

export const UPDATE_ACTIVE_PANEL = "UPDATE_ACTIVE_PANEL";
export const UPDATE_ACTIVE_PANEL_AUX = "UPDATE_ACTIVE_PANEL_AUX";
export const UPDATE_ACTIVE_PANEL_AUX_COMPONENT =
  "UPDATE_ACTIVE_PANEL_AUX_COMPONENT";

export const SET_CURRENT_USER = "SET_CURRENT_USER";

const client = Client;

const loginRequest = (user) => ({
  type: LOGIN_REQUEST,
  payload: user,
});
export const loginSuccess = ({ message, user, token, redirectTo }) => ({
  type: LOGIN_SUCCESS,
  payload: { message, user, token, redirectTo },
});
const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: { error },
});

const logoutRequest = () => ({
  type: LOGOUT_REQUEST,
});
const logoutSuccess = (payload) => ({
  type: LOGOUT_SUCCESS,
  payload,
});

const _updateActivePanel = (content) => ({
  type: UPDATE_ACTIVE_PANEL,
  payload: content,
});

const _updateActivePanelAux = (content) => ({
  type: UPDATE_ACTIVE_PANEL_AUX,
  payload: content,
});

const _updateActivePanelAuxContent = (content) => ({
  type: UPDATE_ACTIVE_PANEL_AUX_COMPONENT,
  payload: content,
});

// const logoutFailure = (error) => ({
//     type: LOGOUT_FAILURE,
//     payload: { error }
// });

const registerRequest = (user) => ({
  type: REGISTER_REQUEST,
  payload: user,
});
const registerSuccess = (user) => ({
  type: REGISTER_SUCCESS,
  payload: user,
});
const registerFailure = (error) => ({
  type: REGISTER_FAILURE,
  payload: error,
});

const deleteRequest = (userId) => ({
  type: DELETE_REQUEST,
  payload: userId,
});
const deleteSuccess = (userId) => ({
  type: DELETE_SUCCESS,
  payload: userId,
});
const deleteFailure = ({ userId, error }) => ({
  type: DELETE_FAILURE,
  payload: { userId, error },
});

const LOGIN_MUTATION = gql`
  mutation currentLogin(
    $userName: String!
    $password: String!
    $timezone: Int
  ) {
    login(userName: $userName, password: $password, timezone: $timezone) {
      token
      refToken
      user {
        id
        userName
        firstName
        lastName
        photo
        language
        customer {
          id
          name
        }
        roles {
          id
          name
          isOwner
          isAdmin
          permission {
            id
            name
          }
        }
      }
    }
  }
`;

export const setCurrentUser = (user) => ({
  type: SET_CURRENT_USER,
  payload: user,
});

export const getUserAction = () => (dispatch, getState) => {
  client
    .query({
      query: CURRENT_USER_QUERY,
    })
    .then((res) => {
      dispatch(setCurrentUser(res.currentUser));
    });
};

export const loginAction = () => (dispatch, getState) => {
  const {
    form: { login: { values: { username, password } = {} } = {} } = {},
  } = getState();

  dispatch(loginRequest({ username }));
  client
    .mutate({
      mutation: LOGIN_MUTATION,
      variables: {
        userName: username,
        password: password,
        timezone: new Date().getTimezoneOffset() / 60,
      },
    })
    .then(
      (res) => {
        localStorage.setItem("access_token", res.data.login.token);
        localStorage.setItem("showMenu", true);
        console.log(
          "user en login a guardar en LS:",
          JSON.stringify(res.data.login.user)
        );
        localStorage.setItem("loggedUser", JSON.stringify(res.data.login.user));
        dispatch(
          loginSuccess({
            message: t("Has ingresado satisfactoriamente"),
            user: res.data.login.user,
            token: res.data.login.token,
            redirectTo: "/map",
          })
        );
        window.location.href = "/map";
      },
      (error) => {
        dispatch(loginFailure(error.toString()));
      }
    );
};

export const logoutAction = () => (dispatch) => {
  // logoutApi().then(
  //     response => {
  //         dispatch(logoutSuccess());

  //         history.push('/map');
  //     },
  //     error => {
  //         dispatch(logoutFailure(error.toString()));
  //         // dispatch(alertActions.error(error.toString()));
  //     }
  // );
  dispatch(logoutRequest());
  dispatch(logoutSuccess({ message: t("Sesion finalizada con exito") }));

  dispatch(_updateActivePanelAux(false));
  dispatch(_updateActivePanel(false));
  dispatch(_updateActivePanelAuxContent(false));
  localStorage.removeItem("user");
  localStorage.removeItem("access_token");
  localStorage.removeItem("showMenu");
  history.push("/login");
};

export const registerAction = () => (dispatch, getState) => {
  const { user } = getState();

  dispatch(registerRequest(user));

  registerApi(user).then(
    () => {
      dispatch(
        registerSuccess({ message: t("Has ingresado satisfactoriamente") })
      );
      // history.push('/login');
      // dispatch(alertActions.success('Registration successful'));
    },
    (error) => {
      dispatch(registerFailure(error.toString()));
      // dispatch(alertActions.error(error.toString()));
    }
  );
};

export const deleteAction = (userId) => (dispatch) => {
  dispatch(deleteRequest(userId));

  deleteApi({ userId }).then(
    () => dispatch(deleteSuccess(userId)),
    (error) => dispatch(deleteFailure({ userId, error: error.toString() }))
  );
};
