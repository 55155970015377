import React, { useEffect, useState } from "react";
import {
  Button,
  FormGroup,
  Grid,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { useQuery } from "@apollo/client";
import * as Yup from "yup";
import { Field, Formik } from "formik";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import GenericSelector from "../../../components/Selectors/GenericSelector";

//import CustomFormikSelect from "../../../components/Base/CustomFormikSelect";
import CUSTOMERS_QUERY from "../../../graphql/queries/getCustomersQuery";
import ImageUpload from "../../../components/Base/ImageUpload";

import { useTranslation } from "../../../lang";
const theme = createMuiTheme({});

const UserForm = ({
  setIsOpen,
  createUser,
  selectedUser,
  updateUser,
  photo,
}) => {
  const [t] = useTranslation();
  const [customers, setCustomers] = useState([]);
  const [customerDefault, setCustomerDefault] = useState();
  const [userPhoto, setUserPhoto] = useState();

  const initialValues = {
    userId: selectedUser?.id,
    userName: selectedUser?.userName,
    password: selectedUser?.password,
    firstName: selectedUser?.firstName,
    lastName: selectedUser?.lastName,
    photo: userPhoto,
    email: selectedUser?.email,
    contactNumber: selectedUser?.contactNumber || "",
    phone: selectedUser?.phone || "",
    celPhone: selectedUser?.celPhone || "",
    status: selectedUser?.status || true,
    timeSession: selectedUser?.timeSession,
    allowGMaps: selectedUser?.allowGMaps || false,
    customerId: selectedUser?.customerId,
  };

  const yupSchema = Yup.object().shape({
    userName: Yup.string().required(t("Requerido")),
    password: !selectedUser ? Yup.string().required(t("Requerido")) : undefined,
    firstName: Yup.string().required(t("Requerido")),
    lastName: Yup.string().required(t("Requerido")),
    email: Yup.string().email().required(t("Requerido")),
    customerId: !selectedUser
      ? Yup.number().required(t("Requerido"))
      : undefined,
  });

  useEffect(() => {
    setUserPhoto(photo);
  }, [photo]);

  const {} = useQuery(CUSTOMERS_QUERY, {
    onCompleted(d) {
      setCustomers(d.customers.items);
      if (selectedUser?.customerId) {
        const defaultC = d.customers.items.find(
          (c) => c.id === selectedUser.customerId
        );
        setCustomerDefault(defaultC);
      }
    },
  });

  // const itemStyle = {
  //   fontSize: "12px",
  //   color: "white",
  // };

  const columnsCustomers = [
    {
      dataField: "name",
      text: t("Nombre"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      // style: () => itemStyle,
      sort: true,
    },
    {
      dataField: "taxId",
      text: t("CUIT"),
      headerStyle: () => ({ width: "15%", fontSize: "12px" }),
      // style: () => itemStyle,
      sort: true,
    },
    {
      dataField: "phone",
      text: t("Telefonos"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      // style: () => itemStyle,
      sort: true,
    },
    {
      dataField: "celPhone",
      text: t("Celular"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      // style: () => itemStyle,
      sort: true,
    },
    {
      dataField: "address",
      text: t("Domicilio"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      // style: () => itemStyle,
      sort: true,
    },
  ];

  return (
    <>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={yupSchema}
        onSubmit={async (values) => {
          if (selectedUser) {
            updateUser({
              variables: { ...values, photo: userPhoto },
            });
          } else {
            createUser({
              variables: { ...values },
            });
          }
        }}
      >
        {(props) => {
          const { values, errors, handleSubmit, setFieldValue } = props;

          return (
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <label className="formLabelFormik">{t("Usuario")}</label>
                <Field
                  type="text"
                  name="userName"
                  tabIndex="0"
                  className={
                    errors.userName
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.userName && (
                  <div className="input-feedback">{errors.userName}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">{t("Contraseña")}</label>
                <Field
                  type="password"
                  name="password"
                  tabIndex="0"
                  className={
                    errors.password
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.password && (
                  <div className="input-feedback">{errors.password}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">{t("Nombre")}</label>
                <Field
                  type="text"
                  name="firstName"
                  tabIndex="0"
                  className={
                    errors.firstName
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.firstName && (
                  <div className="input-feedback">{errors.firstName}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">{t("Apellido")}</label>
                <Field
                  type="text"
                  name="lastName"
                  tabIndex="0"
                  className={
                    errors.lastName
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.lastName && (
                  <div className="input-feedback">{errors.lastName}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">{t("Email")}</label>
                <Field
                  type="email"
                  name="email"
                  tabIndex="0"
                  className={
                    errors.email
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.email && (
                  <div className="input-feedback">{errors.email}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">{t("Teléfono 1")}</label>
                <Field
                  type="text"
                  name="contactNumber"
                  tabIndex="0"
                  className={
                    errors.contactNumber
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.contactNumber && (
                  <div className="input-feedback">{errors.contactNumber}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">{t("Teléfono 2")}</label>
                <Field
                  type="text"
                  name="phone"
                  tabIndex="0"
                  className={
                    errors.phone
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.phone && (
                  <div className="input-feedback">{errors.phone}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">{t("Teléfono Móvil")}</label>
                <Field
                  type="text"
                  name="celPhone"
                  tabIndex="0"
                  className={
                    errors.celPhone
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.celPhone && (
                  <div className="input-feedback">{errors.celPhone}</div>
                )}
              </FormGroup>

              <FormGroup>
                <ImageUpload
                  onChange={(newPhoto) => {
                    setUserPhoto(newPhoto);
                    setFieldValue("photo", newPhoto);
                  }}
                  file={userPhoto}
                  title={t("Imagen de Perfil")}
                />
              </FormGroup>

              <FormGroup>
                {customers && customers.length > 0 && (
                  <GenericSelector
                    callbackOptionSelected={(customer) =>
                      setFieldValue("customerId", customer.value)
                    }
                    columns={columnsCustomers}
                    keyField="id"
                    labelField="name"
                    title={t("Cliente")}
                    data={customers}
                    defaultValue={selectedUser?.customerId}
                  />
                )}
                {errors.customerId && (
                  <div className="input-feedback">{errors.customerId}</div>
                )}
              </FormGroup>

              <FormGroup>
                <label className="formLabelFormik">
                  {t("Tiempo de Session")}
                </label>
                <Field
                  type="number"
                  name="timeSession"
                  tabIndex="0"
                  className={
                    errors.timeSession
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.timeSession && (
                  <div className="input-feedback">{errors.timeSession}</div>
                )}
              </FormGroup>
              <FormGroup>
                <ThemeProvider theme={theme}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.status}
                        onChange={(evt) =>
                          setFieldValue("status", evt.target.checked)
                        }
                        color="primary"
                        name="status"
                      />
                    }
                    label={t("Activo")}
                  />
                </ThemeProvider>
                {errors.status && (
                  <div className="input-feedback">{errors.status}</div>
                )}
              </FormGroup>
              {process.env.REACT_APP_CUSTOMER !== "Reaxium" && (
                <FormGroup>
                  <ThemeProvider theme={theme}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.allowGMaps}
                          onChange={(evt) =>
                            setFieldValue("allowGMaps", evt.target.checked)
                          }
                          color="primary"
                          name="allowGMaps"
                        />
                      }
                      label={t("Habilitar Google Maps")}
                    />
                  </ThemeProvider>
                  {errors.allowGMaps && (
                    <div className="input-feedback">{errors.allowGMaps}</div>
                  )}
                </FormGroup>
              )}
              <Grid item lg={12} style={{ marginTop: "50px" }}>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  tabIndex="0"
                >
                  {selectedUser ? t("Actualizar") : t("Crear")}
                </Button>
                <Button
                  variant="contained"
                  type="button"
                  tabIndex="0"
                  style={{ marginLeft: "20px" }}
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  {t("Cancelar")}
                </Button>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default UserForm;
