import { useEffect } from "react";
import { useChangeLanguage } from ".";

export const getStoredLangFromLocalStorage = () => {
  const storedLangJson = localStorage.getItem("loggedUser") || "{}";
  const storedUser = JSON.parse(storedLangJson);
  const storedLang = storedUser.language;
  return storedLang;
};

const LangFromLocalStorage = () => {
  const [AVAILABLE_LANGS, changeLanguage] = useChangeLanguage();
  let selectedLang = null;

  const storedLang = getStoredLangFromLocalStorage();
  switch (storedLang) {
    case "US":
      selectedLang = AVAILABLE_LANGS.EN_US;
      break;
    case "ES":
      selectedLang = AVAILABLE_LANGS.ES_SP;
      break;
    case "ES_US":
      selectedLang = AVAILABLE_LANGS.ES_US;
      break;
    default:
      selectedLang = AVAILABLE_LANGS.DEFAULT;
  }

  useEffect(() => {
    changeLanguage(selectedLang);
  }, []);

  return null;
};

export default LangFromLocalStorage;
