import { connect } from 'react-redux';

import ExpandablePanel from '../../components/Base/ExpandablePanel';

import { toggleActivePanelAux } from '../../redux/lateralPanel/actions';

const _mapStateToProps = ({ lateralPanel }) => ({
    isOpen: lateralPanel.isPanelAuxOpen
});

const _mapDispatchToProps = {
    handleClickClosePanel: toggleActivePanelAux
};

export default connect(_mapStateToProps, _mapDispatchToProps)(ExpandablePanel);
