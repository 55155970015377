// eslint-disable react/forbid-prop-types
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";

import "./GeneralInfo.scss";
import "./Notifications.scss";
import { useTranslation } from "../../../lang";
import ExpansionPanel from "../../Base/ExpansionPanel";
import NotificationPanel from "../../NotificationPanel";
import TabPanelContent from "../../Base/TabPanelContent";

// TODO: remove index key from here.
const NotificationsList = ({ notifications = [], t }) => {
  const stepsList = (
    <div className="marker-info__notifications-list">
      {notifications.map(
        (
          {
            // type,
            status,
            title,
            date,
            location,
            place,
            transferInfo,
            transferConditions,
          },
          index
        ) => {
          const statusClassNames = classNames(
            "marker-info__notification-icon",
            {
              "marker-info__notification-icon--read": status === "read",
            }
          );
          const summary = (
            <>
              <ReportProblemIcon className={statusClassNames} />

              <div className="marker-info__notification-description flex column">
                <div className="marker-info__notificaiton-summary">{title}</div>
                <div>{date}</div>
                <div>{place}</div>
              </div>
            </>
          );
          const details = (
            <div className="marker-info__notification-details flex column">
              <div>
                <div>{t("Notificacion enviada")}</div>
                <div>{transferInfo}</div>
              </div>
              <div className="pad-vert-4">
                <div>{t("Condiciones de inicio de evento")}</div>
                <div>{transferConditions}</div>
              </div>
              <div>
                <div>{t("Locaclización")}</div>
                <div>{location}</div>
              </div>
            </div>
          );

          return (
            <ExpansionPanel
              key={date}
              index={index}
              summary={summary}
              details={details}
            />
          );
        }
      )}
    </div>
  );

  return stepsList;
};

const Notifications = ({ value, assetid, index }) => {
  const [t] = useTranslation();
  //let notificationsContent = <div>Este marcador no posee notificaciones</div>;

  /*if (!isEmpty(markerNotifications)) {
    const { notifications } = markerNotifications;

    notificationsContent = (
      <div className="marker-info__notifications">
        <SubHeader />

        <NotificationsList notifications={notifications} t={t} />
      </div>
    );

  }*/
  let notificationsContent = <div />;

  if (assetid) {
    notificationsContent = <NotificationPanel assetid={assetid} />;
  }

  return (
    <TabPanelContent value={value} index={index} subtitle={t("Alertas")}>
      {notificationsContent}
    </TabPanelContent>
  );
};

Notifications.propTypes = {
  value: PropTypes.number,
  markerNotifications: PropTypes.object,
  index: PropTypes.number,
};

Notifications.defaultProps = {
  value: 0,
  markerNotifications: {},
  index: 0,
};

export default Notifications;
