import React, { useState } from "react";

import { MapContainer, TileLayer } from "react-leaflet";

import { Marker, Polygon, Polyline, Tooltip } from "react-leaflet";
import { useTranslation } from "../../../lang";

import { SUB_PATH_TYPES } from "./types.d";
import SubPathInformationPopup from "./SubPathInformationPopup";

import L from "leaflet";
import defaultIcon from "leaflet/dist/images/marker-icon.png";
import defaultIconShadow from "leaflet/dist/images/marker-shadow.png";

import "./style.scss";

const routeStartEventIcon = L.icon({
  iconUrl: defaultIcon,
  shadowUrl: defaultIconShadow,
  iconAnchor: [12.5, 41],
});

const routeEndEventIcon = L.icon({
  iconUrl: defaultIcon,
  shadowUrl: defaultIconShadow,
  iconAnchor: [12.5, 41],
});

const DEFAULT_CENTER_MAP = process.env.REACT_APP_DEFAULT_CENTER_MAP.split(",");
const DEFAULT_ZOOM = 16;
const ReportResult = ({ reportData }) => {
  const [t] = useTranslation();
  const [data, setData] = useState(reportData);
  const firstElem = data.pointRoad[0].points[0];
  const [initialPosition, setInitialPosition] = useState(
    [firstElem.latitud, firstElem.longitud] || DEFAULT_CENTER_MAP
  );
  const zoom = DEFAULT_ZOOM;

  const [map, setMap] = useState();

  const offPathOptions = { color: "red", weight: 4 };
  const onPathOptions = { color: "green", weight: 4 };
  const neutralPathOptions = { color: "gray", weight: 4 };

  const getSubpathOptions = (subPath, roadStartPosition, roadEndPosition) => {
    if (!roadStartPosition || !roadEndPosition) {
      return neutralPathOptions;
    }

    const firstPointFromSubPath = subPath.points[0];
    const lastPointFromSubPath = subPath.points[subPath.points.length - 1];

    const subPathInitDate = new Date(firstPointFromSubPath.fechaGPS);
    const subPathEndDate = new Date(lastPointFromSubPath.fechaGPS);
    const roadStartDate = new Date(roadStartPosition.fechaGPS);
    const roadEndDate = new Date(roadEndPosition.fechaGPS);

    if (subPathEndDate < roadStartDate) {
      return neutralPathOptions;
    }

    if (subPathInitDate > roadEndDate) {
      return neutralPathOptions;
    }

    const options = subPath.type === "In" ? onPathOptions : offPathOptions;
    return options;
  };

  const connectSubPaths = (subPaths = []) => {
    if (subPaths.length <= 1) return subPaths;

    const connectedSubPaths = [];

    for (let i = 0; i < subPaths.length; i++) {
      const subPath = subPaths[i];

      if (subPath.type === SUB_PATH_TYPES.IN) {
        // If it is an IN sub path, leave it as it is
        connectedSubPaths.push(subPaths[i]);
      }

      if (subPath.type === SUB_PATH_TYPES.OUT) {
        // If it is an OUT sub path, connect it to the sub paths inside the road polygon
        //
        //         (OUT)  _______________
        //               /                \
        // (IN) ________/                  \___________ (IN)
        //
        // Both sides needs to be connected from the OUT sub path in order to preserve
        // the path color.
        //
        // Special case when the OUT path is at the start (no previous sub path)
        // or at the end (no next sub path) of the full path.

        const previousSubPath = subPaths[i - 1];
        const nextSubPath = subPaths[i + 1];

        const initialPoint = previousSubPath
          ? previousSubPath.points[previousSubPath.points.length - 1]
          : null;
        const lastPoint = nextSubPath ? nextSubPath.points[0] : null;

        const points = [initialPoint, ...subPaths[i].points, lastPoint];

        connectedSubPaths.push({
          ...subPaths[i],
          points: points.filter((element) => element), // filter null elements
        });
      }
    }
    return connectedSubPaths;
  };

  const [connectedSubPaths, setConnectedSubPaths] = useState(
    connectSubPaths(data.pointRoad)
  );

  const roadPolygon = data.road.coordinates;
  const roadPolygonOptions = { color: "blue", weight: 1 };

  const roadStartPosition = data.roadStart
    ? [data.roadStart.latitud, data.roadStart.longitud]
    : null;
  const roadEndPosition = data.roadEnd
    ? [data.roadEnd.latitud, data.roadEnd.longitud]
    : null;

  return (
    <div id="routeDetourReport">
      <MapContainer
        center={initialPosition}
        zoom={zoom}
        scrollWheelZoom={false}
        style={{ width: "100%", height: "100%" }}
        whenCreated={(instance) => {
          setMap(instance);
        }}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        {roadStartPosition && (
          <Marker position={roadStartPosition} icon={routeStartEventIcon}>
            <Tooltip permanent>{t("Inicio de ruta")}</Tooltip>
          </Marker>
        )}

        {roadEndPosition && (
          <Marker position={roadEndPosition} icon={routeEndEventIcon}>
            <Tooltip permanent>{t("Fin de ruta")}</Tooltip>
          </Marker>
        )}

        <Polygon positions={roadPolygon} options={roadPolygonOptions} />

        {connectedSubPaths.length > 0 &&
          connectedSubPaths.map((subPath, key) => {
            const polylineOptions = getSubpathOptions(
              subPath,
              data.roadStart,
              data.roadEnd
            );
            const coords = subPath.points.map((sub) => [
              sub.latitud,
              sub.longitud,
            ]);

            const startingSubPathDate = new Date(
              subPath.points[0].fechaGPS
            ).toISOString();
            const startEventDate = startingSubPathDate
              .split("T")[1]
              .split(".")[0];

            const endingSubPathDate = new Date(
              subPath.points[subPath.points.length - 1].fechaGPS
            ).toISOString();
            const endEventDate = endingSubPathDate.split("T")[1].split(".")[0];

            return (
              <Polyline pathOptions={polylineOptions} positions={coords}>
                {subPath.type === "In" && (
                  <SubPathInformationPopup title={t("Vehiculo en ruta")}>
                    <table className="popup-table">
                      <tbody>
                        <tr>
                          <td>{`${t("Inicio trayecto")}:`}</td>
                          <td>{startEventDate}</td>
                        </tr>
                        <tr>
                          <td>{`${t("Fin trayecto")}:`}</td>
                          <td>{endEventDate}</td>
                        </tr>
                      </tbody>
                    </table>
                  </SubPathInformationPopup>
                )}
                {subPath.type === "Out" && (
                  <SubPathInformationPopup title={t("Desvio de ruta")}>
                    <table className="popup-table">
                      <tbody>
                        <tr>
                          <td>{`${t("Inicio desvio")}:`}</td>
                          <td> {startEventDate}</td>
                        </tr>
                        <tr>
                          <td>{`${t("Fin desvio")}:`}</td>
                          <td>{endEventDate}</td>
                        </tr>
                      </tbody>
                    </table>
                  </SubPathInformationPopup>
                )}
              </Polyline>
            );
          })}
      </MapContainer>
    </div>
  );
};

export default ReportResult;
