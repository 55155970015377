import React, { useState, useEffect } from "react";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import { Button, Checkbox } from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import Table from "../../../components/Base/Table";
import ThreeDotMenu from "../../../components/Base/ThreeDotMenu";

import { useTranslation } from "../../../lang";
import ConfNotificationModal from "./Form";

const theme = createMuiTheme({});

const List = ({
  getConfNotification,
  confNotificationList,
  paginationSizes,
  paginationState,
  setPaginationState,
  total,
  setResultState,
  createConfNotification,
  updateConfNotification,
  deleteConfNotification,
}) => {
  const [t] = useTranslation();

  const [selectedConfNotification, setSelectedConfNotification] = useState();
  const [openSide, setOpenSide] = useState(false);

  useEffect(() => {
    getConfNotification();
  }, []);

  const confirmDelete = (id, name) => {
    confirmAlert({
      title: t("DeleteConfNotificationTitle"),
      message: t("DeleteConfNotificationMessage", { name }),
      buttons: [
        {
          label: t("Si"),
          onClick: () => {
            deleteConfNotification(id);
          },
        },
        {
          label: t("No"),
          onClick: () => console.log("cancelar"),
        },
      ],
    });
  };

  const itemStyle = {
    fontSize: "12px",
  };

  const callbackSelectOption = (option, row) => {
    switch (option) {
      case 1:
        setSelectedConfNotification(row);
        setOpenSide(true);
        break;
      case 2:
        confirmDelete(row.id, `${row.name}`);
        break;
    }
  };

  const columns = [
    {
      dataField: "id",
      text: "",
      headerStyle: () => ({ width: "50px", fontSize: "12px" }),
      formatter: (cell, row) => {
        return (
          <>
            <ThreeDotMenu
              key={row.id}
              row={row}
              callback={callbackSelectOption}
              options={[
                {
                  label: t("Editar"),
                  value: 1,
                },
                {
                  label: t("Eliminar"),
                  value: 2,
                },
              ]}
            />
          </>
        );
      },
    },
    {
      dataField: "name",
      text: t("Nombre"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
    },
    {
      dataField: "enabled",
      text: t("Enabled"),
      headerStyle: () => ({ width: "15%", fontSize: "12px" }),
      formatter: (cell, row) => (
        <ThemeProvider theme={theme}>
          <Checkbox color="primary" checked={row.enabled} disabled />
        </ThemeProvider>
      ),
      sort: true,
    },
    {
      dataField: "mailList",
      text: t("MailList"),
      headerStyle: () => ({ width: "60%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
    },
  ];

  const customTotal = (from, to, size) => (
    <span
      className="react-bootstrap-table-pagination-total customTotalDisplay"
      style={{ marginLeft: "15px" }}
    >
      {t("pages_manage_linea_mostrar_resultado", { from, to, size })}
    </span>
  );

  const options = {
    // Tooltips botones de paginación
    prePageTitle: t("Página previa"),
    nextPageTitle: t("Próxima página"),
    firstPageTitle: t("Primera página"),
    lastPageTitle: t("Última página"),
    showTotal: true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: paginationSizes,
    totalSize: total,
  };

  return (
    <div className="listContainer">
      <SlidingPane
        className="slidingPaneRight"
        isOpen={openSide}
        title={
          selectedConfNotification
            ? t("ActualizarConfiguracion")
            : t("CrearConfiguracion")
        }
        width="400px"
        onRequestClose={() => {
          setOpenSide(false);
        }}
      >
        <ConfNotificationModal
          closeModal={() => setOpenSide(false)}
          createConfNotification={createConfNotification}
          selectedConfNotification={selectedConfNotification}
          updateConfNotification={updateConfNotification}
          isOpen={openSide}
        />
      </SlidingPane>
      <Table
        columns={columns}
        data={confNotificationList}
        keyField="id"
        paginationOptions={options}
        lateralComponent={
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setSelectedConfNotification(null);
              setOpenSide(true);
            }}
            style={{ float: "right" }}
          >
            {t("+ Configuración")}
          </Button>
        }
      />
    </div>
  );
};

export default List;
