// eslint-disable react/forbid-prop-types
import BarChartIcon from "@material-ui/icons/BarChart";
import PublicIcon from "@material-ui/icons/Public";
import DrivingBehaviorsIcon from "../public/icons/DrivingBehaviorsIcon";
import JurneyIcon from "../public/icons/JurneyIcon";
import MantenimientoIcon from "../public/icons/MantenimientoIcon";
import NotificacionIcon from "../public/icons/NotificacionIcon";
import SettingIcon from "../public/icons/SettingIcon";
import TruckIcon from "../public/icons/TruckIcon";

export const NAVBAR_SECTIONS = [
  {
    icon: PublicIcon,
    label: "Mapa",
    controls: "Mapa",
    to: "/map",
    title: "Mapa Principal",
  },
  {
    icon: TruckIcon,
    label: "Menu",
    controls: "Menu",
    panel: "EstadoFlota",
    title: "Estado de Flota",
  },
  {
    icon: JurneyIcon,
    //label: "Pindrop",
    //controls: "Pindrop",
    //panel: "Pindrop",
    panel: "JourneyReport",
    title: "Reporte de Recorridos",
  },
  {
    icon: DrivingBehaviorsIcon,
    label: "Adjust",
    controls: "Adjust",
    panel: "DrivingBehavior",
    title: "Conductas de Manejo",
  },
  {
    icon: NotificacionIcon,
    label: "NotificationsNoneIcon",
    controls: "NotificationsNoneIcon",
    panel: "Notification",
    title: "Alarmas",
  },
  // {
  //   icon: GeocercasIcon,
  //   label: "Crop",
  //   controls: "Crop",
  //   panel: "Geocerca",
  // },
  {
    icon: MantenimientoIcon,
    label: "Tool",
    controls: "Tool",
    panel: "Mantenimiento",
    title: "Mantenimiento",
  },
  // {
  //   icon: FleetIcon,
  //   label: "People",
  //   controls: "People",
  // },
  {
    icon: SettingIcon,
    //to: "/manage",
    label: "Settings",
    controls: "Settings",
    panel: "Administration",
    title: "Administración",
  },
  {
    icon: BarChartIcon,
    label: "Statistics",
    controls: "Statistics",
    panel: "Estadisticas",
    title: "Estadísticas",
  },
];
