import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const DrivingBehaviorsIcon = function (props) {
  return (
    <SvgIcon viewBox="0 0 26 26">
      <path
        d="M47.96,35.46a12.5,12.5,0,1,0,12.5,12.5A12.5,12.5,0,0,0,47.96,35.46Zm0,1.613A10.9,10.9,0,0,1,58.847,47.96H53.524a4.038,4.038,0,0,0-3.951-3.226H46.347A4.038,4.038,0,0,0,42.4,47.96H37.073A10.9,10.9,0,0,1,47.96,37.073Zm-.285,15.106-3.4-3.4a.831.831,0,0,1-.179-.891,2.424,2.424,0,0,1,2.254-1.539h3.226a2.424,2.424,0,0,1,2.254,1.539.831.831,0,0,1-.179.891l-3.4,3.4a.4.4,0,0,1-.57,0h0ZM37.206,49.572h5.581l4.367,4.366v4.868a10.891,10.891,0,0,1-9.948-9.234Zm11.561,9.234V53.938l4.367-4.366h5.581A10.891,10.891,0,0,1,48.766,58.806Z"
        transform="translate(-35.46 -35.46)"
      />
    </SvgIcon>
  );
};

export default DrivingBehaviorsIcon;
