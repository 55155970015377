import gql from "graphql-tag";

const DEVICE_QUERY = gql`
  query getDevice($id: Int!) {
    getDevice(id: $id) {
      id
      deviceIdentifier
      deviceType {
        id
        description
      }
      expirationPassword
      firmwareVersion
      scriptVersion
      hasCanBusy
      tempConversionFactor
      model
      serialNumber
      line {
        id
        lineNumber
        email
      }
      imei
      createdDate
      assetDevice {
        dateAssociated
      }
      line {
        createdAt
      }
    }
  }
`;

export default DEVICE_QUERY;
