import React, { useState } from "react";
import { Menu, MenuItem, IconButton } from "@material-ui/core";

import { MoreVert } from "@material-ui/icons";
import { APP_COLORS } from "./../../Theme";

const ThreeDotsMenu = ({ callback, options, row, menuKey, buttonClass }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (evt) => {
    setAnchorEl(evt.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        style={{ padding: "0" }}
        className={buttonClass}
      >
        <MoreVert style={{ color: APP_COLORS.APP_BASE_TEXT_COLOR }} />
      </IconButton>
      <Menu
        id="card-actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        key={menuKey}
      >
        {options.map((o) => (
          <MenuItem
            onClick={() => {
              callback(o.value, row);
              handleClose();
            }}
            key={o.value}
          >
            {o.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ThreeDotsMenu;
