import gql from "graphql-tag";
import { FormatEnum } from "../enumsReplicatedFromServerSide";

export const SUPPORTED_EXPORT_FILE_TYPES = {
  PDF: FormatEnum.PDF,
  EXCEL: FormatEnum.EXCEL,
};

const FLEET_STATE_EXPORT_QUERY = gql`
  query FleetStatusReportExport(
    $assetsSelected: [Int]!
    $fileType: FormatEnum
  ) {
    fleetStatusReportExport(
      assetsSelected: $assetsSelected
      fileType: $fileType
    ) {
      fileType
      filePayload
    }
  }
`;
export default FLEET_STATE_EXPORT_QUERY;
