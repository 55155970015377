import gql from "graphql-tag";

const GROUPS_BYCUSTOMERMIN_QUERY = gql`
  query GroupsByCustomerMin($customerId: Int!) {
    groupsByCustomer(customerId: $customerId) {
      name
      id
      customerId
      parentGroupId
      customer {
        id
        name
      }
    }
  }
`;

export default GROUPS_BYCUSTOMERMIN_QUERY;
