import { Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import Table from "../../../components/Base/Table";
import ThreeDotMenu from "../../../components/Base/ThreeDotMenu";
import GuideNumberModal from "./Form";
import { useTranslation } from "../../../lang";

const List = ({
  paginationSizes,
  total,
  guideNumbersList,
  assignGuideNumber,
  getGuideNumbers,
  deleteGuideNumber,
}) => {
  const [t] = useTranslation();
  const [openSide, setOpenSide] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [textToSearch] = useState("");

  useEffect(() => {
    getGuideNumbers();
  }, []);

  const confirmDelete = (id, GuideNumber) => {
    confirmAlert({
      title: t("Desasignar Número de Guía"),
      message: t("pages_manage_guidenumber_desasignar_numero_guia", {
        guideNumber: GuideNumber,
      }),
      buttons: [
        {
          label: t("Si"),
          onClick: () => {
            deleteGuideNumber(id);
          },
        },
        {
          label: t("No"),
          onClick: () => console.log("cancelar"),
        },
      ],
    });
  };

  const itemStyle = {
    fontSize: "12px",
  };

  const callbackSelectOption = (option, row) => {
    switch (option) {
      case 1:
        setSelectedItem(row);
        setOpenSide(true);
        break;
      case 2:
        confirmDelete(row.id, `${row.GuideNumber}`);
        break;
    }
  };

  const hightlightIfMatch = (text) => {
    if (
      text &&
      textToSearch &&
      text.toLocaleLowerCase().includes(textToSearch.toLocaleLowerCase())
    ) {
      const newText = text.replace(
        new RegExp(textToSearch, "gi"),
        (match) => `<mark>${match}</mark>`
      );
      return <div dangerouslySetInnerHTML={{ __html: newText }} />;
    }

    return text;
  };

  const columns = [
    {
      dataField: "id",
      text: "",
      headerStyle: () => ({ width: "50px", fontSize: "12px" }),
      formatter: (row) => {
        return (
          <>
            <ThreeDotMenu
              row={row}
              callback={callbackSelectOption}
              options={[
                {
                  label: `${t("Desasignar")}`,
                  value: 2,
                },
              ]}
            />
          </>
        );
      },
    },
    {
      dataField: "GuideNumber",
      text: t("Número de Guía"),
      style: () => itemStyle,
      formatter: hightlightIfMatch,
      sort: true,
    },
    {
      dataField: "asset.name",
      text: t("Móvil"),
      style: () => itemStyle,
      sort: true,
      formatter: hightlightIfMatch,
    },
    {
      dataField: "asset.licensePlate",
      text: t("Placa"),
      style: () => itemStyle,
      sort: true,
      formatter: hightlightIfMatch,
    },
  ];

  const customTotal = (from, to, size) => (
    <span
      className="react-bootstrap-table-pagination-total customTotalDisplay"
      style={{ marginLeft: "15px" }}
    >
      {t("pages_manage_guidenumber_mostrar_resultados", { from, to, size })}
    </span>
  );

  const options = {
    // Tooltips botones de paginación
    prePageTitle: t("Página previa"),
    nextPageTitle: t("Próxima página"),
    firstPageTitle: t("Primera página"),
    lastPageTitle: t("Última página"),
    showTotal: true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: paginationSizes,
    totalSize: total,
  };

  return (
    <div className="listContainer">
      <SlidingPane
        className="slidingPaneRight"
        isOpen={openSide}
        title={
          selectedItem
            ? t("Actualizar Número de Guía")
            : t("Asignar Número de Guía")
        }
        width="400px"
        onRequestClose={() => {
          setOpenSide(false);
        }}
      >
        <GuideNumberModal
          setIsOpen={() => setOpenSide(false)}
          assignGuideNumber={assignGuideNumber}
          selectedGuideNumber={selectedItem}
        />
      </SlidingPane>
      <Table
        columns={columns}
        data={guideNumbersList}
        keyField="id"
        paginationOptions={options}
        //total={total}
        lateralComponent={
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setSelectedItem(null);
              setOpenSide(true);
            }}
            style={{ float: "right" }}
          >
            {t("+ Número de Guía")}
          </Button>
        }
      />
    </div>
  );
};

export default List;
