import gql from "graphql-tag";

const GET_DETENTIONREPORT_QUERY = gql`
  query DetentionReport(
    $assetsSelected: [Int]!
    $driversSelected: [Int]
    $fechaInicio: Datetime
    $fechaFin: Datetime
    $detentionStatus: DetentionStatus
    $minimumTime: Int
    $days: [WeekDaysEnum]
  ) {
    detentionReport(
      assetsSelected: $assetsSelected
      driversSelected: $driversSelected
      fechaInicio: $fechaInicio
      fechaFin: $fechaFin
      detentionStatus: $detentionStatus
      minimumTime: $minimumTime
      days: $days
    ) {
      mid
      name
      pat
      nInt
      brand
      typeMobileDescription
      detentionReports {
        nCh
        uIni
        iTime
        fTime
        detentionStatus
        detentionTime
        lat
        lon
      }
    }
  }
`;

export default GET_DETENTIONREPORT_QUERY;
