import React from "react";
import LoginContainerPanel from "./LoginContainerPanel";
import "./style.scss";

const LoginContainer = ({ children }) => {
  return (
    <div className="custom-login-container">
      <LoginContainerPanel>{children}</LoginContainerPanel>
    </div>
  );
};

export default LoginContainer;
