import React from "react";
import { AppBar, Toolbar } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import classNames from "classnames";
import { useHistory, useLocation } from "react-router-dom";
import { useAvailableNavbarItems } from "../../utils/navbar";
import { hasPermission } from "../../utils/generalUtils";

const IconsContainer = ({
  openPanel,
  /*openPanelAux,
  closePanel,
  closePanelAux,*/
  changePanelType,
  loggedUser,
}) => {
  const [availableNavbarItems] = useAvailableNavbarItems();
  const history = useHistory();
  const location = useLocation();

  if (!availableNavbarItems.length) {
    return null;
  }

  const navbarItems = availableNavbarItems.map(
    ({ icon, label, controls, to, panel, title }) => {
      const Icon = icon;
      const iconButtonClassNames = classNames("navbar__icon-button");
      const showIcon =
        (hasPermission("Notificaciones", loggedUser.roles) &&
          panel === "Notification") ||
        (hasPermission("ConductasManejo", loggedUser.roles) &&
          panel === "DrivingBehavior") ||
        (hasPermission("ReporteRecorrido", loggedUser.roles) &&
          panel === "JourneyReport") ||
        (hasPermission("EstadoFlota", loggedUser.roles) &&
          panel === "EstadoFlota") ||
        (hasPermission("Mantenimiento", loggedUser.roles) &&
          panel === "Mantenimiento") ||
        ![
          "Notification",
          "DrivingBehavior",
          "JourneyReport",
          "EstadoFlota",
          "Mantenimiento",
        ].includes(panel);

      return showIcon ? (
        <IconButton
          // title={label}
          key={`key-${label}`}
          color="inherit"
          aria-label={label}
          aria-controls={controls}
          aria-haspopup="true"
          title={title}
          onClick={() => {
            if (to) {
              if (
                to !== "/map" ||
                (to === "/map" && location.pathname !== "/map")
              ) {
                history.push(to);
              }
            } else {
              openPanel();
              changePanelType(panel);
            }
          }}
          className={iconButtonClassNames}
        >
          <Icon />
        </IconButton>
      ) : (
        <div />
      );
    }
  );

  return (
    <AppBar
      position="static"
      className="navbar-base navbar--lateral navbar--left"
    >
      <Toolbar>{navbarItems}</Toolbar>
    </AppBar>
  );
};

export default IconsContainer;
