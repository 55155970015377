import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { useQuery, useLazyQuery } from "@apollo/client";
import {
  downloadExcel,
  downloadKML,
  downloadPDF,
} from "../../../utils/generalUtils";

import Structure from "../../../components/Base/Structure";

import Filter from "./Filter";
import ReportResult from "./ReportResult";

import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import PageTitle from "../../../components/Base/PageTitle";

import ASSETS_BYGROUPS_QUERY from "../../../graphql/queries/assetByGroupsQuery";
import GET_GEOFENCE_QUERY from "../../../graphql/queries/getGeofencesQuery";
import GEOSCALE_REPORT_QUERY from "../../../graphql/queries/getGeoscaleReportQuery";
import GEOSCALE_REPORT_EXPORT_QUERY from "../../../graphql/queries/geoScaleReportExportQuery";
import { useFilterValidation } from "../useFilterValidation";

import { useTranslation } from "../../../lang";
import { localDateTimeStringToUTC } from "../../../utils/dates";

const GeoScaleReport = ({ selectedGroups, selectedCustomers }) => {
  const [t] = useTranslation();
  const [assetList, setAssetList] = useState([]);
  const [geofenceList, setGeofenceList] = useState([]);
  const [exportSelected, setExportSelected] = useState("EXCEL");
  const [assetTableState, setAssetTableState] = useState({
    searchText: "",
    sort: undefined,
    paginate: false,
  });

  const [reportResult, setReportResult] = useState([]);

  const [resultState, setResultState] = useState({
    isOpen: false,
    type: "success",
    msg: "",
  });

  const {} = useQuery(ASSETS_BYGROUPS_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      groupIDs: selectedGroups.filter(
        (g) => g && !Number.isNaN(Number.parseInt(g))
      ),
      ...assetTableState,
    },
    onCompleted(d) {
      const { count, items } = d.assetsByGroups;
      //setTotal(count);
      setAssetList(items);
    },
  });

  const [fetchGeofences] = useLazyQuery(GET_GEOFENCE_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      setGeofenceList(d.geofence.items);
    },
  });

  useEffect(() => {
    let customerId;
    if (Array.isArray(selectedCustomers) && selectedCustomers.length > 0) {
      customerId = selectedCustomers[selectedCustomers.length - 1].value;
      fetchGeofences({
        variables: {
          customerId: customerId,
        },
      });
    }
  }, [selectedCustomers]);

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setResultState({
      ...resultState,
      isOpen: false,
    });
  };

  const [fetchReport] = useLazyQuery(GEOSCALE_REPORT_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      if (d.geoscalesReport) setReportResult(d.geoscalesReport);
      else {
        setReportResult([]);
        setResultState({
          isOpen: true,
          type: "info",
          msg: t("No se encontraron datos para su consulta"),
        });
      }
    },
    onError(err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: err.message,
      });
    },
    // onCompleted(d) {
    //   setReportResult(d.geoscalesReport);
    // },
  });

  const [getExport] = useLazyQuery(GEOSCALE_REPORT_EXPORT_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      const filename = t("ReporteGeoescalas");
      switch (exportSelected) {
        case "EXCEL":
          downloadExcel(d.geoscalesReportExport, filename);
          break;
        case "PDF":
          downloadPDF(d.geoscalesReportExport, filename);
          break;
        case "KML":
          downloadKML(d.geoscalesReportExport, filename);
          break;
      }
    },
  });

  const handleSearchReportGeofences = ({
    assetsSelected,
    geofencesSelected,
    fechaInicio,
    fechaFin,
    days,
  }) => {
    fetchReport({
      variables: {
        assetsSelected,
        geofencesSelected,
        fechaInicio: localDateTimeStringToUTC(fechaInicio),
        fechaFin: localDateTimeStringToUTC(fechaFin),
        days,
      },
    });
  };

  const { handleSearchReport, errors: filterErrors } = useFilterValidation(
    handleSearchReportGeofences
  );

  const handleExportExcel = (
    assetsSelected,
    geofencesSelected,
    fechaInicio,
    fechaFin,
    days
  ) => {
    setExportSelected("EXCEL");
    getExport({
      variables: {
        assetsSelected,
        geofencesSelected,
        fechaInicio: localDateTimeStringToUTC(fechaInicio),
        fechaFin: localDateTimeStringToUTC(fechaFin),
        days,
        format: "EXCEL",
      },
    });
  };

  const handleExportPDF = (
    assetsSelected,
    geofencesSelected,
    fechaInicio,
    fechaFin,
    days
  ) => {
    setExportSelected("PDF");
    getExport({
      variables: {
        assetsSelected,
        geofencesSelected,
        fechaInicio: localDateTimeStringToUTC(fechaInicio),
        fechaFin: localDateTimeStringToUTC(fechaFin),
        days,
        format: "PDF",
      },
    });
  };

  return (
    <Structure>
      <Snackbar
        open={resultState.isOpen}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleCloseNotification}
          severity={resultState.type}
          variant="filled"
        >
          {resultState.msg}
        </MuiAlert>
      </Snackbar>
      <PageTitle sector={t("Estadísticas")} title={t("Geoescalas")} />
      <Filter
        assets={assetList}
        geofences={geofenceList}
        searchReport={handleSearchReport}
        handleExportExcel={handleExportExcel}
        handleExportPDF={handleExportPDF}
        errors={filterErrors}
        reportResult={Array.isArray(reportResult) && reportResult.length > 0}
      />
      {Array.isArray(reportResult) && reportResult.length > 0 && (
        <ReportResult reportData={reportResult} />
      )}
    </Structure>
  );
};

const _mapStateToProps = ({ groups }) => {
  return {
    selectedGroups: groups.selectedGroups,
    selectedCustomers: groups.selectedCustomers,
  };
};

export default connect(_mapStateToProps, null)(GeoScaleReport);
