import gql from "graphql-tag";

const GET_DRIVER_EXTRAINFO_QUERY = gql`
  query getDriver($id: Int!) {
    getDriver(id: $id) {
      id
      photo
    }
  }
`;

export default GET_DRIVER_EXTRAINFO_QUERY;

//photofull
