import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const NotificacionIcon = function () {
  return (
    <SvgIcon viewBox="0 0 26 26">
      <path
        d="M70.419,50.9a1.563,1.563,0,0,1-1.563-1.562H67.294a3.125,3.125,0,0,0,6.25,0H71.981A1.563,1.563,0,0,1,70.419,50.9Zm10.224-7.089c-1.367-1.3-2.409-2.66-2.409-7.271A7.618,7.618,0,0,0,71.2,29.1v-.857a.781.781,0,0,0-1.563,0V29.1a7.616,7.616,0,0,0-7.031,7.438c0,4.61-1.044,5.971-2.41,7.271a2.272,2.272,0,0,0-.553,2.5,2.33,2.33,0,0,0,2.18,1.465H79.013a2.331,2.331,0,0,0,2.18-1.465,2.272,2.272,0,0,0-.55-2.5Zm-1.63,2.4H61.825a.741.741,0,0,1-.553-1.27c1.7-1.621,2.895-3.435,2.895-8.4a6.257,6.257,0,0,1,12.5,0c0,4.949,1.182,6.772,2.895,8.4A.739.739,0,0,1,79.013,46.21Z"
        transform="translate(-59.482 -27.46)"
      />
    </SvgIcon>
  );
};

export default NotificacionIcon;
