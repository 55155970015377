import gql from "graphql-tag";

const CREATE_TECHNICIAN_MUTATION = gql`
  mutation CreateTechnician(
    $code: String
    $firstname: String!
    $lastname: String!
    $email: String
    $phoneNumber: String!
    $mobilePhone: String!
    $customerId: Int
  ) {
    createTechnician(
      code: $code
      firstname: $firstname
      lastname: $lastname
      email: $email
      phoneNumber: $phoneNumber
      mobilePhone: $mobilePhone
      customerId: $customerId
    ) {
      id
    }
  }
`;

export default CREATE_TECHNICIAN_MUTATION;
