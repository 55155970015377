import gql from "graphql-tag";

const GEOSCALE_REPORT_QUERY = gql`
  query GeoscalesReport(
    $assetsSelected: [Int]!
    $geofencesSelected: [Int]
    $fechaInicio: Datetime
    $fechaFin: Datetime
    $days: [WeekDaysEnum]
  ) {
    geoscalesReport(
      assetsSelected: $assetsSelected
      geofencesSelected: $geofencesSelected
      fechaInicio: $fechaInicio
      fechaFin: $fechaFin
      days: $days
    ) {
      mid
      name
      pat
      geofenceName
      iTime
      fTime
      duration
      rowid
    }
  }
`;

export default GEOSCALE_REPORT_QUERY;
