import { Button, Grid, IconButton } from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import React, { useState } from "react";
import DateWithTimeRangeSelectorFormatedByUserLanguage from "../../../components/Selectors/DateWithTimeRangeSelectorFormatedByUserLanguage";
import GenericSelector from "../../../components/Selectors/GenericSelector";
import { useTranslation } from "../../../lang";
import moment from "moment";
import { localDateTimeStringToUTC } from "../../../utils/dates";

import IconExcel from "../../../public/iconExcel.png";
import IconPDF from "../../../public/iconPDF.png";

const theme = createMuiTheme({});

const DEFAULT_INITIAL_DATE = moment().format("YYYY-MM-DD");
const DEFAULT_INITIAL_TIME = "00:00:00";
const DEFAULT_END_TIME = "23:59:59";
const DEFAULT_DATE_WITH_RANGE_TIME = {
  date: DEFAULT_INITIAL_DATE,
  timeFrom: DEFAULT_INITIAL_TIME,
  timeTo: DEFAULT_END_TIME,
};

const Filter = ({
  assets,
  roads,
  searchReport,
  errors,
  reportResult,
  handleExportExcel,
  handleExportPDF,
  user,
}) => {
  const [t] = useTranslation();
  const [dateWithTimeRange, setDateWithRangeTime] = useState({
    ...DEFAULT_DATE_WITH_RANGE_TIME,
  });
  const [assetSelected, setAssetSelected] = useState();
  const [roadSelected, setRoadSelected] = useState();

  const callbackAssetSelected = (value) => {
    setAssetSelected(value?.value);
  };

  const callbackRoadSelected = (value) => {
    setRoadSelected(value?.value);
  };

  const columnsAssets = [
    {
      dataField: "name",
      text: t("Nombre"),
      sort: true,
    },
    {
      dataField: "licensePlate",
      text: t("Patente"),
      sort: true,
    },
    {
      dataField: "internalNumber",
      text: t("Interno"),
      sort: true,
    },
    {
      dataField: "driver.firstName",
      text: t("Chofer"),
      sort: true,
    },
    {
      dataField: "typeMobile.description",
      text: t("Tipo"),
      sort: true,
    },
  ];
  const columnsRoads = [
    {
      dataField: "name",
      text: t("Nombre"),
      sort: true,
    },
  ];

  const handleChangePeriod = (evt) => {
    const newDateWithRangeTime = {
      date: evt.date,
      timeFrom: evt.startTime,
      timeTo: evt.endTime,
    };
    setDateWithRangeTime(newDateWithRangeTime);
  };

  const buildDateToFromDateWithRangeTime = (dateWithRangeTime) => {
    const localDateTimeString = `${dateWithRangeTime.date}T${dateWithRangeTime.timeTo}`;
    const utcDateTimeString = localDateTimeStringToUTC(localDateTimeString);
    return utcDateTimeString;
  };
  const buildDateFromFromDateWithRangeTime = (dateWithRangeTime) => {
    const localDateTimeString = `${dateWithRangeTime.date}T${dateWithRangeTime.timeFrom}`;
    const utcDateTimeString = localDateTimeStringToUTC(localDateTimeString);
    return utcDateTimeString;
  };

  return (
    <div style={{ padding: "20px" }}>
      <ThemeProvider theme={theme}>
        <Grid container>
          <Grid item xs={5}>
            <DateWithTimeRangeSelectorFormatedByUserLanguage
              onChange={handleChangePeriod}
              initialDate={dateWithTimeRange.date}
              initialStartTime={dateWithTimeRange.timeFrom}
              initialEndTime={dateWithTimeRange.timeTo}
              user={user}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={5}>
            <GenericSelector
              callbackOptionSelected={callbackAssetSelected}
              columns={columnsAssets}
              data={assets}
              keyField="id"
              labelField="licensePlate"
              title={t("Móvil")}
            />
            {errors && errors.assets && (
              <div className="input-feedback">{errors.assets}</div>
            )}
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={5}>
            <GenericSelector
              callbackOptionSelected={callbackRoadSelected}
              columns={columnsRoads}
              data={roads}
              keyField="id"
              labelField="name"
              title={t("Ruta")}
            />
            {errors && errors.roads && (
              <div className="input-feedback">{errors.roads}</div>
            )}
          </Grid>
        </Grid>

        <Grid container className="filterReportButtons">
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              searchReport({
                roadId: roadSelected,
                assetId: assetSelected,
                dateTo: buildDateToFromDateWithRangeTime(dateWithTimeRange),
                dateFrom: buildDateFromFromDateWithRangeTime(dateWithTimeRange),
                validations: [
                  {
                    field: roadSelected,
                    fieldName: "roads",
                    errorMessage: t("Debe seleccionar una ruta"),
                    // validationType: "required",
                  },
                  {
                    field: assetSelected,
                    fieldName: "assets",
                    errorMessage: t("Debe seleccionar un móvil"),
                    // validationType: "required",
                  },
                  {
                    field: dateWithTimeRange.timeFrom,
                    fieldName: "dateFrom",
                    errorMessage: t(
                      "Debe seleccionar una fecha de inicio de ruta"
                    ),
                    // validationType: "required",
                  },
                  {
                    field: dateWithTimeRange.timeTo,
                    fieldName: "dateTo",
                    errorMessage: t(
                      "Debe seleccionar una fecha de fin de ruta"
                    ),
                    // validationType: "required",
                  },
                ],
              });
            }}
          >
            {t("Generar Reporte")}
          </Button>

          {reportResult && reportResult.length > 0 && (
            <div style={{ marginLeft: "auto" }}>
              <IconButton
                title={t("EXCEL")}
                onClick={() =>
                  handleExportExcel(
                    assetSelected,
                    roadSelected,
                    buildDateFromFromDateWithRangeTime(dateWithTimeRange),
                    buildDateToFromDateWithRangeTime(dateWithTimeRange)
                  )
                }
              >
                <img
                  src={IconExcel}
                  className="exportIcon"
                  alt={t("exportar excel")}
                />
              </IconButton>
              <IconButton
                title={t("PDF")}
                onClick={() =>
                  handleExportPDF(
                    assetSelected,
                    roadSelected,
                    buildDateFromFromDateWithRangeTime(dateWithTimeRange),
                    buildDateToFromDateWithRangeTime(dateWithTimeRange)
                  )
                }
              >
                <img
                  src={IconPDF}
                  className="exportIcon"
                  alt={t("exportar pdf")}
                />
              </IconButton>
            </div>
          )}
        </Grid>
      </ThemeProvider>
    </div>
  );
};

export default Filter;
