import React from "react";

import DrawableMap from "../../../components/Base/DrawableMap";
import { GeometryTypes } from "../../../utils/generalUtils";

const Map = ({ handleChangeDraw, selectedGeometry }) => {
  return (
    <DrawableMap
      handleChangeDraw={handleChangeDraw}
      selectedGeometry={selectedGeometry}
      typeGeometry={GeometryTypes.POLYGON}
      //typeGeometry={GeometryTypes.LINE}
      //typeGeometry={GeometryTypes.MARKER}
    />
  );
};

export default Map;
