import React from "react";

import * as Yup from "yup";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { Field, Formik } from "formik";
import {
  Button,
  FormGroup,
  Grid,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";

import { useTranslation } from "../../../lang";
const theme = createMuiTheme({});

const ClientForm = ({
  setIsOpen,
  createCustomer,
  selectedCustomer,
  updateCustomer,
}) => {
  const [t] = useTranslation();
  // const [isOpen, setIsOpen] = useState(false);
  const initialValues = {
    customerId: selectedCustomer?.id,
    name: selectedCustomer?.name,
    taxId: selectedCustomer?.taxId,
    phone: selectedCustomer?.phone,
    celPhone: selectedCustomer?.celPhone,
    email: selectedCustomer?.email,
    address: selectedCustomer?.address,
    zoom: selectedCustomer?.zoom || 100,
    suspended: false,
    automaticMailAlarm: selectedCustomer?.automaticMailAlarm,
  };

  /*const joiSchema = Joi.object({
    name: Joi.string().required("Requirido"),
    email: Joi.string().email({ minDomainSegments: 2, tlds: { allow: false } }).message("Email invalido")
  })*/

  const yupSchema = Yup.object().shape({
    name: Yup.string().required(t("Requerido")),
    email: Yup.string()
      .email(t("Email invalido"))
      .when("automaticMailAlarm", {
        is: true,
        then: Yup.string().required(
          t(
            "Se requiere un email si las alarmas de email automaticas estan activadas"
          )
        ),
      }),
    automaticMailAlarm: Yup.boolean(),
  });

  return (
    <>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={yupSchema}
        onSubmit={async (values) => {
          if (selectedCustomer) {
            updateCustomer({
              variables: values,
            });
          } else {
            createCustomer({
              variables: values,
            });
          }
        }}
      >
        {(props) => {
          const {
            values,
            errors,
            handleSubmit,
            setFieldValue,
            // handleChange,
            // validateForm,
          } = props;

          return (
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <label className="formLabelFormik">{t("Nombre")}</label>
                <Field
                  type="text"
                  name="name"
                  tabIndex="0"
                  className={
                    errors.name
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                />
                {errors.name && (
                  <div className="input-feedback">{errors.name}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">{t("CUIT")}</label>
                <Field
                  type="text"
                  name="taxId"
                  tabIndex="0"
                  className={
                    errors.taxId
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                />
                {errors.taxId && (
                  <div className="input-feedback">{errors.taxId}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">{t("Teléfonos")}</label>
                <Field
                  type="text"
                  name="phone"
                  tabIndex="0"
                  className={
                    errors.phone
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                />
                {errors.phone && (
                  <div className="input-feedback">{errors.phone}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">{t("Celular")}</label>
                <Field
                  type="text"
                  name="celPhone"
                  tabIndex="0"
                  className={
                    errors.celPhone
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                />
                {errors.celPhone && (
                  <div className="input-feedback">{errors.celPhone}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">{t("Domicilio")}</label>
                <Field
                  type="text"
                  name="address"
                  tabIndex="0"
                  className={
                    errors.address
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                />
                {errors.address && (
                  <div className="input-feedback">{errors.address}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">{t("Mail")}</label>
                <Field
                  type="email"
                  name="email"
                  tabIndex="0"
                  className={
                    errors.email
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                />
                {errors.email && (
                  <div className="input-feedback">{errors.email}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">
                  {t("Nivel de Zoom (%)")}
                </label>
                <Field
                  type="number"
                  name="zoom"
                  tabIndex="0"
                  className={
                    errors.zoom
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                />
                {errors.zoom && (
                  <div className="input-feedback">{errors.zoom}</div>
                )}
              </FormGroup>
              <FormGroup>
                <ThemeProvider theme={theme}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={values.suspended}
                        onChange={(evt) =>
                          setFieldValue("suspended", evt.target.checked)
                        }
                        name="suspended"
                      />
                    }
                    label={t("Suspendido")}
                  />
                </ThemeProvider>
                {errors.suspended && (
                  <div className="input-feedback">{errors.suspended}</div>
                )}
              </FormGroup>
              <FormGroup>
                <ThemeProvider theme={theme}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={values.automaticMailAlarm}
                        onChange={(evt) =>
                          setFieldValue(
                            "automaticMailAlarm",
                            evt.target.checked
                          )
                        }
                        name="automaticMailAlarm"
                      />
                    }
                    label={t("Alarma de email automatica")}
                  />
                </ThemeProvider>
                {errors.suspended && (
                  <div className="input-feedback">
                    {errors.automaticMailAlarm}
                  </div>
                )}
              </FormGroup>
              <Grid item lg={12} style={{ marginTop: "50px" }}>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  tabIndex="0"
                >
                  {selectedCustomer ? t("Actualizar") : t("Crear")}
                </Button>
                <Button
                  variant="contained"
                  type="button"
                  tabIndex="0"
                  style={{ marginLeft: "20px" }}
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  {t("Cancelar")}
                </Button>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default ClientForm;
