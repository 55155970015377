import gql from "graphql-tag";

const GET_GROUPS_QUERY = gql`
  query Groups($userId: Int!) {
    groups(userId: $userId) {
      name
      id
      parentGroupId
      customerId
    }
  }
`;

export default GET_GROUPS_QUERY;
