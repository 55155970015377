import gql from "graphql-tag";

const FLEET_KM_REPORT_EXPORT = gql`
  query FleetKmReportExport(
    $assetsSelected: [Int]!
    $fechaInicio: Datetime
    $fechaFin: Datetime
    $days: [WeekDaysEnum]
    $format: FormatEnum
  ) {
    fleetKmReportExport(
      assetsSelected: $assetsSelected
      fechaInicio: $fechaInicio
      fechaFin: $fechaFin
      days: $days
      format: $format
    )
  }
`;

export default FLEET_KM_REPORT_EXPORT;
