import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

import reducer from '../redux/rootReducer';

import { configureInitialState, configureStore } from '../utils/store';

import ConnectedNotification from '../containers/ConnectedNotification';

import './LoginPage.scss';

export default class UserPages extends React.Component {
    static propTypes = {
        children: PropTypes.node
    };

    static defaultProps = {
        children: null
    };

    constructor(props) {
        super(props);

        /**
         * Let's create a store with redux-thunk and set the initial state
         * If you need to add or modify objects in the store, remember to start
         * adding them to the 'configureInitialState' method
         */
        this._userStore = configureStore({
            reducer,
            initialState: configureInitialState(props),
            middleware: [thunk]
        });
    }

    render() {
        const { children } = this.props;

        return (
            <Provider store={this._userStore}>
                {children}

                <ConnectedNotification />
            </Provider>
        );
    }
}
