import gql from "graphql-tag";

const CREATE_DEVICE_MUTATION = gql`
  mutation CreateDevice(
    $model: String
    $serialNumber: String
    $deviceIdentifier: String!
    $deviceTypeId: Int
    $firmwareVersion: String
    $scriptVersion: String
    $expirationPassword: String
    $hasCanBusy: Boolean
    $tempConversionFactor: Float
    $imei: String!
  ) {
    createDevice(
      model: $model
      serialNumber: $serialNumber
      deviceIdentifier: $deviceIdentifier
      deviceTypeId: $deviceTypeId
      firmwareVersion: $firmwareVersion
      scriptVersion: $scriptVersion
      expirationPassword: $expirationPassword
      hasCanBusy: $hasCanBusy
      tempConversionFactor: $tempConversionFactor
      imei: $imei
    ) {
      id
    }
  }
`;

export default CREATE_DEVICE_MUTATION;
