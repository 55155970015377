import gql from "graphql-tag";

const ROLES_QUERY = gql`
  query Roles {
    roles {
      id
      name
      category
      categoryId
      permission {
        id
      }
    }
  }
`;

export default ROLES_QUERY;
