import gql from "graphql-tag";

const CREATE_PLANNINGREPORT_MUTATION = gql`
  mutation CreatePlanningReport(
    $name: String
    $typeReportID: TypeReport
    $customerId: Int
    $formatId: Int
    $enabled: Boolean
    $mailList: String
    $mailListCco: String
    $allMovilesActivos: Boolean
    $assetsId: [Int]
    $usersDestinationsId: [Int]
    $datePeriod: DatePeriod
    $programations: [PlanningReportProgramationInput]
    $detentionReportArgs: DetentionPlanningInput
    $velocidadReportArgs: VelocityPlanningInput
    $journeyReportArgs: JourneyPlanningInput
    $geofenceReportArgs: GeofencePlanningInput
    $obd2ReportArgs: OBD2PlanningInput
    $stopsDetourReportArgs: StopsDetourPlanningInput
    $eventsReportArgs: EventsPlanningInput
  ) {
    createPlanningReport(
      name: $name
      typeReportID: $typeReportID
      customerId: $customerId
      formatId: $formatId
      enabled: $enabled
      mailList: $mailList
      mailListCco: $mailListCco
      allMovilesActivos: $allMovilesActivos
      assetsId: $assetsId
      usersDestinationsId: $usersDestinationsId
      datePeriod: $datePeriod
      programations: $programations
      detentionReportArgs: $detentionReportArgs
      velocidadReportArgs: $velocidadReportArgs
      journeyReportArgs: $journeyReportArgs
      geofenceReportArgs: $geofenceReportArgs
      obd2ReportArgs: $obd2ReportArgs
      stopsDetourReportArgs: $stopsDetourReportArgs
      eventsReportArgs: $eventsReportArgs
    ) {
      id
    }
  }
`;

export default CREATE_PLANNINGREPORT_MUTATION;
