import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import { useLazyQuery } from "@apollo/client";
import { FixedSizeList } from "react-window";
import {
  openPanelAux,
  changePanelAuxType,
  onChangeTab,
  openPanel,
  changePanelType,
} from "../../redux/lateralPanel/actions";

import { toggleFillMarkerInfo } from "../../redux/fillMarkerInfo/actions";

import { markersSelector } from "../../redux/markers/selector";

import clientSubscription from "../Base/apiApolloClientSubscription";

import GET_MARKERSBYGROUP_QUERY from "../../graphql/queries/pointsByGroups";
import GET_MARKERS_SUBSCRIPTION from "../../graphql/subscriptions/markersSubscription";

import FlotaState from "./FlotaState";

import "./styles.scss";
import FlotaItem from "./FlotaItem";
import useInterval from "../Base/useInterval";
import { setMarkerSelected } from "../../redux/notifications/actions";
import { useTranslation } from "../../lang";
import FlotaStateExportPanel from "./ExportPanel/FlotaStateExportPanel";

const EstadoFlotaPanel = ({
  groupsSelected,
  openPanelAux,
  changePanelAuxType,
  openPanel,
  changePanelType,
  onChangeTab,
  toggleFillMarkerInfo,
  setMarkerSelected,
  user,
}) => {
  const [t] = useTranslation();
  const container = document.getElementById("flotaEstadoContainer");

  const [data, setData] = useState([]);
  const [typeSelected, setTypeSelected] = useState(-1);
  const prevMarkers = useRef(new Map());

  const filteredData =
    typeSelected === -1 ? data : data.filter((x) => x.typ === typeSelected);

  const [fetchPoints] = useLazyQuery(GET_MARKERSBYGROUP_QUERY, {
    onCompleted(d) {
      setData(d.pointsByGroups);
    },
  });

  const successNewPointCallback = (dataSubscription) => {
    if (dataSubscription.data.newPoint) {
      const newPoint = dataSubscription.data.newPoint;

      const prev = new Map(prevMarkers.current);
      prev.set(newPoint.mid, newPoint);

      prevMarkers.current = prev;
    }
  };

  useInterval(() => {
    if (prevMarkers.current.size > 0) {
      const aux = Array.from(prevMarkers.current.values());
      const auxMarkers = [...data];
      if (Array.isArray(aux) && aux.length > 0) {
        aux.forEach((markerInfo, index) => {
          const id = data.findIndex((e) => {
            return e.mid === markerInfo.mid;
          });
          if (id >= 0) {
            auxMarkers[id] = markerInfo;
          } else {
            auxMarkers.push(markerInfo);
          }
        });

        setData(auxMarkers);
      }
    }
  }, 5000);

  const subscribeToPoints = (groupsIDs) => {
    let gIds = groupsIDs;
    if (!groupsIDs) {
      const user = JSON.parse(localStorage.getItem("loggedUser"));
      if (user) {
        const dataLS = localStorage.getItem(`groupsSelected_${user.id}`);
        if (dataLS) {
          gIds = dataLS.split(",").filter((g) => g && typeof g === "number");
        }
      }
    }

    let subscription = null;

    if (gIds) {
      const observer = clientSubscription.subscribe({
        query: GET_MARKERS_SUBSCRIPTION,
        variables: {
          groupsIDs: gIds,
        },
      });

      subscription = observer.subscribe({
        next(res) {
          successNewPointCallback(res);
        },
        error(err) {
          console.error("err", err);
        },
      });
    }

    return subscription;
  };

  useEffect(() => {
    let subscription = null;
    if (Array.isArray(groupsSelected) && groupsSelected.length > 0) {
      const intGroups = groupsSelected.filter(
        (g) => g && !Number.isNaN(Number.parseInt(g))
      );
      fetchPoints({
        variables: {
          groupsIDs: intGroups,
        },
      });
      subscription = subscribeToPoints(intGroups);
    }

    return () => subscription && subscription.unsubscribe();
  }, [groupsSelected]);

  const handleChangeType = (type) => {
    setTypeSelected(type);
  };

  const fillMarkerInfo = (marker) => {
    const point = markersSelector({
      markers: [marker],
      t,
    });

    toggleFillMarkerInfo({ markerInfo: point[0] });

    const index = data.findIndex((d) => d.mid === marker.mid);
    const updatedElement = {
      ...data[index],
      selected: true,
    };

    setData([
      ...data.slice(0, index).map((d) => ({
        ...d,
        selected: false,
      })),
      updatedElement,
      ...data.slice(index + 1).map((d) => ({
        ...d,
        selected: false,
      })),
    ]);
  };

  const fixedFlotaList = () => {
    //Es importantisimo que el parametro style se aplique a cada item
    const FLEET_STATUS_SUMMARY_HEIGHT = 75;
    const EXPORT_PANEL_HEIGHT = 55;
    return (
      <FixedSizeList
        height={
          container.offsetHeight -
          FLEET_STATUS_SUMMARY_HEIGHT -
          EXPORT_PANEL_HEIGHT
        }
        width={372}
        itemCount={filteredData.length}
        itemSize={65} //Alto de cada item
      >
        {({ index, style }) => (
          <FlotaItem
            style={style}
            point={filteredData[index]}
            openPanelAux={openPanel}
            changePanelAuxType={changePanelType}
            onChangeTab={onChangeTab}
            fillMarkerInfo={fillMarkerInfo}
            setMarkerSelected={setMarkerSelected}
          />
        )}
      </FixedSizeList>
    );
  };

  return (
    <div className="flotaEstadoContainer" id="flotaEstadoContainer">
      <FlotaState data={data} onChangeType={handleChangeType} user={user} />
      {container && fixedFlotaList()}
      <FlotaStateExportPanel assets={filteredData} />
    </div>
  );
};

const _mapStateToProps = ({ groups, user }) => {
  return {
    user: user,
    groupsSelected: groups.selectedGroups,
  };
};

const _mapDispatchToProps = (dispatch) => {
  return {
    openPanelAux: () => dispatch(openPanelAux()),
    changePanelAuxType: (param) => dispatch(changePanelAuxType(param)),
    openPanel: () => dispatch(openPanel()),
    changePanelType: (param) => dispatch(changePanelType(param)),
    onChangeTab: (tab) => dispatch(onChangeTab(tab)),
    toggleFillMarkerInfo: (point) => dispatch(toggleFillMarkerInfo(point)),
    setMarkerSelected: (marker) => dispatch(setMarkerSelected(marker)),
  };
};

export default connect(_mapStateToProps, _mapDispatchToProps)(EstadoFlotaPanel);
