import React, { useEffect, useState } from "react";

import { Checkbox, Button } from "@material-ui/core";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import Table from "../../../components/Base/Table";
import ModalConfirmacion from "../../../components/Base/ModalConfirmacion";
import ThreeDotMenu from "../../../components/Base/ThreeDotMenu";
import Form from "./Form";

import { useTranslation } from "../../../lang";
const theme = createMuiTheme({});

const List = ({
  planningReportList,
  reportTypes,
  customers,
  handleCreatePlanningReport,
  handleUpdatePlanningReport,
  handleDeletePlanningReport,
  groups,
  getGroups,
}) => {
  const [t] = useTranslation();
  const [selectedPlanning, setSelectedPlanning] = useState();
  const [openForm, setOpenForm] = useState(false);
  const closeModal = () => setOpenForm(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const closeModalConfirm = () => setIsOpenConfirm(false);
  const [confirmText, setConfirmText] = useState();
  const [idToDelete, setIdToDelete] = useState();

  const callbackSelectOption = (option, row) => {
    switch (option) {
      case 1:
        setSelectedPlanning(row);
        setOpenForm(true);
        break;
      case 2:
        setIsOpenConfirm(true);
        setConfirmText(
          t("pages_manage_reportingplanning_seguro_eliminar_planificacion", {
            name: row.name,
          })
        );
        setIdToDelete(row.id);
        break;
    }
  };

  const columns = [
    {
      dataField: "id",
      text: "",
      headerStyle: () => ({ width: "50px", fontSize: "12px" }),
      formatter: (cell, row) => {
        return (
          <>
            <ThreeDotMenu
              row={row}
              callback={callbackSelectOption}
              options={[
                {
                  label: t("Editar"),
                  value: 1,
                },
                {
                  label: t("Eliminar"),
                  value: 2,
                },
              ]}
            />
          </>
        );
      },
    },
    {
      dataField: "name",
      text: t("Plan"),
      sort: true,
    },
    {
      dataField: "customer.name",
      text: t("Cliente"),
      sort: true,
    },
    {
      dataField: "userCreator.userName",
      text: t("Creado por"),
      sort: true,
    },
    {
      dataField: "typeReportID",
      text: t("Reporte"),
      sort: true,
    },
    {
      dataField: "formatId",
      text: t("Formato"),
      sort: true,
      formatter: (cell, row) => (row.formatId === 1 ? "EXCEL" : "PDF"),
    },
    {
      dataField: "enabled",
      text: t("Habilitado"),
      formatter: (cell, row) => (
        <ThemeProvider theme={theme}>
          <Checkbox color="primary" checked={row.enabled} disabled />
        </ThemeProvider>
      ),
    },
  ];

  return (
    <div style={{ padding: "20px" }}>
      <ModalConfirmacion
        isOpen={isOpenConfirm}
        buttonConfirmation={t("Confirmar eliminación")}
        closeModal={closeModalConfirm}
        confirmationText={confirmText}
        handleConfirm={() => {
          handleDeletePlanningReport(idToDelete);
        }}
        title={t("IMPORTANTE")}
      />
      <Form
        isOpen={openForm}
        closeModal={closeModal}
        selectedPlanningReport={selectedPlanning}
        customers={customers}
        handleCreatePlanningReport={handleCreatePlanningReport}
        handleUpdatePlanningReport={handleUpdatePlanningReport}
        reportTypes={reportTypes}
        groups={groups}
        getGroups={getGroups}
      />
      <Table
        columns={columns}
        data={planningReportList}
        keyField="id"
        //paginationOptions={options}
        //total={total}
        //afterSearch={(value) => setTextToSearch(value)}
        lateralComponent={
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setSelectedPlanning(null);
              setOpenForm(true);
            }}
            style={{ float: "right" }}
          >
            {t("+ Reporte Planificado")}
          </Button>
        }
      />
    </div>
  );
};

export default List;
