import gql from "graphql-tag";

const GET_EVENTS_ALARM_QUERY = gql`
  query getEventsAlarm {
    getEventsAlarm {
      value: id
      label: description
    }
  }
`;

export default GET_EVENTS_ALARM_QUERY;
