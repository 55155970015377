import gql from "graphql-tag";

const USERS_QUERY = gql`
  query Users {
    users {
      id
      userName
      firstName
      lastName
      photo
      email
      contactNumber
      phone
      celPhone
      status
      timeSession
      allowGMaps
      roles {
        id
        name
        category
      }
      customer {
        id
        name
      }
      customerId
      #groups
      #planningReport
    }
  }
`;

export default USERS_QUERY;
