import gql from "graphql-tag";

const GET_ASSET_QUERY = gql`
  query Asset($assetId: Int!) {
    asset(assetId: $assetId) {
      brand
      color
      fuelCutWiring
      maximumSpeed
      model
      year
    }
  }
`;

export default GET_ASSET_QUERY;
