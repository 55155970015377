import React, { useState } from "react";

export const FILE_UPLOAD_BUTTON_VARIANTS = {
  PRIMARY: "PRIMARY",
};

const VARIANT_CLASS_MAP = {
  [FILE_UPLOAD_BUTTON_VARIANTS.PRIMARY]:
    "MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary",
  DEFAULT: "btn btn-primary",
};

const FileUpload = ({ file, title, onChange, variant }) => {
  const [newFile, setNewFile] = useState();
  console.log("file", file);

  const handleChange = (fileData) => {
    console.log("🚀 ~ filename", fileData?.target?.files[0]?.name);
    fileData.persist();
    const fr = new FileReader();

    fr.addEventListener("load", (e) => {
      onChange(e.target.result);
    });
    if (fileData && fileData.target && fileData.target.files) {
      // const fileContent = URL.createObjectURL(fileData.target.files[0]);

      //setNewFile(fileContent);
      setNewFile(fileData?.target?.files[0]?.name);

      fr.readAsDataURL(fileData.target.files[0]);
    }
  };

  return (
    <div className="">
      <label
        id="lblFileUpload"
        htmlFor="fileFileUpload"
        className={
          variant in FILE_UPLOAD_BUTTON_VARIANTS
            ? VARIANT_CLASS_MAP[variant]
            : VARIANT_CLASS_MAP.DEFAULT
        }
      >
        {title}
        <input
          id="fileFileUpload"
          type="file"
          accept="application/kml"
          onChange={(e) => handleChange(e)}
          onClick={(event) => {
            event.target.value = null;
          }}
          hidden
        />
      </label>
      {file && (
        <label className="formLabelFormik" style={{ paddingLeft: "5px" }}>
          {newFile}
        </label>
      )}
    </div>
  );
};

// Upload.propTypes = {
//   onChange: PropTypes.func,
//   t: PropTypes.func,
// };

export default FileUpload;
