import { useLazyQuery, useQuery } from "@apollo/client";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { ExitToApp, ExpandMore } from "@material-ui/icons";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import ASSETS_BYGROUPS_QUERY from "../../graphql/queries/assetByGroupsQuery";
import GET_ENUMS_QUERY from "../../graphql/queries/getEnumsQuery";
import JOURNEY_SUMMARY_QUERY from "../../graphql/queries/getJourneyReport";
import JOURNEY_REPORTPOSITION_QUERY from "../../graphql/queries/getJourneyReportPositionQuery";
import JOURNEY_EXCEL_QUERY from "../../graphql/queries/journeyExcelQuery";
import JOURNEY_KML_QUERY from "../../graphql/queries/journeyKMLQuery";
import JOURNEY_PDF_QUERY from "../../graphql/queries/journeyPDFQuery";
import { useTranslation } from "../../lang";
import { toggleFillMarkerInfoAux } from "../../redux/fillMarkerInfo/actions";
import {
  toggleFillMarkerJourney,
  toggleFillMarkerJourney2,
  toggleFillMarkerJourneyPersonalizado,
  _updateMarkerJourney,
} from "../../redux/fillMarkerJourney/actions";
import { toggleFillGenericPopup } from "../../redux/genericPopup/actions";
import { setJourneyReportPanelInitialStatus } from "../../redux/lateralPanel/actions";
import { localDateTimeStringToUTC } from "../../utils/dates";
import CustomSelectStyles from "../../utils/reactSelectStyle";
import DateSelector from "../Selectors/DateSelector";
import GenericSelector from "../Selectors/GenericSelector";
import JourneyAsset from "./JourneyAsset";
import "./styles.scss";

const theme = createMuiTheme({});
const dateFormat = "yyyy-MM-dd HH:mm:ss";
const modeAll = false;

const JourneyReportPanel = ({
  selectedGroups,
  updateMarkerJourney,
  journeyReportPanelConnectedStatus,
  setJourneyReportPanelInitialStatus,
}) => {
  const [t] = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const [assetList, setAssetList] = useState([]);
  const [assetsSelected, setAssetsSelected] = useState([]);
  const [anchorExport, setAnchorExport] = useState();
  const [fechas, setFechas] = useState({
    fechaDesde: format(new Date(new Date() - 60 * 60 * 1000), dateFormat),
    fechaHasta: format(new Date(), dateFormat),
  });
  const [dias, setDias] = useState();
  const [agrupacionSelected, setAgrupacionSelected] = useState();
  const [journeysSummary, setJourneySummary] = useState([]);
  const [journeyAgrupations, setJourneyAgrupations] = useState([]);
  const [searchAfterRedirect, setSearchAfterRedirect] = useState(false);
  const [periodSelectedOption, setPeriodSelectedOption] = useState(null);
  const [expandedAccordionItem, setExpandedAccordionItem] = useState(null);

  const [positionArguments, setPositionArguments] = useState({
    movilID: -1,
    fechaInicio: new Date(),
    fechaFin: new Date(),
  });

  useEffect(() => {
    // Set up accordion item to be expanded only once, after a redirection to /map.
    if (
      location.pathname === "/map" &&
      journeyReportPanelConnectedStatus &&
      journeyReportPanelConnectedStatus.expandedAccordionItem &&
      journeyReportPanelConnectedStatus.firstTimeAfterRedirect
    ) {
      const { expandedAccordionItem } = journeyReportPanelConnectedStatus;

      setExpandedAccordionItem(expandedAccordionItem || null);
    }
  }, []);

  useEffect(() => {
    // Load initial status from redux, only the first time after a redirection to /map.
    if (
      location.pathname === "/map" &&
      journeyReportPanelConnectedStatus &&
      journeyReportPanelConnectedStatus.firstTimeAfterRedirect &&
      assetList.length > 0
    ) {
      const {
        fechas,
        agrupacionSelected,
        dias,
        periodSelectedOption,
        assetsSelected,
        firstTimeAfterRedirect,
      } = journeyReportPanelConnectedStatus;

      setAgrupacionSelected(agrupacionSelected);
      setPeriodSelectedOption(periodSelectedOption);
      setAssetsSelected(assetsSelected);
      setFechas(fechas);
      setDias(dias);
      setJourneyReportPanelInitialStatus({
        firstTimeAfterRedirect: false,
      });
      setSearchAfterRedirect(true);
    }
  }, [assetList, journeyReportPanelConnectedStatus]);

  useEffect(() => {
    if (searchAfterRedirect) {
      handleSearch();
      setSearchAfterRedirect(false);
    }
  }, [searchAfterRedirect]);

  useQuery(ASSETS_BYGROUPS_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      groupIDs: selectedGroups.filter(
        (g) => g && !Number.isNaN(Number.parseInt(g))
      ),
    },
    onCompleted(d) {
      const { items } = d.assetsByGroups;
      setAssetList(items);
    },
  });

  const [fetchJourneyPositions] = useLazyQuery(JOURNEY_REPORTPOSITION_QUERY, {
    onCompleted(d) {
      const positions = d.journeyReportPositions;
      const index = journeysSummary.findIndex(
        (j) => j.mid === positionArguments.movilID
      );

      const newData = [
        ...journeysSummary.slice(0, index),
        {
          ...journeysSummary[index],
          positions: [
            ...(journeysSummary[index].positions || []),
            ...positions,
          ],
        },
        ...journeysSummary.slice(index + 1),
      ];

      setJourneySummary(newData);
      const summary = journeysSummary[index];

      const thisJourney = summary.journeyReports.find(
        (j) =>
          j.iTime === positionArguments.fechaInicio &&
          j.fTime === positionArguments.fechaFin
      );

      const journeyData = {
        mid: positionArguments.movilID,
        summary: {
          uIni: thisJourney.uIni,
          uFin: thisJourney.uFin,
          tIng: thisJourney.tIgn,
          tDet: "",
          odo: thisJourney.odo,
          sOdo: thisJourney.odo,
          pat: summary.pat,
          nCh: positions[0].nCh,
          nMov: summary.name,
          nInt: summary.nInt,
        },
        daySteps: [
          {
            date: thisJourney.iTime,
            steps: [
              {
                sid: 1,
                iTime: thisJourney.iTime,
                fTime: thisJourney.fTime,
                tIgn: thisJourney.tIgn,
                odo: thisJourney.odo,
                tDet: 0,
                listPos: positions.map((p, index) => ({
                  id: index,
                  lat: p.lat,
                  lon: p.lon,
                  cur: p.cur,
                  vel: p.vel,
                  fGPS: p.fGPS,
                  dir: p.dir,
                  course: p.course,
                  odo: p.odo,
                  pat: summary.pat,
                  nMov: summary.name,
                })),
              },
            ],
          },
        ],
      };

      if (location.pathname === "/map") {
        updateMarkerJourney(journeyData);
      } else {
        history.push({
          pathname: "/map",
          state: {
            journeyData: journeyData,
            lateralPanel: {
              panelType: "JourneyReport",
              panelInitialStatus: {
                firstTimeAfterRedirect: true,
                fechas,
                assetsSelected,
                agrupacionSelected,
                dias,
                periodSelectedOption,
                expandedAccordionItem,
              },
            },
          },
        });
      }
    },
  });

  useEffect(() => {
    if (positionArguments.movilID > -1) {
      fetchJourneyPositions({
        variables: positionArguments,
      });
    }
  }, [fetchJourneyPositions, positionArguments]);

  const [fetchExcelJourney] = useLazyQuery(JOURNEY_EXCEL_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      const fileType =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
      const downloadLink = document.createElement("a");
      downloadLink.download = `${t("ReporteRecorridos")}.xlsx`;
      downloadLink.href = `${fileType}${d.journeySummaryExcel}`;
      downloadLink.click();
    },
  });

  useQuery(GET_ENUMS_QUERY, {
    variables: {
      name: "JourneyGroupEnum",
    },
    onCompleted(d) {
      const enums = d.__type.enumValues.map((enumValue) => {
        return {
          ...enumValue,
          label: t(enumValue.label),
        };
      });

      setJourneyAgrupations(enums);
      setAgrupacionSelected(enums[0]);
    },
  });

  const [fetchPDFJourney] = useLazyQuery(JOURNEY_PDF_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      const fileType = "data:application/pdf;base64,";
      const downloadLink = document.createElement("a");
      downloadLink.download = `${t("ReporteRecorridos")}.pdf`;
      downloadLink.href = `${fileType}${d.journeySummaryPDF}`;
      downloadLink.click();
    },
  });

  const [fetchKMLJourney] = useLazyQuery(JOURNEY_KML_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      const kmlBlob = new Blob([d.journeyKML], {
        type: "data:application/vnd.google-earth.kml+xml;base64,",
      });
      const downloadURL = URL.createObjectURL(kmlBlob);
      const downloadLink = document.createElement("a");
      downloadLink.download = `${t("ReporteRecorridos")}.kml`;
      downloadLink.href = downloadURL;
      downloadLink.click();
    },
  });

  const handleExportSelect = (typeExport) => {
    setAnchorExport(null);
    switch (typeExport) {
      case "excel":
        fetchExcelJourney({
          variables: {
            fechaInicio: localDateTimeStringToUTC(fechas.fechaDesde),
            fechaFin: localDateTimeStringToUTC(fechas.fechaHasta),
            movilesID: assetsSelected.map((a) => a.value),
            groupedBy: agrupacionSelected.value,
          },
        });
        break;
      case "pdf":
        fetchPDFJourney({
          variables: {
            fechaInicio: localDateTimeStringToUTC(fechas.fechaDesde),
            fechaFin: localDateTimeStringToUTC(fechas.fechaHasta),
            movilesID: assetsSelected.map((a) => a.value),
            groupedBy: agrupacionSelected.value,
          },
        });
        break;
      default:
        break;
    }
  };

  const handleChangePeriod = (evt) => {
    if (evt.fechaDesde && evt.fechaHasta) {
      setFechas({
        fechaDesde: localDateTimeStringToUTC(evt.fechaDesde),
        fechaHasta: localDateTimeStringToUTC(evt.fechaHasta),
      });
    }

    if (evt.dias) {
      setDias(evt.dias);
    }

    if (evt.selectedOption) {
      setPeriodSelectedOption(evt.selectedOption);
    }
  };

  const onAssetSelect = (assets) => {
    setAssetsSelected(assets);
  };

  const [searchJourneys] = useLazyQuery(JOURNEY_SUMMARY_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      setJourneySummary(d.journeyReport);
    },
  });

  const handleSearch = () => {
    if (!modeAll && assetsSelected.length === 0) {
      alert(t("Seleccione un movil"));
      return;
    }

    searchJourneys({
      variables: {
        fechaInicio: fechas.fechaDesde,
        fechaFin: fechas.fechaHasta,
        movilesID: assetsSelected.map((a) => a.value),
        groupedBy: agrupacionSelected.value,
        days: dias,
      },
    });
  };

  const handleExpandAsset = (movilID) => {
    if (expandedAccordionItem === movilID) {
      setExpandedAccordionItem(null);
    } else {
      setExpandedAccordionItem(movilID);
    }
    // console.log("MovilID: ", movilID);
    //const count = journeyDetail.filter((x) => x.mid == movilID).length;
    /*if (count === 0) {
      fetchJourneyDetail({
        variables: {
          movilID: movilID,
          fechaInicio: fechas.fechaDesde,
          fechaFin: fechas.fechaHasta,
        },
      });
    }*/
  };

  const columns = [
    {
      dataField: "name",
      text: t("Nombre"),
      sort: true,
    },
    {
      dataField: "licensePlate",
      text: t("Patente"),
      sort: true,
    },
    {
      dataField: "internalNumber",
      text: t("Interno"),
      sort: true,
    },
    {
      dataField: "driver.firstName",
      text: t("Chofer"),
      sort: true,
    },
    {
      dataField: "typeMobile.description",
      text: t("Tipo"),
      sort: true,
    },
  ];

  return (
    <>
      <Grid container className="journeyReportFilter">
        <Grid item xs={12}>
          <GenericSelector
            callbackOptionSelected={(value) => onAssetSelect(value)}
            columns={columns}
            isMulti
            keyField="id"
            labelField="name"
            title={t("Móviles")}
            data={assetList}
            defaultValue={assetsSelected.map((a) => a.value)}
          />
        </Grid>
      </Grid>
      <Grid container className="journeyReportFilter">
        <Grid item xs={6}>
          {/* <PeriodSelectorContainer onChange={handleChangePeriod} /> */}
          <DateSelector
            onChange={handleChangePeriod}
            selectedOption={periodSelectedOption}
          />
        </Grid>
        <Grid item xs={6} style={{ padding: "0 10px 0 10px" }}>
          <Select
            placeholder={t("Agrupar por")}
            value={agrupacionSelected}
            options={journeyAgrupations}
            onChange={(option) => {
              setAgrupacionSelected(option);
            }}
            styles={CustomSelectStyles}
          />
        </Grid>
      </Grid>
      <div
        className="journeyReportFilter"
        style={{ textAlign: "center", width: "100%" }}
      >
        <Button color="primary" variant="contained" onClick={handleSearch}>
          {t("Buscar")}
        </Button>
        {Array.isArray(journeysSummary) && journeysSummary.length > 0 && (
          <>
            <IconButton
              aria-controls="exportMenu"
              aria-haspopup="true"
              onClick={(evt) => setAnchorExport(evt.currentTarget)}
            >
              <ExitToApp />
            </IconButton>
            <Menu
              id="exportMenu"
              anchorEl={anchorExport}
              keepMounted
              open={Boolean(anchorExport)}
              onClose={() => setAnchorExport(null)}
            >
              <MenuItem onClick={() => handleExportSelect("excel")}>
                {t("EXCEL")}
              </MenuItem>
              <MenuItem onClick={() => handleExportSelect("pdf")}>PDF</MenuItem>
            </Menu>
          </>
        )}
      </div>
      <div className="journeyReportDataContainer">
        <ThemeProvider theme={theme}>
          {!journeysSummary && <label>{t("No_hay_resultados")}</label>}
          {Array.isArray(journeysSummary) &&
            journeysSummary.map((js) => {
              return (
                <Accordion
                  key={`accordion_${js.mid}`}
                  onChange={() => handleExpandAsset(js.mid)}
                  expanded={js.mid === expandedAccordionItem}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls={`panel_${js.mid}_content`}
                    id={`panel_${js.mid}_header`}
                    style={{ height: "48px" }}
                  >
                    <Grid container>
                      <Grid item xs={4}>
                        <label style={{ paddingTop: "12px" }}>{js.pat}</label>
                      </Grid>
                      <Grid item xs={4} />
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: "0" }}>
                    <JourneyAsset
                      key={`jrSummary_${js.mid}`}
                      data={js}
                      setPositionArguments={setPositionArguments}
                    />
                  </AccordionDetails>
                </Accordion>
              );
            })}
        </ThemeProvider>
      </div>
    </>
  );
};

const _mapStateToProps = ({ groups, lateralPanel }) => {
  return {
    selectedGroups: groups.selectedGroups,
    journeyReportPanelConnectedStatus:
      lateralPanel.newPanelReducer.journeyReportPanelConnectedStatus,
  };
};

const _mapDispatchToProps = (dispatch) => {
  return {
    toggleFillMarkerJourney: (param) =>
      toggleFillMarkerJourney(param)(dispatch),
    toggleFillMarkerJourney2: (param) =>
      toggleFillMarkerJourney2(param)(dispatch),
    toggleFillMarkerJourneyPersonalizado: (param) =>
      toggleFillMarkerJourneyPersonalizado(param)(dispatch),
    toggleFillMarkerInfoAux: (param) =>
      toggleFillMarkerInfoAux(param)(dispatch),
    toggleFillGenericPopup: (param) => toggleFillGenericPopup(param)(dispatch),
    updateMarkerJourney: (param) => dispatch(_updateMarkerJourney(param)),
    setJourneyReportPanelInitialStatus: (status) =>
      dispatch(setJourneyReportPanelInitialStatus(status)),
  };
};

export default connect(
  _mapStateToProps,
  _mapDispatchToProps
)(JourneyReportPanel);
