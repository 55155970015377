import gql from "graphql-tag";

const CUSTOMERS_QUERY = gql`
  query Customers(
    $pageSize: Int
    $pageNumber: Int
    $sort: SortInput
    $filter: CustomerManyInput
    $paginate: Boolean
  ) {
    customers(
      pageSize: $pageSize
      pageNumber: $pageNumber
      sort: $sort
      filter: $filter
      paginate: $paginate
    ) {
      count
      items {
        id
        name
        taxId
        phone
        celPhone
        address
        email
        zoom
        dateSuspension
        automaticMailAlarm
      }
    }
  }
`;

export default CUSTOMERS_QUERY;
