import gql from "graphql-tag";

const ECOTRACKING_REPORT_QUERY = gql`
  query EcoTrackingReport(
    $assetsSelected: [Int]!
    $fechaInicio: Datetime
    $fechaFin: Datetime
    $days: [WeekDaysEnum]
  ) {
    ecoTrackingReport(
      assetsSelected: $assetsSelected
      fechaInicio: $fechaInicio
      fechaFin: $fechaFin
      days: $days
    ) {
      mid
      nMov
      pat
      iTime
      fTime
      kmsRec
      consumptionRangeOne
      consumptionRangeTwo
      consumptionRangeThree
      consumptionIdle
      cO2Emissions
      totalLiters
      co2NoxEmission
    }
  }
`;

export default ECOTRACKING_REPORT_QUERY;
