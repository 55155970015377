import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import frontendReducer from "./frontend/reducer";
import notificationsReducer from "./notifications/reducer";
import userReducer from "./user/reducer";
import markersReducer from "./markers/reducer";
import fillMarkerInfoReducer from "./fillMarkerInfo/reducer";
import userLocationReducer from "./userLocation/reducer";
import fillMarkerJourney from "./fillMarkerJourney/reducer";
import popupInfo from "./genericPopup/reducer";
import lateralPanelReducer from "./lateralPanel/reducer";
import clientReducer from "./manage/clients/reducer";

import loadingReducer from "./loading/reducer";
import groupsReducer from "./groups/reducer";
import baseReducer from "./base/reducer";

/**
 * If you have changed anything in the configureInitialState
 * you should have a reducer corresponding to that change
 */
export default combineReducers({
  env: (state = []) => state,
  urlParams: (state = []) => state,
  frontend: frontendReducer,
  notifications: notificationsReducer,
  userLocation: userLocationReducer,
  user: userReducer,
  markers: markersReducer,

  markerInfo: fillMarkerInfoReducer,
  markerJourney: fillMarkerJourney,
  popupInfo: popupInfo,

  lateralPanel: lateralPanelReducer,
  form: formReducer,

  clientReducer,
  loading: loadingReducer,
  groups: groupsReducer,
  base: baseReducer,
});
