import PropTypes from "prop-types";
import React, { Component } from "react";
import { Route } from "react-router-dom";
import ConnectedBaseMap from "../containers/ConnectedBaseMap";
import ConnectedMarkerInfo from "../containers/MarkerInfo/ConnectedMarkerInfo";
import MapBasePage from "../pages/Map/Base";
import PrivateRoute from "./PrivateRoute";

export default class MapRoutes extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  static defaultProps = {
    match: {},
  };

  render() {
    const {
      match: { path },
    } = this.props;

    return (
      <>
        <Route
          path={`${path}`}
          exact
          component={(routerProps) => (
            <PrivateRoute component={MapBasePage} {...routerProps} />
          )}
        />

        <Route
          path={`${path}/:markerId`}
          exact
          component={(routerProps) => (
            <PrivateRoute
              component={MapBasePage}
              {...routerProps}
              lateralBar={ConnectedMarkerInfo}
            />
          )}
        />

        <Route
          path={`${path}/users`}
          exact
          component={(routerProps) => <MapBasePage {...routerProps} />}
        />

        <ConnectedBaseMap />
      </>
    );
  }
}
