import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
const AccountIcon = function () {
  return (
    <SvgIcon>
      <path
        id="Trazado_427"
        data-name="Trazado 427"
        d="M11,0A11,11,0,1,1,0,11,11,11,0,0,1,11,0Z"
        fill="#348500"
      />
      <g id="user" transform="translate(4 3)">
        <path
          id="user-2"
          data-name="user"
          d="M6.563,7.5a3.75,3.75,0,1,0-3.75-3.75A3.75,3.75,0,0,0,6.563,7.5Zm2.625.938H8.7a5.1,5.1,0,0,1-4.271,0H3.938A3.939,3.939,0,0,0,0,12.375v1.219A1.407,1.407,0,0,0,1.406,15H11.719a1.407,1.407,0,0,0,1.406-1.406V12.375A3.939,3.939,0,0,0,9.188,8.438Z"
          transform="translate(0)"
          fill="rgba(255,255,255,0.78)"
        />
      </g>
    </SvgIcon>
  );
};

export default AccountIcon;
