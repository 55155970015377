import {
  handleSubmitClientStarted,
  handleSubmitClientSuccess,
  handleSubmitClientError,
} from "./actions";

import { authHeader } from "../../../utils/authentication";

// const BASE_URL = 'https://jsonplaceholder.typicode.com';
// const BASE_URL = 'http://localhost:3500/points';
const BASE_URL = "http://52.152.96.229/points";

export const handleSubmitClientForm = () => (dispatch, getState) => {
  dispatch(handleSubmitClientStarted());

  const { form: { ClientForm: { values } = {} } = {} } = getState();

  fetch("https://mywebsite.com/endpoint/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(values),
  })
    .then((response) => dispatch(handleSubmitClientSuccess(response)))
    .catch((error) => {
      dispatch(handleSubmitClientError(error));
    });
};

export const insertClientApi = (client) =>
  fetch(`${BASE_URL}/clients`, {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(client),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });

export const updateClientApi = (client) =>
  fetch(`${BASE_URL}/clients`, {
    method: "PATCH",
    headers: authHeader(),
    body: JSON.stringify(client),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });

export const deleteClientApi = (id) =>
  fetch(`${BASE_URL}/clients?_id=${id}`, {
    method: "DELETE",
    headers: authHeader(),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });

export const getClientsApi = () =>
  fetch(`${BASE_URL}/clients?page=1&limit=9999`, {
    method: "GET",
    headers: authHeader(),
  })
    .then((response) => {
      if (response.status !== 200) {
        // Will jump to the catch skipping all .then
        throw new Error("Error al intentar obtener clientes");
      } else {
        return response.json();
      }
    })
    .catch((error) => {
      throw error;
    });
