import React, { useState, useEffect } from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  FormLabel,
  TextField,
} from "@material-ui/core";

import { useTranslation } from "../../../lang";

const ModalGroup = ({
  title,
  initialValue,
  handleAction,
  isOpen,
  closeModal,
}) => {
  const [t] = useTranslation();
  const [name, setName] = useState();

  useEffect(() => {
    if (initialValue) setName(initialValue);
  }, [initialValue]);
  return (
    <Dialog open={isOpen} onClose={closeModal} maxWidth="sm" fullWidth>
      <DialogTitle className="dialogTitle">{title}</DialogTitle>
      <DialogContent>
        <FormControl component="fieldset" fullWidth>
          <FormLabel component="legend" style={{ color: "white" }}>
            {t("Nombre")}
          </FormLabel>
          <TextField
            variant="standard"
            value={name}
            onChange={(evt) => setName(evt.target.value)}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          color="primary"
          variant="contained"
          onClick={() => closeModal()}
        >
          {t("Cancelar")}
        </Button>
        <Button
          type="button"
          color="primary"
          variant="contained"
          onClick={() => handleAction(name)}
        >
          {t("Guardar")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalGroup;
