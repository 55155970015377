import gql from "graphql-tag";

const DELETE_CUSTOMER_MUTATION = gql`
  mutation DeleteCustomer($customerId: Int!) {
    deleteCustomer(customerId: $customerId) {
      id
    }
  }
`;

export default DELETE_CUSTOMER_MUTATION;
