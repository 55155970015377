import { connect } from "react-redux";

import ExpandablePanel from "../../components/Base/ExpandablePanel";

import {
  toggleActivePanel,
  toggleActivePanelAux,
} from "../../redux/lateralPanel/actions";

const _mapStateToProps = ({ lateralPanel }) => ({
  isOpen: lateralPanel.isPanelOpen,
  isPanelAuxOpen: lateralPanel.isPanelAuxOpen,
  panelType: lateralPanel.panelType,
});

const _mapDispatchToProps = {
  handleClickClosePanel: toggleActivePanel,
  toggleActivePanelAux,
};

export default connect(_mapStateToProps, _mapDispatchToProps)(ExpandablePanel);
