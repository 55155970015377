import { IconButton } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MiniMap from "../../../components/Base/MiniMap";
import Table from "../../../components/Base/Table";
import { useTranslation } from "../../../lang";
import { GetDateFormat } from "../../../utils/generalUtils";
import VelocityChart from "./VelocityChart";

const paginationSizes = [10, 25, 50];
const ReportResult = ({ reportData, user }) => {
  const [t] = useTranslation();
  const [detalle, setDetalle] = useState();
  const [rowSelected, setRowSelected] = useState(0);
  const [miniMapData, setMiniMapData] = useState({
    lat: 0,
    lon: 0,
    title: "",
    tooltipText: "",
  });

  const [isMiniMapOpen, setIsMiniMapOpen] = useState(false);
  const closeMiniMap = () => setIsMiniMapOpen(false);

  useEffect(() => {
    setDetalle(
      reportData[0].details.map((d) => ({
        velMaxConfig: reportData[0].velMaxMovil,
        assetName: reportData[0].assetName,
        licensePlate: reportData[0].licensePlate,
        ...d,
      }))
    );
  }, [reportData]);

  const customTotal = (from, to, size) => (
    <span
      className="react-bootstrap-table-pagination-total customTotalDisplay"
      style={{ marginLeft: "15px" }}
    >
      {t("pages_manage_statistics_velocityreport_mostrar_resultados", {
        from,
        to,
        size,
      })}
    </span>
  );

  const options = {
    // Tooltips botones de paginación
    prePageTitle: t("Página previa"),
    nextPageTitle: t("Próxima página"),
    firstPageTitle: t("Primera página"),
    lastPageTitle: t("Última página"),
    showTotal: true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: paginationSizes,
  };

  const summaryRowEvents = {
    onClick: (e, row, rowIndex) => {
      const assetData = reportData.find((rd) => rd.mid === row.mid);
      setDetalle(
        assetData.details.map((d) => ({
          velMaxConfig: assetData.velMaxMovil,
          assetName: assetData.assetName,
          licensePlate: assetData.licensePlate,
          ...d,
        }))
      );
      setRowSelected(rowIndex);
    },
  };

  const handleOpenMiniMap = (row) => {
    setMiniMapData({
      lat: row.lat,
      lon: row.lon,
      title: t("pages_manage_statistics_velocityreport_velocidad", {
        vel: Math.round(row.vel),
        assetName: row.assetName,
      }),
      tooltipText: row.assetName,
    });
    setIsMiniMapOpen(true);
  };

  //movil place velocidad maxima velocidad maxima movil
  const columnsSummary = [
    {
      dataField: "assetName",
      text: t("Móvil"),
      sort: true,
    },
    {
      dataField: "licensePlate",
      text: t("Placa"),
      sort: true,
    },
    {
      dataField: "velMax",
      text: `${t("Velocidad Maxima")} [${t("km/h")}]`,
      sort: true,
      formatter: (_, row) => {
        return Math.round(row.velMax);
      },
    },
    {
      dataField: "velMaxMovil",
      text: `${t("Velocidad Máxima Movil")} [${t("km/h")}]`,
      sort: true,
      formatter: (_, row) => {
        return Math.round(row.velMaxMovil);
      },
    },
  ];

  const columnsDetail = [
    {
      dataField: "assetName",
      text: t("Móvil"),
      sort: true,
      headerStyle: () => ({ width: "20%", fontSize: "12px" }),
    },
    {
      dataField: "licensePlate",
      text: t("Placa"),
      sort: true,
      headerStyle: () => ({ width: "15%", fontSize: "12px" }),
    },
    {
      dataField: "fecha",
      text: t("Fecha"),
      formatter: (_, row) => {
        return format(new Date(row.fecha), GetDateFormat(user.language));
      },
      headerStyle: () => ({ width: "15%", fontSize: "12px" }),
      sort: true,
    },
    {
      dataField: "ubication",
      text: t("Ubicación"),
      sort: true,
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
    },
    {
      dataField: "vel",
      text: `${t("Velocidad")} [${t("km/h")}]`,
      sort: true,
      headerStyle: () => ({ width: "15%", fontSize: "12px" }),
      formatter: (_, row) => {
        return Math.round(row.vel);
      },
    },
    {
      dataField: "id",
      text: t("Detalles"),
      headerStyle: () => ({
        width: "10%",
        fontSize: "12px",
        textAlign: "center",
      }),
      formatter: (cell, row) => {
        return (
          <IconButton
            style={{ padding: "0" }}
            onClick={() => handleOpenMiniMap(row)}
          >
            <SearchIcon className="SearchIconColor" />
          </IconButton>
        );
      },
    },
  ];

  const rowSummaryStyle = (row, rowIndex) => {
    const style = {};
    if (rowIndex === rowSelected) {
      // style.background = "#1c3663";
      style.background = "lightgrey";
    }

    return style;
  };

  return (
    <div>
      <MiniMap
        isOpen={isMiniMapOpen}
        closeModal={closeMiniMap}
        {...miniMapData}
      />
      <div className="resultPart">
        <Table
          columns={columnsSummary}
          data={reportData}
          keyField="mid"
          paginationOptions={options}
          rowEvents={summaryRowEvents}
          rowStyle={rowSummaryStyle}
        />
      </div>
      <div className="resultPart">
        {detalle && <VelocityChart data={detalle} language={user.language} />}
      </div>
      <div className="resultPart">
        <Table
          columns={columnsDetail}
          data={detalle}
          keyField="id"
          paginationOptions={options}
          tableNumber={1}
        />
      </div>
    </div>
  );
};

const _mapStateToProps = ({ user }) => {
  return {
    user,
  };
};

const _mapDispatchToProps = (dispatch) => {
  return {
    // _setCurrentUser: (param) => dispatch(setCurrentUser(param)),
  };
};

export default connect(_mapStateToProps, _mapDispatchToProps)(ReportResult);
