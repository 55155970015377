import React from "react";
import DateWithTimeRangeSelector, {
  DATE_FORMATS,
} from "./DateWithTimeRangeSelector";

const getDateFormatByUserLanguage = (userLanguage) => {
  const langs = {
    ES: DATE_FORMATS.DD_MM_YYYY,
    US: DATE_FORMATS.MM_DD_YYYY,
    ES_US: DATE_FORMATS.MM_DD_YYYY,
  };

  return langs[userLanguage] || DATE_FORMATS.YYYY_MM_DD;
};

const DateWithTimeRangeSelectorFormatedByUserLanguage = ({ user, ...rest }) => {
  return (
    <DateWithTimeRangeSelector
      {...rest}
      dateFormat={getDateFormatByUserLanguage(user.language)}
    />
  );
};

export default DateWithTimeRangeSelectorFormatedByUserLanguage;
