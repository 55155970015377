import gql from "graphql-tag";

const DELETE_LINE_MUTATION = gql`
  mutation DeleteLine($id: Int!) {
    deleteLine(id: $id) {
      id
    }
  }
`;

export default DELETE_LINE_MUTATION;
