import { Grid, IconButton } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { format } from "date-fns";
import React, { useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "../../lang";
import IniEndIcon from "../../public/icons/IniEndIcon";
import { GetDateFormat, secondsToTime } from "../../utils/generalUtils";
import ModalJourneyPosition from "./ModalJourneyPosition";

const JourneyDetail = ({
  journey,
  setPositionArguments,
  movilID,
  positions,
  user,
}) => {
  const [t] = useTranslation();
  const thisPositions = positions
    ? positions.filter(
        (x) => x.fGPS >= journey.iTime && x.fGPS <= journey.fTime
      )
    : [];

  const [modalOpen, setModalOpen] = useState(false);

  // const dateFormat = "dd-MM-yyyy HH:mm:ss";
  // const durationFormat = "dd:HH:mm:ss";

  const horaInicioFormateada = format(
    new Date(journey.iTime),
    GetDateFormat(user.language)
  );
  const fechaHoraFinFormateada = format(
    new Date(journey.fTime),
    GetDateFormat(user.language)
  );
  const tiempoRalenti = secondsToTime(journey.tRal);

  const handleSelectJourney = () => {
    if (!Array.isArray(thisPositions) || thisPositions.length === 0) {
      setPositionArguments({
        movilID: movilID,
        fechaInicio: journey.iTime,
        fechaFin: journey.fTime,
      });
    }
  };

  return (
    <>
      <ModalJourneyPosition
        isOpen={modalOpen}
        closeModal={() => setModalOpen(false)}
        data={thisPositions}
        movilID={movilID}
        fechaInicio={journey.iTime}
        fechaFin={journey.fTime}
      />
      <Grid
        container
        className="journeyContainer"
        onClick={handleSelectJourney}
        style={{ cursor: "pointer" }}
      >
        <Grid item xs={1} style={{ paddingLeft: "10px", cursor: "pointer" }}>
          <IniEndIcon />
        </Grid>
        <Grid
          item
          xs={10}
          onClick={handleSelectJourney}
          style={{ cursor: "pointer" }}
        >
          <label
            className="labelJourney"
            onClick={handleSelectJourney}
            style={{ cursor: "pointer" }}
          >{`${journey.uIni} - ${horaInicioFormateada}`}</label>
          <br />
          <label
            className="labelJourney"
            onClick={handleSelectJourney}
            style={{ cursor: "pointer" }}
          >{`${journey.uFin} - ${fechaHoraFinFormateada}`}</label>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            onClick={() => {
              setModalOpen(true);
              if (!Array.isArray(thisPositions) || thisPositions.length === 0) {
                setPositionArguments({
                  movilID: movilID,
                  fechaInicio: journey.iTime,
                  fechaFin: journey.fTime,
                });
              }
            }}
          >
            <SearchIcon className="SearchIconColor" />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <table className="tblJourneyDetail">
            <tbody>
              <tr>
                <td style={{ borderRight: "1px solid #ffffff60" }}>
                  <label className="labelJourney jrMarginLeft">
                    {t("Tiempo Recorrido")}
                  </label>
                  <label className="labelJourney jrMarginLeft">
                    {secondsToTime(journey.tIgn)}
                  </label>
                </td>
                <td>
                  <label className="labelJourney jrMarginLeft">
                    {t("Kilometraje")}:
                  </label>
                  <label className="labelJourney jrMarginLeft">
                    {`${journey.odo} ${t("km")}`}
                  </label>
                </td>
              </tr>
              <tr>
                <td style={{ borderRight: "1px solid #ffffff60" }}>
                  <label className="labelJourney jrMarginLeft">
                    {t("Kms Recorridos")}
                  </label>
                  <label className="labelJourney jrMarginLeft">
                    {`${journey.kmsRec.toFixed(2)} ${t("km")}`}
                  </label>
                </td>
                <td>
                  <label className="labelJourney jrMarginLeft">
                    {t("Horómetro")}
                  </label>
                  <label className="labelJourney jrMarginLeft">
                    {secondsToTime(journey.horo)}
                  </label>
                </td>
              </tr>
              <tr>
                <td style={{ borderRight: "1px solid #ffffff60" }}>
                  <label className="labelJourney jrMarginLeft">
                    {t("VelMax")}:
                  </label>
                  <label className="labelJourney jrMarginLeft">
                    {Math.round(journey.velMax)} {t("kmh")}
                  </label>
                </td>
                <td>
                  <label className="labelJourney jrMarginLeft">
                    {t("Tiempo ralenti")}
                  </label>
                  <label className="labelJourney jrMarginLeft">
                    {t(tiempoRalenti)}
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
      </Grid>
    </>
  );
};

const _mapStateToProps = ({ user }) => {
  return {
    user,
  };
};

const _mapDispatchToProps = (dispatch) => {
  return {
    // _setCurrentUser: (param) => dispatch(setCurrentUser(param)),
  };
};

export default connect(_mapStateToProps, _mapDispatchToProps)(JourneyDetail);
