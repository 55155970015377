import React, { useEffect, useState } from "react";

import { Button, Dialog, DialogContent, DialogTitle } from "@material-ui/core";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import { useLazyQuery } from "@apollo/client";
import GET_LAST_HISTORY_POINT_OBD2_BY_ID_QUERY from "../../graphql/queries/getLastHistoryPointOBD2ByIdQuery";
import { useTranslation } from "../../lang";
import OBD2InformationDisplay from "./OBD2InformationDisplay";

import { format } from "date-fns";
import "./style.scss";

const UNKNOWN_VALUE = "N/A";

const KEY_TO_TITLE_MAP = {
  nDTC: "obd2_modal_window_ndtc",
  cELV: "obd2_modal_window_celv",
  eCT: "obd2_modal_window_ect",
  fP: "obd2_modal_window_fp",
  fT: "obd2_modal_window_ft",
  iMAP: "obd2_modal_window_imap",
  rpm: "obd2_modal_window_rpm",
  speed: "obd2_modal_window_speed",
  tA: "obd2_modal_window_ta",
  iAT: "obd2_modal_window_iat",
  tP: "obd2_modal_window_tp",
  tIgn: "obd2_modal_window_tign",
  dTMIL: "obd2_modal_window_dtmil",
  panic: "obd2_modal_window_panic",
  ignition: "obd2_modal_window_ignition",
  movement: "obd2_modal_window_movement",
  bI: "obd2_modal_window_bi",
  aC: "obd2_modal_window_ac",
  gsm: "obd2_modal_window_gsm",
  velocidad: "obd2_modal_window_velocidad",
  kilometrosTotales: "OdometroMi",
  coordenadas: "obd2_modal_window_coordenadas",
  fGPS: "obd2_modal_window_fgps",
};

const formatDate = (date) => {
  return format(new Date(date), "dd/MM/yyyy HH:mm:ss");
};

const useMapKeyToTitle = () => {
  const [t] = useTranslation();
  const mapKeyToTitle = (key) => t(KEY_TO_TITLE_MAP[key]) || t(key);
  return [mapKeyToTitle];
};

const theme = createMuiTheme({});

const OBD2ModalWindow = ({ isOpen, closeModal, markerInfo }) => {
  const [mapKeyToTitle] = useMapKeyToTitle();
  const [t] = useTranslation();
  const [data, setData] = useState({
    summary: [],
    items: [],
  });

  const variables = {
    eid: markerInfo.eid,
    evt: markerInfo.evt,
    fGPS: markerInfo.fGPSd,
  };

  const [getData] = useLazyQuery(GET_LAST_HISTORY_POINT_OBD2_BY_ID_QUERY, {
    variables,
    fetchPolicy: "no-cache",
    onCompleted: (d) => {
      const items = Object.entries(
        d.getLastHistoryPointOBD2ById.historyPointOBD2
      ).map(([key, value]) => {
        return {
          title: mapKeyToTitle(key),
          value: value || UNKNOWN_VALUE,
          id: key,
        };
      });
      d.getLastHistoryPointOBD2ById.summary.kilometrosTotales = markerInfo.odo;
      d.getLastHistoryPointOBD2ById.summary.coordenadas = `${markerInfo.pos.lat} ${markerInfo.pos.lng}`;
      const rawSummary_fGPS = (d.getLastHistoryPointOBD2ById.summary.fGPS = d
        .getLastHistoryPointOBD2ById.summary.fGPS
        ? formatDate(d.getLastHistoryPointOBD2ById.summary.fGPS)
        : formatDate("2021-10-06T08:54:07.000Z"));
      d.getLastHistoryPointOBD2ById.summary.fGPS = rawSummary_fGPS;

      const summary = Object.entries(d.getLastHistoryPointOBD2ById.summary).map(
        ([key, value]) => {
          return {
            title: mapKeyToTitle(key),
            value: value || UNKNOWN_VALUE,
            id: key,
          };
        }
      );

      setData({
        summary,
        items,
      });
    },
    onError: (error) => {
      console.log("onError get data obd2");
      setData({
        summary: [],
        items: [],
      });
      console.log("Error:", error);
    },
  });

  useEffect(() => {
    if (isOpen) {
      getData();
    }
  }, [isOpen]);
  return (
    <>
      <ThemeProvider theme={theme}>
        <Dialog open={isOpen} onClose={closeModal} maxWidth="md" fullWidth>
          <DialogTitle className="dialogTitle">
            {t("OBD2-CANBUS")}

            <Button onClick={closeModal} className="closeWindow">
              x
            </Button>
          </DialogTitle>
          <DialogContent>
            <OBD2InformationDisplay data={data} />
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

export default OBD2ModalWindow;
