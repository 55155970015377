// @ts-check
export const resources = {
  translation: {
    "Satellital Patrol": "Satellital Patrol",

    //=== GRAPHQL ===//
    //****** JourneyGroupEnum *****//
    DIARIO: "DAILY",
    SEMANAL: "WEEKLY",
    QUINCENAL: "BIWEEKLY",
    MENSUAL: "MONTHLY",
    IGNITION: "IGNITION",
    PARADAS: "STOPS",

    //****** src > enums *****//
    Funciones: "Functions",
    Vehículos: "Buses",
    Direcciones: "Addresses",

    //****** src > enums > functions *****//
    "Asignar N° Guía": "Assign Tracking Number",
    Clientes: "Clients",
    Conductores: "Drivers",
    Equipos: "Devices",
    Geocercas: "Geofences",
    Grupos: "Groups",
    Líneas: "Phone Lines",
    Móviles: "Buses",
    Planificador: "Planner",
    "Puntos de interés": "Points of Interest",
    Roles: "Roles",
    Rutas: "Routes",
    Técnicos: "Technicians",
    Usuarios: "Users",
    "Reporte de Consolidados": "Consolidated Report",
    "Reporte de Detenciones": "Stop Report",
    "Reporte de Eco Tracking": "Eco Tracking Report",
    "Reporte de Eventos": "Events Report",
    "Reporte de Geoescalas": "Geofences Report",
    "Reporte de Kilometraje de Flota": "Fleet Mileage Tracking Report",
    "Reporte de OBD2": "OBD2 Report",
    "Reporte de Tablero Seguimiento": "Tracking Dashboard Report",
    "Reporte de Velocidades": "Speeds Report",
    "Desvios de Ruta": "Route detours",
    Mapa: "Map",

    //=== routes ===//
    //****** PublicOnlyRoute *****//
    "Ya has ingresado al sistema": "You have already logged into the system",

    //=== redux > user ===//
    //****** actions *****//
    "Has ingresado satisfactoriamente": "You are logged in",
    "Sesion finalizada con exito": "Session Expired",
    // "Has ingresado satisfactoriamente": "Has ingresado satisfactoriamente",

    //=== Components > AdministrativePanel ===//
    //****** index *****//
    // "Asignar N° Guía": "Assign tracking number",
    // Clientes: "Clients",
    // Conductores: "Drivers",
    // Equipos: "Devices",
    // Geocercas: "Geofences",
    // Grupos: "Groups",
    // Líneas: "Phone lines",
    // Móviles: "Assets",
    // Planificador: "Planner",
    // "Puntos de interés": "Points of interest",
    // Roles: "Roles",
    // Rutas: "Routes",
    // Técnicos: "Technicians",
    // Usuarios: "Users",
    "Seguimiento Múltiple": "Multiple Tracking",

    //=== Components > Base ===//

    //****** apiApolloClient *****//

    //****** apiApolloClientSubscription *****//

    //****** CustomFormikSelect *****//
    Seleccionar_: "Select...",
    "Seleccionar Período": "Select Date Period",

    //****** DrawableMap *****//
    "Ver posiciones": "See locations",
    "Ver puntos de interes": "See points of interest",

    //****** ExpandablePanel *****//
    Notificaciones: "Notifications",

    //****** ExpansionPanel *****//

    //****** FlexRow *****//

    //****** ImageUpload *****//

    //****** Loading *****//

    //****** MainContainer *****//

    //****** ManageNavbar *****//
    // Clientes: "Clientes",
    // Mapa: "Map",

    //****** MiniMap *****//
    Cerrar: "Close",
    //****** Modal *****//

    //****** ModalConfirmacion *****//
    Cancelar: "Cancel",

    //****** ModalPoi *****//
    Nombre: "Name",
    "Seleccione Puntos de interes": "Select points of interest",
    "Puntos de Interes": "Point of interest",
    // Cancelar: "Cancelar",
    Guardar: "Save",

    //****** Navbar *****//

    //****** NavbarTop *****//
    "Buscar móviles, funciones, geocercas":
      "Search buses, functions, geofences...",
    "Ir a Seguimiento Múltiple": "Go to Multiple Tracking",

    //****** Notification *****//
    "Ha notificado un evento": "Has reported an event",

    //****** PanelForm *****//
    // Guardar: "Guardar",

    //****** PeriodSelectorContainer *****//

    "Ultima hora": "Last hour",
    "Ultima 6 horas": "Last 6 hours",
    Ayer: "Yesterday",
    Hoy: "Today",
    "Semana Pasada": "Last week",
    "Mes Pasado": "Last month",

    //****** RemoteTable *****//
    base_remote_table_mostrar_resultados:
      "{{from}} to {{to}} of {{size}} Results",
    Buscar: "Search",

    //****** Structure *****//

    //****** StyledSelect *****//

    //****** Table *****//
    base_table_show_results: "{{from}} to {{to}} of {{size}} Results",
    base_table_mostrar_resultados: "{{from}} to {{to}} of {{size}} Results",
    //****** TablePanelContent *****//

    //****** ThreeDotMenu *****//

    // navbar.js
    "Mapa Principal": "Main Map",
    "Estado de Flota": "Fleet status",
    "Reporte de Recorridos": "Route Reports",
    "Conductas de Manejo": "Driving Behavior",
    Alarmas: "Alerts",
    Mantenimiento: "Maintenance",
    Administración: "Management",
    Estadísticas: "Statistics",

    // revisions.js
    Seleccionar: "Select",
    "Revisión técnica": "Technical Review",
    Frenos: "Brakes",
    Llantas: "Tyres",
    Batería: "Battery",
    "Cambio de aceite": "Oil change",
    "Tren delantero": "Front axle",
    "Correa de transmisión": "Transmission belt",
    Electricidad: "Electricity",
    "¿Está seguro que desea eliminar la revisión?":
      "Are you sure you wish to delete this check?",

    //**** Type of checks ****//
    "Aceite Motor": "Motor Oil",
    Bateria: "Battery",
    Chapista: "Auto bodyworker",
    "Correa y Distribución": "Timing Belts",
    Cristales: "Glasses",
    Cubiertas: "Wheels",
    "Filtro de Aceite": "Oil Filter",
    "Filtro de Aire": "Air Filter",
    "Filtro de Nafta": "Fuel Filter",
    Neumaticos: "Tires",
    "Revision General": "General Check",
    "Revisión Tecnica": "Technical Check",

    //**** Frequencies *****/
    "1Mes": "1 month",
    "2Meses": "2 months",
    "3Meses": "3 months",
    "12Meses": "12 months",
    "6Meses": "6 months",
    "5Años": "5 years",
    Kms: "Miles",
    Meses: "months",

    //=== Components > CargoTracking ===//
    //****** Form *****//
    "Seguimiento de Cargas": "Tracking",
    "Número Guía": "Tracking Number",
    "Buscar Número de Guía": "Search Tracking Number",

    //****** Map *****//

    //****** Page *****//
    "No se encontró el número de guia": "Tracking number not found",
    "Ocurrió un error": "An error has occurred",
    "Ingrese el número de guia": "Enter your tracking number",

    //=== Components > ChangePasswordModal ===//
    //****** Form *****//
    "Usuario actualizado correctamente": "User update correctly",
    error: "error",
    Requerido: "Required",
    "Debe tener 6 o más caracteres":
      "Your password must have at least 6 characters",
    "Confirmación Nueva Contraseña": "Confirmation of the new password",
    // Cancelar: "Cancelar",
    "Nueva Contraseña": "New Password",
    "Contraseña Actual": "Current Password",
    // Guardar: "Guardar",

    //****** index *****//
    "Cambiar informacion de perfil": "Change profile information",
    "Cambiar Contraseña": "Change Password",

    //=== Components > DrivingBehaviorReport ===//
    //****** AssetTable *****//
    // Nombre: "Nombre",
    Puntaje: "Score",
    "Km Rec": "Miles trav.",
    Conducción: "Drive",
    Combustible: "Fuel",
    Eficiencia: "Efficiency",

    //****** ChartsPanel *****//
    "Promedio Flota": "Fleet Average",
    "Conductor seleccionado": "Selected driver",

    //****** ConductionTimeChart *****//
    "Tiempo de Conducción": "Driving time",

    //****** ConsumeEficiencyChart *****//
    "Eficiencia de Consumo": "Fuel Economy",

    //****** DriverTable *****//
    Promedio: "Average",
    Total: "Total",
    // Nombre: "Nombre",
    // "Km Rec": "Km Rec.",
    // Conducción: "Conducción",
    // Combustible: "Combustible",
    // Eficiencia: "Eficiencia",

    //****** ExportMenu *****//

    //****** Filter *****//
    LUNES: "MONDAY",
    MARTES: "TUESDAY",
    MIERCOLES: "WEDNESDAY",
    JUEVES: "THURSDAY",
    VIERNES: "FRIDAY",
    SABADO: "SATURDAY",
    DOMINGO: "SUNDAY",
    // Nombre: "Nombre",
    Patente: "Plate",
    Interno: "Internal Number",
    Chofer: "Driver",
    Tipo: "Type",
    // "Nombre": "Nombre", // Duplicated
    Apellido: "Last Name",

    //****** FuelConsumedChart *****//
    "Combustible Consumido": "Spent fuel",

    //****** Index *****//
    // Conductores: "Conductores",
    // Vehículos: "Vehicles",

    //****** InfractionsChart *****//

    //****** KmsRecChart *****//
    "Kilómetros Recorridos": "Miles Traveled",

    //****** ScoreChart *****//
    "Puntaje Total": "Total Score",
    "Puntaje Flota": "Fleet Score",
    "Kms Recorridos Flota": "Miles Traveled (Fleet)",
    "Tiempo de conducción Flota": "Driving time (Fleet)",
    "Tiempo de conduccion": "Driving time",
    "Combustible consumido": "Spent fuel",
    "Combustible consumido Flota": "Spent fuel (Fleet)",
    "Eficiencia Flota": "Fleet Efficiency",

    //****** ScoreGaugeChart *****//

    //=== Components > EstadoFlotaPanel ===//
    //****** AssetState *****//

    //****** FlotaItem *****//
    components_estadoflotapanel_floatitem_point_vel: "{{pointVel}} mph",

    //****** FlotaState *****//
    "Total Flota": "Total Fleet",
    "Ignition ON": "Ignition ON",
    "Ignition OFF - En movimiento": "Ignition OFF - In motion",
    "Ignition OFF - Detenido": "Ignition OFF - Stopped",
    "Sin configuración de ignición": "Without ignition configuration ",
    "Sin transmisión por x minutos o más":
      "No transmission for {{minutos}} minutes or more",
    Persona: "People",

    //****** index *****//

    //=== Components > EstadoFlotaPanel > ExportPanel ===//
    //****** DownloadButtons *****//
    "Exportar PDF": "Export PDF",
    "Exportar Excel": "Export Excel",

    //****** FlotaStateExportPanel *****//
    ReporteEstadoFlota: "FleetStatusReport",
    "Error al intentar descargar archivo": "Error when trying to download file",
    error_al_intentar_guardar_el_archivo_descargado_formato_no_soportado:
      "Error when trying to save downloaded file. File format not supported.",
    //=== Components > JourneyReportPanel ===//
    //****** index *****//
    "Seleccione un movil": "Select an bus",
    // Nombre: "Nombre",
    // Patente: "Patente",
    // Chofer: "Chofer",
    // Tipo: "Tipo",
    // Móviles: "Móviles",
    "Agrupar por": "Group by...",
    //Buscar: "Search",
    EXCEL: "EXCEL",
    No_hay_resultados: "No results found",

    //****** JourneyAsset *****//

    //****** JourneyDetail *****//
    "Tiempo Recorrido:": "Journey Time:",
    "Km Recorridos:": "Miles Traveled:",
    "Horómetro:": "Hourmeter:",
    // "Tiempo Ralentí:": "Idle Time:",
    km: "miles",

    //****** JourneySummary *****//
    Móvil: "Bus",
    "Velocidad Máxima": "Maximum Speed",
    "Tiempo recorrido": "Journey Time",
    "Kilometros recorridos": "Miles Traveled",
    Kilometraje: "Mileage",
    "Tiempo ralenti": "Idle Time",

    //****** ModalJourneyPosition *****//
    "pagina previa": "Previous page",
    "próxima página": "Next page",
    "primer página": "First page",
    "última página": "Last page",
    Conductor: "Driver",
    Fecha: "Date",
    Evento: "Event",
    Latitud: "Latitude",
    Longitud: "Longitude",
    Dirección: "Address",
    Curso: "Orientation",
    Velocidad: "Speed",
    Posiciones: "Positions",
    // EXCEL: "EXCEL",
    PDF: "PDF",
    KML: "KML",
    // Cerrar: "Cerrar",
    modal_journey_position_mostrar_resultados:
      "Showing {{from}} from {{to}} to {{size}} Results",

    //=== Components > Login ===//
    //****** Login *****//

    //****** LoginForm *****//
    Ingresar: "Login",
    Usuario: "User",
    Contraseña: "Password",

    //=== Components > Map ===//
    //****** AssetsInAreaSearch *****//
    "Radio de busqueda": "Search radius",
    Distancia: "Distance",
    "Sin informacion disponible": "No information available",
    "Seleccionar movil": "Select bus",
    "Busqueda de moviles cercanos": "Search near buses",
    "%assetsCount% moviles en esta area de busqueda":
      "{{assetsCount}} buses in this area",
    "Quitar busqueda en area": "Remove search in area",
    "Mostrar busqueda en area": "Show search in area",

    //****** BaseLayersControl *****//

    //****** BaseMap *****//
    "Geocercas agregadas correctamente": "Geofences added properly",
    "El cliente seleccionado no tiene geocercas":
      "The selected client doesn't have geofences",
    "Se ocultaron las geocercas": "Geofences were hidden",
    "Quitar Dejar Rastro": "Remove Leave a trace",
    "Habilitar Dejar Rastro": "Enable Leave a trace",
    "Ocultar geocercas": "Hide Geofences",
    "Ver geocercas": "See Geofences",
    Remover: "Remove",
    "Quitar Tiempo": "Remove Weather",
    "Mostrar Tiempo": "Show Weather",
    "Deshabilitado Por favor, aumente el nivel de zoom":
      "Disabled - Please zoom in.",

    //****** BaseMapOld *****//

    //****** BaseMapV3 *****//

    //****** GenericPopup *****//
    // Velocidad: "Velocidad",
    Tiempo: "Time",
    Rumbo: "Heading",

    //****** MarkerPopup *****//

    //****** MarkersGroup *****//

    //****** MarkersJourneys *****//

    //****** NavigationPopup *****//
    "La unidad se agregó correctamente para seguimiento múltiple":
      "Unit was successfully added for multiple tracking.",
    "Ya existen 4 unidades en el seguimiento múltiple_ Por favor_ quite alguna para poder continuar agregando_":
      "There are already 4 units in multiple tracking. Please remove any to continue adding.",
    "Quitar de seguimiento múltiple": "Remove from multiple tracking",
    "La unidad se quitó correctamente del seguimiento múltiple":
      "The unit was successfully removed from multiple tracking.",
    "Agregar a seguimiento múltiple": "Add to multiple tracking",
    // Velocidad: "Velocidad",
    // Tiempo: "Tiempo",
    // Rumbo: "Rumbo",
    "Street View": "Street View",
    "Fecha GPS:": "GPS Date:",
    Fecha_GPS: "GPS Date",
    "Seguir en nueva ventana": "Continue in a new window",

    "On Time": "On Time",
    Early: "Early",
    Late: "Late",
    "Off Route": "Off Route",
    "Skipped Stops": "Skipped Stops",
    Unknown: "Unknown",
    "Ruta Activa": "Active Route",

    //****** NavigationRouting *****//

    //****** NavigationRoutingV3 *****//

    //****** react-leaflet-markercluster *****//

    //****** ShowStatus *****//

    //=== Components > Map > JourneyComponents  ===//
    //****** ChartContainer *****//
    // Velocidad: "Velocidad",
    // Kilometraje: "Kilometraje",
    "Dirección:": "Address:",
    "Fecha:": "Date:",
    "km/h": "mph",
    kmh: "mph",

    //****** CustomTrackPlayer *****//

    //****** FuelChart *****//

    //****** KilometrajeChart *****//

    //****** VelocityChart *****//

    //=== Components > Map > MarkerInfo  ===//
    //****** DaySteps *****//

    //****** FuelRefill *****//
    Combustibles: "Fuels",

    //****** GeneralInfo *****//
    // "Street View": "Street View",
    "Copiar Lat/Lon": "Copy Lat/Lon",
    Orientación: "Heading",
    // Velocidad: "Velocidad",
    Odómetro: "Odometer",
    "Odómetro software": "Odometer software",
    Horómetro: "Hourmeter",
    // Combustible: "Combustible",
    "Última transmisión": "Last transmission",
    userLanguage: "US",
    "Tiempo detención": "Detention time",
    "Ver detalles": "View details",
    "Copiado al portapapeles!": "Copied to the clipboard!",
    VelMax: "Max Speed",
    "Voltaje Externo": "External Voltage",
    "Voltaje Batería": "Battery Voltage",
    "GNSS Stat": "GNSS Status",

    //****** GeneralInfoExtra *****//
    "Información general": "General information",
    "Nombre vehículo": "Vehicle name",
    "Num Interno": "Internal number",
    Cliente: "Client",
    "Nombre del conductor": "Driver's name",
    "Datos del Equipo": "Device data",
    IMEI: "IMEI",
    "Tipo de Equipo": "Type of device",
    Modelo: "Model",
    "Nro de Serie": "Serial number",
    "Nro de Teléfono": "Phone number",
    "Versión del script": "Script version",
    "Fecha de Instalación": "Installation date",
    "Fecha de Alta Línea": "Line Activation Date",

    //****** InfoContent *****//

    //****** InfoContentExtra *****//

    //****** Journey *****//
    "Este marcador no posee información de viajes recientes":
      "This marker does not have recent travel information",
    "No se han encontrado resultados para el rango de busqueda establecido":
      "No results found for the specified search range.",
    "Ubicación inicial": "Initial location",
    "Ubicación final": "Final location",
    "Duración de trabajo": "Working hours",
    "Duración de la parada": "Breakdown duration",
    // Kilometraje: "Kilometraje",
    "Kilometraje (Cuenta kilómetros virtual)": "Mileage (Virtual odometer)",
    Recorridos: "Routes",

    //****** Notifications *****//
    Eventos: "Events",
    Alertas: "Alerts",
    "Notificacion enviada": "Notice sent",
    "Condiciones de inicio de evento": "Event start date conditions",
    Locaclización: "Location",
    "Equipo Origen": "Source Device",
    "Equipos de destino": "Target Device",
    //****** Revisions *****//
    // "Este marcador no posee revisiones": "Este marcador no posee revisiones",
    "Proximas revisiones": "Upcoming examinations",

    //****** RevisionsExtra *****//
    // Seleccionar: "Seleccionar",
    Correo: "Email",

    //****** StepList *****//
    Inicio: "Start",
    Parada: "Stop",
    "Duración del tramo": "Route time",
    "Distancia recorrida": "Distance traveled",
    // "Duración de la parada": "Duración de la parada",

    //****** SubHeader *****//

    //=== Components > Menu  ===//
    //****** CustomerSelect *****//
    // Clientes: "Clientes",

    //****** GroupRCTree *****//

    //****** GroupsButton *****//
    // Grupos: "Grupos",

    //****** GroupTree *****//

    //****** JourneyByCoordenates *****//

    //****** Menu *****//
    Principal: "Main",
    Messages: "Messages",

    //****** MenuItem *****//

    //****** NotificationsButton *****//
    "Seleccionar todo": "Select all",
    "Asentir Alarmas": "Confirm alerts",
    "VER TODOS": "SEE ALL",
    "Seleccionar Todas": "Select All",
    Sonido: "Sound",
    "No hay notificaciones": "No notifications.",

    //****** UsersButton *****//
    // "Cambiar Contraseña": "Cambiar Contraseña",
    "Seleccionar idioma": "Select language",
    "Cerrar sesión": "Log out",
    "User profile avatar": "User profile avatar",
    // Usuario: "Usuario", // Duplicated

    //****** GroupsTree *****//
    Buscar_: "Search...",

    //=== Components > MultipleTracking  ===//
    //****** MultipleTracking *****//
    "Aún no ha agregado ningún móvil al Seguimiento Múltiple":
      "You haven't yet added any bus to Multiple Tracking.",

    //=== Components > NewLateralBar  ===//
    //****** IconsContainer *****//

    //****** index *****//

    //****** Panel *****//
    // Notificaciones: "Notificaciones",
    // Mantenimiento: "Mantenimiento",
    // Administración: "Administración",
    // "Reporte de Recorridos": "Reporte de Recorridos",
    // "Estado de Flota": "Estado de Flota",
    // Estadísticas: "Estadísticas",
    // "Conductas de Manejo": "Conductas de Manejo",

    //****** PanelAux *****//

    //=== Components > NotificationPanel  ===//
    //****** Filter *****//
    "Filtrar notificaciones": "Filter notifications",
    // "Asentir Alarmas": "Asentir Alarmas",
    "Tipo de notificación": "Notification type...",
    "Tipo de Alarma": "Alert type...",
    "Seleccione Evento": "Select event...",
    "Borrar filtros": "Clear filters",
    Activado: "On",
    Desactivado: "Off",
    Hardware: "Hardware",
    Software: "Software",
    "Sin transmisión": "No transmission",
    Alarma: "Alert",
    Todas: "All",

    //****** index *****//
    "Get Support": "Get Support",
    //****** ListNotifications *****//
    Cargando: "Loading...",
    Fin: "End",
    "No se encontraron datos": "No data found",

    //****** NotificationItem *****//
    notification_item_posicion_alarma:
      "Alarm Position - {{source}} - Client: {{client}} - Event: {{event}}",
    Asentir: "Accept",
    "Ver en mapa": "View in map",
    Vel: "Speed",

    //=== Components > OBD2Modal ===//
    //****** OBD2DisplayGrid *****//
    //****** OBD2DisplayItem *****//
    //****** OBD2Hook *****//
    //****** OBD2InformationDisplay *****//
    //****** OBD2Modal *****//
    OBD2: "OBD2",
    datos_OBD2: "OBD2 data",
    //****** OBD2ModalWindow *****//
    obd2_modal_window_ndtc: "Error Code Set",
    obd2_modal_window_celv: "Motor Load [%]",
    obd2_modal_window_ect: "Engine Coolant Temperature [ºC]",
    obd2_modal_window_fp: "Fuel Pressure [kPa]",
    obd2_modal_window_ft: "Fuel Level [%]",
    obd2_modal_window_imap: "Intake Manifold Absolute Pressure [%]",
    obd2_modal_window_rpm: "RPM",
    obd2_modal_window_speed: "OBD2 Speed [mph]",
    obd2_modal_window_ta: "Motion [º]",
    obd2_modal_window_iat: "Engine Air Temperature [ºC]",
    obd2_modal_window_tp: "Throttle Position [%]",
    obd2_modal_window_tign: "Time Engine On [s]  ",
    obd2_modal_window_dtmil: "Distance Traveled MIL on [Miles]",
    obd2_modal_window_panic: "Panic",
    obd2_modal_window_ignition: "Ignition ",
    obd2_modal_window_movement: "Motion",
    obd2_modal_window_bi: "Internal Battery [V]",
    obd2_modal_window_ac: "Power [V]",
    obd2_modal_window_gsm: "GSM Signal",
    obd2_modal_window_velocidad: "Speed [mph]",
    obd2_modal_window_kilometrostotales: "Total Km [Km]",
    obd2_modal_window_coordenadas: "Coordinates",
    obd2_modal_window_fgps: "GPS Date",
    "No hay informacion OBD2 disponible": "No OBD2/CAN BUS data available",
    "OBD2-CANBUS": "OBD2 / CAN BUS",
    //****** OBD2ModalToggleButton *****//

    //=== Components > ReaxiumDeviceSyncButton ===//
    //****** index *****//
    "El proceso de sincronizacion de equipos Reaxium iniciara en breve":
      "The process of Reaxium devices sync will start shortly",
    "El proceso de sincronizacion de equipos Reaxium ya se encuentra en ejecucion":
      "The process of Reaxium devices sync is already running",
    "Iniciar sincronizacion Reaxium": "Start Reaxium sync",

    //=== Components > RevisionsPanel  ===//
    //****** FilterRevision *****//
    // Nombre: "Nombre",
    // Patente: "Patente",
    // Interno: "Interno",
    // Chofer: "Chofer",
    // Tipo: "Tipo",
    // Móviles: "Móviles",
    "Nueva Revisión": "New Revision",
    Revision: "Revision",
    "Tipo de Revisión": "Type of Check",
    "Nuevo aviso de revisión": "New alert for Checkup",

    //****** FilterRevisionNotice *****//
    // "Nombre": "Nombre", // duplicado
    // "Patente": "Patente", // duplicado
    // "Interno": "Interno", // duplicado
    // "Chofer": "Chofer", // duplicado
    // "Tipo": "Tipo", // duplicado

    //****** FormRevision *****//
    // "Nombre": "Nombre", // duplicado
    // "Patente": "Patente", // duplicado
    // "Interno": "Interno", // duplicado
    // "Chofer": "Chofer", // duplicado
    // "Tipo": "Tipo", // duplicado
    "Revision eliminada correctamente": "Revision removed successfully",
    "Revisión creado correctamente": "Review created successfully",
    "Revisión actualizada correctamente": "Revision updated successfully",
    // "Móviles": "Móviles", // duplicado
    // Guardar: "Guardar",
    Eliminar: "Delete",
    IMPORTANTE: "IMPORTANT",

    //****** FormRevisionNotice *****//
    "Aviso de revision creado correctamente":
      "Revision notice created successfully",
    "Aviso de revision actualizado correctamente":
      "Revision notice updated correctly",
    "Aviso de revision eliminado correctamente":
      "Revision notice removed successfully",
    // Nombre: "Nombre",
    // Apellido: "Apellido",
    // Usuario: "Usuario",
    Email: "Email",
    // Patente: "Patente",
    // Interno: "Interno",
    // Chofer: "Chofer",
    // Tipo: "Tipo",
    // Móviles: "Móviles",
    "Seleccione el motivo por el cual quiere generar una alerta periodica":
      "Select the reason why you want to generate a periodic alert",
    "Fecha pasada o futura a partir de la cual comienza el conteo para el primer aviso":
      "Past or future date from which the first warning should start",
    // Seleccionar: "Seleccionar",
    // Fecha: "Fecha",
    "Cada cuantos kms o meses se desea el aviso":
      "Every how many kilometers or months is the warning wanted?:",
    Frecuencia: "Frequency",
    "Porcentaje de alerta": "Alert percentage",
    "Porcentaje de kms o tiempo restante para la revision":
      "Distance percentage or left time for the revision",
    "Personas no registradas en el sistema que recibiran el aviso":
      "People not listed in the system who will receive the notice",
    "Agregar email no registrado": "Add unregistered email",
    // Guardar: "Guardar",
    "¿Está seguro que desea eliminar el aviso de revisión?":
      "Are you sure you want to delete the review notice?",
    // Eliminar: "Eliminar",

    //****** index *****//
    "Próximas revisiones": "Upcoming Checks",
    Revisiones: "Past Checks",

    //****** Itemrevision *****//
    "Editar Revisión": "Edit Revision",
    // Revision: "Revision",

    //****** ItemrevisionNotice *****//

    //****** ListRevision *****//

    //****** ListRevisionNotice *****//

    //=== Components > SelectLanguageModal  ===//
    //****** Form *****//
    "Idioma actualizado correctamente": "Language successfully updated.",
    // Guardar: "Guardar",
    // Cancelar: "Cancelar",
    "Seleccione idioma": "Select language",

    //****** index *****//
    "Cambiar Idioma": "Change language",

    //=== Components > Selectors  ===//
    //****** AssetSelector *****//
    // Nombre: "Nombre",
    // Patente: "Patente",
    // Interno: "Interno",
    // Chofer: "Chofer",
    // Tipo: "Tipo",
    // "pagina previa": "Página previa",
    // "próxima página": "Próxima página",
    // "primer página": "Primer página",
    // "última página": "Última página",
    "Seleccionar Moviles": "Select buses",
    // Cancelar: "Cancelar",
    // Seleccionar: "Seleccionar",
    asset_selector_show_results:
      "Mostrando {{from}} de {{to}} de {{size}} Results",

    //****** DateSelector *****//
    "Última hora": "Last hour",
    "Última 6 horas": "Last 6 hours",
    // Ayer: "Ayer",
    // Hoy: "Hoy",
    "Semana pasada": "Previous week",
    "Mes pasado": "Previous month",
    Personalizado: "Customized",
    // LUNES: "LUNES",
    // MARTES: "MARTES",
    // MIERCOLES: "MIERCOLES",
    // JUEVES: "JUEVES",
    // VIERNES: "VIERNES",
    // SABADO: "SABADO",
    // DOMINGO: "DOMINGO",
    Lunes: "Monday",
    Martes: "Tuesday",
    Miercoles: "Wednesday",
    Jueves: "Thursday",
    Viernes: "Friday",
    Sabado: "Saturday",
    Domingo: "Sunday",
    Confirmar: "Confirm",
    // Cancelar: "Cancelar",
    "Seleccionar rango de busqueda": "Select search range",
    "Fechas personalizadas": "Custom dates",

    //****** GenericSelector *****//
    "Seleccionar Todos": "Select All",
    // Cancelar: "Cancelar",
    // Seleccionar: "Seleccionar",
    buscar_por_titulo: "Search {{title}}...",

    //****** LineSelector *****//
    "Buscar líneas": "Search phone lines...",

    //=== Components > StatisticsPanel  ===//
    //****** index *****//
    Consolidados: "Consolidated",
    "Consumo de Combustible": "Fuel consumption",
    Detenciones: "Stops",
    "Eco Tracking": "Eco Tracking",
    // Eventos: "Eventos",
    Geoescalas: "Geofences",
    "Kilometraje de Flota": "Fleet Mileage Tracking",
    "Móviles en Geocercas": "Bus in geofences",
    "Móviles en Geocercas - Histórico": "Bus in geofences - Location History",
    // OBD2: "OBD2",
    "Tablero Seguimiento": "Tracking Dashboard",
    Temperaturas: "Temperatures",
    Velocidades: "Speeds",

    //=== Pages > Manage > Assets ===//
    //****** AsignacionEquipo *****//
    Identificador: "Identifier",
    "Tipo Equipo": "Device Type",
    // Modelo: "Modelo",
    "Nro Serie": "Serial number",
    "Nro Telefono": "Phone number",
    Imei: "Imei",
    "Fecha Alta": "Entry Date",
    Código: "Code",
    // Nombre: "Nombre",
    // Apellido: "Apellido",
    // Email: "Email",
    Teléfono: "Phone",
    "Teléfono Móvil": "Mobile Phone",
    "Asignacion actualizada correctamente": "Assignment updated correctly",
    Equipo: "Device",
    Tecnicos: "Technicians",
    // Cancelar: "Cancelar",
    // Guardar: "Guardar",
    "Ver Historial": "History",
    "Fecha instalación": "Installation Date",

    //****** AssetForm *****//
    // Nombre: "Nombre",
    CUIT: "CUIT",
    Telefonos: "Phones",
    Celular: "Mobile phone",
    Domicilio: "Address",
    // Apellido: "Apellido",
    // Teléfono: "Teléfono",
    "Teléfono Empresa": "Company Phone",
    "Tipo de Móvil": "Bus Type",
    Placa: "Plate",
    "Número de Interno": "Internal Number",
    Marca: "Make",
    Color: "Color",
    Proveedor: "Provider",
    // Modelo: "Modelo",
    Año: "Year",
    Notas: "Notes",
    "Electrovalvula de corte": "Cut-off Solenoid Valve",
    "Corte de Combustible": "Fuel cut off",
    "Velocidad Maxima": "Maximum Speed",
    // Seleccionar: "Seleccionar",
    "Tensión Principal": "Battery",
    // "Velocidad Máxima": "Velocidad Máxima",
    "Odómetro Real Inicial": "Actual Initial Odometer",
    "Capacidad Tanque": "Tank Capacity",
    "Capacidad Máxima Tanque": "Maximum capacity of the tank",
    "Horómetro Real Inicial": "Actual Initial Hourmeter",
    // Cancelar: "Cancelar",
    Crear: "Create",
    Actualizar: "Update",
    Foto: "Picture",
    "Habilitar Alertas": "Enable alerts",
    "Fecha Instalacion Corte de Combustible": "Install Date for Fuel Cut-Off",
    "Fecha Instalacion Electroválvula de Corte":
      "Install Date for Cut-off Solenoid Valve",
    "Actualizar Móvil": "Update bus",
    "Crear Móvil": "Create bus",
    "Soporta OBD2": "Supports OBD2",

    //****** AssetForm - asset types *****//
    "4X4": "4X4",
    BUS: "BUS",
    CUSTER: "CUSTER",
    MAN: "MAN",
    MINIVAN: "MINIVAN",
    MOTO: "MOTO",
    SEDAN: "SEDAN",
    SPORT: "SPORT",
    STATION: "STATION",
    TRACTOR: "TRACTOR",
    TRUCK: "TRUCK",
    VAN: "VAN",
    BALANCÍN: "ROCKER",
    UTILITARIO: "UTILITARIAN",
    PERSONA: "PERSON",
    CAMIÓN: "TRUCK",
    GRÚA: "CRANE",
    "CAJA PORTÁTIL": "PORTABLE BOX",
    CARRO: "CAR",
    REGADORA: "IRRIGATION",
    "PTO FIJO": "FIXED POINT",
    RETROEXCAVADORA: "BACKHOE",
    "CAMIÓN PETROLERO": "OIL TRUCK",
    CASILLA: "HUT",
    MAQUINA: "MACHINE",

    //****** EcoTrackingForm *****//
    "Consumo entre 0 y 50 km/h (l/100km)":
      "Consumption between 0 and 50 km/h (l/100km)",
    "Consumo entre 51 y 100 km/h (l/100km)":
      "Consumption between 51 and 100 km/h (l/100km)",
    "Consumo mayor a 100 km/h (l/100km)":
      "Consumption greater than 100 km/h (l/100km)",
    "Consumo ralentí (l/hora)": "Idle consumption (l/hour)",
    "Emisión CO2 (gramos/litro)": "CO2 Emission (grams/litre)",
    // Cancelar: "Cancelar",
    // Eliminar: "Eliminar",

    //******* MaxSpeed Form ******//
    "Verificar velocidad inválida": "Check invalid speed",

    //****** index *****//
    "Móvil creado correctamente": "Bus successfully created",
    "Equipo asignado correctamente": "Correctly assigned device",
    "Móvil actualizado correctamente": "Bus successfully updated",
    "Tablero de Seguimiento actualizado correctamente":
      "Tracking Dashboard updated successfully",
    "Móvil eliminado correctamente": "Bus removed successfully",
    // "Ocurrió un error": "Ocurrió un error",
    "Información de EcoTracking creada correctamente":
      "EcoTracking information created successfully",
    "Información de EcoTracking actualizada correctamente":
      "EcoTracking information updated successfully",
    "Información de EcoTracking eliminada correctamente":
      "EcoTracking information removed successfully",
    // "Ocurrió un error" // duplicado

    //****** List *****//
    manage_assets_list_eliminar_movil:
      "Are you sure you want to delete bus {{name}} ?",
    "Tipo de Activo": "Type of Bus",
    // Nombre: "Nombre",
    // Placa: "Placa",
    "Nro Interno": "Internal number",
    "Equipo ID": "ID Device",
    // IMEI: "IMEI",
    // Cliente: "Cliente",
    Estado: "Status",
    manage_assets_list_mostrar_resultados:
      "Mostrando {from} de {to} de {size} Results",
    // "pagina previa": "pagina previa",
    // "próxima página": "próxima página",
    // "primer página": "primer página",
    // "última página": "última página",
    // IMPORTANTE: "IMPORTANTE",
    "Asignacion de Equipos": "Device Assignment",
    "Tablero de Seguimiento": "Tracking Dashboard",
    // "Velocidad Máxima": "Velocidad Máxima",
    "+ Móvil": "+ Bus",

    //****** MaxSpeed *****//
    "Este campo debe ser numérico": "This field shall be numeric",
    "Este campo bebe ser mayor que cero":
      "This field shall be greater than zero",
    "Invalidar velocidad superior a": "Invalidate speed greater than",
    // Actualizar: "Actualizar",
    // Cancelar: "Cancelar",

    //****** ModalhistorialAD *****//
    // Equipo: "Equipo",
    // "Tipo de Equipo": "Tipo de Equipo",
    Linea: "Phone Line",
    Técnico: "Technician",
    Operación: "Operation",
    // Fecha: "Fecha",
    "Historial de Asignaciones": "Assignment record",
    // Cerrar: "Cerrar",

    //****** Revisiones *****//

    //****** TrackingDashboard *****//
    "Numero de Operación": "Operation Number",
    "Dias de Detención Maximos": "Maximum stop days",
    "Promedio Teórico de Kilómetros diarios":
      "Average daily distance (theoretical)",
    "Promedio Horómetro Diario Teórico":
      "Average daily hourmeter (theoretical)",
    // "Este campo bebe ser mayor que cero": "Este campo bebe ser mayor que cero",
    // "Este campo debe ser numérico": "Este campo debe ser numérico",

    //=== Pages > Manage > Client ===//
    //****** ClientForm *****//
    // Requerido: "Requerido",
    "Email invalido": "Invalid email",
    // Nombre: "Nombre",
    // CUIT: "CUIT",
    Teléfonos: "Phones",
    // Celular: "Celular",
    // Domicilio: "Domicilio",
    Mail: "Email",
    "Nivel de Zoom (%)": "Zoom level (%)",
    // Cancelar: "Cancelar",
    // Actualizar: "Actualizar",
    // Crear: "Crear",
    Suspendido: "Suspended",
    "Se requiere un email si las alarmas de email automaticas estan activadas":
      "An email is required if automatic email alarms are activated",

    //****** index *****//
    "Cliente creado correctamente": "Client created successfully",
    // "Ocurrió un error": "Ocurrió un error",
    "Cliente actualizado correctamente": "Client updated successfully",
    "Cliente eliminado correctamente": "Client removed successfully",

    //****** List *****//
    pages_manage_clients_delete_client:
      "Are you sure yo want to delete client {{name}} ?",
    // Nombre: "Nombre",
    // CUIT: "CUIT",
    // Telefonos: "Telefonos",
    // Celular: "Celular",
    // Domicilio: "Domicilio",
    pages_manage_clients_show_results:
      "Mostrando {{from}} de {{to}} de {{size}} Results",
    "Página previa": "Previous page",
    // "Próxima página": "Próxima página",
    // "Primera página": "Primera página",
    // "Última página": "Última página",
    // IMPORTANTE: "IMPORTANTE",
    "Actualizar Cliente": "Update Client",
    "Crear Cliente": "Create Client",
    "+ Cliente": "+ Client",

    //=== Pages > Manage > Devices ===//
    //****** Form *****//
    // Identificador: "Identificador",
    // "Tipo de Equipo": "Tipo de Equipo",
    // IMEI: "IMEI",
    // Modelo: "Modelo",
    // "Nro de Serie": "Nro. de Serie",
    "Versión Firmware": "Firmware Version",
    "Versión Script": "Script Version",
    "Clave Expiración": "Key Expiration",
    "Factor de Conversión Temp": "Conversion Factor Temperature ",
    // Actualizar: "Actualizar",
    // Crear: "Crear",
    // Cancelar: "Cancelar",
    "Can Bus": "Can Bus",
    "Actualizar Equipo": "Update Device",
    "Crear Equipo": "Create Device",
    "El dispositivo no puede ser eliminado porque se encuentra asignado a un asset":
      "Device can't be deleted because its assigned to a bus.",

    //****** index *****//
    "Equipo creado correctamente": "Device created successfully",
    "Ocurrió un error_ Intente nuevamente":
      "A failure occurred. Please try again",
    "Equipo actualizado correctamente": "Device updated successfully",
    "Equipo eliminado correctamente": "Device removed successfully",
    "Líneas asociadas correctamente": "Associated phone lines correctly",
    // Equipos: "Equipos",
    pages_manage_devices_index_sure_want_delete_device:
      "Are you sure you want to delete device {{deviceIdentifier}} ?",

    //****** List *****//
    // Identificador: "Identificador",
    // "Tipo Equipo": "Tipo Equipo",
    // Modelo: "Modelo",
    // "Nro Serie": "Nro Serie",
    // "Nro Telefono": "Nro Telefono",
    // Imei: "Imei",
    // "Fecha Alta": "Fecha Alta",
    // "pagina previa": "pagina previa",
    // "próxima página": "próxima página",
    // "primer página": "primer página",
    // "última página": "última página",
    // "Confirmar eliminación": "Confirmar eliminación",
    // IMPORTANTE: "IMPORTANTE",
    "+ Equipo": "+ Equipo",

    //****** ModalClonacionEquipos *****//
    "Equipo clonado correctamente": "Cloned device correctly",
    // Evento: "Evento",
    Valor: "Value",
    "Es pedido posición": "It is position request",
    "Es reporte automático": "It is automatic report",
    "Es evento de entrada": "It is access event",
    "Es evento de salida": "It is exit event",
    "Es ignition on": "It is ignition on",
    "Es ignition off": "It is ignition off",
    "Es puerta abierta": "It is open door",
    "Es evento software": "It is software event",
    // Equipo: "Equipo",
    "Seleccione uno o mas equipos de destino":
      "Select one or more destination devices",
    "Seleccione uno o mas eventos a clonar":
      "Select one or more events to clone",
    "Origen de la clonación": "Clone source",
    "Destino de la clonación": "Clone destination",
    // Cancelar: "Cancelar",
    // Guardar: "Guardar",
    "Clonación de Equipos": "Clone Devices",
    "Clonar toda la configuración": "Clone all settings",
    "Clonar solo algunos eventos": "Clone just a few events",
    "Puede escribir varios equipos separador por ;":
      "You can write multiple devices separated by ;",

    //****** ModalConfiguracionEvento *****//
    "Eventos clonados correctamente": "Clones events correctly",
    // Identificador: "Identificador",
    // "Tipo Equipo": "Tipo Equipo",
    // Modelo: "Modelo",
    // "Nro Serie": "Nro Serie",
    // "Nro Telefono": "Nro Telefono",
    // Imei: "Imei",
    // "Fecha Alta": "Fecha Alta",
    // Evento: "Evento",
    // Valor: "Valor",
    "Es alarma": "It is alert",
    "Es alarma movimiento": "It is alert motion",
    // "Es pedido posición": "Es pedido posición",
    // "Es reporte automático": "Es reporte automático",
    // "Es evento de entrada": "Es evento de entrada",
    // "Es evento de salida": "Es evento de salida",
    // "Es ignition on": "Es ignition on",
    // "Es ignition off": "Es ignition off",
    // "Es puerta abierta": "Es puerta abierta",
    // "Es evento software": "Es evento software",
    "Clonar Eventos": "Clone Events",
    "Nuevo Evento": "New Event",
    Clonar: "Clone",
    // Evento: "Evento",
    // Valor: "Valor",

    manage_device_modal_configuracion_evento_mostrar_resultados:
      "Showing {{from}} from {{to}} to {{size}} Results",

    //****** ModalLineas *****//
    // Líneas: "Líneas",

    //=== Pages > Manage > Drivers ===//
    //****** Form *****//
    IButton: "IButton",
    "Compañia Teléfono": "Phone Company",
    "Compañia Celular": "Mobile Phone Company",
    Observaciones: "Comments",

    //****** index *****//
    "Driver creado correctamente": "Driver created successfully",
    "Driver actualizado correctamente": "Driver updated successfully",
    "Driver eliminado correctamente": "Driver removed successfully",

    //****** List *****//
    "Eliminar un Conductor": "Remove a driver",
    pages_manage_drivers_are_you_sure_want_delete_driver:
      "Are you sure you want to delete Driver {{fullName}} ?",
    Si: "Yes",
    No: "No",
    // Cliente: "Cliente",
    // "Compañia Teléfono": "Compañia Teléfono",
    // Celular: "Celular",
    // "Compañia Teléfono": "Compañia Teléfono",
    // Suspendido: "Suspendido",
    pages_manage_drivers_show_results:
      "Showing {{from}} from {{to}} to {{size}} Results",
    "Actualizar Conductor": "Update Driver",
    "Crear Conductor": "Create Driver",
    // Conductores: "Conductores",
    "+ Conductor": "+ Driver",

    //=== Pages > Manage > Geofences ===//
    //****** Form *****//
    // Cliente: "Cliente",
    "Nombre Geocerca": "Geofence Name",
    "Mostrar en mapa": "Show on map",
    "Tiene alarmas E/S": "It has alerts for entry/exit",
    "Tiene máxima velocidad": "It has maximum speed",
    "Máxima Velocidad": "Maximum Speed",
    "Tipo de Geocerca": "Geofence Type",
    "Por favor dibuje el poligono correspondiente a la geocerca":
      "Please, draw the polygon corresponding to the geofence.",

    //****** index *****//
    "Geocerca creada correctamente": "Geofence created successfully",
    "Geocerca actualizada correctamente": "Geofence aupdated successfully",
    "Geocerca eliminada correctamente": "Geofence removed successfully",
    // Geocercas: "Geocercas",

    //****** List *****//
    "Eliminar Geocerca": "Delete Geofence",
    pages_manage_geofences_esta_seguro_eliminar_geocerca:
      "Are you sure you want to delete geofence {{name}} ?",
    Editar: "Edit",
    "Ver geocerca": "See Geofence",
    // "Mostrar en mapa": "Mostrar en mapa",
    "Actualizar Geocerca": "Update Geofence",
    "Crear Geocerca": "Create Geofence",
    "+ Geocerca": "+ Geofence",

    //****** Map *****//

    //=== Pages > Manage > Groups ===//
    //****** AssetPanel *****//
    // Móviles: "Móviles",
    pages_manage_groups_moviles_pertenecientes_al_grupo:
      "Buses belonging to the group: {{groupName}}",
    "Busqueda de móviles": "Search of buses",
    "Quitar movil": "Remove bus",
    "Quitar todos los móviles": "Remove all buses",
    "Asignar Flota": "Assign Fleet",

    //****** GroupPanel *****//
    // Grupos: "Grupos",
    pages_manage_groups_grupos_pertenecientes_al_cliente:
      "Groups belonging to the client: {{customerName}}",
    "Guardar cambios": "Save changes",
    "Crear grupo": "Create group",
    "Nuevo grupo": "New group",
    "Eliminar grupo": "Delete group",
    "Asociar otra flota": "Associate another fleet",
    "Crear subgrupo": "Create subgroup",
    "Cambiar nombre": "Change name",
    "Asignar usuarios": "Assign users",
    "Asignar moviles": "Assign buses",

    //****** index *****//
    "Grupo creado correctamente": "Grupo created successfully",
    "Móviles asignados correctamente": "Buses assigned successfully",
    "Usuarios asignados correctamente": "Users assigned successfully",
    "Usuarios quitados correctamente": "Users removed successfully",
    "Móviles desasignados correctamente": "Buses designated successfully",
    "No se puede quitar móviles del grupo raiz":
      "You can't remove buses from the root group",
    "No se puede asignar móviles al grupo raiz":
      "You can't assign buses to the root group",
    "Grupo eliminado correctamente": "Group deleted successfully",
    "No se puede eliminar el grupo raiz": "You can't delete the root group",
    "Grupo actualizado correctamente": "Group updated successfully",
    "Debe seleccionar un cliente": "Always select a client",
    // Grupos: "Grupos",

    //****** ModalAssets *****//
    // Nombre: "Nombre",
    // Placa: "Placa",
    "Búsqueda de Móviles": "Search of buses",
    // Cancelar: "Cancelar",
    // Seleccionar: "Seleccionar",

    //****** ModalAssociateFleet *****//
    "Asociar otras flotas": "Associate other fleets",
    // Grupos: "Grupos",
    // "Guardar cambios": "Guardar cambios",

    //****** ModalSubUser *****//
    // Requerido: "Requerido",
    // "Email invalido": "Email invalido",
    "Usuario creado correctamente": "User created successfully",
    "Editar Usuario": "Edit User",
    "Nuevo Usuario": "New User",
    "Las contraseñas no coinciden": "Passwords don't match",
    "Seleccione un grupo": "Select a group",
    "Nueva contraseña": "New password",
    "Confirmación de contraseña": "Password Confirmation",
    "Correo Electrónico": "Email",
    // Usuario: "Usuario",
    Perfil: "Profile",

    //****** ModalUsers *****//
    // Usuario: "Usuario",
    "Búsqueda de Usuarios": "Search of users",

    //****** UserPanel *****//
    // Usuarios: "Usuarios",
    pages_manage_groups_usuarios_pertenecientes_al_grupo:
      "Users belonging to the group: {{groupName}}",
    "Busqueda de Usuarios": "Search of users",
    "Quitar usuario": "Remove user",
    "Quitar todos los usuarios": "Remove all users",
    "Crear sub-usuario": "Create sub-user",
    "Eliminar usuario": "Delete user",
    "Editar usuario": "Edit user",

    //=== Pages > Manage > GuideNumber ===//
    //****** Form *****//
    // Interno: "Interno",
    "Número de Guía": "Tracking number",
    // Actualizar: "Actualizar",
    Asignar: "Assign",

    //****** index *****//
    "Número de guía asignado correctamente":
      "Tracking number assing successfully",
    "Número de guía desasignado correctamente":
      "Tracking number designated successfully",
    // "Ocurrió un error": "Ocurrió un error",

    //****** List *****//
    "Desasignar Número de Guía": "Designate Tracking Number",
    pages_manage_guidenumber_designate_tracking_number:
      "Are you sure you want to designate tracking number {{guideNumber}} ?",
    Desasignar: "Unlink",
    // "Número de Guía": "Número de Guía",
    pages_manage_guidenumber_show_results:
      "Showing {{from}} from {{to}} to {{size}} Results",
    // "Página previa": "Página previa",
    // "Próxima página": "Próxima página",
    // "Primera página": "Primera página",
    // "Última página": "Última página",
    "Actualizar Número de Guía": "Update Tracking Number",
    "Asignar Número de Guía": "Assign Tracking Number",
    "Asignar números de guía": "Assign Tracking Numbers",
    "+ Número de Guía": "+ Tracking Number",

    //=== Pages > Manage > Lines ===//
    //****** index *****//
    "Línea creado correctamente": "Phone Line created successfully",
    "Línea actualizado correctamente": "Phone Line updated successfully",
    "Línea eliminada correctamente": "Phone Line deleted successfully",
    // "Ocurrió un error": "Ocurrió un error",

    //****** LineForm *****//
    "Email no válido": "Invalid Email",
    // Identificador: "Identificador",
    // "Tipo Equipo": "Tipo Equipo",
    // Modelo: "Modelo",
    // "Nro Serie": "Nro Serie",
    // "Nro Telefono": "Nro Telefono",
    // Imei: "Imei",
    // "Fecha Alta": "Fecha Alta",
    // Nombre: "Nombre",
    // CUIT: "CUIT",
    // Telefonos: "Telefonos",
    // Celular: "Celular",
    // Domicilio: "Domicilio",
    // Cliente: "Cliente",
    Número: "Number",
    SIM: "SIM",
    "Tipo Comunicación": "Communication Type",
    // Email: "Email",
    // Actualizar: "Actualizar",
    // Crear: "Crear",

    //****** List *****//
    "Eliminar Línea": "Delete Phone Line",
    pages_manage_phone_line_sure_delete_phone_line:
      "Are you sure you want to delete phone line {{name}} ?",
    // Editar: "Editar",
    // Eliminar: "Eliminar",
    // Número: "Número",
    "Número SIM": "SIM Number",
    // Cliente: "Cliente",
    // "Tipo Comunicación": "Tipo Comunicación",
    // Email: "Email",
    // "Equipo ID": "Equipo ID",
    "Actualizar Línea": "Update Phone Line",
    "Crear Línea": "Create Phone Line",
    // Líneas: "Líneas",
    "+ Línea": "+ Phone Line",
    pages_manage_linea_show_results:
      "Showing {{from}} from {{to}} to {{size}} Results",

    //=== Pages > Manage > POIs ===//
    //****** Form *****//
    // Requerido: "Requerido",
    // Nombre: "Nombre",
    // CUIT: "CUIT",
    // Telefonos: "Telefonos",
    // Celular: "Celular",
    // Domicilio: "Domicilio",
    // Cliente: "Cliente",
    // Nombre: "Nombre",
    // Latitud: "Latitud",
    Longitude: "Longitude",
    Radio: "Radius",
    Categoría: "Category",
    // Actualizar: "Actualizar",
    // Crear: "Crear",
    // Cancelar: "Cancelar",

    //****** index *****//
    // "Geocerca creada correctamente": "Geocerca creada correctamente",
    // "Geocerca actualizada correctamente": "Geocerca actualizada correctamente",
    // "Geocerca eliminada correctamente": "Geocerca eliminada correctamente",
    "Puntos de Interés": "Point of Interest",

    //****** List *****//
    "Eliminar Punto de Interés": "Delete Point of Interest",
    pages_manage_pois_sure_delete_poi:
      "Are you sure you want to delete Point of Interest {{name}} ?",
    "Ver Puntos de Interés": "See Point of Interest",
    // Latitud: "Latitud",
    // Longitude: "Longitude",
    // Radio: "Radio",
    // Categoría: "Categoría",
    // "pagina previa": "pagina previa",
    // "próxima página": "próxima página",
    // "primer página": "primer página",
    // "última página": "última página",
    "Confirmar eliminación": "Confirm delete",
    // IMPORTANTE: "IMPORTANTE",
    "Ver Punto de Interés": "See Point of Interest",
    "Actualizar Punto de Interés": "Update Point of Interest",
    "Crear Punto de Interés": "Create Point of Interest",
    "+ Punto de Interés": "+ Point of Interest",

    //****** Map *****//

    //=== Pages > Manage > ReportPlanning ===//
    "Reporte de detenciones": "Stops Report",
    "Reporte de eventos": "Events Report",
    "Reporte de kilometraje de flota": "Fleet Mileage Tracking Report",
    "Reporte de geoescalas": "Geofences Report",
    "Reporte de recorridos": "Routes Report",
    "Reporte de velocidades": "Speeds Report",
    "Reporte de Tablero de Seguimiento": "Tracking Dashboard Report",
    "Listado de posiciones": "Fleet Status Report",
    "Reporte Consolidado": "Consolidated Report",
    "Reporte Eco Tracking": "Eco Tracking Report",
    "Reporte OBD2": "OBD2 Report",
    "Reporte Desvio de Paradas": "Stops Detour Report",

    //****** Report types *****//

    DETENCIONES: "DETENTIONS",
    EVENTOS: "EVENTS",
    KILOMETRAJEFLOTA: "FLEETMILEAGE",
    GEOESCALAS: "GEOSCALES",
    RECORRIDOS: "ROUTES",
    VELOCIDADES: "VELOCITIES",
    TABLEROSEGUIMIENTO: "TRACKINGBOARD",
    POSICIONES: "POSITIONS",
    CONSOLIDADO: "CONSOLIDATED",
    ECOTRACKING: "ECOTRACKING",
    // OBD2: "OBD2",
    STOPSDETOUR: "STOPSDETOUR",

    //****** Form *****//
    pages_manage_reportingplanning_actualizar_por_nombre: "Update {{name}}",
    "Crear Reporte Planificado": "Create Planned Report",

    //****** FormHeader *****//
    // Planificador: "Planificador",
    "Tipo de Reporte": "Report Type",
    Formato: "Format",
    Habilitado: "Enabled",
    // Cliente: "Cliente",
    Excel: "Excel",
    pages_manage_reportingplanning_reporte_creado_por:
      "Planning created by {{creadaPor}}",

    "Desvio de Paradas": "Stops Detour",

    //****** FormNotification *****//
    "Notificar via email a": "Notify via email to:",
    "Otras direcciones": "Other email addresses",
    "Otras direcciones Cco": "Other email addresses Cco:",

    //****** FormProgramation *****//
    Horas: "Hours",
    // Inicio: "Inicio",
    // Fin: "Fin",
    // Frecuencia: "Frecuencia",
    Ejecución: "Implementation",
    // Habilitado: "Habilitado",
    "Nueva Programación": "New schedule",
    "Editar Programación": "Edit schedule",
    Habilitada: "Enabled",
    "Sucede una vez a las %at%": "It happens once, at {{at}}",
    "Sucede cada %every% horas desde %from% hasta %to%":
      "It happens every {{every}} hours, from {{from}} to {{to}}",
    "Sucede una vez a las": "It happens once at",
    "Sucede cada": "It happens every",
    // Inicio: "Inicio",
    // Fin: "Fin",
    // Frecuencia: "Frecuencia",
    Diaria: "Daily",
    Diario: "Daily",
    Semanal: "Weekly",
    Mensual: "Monthly",
    Cada: "Every",
    dias: "Days",
    "Todos los dias de la semana": "Seven days a week",
    "Repetir cada": "Repeat every",
    "semanas el": "Weeks, day/days:",
    "El día": "Day",
    "de cada": "Each",
    El: "The",
    // "de cada": "de cada",
    "Intervalo de repetición": "Range of Repetition",
    "Comenzar el": "Start on",
    "Finalizar el": "Finish on",
    Programaciones: "Schedules",
    "+ Programación": "+ Schedule",

    //****** FormReportFilters *****//
    // "Última hora": "Última hora",
    "Últimas seis horas": "Last six hours",
    // Hoy: "Hoy",
    // Ayer: "Ayer",
    // "Semana pasada": "Semana pasada",
    // "Mes pasado": "Mes pasado",
    // "Mostrar en mapa": "Mostrar en mapa",
    // "Velocidad Máxima": "Velocidad Máxima",
    Filtros: "Filters",
    "Todos los móviles activos": "All active buses",
    // Seleccionar_: "Seleccionar...",
    "Velocidad Hasta": "Speed up",
    // Conductores: "Conductores",
    // Geocercas: "Geocercas",
    "Tiempo Permanencia minimo [s]": "Minimum dwell time [s]",

    //****** index *****//
    "Reporte planificado creado correctamente":
      "Planned report created successfully",
    "Reporte planificado actualizado correctamente":
      "Planned report updated successfully",
    "Reporte planificado eliminado correctamente":
      "Planned report deleted successfully",
    "Planificador de Reportes": "Report Planner",

    //****** List *****//
    pages_manage_reportingplanning_seguro_eliminar_planificacion:
      "Are you sure you want to delete planning {{name}} ?",
    Plan: "Plan",
    "Creado por": "Created by",
    Reporte: "Report",
    // Formato: "Formato",
    // Habilitado: "Habilitado",
    // "Confirmar eliminación": "Confirmar eliminación",
    "+ Reporte Planificado": "+ Planned Report",
    "Debe ingresar al menos un destinatario":
      "You must enter at least one recipient",

    //=== Pages > Manage > Roles ===//
    //****** index *****//
    "Permisos asignados correctamente": "Assigned permissions successfully",
    "Rol creado correctamente": "Role created successfully",
    "Rol actualizado correctamente": "Role updated successfully",
    "Rol eliminado correctamente": "Role deleted successfully",
    // "Ocurrió un error": "Ocurrió un error",

    //****** List *****//
    "Eliminar Rol": "Delete Role",
    "Asignar Permisos": "Assign Permissions",
    Rol: "Role",
    // Categoría: "Categoría",
    "Actualizar Rol": "Update Role",
    "Crear Rol": "Create Role",
    // Roles: "Roles",
    "+ Rol": "+ Role",
    pages_manage_roles_sure_delete_role:
      "Are you sure you want to delete role {{name}} ?",
    pages_manage_roles_show_results:
      "Showing {{from}} from {{to}} to {{size}} Results",

    //****** ModalAsignarPermisos *****//
    pages_manage_roleseleccionado: "Permissions {{name}}",

    //****** RoleForm *****//
    // Rol: "Rol",
    "Administrador Plataforma": "Platform Manager",
    "Administrador Cliente": "Client Manager",
    Operador: "Operator",

    //=== Pages > Manage > Routes ===//
    //****** Form *****//
    // "Mostrar en mapa": "Mostrar en mapa",
    // "Velocidad Máxima": "Velocidad Máxima",
    Descripción: "Description",
    "Activar Alarma de desvio": "Activate Deviation alarm",
    Desvío: "Desvío",
    "Activar Alarma de velocidad": "Activate Speed warning",
    // "Velocidad Máxima": "Velocidad Máxima",
    // Geocercas: "Geocercas",

    //****** GeofenceMap *****//

    //****** index *****//
    "Ruta creada correctamente": "Route created successfully",
    "Ruta actualizada correctamente": "Route updated successfully",
    "Ruta eliminada correctamente": "Route deleted successfully",
    // Rutas: "Rutas",
    "Actualizar Ruta": "Update Route",
    "Crear Ruta": "Create Route",
    "+ Ruta": "+ Route",

    //****** List *****//
    "Ver Ruta": "See Route",
    "Aviso Desvío": "Diversion Notice",
    "Mts Desvío": "Deviation",
    "Aviso Velocidad": "Speed warning",
    "Máx Velocidad [Km/h]": "Maximum Speed [mph]",
    pages_manage_routes_sure_delete_route:
      "Are you sure you want to delete route {{name}} ?",

    //=== Pages > Manage > Routes ===//
    //****** index *****//
    "Técnico creado correctamente": "Technician created successfully",
    "Técnico actualizado correctamente": "Technician updated successfully",
    "Técnico eliminado correctamente": "Technician deleted successfully",

    //****** List *****//
    "Eliminar Ténico": "Delete Technician",
    // Código: "Código",
    "Actualizar Técnico": "Update Technician",
    "Crear Técnico": "Create Technician",
    // Técnicos: "Técnicos",
    "+ Técnico": "+ Technician",
    pages_manage_technicians_sure_delete_technical:
      "Are you sure you want to delete technician {{name}} ?",
    pages_manage_technicians_show_results:
      "Showing {{from}} from {{to}} to {{size}} Results",

    //****** TechnicianForm *****//
    // Código: "Código",

    //=== Pages > Manage > Users ===//
    //****** index *****//
    // "Usuario creado correctamente": "Usuario creado correctamente",
    // "Usuario actualizado correctamente": "Usuario actualizado correctamente",
    "Usuario eliminado correctamente": "User deleted successfully",
    pages_manage_users_seguro_eliminar_user:
      "Are you sure you want to delete user {{name}} ?",

    // Usuarios: "Usuarios",

    //****** List *****//
    // "Eliminar Línea": "Eliminar Línea",
    // Roles: "Roles",
    Activo: "Active",
    Inactivo: "Inactive",
    "Actualizar Usuario": "Update User",
    "Crear Usuario": "Create User",
    "+ Usuario": "+ User",
    pages_manage_users_sure_delete_phone_line:
      "Are you sure you want to delete phone line {{name}} ?",
    // pages_manage_technicians_mostrar_resultados:
    // "Mostrando {{from}} de {{to}} de {{size}} Resultados",

    //****** ModalAsignarRoles *****//
    "Roles asignados correctamente": "Roles assigned successfully",
    Categoria: "Category",

    //****** UserForm *****//
    // Contraseña: "Contraseña",
    "Teléfono 1": "Phone 1",
    "Teléfono 2": "Phone 2",
    // "Teléfono Móvil": "Teléfono Móvil",
    "Imagen de Perfil": "Profile picture",
    "Tiempo de Session": "Session time",
    // Activo: "Activo",
    "Habilitar Google Maps": "Enable Google Maps",

    //=== Pages > Manage > Statistics ===//
    //****** userFilterValidation *****//

    //=== Pages > Manage > Statistics > ConsolidateReport ===//
    //****** Filter *****//
    // Interno: "Interno",
    Fechas: "Dates",
    Resumido: "Summarized",
    "Debe seleccionar al menos un móvil": "Please select at least one bus",
    "Generar Reporte": "Generate Report",

    //****** index *****//
    "No se encontraron datos para su consulta": "No data found.",
    // Consolidados: "Consolidados",

    //=== Pages > Manage > Statistics > DetentionReport ===//
    //****** Filters *****//
    "Estado Móvil": "Bus State",
    "Tiempo Mínimo (m)": "Minimum Time (m)",
    " Generar Reporte": " Generate Report",
    // "Debe seleccionar al menos un móvil": "Debe seleccionar al menos un móvil.",
    "exportar excel": "export excel",
    "exportar pdf": "export pdf",
    // KML: "KML",
    "exportar kml": "export kml",

    //****** index *****//
    // "No se encontraron datos para su consulta":
    //   "No se encontraron datos para su consulta.",
    // Estadísticas: "Estadísticas",
    // Detenciones: "Detenciones",

    //****** ReportResult *****//
    Lugar: "Place",
    // Estado: "Estado",
    "Duración detención": "Duration",
    "Ver punto": "See Location",
    Detención: "Stopped",

    //=== Pages > Manage > Statistics > EcoTrackingReport ===//
    //****** Filter *****//

    //****** index *****//
    // "No se encontraron datos para su consulta":
    //   "No se encontraron datos para su consulta.",
    EcoTracking: "EcoTracking",

    //****** ReportResult *****//
    "Kms Recorridos": "Miles traveled",
    "Consumo Baja": "Low Speed Consumption",
    "Consumo Media": "Average Speed Consumption",
    "Consumo Alta": "High Speed Consumption",
    "Consumo Ralenti": "Idle Consumption",
    cO2: "cO2",
    "Litros Total [l]": "Litres Total [l]",
    "Emision CO2 - NOx Total[g]": "CO2 Emission - NOx Total[g]",

    //=== Pages > Manage > Statistics > EventReport ===//
    //****** Filter *****//
    // Eventos: "Eventos",
    // "Debe seleccionar al menos un móvil": "Debe seleccionar al menos un móvil.",

    //****** index *****//

    //****** ReportResult *****//
    Desde: "From",
    Hasta: "To",
    "Tiempo Recorrido": "Journey Time",
    "Eventos Totales": "Total Events",
    // KML: "KML",
    // Fecha: "Fecha",
    // "Tiempo Recorrido": "Tiempo Recorrido",
    // "Kms Recorridos": "Kms Recorridos",
    Ver: "See",

    //=== Pages > Manage > Statistics > FleetKmReport ===//
    //****** Filter *****//

    //****** index *****//
    // "Kilometraje de Flota": "Kilometraje de Flota",

    //****** ReportResult *****//
    "Tipo Movil": "Bus Type",
    "Nombre Móvil": "Bus Name",
    // "Kms Recorridos": "Kms Recorridos",
    "Vel_ Maxima Promedio": "Average Maximum Speed",
    // "Velocidad Máxima": "Velocidad Máxima",
    "Ubicación Velocidad Máxima": "Location Maximum Speed",

    //=== Pages > Manage > Statistics > FuelConsumptionReport ===//
    //****** Filter *****//

    //****** index *****//
    "Reporte de Eficiencia de Consumo Combustible":
      "Fuel consumption Efficiency Report",

    //****** ReportResult *****//
    "Kms Recorrido": "Miles Traveled",
    "Ralentí [Hs]": "Idle [Hs]",
    "Ratio Consumo": "Consumption Rate",

    //=== Pages > Manage > Statistics > GeoScaleReport ===//
    //=== Pages > Statistics > RouteDetourReport ===//
    "Debe seleccionar una ruta": "Please select a route",
    "Debe seleccionar un móvil": "Please select an bus",
    "Debe seleccionar una fecha de inicio de ruta":
      "Please select a starting date.",
    "Debe seleccionar una fecha de fin de ruta": "Please select an end date.",
    "Desvio de ruta": "Route detour",
    "Desvios de ruta": "Route detours",
    "Inicio de ruta": "Route start",
    "Fin de ruta": "Route end",
    "Vehiculo en ruta": "Bus in route",
    "Inicio trayecto": "Path start",
    "Fin trayecto": "Path end",
    "Inicio desvio": "Detour start",
    "Fin desvio": "Detour end",
    Ruta: "Route",
    Prom: "Avg.",
    "No se encuentra el evento de inicio de ruta": "Missing route start event",
    "No se encuentra el evento de fin de ruta": "Missing route end event",

    //=== Pages > Statistics > StopDetourReport ===//
    "Horario esperado de llegada": "Expected arrival time",
    "Horario real de llegada": "Actual arrival time",
    "Horario real de salida": "Actual exit time",
    "Desvios de Parada": "Stops Detour",
    "Tiempo en parada": "Time in stop",
    aprox: "approx",

    //=== Pages > Statistics > StopHistoryReport ===//
    "Histórico de Paradas": "Stop History",
    Orden: "Order",

    //****** Filter *****//

    //****** index *****//

    //****** ReportResult *****//
    Entrada: "Entry date",
    Salida: "Exit date",
    Duración: "Duration",
    Geocerca: "Geofence",
    "Nombre móvil": "Bus name",

    //=== Pages > Manage > Statistics > KmReport ===//
    //****** Filter *****//

    //****** index *****//
    // "Kilometraje de Flota": "Kilometraje de Flota",

    //****** ReportResult *****//
    // "Tipo de Móvil": "Tipo de Móvil",
    // "Kms Recorrido": "Kms Recorrido",
    "Velocidad Promedio": "Average Speed",
    // "Ubicación Velocidad Máxima": "Ubicación Velocidad Máxima",
    // Fecha: "Fecha",
    // "Tiempo Recorrido": "Tiempo Recorrido",
    // "Kms Recorridos": "Kms Recorridos",
    // Eventos: "Eventos",
    // Conductor: "Conductor",
    // Lugar: "Lugar",
    // Ver: "Ver",

    //=== Pages > Manage > Statistics > OBD2 ===//
    //****** Chart *****//

    //****** Filter *****//
    "Variable OBD2": "OBD2 Variable",
    // "Reporte OBD2": "OBD2 Report",
    "Debe seleccionar al menos una variable OBD2":
      "Please select at least one OBD2 variable",

    //****** index *****//
    Odometro: "Odometer",
    OdometroMi: "Odometer [mi]",
    "Cantidad de Fallas": "Number of Failures",
    Cantidad: "Quantity",
    "Carga del motor [%]": "Engine load [%]",
    "Temperatura del anticongelante [C°]": "Antifreeze temperature [F°]",
    "Presion de Combustible [kPa]": "Fuel Pressure [kPa]",
    "Nivel de combustible [%]": "Fuel Level [%]",
    "Presión del colector de admision [%]": "Intake manifold pressure [%]",
    RPM: "RPM",
    "Velocidad OBD2 [km/h]": "OBD2 Speed [mph]",
    "Movimiento [°]": "Movement [°]",
    "Temperatura Aire motor [C°]": "Engine air temperature [C°]",
    "Posición del acelerador [%]": "Throttle position [%]",
    "Tiempo desde encendido de motor [s]": "Time from engine start [s]",
    "Distancia recorrida con MIL on [Km]": "Distance traveled with MIL on [Km]",
    Panico: "Panic",
    Ignición: "Ignition",
    Movimiento: "Movement",
    "Bateria Interna [V]": "Internal Battery [V]",
    "Alimentación [V]": "Power [V]",
    "Señal GSM": "GSM signal",

    //****** ReportResult *****//

    //=== Pages > Manage > Statistics > TrackingBoardReport ===//
    //****** Filter *****//

    //****** index *****//
    // "Tablero de Seguimiento": "Tablero de Seguimiento",

    //****** ReportResult *****//
    "Nro Operación": "Operation Number",
    "Dias Detención Max": "Maximum Detention Days",
    "Dias Detención Actual": "Actual Detention Days",
    "Promedio Teorico Km Diarios": "Target Average Daily Km",
    "Promedio Real Km Diarios": "Actual Average Daily Km",
    "Promedio Horometro Diario Teorico (H)":
      "Hourmeter Average Target Daily (H)",
    "Promedio Horometro Diario Real (H)": "Hourmeter Average Actual Daily (H)",
    "Zona Operaciones Evaluada": "Evaluated Operations Area",
    "Zona Operaciones Real": "Real Operations Area",

    //=== Pages > Manage > Statistics > VelocityReport ===//
    //****** Filter *****//
    // Conductores: "Conductores",
    "Velocidad Desde": "Speed from",
    // "Velocidad Hasta": "Velocidad Hasta",

    //****** index *****//
    // Velocidad: "Velocidad",

    //****** ReportResult *****//
    "Velocidad Máxima Movil": "Maximum Bus Speed",
    Ubicación: "Location",
    Detalles: "Details",
    pages_manage_statistics_velocityreport_mostrar_resultados:
      "Showing {{from}} from {{to}} to {{size}} Results",
    pages_manage_statistics_velocityreport_velocidad:
      "Speed {{vel}} [mph], {{assetName}}",

    //=== Pages ===//
    //****** Forbidden *****//
    "Acceso Denegado": "Access Denied",
    "No tiene permisos para acceder a esta página":
      "You are not authorised to access this page",
    "Volver a página principal": "Return to the main page",

    //****** LoginPage *****//

    //****** UserPage *****//

    //****** Geofences ***** */
    "Importar Geocercas (KML)": "Import Geofences (KML)",
    "Importar Geocercas": "Import Geofences",
    Importar: "Import",
    "Seleccione KML": "Select KML",
    "Archivo KML importado correctamente": "KML file imported correctly",
    "Cliente invalido":
      "Invalid client. You must select exactly one client to perform this action.",

    // ** RUMBO **/
    N: "N",
    NE: "NE",
    E: "E",
    SE: "SE",
    S: "S",
    SO: "SW",
    O: "W",
    NO: "NW",

    // ** File names ** //
    ReporteRecorridos: "RouteReport",
    ListadoPosiciones: "PositionsList",
    ReporteEventos: "EventsReport",
    ReporteVelocidad: "SpeedsReport",
    ReporteConsolidado: "ConsolidateReport",
    ReporteDetencion: "StopReport",
    ReporteEcoTracking: "EcoTrackingReport",
    ReporteKmFlota: "FleetKMTrackingReport",
    ReporteConsumoCombustible: "FuelConsumptionReport",
    ReporteGeoescalas: "GeofencesReport",
    ReporteKms: "KmsReport",
    ReporteOBD2: "OBD2Report",
    ReporteDesviosDeParada: "StopDetoursReport",
    ReporteTableroSeguimiento: "TrackingDashboardReport",

    // ** Leaflet mesasure distance units ** //
    primaryLengthUnit: "miles",
    secondaryLengthUnit: "kilometers",
    primaryAreaUnit: "sqmiles",
    secondaryAreaUnit: "acres",

    // ** Permissions ** //
    "Mostrar filtro de conductores en reportes de estadistica":
      "Display drivers filter on statistic reports",
    "Mostrar cuenta de personas en el panel de estado de flota":
      "Display person count on fleet status panel",
    "Crear, modificar y eliminar Clientes": "Create, modify and delete Clients",
    "Crear, modificar y eliminar Lineas":
      "Create, modify and delete Phone Lines",
    "Administrar N° Guia": "Manage Guide Number",
    "Crear, modificar y eliminar Equipos": "Create, modify and delete Devices",
    "Crear, modificar y eliminar Geocercas":
      "Create, modify and delete Geofences",
    "Crear, modificar y eliminar Grupos": "Create, modify and delete Groups",
    "Crear, modificar y eliminar Moviles": "Create, modify and delete Buses",
    "Planificar Reportes": "Schedule Reports",
    "Planificar Recorridos": "Schedule Routes",
    "Crear, modificar y eliminar Puntos de interes":
      "Create, modify and delete Points of Interest",
    "Crear, modificar y eliminar Roles": "Create, modify and delete Roles",
    "Crear, modificar y eliminar Rutas": "Create, modify and delete Routes",
    "Crear, modificar y eliminar Tecnicos":
      "Create, modify and delete Technicians",
    "Crear, modificar y eliminar Usuarios": "Create, modify and delete Users",
    "Reporte de Kilometraje de flota": "Fleet Mileage Report",
    "Reporte móviles en geocerca": "Buses in Geofence Report",
    "Reporte móviles en geocercas Historico":
      "Buses in Geofences History Report",
    "Reporte Tablero de Seguimiento": "Tracking Board Report",
    "Reporte de Temperaturas": "Temperatures Report",
    "Reporte de Velocidad": "Speeds Report",
    "Ver notificaciones y asentir alarmas":
      "View notifications and raise alarms",
    "Reporte de conductas de manejo": "Behavior Report",
    "Mantenimiento de móviles": "Buses Maintenance",
    "Asentir alarmas": "Confirm Alarms",
    "Marcar notificaciones como leidas": "Mark notifications as read",
    "Asignar equipo a un móvil": "Assign device to an bus",
    "Asignar roles a usuarios": "Assign roles to users",
    "Asignar flota de otro cliente a un grupo":
      "Assign fleet of another client to a group",
    "Asignar lineas a un equipo": "Assign lines to a device",
    "Crear, modificar y eliminar conductores":
      "Create, modify and delete Drivers",
    "Columnas extras reporte Eco Tracking": "Eco Tracking Report Columns",
    "Reporte de Consumo de Combustible": "Fuel Consumption Report",
    "Reporte de Desvio de Ruta": "Route Deviation Report",
    "Reporte de Desvio de Parada": "Stop Deviation Report",
    "Reporte de Comparación de Paradas": "Stop Comparison Report",
    "Importar geofences desde archivo KML": "Import geofences from KML file",
    "Iniciar la sincronizacion de los dispositivos de Reaxium":
      "Start synchronization of Reaxium devices",
    "Configurar notificaciones por mail": "Configurar notificaciones por mail",
    // ** Permissions/Roles Categories **//
    General: "General",
    Acciones: "Actions",

    //** Others **/
    Todos: "All",
    "Configuracion Evento": "Event Configuration",
    "Asignar Equipo": "Assign Device",
    Grupo: "Group",
    Movil: "Bus",
    "Fecha de Evento": "Event Date",
    "Hora de Llegada Prevista": "Scheduled Stop Time",
    "errores-continuar": "Please fix all errors to continue.",

    /* Configuracion de notificaciones */
    ConfNotifications: "Notifications Settings",
    ConfNotifUpdated: "Notification setting updated",
    ConfNotifCreated: "Notification setting created",
    ConfNotifDeleted: "Notification setting deleted",
    DeleteConfNotificationTitle: "Delete configuration setting",
    DeleteConfNotificationMessage:
      "Are you sure you want to delete the configuration {{name}} ?",
    CrearConfiguracion: "Create setting",
    "+ Configuración": "+ Setting",
    ActualizarConfiguracion: "Update setting",
    ExcesoVelocidad: "Speeding",
    ExcesoVelocidadEnGeocerca: "Speeding in geofence",
    EventosESGeocerca: "I/O events in geofence",
    EventosDeRuta: "Route events",
    EventosHardware: "Hardware events",
    EventosSoftware: "Software events",
    //ItemRevisionNotice
    "Editar Aviso de Revision": "Edit Revision Notice",
    "Odometro actual": "Current Odometer",
    "Odometro revisión": "Notice Odometer",
  },
};
