import { useLazyQuery } from "@apollo/client";
import { Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import RemoteTable from "../../../components/Base/RemoteTable";
import ThreeDotMenu from "../../../components/Base/ThreeDotMenu";
import GET_DRIVER_EXTRAINFO_QUERY from "../../../graphql/queries/driverExtraInfoQuery";
import DriveModal from "./DriverForm";
import { useTranslation } from "../../../lang";

const List = ({
  paginationSizes,
  total,
  paginationState,
  setPaginationState,
  driverList,
  createDriver,
  updateDriver,
  getDrivers,
  deleteDriver,
}) => {
  const [t] = useTranslation();
  const [openSide, setOpenSide] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [photo, setPhoto] = useState();
  const [textToSearch, setTextToSearch] = useState("");

  useEffect(() => {
    getDrivers();
  }, []);

  const [getDriverPhoto] = useLazyQuery(GET_DRIVER_EXTRAINFO_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      setPhoto(d?.getDriver?.photo);
    },
  });

  const confirmDelete = (id, fullName) => {
    confirmAlert({
      title: t("Eliminar un Conductor"),
      message: t("pages_manage_drivers_esta_seguro_eliminar_conductor", {
        fullName,
      }),
      buttons: [
        {
          label: t("Si"),
          onClick: () => {
            deleteDriver(id);
          },
        },
        {
          label: t("No"),
          onClick: () => console.log("cancelar"),
        },
      ],
    });
  };

  const itemStyle = {
    fontSize: "12px",
  };

  const callbackSelectOption = (option, row) => {
    switch (option) {
      case 1:
        getDriverPhoto({ variables: { id: row.id } });
        setSelectedItem(row);
        setOpenSide(true);
        break;
      case 2:
        confirmDelete(row.id, `${row.firstName} ${row.lastName}`);
        break;
    }
  };

  const hightlightIfMatch = (text) => {
    if (
      text &&
      textToSearch &&
      text.toLocaleLowerCase().includes(textToSearch.toLocaleLowerCase())
    ) {
      const newText = text.replace(
        new RegExp(textToSearch, "gi"),
        (match) => `<mark>${match}</mark>`
      );
      return <div dangerouslySetInnerHTML={{ __html: newText }} />;
    }

    return text;
  };

  const columns = [
    {
      dataField: "id",
      text: "",
      headerStyle: () => ({ width: "50px", fontSize: "12px" }),
      formatter: (cell, row) => {
        return (
          <>
            <ThreeDotMenu
              row={row}
              callback={callbackSelectOption}
              options={[
                {
                  label: `${t("Editar")}`,
                  value: 1,
                },
                {
                  label: `${t("Eliminar")}`,
                  value: 2,
                },
              ]}
            />
          </>
        );
      },
    },
    {
      dataField: "firstName",
      text: t("Nombre"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      style: () => itemStyle,
      formatter: hightlightIfMatch,
      sort: true,
    },
    {
      dataField: "lastName",
      text: t("Apellido"),
      headerStyle: () => ({ width: "15%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
      formatter: hightlightIfMatch,
      // filter: textFilter(),
    },
    {
      dataField: "ibutton",
      text: t("IButton"),
      headerStyle: () => ({ width: "15%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
      formatter: hightlightIfMatch,
      // filter: textFilter(),
    },
    {
      dataField: "customer.name",
      text: t("Cliente"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
      //formatter: hightlightIfMatch,
      // filter: textFilter(),
    },
    {
      dataField: "phone",
      text: t("Teléfono"),
      headerStyle: () => ({ width: "15%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
      formatter: hightlightIfMatch,
      // filter: textFilter(),
    },
    {
      dataField: "companyPhone",
      text: t("Compañia Teléfono"),
      headerStyle: () => ({ width: "15%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
      formatter: hightlightIfMatch,
      // filter: textFilter(),
    },
    {
      dataField: "cellPhone",
      text: t("Celular"),
      headerStyle: () => ({ width: "15%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
      formatter: hightlightIfMatch,
      // filter: textFilter(),
    },
    {
      dataField: "companyCellPhone",
      text: t("Compañia Teléfono"),
      headerStyle: () => ({ width: "15%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
      formatter: hightlightIfMatch,
      // filter: textFilter(),
    },
    {
      dataField: "suspended",
      text: t("Suspendido"),
      headerStyle: () => ({ width: "15%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
      formatter: (cell, row) => {
        return row.suspended ? "Si" : "No";
      },
      // formatter: hightlightIfMatch,
      // filter: textFilter(),
    },
  ];

  const customTotal = (from, to, size) => (
    <span
      className="react-bootstrap-table-pagination-total customTotalDisplay"
      style={{ marginLeft: "15px" }}
    >
      {t("pages_manage_drivers_mostrar_resultados", { from, to, size })}
    </span>
  );

  const options = {
    // Tooltips botones de paginación
    prePageTitle: "Página previa",
    nextPageTitle: "Próxima página",
    firstPageTitle: "Primera página",
    lastPageTitle: "Última página",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: paginationSizes,
    totalSize: total,
  };
  const onSearch = (searchText) => {
    setPaginationState({
      ...paginationState,
      searchText: searchText,
    });
  };

  const onPaginate = (page, sizePerPage) => {
    setPaginationState({
      ...paginationState,
      pageNumber: page,
      pageSize: sizePerPage,
    });
  };

  const onSort = (sortObj) => {
    setPaginationState({
      ...paginationState,
      sort: sortObj,
    });
  };

  return (
    <div className="listContainer">
      <SlidingPane
        className="slidingPaneRight"
        isOpen={openSide}
        title={selectedItem ? t("Actualizar Conductor") : t("Crear Conductor")}
        width="400px"
        onRequestClose={() => {
          setOpenSide(false);
        }}
      >
        <DriveModal
          setIsOpen={() => setOpenSide(false)}
          createDriver={createDriver}
          selectedDriver={selectedItem}
          updateDriver={updateDriver}
          photo={photo}
        />
      </SlidingPane>
      <RemoteTable
        columns={columns}
        data={driverList}
        keyField="id"
        paginationOptions={options}
        onPaginate={onPaginate}
        onSearch={onSearch}
        onSort={onSort}
        total={total}
        lateralComponent={
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setPhoto(null);
              setSelectedItem(null);
              setOpenSide(true);
            }}
            style={{ float: "right" }}
          >
            {t("+ Conductor")}
          </Button>
        }
      />
    </div>
  );
};

export default List;
