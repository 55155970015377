import gql from "graphql-tag";

const CREATE_CUSTOMER_MUTATION = gql`
  mutation CreateCustomer(
    $name: String!
    $taxId: String
    $phone: String
    $celPhone: String
    $email: String
    $address: String
    $zoom: Int
    $dateSuspension: Datetime
    $automaticMailAlarm: Boolean
  ) {
    createCustomer(
      name: $name
      taxId: $taxId
      phone: $phone
      celPhone: $celPhone
      email: $email
      address: $address
      zoom: $zoom
      dateSuspension: $dateSuspension
      automaticMailAlarm: $automaticMailAlarm
    ) {
      id
    }
  }
`;

export default CREATE_CUSTOMER_MUTATION;
