import gql from "graphql-tag";

const GROUPS_BYCUSTOMER_QUERY = gql`
  query GroupsByCustomer($customerId: Int!) {
    groupsByCustomer(customerId: $customerId) {
      name
      id
      customerId
      parentGroupId
      externalCustomer {
        id
      }
      assets {
        id
        licensePlate
        name
      }
      users {
        id
        firstName
        lastName
        userName
        email
        roles {
          value: id
          label: name
        }
      }
      customer {
        id
        name
      }
    }
  }
`;

export default GROUPS_BYCUSTOMER_QUERY;
