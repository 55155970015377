import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import { Provider } from "react-redux";
import thunk from "redux-thunk";

import reducer from "../redux/rootReducer";

import PrivateRoute from "./PrivateRoute";
import ReportVelocityPage from "../pages/Statistics/VelocityReport";
import ReporteDetentionPage from "../pages/Statistics/DetentionReport";
import ReportEventPage from "../pages/Statistics/EventReport";
import ReportFleetKmPage from "../pages/Statistics/FleetKmReport";
import ReportConsolidatePage from "../pages/Statistics/ConsolidateReport";
import ReportGeoScalePage from "../pages/Statistics/GeoScaleReport";
import ReportOBD2Page from "../pages/Statistics/OBD2";
import ReportTrackingBoard from "../pages/Statistics/TrackingBoardReport";
import ReportEcoTracking from "../pages/Statistics/EcoTrackingReport";
import ReportFuelConsumption from "../pages/Statistics/FuelConsumptionReport";
import ReportRouteDetour from "../pages/Statistics/RouteDetourReport";
import StopDetourReport from "../pages/Statistics/StopDetourReport";
import StopHistoryReport from "../pages/Statistics/StopHistoryReport";

import { configureInitialState, configureStore } from "../utils/store";

export default class StatisticsRoutes extends Component {
  static propTypes = {
    path: PropTypes.string,
  };

  static defaultProps = {
    path: "",
  };

  constructor(props) {
    super(props);

    /**
     * Let's create a store with redux-thunk and set the initial state
     * If you need to add or modify objects in the store, remember to start
     * adding them to the 'configureInitialState' method
     */
    this._store = configureStore({
      reducer,
      initialState: configureInitialState(props),
      // eslint-disable-next-line comma-dangle
      middleware: [thunk],
    });
  }

  render() {
    const { path } = this.props;
    return (
      <Provider store={this._store} path={path}>
        <Route
          path={`${path}/velocity`}
          exact
          render={(routerParams) => (
            <PrivateRoute
              path={`${path}/velocity`}
              permission="Velocidad"
              component={ReportVelocityPage}
              {...routerParams}
            />
          )}
        />
        <Route
          path={`${path}/detention`}
          exact
          render={(routerParams) => (
            <PrivateRoute
              path={`${path}/detention`}
              permission="Detenciones"
              component={ReporteDetentionPage}
              {...routerParams}
            />
          )}
        />
        <Route
          path={`${path}/event`}
          exact
          render={(routerParams) => (
            <PrivateRoute
              path={`${path}/event`}
              permission="Eventos"
              component={ReportEventPage}
              {...routerParams}
            />
          )}
        />

        <Route
          path={`${path}/fleetKm`}
          exact
          render={(routerParams) => (
            <PrivateRoute
              path={`${path}/fleetKm`}
              permission="KilometrajeFlota"
              component={ReportFleetKmPage}
              {...routerParams}
            />
          )}
        />

        <Route
          path={`${path}/consolidate`}
          exact
          render={(routerParams) => (
            <PrivateRoute
              path={`${path}/consolidate`}
              permission="Consolidados"
              component={ReportConsolidatePage}
              {...routerParams}
            />
          )}
        />

        <Route
          path={`${path}/geoscale`}
          exact
          render={(routerParams) => (
            <PrivateRoute
              path={`${path}/geoscale`}
              permission="Geoescalas"
              component={ReportGeoScalePage}
              {...routerParams}
            />
          )}
        />

        <Route
          path={`${path}/obd2`}
          exact
          render={(routerParams) => (
            <PrivateRoute
              path={`${path}/obd2`}
              permission="ReporteOBD2"
              component={ReportOBD2Page}
              {...routerParams}
            />
          )}
        />

        <Route
          path={`${path}/trackingBoard`}
          exact
          render={(routerParams) => (
            <PrivateRoute
              path={`${path}/trackingBoard`}
              permission="TableroSeguimiento"
              component={ReportTrackingBoard}
              {...routerParams}
            />
          )}
        />

        <Route
          path={`${path}/ecoTracking`}
          exact
          render={(routerParams) => (
            <PrivateRoute
              path={`${path}/ecoTracking`}
              permission="EcoTracking"
              component={ReportEcoTracking}
              {...routerParams}
            />
          )}
        />

        <Route
          path={`${path}/fuelconsumption`}
          exact
          render={(routerParams) => (
            <PrivateRoute
              path={`${path}/fuelconsumption`}
              permission="ConsumoCombustible"
              component={ReportFuelConsumption}
              {...routerParams}
            />
          )}
        />
        <Route
          path={`${path}/routeDetour`}
          exact
          render={(routerParams) => (
            <PrivateRoute
              path={`${path}/routeDetour`}
              permission="ReporteDesvioDeRuta"
              component={ReportRouteDetour}
              {...routerParams}
            />
          )}
        />
        <Route
          path={`${path}/stopDetour`}
          exact
          render={(routerParams) => (
            <PrivateRoute
              path={`${path}/stopDetour`}
              permission="ReporteDesvioDeParada"
              component={StopDetourReport}
              {...routerParams}
            />
          )}
        />
        <Route
          path={`${path}/stopHistory`}
          exact
          render={(routerParams) => (
            <PrivateRoute
              path={`${path}/stopHistory`}
              permission="ReporteDesvioDeParada"
              component={StopHistoryReport}
              {...routerParams}
            />
          )}
        />
      </Provider>
    );
  }
}
