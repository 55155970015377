import React, { useState } from "react";
import RemoteTable from "../../../components/Base/RemoteTable";

import "react-confirm-alert/src/react-confirm-alert.css";
import { Button, IconButton } from "@material-ui/core";

import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

import ThreeDotMenu from "../../../components/Base/ThreeDotMenu";
import AssetForm from "./AssetForm";

import GET_ASSET_EXTRAINFO_QUERY from "../../../graphql/queries/assetExtraInfoQuery";

import { sanitizeSort } from "../../../utils/search";
import EcoTrackingForm from "./EcoTrackingForm";
import TrackingDashboardForm from "./TrackingDashboard";
import MaxSpeedForm from "./MaxSpeed";
import { useLazyQuery } from "@apollo/client";

import AsignacionEquipo from "./AsignacionEquipo";
import ModalConfirmacion from "../../../components/Base/ModalConfirmacion";

import { hasPermission } from "../../../utils/generalUtils";

import { useTranslation } from "../../../lang";
import IconExcel from "../../../public/iconExcel.png";
import IconPDF from "../../../public/iconPDF.png";
const List = ({
  assetList,
  getAssets,
  deleteAsset,
  paginationSizes,
  total,
  setPaginationState,
  paginationState,
  createAsset,
  updateAsset,
  createEcoTracking,
  updateEcoTracking,
  deleteEcoTracking,
  createAssetDevice,
  updateGeofencesAsset,
  loggedUser,
  getAssetsExport,
}) => {
  const [t] = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [photo, setPhoto] = useState();
  const [selectedAsset, setSelectedAsset] = useState();
  const [textToSearch] = useState("");
  const [openModalEcoTracking, setOpenModalEcoTracking] = useState(false);
  const [openModalTrackingDashboard, setOpenModalTrackingDashboard] = useState(
    false
  );
  const [openModalMaxSpeed, setOpenModalMaxSpeed] = useState(false);

  const [openModalAsignacion, setOpenModalAsignacion] = useState(false);
  const closeModalAsignacion = () => setOpenModalAsignacion(false);

  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const closeModalConfirm = () => setIsOpenConfirm(false);
  const [confirmText, setConfirmText] = useState();
  const [idToDelete, setIdToDelete] = useState();

  const [getAssetPhoto] = useLazyQuery(GET_ASSET_EXTRAINFO_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      setPhoto(d?.asset?.photo);
    },
  });

  const callbackSelectOption = async (option, row) => {
    switch (option) {
      case 1:
        getAssetPhoto({ variables: { assetId: row.id } });
        setSelectedAsset(row);
        setOpenModal(true);
        break;
      case 2:
        //confirmDelete(row.id, row.name);
        setIsOpenConfirm(true);
        setConfirmText(
          t("manage_assets_list_eliminar_movil", {
            name: row.name,
          })
        );
        setIdToDelete(row.id);
        break;
      case 3:
        setSelectedAsset(row);
        setOpenModalEcoTracking(true);
        break;
      case 4:
        setSelectedAsset(row);
        setOpenModalTrackingDashboard(true);
        break;
      case 5:
        setSelectedAsset(row);
        setOpenModalMaxSpeed(true);
        break;
      case 6:
        setSelectedAsset(row);
        setOpenModalAsignacion(true);
        break;
      default:
        break;
    }
  };

  const itemStyle = {
    fontSize: "12px",
  };

  const hightlightIfMatch = (text) => {
    if (
      text &&
      textToSearch &&
      text.toLocaleLowerCase().includes(textToSearch.toLocaleLowerCase())
    ) {
      const newText = text.replace(
        new RegExp(textToSearch, "gi"),
        (match) => `<mark>${match}</mark>`
      );
      return <div dangerouslySetInnerHTML={{ __html: newText }} />;
    }

    return text;
  };

  const columns = [
    {
      dataField: "id",
      text: "",
      headerStyle: () => ({ width: "50px", fontSize: "12px" }),
      formatter: (cell, row) => {
        return (
          <>
            <ThreeDotMenu
              menuKey={row.id}
              row={row}
              callback={callbackSelectOption}
              options={[
                {
                  label: `${t("Editar")}`,
                  value: 1,
                },
                {
                  label: `${t("EcoTracking")}`,
                  value: 3,
                },
                {
                  label: `${t("Tablero de Seguimiento")}`,
                  value: 4,
                },
                {
                  label: `${t("Velocidad Máxima")}`,
                  value: 5,
                },
                hasPermission("AsignarEquipoMovil", loggedUser.roles) && {
                  label: `${t("Asignar Equipo")}`,
                  value: 6,
                },
                {
                  label: `${t("Eliminar")}`,
                  value: 2,
                },
              ]}
            />
          </>
        );
      },
    },
    {
      dataField: "typeMobile.description",
      text: t("Tipo de Activo"),
      headerStyle: () => ({ width: "10%", fontSize: "12px" }),
      style: () => itemStyle,
      // formatter: hightlightIfMatch,
      formatter: (cell, row) => {
        return hightlightIfMatch(t(row.typeMobile?.description));
      },
      sort: true,
    },
    {
      dataField: "name",
      text: t("Nombre"),
      headerStyle: () => ({ width: "20%", fontSize: "12px" }),
      style: () => itemStyle,
      formatter: hightlightIfMatch,
      sort: true,
    },
    {
      dataField: "licensePlate",
      text: t("Placa"),
      headerStyle: () => ({ width: "10%", fontSize: "12px" }),
      style: () => itemStyle,
      formatter: hightlightIfMatch,
      sort: true,
    },
    {
      dataField: "internalNumber",
      text: t("Nro Interno"),
      headerStyle: () => ({ width: "15%", fontSize: "12px" }),
      style: () => itemStyle,
      formatter: hightlightIfMatch,
      sort: true,
    },
    {
      dataField: "assetDevice.device.deviceIdentifier",
      text: t("Equipo ID"),
      headerStyle: () => ({ width: "10%", fontSize: "12px" }),
      style: () => itemStyle,
      formatter: hightlightIfMatch,
      sort: true,
    },
    {
      dataField: "assetDevice.device.imei",
      text: t("IMEI"),
      headerStyle: () => ({ width: "10%", fontSize: "12px" }),
      style: () => itemStyle,
      formatter: hightlightIfMatch,
      sort: true,
    },
    {
      dataField: "customer.name",
      text: t("Cliente"),
      headerStyle: () => ({ width: "10%", fontSize: "12px" }),
      style: () => itemStyle,
      formatter: hightlightIfMatch,
      sort: true,
    },
    {
      dataField: "status",
      text: t("Estado"),
      headerStyle: () => ({ width: "10%", fontSize: "12px" }),
      style: () => itemStyle,
      formatter: hightlightIfMatch,
      sort: true,
    },
  ];

  const customTotal = (from, to, size) => (
    <span
      className="react-bootstrap-table-pagination-total customTotalDisplay"
      style={{ marginLeft: "15px" }}
    >
      {t("manage_assets_list_mostrar_resultados", {
        from,
        to,
        size,
      })}
    </span>
  );

  const options = {
    // Tooltips botones de paginación
    prePageTitle: t("pagina previa"),
    nextPageTitle: t("próxima página"),
    firstPageTitle: t("primer página"),
    lastPageTitle: t("última página"),
    showTotal: true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: paginationSizes,
    totalSize: total,
  };

  const onSearch = (searchText) => {
    setPaginationState({
      ...paginationState,
      pageNumber: 0,
      searchText: searchText ? searchText : undefined,
    });
  };

  const onPaginate = (page, sizePerPage) => {
    setPaginationState({
      ...paginationState,
      pageNumber: page,
      pageSize: sizePerPage,
    });
  };

  const onSort = (sortObj) => {
    setPaginationState({
      ...paginationState,
      sort: sanitizeSort(sortObj),
    });
  };

  return (
    <>
      <ModalConfirmacion
        isOpen={isOpenConfirm}
        buttonConfirmation="Confirmar"
        closeModal={closeModalConfirm}
        confirmationText={confirmText}
        handleConfirm={() => {
          deleteAsset(idToDelete);
        }}
        title={t("IMPORTANTE")}
      />
      <div className="listContainer">
        <SlidingPane
          className="slidingPaneRight"
          isOpen={openModal}
          title={selectedAsset ? t("Actualizar Móvil") : t("Crear Móvil")}
          width="400px"
          onRequestClose={() => {
            setOpenModal(false);
          }}
        >
          <AssetForm
            setIsOpen={() => setOpenModal(false)}
            createAsset={createAsset}
            updateAsset={updateAsset}
            selectedAsset={selectedAsset}
            photo={photo}
          />
        </SlidingPane>
        <SlidingPane
          className="slidingPaneRight"
          isOpen={openModalAsignacion}
          title={t("Asignacion de Equipos")}
          width="400px"
          onRequestClose={() => closeModalAsignacion()}
        >
          <AsignacionEquipo
            assetSelected={selectedAsset}
            closeModal={closeModalAsignacion}
            createAssetDevice={createAssetDevice}
            getAssets={getAssets}
          />
        </SlidingPane>
        <SlidingPane
          className="slidingPaneRight"
          isOpen={openModalEcoTracking}
          title={"EcoTracking"}
          width="400px"
          onRequestClose={() => {
            setOpenModalEcoTracking(false);
          }}
        >
          <EcoTrackingForm
            setIsOpen={() => setOpenModalEcoTracking(false)}
            createEcoTracking={createEcoTracking}
            updateEcoTracking={updateEcoTracking}
            deleteEcoTracking={deleteEcoTracking}
            selectedAsset={selectedAsset}
          />
        </SlidingPane>
        <SlidingPane
          className="slidingPaneRight"
          isOpen={openModalTrackingDashboard}
          title={t("Tablero de Seguimiento")}
          width="400px"
          onRequestClose={() => {
            setOpenModalTrackingDashboard(false);
          }}
        >
          <TrackingDashboardForm
            setIsOpen={() => setOpenModalTrackingDashboard(false)}
            updateGeofencesAsset={updateGeofencesAsset}
            selectedAsset={selectedAsset}
          />
        </SlidingPane>
        <SlidingPane
          className="slidingPaneRight"
          isOpen={openModalMaxSpeed}
          title={t("Velocidad Máxima")}
          width="400px"
          onRequestClose={() => {
            setOpenModalMaxSpeed(false);
          }}
        >
          <MaxSpeedForm
            setIsOpen={() => setOpenModalMaxSpeed(false)}
            updateAsset={updateAsset}
            selectedAsset={selectedAsset}
          />
        </SlidingPane>

        <RemoteTable
          columns={columns}
          data={assetList}
          keyField="id"
          paginationOptions={options}
          onPaginate={onPaginate}
          onSearch={onSearch}
          onSort={onSort}
          total={total}
          lateralComponent={
            <div className="button-container">
              <IconButton
                title={t("EXCEL")}
                onClick={() => getAssetsExport("EXCEL")}
              >
                <img src={IconExcel} className="exportIcon" />
              </IconButton>
              <IconButton
                title={t("PDF")}
                onClick={() => getAssetsExport("PDF")}
              >
                <img src={IconPDF} className="exportIcon" />
              </IconButton>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setPhoto(null);
                  setSelectedAsset(null);
                  setOpenModal(true);
                }}
                style={{ float: "right" }}
              >
                {t("+ Móvil")}
              </Button>
            </div>
          }
        />
      </div>
    </>
  );
};

export default React.memo(List);
