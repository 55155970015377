import React, { useState, useEffect } from "react";

import { format } from "date-fns";
import { useLazyQuery } from "@apollo/client";

import HISTORY_ASSETDEVICE_QUERY from "../../../graphql/queries/getAssetDeviceQuery";

import Table from "../../../components/Base/Table";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import { useTranslation } from "../../../lang";
const theme = createMuiTheme({});

const ModalHistorialAD = ({ assetId, isOpen, closeModal }) => {
  const [t] = useTranslation();
  const [historyAssetDevice, setHistoryAssetDevice] = useState([]);

  const [fetchHistoryAD] = useLazyQuery(HISTORY_ASSETDEVICE_QUERY, {
    onCompleted(d) {
      setHistoryAssetDevice(d.historyAssetDevice);
    },
  });

  useEffect(() => {
    if (assetId) {
      fetchHistoryAD({
        variables: {
          assetId: assetId,
        },
      });
    }
  }, [assetId]);

  const columnsHistoryAD = [
    {
      dataField: "deviceIdentifier",
      text: t("Equipo"),
      sort: true,
    },
    {
      dataField: "deviceType",
      text: t("Tipo de Equipo"),
      sort: true,
    },
    {
      dataField: "devicePhoneNumber",
      text: t("Linea"),
      sort: true,
    },
    {
      dataField: "technicianFirstname",
      text: t("Técnico"),
      sort: true,
      formatter: (cell, row) =>
        row.technicianLastname
          ? `${row.technicianLastname}, ${row.technicianFirstname}`
          : "",
    },
    {
      dataField: "typeOperation",
      text: t("Operación"),
      sort: true,
    },
    {
      dataField: "date",
      text: t("Fecha"),
      formatter: (cell, row) =>
        format(new Date(row.date), "dd/MM/yyyy HH:mm:ss"),
      sort: true,
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Dialog open={isOpen} onClose={closeModal} maxWidth="lg" fullWidth>
        <DialogTitle className="dialogTitle">
          {t("Historial de Asignaciones")}
        </DialogTitle>
        <DialogContent>
          <Table
            columns={columnsHistoryAD}
            data={historyAssetDevice}
            keyField="id"
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={closeModal}>
            {t("Cerrar")}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default ModalHistorialAD;
