import React from "react";
import { Link } from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import "./ManageNavbar.scss";

import { useTranslation } from "../../lang";

const ManageNavbar = () => {
  const [t] = useTranslation();
  const pageTitle = process.env.REACT_APP_PAGE_TITLE;

  return (
    <AppBar position="static" className="manage-navbar manage-navbar--top">
      <Typography component="h1" variant="h4" className="manage-navbar__title">
        <Link to="/manage">
          <div id="manage-navbar-brand-logo">
            <img src="/assets/img/logo.svg" alt={t(pageTitle)} />
          </div>
        </Link>
      </Typography>

      <Toolbar className="manage-navbar__content">
        <div className="manage-navbar__content-menu">
          <Link
            className="manage-navbar__content-menu-item"
            to="/manage/clients"
          >
            <Typography variant="h6" color="inherit">
              {t("Clientes")}
            </Typography>
          </Link>

          <Link className="manage-navbar__content-menu-item" to="/map">
            <Typography variant="h6" color="inherit">
              {t("Mapa")}
            </Typography>
          </Link>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default ManageNavbar;
