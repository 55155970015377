import gql from "graphql-tag";

const GEOSCALE_REPORT_EXPORT_QUERY = gql`
  query GeoscalesReportExport(
    $assetsSelected: [Int]!
    $geofencesSelected: [Int]
    $fechaInicio: Datetime
    $fechaFin: Datetime
    $days: [WeekDaysEnum]
    $format: FormatEnum
  ) {
    geoscalesReportExport(
      assetsSelected: $assetsSelected
      geofencesSelected: $geofencesSelected
      fechaInicio: $fechaInicio
      fechaFin: $fechaFin
      days: $days
      format: $format
    )
  }
`;

export default GEOSCALE_REPORT_EXPORT_QUERY;
