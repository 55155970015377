import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  Grid,
  Snackbar,
  TextField,
} from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import { format } from "date-fns";
import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import Select from "react-select";
import "../../../components/Base/RemoteTable.scss";
import ThreeDotMenu from "../../../components/Base/ThreeDotMenu";
import GenericSelector from "../../../components/Selectors/GenericSelector";
import CLONE_EVENT_MUTATION from "../../../graphql/mutations/cloneEventsMutation";
import SAVE_CONFEVENTOS_MUTATION from "../../../graphql/mutations/saveConfEventosMutation";
import GET_DEVICES_QUERY from "../../../graphql/queries/devicesQuery";
import CONFIGURATION_EVENTS_QUERY from "../../../graphql/queries/getConfigurationEventsQuery";
import TYPE_EVENTALL_QUERY from "../../../graphql/queries/typeEventAllQuery";
import { useTranslation } from "../../../lang";
import CustomSelectStyles from "../../../utils/reactSelectStyle";

const theme = createMuiTheme({});

const ModalConfiguracionEvento = ({
  deviceId,
  isOpen,
  closeModal,
  deviceIdentifier,
}) => {
  const [t] = useTranslation();
  const paginationSizes = [10, 25, 50];
  const [configurationEvents, setConfigurationEvents] = useState([]);
  const [showEditor, setShowEditor] = useState(false);
  const [showCloneEvent, setShowCloneEvent] = useState(false);
  const [tipoEventos, setTipoEventos] = useState([]);
  const [destinyDevices, setDestinyDevices] = useState([]);
  const [destinyDevicesSelected, setDestinyDeviceSelected] = useState();
  const [resultState, setResultState] = useState({
    isOpen: false,
    type: "success",
    msg: "",
  });
  const [editingRow, setEditingRow] = useState({
    tipoEvento: { value: -1, label: "" },
    value: "",
    id: -1,
  });

  const tableRef = React.useRef();

  const [getConfEvents] = useLazyQuery(CONFIGURATION_EVENTS_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      setConfigurationEvents(d.configurationEventByEquipoId);
    },
  });

  const [getDevices] = useLazyQuery(GET_DEVICES_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      setDestinyDevices(d.devicesPaginated.items);
    },
  });

  const [cloneEvents] = useMutation(CLONE_EVENT_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Eventos clonados correctamente"),
      });
    },
    onError(err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: err.message,
      });
    },
  });

  const handleClonar = () => {
    if (destinyDevicesSelected) {
      cloneEvents({
        variables: {
          deviceIdSource: deviceId,
          deviceIdDestination: destinyDevicesSelected.value,
        },
      });
    }
  };

  React.useEffect(() => {
    if (deviceId) {
      getConfEvents({
        variables: {
          deviceId: deviceId,
        },
      });
    }
  }, [deviceId, getConfEvents]);

  useQuery(TYPE_EVENTALL_QUERY, {
    onCompleted(d) {
      setTipoEventos(d.typeEventAll);
    },
  });

  const [saveConfEventos] = useMutation(SAVE_CONFEVENTOS_MUTATION, {
    onCompleted(d) {
      getConfEvents({
        variables: {
          deviceId: deviceId,
        },
      });
    },
    onError(err) {
      console.log("Error: ", err);
    },
  });

  const handleCheck = (checked, id, field) => {
    const data = [...tableRef.current.props.data];
    const index = data.findIndex((x) => x.id === id);
    let confEventToUpdate = { ...data[index] };
    confEventToUpdate[field] = checked;
    confEventToUpdate.status = !confEventToUpdate.status
      ? "modified"
      : confEventToUpdate.status;
    const newConfEvents = [
      ...data.slice(0, index),
      confEventToUpdate,
      ...data.slice(index + 1),
    ];
    // console.log("New conf events: ", newConfEvents);
    setConfigurationEvents(newConfEvents);
  };

  const callbackSelectOption = (option, row) => {
    switch (option) {
      case 1:
        setEditingRow({
          tipoEvento: {
            value: row.typeEvent.id,
            label: row.typeEvent.description,
          },
          value: row.value,
          id: row.id,
          isNew: row.status === "new",
        });
        setShowEditor(true);
        break;
      case 2:
        const index = tableRef.current.props.data.findIndex(
          (x) => x.id === row.id
        );
        let confEventToUpdate = { ...configurationEvents[index] };
        confEventToUpdate.status = "deleted";
        setConfigurationEvents([
          ...configurationEvents.slice(0, index),
          confEventToUpdate,
          ...configurationEvents.slice(index + 1),
        ]);
        break;
      default:
        break;
    }
  };

  const columnsDevices = [
    {
      dataField: "deviceIdentifier",
      text: t("Identificador"),
      headerStyle: () => ({ width: "15%", fontSize: "12px" }),
      sort: true,
    },
    {
      dataField: "deviceType.description",
      text: t("Tipo Equipo"),
      headerStyle: () => ({ width: "15%", fontSize: "12px" }),
      //sort: true,
    },
    {
      dataField: "model",
      text: t("Modelo"),
      headerStyle: () => ({ width: "10%", fontSize: "12px" }),
      sort: true,
    },
    {
      dataField: "serialNumber",
      text: t("Nro Serie"),
      sort: true,
      headerStyle: () => ({ width: "10%", fontSize: "12px" }),
    },
    {
      dataField: "line",
      text: t("Nro Telefono"),
      headerStyle: () => ({ width: "10%", fontSize: "12px" }),
      formatter: (cell, row) => {
        if (Array.isArray(row.line)) {
          return row.line.map((l) => l.lineNumber).join(",");
        }

        return null;
      },
      //sort: true,
    },
    {
      dataField: "imei",
      text: t("Imei"),
      headerStyle: () => ({ width: "15%", fontSize: "12px" }),
      sort: true,
    },
    {
      dataField: "createdDate",
      text: t("Fecha Alta"),
      headerStyle: () => ({ width: "10%", fontSize: "12px" }),
      formatter: (cell, row) => {
        if (row.createdDate) {
          const date = format(new Date(row.createdDate), "yyyy-MM-dd HH:mm:ss");
          return date;
        }

        return "";
      },
      sort: true,
    },
  ];

  const columns = [
    {
      dataField: "id",
      text: "",
      headerStyle: () => ({ width: "20px", fontSize: "12px" }),
      formatter: (cell, row) => {
        return (
          <>
            <ThreeDotMenu
              key={row.id}
              row={row}
              callback={callbackSelectOption}
              options={[
                {
                  label: "Editar",
                  value: 1,
                },
                {
                  label: "Eliminar",
                  value: 2,
                },
              ]}
            />
          </>
        );
      },
    },
    {
      dataField: "typeEvent.description",
      text: t("Evento"),
      headerStyle: () => ({ width: "13.5%", fontSize: "12px" }),
    },
    {
      dataField: "value",
      text: t("Valor"),
      headerStyle: () => ({ width: "13.5%", fontSize: "12px" }),
    },
    {
      dataField: "isAlarm",
      text: t("Es alarma"),
      headerStyle: () => ({ width: "13.5%", fontSize: "12px" }),
      formatter: (cell, row) => {
        return (
          <Checkbox
            color="primary"
            checked={row.isAlarm}
            onChange={(evt) =>
              handleCheck(evt.target.checked, row.id, "isAlarm")
            }
          />
        );
      },
    },
    // {
    //   dataField: "isMovementAlarm",
    //   text: t("Es alarma movimiento"),
    //   headerStyle: () => ({ width: "10%", fontSize: "12px" }),
    //   formatter: (cell, row) => {
    //     return (
    //       <Checkbox
    //         color="primary"
    //         checked={row.isMovementAlarm}
    //         onChange={(evt) =>
    //           handleCheck(evt.target.checked, row.id, "isMovementAlarm")
    //         }
    //       />
    //     );
    //   },
    // },
    // {
    //   dataField: "isPositionRequest",
    //   text: t("Es pedido posición"),
    //   headerStyle: () => ({ width: "10%", fontSize: "12px" }),
    //   formatter: (cell, row) => {
    //     return (
    //       <Checkbox
    //         color="primary"
    //         checked={row.isPositionRequest}
    //         onChange={(evt) =>
    //           handleCheck(evt.target.checked, row.id, "isPositionRequest")
    //         }
    //       />
    //     );
    //   },
    // },
    {
      dataField: "isAutomaticReport",
      text: t("Es reporte automático"),
      headerStyle: () => ({ width: "13.5%", fontSize: "12px" }),
      formatter: (cell, row) => {
        return (
          <Checkbox
            color="primary"
            checked={row.isAutomaticReport}
            onChange={(evt) =>
              handleCheck(evt.target.checked, row.id, "isAutomaticReport")
            }
          />
        );
      },
    },
    // {
    //   dataField: "isInputEvent",
    //   text: t("Es evento de entrada"),
    //   headerStyle: () => ({ width: "10%", fontSize: "12px" }),
    //   formatter: (cell, row) => {
    //     return (
    //       <Checkbox
    //         color="primary"
    //         checked={row.isInputEvent}
    //         onChange={(evt) =>
    //           handleCheck(evt.target.checked, row.id, "isInputEvent")
    //         }
    //       />
    //     );
    //   },
    // },
    // {
    //   dataField: "isOutputEvent",
    //   text: t("Es evento de salida"),
    //   headerStyle: () => ({ width: "10%", fontSize: "12px" }),
    //   formatter: (cell, row) => {
    //     return (
    //       <Checkbox
    //         color="primary"
    //         checked={row.isOutputEvent}
    //         onChange={(evt) =>
    //           handleCheck(evt.target.checked, row.id, "isOutputEvent")
    //         }
    //       />
    //     );
    //   },
    // },
    {
      dataField: "isIgnitionOn",
      text: t("Es ignition on"),
      headerStyle: () => ({ width: "13.5%", fontSize: "12px" }),
      formatter: (cell, row) => {
        return (
          <Checkbox
            color="primary"
            checked={row.isIgnitionOn}
            onChange={(evt) =>
              handleCheck(evt.target.checked, row.id, "isIgnitionOn")
            }
          />
        );
      },
    },
    {
      dataField: "isIgnitionOff",
      text: t("Es ignition off"),
      headerStyle: () => ({ width: "13.5%", fontSize: "12px" }),
      formatter: (cell, row) => {
        return (
          <Checkbox
            color="primary"
            checked={row.isIgnitionOff}
            onChange={(evt) =>
              handleCheck(evt.target.checked, row.id, "isIgnitionOff")
            }
          />
        );
      },
    },
    {
      dataField: "isOpenDoor",
      text: t("Es puerta abierta"),
      headerStyle: () => ({ width: "13.5%", fontSize: "12px" }),
      formatter: (cell, row) => {
        return (
          <Checkbox
            color="primary"
            checked={row.isOpenDoor}
            onChange={(evt) =>
              handleCheck(evt.target.checked, row.id, "isOpenDoor")
            }
          />
        );
      },
    },
    // {
    //   dataField: "isSoftwareEvent",
    //   text: t("Es evento software"),
    //   headerStyle: () => ({ width: "10%", fontSize: "12px" }),
    //   formatter: (cell, row) => {
    //     return (
    //       <Checkbox
    //         color="primary"
    //         checked={row.isSoftwareEvent}
    //         onChange={(evt) =>
    //           handleCheck(evt.target.checked, row.id, "isSoftwareEvent")
    //         }
    //       />
    //     );
    //   },
    // },
  ];

  const customTotal = (from, to, size) => (
    <span
      className="react-bootstrap-table-pagination-total customTotalDisplay"
      style={{ marginLeft: "15px" }}
    >
      {t("manage_device_modal_configuracion_evento_mostrar_resultados", {
        from,
        to,
        size,
      })}
    </span>
  );

  const options = {
    // Tooltips botones de paginación
    prePageTitle: t("pagina previa"),
    nextPageTitle: t("próxima página"),
    firstPageTitle: t("primer página"),
    lastPageTitle: t("última página"),
    showTotal: true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: paginationSizes,
    //totalSize: total,
  };

  const updateRow = () => {
    const index = configurationEvents.findIndex(
      (ce) => ce.id === editingRow.id
    );
    let confEventToUpdate = configurationEvents[index];
    confEventToUpdate = {
      ...confEventToUpdate,
      typeEvent: {
        id: editingRow.tipoEvento.value,
        description: editingRow.tipoEvento.label,
      },
      value: editingRow.value,
      status: "modified",
    };
    setConfigurationEvents([
      ...configurationEvents.slice(0, index),
      confEventToUpdate,
      ...configurationEvents.slice(index + 1),
    ]);
  };

  const addRow = () => {
    const maxId =
      configurationEvents.length === 0
        ? 0
        : Math.max.apply(
            Math,
            configurationEvents.map((ce) => ce.id)
          );
    setConfigurationEvents([
      ...configurationEvents,
      {
        typeEvent: {
          id: editingRow.tipoEvento.value,
          description: editingRow.tipoEvento.label,
        },
        value: editingRow.value,
        id: maxId + 1,
        status: "new",
        isAlarm: false,
        isMovementAlarm: false,
        isPositionRequest: false,
        isAutomaticReport: false,
        isInputEvent: false,
        isOutputEvent: false,
        isIgnitionOn: false,
        isIgnitionOff: false,
        isOpenDoor: false,
        isSoftwareEvent: false,
      },
    ]);
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setResultState({
      ...resultState,
      isOpen: false,
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Snackbar
        open={resultState.isOpen}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleCloseNotification}
          severity={resultState.type}
          variant="filled"
        >
          {resultState.msg}
        </MuiAlert>
      </Snackbar>
      <Dialog open={isOpen} onClose={closeModal} maxWidth="lg" fullWidth>
        <DialogTitle className="dialogTitle">{t("Eventos")}</DialogTitle>
        <DialogContent>
          <Grid container style={{ marginTop: "15px", marginBottom: "15px" }}>
            <Grid item xs={12} className="confEventButtonContainer">
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  if (destinyDevices.length === 0) {
                    getDevices({
                      variables: {
                        paginate: false,
                      },
                    });
                  }
                  setShowCloneEvent(true);
                }}
              >
                {t("Clonar Eventos")}
              </Button>
              <Button
                color="primary"
                variant="contained"
                style={{ marginLeft: "10px" }}
                onClick={() => setShowEditor(true)}
              >
                {t("Nuevo Evento")}
              </Button>
            </Grid>

            <div
              className="editorConfEvento"
              style={{ display: showCloneEvent ? "inline-flex" : "none" }}
            >
              <Grid item xs={6} style={{ padding: "10px" }}>
                <FormGroup>
                  <label className="formLabelFormik">{`${t(
                    "Equipo Origen"
                  )}: ${deviceIdentifier}`}</label>
                </FormGroup>
                <FormGroup>
                  <GenericSelector
                    callbackOptionSelected={(value) =>
                      setDestinyDeviceSelected(value)
                    }
                    columns={columnsDevices}
                    keyField="id"
                    labelField="deviceIdentifier"
                    title={t("Equipos de destino")}
                    data={destinyDevices}
                  />
                </FormGroup>
                <Button
                  color="primary"
                  variant="contained"
                  style={{ width: "45%" }}
                  onClick={() => {
                    setShowCloneEvent(false);
                  }}
                >
                  {t("Cancelar")}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleClonar}
                  style={{ width: "45%", marginLeft: "10px" }}
                >
                  {t("Clonar")}
                </Button>
              </Grid>
            </div>

            <div
              className="editorConfEvento"
              style={{ display: showEditor ? "inline-flex" : "none" }}
            >
              <Grid item xs={4} style={{ padding: "10px" }}>
                <FormGroup>
                  <label className="formLabelFormik">{t("Evento")}</label>
                  <Select
                    placeholder="Evento..."
                    options={tipoEventos}
                    value={editingRow?.tipoEvento}
                    onChange={(option) => {
                      setEditingRow({
                        ...editingRow,
                        tipoEvento: option,
                      });
                    }}
                    styles={CustomSelectStyles}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={4} style={{ padding: "10px" }}>
                <FormGroup>
                  <label className="formLabelFormik">{t("Valor")}</label>
                  <TextField
                    //placeholder="valor"
                    variant="filled"
                    value={editingRow?.value}
                    onChange={(evt) =>
                      setEditingRow({
                        ...editingRow,
                        value: evt.target.value,
                      })
                    }
                    size="small"
                    //style={{ height: "39px", paddingBottom: "4px" }}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={4} style={{ paddingTop: "50px" }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    editingRow.id === -1 ? addRow() : updateRow();
                    setEditingRow({
                      tipoEvento: { value: -1, label: "" },
                      value: "",
                      id: -1,
                    });
                    setShowEditor(false);
                  }}
                  //style={{ width: "45%" }}
                >
                  {t("Guardar")}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  style={{ marginLeft: "10px" }}
                  onClick={() => {
                    setEditingRow({
                      tipoEvento: { value: -1, label: "" },
                      value: "",
                      id: -1,
                    });
                    setShowEditor(false);
                  }}
                >
                  {t("Cancelar")}
                </Button>
              </Grid>
            </div>
          </Grid>
          <BootstrapTable
            keyField="id"
            ref={(n) => {
              //console.log("ref: ", n);
              tableRef.current = n;
            }}
            data={configurationEvents.filter((ce) => ce.status !== "deleted")}
            columns={columns}
            bootstrap4
            //headerClasses="thead-light"
            pagination={paginationFactory(options)}
            //totalSize={total}
            //onTableChange={handleTableChange}
            headerClasses="remoteTableHeader"
            bodyClasses="remoteTableBody"
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => closeModal()}
          >
            {t("Cancelar")}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              saveConfEventos({
                variables: {
                  deviceId: deviceId,
                  confEvents: configurationEvents.map((ce) => ({
                    id: ce.id,
                    value: ce.value,
                    typeEventId: ce.typeEvent.id,
                    isAlarm: ce.isAlarm,
                    isMovementAlarm: ce.isMovementAlarm,
                    isPositionRequest: ce.isPositionRequest,
                    isAutomaticReport: ce.isAutomaticReport,
                    isInputEvent: ce.isInputEvent,
                    isOutputEvent: ce.isOutputEvent,
                    isIgnitionOn: ce.isIgnitionOn,
                    isIgnitionOff: ce.isIgnitionOff,
                    isOpenDoor: ce.isOpenDoor,
                    isSoftwareEvent: ce.isSoftwareEvent,
                    status: ce.status,
                  })),
                },
              });
              closeModal();
            }}
          >
            {t("Guardar")}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default ModalConfiguracionEvento;
