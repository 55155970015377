import React from "react";

import { Formik } from "formik";

import { Button, FormGroup, Grid, FormControlLabel } from "@material-ui/core";
import { useTranslation } from "../../../lang";
import FileUpload from "../../../components/Base/FileUpload";

const ClientGeofenceKMLForm = ({
  setIsOpen,
  importGeofences,
  selectedCustomer,
}) => {
  const [t] = useTranslation();
  const initialValues = {
    kml: "",
    customerId: selectedCustomer?.id,
  };
  return (
    <>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={async (values) => {
          importGeofences({ variables: values });
        }}
      >
        {(props) => {
          const { values, handleSubmit, setFieldValue } = props;
          return (
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <FileUpload
                  onChange={(newKML) => {
                    setFieldValue("kml", newKML);
                  }}
                  title={t("Seleccione KML")}
                  file={values?.kml}
                  key="kml"
                />
              </FormGroup>
              <Grid item lg={12} style={{ marginTop: "50px" }}>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  tabIndex="0"
                  disabled={!values?.kml}
                  // style={{
                  //   backgroundColor: !values?.kml
                  //     ? "red !important"
                  //     : undefined,
                  // }}
                >
                  {t("Importar")}
                </Button>
                <Button
                  variant="contained"
                  type="button"
                  tabIndex="0"
                  style={{ marginLeft: "20px" }}
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  {t("Cancelar")}
                </Button>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default ClientGeofenceKMLForm;
