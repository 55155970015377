import gql from "graphql-tag";

const GET_AVAILABLELINES_QUERY = gql`
  query AvailableLines {
    availableLines {
      id
      lineNumber
    }
  }
`;

export default GET_AVAILABLELINES_QUERY;
