import React from "react";
import FormSeguimientoCarga from "../components/CargoTracking/Form";
import ConnectedLogin from "../containers/ConnectedLogin";
import {
  LoginContainer,
  LoginContainerPanel,
} from "./../components/LoginContainer";
import "./LoginPage.scss";

const DISPLAY_CARGO_TRACKING_FORM =
  process.env.REACT_APP_DISPLAY_CARGO_TRACKING_FORM_ON_LOGIN_PAGE === "true";

export default class LoginPage extends React.Component {
  render() {
    // console.log("DISPLAY_CARGO_TRACKING_FORM: ", DISPLAY_CARGO_TRACKING_FORM);

    /*return (
            <Structure>
                <MainContainer centerContent>
                    <ConnectedLogin />
                </MainContainer>
            </Structure>
        );*/
    return (
      <div className="fullContainer">
        <LoginContainer>
          <LoginContainerPanel>
            <ConnectedLogin />
          </LoginContainerPanel>

          {DISPLAY_CARGO_TRACKING_FORM && (
            <LoginContainerPanel>
              <FormSeguimientoCarga />
            </LoginContainerPanel>
          )}
        </LoginContainer>
      </div>
    );
  }
}
