import React from "react";

const MinivanIcon = () => {
  return (
    <div style={{ justifyContent: "center" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="99.442"
        height="99.443"
        x="0"
        y="0"
        enableBackground="new 0 0 99.442 99.443"
        version="1.1"
        viewBox="0 0 99.442 99.443"
        xmlSpace="preserve"
      >
        <path d="M19.097 54.071a7.56 7.56 0 00-7.561 7.56c0 .324.026.641.066.951.469 3.729 3.642 6.611 7.494 6.611a7.558 7.558 0 007.53-6.916c.018-.214.033-.428.033-.646a7.558 7.558 0 00-7.562-7.56zm-3.787 4.711l1.543 1.543a2.555 2.555 0 00-.274.665h-2.174a4.71 4.71 0 01.905-2.208zm-.913 3.516h2.189c.062.233.153.454.274.656L15.314 64.5a4.745 4.745 0 01-.917-2.202zm4.046 4.03a4.692 4.692 0 01-2.193-.908l1.537-1.538c.202.118.424.205.656.266v2.18zm0-7.212a2.608 2.608 0 00-.668.279l-1.541-1.541c.633-.48 1.385-.8 2.209-.913v2.175zm1.309-2.175a4.676 4.676 0 012.209.914l-1.54 1.54a2.565 2.565 0 00-.669-.279v-2.175zm0 9.387v-2.182c.233-.061.454-.147.657-.268l1.538 1.54a4.698 4.698 0 01-2.195.91zm3.133-1.824l-1.551-1.551c.12-.203.22-.42.282-.655h2.172a4.7 4.7 0 01-.903 2.206zm-1.268-3.514a2.555 2.555 0 00-.274-.665l1.543-1.543a4.7 4.7 0 01.905 2.208h-2.174zM83.965 54.071a7.56 7.56 0 00-7.561 7.56c0 .324.025.641.065.951.468 3.729 3.643 6.611 7.494 6.611 3.958 0 7.201-3.042 7.53-6.916.018-.214.031-.428.031-.646a7.556 7.556 0 00-7.559-7.56zm-3.788 4.711l1.543 1.543c-.12.206-.214.429-.273.665h-2.175a4.71 4.71 0 01.905-2.208zm-.912 3.516h2.19c.062.233.152.454.272.656L80.182 64.5a4.724 4.724 0 01-.917-2.202zm4.045 4.03a4.689 4.689 0 01-2.192-.908l1.537-1.538c.201.118.424.205.655.266v2.18zm0-7.212a2.644 2.644 0 00-.669.279L81.1 57.854c.634-.48 1.385-.8 2.209-.913l.001 2.175zm1.31-2.175a4.689 4.689 0 012.209.914l-1.541 1.54a2.587 2.587 0 00-.668-.279v-2.175zm0 9.387v-2.182c.231-.061.454-.147.655-.268l1.538 1.54a4.695 4.695 0 01-2.193.91zm3.132-1.824l-1.551-1.551c.12-.203.22-.42.281-.655h2.174a4.722 4.722 0 01-.904 2.206zm-1.269-3.514a2.585 2.585 0 00-.272-.665l1.542-1.543c.478.633.792 1.383.906 2.208h-2.176z"></path>
        <path d="M99.091 47.939a10.35 10.35 0 00-1.339-4.754l-5.43-9.581A5.274 5.274 0 0088 30.94c-9.1-.456-37.002-1.618-45.786.744-5.942 1.599-20.936 11.456-20.936 11.456S4.781 45.695 1.634 53.219c0 0-1.358.793-1.605 2.826-.127 1.046.183 2.634.525 3.965a3.768 3.768 0 003.067 2.783l7.16 1.122a8.656 8.656 0 01-.323-2.285c0-4.763 3.875-8.637 8.639-8.637 4.765 0 8.64 3.874 8.64 8.637 0 .249-.016.493-.036.735a8.513 8.513 0 01-.567 2.408l.842.045 47.568-1.287a8.764 8.764 0 01-.219-1.901c0-4.763 3.875-8.637 8.638-8.637 4.765 0 8.64 3.874 8.64 8.637 0 .249-.016.493-.037.735-.013.16-.041.315-.062.473L96.609 62a3.538 3.538 0 002.831-3.589l-.349-10.472zM71.715 32.71l1.093 10.911-16.774.686V32.655c4.904-.113 10.502-.062 15.681.055zM29.387 45.087l-1.659.093a1.018 1.018 0 01-.658-1.839c.975-.712 2.169-1.563 3.499-2.462v2.784c-.81.685-1.182 1.424-1.182 1.424zm4.111-2.554c-.105.004-.191.03-.291.04V39.15c3.382-2.144 7.215-4.273 10.511-5.34 1.5-.485 4.236-.795 7.636-.98v11.668l-14.412.589s-.5-2.664-3.444-2.554zm58.27-1.058a2.94 2.94 0 01-2.426 1.471l-11.852.484-1.062-10.594c4.898.149 8.99.333 11.063.445a2.732 2.732 0 012.271 1.454l2.057 3.903a2.94 2.94 0 01-.051 2.837z"></path>
      </svg>
    </div>
  );
};

export default React.memo(MinivanIcon);
