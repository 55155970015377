import React from "react";
import { useTranslation } from "../../lang";
import { hasPermission } from "../../utils/generalUtils";

const FlotaState = ({ data, onChangeType, user }) => {
  const [t] = useTranslation();
  const cantSuccess = data.filter((x) => x.typ === 0).length;
  const cantLowConnection = data.filter((x) => x.typ === 1).length;
  const cantDanger = data.filter((x) => x.typ === 2).length;
  const cantAlert = data.filter((x) => x.typ === 3).length;
  const cantInactive = data.filter((x) => x.typ === 4).length;
  const cantPerson = data.filter((x) => ![0, 1, 2, 3, 4].includes(x.typ))
    .length;

  /*else if (type == 1) return "low-connection";
  else if (type == 2) return "danger";
  else if (type == 3) return "alert";
  else if (type == 4) return "inactive";
  else return "person";*/

  return (
    <div className="flotaStateContainer">
      <div
        className="countIndicator typeAll"
        onClick={() => onChangeType(-1)}
        title={t("Total Flota")}
      >
        {/* <span className="lblIndicator">{data.length}</span> */}
        <span className="lblIndicator">
          {cantSuccess +
            cantLowConnection +
            cantDanger +
            cantAlert +
            cantInactive +
            cantPerson}
        </span>
      </div>
      <div
        className="countIndicator typeSuccess"
        onClick={() => onChangeType(0)}
        title={t("Ignition ON")}
      >
        <span className="lblIndicator">{cantSuccess}</span>
      </div>
      <div
        className="countIndicator typeLowConnection"
        onClick={() => onChangeType(1)}
        title={t("Ignition OFF - En movimiento")}
      >
        <span className="lblIndicator">{cantLowConnection}</span>
      </div>
      <div
        className="countIndicator typeDanger"
        onClick={() => onChangeType(2)}
        title={t("Ignition OFF - Detenido")}
      >
        <span className="lblIndicator">{cantDanger}</span>
      </div>
      <div
        className="countIndicator typeAlert"
        onClick={() => onChangeType(3)}
        title={t("Sin configuración de ignición")}
      >
        <span className="lblIndicator">{cantAlert}</span>
      </div>
      <div
        className="countIndicator typeInactive"
        onClick={() => onChangeType(4)}
        title={t("Sin transmisión por x minutos o más", {
          minutos: Math.round(process.env.REACT_APP_TIEMPOTX),
        })}
      >
        <span className="lblIndicator">{cantInactive}</span>
      </div>

      {hasPermission("MostrarCuentaDePersonasEnEstadoDeFlota", user.roles) && (
        <div
          className="countIndicator typePerson"
          onClick={() => onChangeType(5)}
          title={t("Persona")}
        >
          <span className="lblIndicator">{cantPerson}</span>
        </div>
      )}
    </div>
  );
};

export default FlotaState;
