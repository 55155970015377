import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const DivisionJourneys = function () {
  return (
    <SvgIcon className="division-journeys-steps-svg">
      <rect
        data-name="Fin de tramo"
        width="5"
        height="10"
        rx="2"
        transform="translate(0 47)"
        fill="#6e9aff"
      />
      <line
        data-name="Union tramo"
        y2="36"
        transform="translate(2.5 10.5)"
        fill="none"
        stroke="#6e9aff"
        strokeWidth="1"
      />
      <rect
        data-name="Inicio de tramo"
        width="5"
        height="10"
        rx="2"
        fill="#2b6cdb"
      />
    </SvgIcon>
  );
};

export default DivisionJourneys;
