import { connect } from "react-redux";

import {
  fetchMarkerJourney,
  fetchMarkerNotification,
  fetchMarkerRevisions,
} from "../../redux/markers/api";
import {
  toggleFillMarkerJourney,
  toggleFillMarkerJourney2,
  toggleFillMarkerJourneyPersonalizado,
} from "../../redux/fillMarkerJourney/actions";
import {
  toggleActivePanel,
  toggleActivePanelAux,
  onChangeTab,
} from "../../redux/lateralPanel/actions";
import { toggleFillMarkerInfoAux } from "../../redux/fillMarkerInfo/actions";
import { toggleFillGenericPopup } from "../../redux/genericPopup/actions";
import MarkerInfo from "../../pages/Map/MarkerInfo";

const _mapStateToProps = (
  { lateralPanel, markerInfo, markerJourney },
  { matchProps }
) => {
  const { params: { markerId } = {} } = matchProps;

  // This should go to the store so every component can access it properly
  return {
    markerInfo: markerInfo,
    markerJourney: markerJourney.markerJourney,
    markerNotifications: fetchMarkerNotification({ markerId }),
    markerRevisions: fetchMarkerRevisions({ markerId }),
    panelAuxInfo: lateralPanel.panelAuxInfo,
    isPanelAuxOpen: lateralPanel.isPanelAuxOpen,
    panelType: lateralPanel.panelType,
  };
};

const _mapDispatchToProps = {
  toggleActivePanel,
  toggleActivePanelAux,
  onChangeTab,
  toggleFillMarkerInfoAux,
  toggleFillMarkerJourney2,
  toggleFillMarkerJourney,
  toggleFillMarkerJourneyPersonalizado,
  toggleFillGenericPopup,
};

export default connect(_mapStateToProps, _mapDispatchToProps)(MarkerInfo);

// Should create a MarkerInfoNavbar that has a component for the content of the navbar
// with all the info we need and that renders the Navbar wrapping that component
