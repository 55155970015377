import React from "react";
import PropTypes from "prop-types";
import DateFnsUtils from "@date-io/date-fns";

import {
  DatePicker,
  DateTimePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import "./ReduxDateFields.scss";

export const ReduxDateField = ({
  label,
  placeholder,
  input,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <DatePicker
      label={label}
      placeholder={placeholder}
      error={touched && invalid}
      helperText={touched && error}
      InputLabelProps={{ shrink: true }}
      {...input}
      {...custom}
    />
  </MuiPickersUtilsProvider>
);

ReduxDateField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  input: PropTypes.any.isRequired,
  meta: PropTypes.object,
};

ReduxDateField.defaultProps = {
  label: "",
  placeholder: "",
  meta: {},
};

export const ReduxDateTimeField = ({
  label,
  placeholder,
  input,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <DateTimePicker
      label={label}
      placeholder={placeholder}
      error={touched && invalid}
      helperText={touched && error}
      InputLabelProps={{ shrink: true }}
      {...input}
      {...custom}
    />
  </MuiPickersUtilsProvider>
);
ReduxDateTimeField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  input: PropTypes.any.isRequired,
  meta: PropTypes.object,
};

ReduxDateTimeField.defaultProps = {
  label: "",
  placeholder: "",
  meta: {},
};

export const ReduxKeyboardDateField = ({
  label,
  placeholder,
  input,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <KeyboardDatePicker
      label={label}
      placeholder={placeholder}
      error={touched && invalid}
      helperText={touched && error}
      InputLabelProps={{ shrink: true }}
      {...input}
      {...custom}
      KeyboardButtonProps={{
        "aria-label": "change date",
      }}
    />
  </MuiPickersUtilsProvider>
);

ReduxKeyboardDateField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  input: PropTypes.any,
  meta: PropTypes.object,
};

ReduxKeyboardDateField.defaultProps = {
  label: "",
  placeholder: "",
  meta: {},
};
