import gql from "graphql-tag";

const SAVE_CONFEVENTOS_MUTATION = gql`
  mutation SaveConfigurationEventMany(
    $deviceId: Int!
    $confEvents: [ConfigurationEventInput]
  ) {
    saveConfigurationEventMany(deviceId: $deviceId, confEvents: $confEvents)
  }
`;

export default SAVE_CONFEVENTOS_MUTATION;
