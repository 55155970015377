import gql from "graphql-tag";

const GET_ASSET_EXPORT_QUERY = gql`
  query AssetsByGroupsExport(
    $groupIDs: [Int]
    $searchText: String
    $sort: AssetSortInput
    $format: FormatEnum
  ) {
    assetsByGroupsExport(
      groupIDs: $groupIDs
      searchText: $searchText
      sort: $sort
      format: $format
    )
  }
`;

export default GET_ASSET_EXPORT_QUERY;
