import gql from "graphql-tag";

const GET_DEVICETYPES_QUERY = gql`
  query DeviceTypes {
    deviceTypes {
      id
      description
    }
  }
`;

export default GET_DEVICETYPES_QUERY;
