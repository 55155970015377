import { useMutation } from "@apollo/client";
import {
  Button,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { Formik } from "formik";
import React, { useState } from "react";
import { connect } from "react-redux";
import * as Yup from "yup";
import UPDATE_USER_LANGUAGE_MUTATION from "../../graphql/mutations/updateUserLanguageMutation";
import {
  getLabelFromLangID,
  useChangeLanguage,
  useTranslation,
} from "../../lang";
import { setCurrentUser } from "../../redux/user/actions";

const Form = ({ closeModal, _setCurrentUser }) => {
  const [AVAILABLE_LANGS, changeLanguage] = useChangeLanguage();
  const [t] = useTranslation();
  const user = JSON.parse(localStorage.getItem("loggedUser"));

  const [language, setLanguage] = useState(user.language);
  const [resultState, setResultState] = useState({
    isOpen: false,
    type: "success",
    msg: "",
  });

  const [updateUser] = useMutation(UPDATE_USER_LANGUAGE_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Idioma actualizado correctamente"),
      });
    },
    onError(err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: t(err.message),
      });
    },
  });

  const initialValues = {
    language: language,
  };

  const yupSchema = Yup.object().shape({
    language: Yup.string().required("Requerido"),
  });

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setResultState({
      ...resultState,
      isOpen: false,
    });
  };

  const selectNewLanguage = (language) => {
    switch (language) {
      case "US":
        changeLanguage(AVAILABLE_LANGS.EN_US);
        break;
      case "ES":
        changeLanguage(AVAILABLE_LANGS.ES_SP);
        break;
      case "ES_US":
        changeLanguage(AVAILABLE_LANGS.ES_US);
        break;
      default:
        throw Error("Error. Selected language is not supported.");
    }
  };

  // const languages = [
  //   { lang: "ES", label: "Español (ES)" },
  //   { lang: "ES_US", label: "Español (US)" },
  //   { lang: "US", label: "Inglés" },
  // ];

  const availableLangs = process.env.REACT_APP_AVAILABLE_LANGS;

  const languages = availableLangs
    ? availableLangs.split(",").map((l) => l.trim())
    : ["ES", "ES_US", "US"];

  return (
    <>
      <Snackbar
        open={resultState.isOpen}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleCloseNotification}
          severity={resultState.type}
          variant="filled"
        >
          {resultState.msg}
        </MuiAlert>
      </Snackbar>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={yupSchema}
        onSubmit={async (values) => {
          await updateUser({
            variables: {
              userId: user.id,
              language: values.language,
            },
          });

          selectNewLanguage(values.language);

          const userUpdated = {
            ...user,
            language: values.language,
          };

          localStorage.setItem("loggedUser", JSON.stringify(userUpdated));

          _setCurrentUser(userUpdated);
        }}
      >
        {(props) => {
          const { handleSubmit } = props;
          return (
            <form onSubmit={handleSubmit}>
              <FormGroup component="language">
                <FormLabel component="legend">
                  {t("Seleccione idioma")}
                </FormLabel>
                <RadioGroup
                  aria-label="language"
                  value={language}
                  onChange={(evt) => setLanguage(evt.target.value)}
                >
                  {languages.map((l) => {
                    return (
                      <FormControlLabel
                        key={l}
                        value={l}
                        control={<Radio color="primary" />}
                        label={getLabelFromLangID(l)}
                      />
                    );
                  })}
                </RadioGroup>
              </FormGroup>

              <Grid
                item
                lg={12}
                style={{
                  marginTop: "50px",
                  textAlign: "center",
                }}
              >
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  tabIndex="0"
                >
                  {t("Guardar")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  tabIndex="0"
                  style={{ marginLeft: "20px" }}
                  onClick={() => {
                    closeModal();
                  }}
                >
                  {t("Cancelar")}
                </Button>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

const _mapStateToProps = ({ user }) => {
  return {
    user,
  };
};

const _mapDispatchToProps = (dispatch) => {
  return {
    _setCurrentUser: (param) => dispatch(setCurrentUser(param)),
  };
};

export default connect(_mapStateToProps, _mapDispatchToProps)(Form);
