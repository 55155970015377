import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const VerticalLine = function () {
  return (
    <SvgIcon className="division-vertical-line-journeys-steps-svg">
      <line
        y2="57"
        transform="translate(0.5)"
        fill="none"
        stroke="#707070"
        strokeWidth="1"
      />
    </SvgIcon>
  );
};

export default VerticalLine;
