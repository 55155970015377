// eslint-disable react/forbid-prop-types
import React, { Component } from "react";
import PropTypes from "prop-types";

import ConnectedLateralNavigation from "../../containers/ConnectedLateralNavigation";

import InfoContent from "../../components/Map/MarkerInfo/InfoContent";
import InfoContentExtra from "../../components/Map/MarkerInfo/InfoContentExtra";

export default class MapMarkerInfo extends Component {
  static propTypes = {
    markerInfo: PropTypes.object,
    markerJourney: PropTypes.object,
    markerNotifications: PropTypes.object,
    markerRevisions: PropTypes.object,
    panelAuxInfo: PropTypes.object,
    toggleActivePanel: PropTypes.func,
    toggleActivePanelAux: PropTypes.func,
    onChangeTab: PropTypes.func,
    isPanelAuxOpen: PropTypes.bool,
    toggleFillMarkerInfoAux: PropTypes.func,
    toggleFillMarkerJourney2: PropTypes.func,
    toggleFillMarkerJourney: PropTypes.func,
    toggleFillMarkerJourneyPersonalizado: PropTypes.func,
    toggleFillGenericPopup: PropTypes.func,
    panelType: PropTypes.string,
  };

  static defaultProps = {
    markerInfo: {},
    markerJourney: {},
    markerNotifications: {},
    markerRevisions: {},
    panelAuxInfo: {},
    toggleActivePanel: () => {},
    toggleActivePanelAux: () => {},
    onChangeTab: () => {},
    isPanelAuxOpen: false,
    toggleFillMarkerInfoAux: () => {},
    toggleFillMarkerJourney2: () => {},
    toggleFillMarkerJourney: () => {},
    toggleFillMarkerJourneyPersonalizado: () => {},
    toggleFillGenericPopup: () => {},
    panelType: "",
  };

  componentDidUpdate() {
    const { markerInfo } = this.props.markerInfo;
    const { toggleActivePanel, panelType } = this.props;
    if (Object.keys(markerInfo).length !== 0) {
      toggleActivePanel({
        isOpen: true,
      });
    }
  }

  render() {
    const { markerInfo } = this.props.markerInfo;
    const {
      markerJourney,
      markerNotifications,
      markerRevisions,
      toggleActivePanelAux,
      onChangeTab,
      toggleActivePanel,
      toggleFillMarkerInfoAux,
      panelAuxInfo: { panelAuxComponent, panelAuxTitle },
      isPanelAuxOpen,
      toggleFillMarkerJourney2,
      toggleFillMarkerJourney,
      toggleFillMarkerJourneyPersonalizado,
      toggleFillGenericPopup,
      panelType,
    } = this.props;

    return (
      <ConnectedLateralNavigation
        title={markerInfo.pat}
        panelAuxTitle={panelAuxTitle}
        panelCloseRedirectTo="/map"
        panelContent={
          <InfoContent
            markerInfo={markerInfo}
            markerJourney={markerJourney}
            markerNotifications={markerNotifications}
            markerRevisions={markerRevisions}
            toggleActivePanelAux={toggleActivePanelAux}
            onChangeTab={onChangeTab}
            toggleActivePanel={toggleActivePanel}
            toggleFillMarkerInfoAux={toggleFillMarkerInfoAux}
            toggleFillMarkerJourney2={toggleFillMarkerJourney2}
            toggleFillMarkerJourney={toggleFillMarkerJourney}
            toggleFillMarkerJourneyPersonalizado={
              toggleFillMarkerJourneyPersonalizado
            }
            toggleFillGenericPopup={toggleFillGenericPopup}
          />
        }
        panelAuxContent={
          <InfoContentExtra
            markerInfo={markerInfo}
            toggleActivePanelAux={toggleActivePanelAux}
            panelAuxComponent={panelAuxComponent}
          />
        }
      />
    );
  }
}
