import gql from "graphql-tag";

const DELETE_TECHNICIAN_MUTATION = gql`
  mutation DeleteTechnician($id: Int!) {
    deleteTechnician(id: $id) {
      id
    }
  }
`;

export default DELETE_TECHNICIAN_MUTATION;
