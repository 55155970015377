import gql from "graphql-tag";

const CHANGE_PROFILE_INFORMATION = gql`
  mutation UpdateUserProfileInformation(
    $userId: Int
    $firstName: String!
    $lastName: String!
    $photo: Upload!
  ) {
    updateUserProfileInformation(
      userId: $userId
      firstName: $firstName
      lastName: $lastName
      photo: $photo
    )
  }
`;

export default CHANGE_PROFILE_INFORMATION;
