import gql from "graphql-tag";

const GUIDE_NUMBERS_QUERY = gql`
  query GuideNumbers {
    guideNumbers {
      id
      GuideNumber
      assetId
      asset {
        id
        name
        licensePlate
      }
    }
  }
`;

export default GUIDE_NUMBERS_QUERY;
