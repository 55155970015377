import gql from "graphql-tag";

const GET_ASSET_EXTRAINFO_QUERY = gql`
  query Asset($assetId: Int!) {
    asset(assetId: $assetId) {
      id
      photo
      photoFull
    }
  }
`;

export default GET_ASSET_EXTRAINFO_QUERY;
