import React from "react";

import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";

import { ExpandMore } from "@material-ui/icons";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import ItemRevisionNotice from "./ItemRevisionNotice";

const theme = createMuiTheme({});

const ListRevisionNotice = ({
  mid,
  data = [],
  setMidSelected,
  setRevisionNoticeSelected,
  openPanelAux,
  changePanelAuxType,
  changePanelAuxTitle,
}) => {
  const assetList = data.map((a) => a.asset);
  const distictAssets = [...new Set(assetList.map((item) => item.id))];

  const HeaderAccordion = ({ id }) => {
    const asset = assetList.find((x) => x.id === id);
    const fullName = `${asset.name} - ${asset.licensePlate}`;
    return <label style={{ paddingTop: "12px" }}>{fullName}</label>;
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="listRevisionContainer">
        {mid ? (
          <Grid container>
            {data.map((x) => (
              <ItemRevisionNotice
                key={x}
                item={x}
                setMidSelected={setMidSelected}
                setRevisionNoticeSelected={setRevisionNoticeSelected}
                openPanelAux={openPanelAux}
                changePanelAuxType={changePanelAuxType}
                changePanelAuxTitle={changePanelAuxTitle}
              />
            ))}
          </Grid>
        ) : (
          distictAssets.map((a) => (
            <Accordion
              key={`accordion_${a}`}
              //onChange={() => handleExpandAsset(a)}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls={`panel_${a}_content`}
                id={`panel_${a}_header`}
              >
                <HeaderAccordion id={a} />
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  {data
                    .filter((d) => d.asset.id === a)
                    .map((x) => (
                      <ItemRevisionNotice
                        item={x}
                        setMidSelected={setMidSelected}
                        setRevisionNoticeSelected={setRevisionNoticeSelected}
                        openPanelAux={openPanelAux}
                        changePanelAuxType={changePanelAuxType}
                        changePanelAuxTitle={changePanelAuxTitle}
                      />
                    ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))
        )}
      </div>
    </ThemeProvider>
  );
};

export default ListRevisionNotice;
