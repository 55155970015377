import React, { useState } from "react";

export const DISTANCE_UNITS = {
    MILE: "mile",
    METER: "meter"
} 

const CONVERTION_TABLE = {
    [DISTANCE_UNITS.MILE]: {
        [DISTANCE_UNITS.METER]: 1609.34
    },
    [DISTANCE_UNITS.METER]: {
        [DISTANCE_UNITS.MILE]: 0.000621371
    }
}

export const convertDistanceUnit = (initialDistance, originalUnit, objectiveUnit) => {
    if(originalUnit === objectiveUnit){
        return initialDistance
    }
    
    return initialDistance * CONVERTION_TABLE[originalUnit][objectiveUnit]
} 