import gql from "graphql-tag";

const ASSETS_BYCUSTOMER_QUERY = gql`
  query AssetByCustomer($customerId: Int!) {
    getAssetsByCustomer(customerId: $customerId) {
      id
      name
      licensePlate
      internalNumber
      driver {
        firstName
        lastName
      }
      typeMobile {
        id
        description
      }
    }
  }
`;

export default ASSETS_BYCUSTOMER_QUERY;
