import gql from "graphql-tag";
const AUTO_LOGIN_MUTATION = gql`
  mutation autologin($token: String!) {
    autologin(token: $token) {
      token
      refToken
      user {
        id
        userName
        firstName
        lastName
        photo
        language
        customer {
          id
          name
        }
        roles {
          id
          name
          isOwner
          isAdmin
          permission {
            id
            name
          }
        }
      }
    }
  }
`;
export default AUTO_LOGIN_MUTATION;
