import React from "react";

import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

// @TODO rename import with a more semantic name
import KmlUploadForm from "./Form";

import { useTranslation } from "../../lang";

const theme = createMuiTheme({});

const ImportGeofenceFromKmlFileModal = ({ isOpen, onClose }) => {
  const [t] = useTranslation();

  return (
    <>
      <ThemeProvider theme={theme}>
        <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
          <DialogTitle className="dialogTitle">
            {t("Importar geocerca desde archivo KML")}
          </DialogTitle>
          <DialogContent className="dialogContent">
            <KmlUploadForm closeModal={onClose} />
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

export default ImportGeofenceFromKmlFileModal;
