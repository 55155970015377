import React, { useCallback, useState, useEffect } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import Structure from "../../../components/Base/Structure";
import { downloadExcel, downloadPDF } from "../../../utils/generalUtils";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import GET_DEVICES_QUERY from "../../../graphql/queries/devicesQuery";
import GET_DEVICES_EXPORT_QUERY from "../../../graphql/queries/devicesExportQuery";

import CREATE_DEVICE_MUTATION from "../../../graphql/mutations/createDeviceMutation";
import UPDATE_DEVICE_MUTATION from "../../../graphql/mutations/updateDeviceMutation";
import DELETE_DEVICE_MUTATION, {
  DELETE_DEVICE_MUTATION_AVAILABLE_ERRORS,
} from "../../../graphql/mutations/deleteDeviceMutation";
import ASOCIATE_LINES_MUTATION from "../../../graphql/mutations/asociateLinesMutation";

import TablaDevices from "./List";
import PageTitle from "../../../components/Base/PageTitle";
import "./Styles.scss";

import { useTranslation } from "../../../lang";

const paginationSizes = [10, 25, 50];

const ManageDevicePage = ({}) => {
  const [t] = useTranslation();
  const [deviceList, setDeviceList] = useState([]);
  const [paginationState, setPaginationState] = useState({
    pageSize: paginationSizes[0],
    pageNumber: 1,
    sort: undefined,
    searchText: "",
  });
  const [total, setTotal] = useState(0);
  const [resultState, setResultState] = useState({
    isOpen: false,
    type: "success",
    msg: "",
  });
  const [exportSelected, setExportSelected] = useState("EXCEL");
  const user = JSON.parse(localStorage.getItem("loggedUser"));
  const customersStr = localStorage.getItem(`customerSelected_${user.id}`);
  const customersSel = customersStr
    ? JSON.parse(customersStr)
    : [{ label: "Todos", value: -1 }];

  const [fetchDevices] = useLazyQuery(GET_DEVICES_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      setDeviceList(d.devicesPaginated.items);
      setTotal(d.devicesPaginated.count);
    },
  });
  const [fetchDevicesExport] = useLazyQuery(GET_DEVICES_EXPORT_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      const filename = t("Equipos"); //ver en los global
      switch (exportSelected) {
        case "EXCEL":
          downloadExcel(d.devicesPaginatedExport, filename);
          break;
        case "PDF":
          downloadPDF(d.devicesPaginatedExport, filename);
          break;
      }
    },
  });

  const [createDevice] = useMutation(CREATE_DEVICE_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Equipo creado correctamente"),
      });
      handleGetDevices();
    },
    onError(e) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: t("Ocurrió un error_ Intente nuevamente"),
      });
    },
  });

  const [updateDevice] = useMutation(UPDATE_DEVICE_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Equipo actualizado correctamente"),
      });
      handleGetDevices();
    },
    onError(e) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: t("Ocurrió un error_ Intente nuevamente"),
      });
    },
  });

  const [deleteDevice] = useMutation(DELETE_DEVICE_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Equipo eliminado correctamente"),
      });
      handleGetDevices();
    },
    onError({ graphQLErrors, networkError }) {
      const error_codes = graphQLErrors.map((error) => error.extensions.code);
      if (
        error_codes.includes(
          DELETE_DEVICE_MUTATION_AVAILABLE_ERRORS.CANT_DELETE_DEVICE_ASSIGNED_TO_ASSET
        )
      ) {
        setResultState({
          isOpen: true,
          type: "error",
          msg: t(
            "El dispositivo no puede ser eliminado porque se encuentra asignado a un asset"
          ),
        });
      } else {
        setResultState({
          isOpen: true,
          type: "error",
          msg: t("Ocurrió un error_ Intente nuevamente"),
        });
      }
    },
  });

  const [asociateLines] = useMutation(ASOCIATE_LINES_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Líneas asociadas correctamente"),
      });
      handleGetDevices();
    },
    onError(e) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: t("Ocurrió un error_ Intente nuevamente"),
      });
    },
  });

  const handleGetDevices = () => {
    fetchDevices({
      variables: {
        ...paginationState,
        paginate: true,
        customerIds: customersSel.map((c) => c.value),
      },
    });
  };

  const handleGetDevicesExport = (format) => {
    setExportSelected(format);
    fetchDevicesExport({
      variables: {
        sort: paginationState.sort,
        searchText: paginationState.searchText,
        customerIds: customersSel.map((c) => c.value),
        format: format,
      },
    });
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setResultState({
      ...resultState,
      isOpen: false,
    });
  };

  useEffect(() => {
    handleGetDevices();
  }, [paginationState]);

  return (
    <Structure>
      <Snackbar
        open={resultState.isOpen}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleCloseNotification}
          severity={resultState.type}
          variant="filled"
        >
          {resultState.msg}
        </MuiAlert>
      </Snackbar>
      <PageTitle sector={t("Administración")} title={t("Equipos")} />
      <TablaDevices
        getDevices={handleGetDevices}
        deviceList={deviceList}
        paginationSizes={paginationSizes}
        paginationState={paginationState}
        setPaginationState={setPaginationState}
        total={total}
        //setResultState={setResultState}
        createDevice={createDevice}
        updateDevice={updateDevice}
        deleteDevice={deleteDevice}
        asociateLines={asociateLines}
        getDevicesExport={handleGetDevicesExport}
      />
    </Structure>
  );
};

export default ManageDevicePage;
