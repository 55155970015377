import gql from "graphql-tag";

const DELETE_POI_MUTATION = gql`
  mutation DeletePoi($id: Int!) {
    deletePoi(id: $id) {
      id
    }
  }
`;

export default DELETE_POI_MUTATION;
