import React, { useState } from "react";
import { connect } from "react-redux";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
} from "@material-ui/core";

import { useLazyQuery } from "@apollo/client";

import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

import JOURNEYPOSITIONS_PDF_QUERY from "../../graphql/queries/journeyPositionsPDF";
import JOURNEYPOSITIONS_EXCEL_QUERY from "../../graphql/queries/journeyPositionsExcelQuery";
import JOURNEYPOSITIONS_KML_QUERY from "../../graphql/queries/journeyPositionsKMLQuery";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { format } from "date-fns";

import IconExcel from "../../public/iconExcel.png";
import IconPDF from "../../public/iconPDF.png";
import IconKML from "../../public/iconKML.png";
import MapIcon from "@material-ui/icons/Map";
import MiniMap from "../../components/Base/MiniMap";

import "../Base/RemoteTable.scss";

import { useTranslation } from "../../lang";
import {
  downloadExcel,
  downloadKML,
  downloadPDF,
  GetDateFormat,
} from "../../utils/generalUtils";

// const theme = createMuiTheme({});

const ModalJourneyPosition = ({
  isOpen,
  closeModal,
  data = [],
  movilID,
  fechaInicio,
  fechaFin,
  user,
}) => {
  const [t] = useTranslation();

  const [isMiniMapOpen, setIsMiniMapOpen] = useState(false);
  const [miniMapData, setMiniMapData] = useState({
    lat: 0,
    lon: 0,
    title: "",
    tooltipText: "",
  });
  const closeMiniMap = () => setIsMiniMapOpen(false);

  const [exportPDF] = useLazyQuery(JOURNEYPOSITIONS_PDF_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      downloadPDF(d.journeyPositionsPDF, `${t("ListadoPosiciones")}`);
    },
  });

  const [exportExcel] = useLazyQuery(JOURNEYPOSITIONS_EXCEL_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      downloadExcel(d.journeyPositionsExcel, `${t("ListadoPosiciones")}`);
    },
  });

  const [exportKML] = useLazyQuery(JOURNEYPOSITIONS_KML_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      downloadKML(d.journeyPositionsKML, `${t("ListadoPosiciones")}`);
    },
  });

  const paginationSizes = [10, 25, 50];

  const customTotal = (from, to, size) => (
    <span
      className="react-bootstrap-table-pagination-total customTotalDisplay"
      style={{ marginLeft: "15px" }}
    >
      {t("modal_journey_position_mostrar_resultados", {
        from,
        to,
        size,
      })}
    </span>
  );

  const options = {
    // Tooltips botones de paginación
    prePageTitle: t("pagina previa"),
    nextPageTitle: t("próxima página"),
    firstPageTitle: t("primer página"),
    lastPageTitle: t("última página"),
    showTotal: true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: paginationSizes,
    //totalSize: total,
  };

  const handleOpenMiniMap = (row) => {
    setMiniMapData({
      lat: row.lat,
      lon: row.lon,
      title: t("Evento"),
      tooltipText: `${row.evt} - ${row.fGPS}`,
    });
    setIsMiniMapOpen(true);
  };

  const columns = [
    {
      dataField: "nCh",
      text: t("Conductor"),
      headerStyle: () => ({ width: "15%", fontSize: "12px" }),
    },
    {
      dataField: "fGPS",
      text: t("Fecha"),
      headerStyle: () => ({ width: "15%", fontSize: "12px" }),
      formatter: (cell, row) => {
        return format(new Date(row.fGPS), GetDateFormat(user.language));
      },
    },
    {
      dataField: "evt",
      text: t("Evento"),
      headerStyle: () => ({ width: "15%", fontSize: "12px" }),
    },
    {
      dataField: "lat",
      text: t("Latitud"),
      headerStyle: () => ({ width: "10%", fontSize: "12px" }),
    },
    {
      dataField: "lon",
      text: t("Longitud"),
      headerStyle: () => ({ width: "10%", fontSize: "12px" }),
    },
    {
      dataField: "dir",
      text: t("Dirección"),
      headerStyle: () => ({ width: "20%", fontSize: "12px" }),
    },
    {
      dataField: "cur",
      text: t("Curso"),
      headerStyle: () => ({ width: "7%", fontSize: "12px" }),
      formatter: (cell, row) => {
        return t(row.cur);
      },
    },
    {
      dataField: "vel",
      text: t("Velocidad"),
      headerStyle: () => ({ width: "8%", fontSize: "12px" }),
    },
    {
      dataField: "id",
      text: t("Ver"),
      sort: true,
      headerStyle: () => ({
        width: "50px",
        fontSize: "12px",
        textAlign: "center",
      }),
      formatter: (cell, row) => (
        <IconButton
          style={{ padding: "0" }}
          onClick={() => handleOpenMiniMap(row)}
        >
          <MapIcon style={{ color: "white" }} />
        </IconButton>
      ),
    },
  ];

  return (
    // <ThemeProvider theme={theme}>
    <Dialog open={isOpen} onClose={closeModal} maxWidth="lg" fullWidth>
      <DialogTitle className="dialogTitle">{t("Posiciones")}</DialogTitle>
      <DialogContent className="dialogContent">
        <MiniMap
          isOpen={isMiniMapOpen}
          closeModal={closeMiniMap}
          {...miniMapData}
        />
        <BootstrapTable
          keyField="fGPS"
          columns={columns}
          data={data}
          bootstrap4
          pagination={paginationFactory(options)}
          headerClasses="remoteTableHeader"
          bodyClasses="remoteTableBody"
        />
      </DialogContent>
      <DialogActions className="dialogActions">
        <div style={{ marginRight: "auto" }}>
          <IconButton
            title={t("EXCEL")}
            onClick={() => {
              exportExcel({
                variables: {
                  movilID,
                  fechaInicio,
                  fechaFin,
                },
              });
            }}
          >
            <img alt="" src={IconExcel} className="exportIcon" />
          </IconButton>
          <IconButton
            title={t("PDF")}
            onClick={() => {
              exportPDF({
                variables: {
                  movilID,
                  fechaInicio,
                  fechaFin,
                },
              });
            }}
          >
            <img alt="" src={IconPDF} className="exportIcon" />
          </IconButton>
          <IconButton
            title={t("KML")}
            onClick={() => {
              exportKML({
                variables: {
                  movilID,
                  fechaInicio,
                  fechaFin,
                },
              });
            }}
          >
            <img alt="" src={IconKML} className="exportIcon" />
          </IconButton>
        </div>
        <Button
          // style={{ color: "white" }}
          color="primary"
          variant="contained"
          onClick={() => closeModal()}
        >
          {t("Cerrar")}
        </Button>
      </DialogActions>
    </Dialog>
    // </ThemeProvider>
  );
};

const _mapStateToProps = ({ user }) => {
  return {
    user,
  };
};

const _mapDispatchToProps = (dispatch) => {
  return {
    // _setCurrentUser: (param) => dispatch(setCurrentUser(param)),
  };
};

export default connect(
  _mapStateToProps,
  _mapDispatchToProps
)(ModalJourneyPosition);
