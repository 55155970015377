import gql from "graphql-tag";

const GET_POINT_QUERY = gql`
  query getPointApi($cliente: Int, $movilesID: [Int]) {
    getPointApi(cliente: $cliente, movilesID: $movilesID) {
      id
      lat
      lon
      pat
      nMov
      nCli
      nCh
      idCh
      vel
      cur
      eid
      mid
      tIgn
      dir
      odo
      sOdo
      horo
      fGPS
      utx
      tDet
      cid
      typ
      nInt
      fix
      alarm
      typevt {
        id
        description
      }
      evt
      tevtid
      evtDesc
      ibutton
      chid
      horoTIgn
      horoTDet
      tStatus
      roadStatus
      activeRouteName
      asset {
        itSupportsOBD2
        typeMobile {
          id
        }
      }
    }
  }
`;

export default GET_POINT_QUERY;
