import { useLazyQuery, useQuery } from "@apollo/client";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useState } from "react";
import { connect } from "react-redux";
import PageTitle from "../../../components/Base/PageTitle";
import Structure from "../../../components/Base/Structure";
import ASSETS_BYGROUPS_QUERY from "../../../graphql/queries/assetByGroupsQuery";
import ALL_ROADS_BY_CUSTOMER_IDS_QUERY from "../../../graphql/queries/allRoadsByCustomersIDs";
import GET_ASSET_IN_STOP_QUERY from "../../../graphql/queries/assetInStopQuery";
import STOPS_DETOUR_EXPORT_QUERY from "../../../graphql/queries/getStopsDetourReportExport";
import { useFilterValidation } from "../useFilterValidation";
import Filters from "./Filters";
import ReportResult from "./ReportResult";
import { useTranslation } from "../../../lang";
import { downloadExcel, downloadPDF } from "../../../utils/generalUtils";
import moment from "moment";

const DEFAULT_REPORT_RESULT = [];
const AVAILABLE_EXPORT_TYPES = {
  EXCEL: "EXCEL",
  PDF: "PDF",
};
const DEFAULT_EXPORT_TYPE = AVAILABLE_EXPORT_TYPES.EXCEL;
const RouteDetourReport = ({ selectedGroups, selectedCustomers, user }) => {
  const [t] = useTranslation();
  const [assetList, setAssetList] = useState([]);
  const [roadList, setRoadList] = useState([]);
  const [exportSelected, setExportSelected] = useState(DEFAULT_EXPORT_TYPE);
  const [assetTableState] = useState({
    searchText: "",
    sort: undefined,
    paginate: false,
  });

  const [reportResult, setReportResult] = useState(DEFAULT_REPORT_RESULT);

  const [resultState, setResultState] = useState({
    isOpen: false,
    type: "success",
    msg: "",
  });

  const {} = useQuery(ASSETS_BYGROUPS_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      groupIDs: selectedGroups.filter(
        (g) => g && !Number.isNaN(Number.parseInt(g))
      ),
      ...assetTableState,
    },
    onCompleted(d) {
      const { items } = d.assetsByGroups;
      setAssetList(items);
    },
  });

  const {} = useQuery(ALL_ROADS_BY_CUSTOMER_IDS_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      customerIDs:
        selectedCustomers && selectedCustomers.length > 0
          ? selectedCustomers.map((customer) => customer.value)
          : [],
    },
    onCompleted(d) {
      const roads = d.allRoadsByCustomersIDs.map((road) => road);
      setRoadList(roads);
    },
  });

  const [fetchReport] = useLazyQuery(GET_ASSET_IN_STOP_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      if (d.assetInStop) {
        setReportResult(d.assetInStop);
      } else {
        setResultState({
          isOpen: true,
          type: "info",
          msg: t("No se encontraron datos para su consulta"),
        });
      }
    },
    onError(err) {
      setReportResult(DEFAULT_REPORT_RESULT);
      setResultState({
        isOpen: true,
        type: "error",
        msg: err.message,
      });
    },
  });

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setResultState({
      ...resultState,
      isOpen: false,
    });
  };

  const handleSearchReportRouteDetour = ({
    roadId,
    assetId,
    dateTo,
    dateFrom,
  }) => {
    setReportResult(DEFAULT_REPORT_RESULT);
    fetchReport({
      variables: {
        roadId,
        assetId,
        dateTo,
        dateFrom,
      },
    });
  };

  const { handleSearchReport, errors: filterErrors } = useFilterValidation(
    handleSearchReportRouteDetour
  );

  const [getExport] = useLazyQuery(STOPS_DETOUR_EXPORT_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      const filename = t("ReporteDesviosDeParada");
      switch (exportSelected) {
        case "EXCEL":
          downloadExcel(d.stopReportExport, filename);
          break;
        case "PDF":
          downloadPDF(d.stopReportExport, filename);
          break;
      }
    },
  });

  const handleExportExcel = (assetId, roadId, dateFrom, dateTo) => {
    setExportSelected(AVAILABLE_EXPORT_TYPES.EXCEL);
    getExport({
      variables: {
        dateFrom,
        dateTo,
        assetId,
        roadId,
        format: AVAILABLE_EXPORT_TYPES.EXCEL,
      },
    });
  };

  const handleExportPDF = (assetId, roadId, dateFrom, dateTo) => {
    setExportSelected(AVAILABLE_EXPORT_TYPES.PDF);
    getExport({
      variables: {
        dateFrom,
        dateTo,
        assetId,
        roadId,
        format: AVAILABLE_EXPORT_TYPES.PDF,
      },
    });
  };

  const formatReportResultData = (reportData) => {
    const DATE_FORMAT = "HH:mm:ss";
    const UNKNOWN_VALUE = "---";

    const formatedReportData = reportData.map((stop) => {
      return {
        ...stop,
        timeInStop: stop.timeInStop
          ? Math.floor(stop.timeInStop)
          : UNKNOWN_VALUE,
        expectedEnterTime: stop.expectedEnterTime
          ? moment(stop.expectedEnterTime).format(DATE_FORMAT)
          : UNKNOWN_VALUE,
        actualEnterTime: stop.actualEnterTime
          ? moment(stop.actualEnterTime).format(DATE_FORMAT)
          : UNKNOWN_VALUE,
        actualExitTime: stop.actualExitTime
          ? moment(stop.actualExitTime).format(DATE_FORMAT)
          : UNKNOWN_VALUE,
      };
    });
    return formatedReportData;
  };

  return (
    <Structure>
      <Snackbar
        open={resultState.isOpen}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleCloseNotification}
          severity={resultState.type}
          variant="filled"
        >
          {resultState.msg}
        </MuiAlert>
      </Snackbar>
      <PageTitle sector={t("Estadísticas")} title={t("Desvios de Parada")} />
      <Filters
        assets={assetList}
        roads={roadList}
        searchReport={handleSearchReport}
        handleExportExcel={handleExportExcel}
        handleExportPDF={handleExportPDF}
        errors={filterErrors}
        reportResult={reportResult || null}
        user={user}
      />
      {reportResult && reportResult.length > 0 && (
        <ReportResult reportData={formatReportResultData(reportResult)} />
      )}
    </Structure>
  );
};

const _mapStateToProps = ({ groups, user }) => {
  return {
    selectedGroups: groups.selectedGroups,
    selectedCustomers: groups.selectedCustomers,
    user,
  };
};

export default connect(_mapStateToProps, null)(RouteDetourReport);
