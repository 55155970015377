import gql from "graphql-tag";

const CREATE_LINE_MUTATION = gql`
  mutation CreateLine(
    $lineNumber: String
    $SimNumber: String
    $customerId: Int!
    $commTypeId: Int!
    $email: String
    $deviceId: Int
  ) {
    createLine(
      lineNumber: $lineNumber
      SimNumber: $SimNumber
      customerId: $customerId
      commTypeId: $commTypeId
      email: $email
      deviceId: $deviceId
    ) {
      id
    }
  }
`;

export default CREATE_LINE_MUTATION;
