import React, { useEffect, useState } from "react";

import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import { Button } from "@material-ui/core";

import LineModal from "./LineForm";
import Table from "../../../components/Base/Table";

import ThreeDotMenu from "../../../components/Base/ThreeDotMenu";

import { useTranslation } from "../../../lang";

const List = ({
  paginationSizes,
  total,
  lineList,
  createLine,
  updateLine,
  getLines,
  deleteLine,
}) => {
  const [t] = useTranslation();

  const [openSide, setOpenSide] = useState(false);
  const [selectedItem, setSelectedItem] = useState();

  useEffect(() => {
    getLines();
  }, []);

  const confirmDelete = (id, name) => {
    confirmAlert({
      title: t("Eliminar Línea"),
      message: t("pages_manage_linea_seguro_eliminar_linea", { name }),
      buttons: [
        {
          label: t("Si"),
          onClick: () => {
            deleteLine(id);
          },
        },
        {
          label: t("No"),
          onClick: () => console.log("cancelar"),
        },
      ],
    });
  };

  const itemStyle = {
    fontSize: "12px",
  };

  const callbackSelectOption = (option, row) => {
    switch (option) {
      case 1:
        setSelectedItem(row);
        setOpenSide(true);
        break;
      case 2:
        confirmDelete(row.id, `${row.lineNumber}`);
        break;
    }
  };

  const columns = [
    {
      dataField: "id",
      text: "",
      headerStyle: () => ({ width: "50px", fontSize: "12px" }),
      formatter: (cell, row) => {
        return (
          <>
            <ThreeDotMenu
              key={row.id}
              row={row}
              callback={callbackSelectOption}
              options={[
                {
                  label: t("Editar"),
                  value: 1,
                },
                {
                  label: t("Eliminar"),
                  value: 2,
                },
              ]}
            />
          </>
        );
      },
    },
    {
      dataField: "lineNumber",
      text: t("Número"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
    },
    {
      dataField: "SimNumber",
      text: t("Número SIM"),
      headerStyle: () => ({ width: "15%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
    },
    {
      dataField: "customer.name",
      text: t("Cliente"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
    },
    {
      dataField: "commType.description",
      text: t("Tipo Comunicación"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
    },
    {
      dataField: "email",
      text: t("Email"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
    },
    {
      dataField: "device.deviceIdentifier",
      text: t("Equipo ID"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
    },
  ];

  const customTotal = (from, to, size) => (
    <span
      className="react-bootstrap-table-pagination-total customTotalDisplay"
      style={{ marginLeft: "15px" }}
    >
      {t("pages_manage_linea_mostrar_resultado", { from, to, size })}
    </span>
  );

  const options = {
    // Tooltips botones de paginación
    prePageTitle: t("Página previa"),
    nextPageTitle: t("Próxima página"),
    firstPageTitle: t("Primera página"),
    lastPageTitle: t("Última página"),
    showTotal: true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: paginationSizes,
    totalSize: total,
  };

  return (
    <div className="listContainer">
      <SlidingPane
        className="slidingPaneRight"
        isOpen={openSide}
        title={selectedItem ? t("Actualizar Línea") : t("Crear Línea")}
        width="400px"
        onRequestClose={() => {
          setOpenSide(false);
        }}
      >
        <LineModal
          setIsOpen={() => setOpenSide(false)}
          createLine={createLine}
          selectedLine={selectedItem}
          updateLine={updateLine}
        />
      </SlidingPane>
      <Table
        columns={columns}
        data={lineList}
        keyField="id"
        paginationOptions={options}
        lateralComponent={
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setSelectedItem(null);
              setOpenSide(true);
            }}
            style={{ float: "right" }}
          >
            {t("+ Línea")}
          </Button>
        }
      />
    </div>
  );
};

export default List;
