import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { Grid, Typography, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import { useLazyQuery, useMutation } from "@apollo/client";

import Structure from "../../../components/Base/Structure";

import GroupPanel from "./GroupPanel";
import AssetPanel from "./AssetPanel";
import UserPanel from "./UserPanel";

import GROUPS_BYCUSTOMER_QUERY from "../../../graphql/queries/groupsByCustomerQuery";
import CREATE_GROUP_MUTATION from "../../../graphql/mutations/createGroupMutation";
import UPDATE_GROUP_MUTATION from "../../../graphql/mutations/updateGroupMutation";
import DELETE_GROUP_MUTATION from "../../../graphql/mutations/deleteGroupMutation";
import ASSIGN_ASSET_MUTATION from "../../../graphql/mutations/assignAssetMutation";
import UNASSIGN_ASSET_MUTATION from "../../../graphql/mutations/unassignMutation";
import USERS_BYCUSTOMER_QUERY from "../../../graphql/queries/usersByCustomerQuery";
import ASSIGN_USER_MUTATION from "../../../graphql/mutations/assignUserMutation";
import UNASSIGN_USER_MUTATION from "../../../graphql/mutations/unassignUserMutation";
//import CREATE_USER_MUTATION from "../../../graphql/mutations/createUserMutation";

import "./styles.scss";
import { setSelectedGroups } from "../../../redux/groups/actions";
import PageTitle from "../../../components/Base/PageTitle";

import { useTranslation } from "../../../lang";

const ABMGroups = ({ selectedCustomers, user: loggedUser }) => {
  const [t] = useTranslation();
  const [groups, setGroups] = useState();
  const [users, setUsers] = useState();
  const [selectedGroup, setSelectedGroup] = useState();
  const [resultState, setResultState] = useState({
    isOpen: false,
    type: "success",
    msg: "",
  });

  const [fetchGroups] = useLazyQuery(GROUPS_BYCUSTOMER_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      setGroups(d.groupsByCustomer);
      if (selectedGroup) {
        setSelectedGroups(
          d.groupsByCustomer.filter((x) => x.id === selectedGroup.id)
        );
      }
    },
  });

  const handleFetchGroups = () => {
    let customerId;

    if (Array.isArray(selectedCustomers) && selectedCustomers.length > 0) {
      customerId = selectedCustomers[selectedCustomers.length - 1].value;
    }

    fetchGroups({
      variables: {
        customerId: customerId,
      },
    });
  };

  const [fetchUsers] = useLazyQuery(USERS_BYCUSTOMER_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      setUsers(d.usersByCustomer);
    },
  });

  const [createGroup] = useMutation(CREATE_GROUP_MUTATION, {
    onCompleted(d) {
      setGroups([...groups, d.createGroup]);
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Grupo creado correctamente"),
      });
    },
    onError(err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: err.message,
      });
    },
  });

  const [assignAsset] = useMutation(ASSIGN_ASSET_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Móviles asignados correctamente"),
      });

      // handleFetchGroups();
    },
  });

  const [assignUser] = useMutation(ASSIGN_USER_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Usuarios asignados correctamente"),
      });

      // handleFetchGroups();
    },
    onError(error) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: error.message,
      });
    },
  });

  const [unassignUser] = useMutation(UNASSIGN_USER_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Usuarios quitados correctamente"),
      });

      // handleFetchGroups();
    },
    onError(error) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: error.message,
      });
    },
  });

  const [unassignAsset] = useMutation(UNASSIGN_ASSET_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Móviles desasignados correctamente"),
      });

      // handleFetchGroups();
    },
  });

  const handleAssignUser = (group, users) => {
    const userIds = users.map((u) => u.id);
    const groupId = group.id;
    assignUser({
      variables: {
        groupId,
        userIds,
      },
    });
    const index = groups.findIndex((g) => g.id === groupId);
    if (index > -1) {
      const groupUpdated = {
        ...groups[index],
        users: [...groups[index].users, ...users],
      };
      setGroups([
        ...groups.slice(0, index),
        groupUpdated,
        ...groups.slice(index + 1),
      ]);
      handleSelectGroup(groupUpdated);
    }
  };

  const handleUnassignUser = (group, userIds) => {
    const { id } = group;

    unassignUser({
      variables: {
        groupId: id,
        userIds,
      },
    });

    const index = groups.findIndex((g) => g.id === id);
    if (index > -1) {
      const groupUpdated = {
        ...groups[index],
        users: groups[index].users.filter((u) => !userIds.includes(u.id)),
      };
      setGroups([
        ...groups.slice(0, index),
        groupUpdated,
        ...groups.slice(index + 1),
      ]);
      handleSelectGroup(groupUpdated);
    }
  };

  const handleUnassignAsset = (group, assetIds) => {
    const { id, parentGroupId } = group;

    if (!parentGroupId) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: t("No se puede quitar móviles del grupo raiz"),
      });
      return;
    }

    unassignAsset({
      variables: {
        groupId: id,
        assetIds,
      },
    });

    const index = groups.findIndex((g) => g.id === id);
    if (index > -1) {
      const groupUpdated = {
        ...groups[index],
        assets: groups[index].assets.filter((a) => !assetIds.includes(a.id)),
      };

      const newGroups = [
        ...groups.slice(0, index),
        groupUpdated,
        ...groups.slice(index + 1),
      ];

      setGroups(newGroups);
      handleSelectGroup(groupUpdated);
    }
  };

  const handleAssignAsset = (groupId, assets) => {
    const thisGroup = groups.find((x) => x.id === groupId);
    const assetIds = assets.map((r) => r.id);

    if (!thisGroup.parentGroupId) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: t("No se puede asignar móviles al grupo raiz"),
      });
      return;
    }

    assignAsset({
      variables: {
        groupId,
        assetIds,
      },
    });

    const index = groups.findIndex((g) => g.id === groupId);
    if (index > -1) {
      const groupUpdated = {
        ...groups[index],
        assets: [...groups[index].assets, ...assets],
      };
      setGroups([
        ...groups.slice(0, index),
        groupUpdated,
        ...groups.slice(index + 1),
      ]);
      handleSelectGroup(groupUpdated);
    }
  };

  const [deleteGroup] = useMutation(DELETE_GROUP_MUTATION, {
    onCompleted(d) {
      setGroups(groups.filter((x) => x.id !== selectedGroup.id));
      setSelectedGroup(null);
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Grupo eliminado correctamente"),
      });
    },
  });

  const handleDeleteGroup = (id) => {
    const thisGroup = groups.find((x) => x.id === id);

    if (!thisGroup.parentGroupId) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: "No se puede eliminar el grupo raiz",
      });
      return;
    }

    deleteGroup({
      variables: {
        groupId: id,
      },
    });
  };

  const [updateGroup] = useMutation(UPDATE_GROUP_MUTATION, {
    onCompleted(d) {
      const updatedGroup = d.updateGroup;
      const index = groups.findIndex((x) => x.id === updatedGroup.id);

      if (index !== -1) {
        setGroups([
          ...groups.slice(0, index),
          {
            ...groups[index],
            name: updatedGroup.name,
          },
          ...groups.slice(index + 1),
        ]);
      } else {
        handleFetchGroups();
      }
      setResultState({
        isOpen: true,
        type: "success",
        msg: "Grupo actualizado correctamente",
      });
    },
    onError(err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: err.message,
      });
    },
  });

  const handleCreateGroup = (name, parentGroupId, customerId) => {
    createGroup({
      variables: {
        name,
        parentGroupId,
        customerId,
      },
    });
  };

  const handleUpdateGroup = (groupId, name) => {
    updateGroup({
      variables: {
        groupId,
        name,
      },
    });
  };

  const handleAssociateFleet = (groupId, dbExternalCustomers) => {
    const externalCustomers = [
      ...dbExternalCustomers,
      selectedCustomers[selectedCustomers.length - 1].value,
    ];
    updateGroup({
      variables: {
        groupId,
        externalCustomerIds: externalCustomers,
      },
    });
  };

  const resetResults = () => {
    setGroups([]);
    setSelectedGroups(null);
  };
  useEffect(() => {
    let customerId;

    if (Array.isArray(selectedCustomers) && selectedCustomers.length === 1) {
      customerId = selectedCustomers[0].value;
    }

    if (Array.isArray(selectedCustomers) && selectedCustomers.length > 1) {
      setResultState({
        isOpen: true,
        msg: "Debe seleccionar un cliente",
        type: "error",
      });
      resetResults();
      return;
    }

    if (!Array.isArray(selectedCustomers) && !selectedCustomers) {
      setResultState({
        isOpen: true,
        msg: "Debe seleccionar un cliente",
        type: "error",
      });
      resetResults();
      return;
    }

    if (customerId === -1) {
      setResultState({
        isOpen: true,
        msg: "Debe seleccionar un cliente",
        type: "error",
      });
      resetResults();
      return;
    }

    if (customerId) {
      fetchGroups({
        variables: {
          customerId: customerId,
        },
      });

      fetchUsers({
        variables: {
          customerId: customerId,
        },
      });
    }
  }, [selectedCustomers]);

  const handleSelectGroup = (g) => {
    setSelectedGroup(g);
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setResultState({
      ...resultState,
      isOpen: false,
    });
  };

  return (
    <Structure>
      <Snackbar
        open={resultState.isOpen}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleCloseNotification}
          severity={resultState.type}
          variant="filled"
        >
          {resultState.msg}
        </MuiAlert>
      </Snackbar>
      <PageTitle sector={t("Administración")} title={t("Grupos")} />
      <Grid container>
        <Grid item xs={4}>
          <GroupPanel
            groups={groups}
            onSelect={handleSelectGroup}
            handleCreateGroup={handleCreateGroup}
            handleUpdateGroup={handleUpdateGroup}
            selectedGroup={selectedGroup}
            handleDeleteGroup={handleDeleteGroup}
            handleAssociateFleet={handleAssociateFleet}
            loggedUser={loggedUser}
            handleAssignAsset={handleAssignAsset}
            handleAssignUser={handleAssignUser}
            usersAvailable={users}
          />
        </Grid>
        <Grid item xs={4}>
          <AssetPanel
            group={selectedGroup}
            allGroups={groups}
            handleAssignAsset={handleAssignAsset}
            handleUnassignAsset={handleUnassignAsset}
            loggedUser={loggedUser}
          />
        </Grid>
        <Grid item xs={4}>
          <UserPanel
            group={selectedGroup}
            usersAvailable={users}
            handleAssignUser={handleAssignUser}
            handleUnassignUser={handleUnassignUser}
            handleFetchGroups={handleFetchGroups}
          />
        </Grid>
      </Grid>
    </Structure>
  );
};

const _mapStateToProps = ({ groups, user }) => {
  return {
    ...groups,
    user,
  };
};

export default connect(_mapStateToProps, null)(ABMGroups);
