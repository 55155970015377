import gql from "graphql-tag";

const STOPS_DETOUR_EXPORT_QUERY = gql`
  query StopReportExport(
    $dateTo: Datetime!
    $dateFrom: Datetime!
    $roadId: Int
    $assetId: Int
    $format: FormatEnum
  ) {
    stopReportExport(
      dateTo: $dateTo
      dateFrom: $dateFrom
      roadId: $roadId
      assetId: $assetId
      format: $format
    )
  }
`;

export default STOPS_DETOUR_EXPORT_QUERY;
