// eslint-disable react/forbid-prop-types
import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withRouter } from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";

import { getAvailableNavbarItems } from "../../utils/navbar";

import ConnectedLateralPanel from "../../containers/LateralPanel/ConnectedLateralPanel";
import ConnectedLateralPanelAux from "../../containers/LateralPanel/ConnectedLateralPanelAux";

import "./Navbar.scss";

const buildNavbarItems = ({ navbarItemClick, selectedSection, history }) => {
  const availableNavbarItems = getAvailableNavbarItems();

  if (!availableNavbarItems.length) {
    return null;
  }

  const navbarItems = availableNavbarItems.map(
    ({ icon, label, controls, to, panel }) => {
      const Icon = icon;
      const iconButtonClassNames = classNames("navbar__icon-button", {
        "navbar__icon-button--selected": label === selectedSection,
      });
      let _handleClick = navbarItemClick;
      if (to) {
        _handleClick = () => {
          history.push(to);
        };
      }

      return (
        <IconButton
          key={`key-${label}`}
          color="inherit"
          aria-label={label}
          aria-controls={controls}
          aria-haspopup="true"
          onClick={() => {
            _handleClick({ activePanel: panel });
          }}
          className={iconButtonClassNames}
        >
          <Icon />
        </IconButton>
      );
    }
  );

  return <Toolbar>{navbarItems}</Toolbar>;
};

class Navbar extends Component {
  render() {
    const {
      toggleActivePanel,
      panelCloseRedirectTo,
      title,
      panelContent,
      panelAuxContent,
      panelAuxTitle,
      history,
      selectedSection,
    } = this.props;

    const availableMenuItems = buildNavbarItems({
      navbarItemClick: toggleActivePanel,
      selectedSection,
      history,
    });

    if (!availableMenuItems) {
      return null;
    }

    const _panelContent = panelContent || null;
    const _panelAuxContent = panelAuxContent || null;

    return (
      <AppBar
        position="static"
        className="navbar-base navbar--lateral navbar--left"
      >
        {availableMenuItems}

        <ConnectedLateralPanel
          level={1}
          title={title}
          panelCloseRedirectTo={panelCloseRedirectTo}
        >
          {_panelContent}
        </ConnectedLateralPanel>

        <ConnectedLateralPanelAux level={2} title={panelAuxTitle}>
          {_panelAuxContent}
        </ConnectedLateralPanelAux>
      </AppBar>
    );
  }
}

Navbar.propTypes = {
  toggleActivePanel: PropTypes.func,
  panelCloseRedirectTo: PropTypes.string,
  title: PropTypes.string,
  panelContent: PropTypes.node,
  panelAuxContent: PropTypes.node,
  panelAuxTitle: PropTypes.string,
  history: PropTypes.object,
  selectedSection: PropTypes.string,
};

Navbar.defaultProps = {
  toggleActivePanel: () => {},
  panelCloseRedirectTo: "",
  title: "",
  panelContent: null,
  panelAuxContent: null,
  panelAuxTitle: "",
  history: {},
  selectedSection: "",
};

export default withRouter(Navbar);
