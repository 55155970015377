import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import SYNC_REAXIUM_DEVICES_MUTATION from "../../graphql/mutations/syncReaxiumDevicesMutation";
import { Button, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

const { t } = require("../../lang");

const SYNC_BUTTON_ON_CLICK_DISABLE_TIME = 2000;

const SNACKBAR_TYPES = {
  SUCCESS: "success",
  INFO: "info",
  ERROR: "error",
};
const ReaxiumDeviceSyncButton = () => {
  const [isSyncButtonDisabled, setIsSyncButtonDisabled] = useState(false);
  const [resultState, setResultState] = useState({
    isOpen: false,
    type: SNACKBAR_TYPES.INFO,
    msg: t(""),
  });

  useEffect(() => {
    if (isSyncButtonDisabled) {
      const timeoutId = setTimeout(() => {
        setIsSyncButtonDisabled(false);
      }, SYNC_BUTTON_ON_CLICK_DISABLE_TIME);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isSyncButtonDisabled]);

  const [startDeviceSync] = useMutation(SYNC_REAXIUM_DEVICES_MUTATION, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const willJobRun = data.syncReaxiumDevices;
      const alertMessage = willJobRun
        ? t("El proceso de sincronizacion de equipos Reaxium iniciara en breve")
        : t(
            "El proceso de sincronizacion de equipos Reaxium ya se encuentra en ejecucion"
          );

      const alertType = willJobRun
        ? SNACKBAR_TYPES.SUCCESS
        : SNACKBAR_TYPES.INFO;
      setResultState({
        isOpen: true,
        type: alertType,
        msg: alertMessage,
      });
    },
    onError: (error) => {
      console.error("startDeviceSync Error:", error);
      setResultState({
        isOpen: true,
        type: SNACKBAR_TYPES.ERROR,
        msg: t(error.message),
      });
    },
  });

  const handleOnClick = (event) => {
    setIsSyncButtonDisabled(true);
    startDeviceSync();
  };

  const handleCloseNotificationSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setResultState({
      ...resultState,
      isOpen: false,
    });
  };
  return (
    <>
      <Snackbar
        open={resultState.isOpen}
        autoHideDuration={5000}
        onClose={handleCloseNotificationSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleCloseNotificationSnackbar}
          severity={resultState.type}
          variant="filled"
        >
          {resultState.msg}
        </MuiAlert>
      </Snackbar>
      <Button
        variant="contained"
        color={isSyncButtonDisabled ? "secondary" : "primary"}
        onClick={handleOnClick}
        disabled={isSyncButtonDisabled}
      >
        {t("Iniciar sincronizacion Reaxium")}
      </Button>
    </>
  );
};

export default ReaxiumDeviceSyncButton;
