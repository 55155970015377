import React from "react";

const SportIcon = () => {
  return (
    <div style={{ justifyContent: "center" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        enableBackground="new 0 0 50.169 50.169"
        version="1.1"
        viewBox="0 0 50.169 50.169"
        xmlSpace="preserve"
      >
        <path d="M49.174 23.484c-23.741-12.419-35.239-1.428-35.239-1.428C2.531 21.901 0 24.766 0 24.766s2.404 3.04.776 4.102c-1.629 1.063 4.428.928 4.428.928a4.226 4.226 0 118.238 0h24.375a4.227 4.227 0 014.122-5.163 4.229 4.229 0 014.229 4.225c0 .321-.041.635-.108.938h3.105c2.254-2.261.009-6.312.009-6.312zm-21.529-1.236H15.738s5.267-3.146 11.904-3.501v3.501h.003zm1.277.003V18.73c3.576.011 7.459.886 11.092 3.521H28.922zM9.318 25.057a3.772 3.772 0 100 7.543 3.772 3.772 0 100-7.543zm0 5.224a1.45 1.45 0 110-2.899 1.45 1.45 0 110 2.899zm32.709-5.224a3.772 3.772 0 100 7.543 3.772 3.772 0 000-7.543zm0 5.224a1.448 1.448 0 110-2.899 1.449 1.449 0 110 2.899z"></path>
      </svg>
    </div>
  );
};

export default React.memo(SportIcon);
