import React, { useLayoutEffect, useRef } from "react";
import { connect } from "react-redux";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import darkTheme from "@amcharts/amcharts4/themes/dark";
import { useTranslation } from "../../lang";
import { GetDateFormat } from "../../utils/generalUtils";

am4core.useTheme(darkTheme);
am4core.useTheme(am4themes_animated);

const KmsRecChart = ({ data, user }) => {
  const [t] = useTranslation();
  const chart = useRef(null);

  //Ver diferencias entre useEffect y useLayoutEffect
  //useLayoutEffect se dispara de forma sincronica despues de las mutations del DOM
  useLayoutEffect(() => {
    if (chart.current) {
      chart.current.series.each((serie) => {
        serie.data = data;
      });
    }
  }, [data]);

  useLayoutEffect(() => {
    let x = am4core.create("kmsRecChartContainer", am4charts.XYChart);

    const title = x.titles.create();
    title.text = t("Kilómetros Recorridos");
    title.fontSize = 25;
    title.marginBottom = 30;
    title.align = "left";

    //Create axis
    x.xAxes.push(new am4charts.DateAxis());
    x.yAxes.push(new am4charts.ValueAxis());

    x.dateFormatter = new am4core.DateFormatter();
    x.dateFormatter.dateFormat = GetDateFormat(user.language, true);

    //Fleet Score serie
    let fleetKmsRecSerie = x.series.push(new am4charts.LineSeries());
    fleetKmsRecSerie.dataFields.valueY = "fleetKmsRec";
    fleetKmsRecSerie.dataFields.dateX = "date";
    fleetKmsRecSerie.name = "FleetKmsRec";
    fleetKmsRecSerie.tooltipText = `${t("Fecha")}: {dateX}
      ${t("Kms Recorridos Flota")}: {valueY} [${t("Km/h")}]
    `;
    fleetKmsRecSerie.stroke = am4core.color("#A20F0F");
    fleetKmsRecSerie.strokeWidth = 2;
    /*const bulletFleet = fleetScoreSerie.bullets.push(new am4charts.CircleBullet());
    bulletFleet.circle.strokeWidth = 2;*/

    let segmentFS = fleetKmsRecSerie.segments.template;
    segmentFS.interactionsEnabled = true;

    fleetKmsRecSerie.data = data;

    //Driver or Asset serie
    let kmsRecSerie = x.series.push(new am4charts.LineSeries());
    kmsRecSerie.dataFields.valueY = "kmsRec";
    kmsRecSerie.dataFields.dateX = "date";
    kmsRecSerie.name = "KmsRec";
    kmsRecSerie.stroke = am4core.color("#87C802");
    kmsRecSerie.strokeWidth = 2;
    kmsRecSerie.tooltipText = `{t("Fecha")}: {dateX}
      ${t("Kms Recorridos")}: {valueY} [Km]
    `;

    let segmentScore = kmsRecSerie.segments.template;
    segmentScore.interactionsEnabled = true;

    kmsRecSerie.data = data;

    /*x.legend = new am4charts.Legend();
    x.legend.position = "right";

    x.legend.markers.template.states.create("dimmed").properties.opacity = 0.3;
    x.legend.labels.template.states.create("dimmed").properties.opacity = 0.3;

    x.legend.itemContainers.template.events.on("over", (event) => {
      processOver(event.target.dataItem.dataContext);
    });

    x.legend.itemContainers.template.events.on("out", (event) => {
      processOut();
    });*/

    x.cursor = new am4charts.XYCursor();
    x.cursor.maxTooltipDistance = 0;

    /*x.scrollbarX = new am4core.Scrollbar();
    x.scrollbarX.parent = x.bottomAxesContainer;*/

    chart.current = x;

    return () => {
      x.dispose();
    };
  }, [user.language]);

  return (
    <>
      <div
        id="kmsRecChartContainer"
        style={{ width: "100%", height: "40vh" }}
      />
    </>
  );
};

const _mapStateToProps = ({ user }) => {
  return {
    user,
  };
};

const _mapDispatchToProps = (dispatch) => {
  return {
    // _setCurrentUser: (param) => dispatch(setCurrentUser(param)),
  };
};

export default connect(_mapStateToProps, _mapDispatchToProps)(KmsRecChart);
