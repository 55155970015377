import React from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import { Field, reduxForm } from "redux-form";

import DateSelector from "../../Selectors/DateSelector";
import { ReduxStyledSelectField } from "../../Base/ReduxFormFields";

import "./SubHeader.scss";

let period = 1;
let lastMarkerId = 0;
const SubHeader = ({
  markerId,
  toggleFillMarkerJourney,
  toggleFillMarkerJourneyPersonalizado,
  subComponent,
  parentId,
  customPeriod,
  toggleFillGenericPopup,
  onDateOptionSelected,
  dateOptionSelected,
}) => {
  if (lastMarkerId != markerId && markerId != 0) {
    period = 1;
    lastMarkerId = markerId;
  }
  const handleChangePeriod = (event) => {
    if (event && event.fechaDesde && event.fechaHasta && markerId > 0) {
      toggleFillMarkerJourneyPersonalizado({
        markerId: markerId,
        fechaDesde: event.fechaDesde,
        fechaHasta: event.fechaHasta,
      });
    }

    if (event.selectedOption) {
      onDateOptionSelected(event.selectedOption);
    }

    toggleFillGenericPopup({ popupInfo: {} });
  };

  let _subComponent = (
    <div className="extra-filter">
      <Field
        component={ReduxStyledSelectField}
        name={`${parentId}-sub-header-select-status`}
        id={`${parentId}-sub-header-select-status`}
        textColor="white"
        // shrinkLabel
        displayEmpty
        label="Leidos"
        lateralPanelDepth={1}
        options={[
          { value: "", text: "Leidos" },
          { value: 1, text: "No leidos" },
        ]}
      />
    </div>
  );

  if (!isEmpty(subComponent)) {
    _subComponent = subComponent;
  }

  return (
    <div className="expandable-panel__info-block expandable-panel__info-block--dark expandable-panel__actions-block">
      <div style={{ width: "100%", marginTop: "20px" }}>
        <DateSelector
          onChange={handleChangePeriod}
          selectedOption={dateOptionSelected}
        />
      </div>
    </div>
  );
};

SubHeader.propTypes = {
  onChangePeriod: PropTypes.func,
  onChangeStatus: PropTypes.func,
  subComponent: PropTypes.any,
  parentId: PropTypes.string,
  customPeriod: PropTypes.bool,
  toggleFillMarkerJourney: PropTypes.func,
  markerId: PropTypes.number,
  toggleFillMarkerJourneyPersonalizado: PropTypes.func,
  toggleFillGenericPopup: PropTypes.func,
};

SubHeader.defaultProps = {
  onChangePeriod: () => {},
  onChangeStatus: () => {},
  subComponent: null,
  parentId: "",
  customPeriod: false,
  toggleFillMarkerJourney: () => {},
  markerId: 0,
  toggleFillMarkerJourneyPersonalizado: () => {},
  toggleFillGenericPopup: () => {},
};

export default reduxForm({
  form: "subHeader",
})(SubHeader);
