import gql from "graphql-tag";

const GET_POI_CATEGORIES_QUERY = gql`
  query PoiCategory {
    poiCategory {
      id
      poiDescription
    }
  }
`;

export default GET_POI_CATEGORIES_QUERY;
