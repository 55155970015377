import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
const FileExportIcon = function () {
  return (
    <SvgIcon>
      <rect
        data-name="Rectángulo 1099"
        width="36"
        height="33"
        transform="translate(0.335 -0.429)"
        fill="#fff"
        opacity="0"
      />
      <path
        data-name="file-export"
        d="M19.664,9.84,17.176,7.478a.988.988,0,0,0-1.069-.186.932.932,0,0,0-.579.866V9.983H13.31V4.574A1.67,1.67,0,0,0,12.822,3.4L9.914.489A1.664,1.664,0,0,0,8.739,0H1.663A1.669,1.669,0,0,0,0,1.667V16.084a1.664,1.664,0,0,0,1.663,1.663h9.983a1.664,1.664,0,0,0,1.663-1.663V12.2H12.2v3.883a.556.556,0,0,1-.555.555H1.663a.556.556,0,0,1-.555-.555V1.667a.559.559,0,0,1,.555-.558h6.1V4.716a.828.828,0,0,0,.832.829h3.6V9.983H5.823a.277.277,0,0,0-.277.277v.555a.277.277,0,0,0,.277.277h9.705v1.826a.932.932,0,0,0,.579.866.99.99,0,0,0,1.07-.187l2.486-2.361a.956.956,0,0,0,0-1.395Zm-10.79-5.4V1.13a.545.545,0,0,1,.257.146l2.907,2.907a.545.545,0,0,1,.146.257H8.875Zm7.763,8.139V8.5l2.149,2.041Z"
        transform="translate(5 3)"
        fill="rgba(255,255,255,0.6)"
      />
    </SvgIcon>
  );
};

export default FileExportIcon;
