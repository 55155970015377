import gql from "graphql-tag";

const OBD2_REPORT_QUERY = gql`
  query OBD2Report(
    $assetsSelected: [Int]!
    $fechaInicio: Datetime
    $fechaFin: Datetime
    $days: [WeekDaysEnum]
    $variablesOBD2: [VariableOBD2Enum]
  ) {
    obd2Report(
      assetsSelected: $assetsSelected
      fechaInicio: $fechaInicio
      fechaFin: $fechaFin
      days: $days
      variablesOBD2: $variablesOBD2
    ) {
      mid
      name
      pat
      Time
      odo
      nDTC
      cELV
      eCT
      fP
      fT
      iMAP
      rpm
      speed
      tA
      iAT
      tIgn
      dTMIL
      panic
      ignition
      movement
      bI
      aC
      gsm
    }
  }
`;

export default OBD2_REPORT_QUERY;
