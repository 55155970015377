import { format } from "date-fns";
import _ from "lodash";

// import MAP_MARKERS from '../../__fixtures__/mapMarkers.json';

const getTypeMobile = (marker) => {
  return marker?.asset?.typeMobile?.id;
};

// eslint-disable import/prefer-default-export
export const markersSelector = _.memoize(({ markers, t }) => {
  // console.log("markersSelector -> markers", markers[0]);
  let _markers = [];

  if (markers) {
    _markers = markers
      //.filter((m) => m.velocidad > 0)
      //.filter((m) => m.patente === "3ALHC5CV2FDGS2394")
      .map((marker) => {
        let _marker;
        const fechaGPSFormateada = format(
          new Date(marker["fGPS"]),
          "dd/MM/yyyy HH:mm:ss"
        );

        if (marker) {
          let cur = t ? t(GetCurso(marker["cur"])) : GetCurso(marker["cur"]);
          let typ = GetType(marker["typ"]);

          _marker = {
            id: marker["id"],
            eid: marker["eid"],
            evt: marker["evt"],
            pos: {
              lng: typeof marker["lon"] != "undefined" ? marker["lon"] : 0,
              lat: typeof marker["lat"] != "undefined" ? marker["lat"] : 0,
            },
            pat: marker["pat"],
            nMov:
              marker["nMov"] !== "N/A"
                ? marker["nMov"].toLowerCase()
                : marker["nMov"],
            nCli:
              marker["nCli"] !== "N/A"
                ? marker["nCli"].toLowerCase()
                : marker["nCli"],
            nCh:
              marker["nCh"] !== "N/A"
                ? marker["nCh"].toLowerCase()
                : marker["nCh"],
            typ: typ,
            vel: marker["vel"],
            cur: cur,
            fGPS: fechaGPSFormateada,
            fGPSd: marker["fGPS"], // unformated fGPS
            tIgn: marker["tIgn"],
            tDet: marker["tDet"],
            nInt: marker["nInt"],
            cmb: "",
            dir: marker["dir"],
            odo: marker["odo"],
            sOdo: "",
            horo: "",
            utx: "",
            mid: marker["mid"],
            roadStatus: marker["roadStatus"],
            activeRouteName: marker["activeRouteName"],
            typeMobile: getTypeMobile(marker),
            asset: {
              itSupportsOBD2: marker?.asset?.itSupportsOBD2 || false,
            },
          };
        }
        return _marker;
      });
  }

  return _markers;
});

function GetCurso(curso) {
  var directions = ["N", "NE", "E", "SE", "S", "SO", "O", "NO"];
  var index = Math.round(((curso %= 360) < 0 ? curso + 360 : curso) / 45) % 8;
  return directions[index];
}

const GetType = (type) => {
  switch (type) {
    case 0:
      return "success";
    case 1:
      return "low-connection";
    case 2:
      return "danger";
    case 3:
      return "alert";
    case 4:
      return "inactive";
    default:
      return "person";
  }
};

export const prepareMarker = ({ marker }) => {
  let _marker;

  const vehicleName = !!marker["nMov"] ? marker["nMov"] : "";
  const clientName = !!marker["nCli"] ? marker["nombreCliente"] : "";
  const driverName = !!marker["nCh"] ? marker["nCh"] : "";

  if (marker) {
    _marker = {
      id: marker["id"],
      position: {
        lng: marker["lng"],
        lat: marker["lat"],
      },
      identifier: marker["pat"],
      vehicleName:
        vehicleName !== "N/A" ? vehicleName.toLowerCase() : vehicleName,
      clientName: clientName !== "N/A" ? clientName.toLowerCase() : clientName,
      driverName: driverName !== "N/A" ? driverName.toLowerCase() : driverName,
      type: "inactive",
      vel: marker["vel"],
      time: "16m",
      direction: marker["direccion"],
      fuel: `${marker["combustible"]}L`,
    };
  }
  return _marker;
};
