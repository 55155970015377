import React, { useEffect, useState } from "react";

import { useMutation, useQuery, useLazyQuery } from "@apollo/client";

import Structure from "../../../components/Base/Structure";

import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import LINES_QUERY from "../../../graphql/queries/getLinesQuery";
import CREATE_LINE_MUTATION from "../../../graphql/mutations/createLineMutation";
import UPDATE_LINE_MUTATION from "../../../graphql/mutations/updateLineMutation";
import DELETE_LINE_MUTATION from "../../../graphql/mutations/deleteLineMutation";

import PageTitle from "../../../components/Base/PageTitle";

import TableLine from "./List";
import "./Line.scss";

import { useTranslation } from "../../../lang";

const ManageLinePage = ({}) => {
  const [t] = useTranslation();
  const paginationSizes = [10, 25, 50];
  const [total, setTotal] = useState(0);
  const [lineList, setLineList] = useState([]);
  const [paginationState, setPaginationState] = useState({
    pageSize: paginationSizes[0],
    pageNumber: 1,
    sort: undefined,
    filter: null,
  });

  const [resultState, setResultState] = useState({
    isOpen: false,
    type: "success",
    msg: "",
  });

  const [getLines] = useLazyQuery(LINES_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      if (d.lines) {
        setLineList(d.lines);
        setTotal(d.lines.length);
      }
    },
  });

  const handleGetLines = () => {
    getLines({
      variables: {
        ...paginationState,
        paginate: true,
      },
    });
  };

  useEffect(() => {
    handleGetLines();
  }, [paginationState]);

  const [createLine] = useMutation(CREATE_LINE_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Línea creado correctamente"),
      });
      handleGetLines();
    },
    onError(err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: err.message,
      });
    },
  });

  const [updateLine] = useMutation(UPDATE_LINE_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Línea actualizado correctamente"),
      });
      handleGetLines();
    },
    onError(err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: err.message,
      });
    },
  });

  const [deleteLine] = useMutation(DELETE_LINE_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Línea eliminada correctamente"),
      });
      handleGetLines();
    },
    onError(d) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: t("Ocurrió un error"),
      });
    },
  });

  const handleDeleteLine = (id) => {
    deleteLine({
      variables: { id: id },
    });
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setResultState({
      ...resultState,
      isOpen: false,
    });
  };

  return (
    <Structure>
      <Snackbar
        open={resultState.isOpen}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleCloseNotification}
          severity={resultState.type}
          variant="filled"
        >
          {resultState.msg}
        </MuiAlert>
      </Snackbar>
      <PageTitle sector={t("Administración")} title={t("Líneas")} />
      <TableLine
        getLines={handleGetLines}
        lineList={lineList}
        paginationSizes={paginationSizes}
        paginationState={paginationState}
        setPaginationState={setPaginationState}
        total={total}
        setResultState={setResultState}
        createLine={createLine}
        updateLine={updateLine}
        deleteLine={handleDeleteLine}
      />
    </Structure>
  );
};

export default ManageLinePage;
