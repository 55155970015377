import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";

import { Button, FormGroup, Grid, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import * as Yup from "yup";
import { Field, Formik } from "formik";

import CHANGE_PROFILE_INFORMATION from "../../graphql/mutations/changeProfileInformation";
import { useTranslation } from "../../lang";

import ImageUpload from "../Base/ImageUpload";

const Form = ({ closeModal, user, onUpdated }) => {
  const [t] = useTranslation();
  const [resultState, setResultState] = useState({
    isOpen: false,
    type: "success",
    msg: t(""),
  });

  const [changeProfileInformation] = useMutation(CHANGE_PROFILE_INFORMATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Usuario actualizado correctamente"),
      });
    },
    onError(err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: t(err.message),
      });
    },
  });

  const initialValues = {
    firstName: user.firstName,
    lastName: user.lastName,
    photo: "",
  };

  const yupSchema = Yup.object().shape({
    firstName: Yup.string().required(t("Requerido")),
    lastName: Yup.string().required(t("Requerido")),
  });

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setResultState({
      ...resultState,
      isOpen: false,
    });
  };

  return (
    <>
      <Snackbar
        open={resultState.isOpen}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleCloseNotification}
          severity={resultState.type}
          variant="filled"
        >
          {resultState.msg}
        </MuiAlert>
      </Snackbar>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={yupSchema}
        onSubmit={async (values) => {
          const userProfile = {
            userId: user.id,
            firstName: values.firstName,
            lastName: values.lastName,
            photo: values.photo,
          };

          await changeProfileInformation({
            variables: userProfile,
          });

          onUpdated({ ...userProfile, photo: values.photo || user.photo });
        }}
      >
        {(props) => {
          const {
            errors,
            handleSubmit,
            touched,
            values,
            setFieldValue,
          } = props;

          return (
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <ImageUpload
                  onChange={(newPhoto) => {
                    setFieldValue("photo", newPhoto);
                  }}
                  title={t("Foto")}
                  file={values.photo}
                />
                {errors.photo && (
                  <div className="input-feedback">{errors.photo}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">{t("Nombre")}</label>
                <Field
                  type="text"
                  name="firstName"
                  tabIndex="0"
                  autoFocus
                  className={
                    errors.firstName
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  placeholder={t("Nombre")}
                />
                {errors.firstName && (
                  <div className="input-feedback">{errors.firstName}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">{t("Apellido")}</label>
                <Field
                  type="text"
                  name="lastName"
                  tabIndex="0"
                  className={
                    errors.lastName
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  placeholder={t("Nueva Contraseña")}
                />
                {errors.lastName && (
                  <div className="input-feedback">{errors.lastName}</div>
                )}
              </FormGroup>
              <Grid
                item
                lg={12}
                style={{
                  marginTop: "50px",
                  textAlign: "center",
                }}
              >
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  tabIndex="0"
                >
                  {t("Guardar")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  tabIndex="0"
                  style={{ marginLeft: "20px" }}
                  onClick={() => {
                    closeModal();
                  }}
                >
                  {t("Cancelar")}
                </Button>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default Form;
