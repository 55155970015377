import gql from "graphql-tag";

const GET_ASSET_IN_STOP_QUERY = gql`
  query AssetInStop(
    $dateTo: Datetime!
    $dateFrom: Datetime!
    $roadId: Int
    $assetId: Int
  ) {
    assetInStop(
      roadId: $roadId
      assetId: $assetId
      dateTo: $dateTo
      dateFrom: $dateFrom
    ) {
      id
      name
      status
      timeInStop
      expectedEnterTime
      actualEnterTime
      actualExitTime
    }
  }
`;

export default GET_ASSET_IN_STOP_QUERY;
