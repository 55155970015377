import { combineReducers } from "redux";

import { UPDATE_MARKER_INFO } from "./actions";

const markerInfo = (markerInfo = {}, { type, payload }) => {
  let newMarkerInfo = markerInfo;
  if (type === UPDATE_MARKER_INFO) {
    newMarkerInfo = payload;
  }

  return newMarkerInfo;
};

export default combineReducers({
  markerInfo,
});
