import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { Snackbar } from "@material-ui/core";

import { useMutation, useLazyQuery } from "@apollo/client";

import MuiAlert from "@material-ui/lab/Alert";

import Structure from "../../../components/Base/Structure";
import List from "./List";
import PageTitle from "../../../components/Base/PageTitle";

import GET_POI_QUERY from "../../../graphql/queries/getPoiQuery";
import CREATE_POI_MUTATION from "../../../graphql/mutations/createPoiMutation";
import UPDATE_POI_MUTATION from "../../../graphql/mutations/updatePoiMutation";
import DELETE_POI_MUTATION from "../../../graphql/mutations/deletePoiMutation";

import { useTranslation } from "../../../lang";

const paginationSizes = [10, 25, 50];
const POIsPage = ({ selectedCustomers }) => {
  const [t] = useTranslation();
  const [poiList, setPoiList] = useState([]);
  const [tableState, setTableState] = useState({
    pageSize: paginationSizes[0],
    pageNumber: 1,
    sort: undefined,
    searchText: "",
    paginate: true,
  });
  const [total, setTotal] = useState(0);

  const [resultState, setResultState] = useState({
    isOpen: false,
    type: "success",
    msg: "",
  });

  const [fetchPOIs] = useLazyQuery(GET_POI_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      setTotal(d.poi.length);
      setPoiList(d.poi);
    },
  });

  useEffect(() => {
    let customerId;

    if (Array.isArray(selectedCustomers) && selectedCustomers.length > 0) {
      customerId = selectedCustomers[selectedCustomers.length - 1].value;
    }

    // console.log("customerId", customerId);
    if (customerId && tableState) {
      fetchPOIs({
        variables: {
          customerId: customerId,
          ...tableState,
        },
      });
    }
  }, [selectedCustomers, tableState]);

  const [createPoi] = useMutation(CREATE_POI_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Geocerca creada correctamente"),
      });

      setTableState({
        pageSize: paginationSizes[0],
        pageNumber: 1,
        sort: undefined,
        searchText: "",
      });
    },
    onError(err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: err.message,
      });
    },
  });

  const [updatePoi] = useMutation(UPDATE_POI_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Geocerca actualizada correctamente"),
      });

      setTableState({
        pageSize: paginationSizes[0],
        pageNumber: 1,
        sort: undefined,
        searchText: "",
      });
    },
    onError(err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: err.message,
      });
    },
  });

  const [deletePoi] = useMutation(DELETE_POI_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: "Geocerca eliminada correctamente",
      });

      setTableState({
        pageSize: paginationSizes[0],
        pageNumber: 1,
        sort: undefined,
        searchText: "",
      });
    },
    onError(err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: err.message,
      });
    },
  });

  const handleCreatePoi = (poiPayload) => {
    //Validaciones que no se puedan poner en Yup

    createPoi({
      variables: poiPayload,
    });
  };

  const handleUpdatePoi = (poiPayload) => {
    updatePoi({
      variables: poiPayload,
    });
  };

  const handleDeletePoi = (id) => {
    deletePoi({
      variables: {
        id: id,
      },
    });
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setResultState({
      ...resultState,
      isOpen: false,
    });
  };

  return (
    <Structure>
      <Snackbar
        open={resultState.isOpen}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleCloseNotification}
          severity={resultState.type}
          variant="filled"
        >
          {resultState.msg}
        </MuiAlert>
      </Snackbar>

      <PageTitle sector={t("Administración")} title={t("Puntos de Interés")} />
      <List
        poiList={poiList}
        getPoi={() => {}}
        createPoi={handleCreatePoi}
        updatePoi={handleUpdatePoi}
        deletePoi={handleDeletePoi}
        paginationSizes={paginationSizes}
        total={total}
        tableState={tableState}
        setTableState={setTableState}
      />
    </Structure>
  );
};

const _mapStateToProps = ({ groups }) => {
  return {
    ...groups,
  };
};

export default connect(_mapStateToProps, null)(POIsPage);
