import gql from "graphql-tag";

const JOURNEYPOSITIONS_EXCEL_QUERY = gql`
  query JourneyPositionsExcel(
    $movilID: Int!
    $fechaInicio: Datetime!
    $fechaFin: Datetime!
  ) {
    journeyPositionsExcel(
      movilID: $movilID
      fechaInicio: $fechaInicio
      fechaFin: $fechaFin
    )
  }
`;

export default JOURNEYPOSITIONS_EXCEL_QUERY;
