import gql from "graphql-tag";

const OBD2_REPORT_EXPORT_QUERY = gql`
  query OBD2ReportExport(
    $assetsSelected: [Int]!
    $fechaInicio: Datetime
    $fechaFin: Datetime
    $days: [WeekDaysEnum]
    $variablesOBD2: [VariableOBD2Enum]
    $format: FormatEnum
  ) {
    obd2ReportExport(
      assetsSelected: $assetsSelected
      fechaInicio: $fechaInicio
      fechaFin: $fechaFin
      days: $days
      variablesOBD2: $variablesOBD2
      format: $format
    )
  }
`;

export default OBD2_REPORT_EXPORT_QUERY;
