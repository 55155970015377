import gql from "graphql-tag";

const UPDATE_GROUP_MUTATION = gql`
  mutation UpdateGroup(
    $groupId: Int!
    $name: String
    $parentGroupId: Int
    $customerId: Int
    $externalCustomerIds: [Int]
  ) {
    updateGroup(
      groupId: $groupId
      name: $name
      parentGroupId: $parentGroupId
      customerId: $customerId
      externalCustomerIds: $externalCustomerIds
    ) {
      id
      name
      parentGroupId
      customerId
    }
  }
`;

export default UPDATE_GROUP_MUTATION;
