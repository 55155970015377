import gql from "graphql-tag";

const UPDATE_LINE_MUTATION = gql`
  mutation UpdateLine(
    $id: Int!
    $lineNumber: String
    $SimNumber: String
    $customerId: Int!
    $commTypeId: Int!
    $email: String
    $deviceId: Int
  ) {
    updateLine(
      id: $id
      lineNumber: $lineNumber
      SimNumber: $SimNumber
      customerId: $customerId
      commTypeId: $commTypeId
      email: $email
      deviceId: $deviceId
    ) {
      id
    }
  }
`;

export default UPDATE_LINE_MUTATION;
