import React, { useEffect, useState } from "react";

import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { getOperationName } from "apollo-link";

import ConnectedClientForm from "../../../containers/Manage/ConnectedClientForm";
import Structure from "../../../components/Base/Structure";

import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import TECHNICIANS_QUERY from "../../../graphql/queries/getTechniciansQuery";
import CREATE_TECHNICIAN_MUTATION from "../../../graphql/mutations/createTechnicianMutation";
import UPDATE_TECHNICIAN_MUTATION from "../../../graphql/mutations/updateTechnicianMutation";
import DELETE_TECHNICIAN_MUTATION from "../../../graphql/mutations/deleteTechnicianMutation";

import PageTitle from "../../../components/Base/PageTitle";

import TableTechnician from "./List";
import "./Technician.scss";

import { useTranslation } from "../../../lang";

const ManageTechnicianPage = ({}) => {
  const [t] = useTranslation();
  const paginationSizes = [10, 25, 50];
  const [total, setTotal] = useState(0);
  const [technicianList, setTechnicianList] = useState([]);
  const [paginationState, setPaginationState] = useState({
    pageSize: paginationSizes[0],
    pageNumber: 1,
    sort: undefined,
    filter: null,
  });

  const [resultState, setResultState] = useState({
    isOpen: false,
    type: "success",
    msg: "",
  });

  const [getTechnicians] = useLazyQuery(TECHNICIANS_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      if (d.technicians) {
        setTechnicianList(d.technicians);
        setTotal(d.technicians.length);
      }
    },
  });

  const handleGetTechnicians = () => {
    getTechnicians({
      variables: {
        ...paginationState,
        paginate: true,
      },
    });
  };

  useEffect(() => {
    handleGetTechnicians();
  }, [paginationState]);

  const [createTechnician] = useMutation(CREATE_TECHNICIAN_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Técnico creado correctamente"),
      });
      handleGetTechnicians();
    },
    onError(err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: t("Ocurrió un error"),
      });
    },
  });

  const [updateTechnician] = useMutation(UPDATE_TECHNICIAN_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Técnico actualizado correctamente"),
      });
      handleGetTechnicians();
    },
    onError(err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: t("Ocurrió un error"),
      });
    },
  });

  const [deleteTechnician] = useMutation(DELETE_TECHNICIAN_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Técnico eliminado correctamente"),
      });
      handleGetTechnicians();
    },
    onError(d) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: t("Ocurrió un error"),
      });
    },
  });

  const handleDeleteTechnician = (id) => {
    deleteTechnician({
      variables: { id: id },
    });
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setResultState({
      ...resultState,
      isOpen: false,
    });
  };

  return (
    <Structure>
      <Snackbar
        open={resultState.isOpen}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleCloseNotification}
          severity={resultState.type}
          variant="filled"
        >
          {resultState.msg}
        </MuiAlert>
      </Snackbar>
      <PageTitle sector={t("Administración")} title={t("Técnicos")} />
      <TableTechnician
        getTechnicians={handleGetTechnicians}
        technicianList={technicianList}
        paginationSizes={paginationSizes}
        paginationState={paginationState}
        setPaginationState={setPaginationState}
        total={total}
        setResultState={setResultState}
        createTechnician={createTechnician}
        updateTechnician={updateTechnician}
        deleteTechnician={handleDeleteTechnician}
      />
    </Structure>
  );
};

export default ManageTechnicianPage;
