import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";

import { ReduxTextField } from "../Base/ReduxFormFields";
import { useTranslation } from "../../lang";

const LoginForm = ({ handleLoginSubmit, handleLogoutSubmit }) => {
  const [t] = useTranslation();

  const _handleLoginSubmit = (event) => {
    event.preventDefault();

    handleLoginSubmit();
  };

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={_handleLoginSubmit}
      className="login-container__item login__form"
    >
      <Field
        component={ReduxTextField}
        id="username-login-field"
        name="username"
        label={t("Usuario")}
        placeholder={t("Usuario")}
        fullWidth
        margin="normal"
      />
      <Field
        component={ReduxTextField}
        id="password-login-field"
        name="password"
        type="password"
        label={t("Contraseña")}
        placeholder={t("Contraseña")}
        fullWidth
        margin="normal"
      />
      <div className="login-container__submit">
        <Button variant="contained" color="primary" type="submit">
          {t("Ingresar")}
        </Button>
      </div>
    </form>
  );
};

LoginForm.propTypes = {
  handleLoginSubmit: PropTypes.func,
};

LoginForm.defaultProps = {
  handleLoginSubmit: () => {},
};

export default reduxForm({
  form: "login",
})(LoginForm);
