import React from "react";
import {
  Button,
  Checkbox,
  createMuiTheme,
  FormControlLabel,
  FormGroup,
  Grid,
  ThemeProvider,
} from "@material-ui/core";

import * as Yup from "yup";
import { Field, Formik } from "formik";

import { useTranslation } from "../../../lang";

const MaxSpeedForm = ({ setIsOpen, selectedAsset, updateAsset }) => {
  const [t] = useTranslation();
  const initialValues = {
    assetId: selectedAsset?.id,
    maximumSpeed: selectedAsset?.maximumSpeed || "",
    controlInvalidSpeed: selectedAsset?.controlInvalidSpeed,
    invalidMaximumSpeed: selectedAsset?.invalidMaximumSpeed || "",
  };

  const yupSchema = Yup.object().shape({
    maximumSpeed: Yup.number(t("Este campo debe ser numérico")).min(
      0,
      t("Este campo bebe ser mayor que cero")
    ),
    averageDailyKm: Yup.number(t("Este campo debe ser numérico")).min(
      0,
      t("Este campo bebe ser mayor que cero")
    ),
    invalidMaximumSpeed: Yup.number(t("Este campo debe ser numérico")).min(
      0,
      t("Este campo bebe ser mayor que cero")
    ),
  });

  const theme = createMuiTheme({});

  return (
    <>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={yupSchema}
        onSubmit={async (values) => {
          updateAsset({
            variables: {
              ...values,
              maximumSpeed: values.maximumSpeed || 0,
              controlInvalidSpeed: values.controlInvalidSpeed,
              invalidMaximumSpeed: values.invalidMaximumSpeed || 0,
              name: selectedAsset.name,
            },
          });
        }}
      >
        {(props) => {
          const { errors, handleSubmit, setFieldValue, values } = props;

          return (
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <label className="formLabelFormik">
                  {t("Velocidad Máxima")}
                </label>
                <Field
                  type="number"
                  name="maximumSpeed"
                  tabIndex="0"
                  className={
                    errors.maximumSpeed
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.maximumSpeed && (
                  <div className="input-feedback">{errors.maximumSpeed}</div>
                )}
              </FormGroup>
              <FormGroup>
                <ThemeProvider theme={theme}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={values.controlInvalidSpeed}
                        onChange={(evt) =>
                          setFieldValue(
                            "controlInvalidSpeed",
                            evt.target.checked
                          )
                        }
                        name="controlInvalidSpeed"
                      />
                    }
                    label={t("Verificar velocidad inválida")}
                  />
                </ThemeProvider>
                {errors.controlInvalidSpeed && (
                  <div className="input-feedback">
                    {errors.controlInvalidSpeed}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">
                  {t("Invalidar velocidad superior a")}
                </label>
                <Field
                  type="number"
                  name="invalidMaximumSpeed"
                  tabIndex="0"
                  className={
                    errors.invalidMaximumSpeed
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.invalidMaximumSpeed && (
                  <div className="input-feedback">
                    {errors.invalidMaximumSpeed}
                  </div>
                )}
              </FormGroup>

              <Grid item lg={12} style={{ marginTop: "50px" }}>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  tabIndex="0"
                >
                  {t("Actualizar")}
                </Button>
                <Button
                  variant="contained"
                  type="button"
                  tabIndex="0"
                  style={{ marginLeft: "5px" }}
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  {t("Cancelar")}
                </Button>
                {/* {ecoTrackingId && (
                  <Button
                    variant="contained"
                    type="button"
                    tabIndex="0"
                    color="secondary"
                    style={{ marginLeft: "5px" }}
                    onClick={async () => {
                      await deleteEcoTracking({
                        variables: {
                          id: ecoTrackingId,
                          assetId: initialValues.assetId,
                        },
                      });
                      setIsOpen(false);
                    }}
                  >
                    Eliminar
                  </Button>
                )} */}
              </Grid>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default MaxSpeedForm;
