import { connect } from 'react-redux';

import { loginAction } from '../redux/user/actions';

import Login from '../components/Login/Login';

const _mapStateToProps = ({ frontend }) => ({
    isLoading: frontend.isLoading
});

const _mapDispatchToProps = {
    handleLoginSubmit: loginAction
};

export default connect(_mapStateToProps, _mapDispatchToProps)(Login);
