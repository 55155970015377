import { connect } from 'react-redux';

import { clearNotification } from '../redux/notifications/actions';

import Notification from '../components/Base/Notification';

const _mapStateToProps = state => {
    return {
        notificationType: state.notifications.notificationType,
        notificationMessage: state.notifications.message
    };
};

const _mapDispatchToProps = {
    clearNotification
};

export default connect(_mapStateToProps, _mapDispatchToProps)(Notification);
