import React, { useState, useEffect } from "react";

import { FormGroup, Button, Tooltip, Grid, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import * as Yup from "yup";
import { Field, withFormik } from "formik";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { connect } from "react-redux";

import CustomFormikSelect from "../Base/CustomFormikSelect";
import GenericSelector from "../Selectors/GenericSelector";

import { ReduxKeyboardDateField as KeyboardDateField } from "../../components/Base/ReduxFormFields";
import ModalConfirmacion from "../Base/ModalConfirmacion";

import {
  closePanelAux,
  setRevisionNoticeList,
} from "../../redux/lateralPanel/actions";

import SERVICE_TYPE_QUERY from "../../graphql/queries/getServiceTypeQuery";
import GET_FREQUENCY_QUERY from "../../graphql/queries/getFrequencyQuery";
import USER_BYCUSTOMER_QUERY from "../../graphql/queries/usersByCustomerQuery";
import CREATE_SERVICE_MUTATION from "../../graphql/mutations/createServiceMutation";
import UPDATE_SERVICE_MUTATION from "../../graphql/mutations/updateServiceMutation";
import DELETE_SERVICE_MUTATION from "../../graphql/mutations/deleteServiceMutation";
import { useTranslation } from "../../lang";
import { GetDateFormat } from "../../utils/generalUtils";

const FormRevisionNotice = ({
  mid,
  selectedRevisionNotice,
  selectedCustomers,
  assetList,
  closePanelAux,
  setRevisionNoticeList,
  revisionNoticeList,
  user,
}) => {
  const [t] = useTranslation();
  const [serviceTypes, setServiceTypes] = useState([]);
  const [frequencies, setFrecuencies] = useState([]);
  const [users, setUsers] = useState([]);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const closeModalConfirm = () => setIsOpenConfirm(false);
  const [confirmText, setConfirmText] = useState();

  const [resultState, setResultState] = useState({
    isOpen: false,
    type: "success",
    msg: "",
  });

  const initialValues = {
    id: selectedRevisionNotice?.id,
    assetIds: mid, //mid ? [mid] : null,
    serviceTypeId: selectedRevisionNotice?.serviceType?.id || "",
    frequencyId: selectedRevisionNotice?.frequency?.id || "",
    quantityKm: selectedRevisionNotice?.quantityKm || 0,
    percentageAlert: selectedRevisionNotice?.percentageAlert || "",
    emailsList: selectedRevisionNotice?.emailsList || "",
    dateStart: selectedRevisionNotice?.dateStart || new Date(),
    userIds:
      Array.isArray(selectedRevisionNotice?.users) &&
      selectedRevisionNotice?.users?.length > 0
        ? selectedRevisionNotice?.users.map((u) => u.id)
        : null,
  };

  const yupSchema = Yup.object().shape({
    assetIds: Yup.string().required(t("Requerido")),
    serviceTypeId: Yup.number().required(t("Requerido")),
    frequencyId: Yup.number().required(t("Requerido")),
    percentageAlert: Yup.number().required(t("Requerido")),
    dateStart: Yup.date().required(t("Requerido")),
  });

  const [fetchUsers] = useLazyQuery(USER_BYCUSTOMER_QUERY, {
    onCompleted(d) {
      setUsers(d.usersByCustomer);
    },
  });

  const [fetchServiceTypes] = useLazyQuery(SERVICE_TYPE_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      setServiceTypes(
        d.serviceType.map((st) => {
          return {
            label: t(st.description),
            value: st.id,
          };
        })
      );
    },
  });

  const [fetchFrequencies] = useLazyQuery(GET_FREQUENCY_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      setFrecuencies(
        d.frequency.map((f) => {
          return {
            label: t(f.description.replace(" ", "")),
            value: f.id,
          };
        })
      );
    },
  });

  const [createService] = useMutation(CREATE_SERVICE_MUTATION, {
    //refetchQueries: [getOperationName(SERVICE_BYASSETS_QUERY)]
  });

  const handleCreateService = async (values, resetForm) => {
    try {
      const newServices = await createService({ variables: values });
      resetForm();
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Aviso de revision creado correctamente"),
      });

      setRevisionNoticeList([
        ...revisionNoticeList,
        ...newServices.data.createService,
      ]);
    } catch (err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: err.message,
      });
    }
  };

  const [updateService] = useMutation(UPDATE_SERVICE_MUTATION);

  const handleUpdateService = async (values) => {
    try {
      const updatedService = await updateService({ variables: values });
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Aviso de revision actualizado correctamente"),
      });

      const updatedData = updatedService.data.updateService;
      const index = revisionNoticeList.findIndex(
        (rn) => rn.id === updatedData.id
      );

      const newList = [
        ...revisionNoticeList.slice(0, index),
        updatedData,
        ...revisionNoticeList.slice(index + 1),
      ];
      setRevisionNoticeList(newList);
    } catch (err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: err.message,
      });
    }
  };

  const [deleteService] = useMutation(DELETE_SERVICE_MUTATION);

  const handleDeleteService = async () => {
    try {
      await deleteService({ variables: { id: selectedRevisionNotice?.id } });
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Aviso de revision eliminado correctamente"),
      });
      setRevisionNoticeList(
        revisionNoticeList.filter((x) => x.id !== selectedRevisionNotice?.id)
      );
      closePanelAux();
    } catch (err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: err.message,
      });
    }
  };

  useEffect(() => {
    let customerId;

    if (Array.isArray(selectedCustomers) && selectedCustomers.length > 0) {
      customerId = selectedCustomers[selectedCustomers.length - 1].value;
    }

    if (customerId) {
      fetchUsers({
        variables: {
          customerId: customerId,
        },
      });
    }
  }, [selectedCustomers]);

  useEffect(() => {
    fetchServiceTypes();
    fetchFrequencies();
  }, [user.language]);

  const columnsUsers = [
    {
      dataField: "firstName",
      text: t("Nombre"),
      sort: true,
    },
    {
      dataField: "lastName",
      text: t("Apellido"),
      sort: true,
    },
    {
      dataField: "userName",
      text: t("Usuario"),
      sort: true,
    },
    {
      dataField: "email",
      text: t("Email"),
      sort: true,
    },
  ];

  const columns = [
    {
      dataField: "name",
      text: t("Nombre"),
      sort: true,
    },
    {
      dataField: "licensePlate",
      text: t("Patente"),
      sort: true,
    },
    {
      dataField: "internalNumber",
      text: t("Interno"),
      sort: true,
    },
    {
      dataField: "driver.firstName",
      text: t("Chofer"),
      sort: true,
    },
    {
      dataField: "typeMobile.description",
      text: t("Tipo"),
      sort: true,
    },
  ];

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setResultState({
      ...resultState,
      isOpen: false,
    });
  };

  const BaseForm = (props) => {
    const { values, errors, handleSubmit, setFieldValue } = props;

    return (
      <form onSubmit={handleSubmit} style={{ padding: "10px 20px" }}>
        {!mid && (
          <FormGroup>
            <GenericSelector
              callbackOptionSelected={(assetsSelected) =>
                setFieldValue(
                  "assetIds",
                  assetsSelected?.map((a) => a.value)
                )
              }
              columns={columns}
              isMulti
              keyField="id"
              labelField="name"
              title={t("Móviles")}
              data={assetList}
            />
          </FormGroup>
        )}
        <FormGroup>
          <Tooltip
            title={t(
              "Seleccione el motivo por el cual quiere generar una alerta periodica"
            )}
            placement="top"
            arrow
          >
            <label className="formLabelFormik">{t("Tipo de Revisión")}</label>
          </Tooltip>
          <Field
            name="serviceTypeId"
            component={CustomFormikSelect}
            options={serviceTypes}
          />
          {errors.serviceTypeId && (
            <div className="input-feedback">{errors.serviceTypeId}</div>
          )}
        </FormGroup>
        <FormGroup>
          <Tooltip
            title={t(
              "Fecha pasada o futura a partir de la cual comienza el conteo para el primer aviso"
            )}
            placement="top"
            arrow
          >
            <label className="formLabelFormik">{t("Fecha")}</label>
          </Tooltip>
          <Field
            component={KeyboardDateField}
            id="dateStart"
            name="dateStart"
            //label="Fecha Instalación Corte de Combustible"
            placeholder={t("Seleccionar")}
            disableToolbar
            variant="inline"
            // format="dd/MM/yyyy"
            format={GetDateFormat(user.language, true)}
            margin="normal"
            value={values.dateStart}
            onChange={(value) => {
              setFieldValue("dateStart", value);
            }}
          />
        </FormGroup>
        <FormGroup>
          <Grid container>
            <Grid item xs={8}>
              <Tooltip
                title={t("Cada cuantos kms o meses se desea el aviso")}
                placement="top"
                arrow
              >
                <label className="formLabelFormik">{t("Frecuencia")}</label>
              </Tooltip>
              <Field
                name="frequencyId"
                component={CustomFormikSelect}
                options={frequencies}
              />
              {errors.frequencyId && (
                <div className="input-feedback">{errors.frequencyId}</div>
              )}
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={3}>
              {/* Si la frecuencia es kms u horas de trabajo se muestra la cantidad */}
              {[6, 7].includes(values.frequencyId) && (
                <>
                  <label className="formLabelFormik">{t("Cantidad")}</label>
                  <Field
                    type="number"
                    name="quantityKm"
                    tabIndex="0"
                    className={
                      errors.quantityKm
                        ? "form-control text-input error"
                        : "form-control text-input"
                    }
                    autoComplete="off"
                  />
                  {errors.quantityKm && (
                    <div className="input-feedback">{errors.quantityKm}</div>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </FormGroup>
        <FormGroup>
          <Tooltip
            title={t("Porcentaje de kms o tiempo restante para la revision")}
            placement="top"
            arrow
          >
            <label className="formLabelFormik">
              {t("Porcentaje de alerta")}
            </label>
          </Tooltip>
          <Field
            type="number"
            name="percentageAlert"
            tabIndex="0"
            className={
              errors.percentageAlert
                ? "form-control text-input error"
                : "form-control text-input"
            }
            autoComplete="off"
          />
          {errors.percentageAlert && (
            <div className="input-feedback">{errors.percentageAlert}</div>
          )}
        </FormGroup>
        <FormGroup>
          <GenericSelector
            callbackOptionSelected={(usersSelected) =>
              setFieldValue(
                "userIds",
                usersSelected ? usersSelected.map((us) => us.value) : []
              )
            }
            columns={columnsUsers}
            keyField="id"
            labelField="userName"
            isMulti
            title={t("Usuarios")}
            data={users}
            defaultValue={values?.userIds}
          />
        </FormGroup>
        <FormGroup>
          <Tooltip
            title={t(
              "Personas no registradas en el sistema que recibiran el aviso"
            )}
            placement="top"
            arrow
          >
            <label>{t("Agregar email no registrado")}</label>
          </Tooltip>
          <Field
            as="textarea"
            name="emailsList"
            className={
              errors.emailsList
                ? "form-control text-input error"
                : "form-control text-input"
            }
          />
        </FormGroup>
        <div className="buttonContainerFrm">
          <Button color="primary" variant="contained" type="submit">
            {t("Guardar")}
          </Button>
          {selectedRevisionNotice && (
            <Button
              color="secondary"
              variant="contained"
              type="button"
              style={{ marginLeft: "15px" }}
              onClick={() => {
                setIsOpenConfirm(true);
                setConfirmText(
                  t("¿Está seguro que desea eliminar el aviso de revisión?")
                );
              }}
            >
              {t("Eliminar")}
            </Button>
          )}
        </div>
      </form>
    );
  };

  const WrappedForm = withFormik({
    mapPropsToValues: () => initialValues,
    validationSchema: yupSchema,
    validateOnChange: false,
    validateOnBlur: false,
    handleSubmit: async (values, { resetForm }) => {
      if (selectedRevisionNotice) {
        const newValues = {
          ...values,
          assetId: values.assetIds,
        };
        delete newValues.assetIds;
        handleUpdateService(newValues);
      } else {
        handleCreateService(values, resetForm);
      }
    },
  })(BaseForm);

  return (
    <>
      <ModalConfirmacion
        isOpen={isOpenConfirm}
        buttonConfirmation="Confirmar eliminación"
        closeModal={closeModalConfirm}
        confirmationText={confirmText}
        handleConfirm={() => {
          handleDeleteService();
        }}
        title={t("IMPORTANTE")}
      />
      <Snackbar
        open={resultState.isOpen}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleCloseNotification}
          severity={resultState.type}
          variant="filled"
        >
          {resultState.msg}
        </MuiAlert>
      </Snackbar>
      <WrappedForm />
    </>
  );
};

const _mapStateToProps = ({ groups, lateralPanel, user }) => {
  return {
    selectedCustomers: groups.selectedCustomers,
    assetList: lateralPanel.newPanelReducer.assetList,
    mid: lateralPanel.newPanelReducer.mid,
    selectedRevisionNotice: lateralPanel.newPanelReducer.revisionNoticeSelected,
    revisionNoticeList: lateralPanel.newPanelReducer.revisionNoticeList,
    user,
  };
};

const _mapDispatchToProps = (dispatch) => {
  return {
    closePanelAux: () => dispatch(closePanelAux()),
    setRevisionNoticeList: (list) => dispatch(setRevisionNoticeList(list)),
  };
};

export default connect(
  _mapStateToProps,
  _mapDispatchToProps
)(FormRevisionNotice);
