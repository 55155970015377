import { Button, FormGroup, Grid, IconButton } from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { format } from "date-fns";
import React, { useState } from "react";
import Select from "react-select";
import DateSelector from "../../../components/Selectors/DateSelector";
import GenericSelector from "../../../components/Selectors/GenericSelector";
import IconExcel from "../../../public/iconExcel.png";
import IconKML from "../../../public/iconKML.png";
import IconPDF from "../../../public/iconPDF.png";
import CustomSelectStyles from "../../../utils/reactSelectStyle";
import { useTranslation } from "../../../lang";
import { localDateTimeStringToUTC } from "../../../utils/dates";
import { hasPermission } from "../../../utils/generalUtils";

const dateFormat = "yyyy-MM-dd HH:mm:ss";

const theme = createMuiTheme({});

const Filter = ({
  assets,
  drivers,
  typeEventList,
  searchReport,
  handleExportExcel,
  handleExportPDF,
  handleExportKML,
  errors,
  reportResult,
  user,
}) => {
  const [t] = useTranslation();
  const [fechas, setFechas] = useState({
    fechaDesde: format(new Date(new Date() - 60 * 60 * 1000), dateFormat),
    fechaHasta: format(new Date(), dateFormat),
  });

  const [dias, setDias] = useState([
    "LUNES",
    "MARTES",
    "MIERCOLES",
    "JUEVES",
    "VIERNES",
    "SABADO",
    "DOMINGO",
  ]);

  const [assetsSelected, setAssetsSelected] = useState();
  const [driversSelected, setDriversSelected] = useState();
  const [typeEventSelected, setTypeEventSelected] = useState();

  const callbackAssetSelected = (values) => {
    setAssetsSelected(values.map((v) => v.value));
  };

  const callbackDriverSelected = (values) => {
    setDriversSelected(values.map((v) => v.value));
  };

  const columnsAssets = [
    {
      dataField: "name",
      text: t("Nombre"),
      sort: true,
    },
    {
      dataField: "licensePlate",
      text: t("Patente"),
      sort: true,
    },
    {
      dataField: "internalNumber",
      text: t("Interno"),
      sort: true,
    },
    {
      dataField: "driver.firstName",
      text: t("Chofer"),
      sort: true,
    },
    {
      dataField: "typeMobile.description",
      text: t("Tipo"),
      sort: true,
    },
  ];
  const columnsDrivers = [
    {
      dataField: "firstName",
      text: t("Nombre"),
      sort: true,
    },
    {
      dataField: "lastName",
      text: t("Apellido"),
      sort: true,
    },
  ];

  const handleChangePeriod = (evt) => {
    if (evt.fechaDesde && evt.fechaHasta) {
      setFechas({
        fechaDesde: localDateTimeStringToUTC(evt.fechaDesde),
        fechaHasta: localDateTimeStringToUTC(evt.fechaHasta),
      });
    }

    if (evt.dias) setDias(evt.dias);
  };

  return (
    <div style={{ padding: "20px" }}>
      <ThemeProvider theme={theme}>
        <Grid container>
          <Grid item xs={5}>
            <DateSelector onChange={handleChangePeriod} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={5}>
            <GenericSelector
              callbackOptionSelected={callbackAssetSelected}
              columns={columnsAssets}
              data={assets}
              keyField="id"
              labelField="licensePlate"
              title={t("Móviles")}
              isMulti
            />
            {errors && errors.assets && (
              <div className="input-feedback">{errors.assets}</div>
            )}
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={5}>
            {hasPermission(
              "MostrarFiltroDeConductoresEnReportesDeEstadistica",
              user.roles
            ) && (
              <GenericSelector
                callbackOptionSelected={callbackDriverSelected}
                columns={columnsDrivers}
                keyField="id"
                data={drivers}
                labelField="fullName"
                title={t("Conductores")}
                isMulti
              />
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={5}>
            <FormGroup>
              <label className="formLabelFormik">{t("Eventos")}</label>
            </FormGroup>
            <Select
              value={typeEventSelected}
              options={typeEventList}
              isMulti
              onChange={(opt) => setTypeEventSelected(opt)}
              styles={CustomSelectStyles}
              placeholder={t("Seleccionar_")}
            />
          </Grid>
          <Grid item xs={2} />
        </Grid>
        <Grid container className="filterReportButtons">
          <Button
            color="primary"
            variant="contained"
            onClick={() =>
              searchReport({
                assetsSelected,
                driversSelected,
                typeEventSelected:
                  Array.isArray(typeEventSelected) &&
                  typeEventSelected.length > 0
                    ? typeEventSelected.map((te) => te.value)
                    : undefined,
                fechaDesde: fechas.fechaDesde,
                fechaHasta: fechas.fechaHasta,
                dias,
                validations: [
                  {
                    field: assetsSelected,
                    fieldName: "assets",
                    errorMessage: t("Debe seleccionar al menos un móvil"),
                    // validationType: "required",
                  },
                ],
              })
            }
          >
            {t("Generar Reporte")}
          </Button>
          {reportResult && (
            <div style={{ marginLeft: "auto" }}>
              <IconButton
                title={t("EXCEL")}
                onClick={() =>
                  handleExportExcel(
                    assetsSelected,
                    driversSelected,
                    Array.isArray(typeEventSelected) &&
                      typeEventSelected.length > 0
                      ? typeEventSelected.map((te) => te.value)
                      : undefined,
                    fechas.fechaDesde,
                    fechas.fechaHasta,
                    dias
                  )
                }
              >
                <img src={IconExcel} className="exportIcon" />
              </IconButton>
              <IconButton
                title={t("PDF")}
                onClick={() =>
                  handleExportPDF(
                    assetsSelected,
                    driversSelected,
                    Array.isArray(typeEventSelected) &&
                      typeEventSelected.length > 0
                      ? typeEventSelected.map((te) => te.value)
                      : undefined,
                    fechas.fechaDesde,
                    fechas.fechaHasta,
                    dias
                  )
                }
              >
                <img src={IconPDF} className="exportIcon" />
              </IconButton>
              <IconButton
                title={t("KML")}
                onClick={() =>
                  handleExportKML(
                    assetsSelected,
                    driversSelected,
                    Array.isArray(typeEventSelected) &&
                      typeEventSelected.length > 0
                      ? typeEventSelected.map((te) => te.value)
                      : undefined,
                    fechas.fechaDesde,
                    fechas.fechaHasta,
                    dias
                  )
                }
              >
                <img src={IconKML} className="exportIcon" />
              </IconButton>
            </div>
          )}
        </Grid>
      </ThemeProvider>
    </div>
  );
};

export default Filter;
