// import { combineReducers } from 'redux';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS,
  SET_CURRENT_USER,
} from "./actions";

const user = (state = {}, { type, payload }) => {
  let newState = state;

  if (type === LOGIN_REQUEST) {
    newState = payload;
  } else if (type === LOGIN_SUCCESS) {
    const { user: _user, token, redirectTo } = payload;
    newState = {
      data: _user,
      loggedIn: true,
      token,
      redirectTo,
    };
  } else if (type === LOGIN_FAILURE) {
    newState = payload;
  } else if (type === LOGOUT_SUCCESS) {
    newState = {};
  } else if (type === SET_CURRENT_USER) {
    const { language, firstName, lastName, photo } = payload;
    newState = {
      ...state,
      language,
      firstName,
      lastName,
      photo,
    };
  }

  return newState;
};

export default user;
