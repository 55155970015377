import React, { useState } from "react";
import { IconButton, Paper, TextField, Button } from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  Add,
  Check,
  ChevronRight,
  ExpandMore,
  LocalShipping,
  Remove,
} from "@material-ui/icons";
import { TreeItem, TreeView } from "@material-ui/lab";
import ModalAssociateFleet from "./ModalAssociateFleet";
import { hasPermission } from "../../../utils/generalUtils";
import { useTranslation } from "../../../lang";

import ThreeDotMenu from "../../../components/Base/ThreeDotMenu";
import ModalGroup from "./ModalGroup";
import ModalAssets from "./ModalAssets";
import ModalUsers from "./ModalUsers";
import ModalSubUser from "./ModalSubUser";

const theme = createMuiTheme({});

const GroupPanel = ({
  groups,
  onSelect,
  handleCreateGroup,
  selectedGroup,
  handleUpdateGroup,
  handleDeleteGroup,
  handleAssociateFleet,
  loggedUser,
  handleAssignAsset,
  usersAvailable,
  handleAssignUser,
}) => {
  const [t] = useTranslation();
  const [groupName, setGroupName] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => setIsOpen(false);
  const [isModalGroupOpen, setIsModalGroupOpen] = useState(false);
  const [isModalAssetsOpen, setIsModalAssetsOpen] = useState(false);
  const [isModalUsersOpen, setIsModalUsersOpen] = useState(false);
  const [assetsAvailable, setAssetsAvailable] = useState([]);
  const [nodeOver, setNodeOver] = useState();
  const [groupToUpdate, setGroupToUpdate] = useState();

  const [isUserFormOpen, setIsUserFormOpen] = useState(false);
  const closeModalUserForm = () => setIsUserFormOpen(false);

  const closeModalAssets = () => {
    setIsModalAssetsOpen(false);
    setAssetsAvailable([]);
  };

  const closeModalUsers = () => {
    setIsModalUsersOpen(false);
  };

  const closeModalGroup = () => {
    setIsModalGroupOpen(false);
    setGroupToUpdate(null);
  };

  const groupActionValues = {
    CREATE_SUBGROUP: 1,
    CHANGE_NAME: 2,
    ASSIGN_ASSETS: 3,
    ASSIGN_USERS: 4,
    DELETE: 5,
    CREATE_SUBUSER: 6,
  };

  const groupActions = [
    {
      label: t("Crear subgrupo"), //'Create subgroup',
      value: groupActionValues.CREATE_SUBGROUP,
    },
    {
      label: t("Cambiar nombre"), //'Change name',
      value: groupActionValues.CHANGE_NAME,
    },
    {
      label: t("Asignar moviles"), //'Assign assets',
      value: groupActionValues.ASSIGN_ASSETS,
    },
    {
      label: t("Asignar usuarios"), // 'Assign users',
      value: groupActionValues.ASSIGN_USERS,
    },
    {
      label: t("Eliminar grupo"), //'Delete',
      value: groupActionValues.DELETE,
    },
    {
      label: t("Crear sub-usuario"), // 'Create subuser',
      value: groupActionValues.CREATE_SUBUSER,
    },
  ];

  const handleAddAsset = (rows) => {
    handleAssignAsset(selectedGroup.id, rows);
  };

  const handleSelectGroup = (group) => {
    setGroupName(group.name);
    onSelect(group);
  };

  const handleMouseEnterGroup = (nodeId) => {
    setNodeOver(nodeId);
  };

  const handleCreateSubgroup = (name) => {
    if (!name) {
      alert("The name cannot be empty");
      return;
    }

    handleCreateGroup(name, selectedGroup.id, selectedGroup.customerId);
    closeModalGroup();
  };

  const handleChangeName = (name) => {
    if (!name) {
      alert("The name cannot be empty");
      return;
    }

    handleUpdateGroup(groupToUpdate.id, name);
    setGroupToUpdate(null);
    closeModalGroup();
    return;
  };

  const handleAddUser = (rows) => {
    handleAssignUser(selectedGroup, rows);
  };

  const renderNodes = (node) => {
    const childs = groups.filter((x) => x.parentGroupId === node.id);
    return childs.map((ch) => (
      <TreeItem
        nodeId={ch.id}
        onMouseEnter={() => handleMouseEnterGroup(ch.id)}
        onMouseLeave={() => handleMouseEnterGroup(null)}
        // label={ch.name}
        onClick={() => handleSelectGroup(ch)}
        label={
          <>
            <span>{ch.name}</span>
            {ch.id === nodeOver && (
              <ThreeDotMenu
                row={ch}
                buttonClass="alignRight"
                options={groupActions}
                callback={callbackGroupAction}
              />
            )}
          </>
        }
      >
        {renderNodes(ch)}
      </TreeItem>
    ));
  };

  const callbackGroupAction = (action, group) => {
    switch (action) {
      case groupActionValues.CREATE_SUBGROUP:
        setIsModalGroupOpen(true);
        break;
      case groupActionValues.CHANGE_NAME:
        setIsModalGroupOpen(true);
        setGroupToUpdate(group);
        break;
      case groupActionValues.DELETE:
        handleDeleteGroup(group.id);
        break;
      case groupActionValues.ASSIGN_ASSETS:
        const parentGroup = groups.find((g) => g.id === group.parentGroupId);
        setAssetsAvailable(parentGroup?.assets || []);
        setIsModalAssetsOpen(true);
        break;
      case groupActionValues.ASSIGN_USERS:
        setIsModalUsersOpen(true);
        break;
      case groupActionValues.CREATE_SUBUSER:
        setIsUserFormOpen(true);
        break;
      default:
        break;
    }
  };

  const renderRoots = () => {
    const roots = groups.filter((x) => !x.parentGroupId);
    const treeRoots = roots.map((r) => (
      <TreeItem
        nodeId={r.id}
        onMouseEnter={() => handleMouseEnterGroup(r.id)}
        onMouseLeave={() => handleMouseEnterGroup(null)}
        label={
          <>
            <span>{r.name}</span>
            {r.id === nodeOver && (
              <ThreeDotMenu
                row={r}
                buttonClass="alignRight"
                options={groupActions}
                callback={callbackGroupAction}
              />
            )}
          </>
        }
        onClick={() => handleSelectGroup(r)}
      >
        {renderNodes(r)}
      </TreeItem>
    ));
    return treeRoots;
  };

  return (
    <ThemeProvider theme={theme}>
      <ModalAssociateFleet
        closeModal={closeModal}
        isOpen={isOpen}
        onSelect={(groupId, externalCustomers) =>
          handleAssociateFleet(groupId, externalCustomers)
        }
      />
      <ModalGroup
        isOpen={isModalGroupOpen}
        closeModal={closeModalGroup}
        title={
          groupToUpdate
            ? `Change name to ${groupToUpdate?.name}`
            : "Create subgroup"
        }
        handleAction={groupToUpdate ? handleChangeName : handleCreateSubgroup}
      />
      <ModalAssets
        assetsList={assetsAvailable}
        closeModal={closeModalAssets}
        isOpen={isModalAssetsOpen}
        onSelect={handleAddAsset}
      />
      <ModalUsers
        usersList={usersAvailable}
        closeModal={closeModalUsers}
        isOpen={isModalUsersOpen}
        onSelect={handleAddUser}
      />
      <ModalSubUser
        closeModal={closeModalUserForm}
        isOpen={isUserFormOpen}
        customerId={selectedGroup?.customerId}
        group={selectedGroup}
      />
      <div className="groupContainer">
        <div>
          <h5 className="groupContainerLabel">{t("Grupos")}</h5>
        </div>
        <div>
          <h6 className="groupContainerLabel">
            {t("pages_manage_groups_grupos_pertenecientes_al_cliente", {
              customerName: groups ? groups[0]?.customer?.name : "",
            })}
          </h6>
        </div>
        <div style={{ paddingBottom: "10px" }}>
          {/* <TextField
          value={groupName}
          onChange={(evt) => setGroupName(evt.target.value)}
          placeholder={t("Grupo") + "..."}
          label={t("Grupo")}
          variant="outlined"
          size="small"
          style={{ width: "50%" }}
        />
        <IconButton
          className="groupActionIcon"
          title={t("Guardar cambios")}
          onClick={() => {
            if (selectedGroup) {
              handleUpdateGroup(selectedGroup.id, groupName);
            }
          }}
        >
          <Check />
        </IconButton>
        <IconButton
          className="groupActionIcon"
          title={t("Crear grupo")}
          onClick={() => {
            if (selectedGroup) {
              handleCreateGroup(
                t("Nuevo grupo"),
                selectedGroup.id,
                selectedGroup.customerId
              );
            }
          }}
        >
          <Add />
        </IconButton>
        <IconButton
          className="groupActionIcon"
          title={t("Eliminar grupo")}
          onClick={() => {
            if (selectedGroup) {
              handleDeleteGroup(selectedGroup.id);
            }
          }}
        >
          <Remove />
        </IconButton> */}
          {hasPermission("AsignarFlota", loggedUser.roles) && (
            // <IconButton
            //   className="groupActionIcon"
            //   title={t("Asociar otra flota")}
            //   onClick={() => setIsOpen(true)}
            // >
            //   <LocalShipping />
            // </IconButton>
            <Button
              type="button"
              color="primary"
              variant="contained"
              onClick={() => setIsOpen(true)}
            >
              {t("Asignar Flota")}
            </Button>
          )}
        </div>
        <Paper elevation={3} className="groupPaper">
          <TreeView
            defaultCollapseIcon={<ExpandMore />}
            defaultExpandIcon={<ChevronRight />}
          >
            {groups && renderRoots()}
          </TreeView>
        </Paper>
      </div>
    </ThemeProvider>
  );
};

export default GroupPanel;
