import React from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";

import "./PanelForm.scss";

import { useTranslation } from "../../lang";

const PanelForm = ({ children, ...props }) => {
  const [t] = useTranslation();
  return (
    <form className="panel-form" {...props}>
      {children}

      <hr />

      <Button variant="contained" size="large" color="primary">
        {t("Guardar")}
      </Button>
    </form>
  );
};

PanelForm.propTypes = {
  children: PropTypes.node.isRequired,
};

export const InlineFieldsGroup = ({ children }) => (
  <div className="flex center panel-form__inline-group">{children}</div>
);

InlineFieldsGroup.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PanelForm;
