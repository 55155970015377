import gql from "graphql-tag";

const GET_ASSET_BYGROUPS_QUERY = gql`
  query AssetByGroups(
    $groupIDs: [Int]
    $searchText: String
    $pageNumber: Int
    $pageSize: Int
    $paginate: Boolean
    $sort: AssetSortInput
  ) {
    assetsByGroups(
      groupIDs: $groupIDs
      searchText: $searchText
      pageNumber: $pageNumber
      pageSize: $pageSize
      paginate: $paginate
      sort: $sort
    ) {
      count
      items {
        id
        name
        licensePlate
        internalNumber
        mainTension
        brand
        color
        fuelCutWiring
        fuelCutWiringDate
        cutSolenoidValve
        electroValveInstallationDate
        maximumSpeed
        initialRealOdometer
        photo
        icon
        photoFull
        model
        year
        fuelTankCapacity
        fuelTankCapacityMax
        initialRealHorometer
        satPatAlerts
        driver {
          id
          firstName
          lastName
        }
        typeMobile {
          id
          description
        }
        customer {
          id
          name
        }
        assetDevice {
          assetid
          deviceid
          dateAssociated
          technicianId
          device {
            id
            deviceIdentifier
            imei
            serialNumber
            status
          }
        }
        # ecoTracking {
        #   id
        #   assetId
        #   ConsumptionRangeOne
        #   ConsumptionRangeTwo
        #   ConsumptionRangeThree
        #   ConsumptionIdle
        #   CO2Emissions
        # }
        operationNumber
        daysStopMaximum
        averageDailyKm
        averageDailyHorometer
        internalCustomer
        invalidMaximumSpeed
        controlInvalidSpeed
        itSupportsOBD2
      }
    }
  }
`;

export default GET_ASSET_BYGROUPS_QUERY;
