import React from "react";
import { useHistory } from "react-router-dom";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { connect } from "react-redux";

import { closePanel } from "../../redux/lateralPanel/actions";
import { hasPermission } from "../../utils/generalUtils";
import { useTranslation } from "../../lang";

const AdministrationPanel = ({ closePanel, user }) => {
  const history = useHistory();
  const [t] = useTranslation();

  return (
    <List component="nav">
      {hasPermission("AsignarNroGuia", user.roles) && (
        <ListItem
          button
          onClick={() => {
            closePanel();
            history.push("/manage/trackingnumber");
          }}
        >
          <ListItemText primary={t("Asignar N° Guía")} />
        </ListItem>
      )}

      {hasPermission("Clientes", user.roles) && (
        <ListItem
          button
          onClick={() => {
            closePanel();
            history.push("/manage/clients");
          }}
        >
          <ListItemText primary={t("Clientes")} />
        </ListItem>
      )}

      {hasPermission("Conductores", user.roles) && (
        <ListItem
          button
          onClick={() => {
            closePanel();
            history.push("/manage/drivers");
          }}
        >
          <ListItemText primary={t("Conductores")} />
        </ListItem>
      )}

      {hasPermission("ConfiguracionNotificaciones", user.roles) && (
        <ListItem
          button
          onClick={() => {
            closePanel();
            history.push("/manage/confNotifications");
          }}
        >
          <ListItemText primary={t("ConfNotifications")} />
        </ListItem>
      )}

      {hasPermission("Equipos", user.roles) && (
        <ListItem
          button
          onClick={() => {
            closePanel();
            history.push("/manage/devices");
          }}
        >
          <ListItemText primary={t("Equipos")} />
        </ListItem>
      )}

      {hasPermission("Geocercas", user.roles) && (
        <ListItem
          button
          onClick={() => {
            closePanel();
            history.push("/manage/geofences");
          }}
        >
          <ListItemText primary={t("Geocercas")} />
        </ListItem>
      )}

      {hasPermission("Grupos", user.roles) && (
        <ListItem
          button
          onClick={() => {
            closePanel();
            history.push("/manage/groups");
          }}
        >
          <ListItemText primary={t("Grupos")} />
        </ListItem>
      )}

      {hasPermission("Lineas", user.roles) && (
        <ListItem
          button
          onClick={() => {
            closePanel();
            history.push("/manage/lines");
          }}
        >
          <ListItemText primary={t("Líneas")} />
        </ListItem>
      )}

      {hasPermission("Moviles", user.roles) && (
        <ListItem
          button
          onClick={() => {
            closePanel();
            history.push("/manage/assets");
          }}
        >
          <ListItemText primary={t("Móviles")} />
        </ListItem>
      )}

      {hasPermission("Planificador", user.roles) && (
        <ListItem
          button
          onClick={() => {
            closePanel();
            history.push("/manage/reportPlanning");
          }}
        >
          <ListItemText primary={t("Planificador")} />
        </ListItem>
      )}

      {/* {hasPermission("PlanificadorRecorridos", user.roles) && (
        <ListItem button>
          <ListItemText primary={t(Planificador de Recorrido" />
        </ListItem>
      )} */}

      {hasPermission("PuntosInteres", user.roles) && (
        <ListItem
          button
          onClick={() => {
            closePanel();
            history.push("/manage/pois");
          }}
        >
          <ListItemText primary={t("Puntos de interés")} />
        </ListItem>
      )}

      {hasPermission("Roles", user.roles) && (
        <ListItem
          button
          onClick={() => {
            closePanel();
            history.push("/manage/roles");
          }}
        >
          <ListItemText primary={t("Roles")} />
        </ListItem>
      )}

      {hasPermission("Rutas", user.roles) && (
        <ListItem
          button
          onClick={() => {
            closePanel();
            history.push("/manage/road");
          }}
        >
          <ListItemText primary={t("Rutas")} />
        </ListItem>
      )}

      {hasPermission("Técnicos", user.roles) && (
        <ListItem
          button
          onClick={() => {
            closePanel();
            history.push("/manage/technicians");
          }}
        >
          <ListItemText primary={t("Técnicos")} />
        </ListItem>
      )}

      {hasPermission("Usuarios", user.roles) && (
        <ListItem
          button
          onClick={() => {
            closePanel();
            history.push("/manage/users");
          }}
        >
          <ListItemText primary={t("Usuarios")} />
        </ListItem>
      )}

      {/* <ListItem
        button
        onClick={() => {
          closePanel();
          history.push("/mtracking");
        }}
      >
        <ListItemText primary={t("Seguimiento Múltiple")} />
      </ListItem> */}
    </List>
  );
};

const _mapStateToProps = ({ user }) => {
  return {
    user,
  };
};

const _mapDispatchToProps = (dispatch) => {
  return {
    closePanel: () => dispatch(closePanel()),
  };
};

export default connect(
  _mapStateToProps,
  _mapDispatchToProps
)(AdministrationPanel);
