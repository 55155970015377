import React from "react";

import { Grid, Checkbox } from "@material-ui/core";

import { format } from "date-fns";

import { useTranslation } from "../../lang";

const ItemRevision = ({
  item,
  setMidSelected,
  setRevisionSelected,
  openPanelAux,
  changePanelAuxType,
  changePanelAuxTitle,
}) => {
  const [t] = useTranslation();

  return (
    <Grid
      item
      xs={12}
      className="itemRevisionNotice"
      onClick={() => {
        setMidSelected(item.asset.id);
        setRevisionSelected(item);
        changePanelAuxTitle(t("Editar Revisión"));
        changePanelAuxType(t("Revision"));
        openPanelAux();
      }}
    >
      <div style={{ width: "8.3%", display: "inline-block" }}>
        <Checkbox checked={true} disabled style={{ color: "green" }} />
      </div>
      <div style={{ width: "50%", display: "inline-block" }}>
        {item.serviceType.description}
      </div>
      <div style={{ width: "41.7%", display: "inline-block" }}>
        <label>{format(new Date(item.date), "dd/MM/yyyy HH:mm:ss")}</label>
      </div>
    </Grid>
  );
};

export default ItemRevision;
