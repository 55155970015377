import gql from "graphql-tag";

const GET_MARKER_JOURNEY_QUERY = gql`
  query Journeys($movilID: Int!, $fechaInicio: Datetime, $fechaFin: Datetime) {
    journeys(
      movilID: $movilID
      fechaInicio: $fechaInicio
      fechaFin: $fechaFin
    ) {
      mid
      summary {
        uIni
        uFin
        tIgn
        tDet
        odo
        sOdo
        pat
        nCh
        nMov
        nInt
      }
      daySteps {
        date
        steps {
          sid
          iTime
          fTime
          tIgn
          odo
          tDet
          listPos {
            id
            lat
            lon
            cur
            vel
            fGPS
            dir
            odo
          }
        }
      }
    }
  }
`;

export default GET_MARKER_JOURNEY_QUERY;
