import React from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";

import IconButton from "@material-ui/core/IconButton";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import InfoIcon from "@material-ui/icons/Info";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";

import TabPanelContent from "../../Base/TabPanelContent";
import FlexRow from "../../Base/FlexRow";
import SubHeader from "./SubHeader";

import RevisionsPanel from "../../RevisionsPanel";

import { useTranslation } from "../../../lang";

import "./GeneralInfo.scss";
import "./Revisions.scss";

const IconByType = ({ type }) => {
  let icon = <CheckBoxIcon />;

  if (type === "danger") {
    icon = <InfoIcon className="marker-info__revisions-icon--danger" />;
  } else if (type === "success") {
    icon = <CheckBoxIcon className="marker-info__revisions-icon--success" />;
  } else if (type === "alert") {
    icon = <ReportProblemIcon className="marker-info__revisions-icon--alert" />;
  }

  return icon;
};

// TODO: remove index key from here.
const RevisionsList = ({ revisions = [], id }) => {
  const revisionsList = (
    <div>
      {revisions.map(({ type, title, date }) => (
        <div key={`${id}-item-${date}`}>
          <FlexRow
            bottomDivider
            expansion="full"
            _classNames="marker-info__revisions-description pad-2"
          >
            <IconByType type={type} />

            <div className="marker-info__revisions-summary">{title}</div>

            <div className="marker-info__revisions-date">{date}</div>
          </FlexRow>
        </div>
      ))}
    </div>
  );

  return revisionsList;
};

const RevisionsContent = ({ revisions, toggleActivePanelAux, id, t }) => {
  const subHeader = (
    <SubHeader
      parentId={id}
      subComponent={
        <IconButton
          onClick={toggleActivePanelAux}
          className="expandable-panel__actions__button"
        >
          <AddBoxOutlinedIcon />
        </IconButton>
      }
    />
  );
  let revisionsContent = (
    <div>
      {subHeader}
      {t("Este marcador no posee revisiones")}
    </div>
  );

  if (!isEmpty(revisions)) {
    revisionsContent = (
      <>
        {subHeader}
        <RevisionsList revisions={revisions} id={id} />
      </>
    );
  }

  return revisionsContent;
};

const Revisions = ({ value, markerRevisions, index, mid }) => {
  const [t] = useTranslation();
  return (
    <TabPanelContent
      value={value}
      index={index}
      subtitle={t("Proximas revisiones")}
    >
      <RevisionsPanel mid={mid} />
    </TabPanelContent>
  );
};

export default Revisions;
