import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const TruckIcon = function (props) {
  return (
    <SvgIcon viewBox="0 0 32 24">
      <path
        d="M59.577,103.674h-1.25v-5.63a3.336,3.336,0,0,0-.979-2.354l-4.37-4.37a3.318,3.318,0,0,0-2.354-.979h-2.3V87.767a2.508,2.508,0,0,0-2.573-2.427H29.233a2.507,2.507,0,0,0-2.573,2.427v15.146a2.508,2.508,0,0,0,2.573,2.427h.844a4,4,0,0,0-.084.833,4.167,4.167,0,0,0,8.333,0,4.39,4.39,0,0,0-.083-.833H48.41a4,4,0,0,0-.083.833,4.167,4.167,0,0,0,8.333,0,4.389,4.389,0,0,0-.083-.833h3a.418.418,0,0,0,.417-.417v-.833A.418.418,0,0,0,59.577,103.674ZM50.62,92.006a1.663,1.663,0,0,1,1.178.49l4.37,4.37a1.394,1.394,0,0,1,.093.146H48.327v-5h2.3V92.01ZM34.16,108.674a2.5,2.5,0,1,1,2.5-2.5,2.5,2.5,0,0,1-2.5,2.5Zm3.31-5a4.125,4.125,0,0,0-6.625,0H29.233a.845.845,0,0,1-.909-.761V87.767a.843.843,0,0,1,.909-.761H45.754a.845.845,0,0,1,.909.761v15.906h-9.19Zm15.021,5a2.5,2.5,0,1,1,2.5-2.5,2.5,2.5,0,0,1-2.5,2.5Zm0-6.666a4.145,4.145,0,0,0-3.31,1.667h-.854v-5H56.66v5h-.854a4.145,4.145,0,0,0-3.31-1.667h0Z"
        transform="translate(-26.66 -85.34)"
      />
    </SvgIcon>
  );
};

export default TruckIcon;
