import gql from "graphql-tag";

const CREATE_ROLE_MUTATION = gql`
  mutation CreateRole($name: String!, $categoryId: Int!) {
    createRole(name: $name, categoryId: $categoryId) {
      id
    }
  }
`;

export default CREATE_ROLE_MUTATION;
