import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import {
  Button,
  FormGroup,
  Grid,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { Field, Formik } from "formik";

import GET_DEVICETYPES_QUERY from "../../../graphql/queries/deviceTypesQuery";
//import GET_AVAILABLEDEVICES_QUERY from "../../../graphql/queries/getAvailableLines";

import CustomFormikSelect from "../../../components/Base/CustomFormikSelect";

import { useTranslation } from "../../../lang";
const theme = createMuiTheme({});

const Form = ({ setIsOpen, createDevice, updateDevice, selectedDevice }) => {
  const [t] = useTranslation();
  const [deviceTypes, setDeviceTypes] = useState([]);
  //const [availableLines, setAvailableLines] = useState([]);

  const {} = useQuery(GET_DEVICETYPES_QUERY, {
    onCompleted(d) {
      const deviceTypes = d.deviceTypes.map((dt) => ({
        label: dt.description,
        value: dt.id,
      }));
      setDeviceTypes(deviceTypes);
    },
  });

  /*const { } = useQuery(GET_AVAILABLEDEVICES_QUERY, {
    onCompleted(d) {
      const availableLines = d.availableLines.map((l) => ({
        label: l.lineNumber,
        value: l.id
      }));

      //Add own lines
      const ownLines = selectedDevice?.line?.map(l => ({
        label: l.lineNumber,
        value: l.id
      })) || [];

      setAvailableLines([...ownLines, ...availableLines]);
    }
  });*/

  const initialValues = {
    id: selectedDevice?.id,
    model: selectedDevice?.model,
    serialNumber: selectedDevice?.serialNumber,
    //status: selectedDevice?.status,
    deviceIdentifier: selectedDevice?.deviceIdentifier,
    firmwareVersion: selectedDevice?.firmwareVersion,
    scriptVersion: selectedDevice?.scriptVersion,
    hasCanBusy: selectedDevice?.hasCanBusy,
    tempConversionFactor: selectedDevice?.tempConversionFactor,
    imei: selectedDevice?.imei,
    //lineId: selectedDevice?.line?.map(l => l.id),
    deviceTypeId: selectedDevice?.deviceType?.id,
    expirationPassword: selectedDevice?.expirationPassword,
  };

  return (
    <>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        enableReinitialize={true}
        //validationSchema={yupSchema}
        onSubmit={async (values) => {
          // console.log("Values: ", values);
          if (selectedDevice) {
            updateDevice({
              variables: values,
            });
          } else {
            createDevice({
              variables: values,
            });
          }
        }}
      >
        {(props) => {
          const { values, errors, handleSubmit, setFieldValue } = props;
          return (
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <label className="formLabelFormik">{t("Identificador")}</label>
                <Field
                  type="text"
                  name="deviceIdentifier"
                  tabIndex="0"
                  className={
                    errors.deviceIdentifier
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                />
                {errors.deviceIdentifier && (
                  <div className="input-feedback">
                    {errors.deviceIdentifier}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">{t("Tipo de Equipo")}</label>
                <Field
                  name="deviceTypeId"
                  component={CustomFormikSelect}
                  options={deviceTypes}
                />
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">{t("IMEI")}</label>
                <Field
                  type="text"
                  name="imei"
                  tabIndex="0"
                  className={
                    errors.imei
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                />
                {errors.imei && (
                  <div className="input-feedback">{errors.imei}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">{t("Modelo")}</label>
                <Field
                  type="text"
                  name="model"
                  tabIndex="0"
                  className={
                    errors.model
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                />
                {errors.model && (
                  <div className="input-feedback">{errors.model}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">{t("Nro de Serie")}</label>
                <Field
                  type="text"
                  name="serialNumber"
                  tabIndex="0"
                  className={
                    errors.serialNumber
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                />
                {errors.serialNumber && (
                  <div className="input-feedback">{errors.serialNumber}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">
                  {t("Versión Firmware")}
                </label>
                <Field
                  type="text"
                  name="firmwareVersion"
                  tabIndex="0"
                  className={
                    errors.firmwareVersion
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                />
                {errors.firmwareVersion && (
                  <div className="input-feedback">{errors.firmwareVersion}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">{t("Versión Script")}</label>
                <Field
                  type="text"
                  name="scriptVersion"
                  tabIndex="0"
                  className={
                    errors.scriptVersion
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                />
                {errors.scriptVersion && (
                  <div className="input-feedback">{errors.scriptVersion}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">
                  {t("Clave Expiración")}
                </label>
                <Field
                  type="text"
                  name="expirationPassword"
                  tabIndex="0"
                  className={
                    errors.expirationPassword
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                />
                {errors.expirationPassword && (
                  <div className="input-feedback">
                    {errors.expirationPassword}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <ThemeProvider theme={theme}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={values.hasCanBusy}
                        onChange={(evt) =>
                          setFieldValue("hasCanBusy", evt.target.checked)
                        }
                        name="hasCanBusy"
                      />
                    }
                    label={t("Can Bus")}
                  />
                </ThemeProvider>
                {errors.hasCanBusy && (
                  <div className="input-feedback">{errors.hasCanBusy}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">
                  {t("Factor de Conversión Temp")}
                </label>
                <Field
                  type="number"
                  name="tempConversionFactor"
                  tabIndex="0"
                  className={
                    errors.tempConversionFactor
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                />
                {errors.tempConversionFactor && (
                  <div className="input-feedback">
                    {errors.tempConversionFactor}
                  </div>
                )}
              </FormGroup>
              {/* <FormGroup>
                <label className="formLabelFormik">Linea</label>
                <Field 
                  name="lineId"
                  component={CustomFormikSelect}
                  options={availableLines}
                  isMulti
                />
              </FormGroup>*/}
              <Grid
                item
                lg={12}
                style={{
                  marginTop: "50px",
                  textAlign: "center",
                }} /*className="footerSlidePanel"*/
              >
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  tabIndex="0"
                >
                  {selectedDevice ? t("Actualizar") : t("Crear")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  tabIndex="0"
                  style={{ marginLeft: "20px" }}
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  {t("Cancelar")}
                </Button>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default Form;
