import { useTranslation } from "../lang";
import { NAVBAR_SECTIONS } from "../constants/navbar";

// We can have a call to get available sections for a certain user here in the future
export const getAvailableNavbarItems = () => {
  const response = NAVBAR_SECTIONS;

  return response;
};

export const useAvailableNavbarItems = () => {
  const [t] = useTranslation();
  const _availableNavbarItems = getAvailableNavbarItems();
  const availableNavbarItems = _availableNavbarItems.map((section) => {
    return { ...section, title: t(section.title) };
  });
  return [availableNavbarItems];
};

export default getAvailableNavbarItems;
