export const UPDATE_POPUP_INFO = "UPDATE_POPUP_INFO";

export const toggleFillGenericPopup = ({ popupInfo }) => (
  dispatch,
  getState
) => {
  dispatch(_updatePopupInfo(popupInfo));
};

const _updatePopupInfo = (content) => ({
  type: UPDATE_POPUP_INFO,
  payload: content,
});
