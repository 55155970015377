import { connect } from 'react-redux';

import Navbar from '../components/Base/Navbar';

import { toggleActivePanel } from '../redux/lateralPanel/actions';

const _mapStateToProps = () => ({});

const _mapDispatchToProps = {
    toggleActivePanel
};

export default connect(_mapStateToProps, _mapDispatchToProps)(Navbar);
