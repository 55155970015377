import React, { useEffect, useState } from "react";

import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import Structure from "../../../components/Base/Structure";

import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import DRIVERS_QUERY from "../../../graphql/queries/driversQuery";
import CREATE_DRIVER_MUTATION from "../../../graphql/mutations/createDriverMutation";
import UPDATE_DRIVER_MUTATION from "../../../graphql/mutations/updateDriverMutation";
import DELETE_DRIVER_MUTATION from "../../../graphql/mutations/deleteDriverMutation";

import PageTitle from "../../../components/Base/PageTitle";

import TableDriver from "./List";
import "./Driver.scss";

import { useTranslation } from "../../../lang";

const ManageDriverPage = ({}) => {
  const [t] = useTranslation();
  const paginationSizes = [10, 25, 50];
  const [total, setTotal] = useState(0);
  const [driverList, setDriverList] = useState([]);
  const [paginationState, setPaginationState] = useState({
    pageSize: paginationSizes[0],
    pageNumber: 1,
    sort: undefined,
    searchText: "",
  });

  const [resultState, setResultState] = useState({
    isOpen: false,
    type: "success",
    msg: "",
  });

  const [getDrivers] = useLazyQuery(DRIVERS_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      if (d) {
        // debugger;
        setDriverList(d.driversPaginated.items);
        setTotal(d.driversPaginated.count);
      }
    },
  });

  const handleGetDrivers = () => {
    getDrivers({
      variables: {
        ...paginationState,
        paginate: true,
      },
    });
  };

  useEffect(() => {
    handleGetDrivers();
  }, [paginationState]);

  const [createDriver] = useMutation(CREATE_DRIVER_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Driver creado correctamente"),
      });
      handleGetDrivers();
    },
    onError(err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: err.message,
      });
    },
  });

  const [updateDriver] = useMutation(UPDATE_DRIVER_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Driver actualizado correctamente"),
      });
      handleGetDrivers();
    },
    onError(err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: err.message,
      });
    },
  });

  const [deleteDriver] = useMutation(DELETE_DRIVER_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Driver eliminado correctamente"),
      });
      handleGetDrivers();
    },
    onError(d) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: t("Ocurrió un error"),
      });
    },
  });

  const handleDeleteDriver = (id) => {
    deleteDriver({
      variables: { id },
    });
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setResultState({
      ...resultState,
      isOpen: false,
    });
  };

  return (
    <Structure>
      <Snackbar
        open={resultState.isOpen}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleCloseNotification}
          severity={resultState.type}
          variant="filled"
        >
          {resultState.msg}
        </MuiAlert>
      </Snackbar>
      <PageTitle sector={t("Administración")} title={t("Conductores")} />
      <TableDriver
        getDrivers={handleGetDrivers}
        driverList={driverList}
        paginationSizes={paginationSizes}
        paginationState={paginationState}
        setPaginationState={setPaginationState}
        total={total}
        setResultState={setResultState}
        createDriver={createDriver}
        updateDriver={updateDriver}
        deleteDriver={handleDeleteDriver}
      />
    </Structure>
  );
};

export default ManageDriverPage;
