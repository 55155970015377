import gql from "graphql-tag";

const TRACKING_BOARD_QUERY = gql`
  query TrackingBoardReport(
    $assetsSelected: [Int]!
    $fechaInicio: Datetime
    $fechaFin: Datetime
    $days: [WeekDaysEnum]
  ) {
    trackingBoardReport(
      assetsSelected: $assetsSelected
      fechaInicio: $fechaInicio
      fechaFin: $fechaFin
      days: $days
    ) {
      mid
      opNumber
      customer
      pat
      maxDetentionDays
      actualDetentionDays
      theoreticalAverageDailyKms
      realAverageDailyKms
      theoreticalAverageDailyHourmeter
      realAverageDailyHourmeter
      areaOfOperationsEvaluated
      areaOfOperationsReal
    }
  }
`;

export default TRACKING_BOARD_QUERY;
