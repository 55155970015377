import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Field } from "formik";
import React, { useState } from "react";
import CustomFormikSelect from "../../../components/Base/CustomFormikSelect";
import GenericSelector from "../../../components/Selectors/GenericSelector";
import { useTranslation } from "../../../lang";
import "./style.scss";

const theme = createMuiTheme({});

const FormHeader = ({
  errors,
  touched,
  values,
  setFieldValue,
  reportTypes,
  initialValues,
  customers,
  groups,
  getGroups,
  setGroupsSelected,
}) => {
  const [t] = useTranslation();
  const [expanded, setExpanded] = useState(true);

  // console.log("errors", errors);
  // console.log("touched", touched);

  const itemStyle = {
    fontSize: "12px",
    color: "white",
  };

  const columnsGroups = [
    {
      dataField: "name",
      text: t("Nombre"),
      headerStyle: () => ({ width: "50%", fontSize: "12px" }),
      sort: true,
    },
    {
      dataField: "customer.name",
      text: t("Cliente"),
      headerStyle: () => ({ width: "50%", fontSize: "12px" }),
      sort: true,
    },
  ];

  const columnsCustomers = [
    {
      dataField: "name",
      text: t("Nombre"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
    },
    {
      dataField: "taxId",
      text: t("CUIT"),
      headerStyle: () => ({ width: "15%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
    },
    {
      dataField: "phone",
      text: t("Telefonos"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
    },
    {
      dataField: "celPhone",
      text: t("Celular"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
    },
    {
      dataField: "address",
      text: t("Domicilio"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className="summaryTitleForm"
        >
          <Typography>{t("Planificador")}</Typography>
        </AccordionSummary>
        <AccordionDetails className="reportPlanningSummaryDetail">
          <Grid container>
            <Grid item xs={4}>
              <FormGroup>
                <FormControlLabel
                  label={t("Nombre")}
                  labelPlacement="start"
                  control={
                    <div className="marginInline">
                      <Field
                        type="text"
                        name="name"
                        tabIndex="0"
                        className={
                          errors.name
                            ? "form-control text-input error"
                            : "form-control text-input"
                        }
                      />
                      {(errors.name || touched.name) && (
                        <div className="input-feedback">{errors.name}</div>
                      )}
                    </div>
                  }
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  label={t("Tipo de Reporte")}
                  labelPlacement="start"
                  control={
                    <div className="marginInline">
                      <Field
                        name="typeReportID"
                        component={CustomFormikSelect}
                        options={reportTypes}
                        className={
                          errors.typeReportID
                            ? "form-control text-input error"
                            : "form-control text-input"
                        }
                      />
                      {(errors.typeReportID || touched.typeReportID) && (
                        <div className="input-feedback">
                          {errors.typeReportID}
                        </div>
                      )}
                    </div>
                  }
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  label={t("Formato")}
                  labelPlacement="start"
                  control={
                    <div className="marginInline">
                      <Field
                        name="formatId"
                        component={CustomFormikSelect}
                        options={[
                          { label: t("Excel"), value: 1 },
                          { label: t("PDF"), value: 2 },
                        ]}
                        className={
                          errors.formatId
                            ? "form-control text-input error"
                            : "form-control text-input"
                        }
                      />
                      {(errors.formatId || touched.formatId) && (
                        <div className="input-feedback">{errors.formatId}</div>
                      )}
                    </div>
                  }
                  style={{ alignItems: "baseline" }}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    className="reportPlanningCheckBox"
                    checked={values.enabled}
                    onChange={(evt) =>
                      setFieldValue("enabled", evt.target.checked)
                    }
                    name="enabled"
                  />
                }
                label={t("Habilitado")}
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={5}>
              <FormGroup>
                {customers && customers.length > 0 && (
                  <GenericSelector
                    callbackOptionSelected={(customer) => {
                      setFieldValue("customerId", customer.value);
                      getGroups(customer.value);
                    }}
                    columns={columnsCustomers}
                    keyField="id"
                    labelField="name"
                    title={t("Cliente")}
                    data={customers}
                    defaultValue={initialValues?.customerId}
                  />
                )}
                {(errors.customerId || touched.customerId) && (
                  <div className="input-feedback">{errors.customerId}</div>
                )}
              </FormGroup>
              {initialValues.creadaPor && (
                <FormGroup style={{ marginTop: "20px" }}>
                  <label>
                    {t("pages_manage_reportingplanning_reporte_creado_por", {
                      creadaPor: initialValues?.creadaPor,
                    })}
                  </label>
                </FormGroup>
              )}
              <FormGroup>
                <GenericSelector
                  callbackOptionSelected={(groups) => {
                    const groupsSelected = groups.map((g) => g.id);
                    setGroupsSelected(groupsSelected);
                  }}
                  isMulti
                  columns={columnsGroups}
                  keyField="id"
                  labelField="name"
                  title={t("Grupos")}
                  data={groups}
                />
              </FormGroup>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </ThemeProvider>
  );
};

export default FormHeader;
