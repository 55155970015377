import gql from "graphql-tag";

const GET_POI_QUERY = gql`
  query Poi($customerId: Int) {
    poi(customerId: $customerId) {
      id
      customer {
        id
        name
      }
      customerId
      name
      latitude
      longitude
      radio
      poiCategoryId
      PoiCategory {
        poiDescription
      }
      expirationDate
    }
  }
`;

export default GET_POI_QUERY;
