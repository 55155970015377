import gql from "graphql-tag";

const UPDATE_ROAD_MUTATION = gql`
  mutation UpdateRoad(
    $id: Int!
    $name: String!
    $description: String
    $hasDetourAlarm: Boolean
    $detourMeters: Float
    $maxVelocity: Float
    $hasVelocityAlarm: Boolean
    $customerId: Int!
    $assets: [Int]
    $geofences: [Int]
  ) {
    updateRoad(
      id: $id
      name: $name
      description: $description
      hasDetourAlarm: $hasDetourAlarm
      detourMeters: $detourMeters
      maxVelocity: $maxVelocity
      hasVelocityAlarm: $hasVelocityAlarm
      customerId: $customerId
      assets: $assets
      geofences: $geofences
    ) {
      id
    }
  }
`;

export default UPDATE_ROAD_MUTATION;
