import gql from "graphql-tag";

const FLEET_KM_REPORT = gql`
  query FleetKmReport(
    $assetsSelected: [Int]!
    $fechaInicio: Datetime
    $fechaFin: Datetime
    $days: [WeekDaysEnum]
  ) {
    fleetKmReport(
      assetsSelected: $assetsSelected
      fechaInicio: $fechaInicio
      fechaFin: $fechaFin
      days: $days
    ) {
      typeMobile
      name
      pat
      kmsRec
      velProm
      velMax
      dir
    }
  }
`;

export default FLEET_KM_REPORT;
