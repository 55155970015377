import gql from "graphql-tag";

const GET_ENUMS_QUERY = gql`
  query GetEnums($name: String!) {
    __type(name: $name) {
      enumValues {
        label: name
        value: name
        description
      }
    }
  }
`;

export default GET_ENUMS_QUERY;
