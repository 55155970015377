import gql from "graphql-tag";

const UPDATE_SERVICEPERFORMED_MUTATION = gql`
  mutation UpdateServicePerformed(
    $id: Int!
    $assetId: Int!
    $serviceTypeId: Int!
    $date: Datetime!
    $kms: Float
    $marca: String
    $supplier: String
    $notes: String
  ) {
    updateServicePerformed(
      id: $id
      assetId: $assetId
      serviceTypeId: $serviceTypeId
      date: $date
      kms: $kms
      marca: $marca
      supplier: $supplier
      notes: $notes
    ) {
      id
      asset {
        id
        name
        licensePlate
      }
      serviceType {
        id
        description
      }
      date
      kms
      marca
      supplier
      notes
    }
  }
`;

export default UPDATE_SERVICEPERFORMED_MUTATION;
