import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useMutation, useLazyQuery } from "@apollo/client";

import Structure from "../../../components/Base/Structure";

import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import ASSETS_QUERY from "../../../graphql/queries/assetByGroupsQuery";
import GET_ASSETS_EXPORT_QUERY from "../../../graphql/queries/assetsExportQuery";
import CREATE_ASSET_MUTATION from "../../../graphql/mutations/createAssetMutation";
import UPDATE_ASSET_MUTATION from "../../../graphql/mutations/updateAssetMutation";
import DELETE_ASSET_MUTATION from "../../../graphql/mutations/deleteAssetMutation";

import CREATE_ASSET_ECOTRACKING_MUTATION from "../../../graphql/mutations/createEcoTrackingMutation";
import UPDATE_ASSET_ECOTRACKING_MUTATION from "../../../graphql/mutations/updateEcoTrackingMutation";
import DELETE_ASSET_ECOTRACKING_MUTATION from "../../../graphql/mutations/deleteEcoTrackingMutation";

import CREATE_ASSETDEVICE_MUTATION from "../../../graphql/mutations/createAssetDeviceMutation";
import UPDATE_GEOFENCES_ASSET_MUTATION from "../../../graphql/mutations/updateGeofencesAssetMutation";

import TableAsset from "./List";
import PageTitle from "../../../components/Base/PageTitle";
import "./Asset.scss";

import { useTranslation } from "../../../lang";
import { downloadExcel, downloadPDF } from "../../../utils/generalUtils";
const ManageAssetPage = ({ selectedGroups, loggedUser }) => {
  const [t] = useTranslation();
  const paginationSizes = [10, 25, 50];
  const [total, setTotal] = useState(0);
  const [assetList, setAssetList] = useState([]);

  //const [resetForm, setResetForm] = useState();

  const [paginationState, setPaginationState] = useState({
    pageSize: paginationSizes[0],
    pageNumber: 1,
    sort: undefined,
    filter: null,
  });

  const [resultState, setResultState] = useState({
    isOpen: false,
    type: "success",
    msg: "",
  });
  const [exportSelected, setExportSelected] = useState("EXCEL");
  const [getAssets] = useLazyQuery(ASSETS_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      if (d.assetsByGroups) {
        setAssetList(d.assetsByGroups.items);
        setTotal(d.assetsByGroups.count);
      }
    },
  });

  const handleGetAssets = () => {
    getAssets({
      variables: {
        ...paginationState,
        paginate: true,
        groupIDs:
          selectedGroups.length > 0
            ? selectedGroups.filter(
                (g) => g && !Number.isNaN(Number.parseInt(g))
              )
            : undefined,
      },
    });
  };
  const handleGetAssetsExport = (format) => {
    setExportSelected(format);
    fetchAssetsExport({
      variables: {
        sort: paginationState.sort,
        searchText: paginationState.searchText,
        groupIDs:
          selectedGroups.length > 0
            ? selectedGroups.filter(
                (g) => g && !Number.isNaN(Number.parseInt(g))
              )
            : undefined,
        format: format,
      },
    });
  };
  const [fetchAssetsExport] = useLazyQuery(GET_ASSETS_EXPORT_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      const filename = t("Móviles"); //ver en los global
      switch (exportSelected) {
        case "EXCEL":
          downloadExcel(d.assetsByGroupsExport, filename);
          break;
        case "PDF":
          downloadPDF(d.assetsByGroupsExport, filename);
          break;
      }
    },
  });
  useEffect(() => {
    handleGetAssets();
  }, [paginationState, selectedGroups]);

  const [createAsset] = useMutation(CREATE_ASSET_MUTATION, {
    /*onCompleted() {
      setResultState({
        isOpen: true,
        type: "success",
        msg: "Móvil creado correctamente",
      });
      handleGetAssets();
      //resetForm && resetForm();
    },
    onError(err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: err.message,
      });
    },*/
  });

  const handleCreateAsset = async (values, resetForm) => {
    //setResetForm(reset);
    try {
      await createAsset({
        variables: values,
      });
      resetForm();
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Móvil creado correctamente"),
      });
      handleGetAssets();
    } catch (err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: err.message,
      });
    }
  };

  const [createAssetDevice] = useMutation(CREATE_ASSETDEVICE_MUTATION, {
    onCompleted() {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Equipo asignado correctamente"),
      });
    },
    onError(err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: err.message,
      });
    },
  });

  const [updateAsset] = useMutation(UPDATE_ASSET_MUTATION, {
    onCompleted() {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Móvil actualizado correctamente"),
      });
      handleGetAssets();
    },
    onError(err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: err.message,
      });
    },
  });

  const [updateGeofencesAsset] = useMutation(UPDATE_GEOFENCES_ASSET_MUTATION, {
    onCompleted() {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Tablero de Seguimiento actualizado correctamente"),
      });
      handleGetAssets();
    },
    onError(err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: err.message,
      });
    },
  });

  const [deleteAsset] = useMutation(DELETE_ASSET_MUTATION, {
    onCompleted() {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Móvil eliminado correctamente"),
      });
      handleGetAssets();
    },
    onError() {
      setResultState({
        isOpen: true,
        type: "error",
        msg: t("Ocurrió un error"),
      });
    },
  });

  const [createEcoTracking] = useMutation(CREATE_ASSET_ECOTRACKING_MUTATION, {
    onCompleted() {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Información de EcoTracking creada correctamente"),
      });
      handleGetAssets();
    },
    onError(err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: err.message,
      });
    },
  });

  const [updateEcoTracking] = useMutation(UPDATE_ASSET_ECOTRACKING_MUTATION, {
    onCompleted() {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Información de EcoTracking actualizada correctamente"),
      });
      handleGetAssets();
    },
    onError(err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: err.message,
      });
    },
  });

  const [deleteEcoTracking] = useMutation(DELETE_ASSET_ECOTRACKING_MUTATION, {
    onCompleted() {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Información de EcoTracking eliminada correctamente"),
      });
      handleGetAssets();
    },
    onError() {
      setResultState({
        isOpen: true,
        type: "error",
        msg: t("Ocurrió un error"),
      });
    },
  });

  const handleDeleteAsset = (id) => {
    deleteAsset({
      variables: { assetId: id },
    });
  };

  const handleCloseNotification = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setResultState({
      ...resultState,
      isOpen: false,
    });
  };

  return (
    <Structure>
      <Snackbar
        open={resultState.isOpen}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleCloseNotification}
          severity={resultState.type}
          variant="filled"
        >
          {resultState.msg}
        </MuiAlert>
      </Snackbar>
      <PageTitle sector={t("Administración")} title={t("Móviles")} />
      <TableAsset
        getAssets={handleGetAssets}
        assetList={assetList}
        paginationSizes={paginationSizes}
        paginationState={paginationState}
        setPaginationState={setPaginationState}
        total={total}
        setResultState={setResultState}
        createAsset={handleCreateAsset}
        updateAsset={updateAsset}
        deleteAsset={handleDeleteAsset}
        createEcoTracking={createEcoTracking}
        updateEcoTracking={updateEcoTracking}
        deleteEcoTracking={deleteEcoTracking}
        createAssetDevice={createAssetDevice}
        updateGeofencesAsset={updateGeofencesAsset}
        loggedUser={loggedUser}
        getAssetsExport={handleGetAssetsExport}
      />
    </Structure>
  );
};

const _mapStateToProps = ({ groups, user }) => {
  return {
    selectedGroups: groups.selectedGroups,
    loggedUser: user,
  };
};

export default connect(_mapStateToProps, null)(React.memo(ManageAssetPage));
//export default ManageAssetPage;
