import gql from "graphql-tag";

const JOURNEYPOSITIONS_KML_QUERY = gql`
  query JourneyPositionsKML(
    $movilID: Int!
    $fechaInicio: Datetime!
    $fechaFin: Datetime!
  ) {
    journeyPositionsKML(
      movilID: $movilID
      fechaInicio: $fechaInicio
      fechaFin: $fechaFin
    )
  }
`;

export default JOURNEYPOSITIONS_KML_QUERY;
