import gql from "graphql-tag";

const GET_ROAD_STOPS_BY_ROAD_ID = gql`
  query GetRoadStopsByRoadId($roadId: Int) {
    getRoadStopsByRoadId(roadId: $roadId) {
      id
      name
      geom
    }
  }
`;

export default GET_ROAD_STOPS_BY_ROAD_ID;
