import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useLazyQuery, useQuery } from "@apollo/client";

import FilterRevisionNotice from "./FilterRevisionNotice";
import FilterRevision from "./FilterRevision";
import ListRevision from "./ListRevision";
import ListRevisionNotice from "./ListRevisionNotice";

import ASSETS_BYGROUPS_QUERY from "../../graphql/queries/assetByGroupsQuery";
import SERVICE_BYASSETS_QUERY from "../../graphql/queries/getServiceByAssetsQuery";
import SERVICE_PERFORMED_QUERY from "../../graphql/queries/getServicePerformedQuery";

import {
  openPanelAux,
  changePanelAuxType,
  changePanelAuxTitle,
  setAssetList,
  setMidSelected,
  setRevisionNoticeSelected,
  setRevisionSelected,
  setRevisionNoticeList,
  setRevisionList,
} from "../../redux/lateralPanel/actions";

import { useTranslation } from "../../lang";

import "./Styles.scss";

const RevisionPanel = ({
  mid,
  selectedGroups,
  openPanelAux,
  changePanelAuxType,
  changePanelAuxTitle,
  setAssetList,
  setMidSelected,
  setRevisionNoticeSelected,
  setRevisionSelected,
  revisionNoticeList,
  setRevisionNoticeList,
  revisionList,
  setRevisionList,
  user,
}) => {
  const [t] = useTranslation();
  useEffect(() => {
    setRevisionNoticeList([]);
    setRevisionList([]);
  }, []);

  const [assets, setAssets] = useState();
  //const [revisionNotices, setRevisionNotices] = useState([]);
  //const [revisions, setRevisions] = useState([]);

  useQuery(ASSETS_BYGROUPS_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      groupIDs: selectedGroups.filter(
        (g) => g && !Number.isNaN(Number.parseInt(g))
      ),
    },
    onCompleted(d) {
      const { items } = d.assetsByGroups;
      setAssets(items);
      setAssetList(items);
    },
  });

  const [fetchRevisionNotices] = useLazyQuery(SERVICE_BYASSETS_QUERY, {
    onCompleted(d) {
      //setRevisionNotices(d.service);
      setRevisionNoticeList(d.service);
    },
  });

  const handleGetRevisionNotices = (assetIds) => {
    fetchRevisionNotices({
      variables: {
        assetIds: assetIds,
        groupIDs: selectedGroups.filter(
          (g) => g && !Number.isNaN(Number.parseInt(g))
        ),
      },
    });
  };

  useEffect(() => {
    handleGetRevisionNotices(mid ? [mid] : []);
  }, [mid]);

  useEffect(() => {
    handleGetRevisionNotices(mid ? [mid] : []);
  }, [selectedGroups]);

  const [fetchRevision] = useLazyQuery(SERVICE_PERFORMED_QUERY, {
    onCompleted(d) {
      setRevisionList(d.getServicePerformed);
    },
  });

  const handleGetRevisions = (assetIds, startDate, endDate, days) => {
    fetchRevision({
      variables: {
        assetIds,
        startDate,
        endDate,
        days,
      },
    });
  };

  return (
    <div>
      {!mid && <div className="sectionTitle">{t("Próximas revisiones")}</div>}
      <FilterRevisionNotice
        mid={mid}
        assetList={assets}
        setMidSelected={setMidSelected}
        openPanelAux={openPanelAux}
        changePanelAuxType={changePanelAuxType}
        changePanelAuxTitle={changePanelAuxTitle}
        handleGetRevisionNotices={handleGetRevisionNotices}
        language={user.language}
      />
      <ListRevisionNotice
        mid={mid}
        data={revisionNoticeList}
        setMidSelected={setMidSelected}
        setRevisionNoticeSelected={setRevisionNoticeSelected}
        openPanelAux={openPanelAux}
        changePanelAuxType={changePanelAuxType}
        changePanelAuxTitle={changePanelAuxTitle}
      />
      <div className="sectionTitle">{t("Revisiones")}</div>
      <FilterRevision
        mid={mid}
        assetList={assets}
        setMidSelected={setMidSelected}
        openPanelAux={openPanelAux}
        changePanelAuxType={changePanelAuxType}
        changePanelAuxTitle={changePanelAuxTitle}
        handleGetRevisions={handleGetRevisions}
        language={user.language}
      />
      <ListRevision
        mid={mid}
        data={revisionList}
        setMidSelected={setMidSelected}
        setRevisionSelected={setRevisionSelected}
        openPanelAux={openPanelAux}
        changePanelAuxType={changePanelAuxType}
        changePanelAuxTitle={changePanelAuxTitle}
      />
    </div>
  );
};

const _mapStateToProps = ({ groups, lateralPanel, user }) => {
  return {
    selectedGroups: groups.selectedGroups,
    revisionNoticeList: lateralPanel.newPanelReducer.revisionNoticeList,
    revisionList: lateralPanel.newPanelReducer.revisionList,
    user,
  };
};

const _mapDispatchToProps = (dispatch) => {
  return {
    openPanelAux: () => dispatch(openPanelAux()),
    changePanelAuxType: (type) => dispatch(changePanelAuxType(type)),
    changePanelAuxTitle: (title) => dispatch(changePanelAuxTitle(title)),
    setAssetList: (list) => dispatch(setAssetList(list)),
    setMidSelected: (mid) => dispatch(setMidSelected(mid)),
    setRevisionNoticeSelected: (revisionNotice) =>
      dispatch(setRevisionNoticeSelected(revisionNotice)),
    setRevisionSelected: (revision) => dispatch(setRevisionSelected(revision)),
    setRevisionNoticeList: (list) => dispatch(setRevisionNoticeList(list)),
    setRevisionList: (list) => dispatch(setRevisionList(list)),
  };
};

export default connect(_mapStateToProps, _mapDispatchToProps)(RevisionPanel);
