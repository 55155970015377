import gql from "graphql-tag";

const ASSIGN_PERMISSION_TO_ROLE_MUTATION = gql`
  mutation AssignPermission($roleId: Int!, $permissions: [Int]) {
    assignPermissions(roleId: $roleId, permissions: $permissions) {
      id
    }
  }
`;

export default ASSIGN_PERMISSION_TO_ROLE_MUTATION;
