import gql from "graphql-tag";

const GET_HISTORY_POINT_ENTENDED_DATA = gql`
  query GetHistoryPointExtendedData($id: Int!) {
    getHistoryPointExtendedData(id: $id) {
      eid
      mid
      fGPS
      evt
      externalVoltage
      batteryVoltage
      GNSSStat
    }
  }
`;

export default GET_HISTORY_POINT_ENTENDED_DATA;
