import React, { useState } from "react";

import { Paper, IconButton, Button } from "@material-ui/core";

import { Add, Remove, Clear, People, Delete, Edit } from "@material-ui/icons";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import ModalUsers from "./ModalUsers";
import ModalSubUser from "./ModalSubUser";
import ThreeDotMenu from "../../../components/Base/ThreeDotMenu";

import { useTranslation } from "../../../lang";
const theme = createMuiTheme({});

const UserPanel = ({
  group,
  usersAvailable,
  handleAssignUser,
  handleUnassignUser,
  handleFetchGroups,
}) => {
  const [t] = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isUserFormOpen, setIsUserFormOpen] = useState(false);
  const closeModalUserForm = () => setIsUserFormOpen(false);
  const [usersSelected, setUsersSelected] = useState([]);
  const closeModal = () => setIsOpen(false);
  const [userToEdit, setUserToEdit] = useState();

  const [userOver, setUserOver] = useState();

  const users = group?.users || [];

  const handleAddUser = (rows) => {
    handleAssignUser(group, rows);
  };

  const userActionValues = {
    EDIT_USER: 1,
    REMOVE_USER: 2,
  };

  const userActions = [
    {
      label: "Edit user",
      value: userActionValues.EDIT_USER,
    },
    {
      label: "Remove user",
      value: userActionValues.REMOVE_USER,
    },
  ];

  const handleMouseEnterUser = (userId) => {
    setUserOver(userId);
  };

  const callbackUserAction = (action, user) => {
    switch (action) {
      case userActionValues.EDIT_USER:
        setUserToEdit(user);
        setIsUserFormOpen(true);
        break;
      case userActionValues.REMOVE_USER:
        handleUnassignUser(group, [user.id]);
        break;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <ModalUsers
        usersList={usersAvailable}
        closeModal={closeModal}
        isOpen={isOpen}
        onSelect={handleAddUser}
      />
      <ModalSubUser
        closeModal={closeModalUserForm}
        isOpen={isUserFormOpen}
        customerId={group?.customerId}
        handleFetchGroups={handleFetchGroups}
        group={group}
        user={userToEdit}
      />
      <div className="groupContainer">
        <div>
          <h5 className="groupContainerLabel">{t("Usuarios")}</h5>
        </div>
        <div>
          <h6 className="groupContainerLabel">
            {t("pages_manage_groups_usuarios_pertenecientes_al_grupo", {
              groupName: group?.name || "",
            })}
          </h6>
        </div>
        <div style={{ paddingBottom: "16px" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (Array.isArray(users) && users.length > 0) {
                handleUnassignUser(
                  group,
                  users.map((a) => a.id)
                );
              }
            }}
          >
            Remove all users
          </Button>

          {/* <IconButton
            className="groupActionIcon"
            title={t("Busqueda de Usuarios")}
            onClick={() => setIsOpen(true)}
          >
            <Add />
          </IconButton>
          <IconButton
            className="groupActionIcon"
            title={t("Quitar usuario")}
            onClick={() => {
              if (Array.isArray(usersSelected) && usersSelected.length > 0) {
                handleUnassignUser(group, usersSelected);
              }
            }}
          >
            <Remove />
          </IconButton>
          <IconButton
            className="groupActionIcon"
            title={t("Quitar todos los usuarios")}
            onClick={() => {
              if (Array.isArray(users) && users.length > 0) {
                handleUnassignUser(
                  group,
                  users.map((a) => a.id)
                );
              }
            }}
          >
            <Clear />
          </IconButton>
          <IconButton
            className="groupActionIcon"
            title={t("Crear sub-usuario")}
            onClick={() => {
              if (!group) {
                alert(t("Seleccione un grupo"));
                return;
              }

              setIsUserFormOpen(true);
            }}
          >
            <People />
          </IconButton>
          <IconButton className="groupActionIcon" title={t("Eliminar usuario")}>
            <Delete />
          </IconButton>
          <IconButton
            className="groupActionIcon"
            title={t("Editar usuario")}
            onClick={() => {
              if (!userToEdit) {
                alert("Seleccione un usuario");
                return;
              }

              setIsUserFormOpen(true);
            }}
          >
            <Edit />
          </IconButton> */}
        </div>
        <Paper elevation={3} className="groupPaper">
          <ul className="listUsers">
            {users.map((a) => (
              <li
                key={a.id}
                onMouseEnter={() => handleMouseEnterUser(a.id)}
                onMouseLeave={() => handleMouseEnterUser(null)}
                className={usersSelected.includes(a.id) && "userSelected"}
                onClick={() => {
                  const index = usersSelected.findIndex((x) => x === a.id);
                  setUserToEdit(a);
                  setUsersSelected(
                    index === -1
                      ? [...usersSelected, a.id]
                      : usersSelected.filter((x) => x !== a.id)
                  );
                }}
              >
                {`${a.firstName} ${a.lastName}`}
                {a.id === userOver && (
                  <ThreeDotMenu
                    row={a}
                    buttonClass="alignRight"
                    options={userActions}
                    callback={callbackUserAction}
                  />
                )}
              </li>
            ))}
          </ul>
        </Paper>
      </div>
    </ThemeProvider>
  );
};

export default UserPanel;
