import React, { useState } from "react";
import LineSelector from "../../../components/Selectors/LineSelector";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import { useTranslation } from "../../../lang";
const theme = createMuiTheme({});

const ModalLineas = ({
  isOpen,
  closeModal,
  asociateLines,
  ownLines,
  deviceId,
}) => {
  const [t] = useTranslation();
  const [linesSelected, setLinesSelected] = useState(ownLines);

  const handleLineSelect = (lines) => {
    setLinesSelected(lines);
  };

  const handleAsociateLines = () => {
    const linesA = linesSelected.map((l) => l.id);
    const linesD = ownLines
      .filter((x) => !linesA.includes(x.id))
      .map((l) => l.id);
    asociateLines({
      variables: {
        linesAsociated: linesA,
        linesDisAsociated: linesD,
        deviceId: deviceId,
      },
    });
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Dialog open={isOpen} onClose={closeModal} maxWidth="sm" fullWidth>
          <DialogTitle className="dialogTitle">{t("Líneas")}</DialogTitle>
          <DialogContent>
            <LineSelector
              callbackLineSelected={handleLineSelect}
              ownLines={ownLines}
            />
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => closeModal()}>
              {t("Cancelar")}
            </Button>
            <Button
              color="primary"
              onClick={() => {
                handleAsociateLines();
                closeModal();
              }}
            >
              {t("Guardar")}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

export default ModalLineas;
