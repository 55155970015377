import React, { useEffect, useState } from "react";

import { useLazyQuery } from "@apollo/client";

import { Grid, Snackbar } from "@material-ui/core";

import MuiAlert from "@material-ui/lab/Alert";
import { format } from "date-fns";
import { useLocation } from "react-router";

import POINT_GUIDENUMBER_QUERY from "../../graphql/queries/getPointGuidNumberQuery";

import Form from "./Form";
import Map from "./Map";
import { useTranslation } from "../../lang";

const CargoTrackingPage = ({}) => {
  const [t] = useTranslation();
  const location = useLocation();
  const [point, setPoint] = useState();
  const propState = location.state;

  const [resultState, setResultState] = useState({
    isOpen: false,
    type: "success",
    msg: "",
  });

  const [fetchPoint] = useLazyQuery(POINT_GUIDENUMBER_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      const p = d.getPoinGuideNumber;

      if (!p) {
        setResultState({
          isOpen: true,
          type: "error",
          msg: t("No se encontró el número de guia"),
        });
        return;
      }

      setPoint(p);
    },
    onError(err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: t("Ocurrió un error"),
      });
    },
  });

  const handleBuscarGuia = (guidNumber) => {
    if (!guidNumber) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: t("Ingrese el número de guia"),
      });
      return;
    }

    fetchPoint({
      variables: {
        GuideNumber: guidNumber,
      },
    });
  };

  useEffect(() => {
    if (propState && propState.guideNumber && propState.isCaptchaValid) {
      handleBuscarGuia(propState.guideNumber);
    }
  }, []);

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setResultState({
      ...resultState,
      isOpen: false,
    });
  };

  return (
    <>
      <Snackbar
        open={resultState.isOpen}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleCloseNotification}
          severity={resultState.type}
          variant="filled"
        >
          {resultState.msg}
        </MuiAlert>
      </Snackbar>
      <Grid container style={{ height: "100vh" }}>
        <Grid item xs={2}>
          <div style={{ margin: "50% 0 50% 20px" }}>
            <Form handleSearchGuidNumber={handleBuscarGuia} />
          </div>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={9}>
          <div style={{ margin: "25px" }}>
            <div
              style={{
                backgroundColor: "gray",
                height: "50px",
                color: "white",
                fontWeight: "bold",
                lineHeight: "50px",
                paddingLeft: "25px",
              }}
            >
              {`Última transmisión: ${
                point && point.fGPS
                  ? format(new Date(point.fGPS), "dd/MM/yyyy HH:mm:ss")
                  : "N/A"
              }`}
            </div>
            <Map point={point} />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default CargoTrackingPage;
