import React, { useState, useEffect } from "react";

import { connect } from "react-redux";

import { useLazyQuery } from "@apollo/client";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import GenericSelector from "../Selectors/GenericSelector";

import GET_POI_QUERY from "../../graphql/queries/getPoiQuery";

import { useTranslation } from "../../lang";

const theme = createMuiTheme({});

const ModalPoi = ({
  isOpen,
  closeModal,
  handleSelectionPoi,
  selectedCustomers,
}) => {
  const [t] = useTranslation();
  const [poiSelected, setPoiSelected] = useState([]);
  const [poiList, setPoiList] = useState([]);

  const [getPoi] = useLazyQuery(GET_POI_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      setPoiList(d.poi);
    },
  });

  useEffect(() => {
    let customerId;

    if (Array.isArray(selectedCustomers) && selectedCustomers.length > 0) {
      customerId = selectedCustomers[selectedCustomers.length - 1].value;
    }

    if (customerId) {
      getPoi({
        variables: {
          customerId: customerId,
        },
      });
    }
  }, [selectedCustomers]);

  const handlePoiSelect = (poi) => {
    const ids = poi.map((p) => p.value);
    const poiFound = poiList.filter((p) => ids.includes(p.id));
    setPoiSelected(poiFound);
  };

  const columns = [
    {
      dataField: "name",
      text: t("Nombre"),
    },
  ];

  return (
    <>
      <ThemeProvider theme={theme}>
        <Dialog open={isOpen} onClose={closeModal} maxWidth="sm" fullWidth>
          <DialogTitle className="dialogTitle">
            {t("Seleccione Puntos de interes")}
          </DialogTitle>
          <DialogContent>
            <GenericSelector
              callbackOptionSelected={(value) => handlePoiSelect(value)}
              columns={columns}
              isMulti
              keyField="id"
              labelField="name"
              title={t("Puntos de Interes")}
              data={poiList}
            />
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => closeModal()}>
              {t("Cancelar")}
            </Button>
            <Button
              color="primary"
              onClick={() => {
                handleSelectionPoi(poiSelected);
                closeModal();
              }}
            >
              {t("Guardar")}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

const _mapStateToProps = ({ groups }) => {
  return {
    ...groups,
  };
};

export default connect(_mapStateToProps, null)(ModalPoi);
