import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Snackbar,
} from "@material-ui/core";

import MuiAlert from "@material-ui/lab/Alert";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import ROLES_QUERY from "../../../graphql/queries/getRolesQuery";
import ASSIGN_ROLES_MUTATION from "../../../graphql/mutations/assignRolesMutation";

import Table from "../../../components/Base/Table";
import { TABLE_COLORS } from "../../../Theme.js";

import { useTranslation } from "../../../lang";
const theme = createMuiTheme({});

const ModalAsignarRoles = ({ isOpen, closeModal, selectedUser }) => {
  const [t] = useTranslation();
  const [resultState, setResultState] = useState({
    isOpen: false,
    type: "success",
    msg: "",
  });

  const [roles, setRoles] = useState([]);
  const [rolesSelected, setRolesSelected] = useState([]);

  useEffect(() => {
    if (!selectedUser) return;

    setRolesSelected(selectedUser.roles.map((r) => r.id));
  }, [selectedUser]);

  const {} = useQuery(ROLES_QUERY, {
    onCompleted(d) {
      // console.log("Roles: ", d.roles);
      setRoles(d.roles);
    },
    onError(e) {
      console.log("Error roles: ", e);
    },
  });

  const [assignRoles] = useMutation(ASSIGN_ROLES_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Roles asignados correctamente"),
      });
    },
    onError(err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: err.message,
      });
    },
  });

  const columns = [
    {
      dataField: "name",
      text: t("Rol"),
    },
    {
      dataField: "category",
      text: t("Categoria"),
    },
  ];

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      const isSelected = !rolesSelected.includes(row.id);
      setRolesSelected(
        isSelected
          ? [...rolesSelected, row.id]
          : rolesSelected.filter((r) => r !== row.id)
      );
    },
  };

  const rowStyle = (row, rowIndex) => {
    const style = {};
    if (rolesSelected && rolesSelected.includes(row.id)) {
      style.background = TABLE_COLORS.ROW_SELECTED; // "#1c3663";
      style.color = TABLE_COLORS.ROW_SELECTED_TEXT;
    }

    return style;
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setResultState({
      ...resultState,
      isOpen: false,
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Snackbar
        open={resultState.isOpen}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleCloseNotification}
          severity={resultState.type}
          variant="filled"
        >
          {resultState.msg}
        </MuiAlert>
      </Snackbar>
      <Dialog open={isOpen} onClose={closeModal} maxWidth="md" fullWidth>
        <DialogTitle className="dialogTitle">{t("Roles")}</DialogTitle>
        <DialogContent>
          <Table
            columns={columns}
            data={roles}
            //paginationOptions={}
            keyField="id"
            rowEvents={rowEvents}
            rowStyle={rowStyle}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => closeModal()}
          >
            {t("Cancelar")}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              assignRoles({
                variables: {
                  userId: selectedUser.id,
                  roleIds: rolesSelected,
                },
              });
              closeModal();
            }}
          >
            {t("Guardar")}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default ModalAsignarRoles;
