import React, { useState } from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
  Checkbox,
  TextField,
  IconButton,
  Snackbar,
} from "@material-ui/core";

import { Add, DeleteOutline } from "@material-ui/icons";

import MuiAlert from "@material-ui/lab/Alert";

import { useMutation, useLazyQuery } from "@apollo/client";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import CONFIGURATION_EVENTS_QUERY from "../../../graphql/queries/getConfigurationEventsQuery";
import CLONE_DEVICE_MUTATION from "../../../graphql/mutations/cloneDeviceMutation";

import GenericSelector from "../../../components/Selectors/GenericSelector";
import Table from "../../../components/Base/Table";

import { useTranslation } from "../../../lang";
const theme = createMuiTheme({});

const ModalClonacionEquipos = ({ isOpen, closeModal, deviceId }) => {
  const [t] = useTranslation();
  const [origin, setOrigin] = useState("all");
  const [configurationEvents, setConfigurationEvents] = useState([]);
  const [confEventsSelected, setConfEventsSelected] = useState();
  const [destinyDeviceStr, setDestinyDeviceStr] = useState("");
  const [destinyDevices, setDestinyDevices] = useState([]);

  const [resultState, setResultState] = useState({
    isOpen: false,
    type: "success",
    msg: "",
  });

  const [getConfEvents] = useLazyQuery(CONFIGURATION_EVENTS_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      setConfigurationEvents(d.configurationEventByEquipoId);
    },
  });

  const [saveClonation] = useMutation(CLONE_DEVICE_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Equipo clonado correctamente"),
      });
    },
    onError(e) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: e.message,
      });
    },
  });

  React.useEffect(() => {
    if (deviceId) {
      getConfEvents({
        variables: {
          deviceId: deviceId,
        },
      });
    }
  }, [deviceId, getConfEvents]);

  const columns = [
    {
      dataField: "typeEvent.description",
      text: t("Evento"),
      headerStyle: () => ({ width: "10%", fontSize: "12px" }),
    },
    {
      dataField: "value",
      text: t("Valor"),
      headerStyle: () => ({ width: "10%", fontSize: "12px" }),
    },
    {
      dataField: "isMovementAlarm",
      text: "Es alarma movimiento",
      headerStyle: () => ({ width: "10%", fontSize: "12px" }),
      formatter: (cell, row) => {
        return (
          <Checkbox color="primary" checked={row.isMovementAlarm} disabled />
        );
      },
    },
    {
      dataField: "isPositionRequest",
      text: t("Es pedido posición"),
      headerStyle: () => ({ width: "10%", fontSize: "12px" }),
      formatter: (cell, row) => {
        return (
          <Checkbox color="primary" checked={row.isPositionRequest} disabled />
        );
      },
    },
    {
      dataField: "isAutomaticReport",
      text: t("Es reporte automático"),
      headerStyle: () => ({ width: "10%", fontSize: "12px" }),
      formatter: (cell, row) => {
        return (
          <Checkbox color="primary" checked={row.isAutomaticReport} disabled />
        );
      },
    },
    {
      dataField: "isInputEvent",
      text: t("Es evento de entrada"),
      headerStyle: () => ({ width: "10%", fontSize: "12px" }),
      formatter: (cell, row) => {
        return <Checkbox color="primary" checked={row.isInputEvent} disabled />;
      },
    },
    {
      dataField: "isOutputEvent",
      text: t("Es evento de salida"),
      headerStyle: () => ({ width: "10%", fontSize: "12px" }),
      formatter: (cell, row) => {
        return (
          <Checkbox color="primary" checked={row.isOutputEvent} disabled />
        );
      },
    },
    {
      dataField: "isIgnitionOn",
      text: t("Es ignition on"),
      headerStyle: () => ({ width: "10%", fontSize: "12px" }),
      formatter: (cell, row) => {
        return <Checkbox color="primary" checked={row.isIgnitionOn} disabled />;
      },
    },
    {
      dataField: "isIgnitionOff",
      text: t("Es ignition off"),
      headerStyle: () => ({ width: "10%", fontSize: "12px" }),
      formatter: (cell, row) => {
        return (
          <Checkbox color="primary" checked={row.isIgnitionOff} disabled />
        );
      },
    },
    {
      dataField: "isOpenDoor",
      text: t("Es puerta abierta"),
      headerStyle: () => ({ width: "10%", fontSize: "12px" }),
      formatter: (cell, row) => {
        return <Checkbox color="primary" checked={row.isOpenDoor} disabled />;
      },
    },
    {
      dataField: "isSoftwareEvent",
      text: t("Es evento software"),
      headerStyle: () => ({ width: "10%", fontSize: "12px" }),
      formatter: (cell, row) => {
        return (
          <Checkbox color="primary" checked={row.isSoftwareEvent} disabled />
        );
      },
    },
  ];

  const columnsDestinyDevices = [
    {
      dataField: "id",
      text: t("Equipo"),
      headerStyle: () => ({ width: "80%", fontSize: "12px" }),
    },
    {
      dataField: "eliminar",
      text: "",
      headerStyle: () => ({ width: "20%", fontSize: "12px" }),
      formatter: (cell, row) => {
        return (
          <IconButton
            className="actionIcon"
            onClick={() =>
              setDestinyDevices(destinyDevices.filter((x) => x.id !== row.id))
            }
          >
            <DeleteOutline style={{ color: "white" }} />
          </IconButton>
        );
      },
    },
  ];

  const addDestinyDevices = () => {
    const devices = destinyDeviceStr.split(";");
    setDestinyDevices([...destinyDevices, ...devices.map((d) => ({ id: d }))]);
  };

  const handleSaveClonation = () => {
    if (!(Array.isArray(destinyDevices) && destinyDevices.length > 0)) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: t("Seleccione uno o mas equipos de destino"),
      });
      return;
    }

    if (
      origin !== "all" &&
      !(Array.isArray(confEventsSelected) && confEventsSelected.length > 0)
    ) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: t("Seleccione uno o mas eventos a clonar"),
      });
      return;
    }

    saveClonation({
      variables: {
        sourceDeviceId: deviceId,
        destinationDevices: destinyDevices.map((dd) => dd.id),
        clonationType: origin,
        eventsToCopy:
          origin === "all" ? [] : confEventsSelected.map((ce) => ce.value),
      },
    });
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setResultState({
      ...resultState,
      isOpen: false,
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Snackbar
        open={resultState.isOpen}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleCloseNotification}
          severity={resultState.type}
          variant="filled"
        >
          {resultState.msg}
        </MuiAlert>
      </Snackbar>
      <Dialog open={isOpen} onClose={closeModal} maxWidth="md" fullWidth>
        <DialogTitle className="dialogTitle">
          {t("Clonación de Equipos")}
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={4}>
              <FormControl component="fieldset">
                <FormLabel component="legend" style={{ color: "white" }}>
                  {t("Origen de la clonación")}
                </FormLabel>
                <RadioGroup
                  aria-label="origin"
                  value={origin}
                  onChange={(evt) => setOrigin(evt.target.value)}
                >
                  <FormControlLabel
                    value="all"
                    control={<Radio color="primary" />}
                    label={t("Clonar toda la configuración")}
                  />
                  <FormControlLabel
                    value="someEvents"
                    control={<Radio color="primary" />}
                    label={t("Clonar solo algunos eventos")}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={8}>
              {origin === "someEvents" && (
                <GenericSelector
                  callbackOptionSelected={(value) =>
                    setConfEventsSelected(value)
                  }
                  columns={columns}
                  isMulti
                  keyField="id"
                  labelField="typeEvent.description"
                  title={t("Eventos")}
                  data={configurationEvents}
                />
              )}
            </Grid>
            <Grid item xs={5} style={{ marginTop: "50px" }}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend" style={{ color: "white" }}>
                  {t("Destino de la clonación")}
                </FormLabel>
                <TextField
                  value={destinyDeviceStr}
                  variant="standard"
                  onChange={(evt) => setDestinyDeviceStr(evt.target.value)}
                  placeholder={t(
                    "Puede escribir varios equipos separador por ;"
                  )}
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={addDestinyDevices}>
                        <Add style={{ color: "white" }} />
                      </IconButton>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={5}>
              <Table
                columns={columnsDestinyDevices}
                data={destinyDevices}
                //paginationOptions={}
                keyField="id"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={closeModal}>
            {t("Cancelar")}
          </Button>
          <Button
            color="primary"
            onClick={() => {
              handleSaveClonation();
            }}
          >
            {t("Guardar")}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default ModalClonacionEquipos;
