import React, { useState } from "react";
import DateSelector from "../Selectors/DateSelector";

import { format } from "date-fns";

import { Grid, IconButton, Menu, MenuItem, Button } from "@material-ui/core";

import { ExitToApp } from "@material-ui/icons";

import GenericSelector from "../Selectors/GenericSelector";

import { useTranslation } from "../../lang";

const dateFormat = "yyyy-MM-dd HH:mm:ss";
const Filter = ({ driverList, assetList, fetchReport }) => {
  const [t] = useTranslation();
  const [anchorExport, setAnchorExport] = useState();
  const [fechas, setFechas] = useState({
    fechaDesde: format(new Date(new Date() - 60 * 60 * 1000), dateFormat),
    fechaHasta: format(new Date(), dateFormat),
  });

  const [dias, setDias] = useState([
    t("LUNES"),
    t("MARTES"),
    t("MIERCOLES"),
    t("JUEVES"),
    t("VIERNES"),
    t("SABADO"),
    t("DOMINGO"),
  ]);

  const [assetsSelected, setAssetsSelected] = useState();
  const [driversSelected, setDriversSelected] = useState();

  const callbackAssetSelected = (values) => {
    setAssetsSelected(values.map((v) => v.value));
  };

  const callbackDriverSelected = (values) => {
    setDriversSelected(values.map((v) => v.value));
  };

  const columnsAssets = [
    {
      dataField: "name",
      text: t("Nombre"),
      sort: true,
    },
    {
      dataField: "licensePlate",
      text: t("Patente"),
      sort: true,
    },
    {
      dataField: "internalNumber",
      text: t("Interno"),
      sort: true,
    },
    {
      dataField: "driver.firstName",
      text: t("Chofer"),
      sort: true,
    },
    {
      dataField: "typeMobile.description",
      text: t("Tipo"),
      sort: true,
    },
  ];
  const columnsDrivers = [
    {
      dataField: "firstName",
      text: t("Nombre"),
      sort: true,
    },
    {
      dataField: "lastName",
      text: t("Apellido"),
      sort: true,
    },
  ];

  const handleChangePeriod = (evt) => {
    if (evt.fechaDesde && evt.fechaHasta) {
      setFechas({
        fechaDesde: evt.fechaDesde,
        fechaHasta: evt.fechaHasta,
      });
    }

    if (evt.dias) {
      setDias(evt.dias);
    }
  };

  return (
    <>
      <Grid container className="driverBehaviorFilter">
        <Grid item xs={5}>
          <DateSelector onChange={handleChangePeriod} />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              fetchReport(
                assetsSelected,
                driversSelected,
                fechas.fechaDesde,
                fechas.fechaHasta,
                dias
              );
            }}
          >
            {t("Buscar")}
          </Button>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            aria-controls="exportMenu"
            aria-haspopup="true"
            onClick={(evt) => setAnchorExport(evt.currentTarget)}
            className="exportButtonDB"
          >
            <ExitToApp style={{ color: "white" }} />
          </IconButton>
          <Menu
            id="exportMenu"
            anchorEl={anchorExport}
            keepMounted
            open={Boolean(anchorExport)}
            onClose={() => setAnchorExport(null)}
          >
            <MenuItem /*onClick={() => handleExportSelect("excel")}*/>
              {t("EXCEL")}
            </MenuItem>
            <MenuItem /*onClick={() => handleExportSelect("pdf")}*/>
              {t("PDF")}
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
      <Grid container className="selectorFilterRow">
        <Grid item xs={5}>
          <GenericSelector
            callbackOptionSelected={callbackAssetSelected}
            columns={columnsAssets}
            data={assetList}
            keyField="id"
            labelField="licensePlate"
            title={t("Móviles")}
            isMulti
            limitTags={1}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5}>
          <GenericSelector
            callbackOptionSelected={callbackDriverSelected}
            columns={columnsDrivers}
            keyField="id"
            data={driverList}
            labelField="fullName"
            title={t("Conductores")}
            isMulti
            limitTags={1}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Filter;
