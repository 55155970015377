import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import "./Dropdown/DropdownList.scss";
import "./StyledSelect.scss";

const StyledSelect = ({
  id,
  label,
  value,
  onChange,
  textColor,
  options,
  lateralPanelDepth,
  className,
  shrinkLabel,
  ...selectProps
}) => {
  const wrapperClassNames = classNames("form-control styled-select__wrapper", {
    [`text-color--${textColor}`]: textColor,
    [`${className}`]: className,
  });
  const listClassNames = classNames("styled-select__menu dropdown-list", {
    "styled-select__menu--lateral-panel": lateralPanelDepth,
    [`styled-select__menu--depth-${lateralPanelDepth}`]: lateralPanelDepth,
  });

  const labelId = `${id}-label`;
  const labelClassName = !shrinkLabel && value ? "a11y-hidden" : "";

  return (
    <FormControl className={wrapperClassNames}>
      <InputLabel className={labelClassName} shrink={shrinkLabel} id={labelId}>
        {label}
      </InputLabel>

      <Select
        labelId={labelId}
        id={id}
        value={value}
        onChange={onChange}
        MenuProps={{
          className: listClassNames,
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        }}
        IconComponent={ExpandMoreOutlinedIcon}
        {...selectProps}
      >
        {options &&
          options.map(({ value: optionValue, text }) => (
            <MenuItem key={`${id}_${optionValue}`} value={optionValue}>
              {text}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

StyledSelect.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  textColor: PropTypes.string,
  options: PropTypes.array,
  lateralPanelDepth: PropTypes.number,
  className: PropTypes.string,
  shrinkLabel: PropTypes.bool,
};

StyledSelect.defaultProps = {
  id: "",
  label: "",
  value: "",
  onChange: () => {},
  textColor: "",
  options: [],
  lateralPanelDepth: 0,
  className: "",
  shrinkLabel: false,
};

export default StyledSelect;
