import gql from "graphql-tag";

export const DELETE_DEVICE_MUTATION_AVAILABLE_ERRORS = {
  CANT_DELETE_DEVICE_ASSIGNED_TO_ASSET: "CANT_DELETE_DEVICE_ASSIGNED_TO_ASSET",
};

const DELETE_DEVICE_MUTATION = gql`
  mutation DeleteDevice($id: Int!) {
    deleteDevice(id: $id) {
      id
    }
  }
`;

export default DELETE_DEVICE_MUTATION;
