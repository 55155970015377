import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const JurneyIcon = function () {
  return (
    <SvgIcon viewBox="0 0 26 26">
      <path
        d="M63.6,59.688H58.135a2.344,2.344,0,1,1,0-4.688h4.688s4.688-5.224,4.688-7.812a4.688,4.688,0,0,0-9.375,0c0,1.69,2,4.5,3.384,6.25H58.135a3.906,3.906,0,1,0,0,7.813H63.6a2.344,2.344,0,0,1,0,4.688H48.5c1.387-1.748,3.384-4.56,3.384-6.25a4.688,4.688,0,0,0-9.375,0c0,2.588,4.688,7.813,4.688,7.813H63.6a3.906,3.906,0,1,0,0-7.812ZM59.7,47.185a3.125,3.125,0,0,1,6.25,0c0,1-1.47,3.32-3.125,5.39C61.162,50.5,59.7,48.154,59.7,47.188Zm-15.625,12.5a3.125,3.125,0,0,1,6.25,0c0,1-1.47,3.32-3.125,5.39-1.66-2.08-3.124-4.423-3.124-5.39Zm3.125-.781a.781.781,0,0,0,0,1.562.781.781,0,0,0,0-1.562ZM62.822,46.4a.781.781,0,0,0,0,1.563.781.781,0,0,0,0-1.562Z"
        transform="translate(-42.51 -42.5)"
      />
    </SvgIcon>
  );
};

export default JurneyIcon;
