import React from "react";
import { Grid, Checkbox } from "@material-ui/core";

import { format } from "date-fns";
import { useTranslation } from "../../lang";
import WarningIcon from "../../public/warningIcon.png";
import ErrorIcon from "../../public/errorIcon.png";

const IconState = ({ item }) => {
  const {
    frequency,
    warningDate,
    dueDate,
    actualOdo,
    warningKm,
    dueOdo,
  } = item;
  const actualDate = new Date();
  if (frequency.id === 6) {
    //Kms
    if (actualOdo > dueOdo)
      return <img src={ErrorIcon} className="revisionIconState" />;
    if (actualOdo >= warningKm)
      return <img src={WarningIcon} className="revisionIconState" />;

    return <Checkbox checked={true} disabled />;
  } else {
    //Por fechas
    if (actualDate >= dueDate)
      return <img src={ErrorIcon} className="revisionIconState" />;
    if (actualDate >= warningDate)
      return <img src={WarningIcon} className="revisionIconState" />;

    return <Checkbox checked={true} disabled />;
  }
};

const ItemRevisionNotice = ({
  item,
  setMidSelected,
  setRevisionNoticeSelected,
  openPanelAux,
  changePanelAuxType,
  changePanelAuxTitle,
}) => {
  const [t] = useTranslation();
  return (
    <Grid
      item
      xs={12}
      className="itemRevisionNotice"
      onClick={() => {
        setMidSelected(item.asset.id);
        setRevisionNoticeSelected(item);
        changePanelAuxTitle(t("Editar Aviso de Revision"));
        changePanelAuxType("RevisionNotice");
        openPanelAux();
      }}
    >
      <div style={{ width: "8.3%", display: "inline-block" }}>
        <IconState item={item} />
      </div>
      <div style={{ width: "50%", display: "inline-block" }}>
        {t(item.serviceType.description)}
      </div>
      <div style={{ width: "41.7%", display: "inline-block" }}>
        {item.frequency.id === 6 ? (
          <>
            <p style={{ marginBottom: "0px" }}>{`${t("Odometro actual")}: ${
              item.actualOdo
            }`}</p>
            <p style={{ marginBottom: "0px" }}>{`${t("Odometro revisión")}: ${
              item.dueOdometer
            }`}</p>
          </>
        ) : (
          <label>{format(new Date(item.dueDate), "dd/MM/yyyy")}</label>
        )}
      </div>
    </Grid>
  );
};

export default ItemRevisionNotice;
