import { format } from "date-fns";
import L from "leaflet";
import defaultIcon from "leaflet/dist/images/marker-icon.png";
import defaultIconShadow from "leaflet/dist/images/marker-shadow.png";
import React, { useEffect, useState } from "react";
import { MapContainer, Marker, Polygon, Popup, TileLayer } from "react-leaflet";
import { connect } from "react-redux";
import { useTranslation } from "../../../lang";
import { GetDateFormat } from "../../../utils/generalUtils";
import RoadPath from "../../Manage/Routes/RoadPath";

const DEFAULT_CENTER_MAP = process.env.REACT_APP_DEFAULT_CENTER_MAP.split(",");
const DEFAULT_ZOOM = 16;
const icon = L.icon({
  iconUrl: defaultIcon,
  shadowUrl: defaultIconShadow,
  iconAnchor: [12.5, 41],
});

const ReportResult = ({ reportData, user }) => {
  const [t] = useTranslation();
  const defaultPosition = DEFAULT_CENTER_MAP;
  const zoom = DEFAULT_ZOOM;
  const { openArmReport, roadReport } = reportData;
  const [map, setMap] = useState();

  // This method convert this string: "[[1,2],[3,4]]" to this array: [[1,2],[3,4]]
  const convertStringToArray = (str) => {
    return JSON.parse(str);
  };

  useEffect(() => {
    if (openArmReport && openArmReport.length > 0) {
      const bounds = new L.LatLngBounds(openArmReport);
      map && map.fitBounds(bounds);
    } else {
      const bounds = [];
      roadReport.roadGeofence.map((g) =>
        bounds.push(convertStringToArray(g.geofence.geomText))
      );
      map && map.fitBounds(bounds.flat());
    }
  }, [map, openArmReport]);

  return (
    <div id="routeDetourReport">
      <MapContainer
        center={defaultPosition}
        zoom={zoom}
        // scrollWheelZoom={false}
        style={{ width: "100%", height: "100%" }}
        whenCreated={(instance) => {
          setMap(instance);
        }}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {roadReport && <RoadPath road={roadReport} color="blue" />}
        {openArmReport &&
          openArmReport.map((m) => {
            return (
              <Marker
                position={[m.lat, m.lon]}
                icon={icon}
                classNames="marker-container"
              >
                <Popup>
                  <span style={{ fontWeight: "bold" }}>{t("Orden")}: </span>
                  {m.order}
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    {t("Fecha de Evento")}:{" "}
                  </span>
                  {format(new Date(m.fGPS), GetDateFormat(user.language))}
                  <br />
                  <span style={{ fontWeight: "bold" }}>{t("Móvil")}: </span>
                  {m.nMov}
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    {t("Hora de Llegada Prevista")}:{" "}
                  </span>
                  {format(new Date(m.stop_time), "HH:mm:ss")}
                </Popup>
              </Marker>
            );
          })}

        {roadReport?.roadGeofence &&
          roadReport.roadGeofence.map((g) => {
            return (
              <Polygon
                positions={convertStringToArray(g.geofence.geomText)}
                color="purple"
              />
            );
            // pathOptions={limeOptions}
          })}
      </MapContainer>
    </div>
  );
};
const _mapStateToProps = ({ user }) => {
  return {
    user,
  };
};

const _mapDispatchToProps = (dispatch) => {
  return {
    // _setCurrentUser: (param) => dispatch(setCurrentUser(param)),
  };
};

export default connect(_mapStateToProps, _mapDispatchToProps)(ReportResult);
