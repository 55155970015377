import GET_MARKER_JOURNEY_QUERY from "../../graphql/queries/markerJourneyQuery";
import Client from "../../components/Base/apiApolloClient";
import { format, toDate } from "date-fns";
import { localDateTimeStringToUTC } from "../../utils/dates";

export const UPDATE_MARKER_JOURNEY = "UPDATE_MARKER_JOURNEY";

export const toggleFillMarkerJourney2 = ({ markerJourney }) => (
  dispatch,
  getState
) => {
  dispatch(_updateMarkerJourney(markerJourney));
};

const client = Client;
let sD = "2021-03-01";
let eD = "2021-03-02";

export const _updateMarkerJourney = (content) => ({
  type: UPDATE_MARKER_JOURNEY,
  payload: content,
});

const applyFormat = (date) => {
  return format(date, "yyyy-MM-dd HH:mm:ss");
};

const calculatePeriod = (param, fechaDesde, fechaHasta) => {
  switch (param) {
    case 1: // Ultima hora
      var ONE_HOUR = 60 * 60 * 1000;
      eD = applyFormat(new Date());
      var sDResta = new Date(new Date() - ONE_HOUR);
      sD = applyFormat(sDResta);
      return true;
    case 2: // Ultima 6 horas
      var SIX_HOUR = 360 * 60 * 1000;
      eD = applyFormat(new Date());
      var sDResta = new Date(new Date() - SIX_HOUR);
      sD = applyFormat(sDResta);
      return true;
    case 3: // Ayer
      const yesterday2 = new Date();
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      yesterday.setHours(0);
      yesterday.setMinutes(0);
      yesterday.setSeconds(0);

      yesterday2.setDate(yesterday2.getDate() - 1);
      yesterday2.setHours(23);
      yesterday2.setMinutes(59);
      yesterday2.setSeconds(59);

      sD = applyFormat(yesterday);
      eD = applyFormat(yesterday2);
      return true;
    case 4: // Hoy
      const today = new Date();
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);

      sD = applyFormat(today);
      eD = applyFormat(new Date());
      return true;
    case 5: // Ultima Semana
      // var WEEK_HOUR = 10080 * 60 * 1000;
      // var sDResta = new Date(new Date() - WEEK_HOUR);
      // sD = applyFormat(sDResta);
      // eD = applyFormat(new Date());
      // Semana Pasada
      let hoy = new Date();
      let lunes = new Date(
        hoy.getFullYear(),
        hoy.getMonth(),
        hoy.getDate() - hoy.getDay() + 1
      );
      let lunesPasado = new Date(
        lunes.getFullYear(),
        lunes.getMonth(),
        lunes.getDate() - 7
      );
      let domingoPasado = new Date(
        hoy.getFullYear(),
        hoy.getMonth(),
        hoy.getDate() - hoy.getDay(),
        23,
        59,
        59
      );
      sD = lunesPasado;
      eD = domingoPasado;
      return true;
    case 6: // Ultimo Mes
      // var MONTH_HOUR = 43200 * 60 * 1000;
      // var sDResta = new Date(new Date() - MONTH_HOUR);
      // sD = applyFormat(sDResta);
      // eD = applyFormat(new Date());
      // Mes Pasado
      let nuevaFecha = new Date();
      nuevaFecha = new Date(nuevaFecha.getFullYear(), nuevaFecha.getMonth(), 0);
      sD = new Date(
        nuevaFecha.getFullYear(),
        nuevaFecha.getMonth(),
        1,
        0,
        0,
        0
      );
      eD = new Date(
        nuevaFecha.getFullYear(),
        nuevaFecha.getMonth() + 1,
        0,
        23,
        59,
        59
      );
      return true;
    case 7: // Personalizado
      var start = new Date(fechaDesde);
      var end = new Date(fechaHasta);
      sD = applyFormat(start);
      eD = applyFormat(end);
      return true;
  }
};

export const toggleFillMarkerJourney = ({ markerId, period }) => (
  dispatch,
  getState
) => {
  const mid = markerId;
  calculatePeriod(period);
  // console.log("mid: ", mid);
  // console.log("start: " + sD);
  // console.log("end: " + eD);

  getJourneyByDate(mid, null, null, dispatch);
};

export const toggleFillMarkerJourneyPersonalizado = ({
  markerId,
  fechaDesde,
  fechaHasta,
}) => (dispatch, getState) => {
  // console.log("toggleFillMarkerJourneyPersonalizado");
  /*const mid = markerId;
  calculatePeriod(7, fechaDesde, fechaHasta);
  console.log("start: " + sD);
  console.log("end: " + eD);*/

  getJourneyByDate(markerId, fechaDesde, fechaHasta, dispatch);
};

const getJourneyByDate = (mid, fechaDesde, fechaHasta, dispatch) => {
  if (!fechaDesde || !fechaHasta) {
    return;
  }

  client
    .query({
      query: GET_MARKER_JOURNEY_QUERY,
      variables: {
        movilID: mid,
        fechaInicio: localDateTimeStringToUTC(fechaDesde),
        fechaFin: localDateTimeStringToUTC(fechaHasta),
      },
    })
    .then(
      (res) => {
        if (res != null && res.data != null && res.data.journeys !== null) {
          return dispatch(_updateMarkerJourney(res.data.journeys));
        }
      },
      (error) => {
        return null;
      }
    );
};
