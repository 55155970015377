import gql from "graphql-tag";

const GET_LAST_HISTORY_POINT_OBD2_BY_ID_QUERY = gql`
  query getLastHistoryPointOBD2ById(
    $eid: Int!
    $evt: String!
    $fGPS: Datetime!
  ) {
    getLastHistoryPointOBD2ById(eid: $eid, evt: $evt, fGPS: $fGPS) {
      historyPointOBD2 {
        # eid,
        # fGPS,
        # evt,
        # mid,
        nDTC
        cELV
        eCT
        fP
        fT
        iMAP
        rpm
        speed
        tA
        iAT
        tP
        tIgn
        dTMIL
        panic
        ignition
        movement
        bI
        aC
        gsm
      }
      summary {
        velocidad
        fGPS
      }
    }
  }
`;

export default GET_LAST_HISTORY_POINT_OBD2_BY_ID_QUERY;
