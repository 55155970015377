import React, { useState } from "react";

import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ThreeDotMenu from "../../../components/Base/ThreeDotMenu";

import "react-confirm-alert/src/react-confirm-alert.css";

import { Button } from "@material-ui/core";

import ClientModal from "./ClientForm";
import RemoteTable from "../../../components/Base/RemoteTable";
import ModalConfirmacion from "../../../components/Base/ModalConfirmacion";

import { useTranslation } from "../../../lang";
import ClientGeofenceKMLForm from "./ClientGeofenceKMLForm";

const List = ({
  paginationSizes,
  total,
  paginationState,
  setPaginationState,
  customerList,
  createCustomer,
  updateCustomer,
  getCustomers,
  deleteCustomer,
  importGeofences,
}) => {
  const [t] = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [openModalImportGeofences, setOpenModalImportGeofences] = useState(
    false
  );
  const [selectedItem, setSelectedItem] = useState();
  const [textToSearch, setTextToSearch] = useState("");

  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const closeModalConfirm = () => setIsOpenConfirm(false);
  const [confirmText, setConfirmText] = useState();
  const [idToDelete, setIdToDelete] = useState();

  const callbackSelectOption = (option, row) => {
    switch (option) {
      case 1:
        setSelectedItem(row);
        setOpenModal(true);
        break;
      case 2:
        //confirmDelete(row.id, row.name);
        setIdToDelete(row.id);
        setIsOpenConfirm(true);
        setConfirmText(
          t("pages_manage_clients_eliminar_cliente", { name: row.name })
        );
        break;
      case 3:
        setSelectedItem(row);
        setOpenModalImportGeofences(true);
        break;
      default:
        break;
    }
  };

  const itemStyle = {
    fontSize: "12px",
  };

  const hightlightIfMatch = (text) => {
    if (
      text &&
      textToSearch &&
      text.toLocaleLowerCase().includes(textToSearch.toLocaleLowerCase())
    ) {
      const newText = text.replace(
        new RegExp(textToSearch, "gi"),
        (match) => `<mark>${match}</mark>`
      );
      return <div dangerouslySetInnerHTML={{ __html: newText }} />;
    }

    return text;
  };

  const columns = [
    {
      dataField: "id",
      text: "",
      headerStyle: () => ({ width: "50px", fontSize: "12px" }),
      formatter: (_, row) => {
        return (
          <>
            <ThreeDotMenu
              key={row.id}
              row={row}
              callback={callbackSelectOption}
              options={[
                {
                  label: `${t("Editar")}`,
                  value: 1,
                },
                {
                  label: `${t("Importar Geocercas (KML)")}`,
                  value: 3,
                },
                {
                  label: `${t("Eliminar")}`,
                  value: 2,
                },
              ]}
            />
          </>
        );
      },
    },
    {
      dataField: "id",
      text: t("ID"),
      headerStyle: () => ({ width: "10%", fontSize: "12px" }),
      style: () => itemStyle,
      formatter: hightlightIfMatch,
      sort: true,
    },
    {
      dataField: "name",
      text: t("Nombre"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      style: () => itemStyle,
      formatter: hightlightIfMatch,
      sort: true,
    },
    {
      dataField: "taxId",
      text: t("CUIT"),
      headerStyle: () => ({ width: "15%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
      formatter: hightlightIfMatch,
      // filter: textFilter(),
    },
    {
      dataField: "phone",
      text: t("Telefonos"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
      formatter: hightlightIfMatch,
      // filter: textFilter(),
    },
    {
      dataField: "celPhone",
      text: t("Celular"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
      formatter: hightlightIfMatch,
      // filter: textFilter(),
    },
    {
      dataField: "address",
      text: t("Domicilio"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
      formatter: hightlightIfMatch,
      // filter: textFilter(),
    },
  ];

  const customTotal = (from, to, size) => (
    <span
      className="react-bootstrap-table-pagination-total customTotalDisplay"
      style={{ marginLeft: "15px" }}
    >
      {t("pages_manage_clients_mostrar_resultados", {
        from,
        to,
        size,
      })}
    </span>
  );

  const options = {
    // Tooltips botones de paginación
    prePageTitle: t("Página previa"),
    nextPageTitle: t("Próxima página"),
    firstPageTitle: t("Primera página"),
    lastPageTitle: t("Última página"),
    showTotal: true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: paginationSizes,
    totalSize: total,
  };

  const onSearch = (searchText) => {
    setTextToSearch(searchText);
    if (!searchText) {
      setPaginationState({
        ...paginationState,
        filter: undefined,
      });
    }

    if (searchText.length >= 3) {
      setPaginationState({
        ...paginationState,
        pageNumber: 1,
        filter: {
          OR: [
            {
              name: {
                contains: searchText,
                mode: "insensitive",
              },
            },
            {
              taxId: {
                contains: searchText,
                mode: "insensitive",
              },
            },
            {
              phone: {
                contains: searchText,
                mode: "insensitive",
              },
            },
            {
              celPhone: {
                contains: searchText,
                mode: "insensitive",
              },
            },
            {
              address: {
                contains: searchText,
                mode: "insensitive",
              },
            },
          ],
        },
      });
    }
  };

  const onPaginate = (page, sizePerPage) => {
    setPaginationState({
      ...paginationState,
      pageNumber: page,
      pageSize: sizePerPage,
    });
  };

  const onSort = (sortObj) => {
    setPaginationState({
      ...paginationState,
      sort: sortObj,
    });
  };

  return (
    <div className="listContainer">
      <ModalConfirmacion
        isOpen={isOpenConfirm}
        buttonConfirmation="Confirmar"
        closeModal={closeModalConfirm}
        confirmationText={confirmText}
        handleConfirm={() => {
          deleteCustomer(idToDelete);
        }}
        title={t("IMPORTANTE")}
      />
      <SlidingPane
        className="slidingPaneRight"
        isOpen={openModal}
        title={selectedItem ? t("Actualizar Cliente") : t("Crear Cliente")}
        width="400px"
        onRequestClose={() => {
          setOpenModal(false);
        }}
      >
        <ClientModal
          setIsOpen={() => setOpenModal(false)}
          createCustomer={createCustomer}
          selectedCustomer={selectedItem}
          updateCustomer={updateCustomer}
        />
      </SlidingPane>
      <SlidingPane
        className="slidingPaneRight"
        isOpen={openModalImportGeofences}
        title={t("Importar Geocercas")}
        width="400px"
        onRequestClose={() => {
          setOpenModalImportGeofences(false);
        }}
      >
        <ClientGeofenceKMLForm
          setIsOpen={() => setOpenModalImportGeofences(false)}
          importGeofences={importGeofences}
          selectedCustomer={selectedItem}
        />
      </SlidingPane>
      <RemoteTable
        columns={columns}
        data={customerList}
        keyField="id"
        paginationOptions={options}
        onPaginate={onPaginate}
        onSearch={onSearch}
        onSort={onSort}
        total={total}
        lateralComponent={
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setSelectedItem(null);
              setOpenModal(true);
            }}
            style={{ float: "right" }}
          >
            {t("+ Cliente")}
          </Button>
        }
      />
    </div>
  );
};

export default List;
