import React, { useState, useEffect } from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import { MapContainer, TileLayer, Marker, Tooltip } from "react-leaflet";
import Leaflet from "leaflet";

import "leaflet/dist/leaflet.css";
import "../../components/Map/BaseMap.scss";

import defaultIcon from "leaflet/dist/images/marker-icon.png";
import defaultIconShadow from "leaflet/dist/images/marker-shadow.png";
import NavigationMarker from "../Map/NavigationMarker";
import { useLazyQuery } from "@apollo/client";
import GET_HISTORY_POINT_QUERY from "../../graphql/queries/getHistoryPointQuery";
import { GetType } from "../../utils/generalUtils";
import { useTranslation } from "../../lang";

const theme = createMuiTheme({});

const MiniMap = ({ lat, lon, tooltipText, geofence, point }) => {
  // const defaultPosition = [-12.050742, -77.04656];
  const defaultPosition = process.env.REACT_APP_DEFAULT_CENTER_MAP.split(",");

  const position = lat && lon ? [lat, lon] : null;
  const [map, setMap] = useState();
  const [markerInfo, setMarkerInfo] = useState();
  const [mapDimensions, setMapDimensions] = useState({
    width: "910px", // 550px
    height: "600px", // 400px
  });

  const icon = Leaflet.icon({
    iconUrl: defaultIcon,
    shadowUrl: defaultIconShadow,
    iconAnchor: [12, 40],
  });

  useEffect(() => {
    if (!map) return;

    if (geofence && geofence.geomJson) {
      let parsedJson = JSON.parse(geofence.geomJson);
      let geoJSON = Leaflet.geoJson(parsedJson).addTo(map);
      map.fitBounds(geoJSON.getBounds());
    }
  }, [map, geofence]);

  const [getPoint] = useLazyQuery(GET_HISTORY_POINT_QUERY, {
    variables: {
      id: point?.id,
    },
    fetchPolicy: "no-cache",
    onCompleted: (d) => {
      setMarkerInfo(d.getHistoryPoint);
    },
    onError: (error) => {
      console.log("Error:", error);
    },
  });

  useEffect(() => {
    if (point) {
      getPoint();
    }
  }, []);

  return (
    <MapContainer
      center={position || defaultPosition}
      zoom={13}
      scrollWheelZoom={false}
      style={{
        width: mapDimensions.width,
        height: mapDimensions.height,
        // width: point ? "910px" : "550px",
        // height: point ? "600px" : "400px",
      }}
      whenCreated={(instance) => setMap(instance)}
    >
      <TileLayer
        // attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {position &&
        (point ? (
          <NavigationMarker
            markerInfo={{
              pos: { lat: lat, lng: lon },
              cur: markerInfo?.cur,
              dir: markerInfo?.dir,
              eid: markerInfo?.eid,
              fGPS: markerInfo?.fGPS,
              horo: markerInfo?.horo,
              mid: markerInfo?.mid,
              nCh: markerInfo?.nCh,
              nCli: markerInfo?.nCli,
              nInt: markerInfo?.nInt,
              nMov: markerInfo?.nMov,
              odo: markerInfo?.odo,
              pat: markerInfo?.pat,
              sOdo: markerInfo?.sOdo,
              tDet: markerInfo?.tDet,
              tIgn: markerInfo?.tIgn,
              typ: GetType(markerInfo?.typ),
              utx: markerInfo?.utx,
            }}
            viewDetail={false}
          />
        ) : (
          <Marker position={position} icon={icon} classNames="marker-container">
            {/* <ConnectedNavigationPopup markerInfo={markerInfo} /> */}
            <Tooltip>{tooltipText}</Tooltip>
          </Marker>
        ))}
    </MapContainer>
  );
};

const ModalMiniMap = ({
  isOpen,
  closeModal,
  lat,
  lon,
  tooltipText,
  title,
  geofence,
  point,
}) => {
  const [t] = useTranslation();
  const handleCloseModal = () => {
    //document.body.removeChild(document.getElementById('map-container'));
    closeModal();
  };

  return (
    // <ThemeProvider theme={theme}>

    <Dialog open={isOpen} onClose={handleCloseModal} maxWidth="md" fullWidth>
      <DialogTitle className="dialogTitle">{title}</DialogTitle>
      <DialogContent>
        <MiniMap
          lat={lat}
          lon={lon}
          tooltipText={tooltipText}
          geofence={geofence}
          point={point}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          style={{ marginLeft: "10px" }}
          onClick={() => handleCloseModal()}
        >
          {t("Cerrar")}
        </Button>
      </DialogActions>
    </Dialog>

    // </ThemeProvider>
  );
};

export default ModalMiniMap;
