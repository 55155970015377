import { useState } from "react";

export const useFilterValidation = (handle) => {
  const [errors, setErrors] = useState({});

  const handleSearchReport = (filters) => {
    if (filters && validate(filters.validations)) {
      if (handle) handle(filters);
      else console.log("Handle can not be undefined");
    }
  };

  const validate = (fieldsToValidate) => {
    let newErrors = {};
    let errors = false;
    fieldsToValidate.map((f) => {
      if (!f.field || f.field.length <= 0) {
        newErrors = { ...newErrors, [f.fieldName]: f.errorMessage };
        errors = true;
      }
    });
    if (errors) {
      setErrors(newErrors);
      return false;
    }
    setErrors(newErrors);
    return true;
  };

  return {
    handleSearchReport,
    errors,
  };
};
