import React from "react";

import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import ChangePasswordForm from "../ChangePasswordModal/Form";
import { useTranslation } from "../../lang";

const theme = createMuiTheme({});

const ChangePasswordModal = ({ isOpen, closeModal }) => {
  const [t] = useTranslation();

  return (
    <>
      <ThemeProvider theme={theme}>
        <Dialog open={isOpen} onClose={closeModal} maxWidth="sm" fullWidth>
          <DialogTitle className="dialogTitle">
            {t("Cambiar Contraseña")}
          </DialogTitle>
          <DialogContent>
            <ChangePasswordForm closeModal={closeModal} />
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

export default ChangePasswordModal;
