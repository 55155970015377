import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { useQuery, useLazyQuery } from "@apollo/client";
import { downloadExcel, downloadPDF } from "../../../utils/generalUtils";

import Structure from "../../../components/Base/Structure";

import Filter from "./Filter";
import ReportResult from "./ReportResult";

import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import PageTitle from "../../../components/Base/PageTitle";

import ASSETS_BYGROUPS_QUERY from "../../../graphql/queries/assetByGroupsQuery";
import GET_ENUMS_QUERY from "../../../graphql/queries/getEnumsQuery";
import OBD2_REPORT_QUERY from "../../../graphql/queries/getOBD2ReportQuery";
import OBD2_REPORT_EXPORT_QUERY from "../../../graphql/queries/getOBD2ReportExportQuery";

import { useTranslation } from "../../../lang";
import { useFilterValidation } from "../useFilterValidation";

import { localDateTimeStringToUTC } from "../../../utils/dates";

const OBD2Report = ({ selectedGroups, user }) => {
  const [t] = useTranslation();
  const [assetList, setAssetList] = useState([]);
  const [variablesOBD2, setVariablesOBD2] = useState([]);
  const [exportSelected, setExportSelected] = useState("EXCEL");
  const [assetTableState] = useState({
    searchText: "",
    sort: undefined,
    paginate: false,
  });

  const [variablesSelected, setVariablesSelected] = useState();
  const [assetsSelected, setAssetsSelected] = useState();

  const [reportResult, setReportResult] = useState([]);

  const [resultState, setResultState] = useState({
    isOpen: false,
    type: "success",
    msg: "",
  });

  const translateVariablesOBD2 = (_variablesOBD2) => {
    const translatedVariablesOBD2 = _variablesOBD2.map((variable) => ({
      ...variable,
      label: t(variable.description),
    }));

    return translatedVariablesOBD2;
  };

  useEffect(() => {
    const translatedVariablesOBD2 = translateVariablesOBD2(variablesOBD2);
    setVariablesOBD2(translatedVariablesOBD2);
  }, [user.language]);

  const {} = useQuery(ASSETS_BYGROUPS_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      groupIDs: selectedGroups.filter(
        (g) => g && !Number.isNaN(Number.parseInt(g))
      ),
      ...assetTableState,
    },
    onCompleted(d) {
      const { count, items } = d.assetsByGroups;
      //setTotal(count);
      setAssetList(
        items.map((a) => ({
          ...a,
          completeName: `${a.name} - ${a.licensePlate}`,
        }))
      );
    },
  });

  const {} = useQuery(GET_ENUMS_QUERY, {
    variables: {
      name: "VariableOBD2Enum",
    },
    onCompleted(d) {
      setVariablesOBD2(
        d.__type.enumValues.map((ev) => ({
          label: t(ev.description),
          value: ev.value,
          description: ev.description,
        }))
      );
    },
  });

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setResultState({
      ...resultState,
      isOpen: false,
    });
  };

  const [fetchReport] = useLazyQuery(OBD2_REPORT_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      if (d.obd2Report) setReportResult(d.obd2Report);
      else {
        setReportResult([]);
        setResultState({
          isOpen: true,
          type: "info",
          msg: t("No se encontraron datos para su consulta"),
        });
      }
    },
  });

  const [getExport] = useLazyQuery(OBD2_REPORT_EXPORT_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      const filename = t("ReporteOBD2");
      switch (exportSelected) {
        case "EXCEL":
          downloadExcel(d.obd2ReportExport, filename);
          break;
        case "PDF":
          downloadPDF(d.obd2ReportExport, filename);
          break;
      }
    },
  });

  const handleSearchReportOBD2 = ({
    assetsSelected,
    variablesOBD2,
    fechaInicio,
    fechaFin,
    days,
  }) => {
    if (assetsSelected) {
      setAssetsSelected(assetsSelected);
      setVariablesSelected(variablesOBD2);
      fetchReport({
        variables: {
          assetsSelected: assetsSelected.map((v) => v.value),
          fechaInicio: localDateTimeStringToUTC(fechaInicio),
          fechaFin: localDateTimeStringToUTC(fechaFin),
          days,
          variablesOBD2: variablesOBD2 && variablesOBD2.map((v) => v.value),
        },
      });
    }
  };

  const { handleSearchReport, errors: filterErrors } = useFilterValidation(
    handleSearchReportOBD2
  );
  const handleExport = (
    { assetsSelected, variablesOBD2, fechaInicio, fechaFin, days, type } // EXCEL, PDF
  ) => {
    if (assetsSelected) {
      setExportSelected(type);
      setAssetsSelected(assetsSelected);
      setVariablesSelected(variablesOBD2);
      getExport({
        variables: {
          assetsSelected: assetsSelected.map((v) => v.value),
          fechaInicio: localDateTimeStringToUTC(fechaInicio),
          fechaFin: localDateTimeStringToUTC(fechaFin),
          days,
          variablesOBD2: variablesOBD2 && variablesOBD2.map((v) => v.value),
          format: type,
        },
      });
    }
  };

  const getVariableFieldName = (value) => {
    switch (value) {
      case "Odometro":
        return "odo";
      case "NumberOfDTC":
        return "nDTC";
      case "CalculatedEngineLoadValue":
        return "cELV";
      case "EngineCoolantTemperature":
        return "eCT";
      case "FuelPressure":
        return "fP";
      case "FuelTrim":
        return "fT";
      case "IntakeManifoldAbsolutePressure":
        return "iMAP";
      case "EngineRPM":
        return "rpm";
      case "VehicleSpeed":
        return "speed";
      case "TimingAdvance":
        return "tA";
      case "IntakeAirTemperature":
        return "iAT";
      case "ThrottlePosition":
        return "tP";
      case "RunTimeSinceEngineStart":
        return "tIgn";
      case "DistanceTraveledMILon":
        return "dTMIL";
      case "Panico":
        return "panic";
      case "Ignicion":
        return "ignition";
      case "Movimiento":
        return "movement";
      case "BateriaInterna":
        return "bI";
      case "Alimentacion":
        return "aC";
      case "SenalGSM":
        return "gsm";
      case "FechaGPS":
        return "Time";
    }
  };

  return (
    <Structure>
      <Snackbar
        open={resultState.isOpen}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleCloseNotification}
          severity={resultState.type}
          variant="filled"
        >
          {resultState.msg}
        </MuiAlert>
      </Snackbar>
      <PageTitle sector={t("Estadísticas")} title={t("Reporte OBD2")} />
      <Filter
        assets={assetList}
        variablesOBD2={variablesOBD2}
        searchReport={handleSearchReport}
        handleExport={handleExport}
        errors={filterErrors}
        reportResult={Array.isArray(reportResult) && reportResult.length > 0}
      />
      {Array.isArray(reportResult) && reportResult.length > 0 && (
        <ReportResult
          reportData={reportResult}
          assetList={assetsSelected}
          variableList={variablesSelected}
          getVariableFieldName={getVariableFieldName}
          user={user}
        />
      )}
    </Structure>
  );
};

const _mapStateToProps = ({ groups, user }) => {
  return {
    selectedGroups: groups.selectedGroups,
    user,
  };
};

export default connect(_mapStateToProps, null)(OBD2Report);
