import gql from "graphql-tag";

const UPDATE_TECHNICIAN_MUTATION = gql`
  mutation UpdateTechnician(
    $id: Int!
    $code: String
    $firstname: String!
    $lastname: String!
    $email: String
    $phoneNumber: String!
    $mobilePhone: String!
    $customerId: Int
  ) {
    updateTechnician(
      id: $id
      code: $code
      firstname: $firstname
      lastname: $lastname
      email: $email
      phoneNumber: $phoneNumber
      mobilePhone: $mobilePhone
      customerId: $customerId
    ) {
      id
    }
  }
`;

export default UPDATE_TECHNICIAN_MUTATION;
