import gql from "graphql-tag";

const UPDATE_POI_MUTATION = gql`
  mutation UpdatePoi(
    $id: Int!
    $customerId: Int
    $name: String
    $latitude: Float
    $longitude: Float
    $radio: Float
    $poiCategoryId: Int
    $expirationDate: Datetime
    $iconId: Int
  ) {
    updatePoi(
      id: $id
      customerId: $customerId
      name: $name
      latitude: $latitude
      longitude: $longitude
      radio: $radio
      poiCategoryId: $poiCategoryId
      expirationDate: $expirationDate
      iconId: $iconId
    ) {
      id
    }
  }
`;

export default UPDATE_POI_MUTATION;
