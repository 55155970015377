import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";
import PermIdentityOutlinedIcon from "@material-ui/icons/PermIdentityOutlined";

import Dropdown from "../Base/Dropdown";
import FlexRow from "../Base/FlexRow";

import "./UsersButton.scss";
import ChangeProfileInformationModal from "../ChangeProfileInformationModal";
import ChangePasswordModal from "../ChangePasswordModal";
import SelecteLanguageModal from "../SelectLanguageModal";
import { useTranslation } from "../../lang";
import { connect } from "react-redux";

const UsersButton = ({
  handleLogoutSubmit,
  loggedIn,
  username,
  //profilePicture,
  user,
  email,
}) => {
  const [t] = useTranslation();
  const [
    isChangeProfileInformationOpen,
    setIsChangeProfileInformationOpen,
  ] = useState(false);
  const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false);
  const [isSelecteLanguageOpen, setIsSelecteLanguageOpen] = useState(false);
  const closeModalChangeProfileInformation = () =>
    setIsChangeProfileInformationOpen(false);
  const closeModalChangePassword = () => setIsChangePasswordOpen(false);
  const closeModalSelecteLanguage = () => setIsSelecteLanguageOpen(false);

  const profilePicture = user?.photo;

  let header = null;
  let items = [
    {
      item: (
        <FlexRow bottomDivider expansion="full" _classNames="pad-4">
          {t("Seleccionar idioma")}
        </FlexRow>
      ),
    },
  ];

  if (loggedIn) {
    let profilePictureComponent = null;

    if (profilePicture) {
      profilePictureComponent = (
        <img src={profilePicture} alt={t("User profile avatar")} />
      );
    }

    header = (
      <FlexRow
        bottomDivider
        expansion="full"
        _classNames="users-button__content text-color--white pad-4"
      >
        <div className="users-button__avatar">{profilePictureComponent}</div>
        <Typography
          component="div"
          className="users-button__info expandable-panel__title-text"
        >
          <FlexRow direction="column">
            <div>{user?.userName}</div>
            <div>{user?.customer?.name}</div>
          </FlexRow>
        </Typography>
      </FlexRow>
    );

    items = [
      {
        item: (
          <FlexRow bottomDivider expansion="full" _classNames="pad-4">
            {t("Cambiar informacion de perfil")}
          </FlexRow>
        ),
        handleOnClick: () => setIsChangeProfileInformationOpen(true),
      },
      {
        item: (
          <FlexRow bottomDivider expansion="full" _classNames="pad-4">
            {t("Cambiar Contraseña")}
          </FlexRow>
        ),
        handleOnClick: () => setIsChangePasswordOpen(true),
      },
      {
        item: (
          <>
            <FlexRow bottomDivider expansion="full" _classNames="pad-4">
              {t("Seleccionar idioma")}
            </FlexRow>
          </>
        ),
        handleOnClick: () => setIsSelecteLanguageOpen(true),
      },
      {
        item: (
          <FlexRow bottomDivider expansion="full" _classNames="pad-4">
            {t("Cerrar sesión")}
          </FlexRow>
        ),
        handleOnClick: handleLogoutSubmit,
      },
    ];
  }

  return (
    <>
      <Dropdown
        title={t("Usuario")}
        icon={PermIdentityOutlinedIcon}
        header={header}
        items={items}
      />
      <ChangeProfileInformationModal
        isOpen={isChangeProfileInformationOpen}
        closeModal={closeModalChangeProfileInformation}
        handleConfirm={() => {
          alert("ok");
          //deleteAsset(idToDelete);
        }}
      />
      <ChangePasswordModal
        isOpen={isChangePasswordOpen}
        closeModal={closeModalChangePassword}
        handleConfirm={() => {
          alert("ok");
          //deleteAsset(idToDelete);
        }}
      />
      <SelecteLanguageModal
        isOpen={isSelecteLanguageOpen}
        closeModal={closeModalSelecteLanguage}
        handleConfirm={() => {
          alert("ok");
          //deleteAsset(idToDelete);
        }}
      />
    </>
  );
};

UsersButton.propTypes = {
  handleLogoutSubmit: PropTypes.func,
  loggedIn: PropTypes.bool,
  username: PropTypes.string,
  profilePicture: PropTypes.string,
  email: PropTypes.string,
  user: PropTypes.object,
};

UsersButton.defaultProps = {
  handleLogoutSubmit: () => {},
  loggedIn: false,
  username: "",
  profilePicture: "",
  email: "",
  user: null,
};

const _mapStateToProps = ({ user }) => {
  return {
    user,
  };
};

export default connect(_mapStateToProps, null)(UsersButton);
