import {
  UPDATE_GROUPS,
  SET_SELECTED_GROUPS,
  SET_SELECTED_CUSTOMER,
} from "./actions";

const initialInfo = {
  groups: [],
  selectedGroups: [],
  selectedCustomers: [],
};

const stateGroup = (state = initialInfo, { type, payload }) => {
  let newState = state;
  switch (type) {
    case UPDATE_GROUPS:
      newState = {
        ...state,
        groups: payload,
      };
      break;
    case SET_SELECTED_GROUPS:
      newState = {
        ...state,
        selectedGroups: payload,
      };
      break;
    case SET_SELECTED_CUSTOMER:
      newState = {
        ...state,
        selectedCustomers: payload,
      };
      break;
  }

  return newState;
};

export default stateGroup;
