import { connect } from "react-redux";

import NavitationPopup from "../components/Map/NavigationPopup";

import { toggleFillMarkerInfo } from "../redux/fillMarkerInfo/actions";
import { openPanel, changePanelType } from "../redux/lateralPanel/actions";
import {
  addAssetToMultipleTracking,
  removeAssetFromMultipleTracking,
} from "../redux/base/action";

// const _mapStateToProps = ({ variant }, { markerInfo }) => {
const _mapStateToProps = ({ base }, { markerInfo, variant, setTrace }) => {
  return {
    variant: variant,
    markerInfo: markerInfo,
    assetsMultipleTracking: base.assetsMultipleTracking,
    // setTrace: setTrace,
  };
};

const _mapDispatchToProps = {
  toggleFillMarkerInfo,
  openPanel,
  changePanelType,
  addAssetToMultipleTracking,
  removeAssetFromMultipleTracking,
};

export default connect(_mapStateToProps, _mapDispatchToProps)(NavitationPopup);
