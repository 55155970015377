import gql from "graphql-tag";

const GET_GENERAL_INFO_EXTRA_QUERY = gql`
  query Points($equipoID: Int!) {
    points(equipoID: $equipoID) {
      nCh
    }
  }
`;

export default GET_GENERAL_INFO_EXTRA_QUERY;
