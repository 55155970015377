export const ADD_SEARCH_OPTIONS = "ADD_SEARCH_OPTIONS";
export const ADD_GEOFENCE_TO_MAP = "ADD_GEOFENCE_TO_MAP";
export const REMOVE_GEOFENCE_FROM_MAP = "REMOVE_GEOFENCE_FROM_MAP";
export const ADD_ASSET_TO_MULTIPLE_TRACKING = "ADD_ASSET_TO_MULTIPLE_TRACKING";
export const REMOVE_ASSET_FROM_MULTIPLE_TRACKING =
  "REMOVE_ASSET_FROM_MULTIPLE_TRACKING";

export const addSearchOptions = (options) => {
  return {
    type: ADD_SEARCH_OPTIONS,
    payload: options,
  };
};

export const addGeofenceToMapFromSearchOptions = (geofence) => ({
  type: ADD_GEOFENCE_TO_MAP,
  payload: geofence,
});

export const removeGeofenceFromMap = (geofence) => ({
  type: REMOVE_GEOFENCE_FROM_MAP,
  payload: geofence,
});

export const addAssetToMultipleTracking = (asset) => ({
  type: ADD_ASSET_TO_MULTIPLE_TRACKING,
  payload: asset,
});

export const removeAssetFromMultipleTracking = (asset) => ({
  type: REMOVE_ASSET_FROM_MULTIPLE_TRACKING,
  payload: asset,
});
