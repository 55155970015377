import { useMutation } from "@apollo/client";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import AUTO_LOGIN_MUTATION from "../graphql/mutations/autologinMutation";
import { loginSuccess } from "../redux/user/actions";

const AutoLogin = () => {
  const [t] = useTranslation();
  const { token } = useParams();
  const [autologin] = useMutation(AUTO_LOGIN_MUTATION);

  useEffect(async () => {
    const res = await autologin({ variables: { token: token } });
    localStorage.setItem("access_token", res.data.autologin.token);
    localStorage.setItem("showMenu", true);
    const loggedUser = JSON.stringify(res.data.autologin.user);
    localStorage.setItem("loggedUser", loggedUser);

    loginSuccess({
      message: t("Has ingresado satisfactoriamente"),
      user: loggedUser,
      token: token,
      redirectTo: "/map",
    });
    window.location.href = "/map";
  }, []);

  return "";
};

const _mapStateToProps = ({ user }) => ({
  //loggedIn: user.loggedIn,
  user,
});

const _mapDispatchToProps = (dispatch) => {
  return {
    loginSuccess: (payload) => dispatch(loginSuccess(payload)),
  };
};

export default connect(_mapStateToProps, _mapDispatchToProps)(AutoLogin);
