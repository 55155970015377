import gql from "graphql-tag";

const DELETE_GEOFENCE_MUTATION = gql`
  mutation DeleteGeofence($id: Int!) {
    deleteGeofence(id: $id) {
      id
    }
  }
`;

export default DELETE_GEOFENCE_MUTATION;
