import gql from "graphql-tag";

const CURRENT_USER_QUERY = gql`
  query CurrentUser {
    currentUser {
      id
      userName
      firstName
      lastName
      photo
      email
      phone
    }
  }
`;

export default CURRENT_USER_QUERY;
