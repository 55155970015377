import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { parse } from "date-fns";
import ReactDomServer from "react-dom/server";
import L from "leaflet";
import "leaflet-routing-machine";
import isEmpty from "lodash/isEmpty";
import { useMap } from "react-leaflet";
import MarkerIcon from "../../public/icons/MarkerIcon";
import classNames from "classnames";
// import ConnectedNavigationPopup from '../../containers/ConnectedNavigationPopup';
// import NavigationPopup from "./NavigationPopup";
import JourneyPopup from "./JourneyPopup";
import reducer from "../../redux/rootReducer";
// import { Provider } from "react-redux";
// import { BrowserRouter } from "react-router-dom";
import thunk from "redux-thunk";
import { configureInitialState, configureStore } from "../../utils/store";
import "./NavigationRouting.scss";
import { useTranslation } from "../../lang";

const ICON_SIZE = 20;
const ANCHOR_POSITION = 10; // ICON_SIZE / 2
const POPUP_ANCHOR_POSITION = -15; // ICON_SIZE / 2
const IconSettings = {
  iconSize: [ICON_SIZE, ICON_SIZE], // size of the icon
  iconAnchor: [ANCHOR_POSITION, ANCHOR_POSITION], // point of the icon which will correspond to marker's location
  popupAnchor: [0, POPUP_ANCHOR_POSITION], // point from which the popup should open relative to the iconAnchor
};

function drawJourneys(
  journeys,
  map,
  del,
  toggleFillGenericPopup,
  firstDraw,
  avoidNewZoom,
  store,
  t,
  user
) {
  let bounds = [];
  let boundsToPrint = [];
  let boundsAux = [];
  var polyline;
  var polyline2;
  var polyline3;
  //Icon
  const markerIconClassNames = classNames("marker-icon");
  const pointerIconClassNames = classNames("pointer-icon");
  const arrowIconClassNames = classNames("arrow-icon");

  const popupClassNames = classNames(
    "marker-popup--navigation",
    "marker-popup--navigation-open"
  );

  const iconA = L.divIcon({
    className: `${markerIconClassNames}`,
    ...IconSettings,
    html: MarkerIcon("A"),
  });
  const iconB = L.divIcon({
    className: `${markerIconClassNames}`,
    ...IconSettings,
    html: MarkerIcon("B"),
  });
  const iconO = L.divIcon({
    className: `${pointerIconClassNames}`,
    ...IconSettings,
    html: MarkerIcon("O"),
  });
  const iconOWithCurse = (curso) =>
    L.divIcon({
      className: `${arrowIconClassNames}`,
      ...IconSettings,
      html: MarkerIcon("OWC-" + curso),
    });

  const createPopupInfo = (summary, step, listPos, variant) => {
    const ftemp = parse(step.iTime, "dd/MM/yyyy HH:mm:SS", new Date());
    const fini = new Date(
      Date.UTC(
        ftemp.getFullYear(),
        ftemp.getMonth(),
        ftemp.getDate(),
        ftemp.getHours(),
        ftemp.getMinutes(),
        ftemp.getSeconds()
      )
    );
    const ffin = new Date(listPos.fGPS);
    const tign2 = Math.trunc((ffin - fini) / 1000);
    let a = {
      nCh: summary.nCh,
      nMov: summary.nMov,
      vel: listPos.vel,

      tIgn: tign2,
      cur: listPos.cur,
      fGPS: listPos.fGPS,
      dir: listPos.dir,

      pat: summary.pat,
      nInt: summary.nInt,

      lat: listPos.lat,
      lng: listPos.lon,
      variant: variant,
    };
    return a;
  };

  //Limpiamos el mapa para no llenarlo de puntos
  map.eachLayer(function (layer) {
    if (
      typeof layer.options.layerID != "undefined" &&
      layer.options.layerID.indexOf("Layer_Group_Routing") !== -1
    ) {
      map.removeLayer(layer);
    }
  });

  let dayStepG = {};
  if (!isEmpty(journeys)) {
    var someTrue = journeys.daySteps.some((e) => e.visible === true);
    let summary = journeys.summary;
    let printedBlue = false;
    journeys.daySteps.forEach((dayStep, index) => {
      if (dayStep.visible || !someTrue) {
        if (!someTrue && index > 0) {
          return true; //Dibujo el primer dayStep
        }
        dayStepG = dayStep; //DayG se va a usar para obtener los punto
        dayStep.steps.forEach((pos) => {
          let StepsToPrint = [];
          const posLen = pos.listPos.length;
          const showBlue = pos.visible ? true : false;
          let lastCurse = "";
          let lastodommarker = 0;
          pos.listPos.forEach((item, index) => {
            // if (showBlue) {
            printedBlue = true;
            StepsToPrint.push(L.latLng(item.lat, item.lon));

            if (
              index !== 0 &&
              posLen !== index + 1 &&
              (lastCurse !== item.cur || item.odo - lastodommarker >= 0.25)
            ) {
              //Verifico que no sea ni el primer ni el ultimo punto para no dibujarlo
              lastCurse = item.cur;
              lastodommarker = item.odo;
              let itemLat = item.lat;
              let itemLng = item.lon;
              const typ = item.typ;
              L.marker([itemLat, itemLng], {
                icon: iconOWithCurse(item.cur),
                layerID: "Layer_Group_Routing_Curso",
              })
                .addTo(map)
                .bindPopup(
                  ReactDomServer.renderToString(
                    <JourneyPopup
                      variant={typ}
                      markerInfo={createPopupInfo(
                        summary,
                        pos,
                        item,
                        "secundary"
                      )}
                      t={t}
                      user={user}
                    />
                  ),
                  {
                    className: popupClassNames,
                  }
                );
            }
          });

          if (StepsToPrint.length > 0) {
            //Evitamos el problema de seleccionar tres steps, luego quitamos del steps del medio, se dibujaria una linea entre el fin del steps 1 y el inicio del steps 3
            boundsToPrint.push(StepsToPrint);
          }
          //Dibujar los puntos de cada tramo
          let firstItemStepLat = pos.listPos[0].lat;
          let firstItemStepLng = pos.listPos[0].lon;
          let lastItemStepLat = pos.listPos[pos.listPos.length - 1].lat;
          let lastItemStepLng = pos.listPos[pos.listPos.length - 1].lon;

          L.marker([firstItemStepLat, firstItemStepLng], {
            icon: iconO,
            layerID: "Layer_Group_Routing_PuntoO",
          })
            .addTo(map)
            .bindPopup(
              ReactDomServer.renderToString(
                <JourneyPopup
                  variant={pos.listPos[0].typ}
                  markerInfo={createPopupInfo(
                    summary,
                    pos,
                    pos.listPos[0],
                    "secundary"
                  )}
                  t={t}
                  user={user}
                />
              ),
              {
                className: popupClassNames,
              }
            );
          L.marker([lastItemStepLat, lastItemStepLng], {
            icon: iconO,
            layerID: "Layer_Group_Routing_PuntoO",
          })
            .addTo(map)
            .bindPopup(
              ReactDomServer.renderToString(
                <JourneyPopup
                  variant={pos.listPos[pos.listPos.length - 1].typ}
                  markerInfo={createPopupInfo(
                    summary,
                    pos,
                    pos.listPos[pos.listPos.length - 1],
                    "secundary"
                  )}
                  t={t}
                  user={user}
                />
              ),
              {
                className: popupClassNames,
              }
            );
        });
      }
    });

    if (Object.keys(dayStepG).length !== 0) {
      //Dibujar el punto A
      let firstItemLat = dayStepG.steps[0].listPos[0].lat;
      let firstItemLng = dayStepG.steps[0].listPos[0].lon;
      L.marker([firstItemLat, firstItemLng], {
        icon: iconA,
        layerID: "Layer_Group_Routing_PuntoA",
      })
        .addTo(map)
        .bindPopup(
          ReactDomServer.renderToString(
            <JourneyPopup
              variant={dayStepG.steps[0].listPos[0].typ}
              markerInfo={createPopupInfo(
                summary,
                dayStepG.steps[0],
                dayStepG.steps[0].listPos[0],
                "principal"
              )}
              t={t}
              user={user}
            />
          ),
          {
            className: popupClassNames,
          }
        );
      let lastItemLat =
        dayStepG.steps[dayStepG.steps.length - 1].listPos[
          dayStepG.steps[dayStepG.steps.length - 1].listPos.length - 1
        ].lat;
      let lastItemLng =
        dayStepG.steps[dayStepG.steps.length - 1].listPos[
          dayStepG.steps[dayStepG.steps.length - 1].listPos.length - 1
        ].lon;
      L.marker([lastItemLat, lastItemLng], {
        icon: iconB,
        layerID: "Layer_Group_Routing_PuntoB",
      })
        .addTo(map)
        .bindPopup(
          ReactDomServer.renderToString(
            <JourneyPopup
              variant={
                dayStepG.steps[dayStepG.steps.length - 1].listPos[
                  dayStepG.steps[dayStepG.steps.length - 1].listPos.length - 1
                ].typ
              }
              markerInfo={createPopupInfo(
                summary,
                dayStepG.steps[dayStepG.steps.length - 1],
                dayStepG.steps[dayStepG.steps.length - 1].listPos[
                  dayStepG.steps[dayStepG.steps.length - 1].listPos.length - 1
                ],
                "principal"
              )}
              t={t}
              user={user}
            />
          ),
          {
            className: popupClassNames,
          }
        );
    }
  }
  //PARA AGREGAR ROUTING, PREDICCION DE RUTAS
  // let routing = L.Routing.control({
  //   waypoints: bounds,
  //   lineOptions: {
  //     styles: [
  //       {
  //         color: "red",
  //         opacity: 0.6,
  //         weight: 4
  //       }
  //     ]
  //   },
  //   addWaypoints: false,
  //   draggableWaypoints: false,
  //   fitSelectedRoutes: false,
  //   showAlternatives: false
  // }).addTo(map);

  polyline = L.polyline(bounds, {
    layerID: "Layer_Group_Routing_Journeys",
    color: "#9E9E9E", //Gris
  });

  if (boundsToPrint.length > 0) {
    polyline2 = L.polyline(boundsToPrint, {
      layerID: "Layer_Group_Routing_Journeys_2",
      color: "#6E9AFF", //Azul
    });
  }

  if (boundsAux.length > 0) {
    polyline3 = L.polyline(boundsAux, {
      layerID: "Layer_Group_Routing_Journeys_3",
      color: "#9E9E9E", //Gris
    });
  }

  //condicional para un step day
  if (
    Array.isArray(polyline._latlngs) &&
    polyline._latlngs.length > 0 &&
    !del &&
    typeof polyline2 == "undefined" &&
    typeof polyline3 == "undefined"
  ) {
    let layergroup = L.layerGroup().addTo(map);
    layergroup.options.layerID = "Layer_Group_Routing";
    polyline.addTo(layergroup);
    // console.log("Fit bounds 1");
    if (firstDraw) {
      map.fitBounds(polyline.getBounds());
      avoidNewZoom();
    }
    return layergroup;
    //condicional con step day y un step seleccionado
  } else if (
    typeof polyline != "undefined" &&
    typeof polyline2 != "undefined" &&
    Array.isArray(polyline._latlngs) &&
    polyline._latlngs.length > 0 &&
    Array.isArray(polyline2._latlngs) &&
    polyline2._latlngs.length > 0 &&
    !del
  ) {
    let layergroup = L.layerGroup().addTo(map);
    layergroup.options.layerID = "Layer_Group_Routing";
    polyline.addTo(layergroup);
    polyline2.addTo(layergroup);
    if (
      typeof polyline3 != "undefined" &&
      Array.isArray(polyline._latlngs) &&
      polyline._latlngs.length > 0
    ) {
      polyline3.addTo(layergroup);
    }
    map.fitBounds(polyline2.getBounds());
    return layergroup;
    //condicional step day y step inicial seleccionado
  } else if (
    typeof polyline2 != "undefined" &&
    typeof polyline3 != "undefined" &&
    Array.isArray(polyline2._latlngs) &&
    polyline2._latlngs.length > 0 &&
    Array.isArray(polyline3._latlngs) &&
    polyline3._latlngs.length > 0 &&
    !del
  ) {
    let layergroup = L.layerGroup().addTo(map);
    layergroup.options.layerID = "Layer_Group_Routing";
    polyline2.addTo(layergroup);
    polyline3.addTo(layergroup);
    map.fitBounds(polyline2.getBounds());
    return layergroup;
    //condicional step day y solo un step inicial seleccionado
  } else if (
    typeof polyline2 != "undefined" &&
    Array.isArray(polyline2._latlngs) &&
    polyline2._latlngs.length > 0 &&
    !del
  ) {
    let layergroup = L.layerGroup().addTo(map);
    layergroup.options.layerID = "Layer_Group_Routing";
    polyline2.addTo(layergroup);
    map.fitBounds(polyline2.getBounds());
    return layergroup;
    //Se cerró el side bar de recorrido, se borran los journeys
  } else {
    map.eachLayer(function (layer) {
      if (
        typeof layer.options.layerID != "undefined" &&
        layer.options.layerID.indexOf("Layer_Group_Routing") !== -1
      ) {
        map.removeLayer(layer);
      }
    });
    return L.layerGroup();
  }
}

const NavigationRouting = (props) => {
  const map = useMap();
  const [firstDraw, setFirstDraw] = useState(true);
  const avoidNewZoom = () => setFirstDraw(false);
  const [t] = useTranslation();
  let store;

  useEffect(() => {
    const { journeys, toggleFillGenericPopup, showJourney } = props;

    store = configureStore({
      reducer,
      initialState: configureInitialState(props),
      // eslint-disable-next-line comma-dangle
      middleware: [thunk],
    });

    const journeyLayer = drawJourneys(
      journeys,
      map,
      !showJourney,
      toggleFillGenericPopup,
      firstDraw,
      avoidNewZoom,
      store,
      t,
      props.user
    );
    map.addLayer(journeyLayer);

    return () => {
      map.removeLayer(journeyLayer);
    };
  }, [map, props, firstDraw]);

  return null;
};

const _mapStateToProps = ({ user }) => {
  return {
    user,
  };
};

const _mapDispatchToProps = (dispatch) => {
  return {
    // _setCurrentUser: (param) => dispatch(setCurrentUser(param)),
  };
};

export default connect(
  _mapStateToProps,
  _mapDispatchToProps
)(NavigationRouting);
