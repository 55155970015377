import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import WifiIcon from "@material-ui/icons/Wifi";
import classNames from "classnames";
import { format } from "date-fns";
import React from "react";
import { GetDateFormat, secondsToTime } from "../../utils/generalUtils";
import "./NavigationPopup.scss";

const JourneyPopup = ({ markerInfo, t, user }) => {
  const {
    pat,
    nCli,
    nCh,
    nMov,
    vel,
    tIgn,
    cur,
    fGPS,
    // fGPSd,
    dir,
    nInt,
    typ,
  } = markerInfo;

  const popupClassNames = classNames(
    "marker-popup--navigation",
    "marker-popup--navigation-open"
  );
  const iconClassNames = classNames("marker-popup__content-icon", {
    [`marker-popup__content-icon--${typ}`]: typ,
  });

  const cellsClassname =
    "marker-popup__row__item marker-popup__content-text pad-vert-2 pad-hor-4";

  return (
    <div className={popupClassNames}>
      <div className="marker-popup__content">
        <div className="marker-popup__content--extended">
          <div className="marker-popup__row marker-popup__row--dark">
            <div className="marker-popup__content-text marker-popup__content-text__title pad-vert-2 pad-hor-3">
              {nCli}
            </div>
          </div>

          <div className="marker-popup__row">
            <div className={cellsClassname}>
              <AccountCircleIcon className="marker-popup__driver-icon" />
              <div className="marker-popup__content-text__title">{nCh}</div>
            </div>
            <div className={cellsClassname}>
              <div className="marker-popup__content-text__title">{nMov}</div>
            </div>
          </div>

          <div className="marker-popup__row">
            <div className="marker-popup__row__item">
              <div className="generic-popup-veinte marker-popup__row__item marker-popup__content-icon">
                <WifiIcon />
              </div>
              <div className="generic-popup-treinta marker-popup__row__item">
                <div className={cellsClassname}>
                  <div className="marker-popup__content-text__title">
                    {t("Velocidad")}
                  </div>
                  <div className="marker-popup__content-text__info">
                    {Math.round(vel)}
                  </div>
                </div>
              </div>
              <div className="generic-popup-treinta marker-popup__row__item">
                {/* <Tooltip target={ id } title={ tIgn } arrow placement="right"> */}
                <div className={cellsClassname}>
                  <div className="marker-popup__content-text__title">
                    {t("Tiempo")}
                  </div>
                  <div className="marker-popup__content-text__info">
                    {secondsToTime(tIgn)}
                  </div>
                </div>
              </div>
              <div className="generic-popup-veinte marker-popup__row__item">
                <div className={cellsClassname}>
                  <div className="marker-popup__content-text__title">
                    {t("Rumbo")}
                  </div>
                  <div className="marker-popup__content-text__info">
                    {t(cur)}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="marker-popup__row">
            <div className="navigation-popup-dir marker-popup__row__item pad-vert-2 pad-hor-2">
              <div className="marker-popup__content-text__info">
                {t("Fecha_GPS")}:
                {format(new Date(fGPS), GetDateFormat(user.language))}
              </div>
              <div className="marker-popup__content-text__info">{dir}</div>
            </div>
          </div>
        </div>
        <div className="marker-popup__row marker-popup__row--dark">
          <div className="marker-popup__row__item">
            <div className={iconClassNames}>
              <LocalShippingIcon />
            </div>
            {/* <Tooltip target={ id } title={ pat } arrow placement="right"> */}
            <div className="marker-popup__row__item marker-popup__content-text pad-vert-2 pad-hor-2">
              {pat.length > 10 ? pat.substr(0, 10) + "..." : pat}
            </div>
            {/* </Tooltip> */}
          </div>
          <div
            className="marker-popup__row__item"
            style={{ justifyContent: "center !important" }}
          >
            <div className="marker-popup__content-text pad-vert-2 pad-hor-2">
              {nInt}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JourneyPopup;
