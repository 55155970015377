import gql from "graphql-tag";

const UPDATE_CONFNOTIFICATION_MUTATION = gql`
  mutation UpdateConfNotification(
    $id: Int
    $name: String!
    $customerId: Int!
    $enabled: Boolean
    $mailList: String
    $mailListCco: String
    $assetsId: [Int]
    $usersDestinationsId: [Int]
    $typeEventsId: [Int]
    $speeding: Boolean
    $geofenceEvents: Boolean
    $geofenceSpeeding: Boolean
    $geofencesId: [Int]
    $withoutTransmision: Boolean
    $roadEvents: Boolean
    $roadsId: [Int]
  ) {
    updateConfigurationNotification(
      id: $id
      name: $name
      customerId: $customerId
      enabled: $enabled
      mailList: $mailList
      mailListCco: $mailListCco
      assetsId: $assetsId
      usersDestinationsId: $usersDestinationsId
      typeEventsId: $typeEventsId
      speeding: $speeding
      geofenceEvents: $geofenceEvents
      geofenceSpeeding: $geofenceSpeeding
      geofencesId: $geofencesId
      withoutTransmision: $withoutTransmision
      roadEvents: $roadEvents
      roadsId: $roadsId
    )
  }
`;

export default UPDATE_CONFNOTIFICATION_MUTATION;
