import gql from "graphql-tag";

const UPDATE_GEOFENCES_ASSET_MUTATION = gql`
  mutation UpdateGeofencesAsset(
    $assetId: Int!
    $operationNumber: String
    $daysStopMaximum: Int
    $averageDailyKm: Float
    $averageDailyHorometer: Int
    $internalCustomer: String
    $geofencesId: [Int]
  ) {
    updateGeofencesAsset(
      assetId: $assetId
      operationNumber: $operationNumber
      daysStopMaximum: $daysStopMaximum
      averageDailyKm: $averageDailyKm
      averageDailyHorometer: $averageDailyHorometer
      internalCustomer: $internalCustomer
      geofencesId: $geofencesId
    )
  }
`;

export default UPDATE_GEOFENCES_ASSET_MUTATION;
