/* eslint-disable max-len */
const MarkerIcon = (param) => {
  switch (param) {
    case "A":
      return MarkerA();
    case "B":
      return MarkerB();
    case "O":
      return MarkerAlone();
    case "OWC-N":
      return MarkerWithParam("");
    case "OWC-NO":
      return MarkerWithParam('transform="translate(0 8.485) rotate(-45)"');
    case "OWC-E":
      return MarkerWithParam('transform="translate(18) rotate(90)"');
    case "OWC-O":
      return MarkerWithParam('transform="translate(0 12) rotate(-90)"');
    case "OWC-S":
      return MarkerWithParam('transform="translate(12 18) rotate(180)"');
    case "OWC-SE":
      return MarkerWithParam(
        'transform="matrix(-0.819, 0.574, -0.574, -0.819, 20.154, 14.745)"'
      );
    case "OWC-SO":
      return MarkerWithParam(
        'transform="matrix(-0.819, -0.574, 0.574, -0.819, 9.83, 21.628)"'
      );
    case "OWC-NE":
      return MarkerWithParam('transform="translate(12.728) rotate(45)"');
    default:
      return null;
  }
};

const MarkerA = () => `
  <svg width="27" height="48.832" viewBox="0 0 27 48.832">
    <defs>      
      <clipPath id="clip-path-2">
        <rect width="27" height="36" fill="none"/>
      </clipPath>
    </defs>
    <g id="Iconografía-31" clip-path="url(#clip-path-2)">
      <path id="Trazado_420" data-name="Trazado 420" d="M104.89,27.46a13.5,13.5,0,0,0-13.5,13.5c0,5.443,1.9,6.961,12.113,21.773a1.687,1.687,0,0,0,2.774,0C116.493,47.924,118.39,46.4,118.39,40.96A13.5,13.5,0,0,0,104.89,27.46Z" transform="translate(-91.39 -27.46)" fill="#0042a9"/>
      <path id="Trazado_421" data-name="Trazado 421" d="M134.64,90.534c-9.794-14.161-11.25-15.286-11.25-19.824a11.25,11.25,0,0,1,22.5,0C145.89,75.248,144.435,76.368,134.64,90.534Z" transform="translate(-121.14 -57.21)" fill="#fff"/>
      <path id="Trazado_422" data-name="Trazado 422" d="M196.228,125.338h-5.237L190,128.326H186.82l5.4-14.5h2.768l5.426,14.5h-3.176Zm-4.43-2.419h3.624l-1.822-5.426Z" transform="translate(-180.11 -107.757)" fill="#0042a9"/>
    </g>
  </svg>
  `;
const MarkerB = () => `
  <svg width="26.25" height="47.832" viewBox="0 0 26.25 47.832">
    <defs>      
      <clipPath id="clip-path-2">
        <rect width="26.25" height="35" fill="none"/>
      </clipPath>
    </defs>    
    <g id="Iconografía-32" clip-path="url(#clip-path-2)">
      <path id="Trazado_423" data-name="Trazado 423" d="M104.585,27.46A13.128,13.128,0,0,0,91.46,40.585c0,5.292,1.846,6.768,11.776,21.169a1.641,1.641,0,0,0,2.7,0c9.933-14.4,11.776-15.876,11.776-21.169A13.125,13.125,0,0,0,104.585,27.46Z" transform="translate(-91.46 -27.46)" fill="#0042a9"/>
      <path id="Trazado_424" data-name="Trazado 424" d="M134.4,89.67C124.876,75.9,123.46,74.809,123.46,70.4a10.937,10.937,0,0,1,21.875,0C145.335,74.809,143.92,75.9,134.4,89.67Z" transform="translate(-121.273 -57.273)" fill="#fff"/>
      <path id="Trazado_425" data-name="Trazado 425" d="M214.79,127.9V113.81h4.936a6.482,6.482,0,0,1,3.891.982,3.359,3.359,0,0,1,1.326,2.88,3.185,3.185,0,0,1-.531,1.825,3.076,3.076,0,0,1-1.481,1.157,2.993,2.993,0,0,1,1.709,1.094,3.22,3.22,0,0,1,.623,2.014,3.711,3.711,0,0,1-1.3,3.076,5.871,5.871,0,0,1-3.7,1.068Zm2.9-8.189h2.149q2.2-.038,2.2-1.752a1.622,1.622,0,0,0-.556-1.379,2.921,2.921,0,0,0-1.758-.422h-2.032v3.555Zm0,2.051v3.8h2.488a2.4,2.4,0,0,0,1.6-.489,1.67,1.67,0,0,0,.576-1.347,1.77,1.77,0,0,0-2-1.965h-2.662Z" transform="translate(-206.359 -107.907)" fill="#0042a9"/>
    </g>
  </svg>
  `;
const MarkerAlone = () => `
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25" height="25" viewBox="0 0 25 25">
    <defs>
      <filter id="Punto" x="0" y="0" width="25" height="25" filterUnits="userSpaceOnUse">
        <feOffset input="SourceAlpha"/>
        <feGaussianBlur stdDeviation="1.5" result="blur"/>
        <feFlood flood-opacity="0.612"/>
        <feComposite operator="in" in2="blur"/>
        <feComposite in="SourceGraphic"/>
      </filter>
    </defs>
    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Punto)">
      <g id="Punto-2" data-name="Punto" transform="translate(4.5 4.5)" fill="#2b6cdb" stroke="#fff" stroke-width="3">
        <circle cx="8" cy="8" r="8" stroke="none"/>
        <circle cx="8" cy="8" r="6.5" fill="none"/>
      </g>
    </g>
  </svg>
  `;
const MarkerWithParam = (param) => `
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="21.213" height="21.213" viewBox="0 0 21.213 21.213">
  <defs>
    <clipPath id="clip-path">
      <rect width="12" height="18" fill="none"/>
    </clipPath>
  </defs>
  <g ${param}>
    <rect id="Rectángulo_994" data-name="Rectángulo 994" width="12" height="18" rx="3" fill="#2b6cdb"/>
    <path id="Polígono_3" data-name="Polígono 3" d="M3.6,0,7.2,10.8H0Z" transform="translate(2.4 3)" fill="#fff"/>
  </g>
</svg>
  `;
export default MarkerIcon;
