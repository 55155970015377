import gql from "graphql-tag";

export const KMLTypes = {
  DPWORLD: "DPWORLD",
};

const IMPORT_KML_FILE_GEOFENCE_MUTATION = gql`
  mutation ImportKMLFileGeofence(
    $kml: Upload
    $type: KMLType
    $customerId: Int!
  ) {
    importKMLFileGeofence(kml: $kml, type: $type, customerId: $customerId)
  }
`;

export default IMPORT_KML_FILE_GEOFENCE_MUTATION;
