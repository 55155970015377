import gql from "graphql-tag";

const TYPE_EVENTALL_QUERY = gql`
  query TypeEventAll {
    typeEventAll {
      value: id
      label: description
    }
  }
`;

export default TYPE_EVENTALL_QUERY;
