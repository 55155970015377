import React, { useState, useEffect } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";

import {
  Button,
  FormGroup,
  Grid,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import * as Yup from "yup";
import { Field, Formik } from "formik";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import CustomFormikSelect from "../../../components/Base/CustomFormikSelect";
import GenericSelector from "../../../components/Selectors/GenericSelector";

import GET_ROAD_BY_ID_QUERY from "../../../graphql/queries/getRoadById";
import CUSTOMERS_QUERY from "../../../graphql/queries/getCustomersQuery";
import GET_ROAD_STOPS_BY_ROAD_ID from "../../../graphql/queries/getRoadStopsQuery";
import GeofenceMap from "./GeofenceMap";

import { useTranslation } from "../../../lang";
import { decode } from "@googlemaps/polyline-codec";

const theme = createMuiTheme({});

const Form = ({
  closeModal,
  selectedRoad,
  createRoad,
  updateRoad,
  assetList,
  geofenceList,
}) => {
  const [t] = useTranslation();
  const [userLogged, setUserLogged] = useState();
  const [isOwner, setIsOwner] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [roadStops, setRoadStops] = useState([]);
  const [_selectedRoad, set_selectedRoad] = useState();

  // selectedRoad is a summary object with partial data.
  // It does not contain the needed data of related entities nor it contains
  // the geospatial fields needed to draw on the map.
  // fetch_selectedRoad request the entire road data.
  const [fetch_selectedRoad] = useLazyQuery(GET_ROAD_BY_ID_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      const road = d.getRoad;
      const decodedPolyline = road.overviewPolyline
        ? decode(road.overviewPolyline)
        : [];

      const bufferedDecodedOverviewPolyline = road.bufferedDecodedOverviewPolyline
        ? JSON.parse(road.bufferedDecodedOverviewPolyline)
        : [];

      const fullRoad = {
        ...road,
        decodedPolyline,
        bufferedDecodedOverviewPolyline,
      };
      set_selectedRoad(fullRoad || {});
    },
  });

  useEffect(() => {
    if (selectedRoad) {
      fetch_selectedRoad({
        variables: {
          id: selectedRoad.id,
        },
      });
    }
  }, selectedRoad);

  useEffect(() => {
    const userStr = localStorage.getItem("loggedUser");
    if (userStr) {
      const user = JSON.parse(userStr);
      setUserLogged(user);
      setIsOwner(user.roles.some((r) => r.isOwner));
    }
  }, []);

  useQuery(CUSTOMERS_QUERY, {
    onCompleted(d) {
      setCustomers(
        d.customers.items.map((c) => ({
          label: c.name,
          value: c.id,
        }))
      );
    },
  });

  const [getRoadStopsByRoadId] = useLazyQuery(GET_ROAD_STOPS_BY_ROAD_ID, {
    fetchPolicy: "no-cache",
    onCompleted(d) {
      setRoadStops(d.getRoadStopsByRoadId);
    },
  });

  useEffect(() => {
    if (selectedRoad) {
      getRoadStopsByRoadId({
        variables: {
          roadId: selectedRoad.id,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (selectedRoad) {
      getRoadStopsByRoadId({
        variables: {
          roadId: selectedRoad.id,
        },
      });
    }
  }, [selectedRoad]);

  const initialValues = {
    id: _selectedRoad?.id,
    name: _selectedRoad?.name,
    description: _selectedRoad?.description,
    hasDetourAlarm: _selectedRoad?.hasDetourAlarm ? true : false,
    detourMeters: _selectedRoad?.detourMeters,
    maxVelocity: _selectedRoad?.maxVelocity,
    hasVelocityAlarm: _selectedRoad?.hasVelocityAlarm ? true : false,
    customerId: _selectedRoad?.customerId,
    assets: _selectedRoad?.assets?.map((a) => a.id),
    geofences: _selectedRoad?.roadGeofence?.map((rg) => rg.geofence.id),
  };

  const yupSchema = Yup.object().shape({
    name: Yup.string().required(t("Requerido")),
    customerId: Yup.number().required(t("Requerido")),
  });

  const columnsAssets = [
    {
      dataField: "name",
      text: t("Nombre"),
      sort: true,
    },
    {
      dataField: "licensePlate",
      text: t("Patente"),
      sort: true,
    },
    {
      dataField: "internalNumber",
      text: t("Interno"),
      sort: true,
    },
    {
      dataField: "driver.firstName",
      text: t("Chofer"),
      sort: true,
    },
    {
      dataField: "typeMobile.description",
      text: t("Tipo"),
      sort: true,
    },
  ];

  const columnsGeofence = [
    {
      dataField: "name",
      text: t("Nombre"),
      sort: true,
    },
    {
      dataField: "customerName",
      text: t("Cliente"),
      sort: true,
    },
    {
      dataField: "showOnMap",
      text: t("Mostrar en mapa"),
      formatter: (cell, row) => {
        return row.showOnMap ? t("Si") : t("No");
      },
    },
    {
      dataField: "maxSpeed",
      text: t("Velocidad Máxima"),
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={yupSchema}
        onSubmit={async (values) => {
          // console.log("Values: ", values);
          const _values = {
            ...values,
            detourMeters: values.hasDetourAlarm ? values.detourMeters : 0,
            maxVelocity: values.hasVelocityAlarm ? values.maxVelocity : 0,
          };
          if (selectedRoad) {
            updateRoad(_values);
          } else {
            createRoad(_values);
          }
        }}
      >
        {(props) => {
          const { values, errors, handleSubmit, setFieldValue } = props;
          return (
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <label className="formLabelFormik">{t("Cliente")}</label>
                {isOwner ? (
                  <Field
                    name="customerId"
                    component={CustomFormikSelect}
                    options={customers}
                  />
                ) : (
                  <label>{userLogged?.customer?.name}</label>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">{t("Nombre")}</label>
                <Field
                  type="text"
                  name="name"
                  tabIndex="0"
                  className={
                    errors.name
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                />
                {errors.name && (
                  <div className="input-feedback">{errors.name}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">{t("Descripción")}</label>
                <Field
                  type="text"
                  name="description"
                  tabIndex="0"
                  className={
                    errors.description
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                />
                {errors.description && (
                  <div className="input-feedback">{errors.description}</div>
                )}
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={values.hasDetourAlarm}
                      onChange={(evt) =>
                        setFieldValue("hasDetourAlarm", evt.target.checked)
                      }
                      name="hasDetourAlarm"
                    />
                  }
                  label={t("Activar Alarma de desvio")}
                />
              </FormGroup>
              {values.hasDetourAlarm && (
                <FormGroup>
                  <label className="formLabelFormik">{t("Desvío")}</label>
                  <Field
                    type="number"
                    name="detourMeters"
                    tabIndex="0"
                    className={
                      errors.detourMeters
                        ? "form-control text-input error"
                        : "form-control text-input"
                    }
                  />
                  {errors.detourMeters && (
                    <div className="input-feedback">{errors.detourMeters}</div>
                  )}
                </FormGroup>
              )}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={values.hasVelocityAlarm}
                      onChange={(evt) =>
                        setFieldValue("hasVelocityAlarm", evt.target.checked)
                      }
                      name="hasVelocityAlarm"
                    />
                  }
                  label={t("Activar Alarma de velocidad")}
                />
              </FormGroup>
              {values.hasVelocityAlarm && (
                <FormGroup>
                  <label className="formLabelFormik">
                    {t("Velocidad Máxima")}
                  </label>
                  <Field
                    type="number"
                    name="maxVelocity"
                    tabIndex="0"
                    className={
                      errors.maxVelocity
                        ? "form-control text-input error"
                        : "form-control text-input"
                    }
                  />
                  {errors.maxVelocity && (
                    <div className="input-feedback">{errors.maxVelocity}</div>
                  )}
                </FormGroup>
              )}
              <GenericSelector
                callbackOptionSelected={(assets) => {
                  setFieldValue(
                    "assets",
                    Array.isArray(assets) && assets.length > 0
                      ? assets.map((a) => a.value)
                      : null
                  );
                }}
                columns={columnsAssets}
                keyField="id"
                labelField="licensePlate"
                title={t("Móviles")}
                data={assetList}
                isMulti
                defaultValue={values.assets}
              />
              <GenericSelector
                callbackOptionSelected={(geofences) => {
                  setFieldValue(
                    "geofences",
                    Array.isArray(geofences) && geofences.length > 0
                      ? geofences.map((a) => a.value)
                      : null
                  );
                  //Agregar las geocercas al mapa
                }}
                columns={columnsGeofence}
                isMulti
                keyField="id"
                labelField="name"
                title={t("Geocercas")}
                data={geofenceList}
                defaultValue={values.geofences}
              />

              <GeofenceMap
                geofences={geofenceList?.filter((g) =>
                  values.geofences?.includes(g.id)
                )}
                roadStops={roadStops}
                road={_selectedRoad}
              />
              <Grid
                item
                lg={12}
                style={{ marginTop: "50px" }} /*className="footerSlidePanel"*/
              >
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  tabIndex="0"
                >
                  {selectedRoad ? t("Actualizar") : t("Crear")}
                </Button>
                <Button
                  variant="contained"
                  type="button"
                  tabIndex="0"
                  style={{ marginLeft: "20px" }}
                  onClick={() => {
                    closeModal();
                  }}
                >
                  {t("Cancelar")}
                </Button>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </ThemeProvider>
  );
};

export default Form;
