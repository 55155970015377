import gql from "graphql-tag";

const CHANGE_PASSWORD = gql`
  mutation changePassword(
    $userId: Int!
    $currentPassword: String!
    $newPassword1: String!
    $newPassword2: String!
  ) {
    changePassword(
      userId: $userId
      currentPassword: $currentPassword
      newPassword1: $newPassword1
      newPassword2: $newPassword2
    )
  }
`;

export default CHANGE_PASSWORD;
