import gql from "graphql-tag";

const DRIVING_BEHAVIOR_QUERY = gql`
  query DrivingBehaviorReport(
    $assetsSelected: [Int]!
    $driversSelected: [Int]
    $fechaInicio: Datetime
    $fechaFin: Datetime
    $days: [WeekDaysEnum]
  ) {
    drivingBehaviorsReport(
      assetsSelected: $assetsSelected
      driversSelected: $driversSelected
      fechaInicio: $fechaInicio
      fechaFin: $fechaFin
      days: $days
    ) {
      minScore
      maxScore
      driverProms {
        scoreProm
        kmsRecProm
        conductionTimeProm
        cmbConsumedProm
        eficiencyProm
      }
      assetProms {
        scoreProm
        kmsRecProm
        conductionTimeProm
        cmbConsumedProm
        eficiencyProm
      }
      totals {
        scoreProm
        kmsRecTot
        conductionTimeTot
        cmbConsumedTot
        eficiencyProm
      }
      summary {
        driverId
        driverName
        assetId
        assetName
        scoreProm
        scoreStatus
        kmsRecSum
        conductionTimeSum
        cmbConsumedSum
        eficiencyProm
        suddenAcelerations
        hardBrakings
        velocityExcess
        sharpTurn
      }
      details {
        driverId
        assetId
        date
        score
        kmsRec
        conductionTime
        cmbConsumed
        eficiency
        suddenAcelerations
        hardBrakings
        velocityExcess
        sharpTurn
      }
    }
  }
`;

export default DRIVING_BEHAVIOR_QUERY;
