import gql from "graphql-tag";

const DRIVERS_QUERY = gql`
  query driversPaginated(
    $pageNumber: Int
    $pageSize: Int
    $sort: DriverSortInput
    $searchText: String
    $paginate: Boolean
  ) {
    driversPaginated(
      pageNumber: $pageNumber
      pageSize: $pageSize
      sort: $sort
      searchText: $searchText
      paginate: $paginate
    ) {
      count
      items {
        id
        firstName
        lastName
        fullName
        ibutton
        phone
        companyPhone
        cellPhone
        companyCellPhone
        photo
        suspended
        comments
        customerId
        customer {
          name
        }
      }
    }
  }
`;

export default DRIVERS_QUERY;
