import gql from "graphql-tag";

const JOURNEY_PDF_QUERY = gql`
  query JourneySummaryPDF(
    $movilesID: [Int]!
    $fechaInicio: Datetime
    $fechaFin: Datetime
    $groupedBy: JourneyGroupEnum
    $days: [WeekDaysEnum]
  ) {
    journeySummaryPDF(
      movilesID: $movilesID
      fechaInicio: $fechaInicio
      fechaFin: $fechaFin
      groupedBy: $groupedBy
      days: $days
    )
  }
`;

export default JOURNEY_PDF_QUERY;
