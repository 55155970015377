import React from "react";

import Table from "../../../components/Base/Table";

import { useTranslation } from "../../../lang";

const ReportResult = ({ reportData }) => {
  const [t] = useTranslation();
  //console.log(reportData);
  const columns = [
    {
      dataField: "nMov",
      text: t("Móvil"),
      sort: true,
      headerStyle: () => ({ width: "20%" }),
    },
    {
      dataField: "pat",
      text: t("Placa"),
      sort: true,
      headerStyle: () => ({ width: "15%" }),
    },
    {
      dataField: "km",
      text: t("Kms Recorrido"),
      sort: true,
      headerStyle: () => ({ width: "15%" }),
      formatter: (cell, row) => row.km.toFixed(2),
    },

    {
      dataField: "hDet",
      text: t("Ralentí [Hs]"),
      sort: true,
      headerStyle: () => ({ width: "15%" }),
      formatter: (cell, row) => row.hDet.toFixed(2),
    },
    {
      dataField: "consumption",
      text: t("Ratio Consumo"),
      sort: true,
      headerStyle: () => ({ width: "15%" }),
      formatter: (cell, row) => row.consumption.toFixed(2),
    },
  ];

  return (
    <div style={{ padding: "20px" }}>
      <Table columns={columns} data={reportData} keyField="mid" />
    </div>
  );
};

export default ReportResult;
