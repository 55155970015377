import gql from "graphql-tag";

const SERVICE_TYPE_QUERY = gql`
  query ServiceType {
    serviceType {
      id
      description
    }
  }
`;

export default SERVICE_TYPE_QUERY;
