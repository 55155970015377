import { connect } from "react-redux";

import { logoutAction } from "../../redux/user/actions";

import UsersButton from "../../components/Menu/UsersButton";

const _mapStateToProps = ({ user }) => ({
  loggedIn: user.loggedIn,
  username: user.username,
  //profilePicture: user.profilePicture,
  email: user.email,
});

const _mapDispatchToProps = {
  handleLogoutSubmit: logoutAction,
};

export default connect(_mapStateToProps, _mapDispatchToProps)(UsersButton);
