import gql from "graphql-tag";

const SAVE_ASSETDEVICE_MUTATION = gql`
  mutation SaveAssetDevice(
    $assetid: Int!
    $deviceid: Int
    $technicianId: Int
    $dateAssociated: Datetime!
  ) {
    saveAssetDevice(
      assetid: $assetid
      deviceid: $deviceid
      technicianId: $technicianId
      dateAssociated: $dateAssociated
    )
  }
`;

export default SAVE_ASSETDEVICE_MUTATION;
