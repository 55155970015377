import gql from "graphql-tag";

const FUELCONSUMPTION_REPORT_EXPORT_QUERY = gql`
  query consumptionReportExport(
    $assetsId: [Int]!
    $dateFrom: Datetime!
    $dateTo: Datetime!
    $format: FormatEnum
  ) {
    consumptionReportExport(
      assetsId: $assetsId
      dateFrom: $dateFrom
      dateTo: $dateTo
      format: $format
    )
  }
`;

export default FUELCONSUMPTION_REPORT_EXPORT_QUERY;
