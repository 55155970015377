import gql from "graphql-tag";

const CUSTOMERS_QUERY = gql`
  query Customers(
    $pageSize: Int
    $pageNumber: Int
    $sort: SortInput
    $filter: CustomerManyInput
    $paginate: Boolean
  ) {
    customers(
      pageSize: $pageSize
      pageNumber: $pageNumber
      sort: $sort
      filter: $filter
      paginate: $paginate
    ) {
      items {
        value: id
        label: name
      }
    }
  }
`;

export default CUSTOMERS_QUERY;
