import gql from "graphql-tag";

const DELETE_DRIVER_MUTATION = gql`
  mutation deleteDriver($id: Int!) {
    deleteDriver(id: $id) {
      id
    }
  }
`;

export default DELETE_DRIVER_MUTATION;
