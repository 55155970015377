import gql from "graphql-tag";

const USER_BYCUSTOMER_QUERY = gql`
  query UsersByCustomer($customerId: Int!) {
    usersByCustomer(customerId: $customerId) {
      id
      userName
      firstName
      lastName
      email
    }
  }
`;

export default USER_BYCUSTOMER_QUERY;
