// eslint-disable react/forbid-prop-types
import React from "react";
import PropTypes from "prop-types";

import NavbarTop from "./NavbarTop";

import ConnectedLateralNavigation from "../../containers/ConnectedLateralNavigation";

import NewLateralPanel from "../NewLateralBar";

import "./Structure.scss";

const Structure = ({ children, lateralBar, matchProps }) => {
  let LateralBar = ConnectedLateralNavigation;
  let childComponent = null;
  const showMenu = localStorage.getItem("showMenu");

  if (lateralBar) {
    LateralBar = lateralBar;
  }

  if (children) {
    childComponent = <div className="structure__main">{children}</div>;
  }

  if (showMenu) {
    return (
      <div className="structure">
        <NavbarTop />
        <div className="structure__body structure__body--internal-scroll">
          <div className="structure__navigation">
            {/* <LateralBar matchProps={matchProps} />*/}
            <NewLateralPanel />
          </div>

          {childComponent}
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div className="structure__body structure__body--internal-scroll">
          {childComponent}
        </div>
      </div>
    );
  }
};

Structure.propTypes = {
  matchProps: PropTypes.object,
  children: PropTypes.node,
  lateralBar: PropTypes.any,
};

Structure.defaultProps = {
  matchProps: {},
  children: null,
  lateralBar: null,
};

export default Structure;
