import React from "react";
import OBD2DisplayGrid from "./OBD2DisplayGrid";
import OBD2DisplayItem from "./OBD2DisplayItem";
import { useTranslation } from "./../../lang";

import "./style.scss";

const OBD2InformationDisplay = ({ data }) => {
  const [t] = useTranslation();
  const summary = data.summary || [];
  const items = data.items || [];
  return (
    <>
      {summary && summary.length > 0 && (
        <OBD2DisplayGrid>
          {summary.map((item, key) => {
            return (
              <OBD2DisplayItem
                title={item.title}
                value={item.value}
                key={key}
              />
            );
          })}
        </OBD2DisplayGrid>
      )}
      {items && items.length > 0 && (
        <OBD2DisplayGrid>
          {items.map((item) => {
            return (
              <OBD2DisplayItem
                title={item.title}
                value={item.value}
                key={item.id}
              />
            );
          })}
        </OBD2DisplayGrid>
      )}
      {!(summary.length === 0 && items.length === 0) ||
        t("No hay informacion OBD2 disponible")}
    </>
  );
};

export default OBD2InformationDisplay;
