import gql from "graphql-tag";

const GET_CONF_NOTIFICATIONS = gql`
  query ConfNotificationList(
    $customerIds: [Int]
    $pageNumber: Int
    $pageSize: Int
    $searchText: String
    $sort: ConfigurationNotificationListItemSortInput
    $paginate: Boolean
  ) {
    getConfigurationNotificationList(
      customerIds: $customerIds
      pageNumber: $pageNumber
      pageSize: $pageSize
      searchText: $searchText
      sort: $sort
      paginate: $paginate
    ) {
      count
      items {
        id
        name
        enabled
        mailList
      }
    }
  }
`;

export default GET_CONF_NOTIFICATIONS;
