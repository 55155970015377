import gql from "graphql-tag";

const DELETE_GROUP_MUTATION = gql`
  mutation deleteGroup($groupId: Int!) {
    deleteGroup(groupId: $groupId) {
      id
    }
  }
`;

export default DELETE_GROUP_MUTATION;
