import React, { useEffect, useState } from "react";
import { IconButton } from "@material-ui/core";
import { useTranslation } from "../../../lang";
import IconExcel from "../../../public/iconExcel.png";
import IconPDF from "../../../public/iconPDF.png";

const INITIAL_CSS_CLASSES = ["exportIcon"];

export const BaseDownloadButton = ({
  disabled = false,
  title,
  alt,
  icon,
  onClick,
}) => {
  const [classes, setClasses] = useState(INITIAL_CSS_CLASSES);

  useEffect(() => {
    if (disabled) {
      setClasses([...classes, "exportIconMuted"]);
    } else {
      setClasses(INITIAL_CSS_CLASSES);
    }
  }, [disabled]);
  return (
    <div className="flotaStateExportPanelIconWrapper">
      <IconButton
        className="flotaStateExportPanelIcon"
        title={title}
        onClick={onClick}
        disabled={disabled}
      >
        <img src={icon} className={classes.join(" ")} alt={alt} />
      </IconButton>
    </div>
  );
};

export const DownloadPDFButton = ({ disabled = false, onClick = () => {} }) => {
  const [t] = useTranslation();
  return (
    <BaseDownloadButton
      title={t("Exportar PDF")}
      alt={t("Exportar PDF")}
      icon={IconPDF}
      onClick={onClick}
      disabled={disabled}
    />
  );
};

export const DownloadExcelButton = ({
  disabled = false,
  onClick = () => {},
}) => {
  const [t] = useTranslation();
  return (
    <BaseDownloadButton
      title={t("Exportar Excel")}
      alt={t("Exportar Excel")}
      icon={IconExcel}
      onClick={onClick}
      disabled={disabled}
    />
  );
};
