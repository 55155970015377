import React from "react";
import { useHistory } from "react-router-dom";
import { List, ListItem, ListItemText } from "@material-ui/core";
import { connect } from "react-redux";

import { closePanel } from "../../redux/lateralPanel/actions";
import { hasPermission } from "../../utils/generalUtils";

import { useTranslation } from "../../lang";

const AVAILABLE_REPORTS = {
  Consolidados: "Consolidados",
  ConsumoCombustible: "ConsumoCombustible",
  Detenciones: "Detenciones",
  EcoTracking: "EcoTracking",
  Eventos: "Eventos",
  Geoescalas: "Geoescalas",
  KilometrajeFlota: "KilometrajeFlota",
  ReporteOBD2: "ReporteOBD2",
  TableroSeguimiento: "TableroSeguimiento",
  Velocidad: "Velocidad",
  ReporteDesvioDeRuta: "ReporteDesvioDeRuta",
  ReporteDesvioDeParada: "ReporteDesvioDeParada",
  StopHistoryComparison: "StopHistoryComparison",
};

const DEFAULT_REPORTS_ORDER = {
  [AVAILABLE_REPORTS.Velocidad]: 0,
  [AVAILABLE_REPORTS.Consolidados]: 1,
  [AVAILABLE_REPORTS.ConsumoCombustible]: 2,
  [AVAILABLE_REPORTS.Detenciones]: 3,
  [AVAILABLE_REPORTS.EcoTracking]: 4,
  [AVAILABLE_REPORTS.Eventos]: 5,
  [AVAILABLE_REPORTS.Geoescalas]: 6,
  [AVAILABLE_REPORTS.KilometrajeFlota]: 7,
  [AVAILABLE_REPORTS.ReporteOBD2]: 8,
  [AVAILABLE_REPORTS.TableroSeguimiento]: 9,
  [AVAILABLE_REPORTS.ReporteDesvioDeRuta]: 10,
  [AVAILABLE_REPORTS.ReporteDesvioDeParada]: 11,
  [AVAILABLE_REPORTS.StopHistoryComparison]: 12,
};

const sortListItemByItemOrder = (listItemA, listItemB) => {
  return listItemA.order - listItemB.order;
};

const StatisticsPanel = ({ closePanel, user }) => {
  const [t] = useTranslation();
  const history = useHistory();
  // console.log("roles", user.roles);

  const listItemSortingAlgorithm = sortListItemByItemOrder;

  const LIST_ITEMS = [
    {
      order: DEFAULT_REPORTS_ORDER.Consolidados,
      item: hasPermission(AVAILABLE_REPORTS.Consolidados, user.roles) && (
        <ListItem
          button
          onClick={() => {
            closePanel();
            history.push("/report/consolidate");
          }}
        >
          <ListItemText primary={t("Consolidados")} />
        </ListItem>
      ),
    },
    {
      order: DEFAULT_REPORTS_ORDER.ConsumoCombustible,
      item: hasPermission(AVAILABLE_REPORTS.ConsumoCombustible, user.roles) && (
        <ListItem
          button
          onClick={() => {
            closePanel();
            history.push("/report/fuelconsumption");
          }}
        >
          <ListItemText primary={t("Consumo de Combustible")} />
        </ListItem>
      ),
    },
    {
      order: DEFAULT_REPORTS_ORDER.Detenciones,
      item: hasPermission(AVAILABLE_REPORTS.Detenciones, user.roles) && (
        <ListItem
          button
          onClick={() => {
            closePanel();
            history.push("/report/detention");
          }}
        >
          <ListItemText primary={t("Detenciones")} />
        </ListItem>
      ),
    },
    {
      order: DEFAULT_REPORTS_ORDER.EcoTracking,
      item: hasPermission(AVAILABLE_REPORTS.EcoTracking, user.roles) && (
        <ListItem
          button
          style={{ backgroundColor: "green" }}
          onClick={() => {
            closePanel();
            history.push("/report/ecoTracking");
          }}
        >
          <ListItemText primary={t("Eco Tracking")} />
        </ListItem>
      ),
    },
    {
      order: DEFAULT_REPORTS_ORDER.Eventos,
      item: hasPermission(AVAILABLE_REPORTS.Eventos, user.roles) && (
        <ListItem
          button
          onClick={() => {
            closePanel();
            history.push("/report/event");
          }}
        >
          <ListItemText primary={t("Eventos")} />
        </ListItem>
      ),
    },
    {
      order: DEFAULT_REPORTS_ORDER.Geoescalas,
      item: hasPermission(AVAILABLE_REPORTS.Geoescalas, user.roles) && (
        <ListItem
          button
          onClick={() => {
            closePanel();
            history.push("/report/geoscale");
          }}
        >
          <ListItemText primary={t("Geoescalas")} />
        </ListItem>
      ),
    },
    {
      order: DEFAULT_REPORTS_ORDER.KilometrajeFlota,
      item: hasPermission(AVAILABLE_REPORTS.KilometrajeFlota, user.roles) && (
        <ListItem
          button
          onClick={() => {
            closePanel();
            history.push("/report/fleetKm");
          }}
        >
          <ListItemText primary={t("Kilometraje de Flota")} />
        </ListItem>
      ),
    },
    {
      order: DEFAULT_REPORTS_ORDER.ReporteOBD2,
      item: hasPermission(AVAILABLE_REPORTS.ReporteOBD2, user.roles) && (
        <ListItem
          button
          onClick={() => {
            closePanel();
            history.push("/report/obd2");
          }}
        >
          <ListItemText primary={t("OBD2")} />
        </ListItem>
      ),
    },
    {
      order: DEFAULT_REPORTS_ORDER.TableroSeguimiento,
      item: hasPermission(AVAILABLE_REPORTS.TableroSeguimiento, user.roles) && (
        <ListItem
          button
          onClick={() => {
            closePanel();
            history.push("/report/trackingBoard");
          }}
        >
          <ListItemText primary={t("Tablero Seguimiento")} />
        </ListItem>
      ),
    },
    {
      order: DEFAULT_REPORTS_ORDER.Velocidad,
      item: hasPermission(AVAILABLE_REPORTS.Velocidad, user.roles) && (
        <ListItem
          button
          onClick={() => {
            closePanel();
            history.push("/report/velocity");
          }}
        >
          <ListItemText primary={t("Velocidades")} />
        </ListItem>
      ),
    },
    {
      order: DEFAULT_REPORTS_ORDER.ReporteDesvioDeRuta,
      item: hasPermission(
        AVAILABLE_REPORTS.ReporteDesvioDeRuta,
        user.roles
      ) && (
        <ListItem
          button
          onClick={() => {
            closePanel();
            history.push("/report/routeDetour");
          }}
        >
          <ListItemText primary={t("Desvios de ruta")} />
        </ListItem>
      ),
    },
    {
      order: DEFAULT_REPORTS_ORDER.ReporteDesvioDeParada,
      item: hasPermission(
        AVAILABLE_REPORTS.ReporteDesvioDeParada,
        user.roles
      ) && (
        <ListItem
          button
          onClick={() => {
            closePanel();
            history.push("/report/stopDetour");
          }}
        >
          <ListItemText primary={t("Desvios de Parada")} />
        </ListItem>
      ),
    },
    {
      order: DEFAULT_REPORTS_ORDER.StopHistoryComparison,
      item: hasPermission(
        AVAILABLE_REPORTS.StopHistoryComparison,
        user.roles
      ) && (
        <ListItem
          button
          onClick={() => {
            closePanel();
            history.push("/report/stopHistory");
          }}
        >
          <ListItemText primary={t("Histórico de Paradas")} />
        </ListItem>
      ),
    },
  ];
  return (
    <List component="nav">
      {LIST_ITEMS.sort(listItemSortingAlgorithm).map(
        (listItem) => listItem.item
      )}
    </List>
  );
};

const _mapStateToProps = ({ user }) => {
  return {
    user,
  };
};

const _mapDispatchToProps = (dispatch) => {
  return {
    closePanel: () => dispatch(closePanel()),
  };
};

export default connect(_mapStateToProps, _mapDispatchToProps)(StatisticsPanel);
