import gql from "graphql-tag";

const CREATE_GROUP_MUTATION = gql`
  mutation CreateGroup(
    $name: String!
    $parentGroupId: Int!
    $customerId: Int!
  ) {
    createGroup(
      name: $name
      parentGroupId: $parentGroupId
      customerId: $customerId
    ) {
      id
      name
      parentGroupId
      customerId
    }
  }
`;

export default CREATE_GROUP_MUTATION;
