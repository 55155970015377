import React, { useState } from "react";

import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import GET_DEVICES_QUERY from "../../../graphql/queries/devicesQuery";
import GET_TECHNICIANS_QUERY from "../../../graphql/queries/getTechniciansQuery";
import SAVE_ASSETDEVICE_MUTATION from "../../../graphql/mutations/saveAssetDevice";

import ModalHistorialAD from "./ModalHistorialAD";
import moment from "moment";
import { DatePicker } from "antd";
import "antd/dist/antd.css";

import { Button, FormGroup, Snackbar } from "@material-ui/core";

import MuiAlert from "@material-ui/lab/Alert";

import { format } from "date-fns";

import GenericSelector from "../../../components/Selectors/GenericSelector";

import { Field, Formik } from "formik";
import { useEffect } from "react";

import { useTranslation } from "../../../lang";

const AsignacionEquipo = ({ closeModal, assetSelected, getAssets }) => {
  const [t] = useTranslation();
  const [devices, setDevices] = useState([]);
  const [technicians, setTechnicians] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const closeModalHistorialAD = () => setIsOpen(false);

  const [resultState, setResultState] = useState({
    isOpen: false,
    type: "success",
    msg: "",
  });

  const initialValues = {
    assetid: assetSelected?.id,
    deviceid: assetSelected?.assetDevice?.device?.id || "",
    technicianId: assetSelected?.assetDevice?.technicianId,
    dateAssociated: assetSelected?.assetDevice?.dateAssociated || "",
    typeMobile: assetSelected?.typeMobile?.description,
    name: assetSelected?.name || "",
  };

  const columnsDevices = [
    {
      dataField: "deviceIdentifier",
      text: t("Identificador"),
      headerStyle: () => ({ width: "15%", fontSize: "12px" }),
      sort: true,
    },
    {
      dataField: "deviceType.description",
      text: t("Tipo Equipo"),
      headerStyle: () => ({ width: "15%", fontSize: "12px" }),
      //sort: true,
    },
    {
      dataField: "model",
      text: t("Modelo"),
      headerStyle: () => ({ width: "10%", fontSize: "12px" }),
      sort: true,
    },
    {
      dataField: "serialNumber",
      text: t("Nro Serie"),
      sort: true,
      headerStyle: () => ({ width: "10%", fontSize: "12px" }),
    },
    {
      dataField: "line",
      text: t("Nro Telefono"),
      headerStyle: () => ({ width: "10%", fontSize: "12px" }),
      formatter: (cell, row) => {
        if (Array.isArray(row.line)) {
          return row.line.map((l) => l.lineNumber).join(",");
        }

        return null;
      },
      //sort: true,
    },
    {
      dataField: "imei",
      text: t("Imei"),
      headerStyle: () => ({ width: "15%", fontSize: "12px" }),
      sort: true,
    },
    {
      dataField: "createdDate",
      text: t("Fecha Alta"),
      headerStyle: () => ({ width: "10%", fontSize: "12px" }),
      formatter: (cell, row) => {
        if (row.createdDate) {
          const date = format(new Date(row.createdDate), "yyyy-MM-dd HH:mm:ss");
          return date;
        }

        return "";
      },
      sort: true,
    },
  ];

  const itemStyle = {
    fontSize: "12px",
    color: "white",
  };

  const columnsTechnicians = [
    {
      dataField: "code",
      text: t("Código"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
    },
    {
      dataField: "firstname",
      text: t("Nombre"),
      headerStyle: () => ({ width: "15%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
    },
    {
      dataField: "lastname",
      text: t("Apellido"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
    },
    {
      dataField: "email",
      text: t("Email"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
    },
    {
      dataField: "phoneNumber",
      text: t("Teléfono"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
    },
    {
      dataField: "mobilePhone",
      text: t("Teléfono Móvil"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
    },
  ];

  const [fetchDevices] = useLazyQuery(GET_DEVICES_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      withoutAssign: true,
    },
    onCompleted(d) {
      setDevices(
        assetSelected.assetDevice
          ? [assetSelected.assetDevice.device, ...d.devicesPaginated.items]
          : d.devicesPaginated.items
      );
    },
  });

  useQuery(GET_TECHNICIANS_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      setTechnicians(d.technicians);
    },
  });

  const [saveAssetDevice] = useMutation(SAVE_ASSETDEVICE_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Asignacion actualizada correctamente"),
      });
      getAssets();
    },
    onError(error) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: error.message,
      });
    },
  });

  useEffect(() => {
    if (assetSelected) {
      fetchDevices();
    }
  }, [assetSelected, fetchDevices]);

  const handleCloseNotification = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setResultState({
      ...resultState,
      isOpen: false,
    });
  };

  return (
    <>
      <Snackbar
        open={resultState.isOpen}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleCloseNotification}
          severity={resultState.type}
          variant="filled"
        >
          {resultState.msg}
        </MuiAlert>
      </Snackbar>
      <ModalHistorialAD
        assetId={assetSelected?.id}
        isOpen={isOpen}
        closeModal={closeModalHistorialAD}
      />
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        enableReinitialize={true}
        //validationSchema={yupSchema}
        onSubmit={async ({
          assetid,
          deviceid,
          technicianId,
          dateAssociated,
        }) => {
          saveAssetDevice({
            variables: {
              assetid,
              deviceid,
              technicianId,
              dateAssociated,
            },
          });
        }}
      >
        {(props) => {
          const { handleSubmit, setFieldValue } = props;

          return (
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <label className="formLabelFormik">
                  {t("Tipo de Activo")}:{" "}
                </label>
                <Field
                  type="text"
                  name="typeMobile"
                  tabIndex="0"
                  className="form-control text-input"
                  autoComplete="off"
                  disabled
                />
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">{t("Movil")}: </label>
                <Field
                  type="text"
                  name="name"
                  tabIndex="0"
                  className="form-control text-input"
                  autoComplete="off"
                  disabled
                />
              </FormGroup>
              <FormGroup>
                {assetSelected && (
                  <GenericSelector
                    callbackOptionSelected={(device) =>
                      setFieldValue("deviceid", device?.value)
                    }
                    columns={columnsDevices}
                    keyField="id"
                    labelField="deviceIdentifier"
                    title={t("Equipo")}
                    data={devices}
                    defaultValue={assetSelected?.assetDevice?.device?.id}
                  />
                )}
              </FormGroup>
              <FormGroup>
                <GenericSelector
                  callbackOptionSelected={(technician) =>
                    setFieldValue("technicianId", technician?.value)
                  }
                  columns={columnsTechnicians}
                  keyField="id"
                  labelField="lastname"
                  title={t("Tecnicos")}
                  data={technicians}
                  defaultValue={assetSelected?.assetDevice?.technicianId}
                />
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">
                  {t("Fecha instalación")}:{" "}
                </label>
                <DatePicker
                  defaultValue={
                    initialValues.dateAssociated
                      ? moment(
                          initialValues.dateAssociated,
                          "YYYY-MM-DD HH:mm:ss"
                        )
                      : undefined
                  }
                  showTime
                  format="YYYY-MM-DD HH:mm:ss"
                  onChange={(value, dateString) => {
                    setFieldValue("dateAssociated", dateString);
                  }}
                />
              </FormGroup>

              <div
                style={{
                  marginTop: "50px",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  type="button"
                  onClick={() => closeModal()}
                  style={{ marginLeft: "5px" }}
                >
                  {t("Cancelar")}
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  style={{ marginLeft: "5px" }}
                >
                  {t("Guardar")}
                </Button>
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  style={{ marginLeft: "5px" }}
                  onClick={() => setIsOpen(true)}
                >
                  {t("Ver Historial")}
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default AsignacionEquipo;
