import gql from "graphql-tag";

const LINES_QUERY = gql`
  query Lines {
    lines {
      id
      lineNumber
      SimNumber
      createdAt
      customer {
        id
        name
      }
      customerId
      commType {
        id
        description
      }
      commTypeId
      email
      device {
        id
        deviceIdentifier
      }
      deviceId
    }
  }
`;

export default LINES_QUERY;
