import gql from "graphql-tag";

const UPDATE_SERVICE_MUTATION = gql`
  mutation UpdateService(
    $id: Int!
    $assetId: Int!
    $serviceTypeId: Int!
    $frequencyId: Int
    $quantityKm: Float
    $percentageAlert: Int
    $emailsList: String
    $dateStart: Datetime
    $userIds: [Int]
  ) {
    updateService(
      id: $id
      assetId: $assetId
      serviceTypeId: $serviceTypeId
      frequencyId: $frequencyId
      quantityKm: $quantityKm
      percentageAlert: $percentageAlert
      emailsList: $emailsList
      dateStart: $dateStart
      userIds: $userIds
    ) {
      id
      asset {
        id
        name
        licensePlate
      }
      serviceType {
        id
        description
      }
      frequency {
        id
        description
      }
      quantityKm
      percentageAlert
      emailsList
      warningDate
      warningKm
      dueDate
      actualOdo
      dueOdometer
      users {
        id
      }
    }
  }
`;

export default UPDATE_SERVICE_MUTATION;
