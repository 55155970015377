import gql from "graphql-tag";

const CONFIGURATION_EVENTS_QUERY = gql`
  query ConfigurationEventByEquipoId($deviceId: Int) {
    configurationEventByEquipoId(deviceId: $deviceId) {
      id
      value
      isAlarm
      isMovementAlarm
      isPositionRequest
      isAutomaticReport
      isInputEvent
      isOutputEvent
      isIgnitionOn
      isIgnitionOff
      isOpenDoor
      isSoftwareEvent
      typeEvent {
        id
        description
      }
    }
  }
`;

export default CONFIGURATION_EVENTS_QUERY;
