import React, { useState, useEffect } from "react";

import VelocityChart from "./VelocityChart";
import KilometrajeChart from "./KilometrajeChart";

import { Grid } from "@material-ui/core";
import { format } from "date-fns";

import "./styles.scss";
import { useTranslation } from "../../../lang";

const ChartContainer = ({ showJourney, journeys, actualPoint }) => {
  const [t] = useTranslation();
  const [data, setData] = useState([]);
  const [selPoint, setSelPoint] = useState();

  // console.log("journeys: ", journeys);

  useEffect(() => {
    if (journeys && journeys.daySteps) {
      let track = [];
      let dayStep;

      if (journeys.daySteps.length > 1) {
        dayStep = journeys.daySteps.filter((ds) => ds.visible);
        if (dayStep.length === 0) dayStep = journeys.daySteps[0];
        if (dayStep.length > 0) dayStep = dayStep[0];
      } else {
        dayStep = journeys.daySteps[0];
      }

      const selectedStep = dayStep
        ? dayStep.steps.filter((s) => s.visible)
        : [];

      if (Array.isArray(selectedStep) && selectedStep.length === 1) {
        // console.log("Seteando tramo seleccionado");
        track = [
          ...track,
          ...selectedStep[0].listPos.map((lp) => ({
            id: lp.id,
            lat: lp.lat,
            lng: lp.lon,
            dir: lp.dir,
            date: new Date(lp.fGPS),
            vel: lp.vel,
            odo: lp.odo,
          })),
        ];
      } else {
        // console.log("Seteando viaje seleccionado");
        dayStep &&
          dayStep.steps.forEach((s) => {
            track = [
              ...track,
              ...s.listPos.map((lp) => ({
                id: lp.id,
                lat: lp.lat,
                lng: lp.lon,
                dir: lp.dir,
                date: new Date(lp.fGPS),
                //date: lp.fGPS,
                vel: lp.vel,
                odo: lp.odo,
              })),
            ];
          });
      }

      setData(track);
    }
  }, [journeys]);

  useEffect(() => {
    if (!actualPoint) return;

    const point = data.find((p) => p.id === actualPoint);
    setSelPoint(point);
  }, [actualPoint]);

  if (!showJourney) return <div />;

  return (
    <div className="chartsContainer">
      <Grid container className="legendRow">
        <Grid item xs={3} style={{ lineHeight: "21px" }}>
          {data.length > 0 ? (
            <>
              <div className="indicator velIndicator" />
              <label>{t("Velocidad")}</label>
              <div className="indicator odoIndicator" />
              <label>{t("Kilometraje")}</label>
            </>
          ) : (
            <>
              <label className="noDataAvailableIndicator">
                {t("Sin informacion disponible")}
              </label>
            </>
          )}
        </Grid>

        <Grid item xs={3} style={{ lineHeight: "21px" }} />
        <Grid item xs={6} style={{ lineHeight: "21px" }}>
          {selPoint && (
            <>
              <p className="selPointValue">
                <b>{t("Dirección:")} </b>
                {selPoint.dir}
              </p>
              {/* <p className="selPointValue">{`{<b>}Fecha:{</b>} ${format(new Date(selPoint.date), 'dd/MM/yyyy HH:mm:ss')} - ${<b>Velocidad:</b>} ${selPoint.vel}`}</p> */}
              <p className="selPointValue">
                <b>{t("Fecha:")} </b>
                {format(new Date(selPoint.date), "dd/MM/yyyy HH:mm:ss")} -{" "}
                <b>{t("Velocidad:")} </b>
                {selPoint.vel} {t("km/h")}
              </p>
            </>
          )}
        </Grid>
      </Grid>
      <VelocityChart data={data} />
      <KilometrajeChart data={data} />
    </div>
  );
};

export default ChartContainer;
