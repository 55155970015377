import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { Popup } from "react-leaflet";

import { AVAILABLE_VARIANTS } from "../../constants/map";

import "./MarkerPopup.scss";

export default class MarkerPopup extends Component {
  static propTypes = {
    variant: PropTypes.oneOf(AVAILABLE_VARIANTS),
    className: PropTypes.string,
    autoClose: PropTypes.bool,
    children: PropTypes.node,
    position: PropTypes.array,
  };

  static defaultProps = {
    variant: AVAILABLE_VARIANTS[0],
    className: "",
    autoClose: false,
    children: null,
    position: [],
  };

  render() {
    const { variant, children, autoClose, className, position } = this.props;

    const popupClassNames = classNames("marker-popup", {
      [`marker-popup--${variant}`]: variant,
      [className]: className,
    });

    return (
      <Popup
        position={position}
        className={popupClassNames}
        autoClose={autoClose}
      >
        {children}
      </Popup>
    );
  }
}
