import gql from "graphql-tag";

const CREATE_SERVICE_PERFORMED_MUTATION = gql`
  mutation CreateServicePerformed(
    $assetId: Int!
    $serviceTypeId: Int!
    $date: Datetime!
    $kms: Float
    $marca: String
    $supplier: String
    $notes: String
  ) {
    createServicePerformed(
      assetId: $assetId
      serviceTypeId: $serviceTypeId
      date: $date
      kms: $kms
      marca: $marca
      supplier: $supplier
      notes: $notes
    )
  }
`;

export default CREATE_SERVICE_PERFORMED_MUTATION;
