import { isObject } from "lodash";

const getDateQuery = (dates) => {
  let queryParams;

  if (isObject(dates) && dates.startDate && dates.endDate) {
    queryParams = `?start_date=${dates.startDate}&page=1&end_date=${dates.endDate}`;

    return queryParams;
  }

  return "";
};

const sanitizeSort = (sortObj) => {
  if (Object.keys(sortObj).some((key) => key.includes("."))) {
    Object.keys(sortObj).forEach(function (key) {
      var newkey = key.replace(".", "_");
      sortObj[newkey] = sortObj[key];
      delete sortObj[key];
    });
  }
  return sortObj;
};

export { getDateQuery, sanitizeSort };
