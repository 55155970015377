import gql from "graphql-tag";

const DELETE_ROAD_MUTATION = gql`
  mutation DeleteRoad($id: Int!) {
    deleteRoad(id: $id) {
      id
    }
  }
`;

export default DELETE_ROAD_MUTATION;
