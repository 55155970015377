import React, { useLayoutEffect, useRef } from "react";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import darkTheme from "@amcharts/amcharts4/themes/dark";

am4core.useTheme(darkTheme);
am4core.useTheme(am4themes_animated);

const KilometrajeChart = ({ data }) => {
  const chart = useRef(null);

  useLayoutEffect(() => {
    if (chart.current) {
      chart.current.series.each((serie) => {
        serie.data = data;
      });
    }
  }, [data]);

  useLayoutEffect(() => {
    let x = am4core.create("odoChartContainer", am4charts.XYChart);
    //Create axis
    const dateAxis = x.xAxes.push(new am4charts.DateAxis());
    x.yAxes.push(new am4charts.ValueAxis());

    x.background.fill = am4core.color("#2C2C2C");
    dateAxis.renderer.labels.template.disabled = true;

    let odoSerie = x.series.push(new am4charts.LineSeries());
    odoSerie.dataFields.valueY = "odo";
    odoSerie.dataFields.dateX = "date";
    odoSerie.name = "velocity";

    odoSerie.stroke = am4core.color("#348500");
    odoSerie.strokeWidth = 2;

    let segmentFS = odoSerie.segments.template;
    segmentFS.interactionsEnabled = true;

    odoSerie.data = data;

    chart.current = x;

    return () => {
      x.dispose();
    };
  }, []);

  return (
    <>
      <div id="odoChartContainer" style={{ width: "100%", height: "66px" }} />
    </>
  );
};

export default KilometrajeChart;
