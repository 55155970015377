import React from 'react';
import PropTypes from 'prop-types';

import AppBar from '@material-ui/core/AppBar';

import Typography from '@material-ui/core/Typography';

import './TabPanelContent.scss';

const TabsPanelContent = ({
    id,
    children,
    value,
    index,
    subtitle,
    ...other
}) => {
    let hiddenTabProps = {};

    if (index !== undefined) {
        hiddenTabProps = {
            hidden: value !== index
        };
    }

    return (
        <div className="tab-panel__content">
            <Typography
                component="div"
                role="tabpanel"
                id={`${id}-${index}`}
                aria-labelledby={`${id}-${index}`}
                {...hiddenTabProps}
                {...other}
            >
                {subtitle && (
                    <AppBar
                        className="tab-panel__content-subtitle"
                        position="static"
                    >
                        {subtitle}
                    </AppBar>
                )}

                {children}
            </Typography>
        </div>
    );
};

TabsPanelContent.propTypes = {
    id: PropTypes.string,
    children: PropTypes.node,
    index: PropTypes.number,
    value: PropTypes.number,
    subtitle: PropTypes.string
};

TabsPanelContent.defaultProps = {
    id: 'simple-tabpanel',
    children: null,
    index: undefined,
    value: undefined,
    subtitle: ''
};

export default TabsPanelContent;
