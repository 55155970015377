import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import { Provider } from "react-redux";
import thunk from "redux-thunk";

import reducer from "../redux/rootReducer";

import { configureInitialState, configureStore } from "../utils/store";

import ManageBasePage from "../pages/Manage/Base";
import ManageClientsPage from "../pages/Manage/Clients";
import ManageDevicesPage from "../pages/Manage/Devices";
import PrivateRoute from "./PrivateRoute";
import ManageTechnicianPage from "../pages/Manage/Technicians";
import ManageLinePage from "../pages/Manage/Lines";
import ManageUserPage from "../pages/Manage/Users";
import ManageRolePage from "../pages/Manage/Roles";
import ManageDriverPage from "../pages/Manage/Drivers";
import ManageAssetPage from "../pages/Manage/Assets";
import ManageGroupsPage from "../pages/Manage/Groups";
import ManageGeofencePage from "../pages/Manage/Geofences";
import ManageReportPlanningPage from "../pages/Manage/ReportPlanning";
import ManagePOIPage from "../pages/Manage/POIs";
import ManageGuidNumberPage from "../pages/Manage/GuideNumber";
import ManageRoadPage from "../pages/Manage/Routes";
import MultipleTracking from "../components/MultipleTracking/MultipleTracking";
import ManageNotificationConfiguration from "../pages/Manage/NotificationConfiguration";

export default class ManageRoutes extends Component {
  static propTypes = {
    path: PropTypes.string,
  };

  static defaultProps = {
    path: "",
  };

  constructor(props) {
    super(props);

    /**
     * Let's create a store with redux-thunk and set the initial state
     * If you need to add or modify objects in the store, remember to start
     * adding them to the 'configureInitialState' method
     */
    this._store = configureStore({
      reducer,
      initialState: configureInitialState(props),
      // eslint-disable-next-line comma-dangle
      middleware: [thunk],
    });
  }

  render() {
    const { path } = this.props;
    return (
      <Provider store={this._store} path={path}>
        <Route
          path={`${path}/`}
          exact
          render={(routerParams) => (
            <PrivateRoute
              path={`${path}/`}
              component={ManageBasePage}
              {...routerParams}
            />
          )}
        />

        <Route
          path={`${path}/clients`}
          exact
          render={(routerParams) => (
            <PrivateRoute
              path={`${path}/clients`}
              permission="Clientes"
              component={ManageClientsPage}
              {...routerParams}
            />
          )}
        />
        <Route
          path={`${path}/devices`}
          exact
          render={(routerParams) => (
            <PrivateRoute
              path={`${path}/devices`}
              permission="Equipos"
              component={ManageDevicesPage}
              {...routerParams}
            />
          )}
        />
        <Route
          path={`${path}/technicians`}
          exact
          render={(routerParams) => (
            <PrivateRoute
              path={`${path}/technicians`}
              permission="Técnicos"
              component={ManageTechnicianPage}
              {...routerParams}
            />
          )}
        />
        <Route
          path={`${path}/lines`}
          exact
          render={(routerParams) => (
            <PrivateRoute
              path={`${path}/lines`}
              permission="Lineas"
              component={ManageLinePage}
              {...routerParams}
            />
          )}
        />
        <Route
          path={`${path}/users`}
          exact
          render={(routerParams) => (
            <PrivateRoute
              path={`${path}/users`}
              permission="Usuarios"
              component={ManageUserPage}
              {...routerParams}
            />
          )}
        />
        <Route
          path={`${path}/roles`}
          exact
          render={(routerParams) => (
            <PrivateRoute
              path={`${path}/roles`}
              permission="Roles"
              component={ManageRolePage}
              {...routerParams}
            />
          )}
        />
        <Route
          path={`${path}/drivers`}
          exact
          render={(routerParams) => (
            <PrivateRoute
              path={`${path}/drivers`}
              permission="Conductores"
              component={ManageDriverPage}
            />
          )}
        />
        <Route
          path={`${path}/assets`}
          exact
          render={(routerParams) => (
            <PrivateRoute
              path={`${path}/assets`}
              permission="Moviles"
              component={ManageAssetPage}
              {...routerParams}
            />
          )}
        />

        <Route
          path={`${path}/groups`}
          exact
          render={(routerParams) => (
            <PrivateRoute
              path={`${path}/groups`}
              permission="Grupos"
              component={ManageGroupsPage}
              {...routerParams}
            />
          )}
        />

        <Route
          path={`${path}/geofences`}
          exact
          render={(routerParams) => (
            <PrivateRoute
              path={`${path}/geofences`}
              permission="Geocercas"
              component={ManageGeofencePage}
              {...routerParams}
            />
          )}
        />

        <Route
          path={`${path}/reportPlanning`}
          exact
          render={(routerParams) => (
            <PrivateRoute
              path={`${path}/reportPlanning`}
              permission="Planificador"
              component={ManageReportPlanningPage}
              {...routerParams}
            />
          )}
        />

        <Route
          path={`${path}/pois`}
          exact
          render={(routerParams) => (
            <PrivateRoute
              path={`${path}/pois`}
              permission="PuntosInteres"
              component={ManagePOIPage}
              {...routerParams}
            />
          )}
        />

        <Route
          path={`${path}/trackingnumber`}
          exact
          render={(routerParams) => (
            <PrivateRoute
              path={`${path}/trackingnumber`}
              permission="AsignarNroGuia"
              component={ManageGuidNumberPage}
              {...routerParams}
            />
          )}
        />
        <Route
          path={`${path}/road`}
          exact
          render={(routerParams) => (
            <PrivateRoute
              path={`${path}/road`}
              permission="Rutas"
              component={ManageRoadPage}
              {...routerParams}
            />
          )}
        />
        <Route
          path={`${path}/confNotifications`}
          exact
          render={(routerParams) => (
            <PrivateRoute
              path={`${path}/confNotifications`}
              permission="Planificador"
              component={ManageNotificationConfiguration}
              {...routerParams}
            />
          )}
        />
        {/* <Route
          path={`${path}/mtracking`}
          exact
          render={(routerParams) => (
            <PrivateRoute
              path={`${path}/mtracking`}
              permission="Rutas"
              component={MultipleTracking}
              {...routerParams}
            />
          )}
        /> */}
      </Provider>
    );
  }
}
