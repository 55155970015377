import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { useQuery, useLazyQuery } from "@apollo/client";
import {
  downloadExcel,
  downloadKML,
  downloadPDF,
} from "../../../utils/generalUtils";

import Structure from "../../../components/Base/Structure";

import ASSETS_BYGROUPS_QUERY from "../../../graphql/queries/assetByGroupsQuery";
import DRIVERS_PAGINATED_QUERY from "../../../graphql/queries/getDriversPaginatedQuery";
import GET_ENUMS_QUERY from "../../../graphql/queries/getEnumsQuery";
import GET_DETENTIONREPORT_QUERY from "../../../graphql/queries/getDetentionReportQuery";
import DETENTIONREPORT_EXPORT_QUERY from "../../../graphql/queries/getDetentionReportExportQuery";

import Filters from "./Filters";
import ReportResult from "./ReportResult";

import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import PageTitle from "../../../components/Base/PageTitle";
import { useFilterValidation } from "../useFilterValidation";

import { useTranslation } from "../../../lang";
import { localDateTimeStringToUTC } from "../../../utils/dates";

const DetentionReport = ({ selectedGroups, user }) => {
  const [t] = useTranslation();
  const [assetList, setAssetList] = useState([]);
  const [exportSelected, setExportSelected] = useState("EXCEL");
  const [driverList, setDriverList] = useState([]);
  const [assetStateList, setAssetStateList] = useState([]);
  const [assetTableState] = useState({
    searchText: "",
    sort: undefined,
    paginate: false,
  });

  const [reportResult, setReportResult] = useState([]);

  const [resultState, setResultState] = useState({
    isOpen: false,
    type: "success",
    msg: "",
  });

  const handleSearchReportDetention = ({
    assetsSelected,
    driversSelected,
    fechaDesde,
    fechaHasta,
    assetState,
    minimumTime,
    dias,
  }) => {
    fetchReport({
      variables: {
        assetsSelected: assetsSelected,
        driversSelected: driversSelected,
        fechaInicio: localDateTimeStringToUTC(fechaDesde),
        fechaFin: localDateTimeStringToUTC(fechaHasta),
        detentionStatus: assetState,
        minimumTime: +minimumTime,
        days: dias,
      },
    });
  };

  const { handleSearchReport, errors: filterErrors } = useFilterValidation(
    handleSearchReportDetention
  );

  const {} = useQuery(ASSETS_BYGROUPS_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      groupIDs: selectedGroups.filter(
        (g) => g && !Number.isNaN(Number.parseInt(g))
      ),
      ...assetTableState,
    },
    onCompleted(d) {
      const { items } = d.assetsByGroups;
      setAssetList(items);
    },
  });

  const {} = useQuery(DRIVERS_PAGINATED_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      paginate: false,
    },
    onCompleted(d) {
      setDriverList(d.driversPaginated.items);
    },
  });

  const {} = useQuery(GET_ENUMS_QUERY, {
    variables: {
      name: "DetentionStatus",
    },
    onCompleted(d) {
      setAssetStateList(d.__type.enumValues);
    },
  });

  const [fetchReport] = useLazyQuery(GET_DETENTIONREPORT_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      if (d.detentionReport) setReportResult(d.detentionReport);
      else {
        setReportResult([]);
        setResultState({
          isOpen: true,
          type: "info",
          msg: t("No se encontraron datos para su consulta"),
        });
      }
    },
    onError(err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: err.message,
      });
    },
  });

  const [getExport] = useLazyQuery(DETENTIONREPORT_EXPORT_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      const filename = t("ReporteDetencion");
      switch (exportSelected) {
        case "EXCEL":
          downloadExcel(d.detentionReportExport, filename);
          break;
        case "PDF":
          downloadPDF(d.detentionReportExport, filename);
          break;
        case "KML":
          downloadKML(d.detentionReportExport, filename);
          break;
      }
    },
  });

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setResultState({
      ...resultState,
      isOpen: false,
    });
  };

  const handleExportExcel = (
    assetsSelected,
    driversSelected,
    fechaDesde,
    fechaHasta,
    assetState,
    minimumTime,
    dias
  ) => {
    setExportSelected("EXCEL");
    getExport({
      variables: {
        assetsSelected: assetsSelected,
        driversSelected: driversSelected,
        fechaInicio: localDateTimeStringToUTC(fechaDesde),
        fechaFin: localDateTimeStringToUTC(fechaHasta),
        detentionStatus: assetState,
        minimumTime: +minimumTime,
        days: dias,
        format: "EXCEL",
      },
    });
  };

  const handleExportPDF = (
    assetsSelected,
    driversSelected,
    fechaDesde,
    fechaHasta,
    assetState,
    minimumTime,
    dias
  ) => {
    setExportSelected("PDF");
    getExport({
      variables: {
        assetsSelected: assetsSelected,
        driversSelected: driversSelected,
        fechaInicio: localDateTimeStringToUTC(fechaDesde),
        fechaFin: localDateTimeStringToUTC(fechaHasta),
        detentionStatus: assetState,
        minimumTime: +minimumTime,
        days: dias,
        format: "PDF",
      },
    });
  };

  const handleExportKML = (
    assetsSelected,
    driversSelected,
    fechaDesde,
    fechaHasta,
    assetState,
    minimumTime,
    dias
  ) => {
    setExportSelected("KML");
    getExport({
      variables: {
        assetsSelected: assetsSelected,
        driversSelected: driversSelected,
        fechaInicio: localDateTimeStringToUTC(fechaDesde),
        fechaFin: localDateTimeStringToUTC(fechaHasta),
        detentionStatus: assetState,
        minimumTime: +minimumTime,
        days: dias,
        format: "KML",
      },
    });
  };

  return (
    <Structure>
      <Snackbar
        open={resultState.isOpen}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleCloseNotification}
          severity={resultState.type}
          variant="filled"
        >
          {resultState.msg}
        </MuiAlert>
      </Snackbar>
      <PageTitle sector={t("Estadísticas")} title={t("Detenciones")} />
      <Filters
        assets={assetList}
        drivers={driverList}
        assetStateList={assetStateList}
        searchReport={handleSearchReport}
        handleExportExcel={handleExportExcel}
        handleExportPDF={handleExportPDF}
        handleExportKML={handleExportKML}
        errors={filterErrors}
        reportResult={Array.isArray(reportResult) && reportResult.length > 0}
        user={user}
      />
      {Array.isArray(reportResult) && reportResult.length > 0 && (
        <ReportResult reportData={reportResult} />
      )}
    </Structure>
  );
};

const _mapStateToProps = ({ user, groups }) => {
  return {
    selectedGroups: groups.selectedGroups,
    user: user,
  };
};

export default connect(_mapStateToProps, null)(DetentionReport);
