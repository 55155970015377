import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import { useTranslation } from "../../lang";
import { TABLE_COLORS } from "../../Theme.js";
import Table from "../Base/Table";
import CssTextField from "./../Base/CssTextField";
import "./GenericSelector.scss";

const theme = createMuiTheme({});

const GenericSelector = ({
  data = [],
  columns,
  //isRemote,
  //onSearch,
  //onPaginate,
  //onSort,
  title,
  paginationOptions,
  isMulti,
  keyField,
  labelField,
  callbackOptionSelected,
  limitTags = 2,
  //total,
  defaultValue,
  //selectRowOptions
}) => {
  const [t] = useTranslation();
  const isNestedLabel = labelField.includes(".");
  const fObj = isNestedLabel ? labelField.split(".")[0] : "";
  const sObj = isNestedLabel ? labelField.split(".")[1] : "";

  const autoCompleteOptions = data.map((d) => {
    return {
      label: isNestedLabel ? d[fObj][sObj] : d[labelField],
      value: d[keyField],
    };
  });

  const defaultArray = defaultValue
    ? autoCompleteOptions.filter((x) =>
        isMulti ? defaultValue.includes(x.value) : defaultValue === x.value
      )
    : [];

  let defaultOption =
    Array.isArray(defaultArray) && defaultArray.length > 0
      ? isMulti
        ? defaultArray
        : defaultArray[0]
      : null;

  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => setIsOpen(false);
  const [valueAutoComplete, setValueAutoComplete] = useState(
    defaultOption || (isMulti ? [] : {})
  );

  useEffect(() => {
    if (defaultValue && Array.isArray(data) && data.length > 0) {
      setValueAutoComplete(defaultOption);
    }

    if (!defaultValue && Array.isArray(data) && data.length > 0) {
      setValueAutoComplete(isMulti ? [] : {});
    }
  }, [defaultValue, data]);

  const [selectedOptions, setSelectedOptions] = useState(
    defaultOption || (isMulti ? [] : {})
  );

  const handleSingleSelect = (value, isSelected) => {
    const selected = isSelected
      ? {
          label: value[labelField],
          value: value[keyField],
        }
      : null;
    setSelectedOptions(selected);
  };

  const handleSelectMultiple = (row, isSelected) => {
    const newSelectedOptions = isSelected
      ? [
          ...selectedOptions,
          {
            value: row[keyField],
            label: isNestedLabel ? row[fObj][sObj] : row[labelField],
          },
        ]
      : selectedOptions.filter((x) => x.value !== row[keyField]);

    setSelectedOptions(newSelectedOptions);
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      isMulti
        ? handleSelectMultiple(
            row,
            !selectedOptions.map((s) => s.value).includes(row[keyField])
          )
        : handleSingleSelect(row, true);
    },
  };

  const rowStyle = (row, rowIndex) => {
    const style = {};
    if (
      isMulti
        ? selectedOptions.map((s) => s.value).includes(row[keyField])
        : selectedOptions.value === row[keyField]
    ) {
      style.background = TABLE_COLORS.ROW_SELECTED;
      style.color = TABLE_COLORS.ROW_SELECTED_TEXT;
    }

    return style;
  };

  const handleSelectAll = () => {
    const isAllSelected = data.length === selectedOptions.length;
    setSelectedOptions(isAllSelected ? [] : autoCompleteOptions);
  };

  return (
    <ThemeProvider theme={theme}>
      <Autocomplete
        //style={{ width: "100%" }}
        value={valueAutoComplete || []}
        //value={valueAC}
        multiple={isMulti}
        // freeSolo
        // disableCloseOnSelect
        id={`autocomplete_${title}`}
        //disableClearable
        options={autoCompleteOptions}
        //defaultValue={[autoCompleteOptions[0]]}
        limitTags={limitTags}
        getOptionSelected={(opt, val) => {
          return val && opt && val.value === opt.value;
        }}
        getOptionLabel={(opt) => {
          return opt?.label || "";
        }}
        onChange={(evt, value) => {
          setValueAutoComplete(value);
          callbackOptionSelected(value);
        }}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "clear") {
            setValueAutoComplete(isMulti ? [] : {});
            callbackOptionSelected(null);
          }
        }}
        renderInput={(params) => (
          <CssTextField
            {...params}
            id="navbar-search"
            type="search"
            label={t("buscar_por_titulo", { title })}
            margin="normal"
            color="primary"
            autoComplete="off"
            //onChange={(evt) => isRemote && onSearch && onSearch(evt.target.value)}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setIsOpen(true)}
                      style={{ padding: "3px" }}
                    >
                      <SearchIcon className="SearchIconColor" />
                    </IconButton>
                  </InputAdornment>
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />

      <Dialog
        open={isOpen}
        onClose={handleClose}
        maxWidth="lg"
        className="reportingModal"
      >
        <DialogTitle className="dialogTitle">{title}</DialogTitle>
        <DialogContent className="dialogContent">
          <Table
            columns={columns}
            data={data}
            keyField={keyField}
            paginationOptions={paginationOptions}
            /*selectRowOptions={
              isMulti ? multipleSelectionOption : singleSelectionOptions
            }*/
            rowEvents={rowEvents}
            rowStyle={rowStyle}
            lateralComponent={
              isMulti ? (
                <Button
                  color="primary"
                  variant="contained"
                  style={{ float: "right" }}
                  onClick={handleSelectAll}
                >
                  {t("Seleccionar Todos")}
                </Button>
              ) : undefined
            }
          />
        </DialogContent>
        <DialogActions className="dialogActions">
          <Button
            onClick={() => {
              handleClose();
            }}
            color="primary"
            variant="contained"
          >
            {t("Cancelar")}
          </Button>
          <Button
            onClick={() => {
              setValueAutoComplete(selectedOptions);
              callbackOptionSelected(selectedOptions);
              handleClose();
            }}
            color="primary"
            variant="contained"
          >
            {t("Seleccionar")}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default GenericSelector;
