import { nonExecutableDefinitionMessage } from "graphql/validation/rules/ExecutableDefinitions";
import { APP_COLORS, BASE_COLORS } from "../Theme";

const style = {
  control: (provided) => ({
    ...provided,
    boxShadow: "0!important",
    color: APP_COLORS.APP_BASE_TEXT_COLOR,
    backgroundColor: APP_COLORS.APP_BASE,
    border: `1px solid ${APP_COLORS.APP_BASE_SECONDARY}`,
    // This line disable the blue border
    boxShadow: 0,
    "&:hover": {
      border: `1px solid ${APP_COLORS.APP_BASE_SECONDARY}`,
    },
  }),
  option: (provided, state) => {
    let optionStyle = {
      ...provided,
      color: APP_COLORS.APP_BASE_TEXT_COLOR,
    };

    if (state.isFocused) {
      optionStyle.backgroundColor = "gray";
    }

    if (state.isSelected) {
      optionStyle.backgroundColor = BASE_COLORS.BRAND;
      optionStyle.color = BASE_COLORS.BRAND_CONTRAST;
    }
    return optionStyle;
  },
  singleValue: (provided) => ({
    ...provided,
    color: APP_COLORS.APP_BASE_TEXT_COLOR,
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  menu: (provided) => ({
    ...provided,
    // backgroundColor: "rgba(47, 47, 47, 1)",
    backgroundColor: APP_COLORS.APP_BASE_SECONDARY,
    zIndex: 9999999,
    //overflow: "visible",
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999999,
  }),
  input: (provided) => ({ ...provided, color: APP_COLORS.APP_BASE_TEXT_COLOR }),
};

export default style;
