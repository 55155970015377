import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const SettingIcon = function (props) {
  return (
    <SvgIcon viewBox="0 0 26 26">
      <path
        d="M68.21,50.139l-1.644-.949a9.838,9.838,0,0,0,0-2.465l1.644-.949a1.207,1.207,0,0,0,.554-1.386,12.479,12.479,0,0,0-2.9-5.02,1.21,1.21,0,0,0-1.484-.216l-1.641.948a9.785,9.785,0,0,0-2.134-1.234v-1.9a1.21,1.21,0,0,0-.931-1.178,12.588,12.588,0,0,0-5.785,0,1.21,1.21,0,0,0-.929,1.178v1.9A9.783,9.783,0,0,0,50.826,40.1l-1.641-.948a1.21,1.21,0,0,0-1.484.216,12.479,12.479,0,0,0-2.9,5.02,1.207,1.207,0,0,0,.554,1.386L47,46.725a9.837,9.837,0,0,0,0,2.465l-1.644.95a1.207,1.207,0,0,0-.554,1.386,12.479,12.479,0,0,0,2.9,5.02,1.21,1.21,0,0,0,1.484.216l1.641-.948a9.782,9.782,0,0,0,2.132,1.234v1.9a1.21,1.21,0,0,0,.931,1.178,12.588,12.588,0,0,0,5.785,0,1.21,1.21,0,0,0,.931-1.178v-1.9a9.785,9.785,0,0,0,2.134-1.234l1.641.948a1.21,1.21,0,0,0,1.484-.216,12.479,12.479,0,0,0,2.9-5.02,1.207,1.207,0,0,0-.554-1.386h0ZM64.91,55.2l-2.334-1.348A8.717,8.717,0,0,1,59,55.925v2.7a10.945,10.945,0,0,1-4.422,0v-2.7A8.69,8.69,0,0,1,51,53.855L48.658,55.2a10.887,10.887,0,0,1-2.214-3.831l2.335-1.348a8.777,8.777,0,0,1,0-4.137l-2.335-1.345a10.9,10.9,0,0,1,2.214-3.831l2.334,1.349a8.7,8.7,0,0,1,3.579-2.07V37.3a10.927,10.927,0,0,1,4.422,0v2.7a8.666,8.666,0,0,1,3.579,2.07l2.334-1.349a10.894,10.894,0,0,1,2.214,3.831l-2.335,1.348a8.777,8.777,0,0,1,0,4.137l2.335,1.348a10.887,10.887,0,0,1-2.214,3.831h0ZM56.784,43.12a4.839,4.839,0,1,0,4.839,4.839A4.839,4.839,0,0,0,56.784,43.12Zm0,8.064a3.226,3.226,0,1,1,3.226-3.226A3.226,3.226,0,0,1,56.784,51.184Z"
        transform="translate(-44.752 -35.458)"
      />
    </SvgIcon>
  );
};

export default SettingIcon;
