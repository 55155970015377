import gql from "graphql-tag";

const JOURNEYPOSITIONS_PDF_QUERY = gql`
  query JourneyPositionsPDF(
    $movilID: Int!
    $fechaInicio: Datetime!
    $fechaFin: Datetime!
  ) {
    journeyPositionsPDF(
      movilID: $movilID
      fechaInicio: $fechaInicio
      fechaFin: $fechaFin
    )
  }
`;

export default JOURNEYPOSITIONS_PDF_QUERY;
