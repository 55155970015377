import gql from "graphql-tag";

const DELETE_GUIDE_NUMBER_MUTATION = gql`
  mutation DeleteGuideNumber($id: Int!) {
    deleteGuideNumber(id: $id) {
      id
    }
  }
`;

export default DELETE_GUIDE_NUMBER_MUTATION;
