import gql from "graphql-tag";

const POINT_GUIDENUMBER_QUERY = gql`
  query GetPointGuideNumber($GuideNumber: String) {
    getPoinGuideNumber(GuideNumber: $GuideNumber) {
      lat
      lon
      pat
      nMov
      fGPS
    }
  }
`;

export default POINT_GUIDENUMBER_QUERY;
