import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const GasPumpIcon = function () {
  return (
    <SvgIcon>
      <path d="M24.541,5.859l-4.57-4.575a.392.392,0,0,0-.552,0l-.552.552a.392.392,0,0,0,0,.552l1.445,1.45V7.813a2.344,2.344,0,0,0,2.344,2.344h.781v8.506a1.631,1.631,0,0,1-1.3,1.631,1.564,1.564,0,0,1-1.826-1.543V16.406A3.905,3.905,0,0,0,16.406,12.5h-.781V3.125A3.128,3.128,0,0,0,12.5,0H4.687A3.128,3.128,0,0,0,1.562,3.125V23.438H.391A.392.392,0,0,0,0,23.828v.781A.392.392,0,0,0,.391,25H16.8a.392.392,0,0,0,.391-.391v-.781a.392.392,0,0,0-.391-.391H15.625V14.063h.781a2.344,2.344,0,0,1,2.344,2.344v2.168a3.248,3.248,0,0,0,2.6,3.257A3.128,3.128,0,0,0,25,18.75V6.963A1.56,1.56,0,0,0,24.541,5.859Zm-1.1,2.734h-.781a.784.784,0,0,1-.781-.781V5.4l1.562,1.563ZM14.062,23.438H3.125v-12.5H14.062Zm0-14.063H3.125V3.125A1.567,1.567,0,0,1,4.687,1.563H12.5a1.567,1.567,0,0,1,1.562,1.563Z" />
    </SvgIcon>
  );
};

export default GasPumpIcon;
