import React, { useState, useEffect } from "react";

import { Grid, TextField } from "@material-ui/core";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";

import Select from "react-select";

import CustomSelectStyle from "../../utils/reactSelectStyle";

import "./RemoteTable.scss";

import { useTranslation } from "../../lang";

const theme = createMuiTheme({});

const { SearchBar } = Search;

const RemoteTable = ({
  columns,
  data,
  keyField,
  onSearch,
  onPaginate,
  onSort,
  selectRowOptions,
  total,
  paginationOptions,
  lateralComponent,
}) => {
  const [t] = useTranslation();
  const [currentPage, setCurrentPage] = useState({
    label: paginationOptions.sizePerPageList[0].toString(),
    value: paginationOptions.sizePerPageList[0],
  });

  const [textoToSearch, setTextoToSearch] = useState(null);

  const handleTableChange = (
    type,
    { page, sizePerPage, searchText, sortField, sortOrder }
  ) => {
    // setTimeout(() => {
    switch (type) {
      case "search":
        //onSearch && onSearch(searchText);
        setTextoToSearch(searchText);
        break;
      case "pagination":
        onPaginate && onPaginate(page, sizePerPage);
        break;
      case "sort":
        let sortObj = {};
        sortObj[sortField] = sortOrder;
        onSort && onSort(sortObj);
        break;
    }
    // }, 500);
  };

  const highlightText = (value, mark) => {
    const tableBody = document.getElementsByClassName("remoteTableBody")[0];
    if (!tableBody) return;

    const tds = tableBody.getElementsByTagName("td");
    for (let td of tds) {
      const text = td.innerText;
      if (
        text &&
        value &&
        text.toLocaleLowerCase().includes(value.toLocaleLowerCase())
      ) {
        const newText = text.replace(
          new RegExp(value, "gi"),
          (match) => `<mark>${match}</mark>`
        );

        td.innerHTML = newText;
      }

      if (!value && text) {
        // console.log("Text: ", text);
        td.innerHTML = text;
      }
    }
  };

  useEffect(() => {
    if (textoToSearch === "") {
      onSearch && onSearch(textoToSearch);
      highlightText(textoToSearch);
    } else if (textoToSearch && textoToSearch.length >= 3) {
      const timeout = setTimeout(() => {
        onSearch && onSearch(textoToSearch);
        highlightText(textoToSearch, true);
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [textoToSearch]);

  const pageButtonRenderer = ({ page, active, onPageChange }) => {
    const handleClick = (e) => {
      e.preventDefault();
      onPageChange(page);
    };
    const activeStyle = {};
    if (active) {
      activeStyle.backgroundColor = "black";
      activeStyle.color = "white";
    } else {
      activeStyle.backgroundColor = "gray";
      activeStyle.color = "black";
    }
    if (typeof page === "string") {
      activeStyle.backgroundColor = "white";
      activeStyle.color = "black";
    }
    return (
      <li className="page-item">
        <a href="#" onClick={handleClick} style={activeStyle}>
          {page}
        </a>
      </li>
    );
  };

  const paginationTotalRenderer = (from, to, size) => (
    <span
      className="react-bootstrap-table-pagination-total customTotalDisplay"
      style={{ marginLeft: "15px" }}
    >
      {t("base_remote_table_mostrar_resultados", { from, to, size })}
    </span>
  );

  const pageListRenderer = ({ pages, onPageChange }) => {
    return (
      <div style={{ paddingTop: "7px" }}>
        {pages.map((p, index) => (
          <button
            className={`btnCustomPagination ${
              p.active && "btnCustomPaginationActive"
            }`}
            onClick={() => onPageChange(p.page)}
            key={index}
          >
            {p.page}
          </button>
        ))}
      </div>
    );
  };

  const sizePerPageRenderer = ({ options, onSizePerPageChange }) => {
    return (
      <div className="pageSizeContainerSelector">
        <Select
          value={currentPage}
          options={options.map((opt) => ({ label: opt.text, value: opt.page }))}
          onChange={(opt) => {
            setCurrentPage(opt);
            onSizePerPageChange(opt.value);
          }}
          styles={CustomSelectStyle}
        />
      </div>
    );
  };

  const optionsPagination = {
    ...paginationOptions,
    pageListRenderer,
    pageButtonRenderer,
    sizePerPageRenderer,
    paginationTotalRenderer,
  };

  return (
    <ToolkitProvider
      keyField={keyField}
      data={data || []}
      columns={columns}
      search
    >
      {(props) => (
        <div>
          <Grid container>
            {onSearch && (
              <Grid item lg={6} style={{ marginBottom: "10px" }}>
                <SearchBar
                  {...props.searchProps}
                  placeholder={t("Buscar")}
                  style={{ maxWidth: "430px" }}
                  className="customSearchTable"
                />
              </Grid>
            )}
            {lateralComponent && (
              <>
                <Grid item lg={6}>
                  <>{lateralComponent}</>
                </Grid>
                <hr />
              </>
            )}
            <BootstrapTable
              {...props.baseProps}
              remote
              //striped
              //hover
              //condensed
              bootstrap4
              headerClasses="thead-light"
              selectRow={selectRowOptions}
              pagination={paginationFactory(optionsPagination)}
              totalSize={total}
              onTableChange={handleTableChange}
              headerClasses="remoteTableHeader"
              bodyClasses="remoteTableBody"
              //filter={filterFactory()}
            />
          </Grid>
        </div>
      )}
    </ToolkitProvider>
  );
};

export default RemoteTable;
