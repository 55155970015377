import { combineReducers } from "redux";

import {
  UPDATE_ACTIVE_PANEL,
  UPDATE_ACTIVE_PANEL_COMPONENT,
  UPDATE_ACTIVE_PANEL_AUX,
  UPDATE_ACTIVE_PANEL_AUX_COMPONENT,
  TAB_CHANGED,
  UPDATE_PANEL_TYPE,
  OPEN_PANEL,
  OPEN_PANELAUX,
  CLOSE_PANEL,
  CLOSE_PANELAUX,
  CHANGE_PANELTYPE,
  CHANGE_PANELAUXTYPE,
  CHANGE_PANELAUXTITLE,
  SEE_ALL_FLEET,
  SET_MID_SELECTED,
  SET_ASSET_LIST,
  SET_REVISIONNOTICE_SELECTED,
  SET_REVISION_SELECTED,
  SET_REVISIONNOTICE_LIST,
  SET_REVISION_LIST,
  SET_JOURNEY_REPORT_PANEL_INITIAL_STATUS,
  SET_FLEET_STATUS_PANEL_INITIAL_STATUS,
} from "./actions";

// import {
//   UPDATE_MARKER_INFO
// } from "../fillMarkerInfo/actions";

const isPanelOpen = (state = false, { type, payload }) => {
  let nextState = state;

  if (type === UPDATE_ACTIVE_PANEL) {
    nextState = payload;
  }

  return nextState;
};

const panelType = (state = null, { type, payload }) => {
  let nextState = state;

  if (type === UPDATE_PANEL_TYPE) {
    nextState = payload || "";
  }

  return nextState;
};

const isPanelAuxOpen = (state = false, { type, payload }) => {
  let nextState = state;

  if (type === UPDATE_ACTIVE_PANEL_AUX) {
    nextState = payload;
  } else if (type === TAB_CHANGED) {
    nextState = false;
  }

  return nextState;
};

const panelInfo = (state = {}, { type, payload }) => {
  let nextState = state;

  if (type === UPDATE_ACTIVE_PANEL_COMPONENT) {
    nextState = payload;
  }

  return nextState;
};

const panelAuxInfo = (state = {}, { type, payload }) => {
  let nextState = state;

  if (type === UPDATE_ACTIVE_PANEL_AUX_COMPONENT) {
    nextState = payload;
  }

  return nextState;
};

const initialInfo = {
  isPanelOpen: false,
  isPanelAuxOpen: false,
  panelType: "",
  tabActive: "",
  panelAuxType: "",
  panelAuxTitle: "",
  allFleetIsVisible: true,
  mid: "",
  assetList: [],
  revisionNoticeSelected: null,
  revisionSelected: null,
  revisionNoticeList: [],
  revisionList: [],
};

const newPanelReducer = (state = initialInfo, { type, payload }) => {
  switch (type) {
    case OPEN_PANEL:
      return {
        ...state,
        isPanelOpen: true,
      };
    case SET_REVISION_LIST:
      return {
        ...state,
        revisionList: payload,
      };
    case SET_JOURNEY_REPORT_PANEL_INITIAL_STATUS:
      return {
        ...state,
        journeyReportPanelConnectedStatus: payload,
      };
    case SET_FLEET_STATUS_PANEL_INITIAL_STATUS:
      return {
        ...state,
        fleetStatusPanelInitialStatus: payload,
      };
    case SET_REVISIONNOTICE_LIST:
      return {
        ...state,
        revisionNoticeList: payload,
      };
    case SET_MID_SELECTED:
      return {
        ...state,
        mid: payload,
      };
    case SET_REVISIONNOTICE_SELECTED: {
      return {
        ...state,
        revisionNoticeSelected: payload,
      };
    }
    case SET_REVISION_SELECTED: {
      return {
        ...state,
        revisionSelected: payload,
      };
    }
    case SET_ASSET_LIST:
      return {
        ...state,
        assetList: payload,
      };
    case SEE_ALL_FLEET:
      return {
        ...state,
        allFleetIsVisible: payload,
        panelAuxTitle: payload ? "" : state.panelAuxTitle,
      };
    case OPEN_PANELAUX:
      return {
        ...state,
        isPanelAuxOpen: true,
      };
    case CLOSE_PANEL:
      return {
        ...state,
        isPanelOpen: false,
        isPanelAuxOpen: false,
        tabActive: 0,
        panelType: "GeneralInfo",
      };
    case CLOSE_PANELAUX:
      return {
        ...state,
        isPanelAuxOpen: false,
      };
    case CHANGE_PANELTYPE:
      return {
        ...state,
        panelType: payload,
        isPanelAuxOpen: false,
        //panelAuxType: payload === "DrivingBehavior" ? "DrivingBehaviorChartPanel" : state.panelAuxType,
        //isPanelAuxOpen: payload === "DrivingBehavior" ? true : state.isPanelAuxOpen
      };
    case CHANGE_PANELAUXTYPE:
      return {
        ...state,
        panelAuxType: payload,
      };
    case TAB_CHANGED:
      return {
        ...state,
        tabActive: payload,
        isPanelAuxOpen: payload === 0 ? state.isPanelAuxOpen : false,
      };
    case CHANGE_PANELAUXTITLE:
      return {
        ...state,
        panelAuxTitle: payload,
      };
    default:
      return state;
  }
};

// const fillMarkerInfo = (markerInfo = {}, { type, payload }) => {
//   let newMarkerInfo = markerInfo;
//   if (type === UPDATE_MARKER_INFO) {
//     newMarkerInfo = payload;
//   }

//   return newMarkerInfo;
// };

export default combineReducers({
  isPanelOpen,
  isPanelAuxOpen,
  panelInfo,
  panelAuxInfo,
  panelType,
  newPanelReducer,
  // fillMarkerInfo
});
