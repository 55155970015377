import gql from "graphql-tag";

const EVENTREPORT_EXPORT_QUERY = gql`
  query EventReportExport(
    $assetsSelected: [Int]!
    $driversSelected: [Int]
    $typeEventSelected: [Int]
    $fechaInicio: Datetime
    $fechaFin: Datetime
    $days: [WeekDaysEnum]
    $format: FormatEnum
  ) {
    eventReportExport(
      assetsSelected: $assetsSelected
      driversSelected: $driversSelected
      typeEventSelected: $typeEventSelected
      fechaInicio: $fechaInicio
      fechaFin: $fechaFin
      days: $days
      format: $format
    )
  }
`;

export default EVENTREPORT_EXPORT_QUERY;
