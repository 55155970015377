import gql from "graphql-tag";

const TECHNICIANS_QUERY = gql`
  query Technicians {
    technicians {
      id
      code
      firstname
      lastname
      email
      phoneNumber
      mobilePhone
      customerId
    }
  }
`;

export default TECHNICIANS_QUERY;
