import gql from "graphql-tag";

const SYNC_REAXIUM_DEVICES_MUTATION = gql`
  mutation SyncReaxiumDevices(
    $businessId: Int
    $customerId: Int
    $userId: Int
  ) {
    syncReaxiumDevices(
      businessId: $businessId
      customerId: $customerId
      userId: $userId
    )
  }
`;

export default SYNC_REAXIUM_DEVICES_MUTATION;
