import React from "react";

import Table from "../../../components/Base/Table";

import { useTranslation } from "../../../lang";

const ReportResult = ({ reportData }) => {
  const [t] = useTranslation();
  //console.log(reportData);
  const columns = [
    {
      dataField: "name",
      text: t("Parada"),
      sort: true,
      headerStyle: () => ({ width: "15%" }),
      footer: () => "",
    },
    {
      dataField: "status",
      text: t("Estado"),
      sort: true,
      headerStyle: () => ({ width: "15%" }),
      footer: () => "",
    },
    {
      dataField: "expectedEnterTime",
      text: t("Horario esperado de llegada"),
      sort: true,
      headerStyle: () => ({ width: "15%" }),
      footer: () => "",
    },
    {
      dataField: "actualEnterTime",
      text: t("Horario real de llegada"),
      sort: true,
      headerStyle: () => ({ width: "15%" }),
      footer: () => "",
    },
    {
      dataField: "actualExitTime",
      text: t("Horario real de salida"),
      sort: true,
      headerStyle: () => ({ width: "15%" }),
      footer: () => "",
    },
    {
      dataField: "timeInStop",
      text: `${t("Tiempo en parada")} [min] (${"aprox"})`,
      sort: true,
      headerStyle: () => ({ width: "15%" }),
      footer: (columnData) => {
        console.log("columnData", columnData);
        const numbersData = columnData.filter((x) => typeof x === "number");
        const sum = numbersData.reduce((a, b) => a + b, 0);
        const avg = (sum / columnData.length).toFixed(2) || 0;
        return (
          <div style={{ padding: "12px" }}>
            {t("Prom")}: {avg}
          </div>
        );
      },
    },
  ];

  return (
    <div style={{ padding: "20px" }}>
      <Table columns={columns} data={reportData} keyField="id" />
    </div>
  );
};

export default ReportResult;
