import React, { useState } from "react";

import GenericSelector from "../../../components/Selectors/GenericSelector";
import { Grid, Button, IconButton } from "@material-ui/core";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { format } from "date-fns";

import DateSelector from "../../../components/Selectors/DateSelector";

import IconExcel from "../../../public/iconExcel.png";
import IconPDF from "../../../public/iconPDF.png";

import { useTranslation } from "../../../lang";

const dateFormat = "yyyy-MM-dd HH:mm:ss";

const theme = createMuiTheme({});

const Filter = ({
  assets,
  searchReport,
  handleExportExcel,
  handleExportPDF,
  errors,
  reportResult,
}) => {
  const [t] = useTranslation();
  const [fechas, setFechas] = useState({
    fechaDesde: format(new Date(new Date() - 60 * 60 * 1000), dateFormat),
    fechaHasta: format(new Date(), dateFormat),
  });

  const [dias, setDias] = useState([
    "LUNES",
    "MARTES",
    "MIERCOLES",
    "JUEVES",
    "VIERNES",
    "SABADO",
    "DOMINGO",
  ]);

  const [assetsSelected, setAssetsSelected] = useState();

  const callbackAssetSelected = (values) => {
    setAssetsSelected(values?.map((v) => v.value));
  };

  const columnsAssets = [
    {
      dataField: "name",
      text: t("Nombre"),
      sort: true,
    },
    {
      dataField: "licensePlate",
      text: t("Patente"),
      sort: true,
    },
    {
      dataField: "internalNumber",
      text: t("Interno"),
      sort: true,
    },
    {
      dataField: "driver.firstName",
      text: t("Chofer"),
      sort: true,
    },
    {
      dataField: "typeMobile.description",
      text: t("Tipo"),
      sort: true,
    },
  ];

  const handleChangePeriod = (evt) => {
    if (evt.fechaDesde && evt.fechaHasta) {
      setFechas({
        fechaDesde: evt.fechaDesde,
        fechaHasta: evt.fechaHasta,
      });
    }

    if (evt.dias) setDias(evt.dias);
  };

  return (
    <div style={{ padding: "20px" }}>
      <ThemeProvider theme={theme}>
        <Grid container>
          <Grid item xs={5}>
            <DateSelector onChange={handleChangePeriod} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={5}>
            <GenericSelector
              callbackOptionSelected={callbackAssetSelected}
              columns={columnsAssets}
              data={assets}
              keyField="id"
              labelField="licensePlate"
              title={t("Móviles")}
              isMulti
            />
            {errors && errors.assets && (
              <div className="input-feedback">{errors.assets}</div>
            )}
          </Grid>
        </Grid>
        <Grid container className="filterReportButtons">
          <Button
            color="primary"
            variant="contained"
            onClick={() =>
              searchReport({
                assetsSelected,
                fechaInicio: fechas.fechaDesde,
                fechaFin: fechas.fechaHasta,
                dias,
                validations: [
                  {
                    field: assetsSelected,
                    fieldName: "assets",
                    errorMessage: t("Debe seleccionar al menos un móvil."),
                    // validationType: "required",
                  },
                ],
              })
            }
          >
            {t("Generar Reporte")}
          </Button>
          {reportResult && (
            <div style={{ marginLeft: "auto" }}>
              <IconButton
                title={t("EXCEL")}
                onClick={() =>
                  handleExportExcel(
                    assetsSelected,
                    fechas.fechaDesde,
                    fechas.fechaHasta,
                    dias
                  )
                }
              >
                <img src={IconExcel} className="exportIcon" />
              </IconButton>
              <IconButton
                title={t("PDF")}
                onClick={() =>
                  handleExportPDF(
                    assetsSelected,
                    fechas.fechaDesde,
                    fechas.fechaHasta,
                    dias
                  )
                }
              >
                <img src={IconPDF} className="exportIcon" />
              </IconButton>
            </div>
          )}
        </Grid>
      </ThemeProvider>
    </div>
  );
};

export default Filter;
