import React from "react";

const Icon4x4 = () => {
  return (
    <div style={{ justifyContent: "center" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="514.143"
        height="514.143"
        x="0"
        y="0"
        enableBackground="new 0 0 514.143 514.143"
        version="1.1"
        viewBox="0 0 514.143 514.143"
        xmlSpace="preserve"
      >
        <path d="M508.582 259.261c-4.285 0-9.525-3.786-11.686-8.434l-8.416-18.025c-2.17-4.647-8.08-8.826-13.186-9.313L364.35 212.864c-5.115-.498-11.686-4.266-14.688-8.444l-40.314-56.199c-2.994-4.179-9.582-7.554-14.727-7.554h-68.994c-5.135 0-11.379-2.343-13.952-5.24-2.572-2.888-8.816-5.24-13.952-5.24H70.992c-5.135 0-10.662 3.949-12.345 8.797L45.9 175.886c-1.587-5.833-10.643-10.318-21.688-10.318h-2.2C9.859 165.568 0 175.408 0 187.581v57.671c0 12.029 4.341 21.735 9.715 21.975-1.578 3.175-2.687 7.182-2.687 10.634v13.292c0 5.135 4.169 9.304 9.304 9.304h29.042c2.562 0 5.9.153 9.237.393-1.587 5.642-2.496 11.57-2.496 17.719 0 36.108 29.28 65.389 65.378 65.389 36.108 0 65.379-29.28 65.379-65.389 0-.028-.009-.066-.009-.104 1.597.066 3.098.104 4.389.104H359.34c.641 0 1.338-.019 2.064-.028v.028c0 36.108 29.271 65.389 65.379 65.389 36.109 0 65.379-29.28 65.379-65.389 0-5.526-.764-10.862-2.055-15.998l4.121.163c5.135.182 10.461-3.672 11.904-8.597l7.574-25.934c1.454-4.945-.851-8.942-5.124-8.942zm-325.699-55.797H56.199l14.363-51.169h112.321v51.169zm31.9 3.863l-.201-48.864h80.621l35.928 48.864H214.783z"></path>
      </svg>
    </div>
  );
};

export default React.memo(Icon4x4);
