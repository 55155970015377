import React, { useState } from "react";
import { connect } from "react-redux";

// import ListNotifications from "./ListNotifications";
import ListNotificationsPaginated from "./ListNotificationsPaginated";
import Filter from "./Filter";

import {
  getNotificationsPaginated,
  getTypeNotifications,
  setMarkerSelected,
  ackAlarms,
  selectNotification,
  unselectNotification,
  readNotifications,
  selectAllNotification,
  unSelectAllNotification,
} from "../../redux/notifications/actions";

import "./NotificationPanel.scss";

const NotificationPanel = ({
  total,
  notifications,
  typeNotifications,
  getNotificationsPaginated,
  getTypeNotifications,
  setMarkerSelected,
  hasMoreNotifications,
  assetid,
  ackAlarms,
  selectedNotifications,
  selectNotification,
  unselectNotification,
  cantUnACKHardware,
  cantUnACKSoftware,
  cantUnACKWithoutTransmision,
  // readNotifications,
  loggedUser,
  selectAllNotifications,
  unSelectAllNotifications,
}) => {
  //const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
  const soundStorage = localStorage.getItem(`soundActivated_${loggedUser.id}`);
  const typeStorage = localStorage.getItem(`typeNotification_${loggedUser.id}`);
  const typeAlarmStorage = localStorage.getItem(`typeAlarm_${loggedUser.id}`);
  const eventStorage = localStorage.getItem(`event_${loggedUser.id}`);
  const onlyUnreadedStorage = localStorage.getItem(
    `onlyUnreaded${loggedUser.id}`
  );

  const [pageSize, setPageSize] = useState(10);

  const [soundActivated, setSoundActivated] = useState(
    !soundStorage || soundStorage === "true"
  );

  const [typeNotificationSel, setTypeNotificationSel] = useState(
    typeStorage && parseInt(typeStorage, 10)
  );
  const [onlyUnreaded, setOnlyUnreaded] = useState(
    onlyUnreadedStorage === true
  );
  // const [typeAlarm, setTypeAlarm] = useState(typeAlarmStorage || -1);
  const [typeAlarm, setTypeAlarm] = useState(-1);
  const [event, setEvent] = useState(eventStorage || -1);

  const handleChangeSound = () => {
    const activated = !soundActivated;
    setSoundActivated(activated);
    localStorage.setItem(`soundActivated_${loggedUser.id}`, activated);
  };

  const handleChangeTypeNotification = (typeNotificationId) => {
    setTypeNotificationSel(typeNotificationId);
    localStorage.setItem(
      `typeNotification_${loggedUser.id}`,
      typeNotificationId
    );

    if (typeNotificationId !== 1) setTypeAlarm(-1);

    // Reset event filter
    if (typeNotificationId !== 10) {
      setEvent(null);
      localStorage.removeItem(`event_${loggedUser.id}`);
    }

    getNotificationsPaginated({
      typeNotification:
        typeNotificationId === -1 ? undefined : typeNotificationId,
      onlyUnreaded: onlyUnreaded,
      typeAlarm:
        typeNotificationId !== 1 ||
        typeAlarm === -1 ||
        (Array.isArray(typeAlarm) && typeAlarm.includes("-1"))
          ? undefined
          : [typeAlarm],
      assetid: assetid,
      event:
        typeNotificationId !== 10 ? null : event === -1 ? undefined : event,
      pageSize: pageSize,
      pageNumber: 1,
    });
  };

  const handleChangeOnlyReaded = (checked) => {
    setOnlyUnreaded(checked);
    localStorage.setItem(`onlyUnreaded${loggedUser.id}`, checked);

    getNotificationsPaginated({
      typeNotification:
        typeNotificationSel === -1 ? undefined : typeNotificationSel,
      onlyUnreaded: checked,
      typeAlarm:
        typeNotificationSel !== 1 || typeAlarm == -1 ? undefined : [typeAlarm],
      assetid: assetid,
      event: event === -1 ? undefined : event,
      pageSize: pageSize,
      pageNumber: 1,
    });
  };

  const handleChangeTypeAlarm = (type) => {
    const typeAlarmFilter = type !== -1 ? type : undefined;

    setTypeAlarm(type);
    localStorage.setItem(`typeAlarm_${loggedUser.id}`, type);

    getNotificationsPaginated({
      typeNotification: typeAlarmFilter ? 1 : typeNotificationSel,
      onlyUnreaded: onlyUnreaded,
      typeAlarm: typeAlarmFilter !== -1 ? typeAlarmFilter : undefined,
      assetid: assetid,
      event: event === -1 ? undefined : event,
      pageSize: pageSize,
      pageNumber: 1,
      // lastId: notifications && notifications[0].id,
    });
  };

  const handleChangeEvent = (eventSel) => {
    setEvent(eventSel);
    localStorage.setItem(`event_${loggedUser.id}`, eventSel);

    getNotificationsPaginated({
      typeNotification: typeNotificationSel,
      onlyUnreaded: onlyUnreaded,
      event:
        typeNotificationSel !== 10 ||
        eventSel === "Todos" ||
        !eventSel ||
        eventSel.length <= 0
          ? undefined
          : eventSel,
      assetid: assetid,
      pageSize: pageSize,
      pageNumber: 1,
    });
  };

  const clearFilters = () => {
    setTypeNotificationSel(-1);
    setOnlyUnreaded(false);
    localStorage.setItem(`onlyUnreaded${loggedUser.id}`, false);
    localStorage.setItem(`typeNotification_${loggedUser.id}`, -1);

    getNotificationsPaginated({
      typeNotification: undefined,
      onlyUnreaded: false,
      typeAlarm: undefined,
      event: undefined,
      assetid: assetid,
      pageSize: pageSize,
      pageNumber: 1,
    });
  };

  return (
    <>
      <Filter
        typeNotifications={typeNotifications}
        getTypeNotifications={getTypeNotifications}
        typeSel={typeNotificationSel}
        setTypeSel={handleChangeTypeNotification}
        onlyUnreaded={onlyUnreaded}
        setOnlyUnreaded={handleChangeOnlyReaded}
        typeAlarm={typeAlarm}
        setTypeAlarm={handleChangeTypeAlarm}
        setEvent={handleChangeEvent}
        event={event}
        soundActivated={soundActivated}
        setSoundActivated={handleChangeSound}
        cantUnACKHardware={cantUnACKHardware}
        cantUnACKSoftware={cantUnACKSoftware}
        cantUnACKWithoutTransmision={cantUnACKWithoutTransmision}
        readNotifications={() => {
          const filters = {
            typeNotification:
              typeNotificationSel === -1 ? undefined : typeNotificationSel,
            onlyUnreaded: onlyUnreaded,
            event:
              typeNotificationSel !== 10
                ? null
                : event === -1
                ? undefined
                : event,
            assetid: assetid,
            pageSize: pageSize,
            pageNumber: 1,
            typeAlarm:
              typeAlarm === -1 ||
              (Array.isArray(typeAlarm) && typeAlarm.includes("-1"))
                ? undefined
                : [typeAlarm],
          };
          ackAlarms(selectedNotifications, filters);
          // readNotifications(selectedNotifications, filters);
        }}
        selectedNotifications={selectedNotifications}
        clearFilters={clearFilters}
        loggedUser={loggedUser}
        selectAllNotifications={selectAllNotifications}
        unSelectAllNotifications={unSelectAllNotifications}
      />
      <ListNotificationsPaginated
        notificationList={notifications}
        //notificationList={fakeNotifications}
        getNotificationsPaginated={getNotificationsPaginated}
        typeSel={typeNotificationSel}
        onlyUnreaded={onlyUnreaded}
        typeAlarm={typeAlarm}
        hasMoreNotifications={hasMoreNotifications}
        assetid={assetid}
        setMarkerSelected={setMarkerSelected}
        ackAlarms={(notificationId) => {
          const filters = {
            typeNotification:
              typeNotificationSel === -1 ? undefined : typeNotificationSel,
            onlyUnreaded: onlyUnreaded,
            event:
              typeNotificationSel !== 10
                ? null
                : event === -1
                ? undefined
                : event,
            assetid: assetid,
            pageSize: pageSize,
            pageNumber: 1,
            typeAlarm:
              typeAlarm === -1 ||
              (Array.isArray(typeAlarm) && typeAlarm.includes("-1"))
                ? undefined
                : [typeAlarm],
          };
          ackAlarms(notificationId, filters);
        }}
        selectNotification={selectNotification}
        unselectNotification={unselectNotification}
        selectedNotifications={selectedNotifications}
        loggedUser={loggedUser}
        pageSize={pageSize}
        total={total}
      />
    </>
  );
};

const _mapStateToProps = ({ notifications, user }) => {
  return {
    notifications: notifications.notificationsPaginated,
    total: notifications.totalNotifications,
    typeNotifications: notifications.typeNotifications,
    hasMoreNotifications: notifications.hasMoreNotifications,
    selectedNotifications: notifications.selectedNotifications,
    // cantUnreadAlarms: notifications.topNotifications.cantUnreadAlarms,
    // cantUnreadReport: notifications.topNotifications.cantUnreadReport,
    // cantUnreadMaintence: notifications.topNotifications.cantUnreadMaintence,
    cantUnACKHardware: notifications.topNotifications.cantUnACKHardware,
    cantUnACKSoftware: notifications.topNotifications.cantUnACKSoftware,
    cantUnACKWithoutTransmision:
      notifications.topNotifications.cantUnACKWithoutTransmision,
    loggedUser: user,
  };
};

const _mapDispatchToProps = (dispatch) => {
  return {
    getNotificationsPaginated: (params) =>
      getNotificationsPaginated(params)(dispatch),
    getTypeNotifications: () => getTypeNotifications()(dispatch),
    setMarkerSelected: (marker) => dispatch(setMarkerSelected(marker)),
    ackAlarms: (notifications, filters) =>
      ackAlarms(notifications, filters)(dispatch),
    selectNotification: (messageId) => dispatch(selectNotification(messageId)),
    unselectNotification: (messageId) =>
      dispatch(unselectNotification(messageId)),
    readNotifications: (messages, filters) =>
      readNotifications(messages, filters)(dispatch),
    selectAllNotifications: (notifications) =>
      dispatch(selectAllNotification(notifications)),
    unSelectAllNotifications: () => dispatch(unSelectAllNotification()),
  };
};

export default connect(
  _mapStateToProps,
  _mapDispatchToProps
)(NotificationPanel);
