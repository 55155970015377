import gql from "graphql-tag";

const PLANNING_REPORTS_QUERY = gql`
  query PlanningReport {
    planningReport {
      id
      name
      typeReportID
      customerId
      customer {
        name
      }
      userCreatorId
      formatId
      enabled
      mailList
      mailListCco
      datePeriod
      allMovilesActivos
      userCreator {
        id
        firstName
        lastName
        userName
      }
      assets {
        id
        name
        licensePlate
        internalNumber
        typeMobile {
          id
          description
        }
      }
      usersDestinations {
        id
        firstName
        lastName
        userName
        email
      }
    }
  }
`;

export default PLANNING_REPORTS_QUERY;
