import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Button, Card } from "@material-ui/core";

import { useHistory } from "react-router";

import { ReduxTextField } from "../Base/ReduxFormFields";
import { useTranslation } from "../../lang";

const Form = ({ handleSearchGuidNumber }) => {
  const [t] = useTranslation();
  const history = useHistory();

  const [guideNumber, setGuideNumber] = useState("");
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);

  const handleChangeRecaptcha = (value) => {
    setIsCaptchaValid(true);
  };

  return (
    <Card className="login-container" style={{ width: "370px" }}>
      <h4 style={{ color: "white" }}>{t("Seguimiento de Cargas")}</h4>

      <ReduxTextField
        label={t("Número Guía")}
        //placeholder={placeholder}
        fullWidth
        value={guideNumber}
        margin="normal"
        onChange={(evt) => setGuideNumber(evt.target.value)}
      />

      <ReCAPTCHA
        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
        onChange={handleChangeRecaptcha}
      />

      <Button
        type="button"
        color="primary"
        variant="contained"
        onClick={() => {
          if (!isCaptchaValid) return;

          if (handleSearchGuidNumber) {
            handleSearchGuidNumber(guideNumber);
            setIsCaptchaValid(false);
          } else {
            history.push("/cargoTracking", {
              guideNumber: guideNumber,
              isCaptchaValid: isCaptchaValid,
            });
          }
        }}
      >
        {t("Buscar Número de Guía")}
      </Button>
    </Card>
  );
};

export default Form;
