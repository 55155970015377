import {
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { Field, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import { useTranslation } from "../../../lang";

const theme = createMuiTheme({});

const RoleForm = ({ setIsOpen, createRole, selectedRole, updateRole }) => {
  const [t] = useTranslation();
  const RoleCategories = {
    ADMINISTRATOR: 1,
    CUSTOMER_ADMINISTRATOR: 2,
    OPERATOR: 3,
  };

  const initialValues = {
    id: selectedRole?.id,
    name: selectedRole?.name,
    categoryId: selectedRole?.categoryId,
  };

  const yupSchema = Yup.object().shape({
    name: Yup.string().required(t("Requerido")),
    categoryId: Yup.number().required(t("Requerido")),
  });

  return (
    <>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={yupSchema}
        onSubmit={async (values) => {
          if (selectedRole) {
            updateRole({
              variables: { ...values, categoryId: +values.categoryId },
            });
          } else {
            createRole({
              variables: { ...values, categoryId: +values.categoryId },
            });
          }
        }}
      >
        {(props) => {
          const { values, errors, handleSubmit, setFieldValue } = props;

          return (
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <label className="formLabelFormik">{t("Rol")}</label>
                <Field
                  type="text"
                  name="name"
                  tabIndex="0"
                  className={
                    errors.name
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.name && (
                  <div className="input-feedback">{errors.name}</div>
                )}
              </FormGroup>

              <FormGroup>
                <ThemeProvider theme={theme}>
                  <RadioGroup
                    aria-label="category-label"
                    name="category"
                    value={values?.categoryId?.toString()}
                    onChange={(evt) => {
                      setFieldValue("categoryId", evt.currentTarget.value);
                    }}
                  >
                    <FormControlLabel
                      value={RoleCategories.ADMINISTRATOR.toString()}
                      control={<Radio color="primary" />}
                      label={t("Administrador Plataforma")}
                    />
                    <FormControlLabel
                      value={RoleCategories.CUSTOMER_ADMINISTRATOR.toString()}
                      control={<Radio color="primary" />}
                      label={t("Administrador Cliente")}
                    />
                    <FormControlLabel
                      value={RoleCategories.OPERATOR.toString()}
                      control={<Radio color="primary" />}
                      label={t("Operador")}
                    />
                    {errors.categoryId && (
                      <div className="input-feedback">{errors.categoryId}</div>
                    )}
                  </RadioGroup>
                </ThemeProvider>
              </FormGroup>

              <Grid item lg={12} style={{ marginTop: "50px" }}>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  tabIndex="0"
                >
                  {selectedRole ? t("Actualizar") : t("Crear")}
                </Button>
                <Button
                  variant="contained"
                  type="button"
                  tabIndex="0"
                  style={{ marginLeft: "20px" }}
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  {t("Cancelar")}
                </Button>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default RoleForm;
