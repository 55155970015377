import React, { useLayoutEffect, useRef } from "react";
import { connect } from "react-redux";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import darkTheme from "@amcharts/amcharts4/themes/dark";
import { useTranslation } from "../../lang";
import { GetDateFormat } from "../../utils/generalUtils";

am4core.useTheme(darkTheme);
am4core.useTheme(am4themes_animated);

const ConductionTimeChart = ({ data, user }) => {
  const [t] = useTranslation();
  const chart = useRef(null);

  //Ver diferencias entre useEffect y useLayoutEffect
  //useLayoutEffect se dispara de forma sincronica despues de las mutations del DOM
  useLayoutEffect(() => {
    if (chart.current) {
      chart.current.series.each((serie) => {
        serie.data = data;
      });
    }
  }, [data]);

  useLayoutEffect(() => {
    let x = am4core.create("conductionTimeChartContainer", am4charts.XYChart);

    const title = x.titles.create();
    title.text = t("Tiempo de Conducción");
    title.fontSize = 25;
    title.marginBottom = 30;
    title.align = "left";

    //Create axis
    x.xAxes.push(new am4charts.DateAxis());
    x.yAxes.push(new am4charts.ValueAxis());

    x.dateFormatter = new am4core.DateFormatter();
    x.dateFormatter.dateFormat = GetDateFormat(user.language, true);

    //Fleet Score serie
    let fleetCondTimeSerie = x.series.push(new am4charts.LineSeries());
    fleetCondTimeSerie.dataFields.valueY = "fleetConductionTime";
    fleetCondTimeSerie.dataFields.dateX = "date";
    fleetCondTimeSerie.name = "FleetKmsRec";
    fleetCondTimeSerie.tooltipText = `${t("Fecha")}: {dateX}
      ${t("Tiempo de conducción Flota")}: {valueY} [h]
    `;
    fleetCondTimeSerie.stroke = am4core.color("#A20F0F");
    fleetCondTimeSerie.strokeWidth = 2;

    let segmentFS = fleetCondTimeSerie.segments.template;
    segmentFS.interactionsEnabled = true;

    fleetCondTimeSerie.data = data;

    //Driver or Asset serie
    let condTimeSerie = x.series.push(new am4charts.LineSeries());
    condTimeSerie.dataFields.valueY = "conductionTime";
    condTimeSerie.dataFields.dateX = "date";
    condTimeSerie.name = "Condtime";
    condTimeSerie.stroke = am4core.color("#87C802");
    condTimeSerie.strokeWidth = 2;
    condTimeSerie.tooltipText = `${t("Fecha")}: {dateX}
      ${t("Tiempo de conduccion")}: {valueY} [h]
    `;

    let segmentScore = condTimeSerie.segments.template;
    segmentScore.interactionsEnabled = true;

    condTimeSerie.data = data;

    x.cursor = new am4charts.XYCursor();
    x.cursor.maxTooltipDistance = 0;

    chart.current = x;

    return () => {
      x.dispose();
    };
  }, [user.language]);

  return (
    <>
      <div
        id="conductionTimeChartContainer"
        style={{ width: "100%", height: "40vh" }}
      />
    </>
  );
};

const _mapStateToProps = ({ user }) => {
  return {
    user,
  };
};

const _mapDispatchToProps = (dispatch) => {
  return {
    // _setCurrentUser: (param) => dispatch(setCurrentUser(param)),
  };
};

export default connect(
  _mapStateToProps,
  _mapDispatchToProps
)(ConductionTimeChart);
