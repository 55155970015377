import gql from "graphql-tag";

const UPDATE_ASSET_MUTATION = gql`
  mutation UpdateAsset(
    $assetId: Int!
    $name: String!
    $licensePlate: String
    $brand: String
    # $patent: String # ??
    $color: String
    $driverId: Int
    $typeMobileId: Int
    $customerId: Int
    $internalNumber: String
    $fuelCutWiring: Boolean
    $fuelCutWiringDate: Datetime
    $cutSolenoidValve: Boolean
    $electroValveInstallationDate: Datetime
    $mainTension: String
    # $workingHoursId: Int
    $maximumSpeed: Float
    $initialRealOdometer: Float
    $photo: Upload
    # $icon: String
    # $mobileImageType: String # ??
    # $photoFull: String # ??
    $model: String
    $year: Int
    $fuelTankCapacity: Float
    $fuelTankCapacityMax: Float
    $invalidMaximumSpeed: Float
    $controlInvalidSpeed: Boolean
    # $imei: String # ??
    $initialRealHorometer: Int
    $satPatAlerts: Boolean
    $operationNumber: String
    $daysStopMaximum: Int
    $averageDailyKm: Float
    $internalCustomer: String
    $averageDailyHorometer: Int
    $itSupportsOBD2: Boolean
  ) {
    updateAsset(
      assetId: $assetId
      name: $name
      licensePlate: $licensePlate
      brand: $brand
      # patent: $patent # ??
      color: $color
      driverId: $driverId
      typeMobileId: $typeMobileId
      customerId: $customerId
      internalNumber: $internalNumber
      fuelCutWiring: $fuelCutWiring
      fuelCutWiringDate: $fuelCutWiringDate
      cutSolenoidValve: $cutSolenoidValve
      electroValveInstallationDate: $electroValveInstallationDate
      mainTension: $mainTension
      # workingHoursId: $workingHoursId
      maximumSpeed: $maximumSpeed
      initialRealOdometer: $initialRealOdometer
      photo: $photo
      # icon: $icon
      # mobileImageType: $mobileImageType # ??
      # photoFull: $photoFull # ??
      model: $model
      year: $year
      fuelTankCapacity: $fuelTankCapacity
      fuelTankCapacityMax: $fuelTankCapacityMax
      invalidMaximumSpeed: $invalidMaximumSpeed
      controlInvalidSpeed: $controlInvalidSpeed
      # imei: $imei # ??
      initialRealHorometer: $initialRealHorometer
      satPatAlerts: $satPatAlerts
      operationNumber: $operationNumber
      daysStopMaximum: $daysStopMaximum
      averageDailyKm: $averageDailyKm
      internalCustomer: $internalCustomer
      averageDailyHorometer: $averageDailyHorometer
      itSupportsOBD2: $itSupportsOBD2
    ) {
      id
    }
  }
`;

export default UPDATE_ASSET_MUTATION;
