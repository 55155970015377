import gql from "graphql-tag";

const CREATE_ASSETDEVICE_MUTATION = gql`
  mutation CreateAssetDevice(
    $assetid: Int!
    $deviceid: Int!
    $technicianId: Int
    $dateAssociated: Datetime!
  ) {
    createAssetDevice(
      assetid: $assetid
      deviceid: $deviceid
      technicianId: $technicianId
      dateAssociated: $dateAssociated
    ) {
      id
    }
  }
`;

export default CREATE_ASSETDEVICE_MUTATION;
