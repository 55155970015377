import { UPDATE_MARKERS, UPDATE_MARKERS_INITIAL } from "./actions";
import { markersSelector, prepareMarker } from "./selector";

const markers = (state = [], { type, payload }) => {
  let nextState = state;
  if (type === UPDATE_MARKERS) {
    //debugger;
    // nextState = markersSelector({ markers: [payload] });

    //if (payload.velocidad === 0) return nextState;
    //if (payload.patente != "T9Y-817") return nextState;

    const exists = state.findIndex((e) => {
      return e.identifier === payload.patente;
      //   return JSON.stringify(e) === JSON.stringify(new_marker[0]);
    });

    const new_marker = prepareMarker({ marker: payload });

    if (exists >= 0) {
      //alert(payload.patente);
      // console.log("ACTUALIZACION vehiculo ", payload.patente);
      // console.log("POSICIÓN nueva ", payload.latitud);
      // console.log("POSICIÓN anterior ", nextState[exists].position.lat);

      nextState[exists] = new_marker;

      // nextState.splice(exists, 1);
      // nextState.push(new_marker);

      // const clonedArr = [...nextState];
      // clonedArr[exists] = new_marker;
      // return clonedArr;

      // nextState = [
      //   ...nextState.slice(0, exists),
      //   new_marker,
      //   ...nextState.slice(exists + 1),
      // ];

      // let newData = state;
      // let user = newData.find((d) => d.patente === payload.patente)[0];
      // Object.assign(user, new_marker);
      // nextState = newData;
    } else {
      nextState = [...state, new_marker];
    }

    // console.log("markers -> state", state);
    // console.log("markers -> nextState", nextState);

    return nextState;
  }

  if (type === UPDATE_MARKERS_INITIAL) {
    nextState = markersSelector({ markers: payload });
  }

  return nextState;
};

export default markers;
