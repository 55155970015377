import React, { useEffect, useState } from "react";

import { useMutation, useLazyQuery } from "@apollo/client";

import Structure from "../../../components/Base/Structure";

import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import CUSTOMERS_QUERY from "../../../graphql/queries/getCustomersQuery";
import CREATE_CUSTOMER_MUTATION from "../../../graphql/mutations/createCustomerMutation";
import UPDATE_CUSTOMER_MUTATION from "../../../graphql/mutations/updateCustomerMutation";
import DELETE_CUSTOMER_MUTATION from "../../../graphql/mutations/deleteCustomerMutation";
import IMPORT_KML_FILE_GEOFENCE_MUTATION from "../../../graphql/mutations/importKMLFileGeofenceMutation";

import PageTitle from "../../../components/Base/PageTitle";

import TableCustomer from "./List";
import "./Clients.scss";

import { useTranslation } from "../../../lang";

const ManageClientsPage = ({}) => {
  const [t] = useTranslation();
  const paginationSizes = [10, 25, 50];
  const [total, setTotal] = useState(0);
  const [customerList, setCustomerList] = useState([]);
  const [paginationState, setPaginationState] = useState({
    pageSize: paginationSizes[0],
    pageNumber: 1,
    sort: undefined,
    filter: null,
  });

  const [resultState, setResultState] = useState({
    isOpen: false,
    type: "success",
    msg: "",
  });

  const [getCustomers] = useLazyQuery(CUSTOMERS_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      setCustomerList(d.customers.items);
      setTotal(d.customers.count);
    },
  });

  const handleGetCustomers = () => {
    getCustomers({
      variables: {
        ...paginationState,
        paginate: true,
      },
    });
  };

  useEffect(() => {
    handleGetCustomers();
  }, [paginationState]);

  const [createCustomer] = useMutation(CREATE_CUSTOMER_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Cliente creado correctamente"),
      });
      handleGetCustomers();
    },
    onError(err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: t("Ocurrió un error"),
      });
    },
  });

  const [updateCustomer] = useMutation(UPDATE_CUSTOMER_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Cliente actualizado correctamente"),
      });
      handleGetCustomers();
    },
    onError(err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: t("Ocurrió un error"),
      });
    },
  });

  const [deleteCustomer] = useMutation(DELETE_CUSTOMER_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Cliente eliminado correctamente"),
      });
      handleGetCustomers();
    },
    onError(d) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: t("Ocurrió un error"),
      });
    },
  });

  const [importKMLFile] = useMutation(IMPORT_KML_FILE_GEOFENCE_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Archivo KML importado correctamente"),
      });
      handleGetCustomers();
    },
    onError(d) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: t("Ocurrió un error"),
      });
    },
  });

  // TODO: Use correct mutation
  const importGeofences = (info) => {
    console.log("Actualizando...", info);
    importKMLFile({ ...info, type: "DPWORLD" });
    console.log("Actualizado");
  };

  const handleDeleteCustomer = (id) => {
    deleteCustomer({
      variables: { customerId: id },
    });
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setResultState({
      ...resultState,
      isOpen: false,
    });
  };

  return (
    <Structure>
      <Snackbar
        open={resultState.isOpen}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleCloseNotification}
          severity={resultState.type}
          variant="filled"
        >
          {resultState.msg}
        </MuiAlert>
      </Snackbar>
      <PageTitle sector={t("Administración")} title={t("Clientes")} />
      <TableCustomer
        getCustomers={handleGetCustomers}
        customerList={customerList}
        paginationSizes={paginationSizes}
        paginationState={paginationState}
        setPaginationState={setPaginationState}
        total={total}
        setResultState={setResultState}
        createCustomer={createCustomer}
        updateCustomer={updateCustomer}
        deleteCustomer={handleDeleteCustomer}
        importGeofences={importGeofences}
      />
    </Structure>
  );
};

export default ManageClientsPage;
