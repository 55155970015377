import gql from "graphql-tag";

const ROAD_LIST_SUMMARY_QUERY = gql`
  query Road($customerId: Int) {
    road(customerId: $customerId) {
      id
      name
      description
      hasDetourAlarm
      hasVelocityAlarm
      detourMeters
      maxVelocity
      customerId
    }
  }
`;

export default ROAD_LIST_SUMMARY_QUERY;
