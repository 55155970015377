import React from "react";
//import SettingIcon from "../../../ public/icons/SettingIcon";
import SettingIcon from "../../public/icons/SettingIcon";

import "./PageTitle.scss";

const PageTitle = ({ sector, title }) => {
  return (
    <div className="pageTitleContainer">
      <SettingIcon />
      {` ${sector}  >  ${title}`}
    </div>
  );
};

export default PageTitle;
