import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";

import { Button } from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";

import { formatDistanceToNow } from "date-fns";
import { es, enUS } from "date-fns/locale";

import Dropdown from "../Base/Dropdown";
import FlexRow from "../Base/FlexRow";

import { openPanel, changePanelType } from "../../redux/lateralPanel/actions";

import { hasPermission, truncateString } from "../../utils/generalUtils";

import {
  selectNotification,
  unselectNotification,
  selectAllNotification,
  getNotifications,
  readNotifications,
  setNewNotification,
} from "../../redux/notifications/actions";

import "./NotificationsButton.scss";

import { useTranslation } from "../../lang";
import { stringDateToDateWithoutUTC } from "../../utils/dates";

const NotificationsButtonHeader = ({
  handleClick,
  selectAll,
  notifications,
  loggedUser,
  selectedNotifications,
}) => {
  const [t] = useTranslation();
  const [
    isConfirmAlarmButtonDisabled,
    setIsConfirmAlarmButtonDisabled,
  ] = useState(true);

  const confirmAlarmsPermission = hasPermission(
    "LeerNotificaciones",
    loggedUser.roles
  );

  useEffect(() => {
    if (
      Array.isArray(selectedNotifications) &&
      selectedNotifications.length > 0
    ) {
      setIsConfirmAlarmButtonDisabled(false);
    } else {
      setIsConfirmAlarmButtonDisabled(true);
    }
  }, [selectedNotifications]);

  return (
    <FlexRow alignment="space-between" _classNames="text-color--white pad-4">
      {notifications?.length > 0 ? (
        <>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              //alert("Seleccionando todos");
              confirmAlarmsPermission &&
                selectAll(notifications.map((n) => n.id));
            }}
          >
            {t("Seleccionar todo")}
          </Button>
          {confirmAlarmsPermission && (
            <Button
              variant={isConfirmAlarmButtonDisabled ? "outlined" : "contained"}
              size="small"
              color="primary"
              onClick={handleClick}
              disabled={isConfirmAlarmButtonDisabled}
            >
              {t("Asentir Alarmas")}
            </Button>
          )}
        </>
      ) : (
        <div className="no-notifications">{t("No hay notificaciones")}</div>
      )}
    </FlexRow>
  );
};

NotificationsButtonHeader.propTypes = {
  handleClick: PropTypes.func,
  selectAll: PropTypes.func,
};

NotificationsButtonHeader.defaultProps = {
  handleClick: () => {},
  selectAll: () => {},
};

const notificationsItems = (
  selectedItems,
  selItem,
  unselectNotification,
  notifications,
  t,
  loggedUser
) => {
  const dateFormatOptions = {
    addSuffix: true,
    includeSeconds: true,
    locale: loggedUser.language === "ES" ? es : enUS,
  };

  return notifications.map((n) => {
    const date = stringDateToDateWithoutUTC(n.point.fGPS);

    return {
      item: (
        <div
          onClick={() =>
            selectedItems.includes(n.id)
              ? unselectNotification(n.id)
              : selItem(n.id)
          }
          className={
            selectedItems?.includes(n.id) ? "selectedNotification" : undefined
          }
        >
          <FlexRow
            bottomDivider
            expansion="full"
            _classNames={"notification-content__row pad-4"}
            //handleClick={() => selectedItems.includes(n.id) ? unselectNotification(n.id) : selItem(n.id)}
          >
            <div
              className={`notification-icon noticiation-icon-type notification-icon-type--${
                n.type || "alert"
              }`}
            />
            <FlexRow direction="column" _classNames="notification-content">
              <ListItemText
                className="notification-content-item notification-content__title"
                primary={`${n.source} ${t("Ha notificado un evento")}`}
              />
              <ListItemText
                className="notification-content-item notification-content__description"
                primary={truncateString(n.event, 25)}
                title={n.event}
              />
              <ListItemText
                className="notification-content-item notification-content__time"
                primary={formatDistanceToNow(date, dateFormatOptions)}
              />
            </FlexRow>
            <div
              className={`notification-icon notification-icon-status notification-icon-status--${
                n.datereaded ? "read" : "new"
              }`}
            />
          </FlexRow>
        </div>
      ),
    };
  });
};

const NotificationsButton = ({
  selectedNotifications,
  selectNotification,
  unselectNotification,
  selectAllNotification,
  getNotif,
  notifications,
  readNotifications,
  setNewNotification,
  cantUnACK,
  openPanel,
  changePanelType,
  loggedUser,
  lateralPanel,
}) => {
  const [t] = useTranslation();

  React.useEffect(() => {
    getNotif();
  }, []);

  const sounds = {
    Panico: "/assets/sounds/Panico.mp3",
    Desconexión: "/assets/sounds/Desconexion.mp3",
    "Desconexion de Antena": "/assets/sounds/Desconexion_de_antena.mp3",
    // Jamming: "/assets/sounds/Panico.mp3",
    // "Sin Transmisión": "/assets/sounds/Desconexion_de_antena.mp3",
  };

  let alarmIndex = -1;
  function playSnd(snd) {
    alarmIndex++;
    if (alarmIndex == snd.length) return;
    snd[alarmIndex].addEventListener("ended", () => playSnd(snd));
    snd[alarmIndex].play();
  }

  const soundStorage =
    localStorage.getItem(`soundActivated_${loggedUser.id}`) === "false"
      ? false
      : true;
  useEffect(() => {
    // Logic to play an alarm sound
    // If there are notifications, and the notification panel is open... then play the corresponding sound
    if (
      notifications &&
      lateralPanel?.newPanelReducer?.isPanelOpen &&
      lateralPanel?.newPanelReducer?.panelType === "Notification" &&
      soundStorage
    ) {
      const alarms = new Array();
      Object.keys(sounds).forEach((s) => {
        if (notifications.filter((n) => n.event === s).length > 0)
          alarms.push(new Audio(sounds[s]));
      });
      playSnd(alarms);
    }
  }, [notifications, lateralPanel, soundStorage]);

  return (
    <Dropdown
      icon={NotificationsNoneOutlinedIcon}
      iconBadge={{
        //cant: Array.isArray(notifications) ? notifications.filter(x => !x.datereaded).length : 0,
        cant: cantUnACK,
        max: 999999,
        color: "secondary",
        anchor: {
          vertical: "bottom",
          horizontal: "right",
        },
      }}
      header={
        <NotificationsButtonHeader
          selectAll={selectAllNotification}
          notifications={notifications}
          handleClick={() => readNotifications(selectedNotifications)}
          loggedUser={loggedUser}
          selectedNotifications={selectedNotifications}
        />
      }
      items={[
        ...notificationsItems(
          selectedNotifications,
          selectNotification,
          unselectNotification,
          notifications || [],
          t,
          loggedUser
        ),
        {
          item: (
            <FlexRow
              expansion="full"
              _classNames={"notification-content__row pad-4 flexrowCentered"}
            >
              <Button
                className="button-vertodos"
                onClick={() => {
                  openPanel();
                  changePanelType("Notification");
                }}
              >
                {t("VER TODOS")}
              </Button>
            </FlexRow>
          ),
        },
      ]}
    />
  );
};

const _mapStateToProps = ({ notifications, user, lateralPanel }) => {
  return {
    selectedNotifications: notifications.selectedNotifications,
    cantUnACK: notifications.topNotifications.cantUnACK,
    notifications: notifications.topNotifications.notifications,
    loggedUser: user,
    lateralPanel: lateralPanel,
  };
};

const _mapDispatchToProps = (dispatch, getState) => {
  return {
    selectNotification: (messageId) => dispatch(selectNotification(messageId)),
    unselectNotification: (messageId) =>
      dispatch(unselectNotification(messageId)),
    selectAllNotification: (messages) =>
      dispatch(selectAllNotification(messages)),
    getNotif: () => getNotifications()(dispatch),
    readNotifications: (messages) => readNotifications(messages)(dispatch),
    setNewNotification: (message) => dispatch(setNewNotification(message)),
    //toogleNotification: () => toogleNotification()(dispatch),
    openPanel: () => dispatch(openPanel()),
    changePanelType: (panelType) => dispatch(changePanelType(panelType)),
  };
};

export default connect(
  _mapStateToProps,
  _mapDispatchToProps
)(NotificationsButton);
