import React, { useMemo, useRef } from "react";
import { Circle, Marker, Pane, Tooltip } from "react-leaflet";

import L from "leaflet";
import defaultIcon from "leaflet/dist/images/marker-icon.png";
import defaultIconShadow from "leaflet/dist/images/marker-shadow.png";
import { useTranslation } from "../../../lang";
import { DISTANCE_UNITS, convertDistanceUnit } from "./DistanceUnit";

const markerIcon = L.icon({
  iconUrl: defaultIcon,
  shadowUrl: defaultIconShadow,
  iconAnchor: [12.5, 41],
});

const PANE_INDEX = {
  CENTRAL_MARKER: 650,
  SEARCH_AREA: 550,
};

const AssetsInRadiusNearPoint = ({
  onDragging,
  onDragEnd,
  center,
  radius,
  distanceUnit,
  onMarkerClicked,
  assetsCount,
}) => {
  const [t] = useTranslation();
  const markerRef = useRef(null);
  const eventHandlers = {
    click() {
      onMarkerClicked();
    },
    drag() {
      const marker = markerRef.current;
      if (marker != null) {
        onDragging([marker.getLatLng().lat, marker.getLatLng().lng]);
      }
    },
    dragend() {
      const marker = markerRef.current;
      if (marker != null) {
        onDragEnd([marker.getLatLng().lat, marker.getLatLng().lng]);
      }
    },
  };
  const radiusInMeters = convertDistanceUnit(
    radius,
    distanceUnit.unit,
    DISTANCE_UNITS.METER
  );

  return (
    <>
      <Pane
        name="leaflet-search-in-area-pane-marker"
        style={{ zIndex: PANE_INDEX.CENTRAL_MARKER }}
      >
        <Marker
          position={center}
          draggable={true}
          eventHandlers={eventHandlers}
          icon={markerIcon}
          ref={markerRef}
          className="search-in-radius-marker"
        >
          <Tooltip permanent={true} direction="bottom">
            {t("%assetsCount% moviles en esta area de busqueda", {
              assetsCount: assetsCount || 0,
            })}
          </Tooltip>
        </Marker>
      </Pane>
      <Pane
        name="leaflet-search-in-area-pane-area"
        style={{ zIndex: PANE_INDEX.SEARCH_AREA }}
      >
        <Circle center={center} radius={radiusInMeters} fillColor="blue" />
      </Pane>
    </>
  );
};

export default AssetsInRadiusNearPoint;
