import { INPUT_COLORS, SEMANTIC_COLORS } from "../../Theme.js";
import { styled } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";

const CssTextField = styled(TextField)({
  "& input": {
    color: INPUT_COLORS.TEXT,
  },
  "& label": {
    color: INPUT_COLORS.TEXT,
  },
  "& label.Mui-focused": {
    color: SEMANTIC_COLORS.PRIMARY,
  },
  "& .MuiIconButton-root": {
    color: INPUT_COLORS.TEXT,
  },
  "& .MuiInput-underline": {
    "&:hover:not($disabled):before": {
      borderBottomColor: INPUT_COLORS.TEXT,
    },
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: INPUT_COLORS.TEXT,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: SEMANTIC_COLORS.PRIMARY,
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: SEMANTIC_COLORS.PRIMARY,
    },
  },
});

export default CssTextField;
