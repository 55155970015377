import gql from "graphql-tag";

const GET_GENERAL_INFO_QUERY_COMPLETE = gql`
  query Points($equipoID: Int!) {
    points(equipoID: $equipoID) {
      dir
      odo
      sOdo
      horo
      utx
      nCh
      mid
    }
  }
`;

export default GET_GENERAL_INFO_QUERY_COMPLETE;
