import React, { useLayoutEffect, useRef } from "react";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import darkTheme from "@amcharts/amcharts4/themes/dark";

const InfractionsChart = ({ data }) => {
  const chart = useRef(null);

  const colors = ["#008DB9", "#09B7AB", "#EF4900", "#7CB509"];

  useLayoutEffect(() => {
    if (chart.current) {
      chart.current.data = data;
    }
  }, [data]);

  useLayoutEffect(() => {
    let x = am4core.create("infractionChartContainer", am4charts.XYChart);

    x.data = data;

    let categoryAxis = x.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.dataFields.category = "infraction";
    categoryAxis.renderer.minGridDistance = 60;
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.grid.template.disabled = true;

    let valueAxis = x.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.renderer.labels.template.disabled = true;

    let series = x.series.push(new am4charts.ColumnSeries());
    series.dataFields.categoryX = "infraction";
    series.dataFields.valueY = "quantity";
    series.tooltipText = "{valueY.value}";
    series.columns.template.strokeOpacity = 0;
    series.fill = am4core.color("#348500");
    series.calculatePercent = true;

    let labelBullet = series.bullets.push(new am4charts.LabelBullet());
    labelBullet.label.verticalCenter = "bottom";
    labelBullet.label.dy = -10;
    labelBullet.fontSize = "10px";
    //labelBullet.label.text = "{values.valueY.workingValue.formatNumber('#.')}";
    labelBullet.label.text = "{values.valueY.percent.formatNumber('#.')}%";

    /*let serieFleet = x.series.push(new am4charts.LineSeries());
    serieFleet.dataFields.valueY = "promFleet";
    serieFleet.dataFields.categoryX = "infraction";
    serieFleet.bullets.push(new am4charts.CircleBullet());
    serieFleet.strokeWidth = 2;*/

    let serieFleet = x.series.push(new am4charts.ColumnSeries());
    serieFleet.dataFields.valueY = "promFleet";
    serieFleet.dataFields.categoryX = "infraction";
    serieFleet.tooltipText = "{valueY.value}";
    serieFleet.columns.template.strokeOpacity = 0;
    serieFleet.fill = am4core.color("#B50909");
    serieFleet.calculatePercent = true;

    let labelBulletFleet = serieFleet.bullets.push(new am4charts.LabelBullet());
    labelBulletFleet.label.verticalCenter = "bottom";
    labelBulletFleet.label.dy = -10;
    labelBulletFleet.fontSize = "10px";
    //labelBullet.label.text = "{values.valueY.workingValue.formatNumber('#.')}";
    labelBulletFleet.label.text = "{values.valueY.percent.formatNumber('#.')}%";

    //as by default columns of the same series are the same color, we add adapter which take colors from a variable
    /*series.columns.template.adapter.add("fill", function (fill, target) {
      return am4core.color(colors[target.dataItem.index]);
     });*/

    chart.current = x;

    return () => {
      x.dispose();
    };
  }, []);

  return (
    <>
      <div
        id="infractionChartContainer"
        style={{ width: "100%", height: "20vh" }}
      />
    </>
  );
};

export default InfractionsChart;
