// eslint-disable react/forbid-prop-types
import React from "react";
import PropTypes from "prop-types";

import DropdownButton from "./DropdownButton";
import DropdownList from "./DropdownList";

import "./index.scss";

const Dropdown = ({
  title,
  icon,
  header,
  headerProps,
  items,
  iconBadge = null,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const dropdownId = title ? `${title}-random-menu-panel` : "random-menu-panel";

  return (
    <div className="dropdown">
      <DropdownButton
        handleClick={handleClick}
        title={title}
        icon={icon}
        iconBadge={iconBadge}
      />

      <DropdownList
        dropdownId={dropdownId}
        dropdownHeader={header}
        headerProps={headerProps}
        dropdownItems={items}
        handleClose={handleClose}
        anchorEl={anchorEl}
      />
    </div>
  );
};

Dropdown.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.any,
  header: PropTypes.node,
  headerProps: PropTypes.object,
  items: PropTypes.array,
};

Dropdown.defaultProps = {
  title: "",
  icon: null,
  header: null,
  headerProps: {},
  items: [],
};

export default Dropdown;
