import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import { infoNotification } from "../redux/notifications/actions";

import { useTranslation } from "../lang";

const PublicOnlyRoute = ({
  component: Component,
  loggedIn,
  redirectTo,
  infoNotificationAction,
  ...rest
}) => {
  const [t] = useTranslation();
  let _publicComponent = <Component {...rest} />;

  if (loggedIn) {
    const pathname = redirectTo || "/map";

    _publicComponent = (
      <Redirect
        to={{
          pathname,
          // state: { from: routerProps.location }
        }}
      />
    );

    /**
     * redirectTo is set on the user state
     * People wanting to access /login without the redirectTo set
     * will get this message as it is only saved temporarily when logging
     * in and not in the localStorage or when the user returns.
     */
    if (!redirectTo && infoNotificationAction) {
      infoNotificationAction(t("Ya has ingresado al sistema"));
    }
  }

  return _publicComponent;
};

const _mapStateToProps = ({ user }) => ({
  loggedIn: user.loggedIn,
  redirectTo: user.redirectTo,
});

const _mapDispatchToProps = {
  infoNotificationAction: infoNotification,
};

export default connect(_mapStateToProps, _mapDispatchToProps)(PublicOnlyRoute);
