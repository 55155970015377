import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import React, { useEffect, useRef } from "react";
import MarkerClusterGroup from "react-leaflet-markercluster";
import createClusterCustomIcon from "../../utils/clusters";
import "./MarkersGroup.scss";

const MarkersGroupV3 = ({ markers, maxZoom, markersData }) => {
  // console.log("🚀 ~  markers", markers);
  // console.log("🚀 ~  markersData", markersData);
  const markerClusterGroup = useRef();

  useEffect(() => {
    // console.time("refreshingclusters");
    markerClusterGroup.current.refreshClusters();
    // console.timeEnd("refreshingclusters");
  }, [markersData]);
  // console.log("rendering", new Date());
  return (
    <MarkerClusterGroup
      iconCreateFunction={createClusterCustomIcon}
      zoomToBoundsOnClick={true}
      chunkedLoading={true}
      // chunkInterval={100}
      // chunkDelay={100}
      // chunkProgress={(processed, total, elapsed, layersArray) =>
      //   console.log("progressing", processed, total, elapsed, layersArray)
      // }
      disableClusteringAtZoom={maxZoom}
      markerOptions={{
        closePopupOnClick: false,
        closeOnClick: false,
        autoClose: false,
      }}
      ref={markerClusterGroup}
      showCoverageOnHover={false}
    >
      {markers}
    </MarkerClusterGroup>
  );
};

export default MarkersGroupV3;
