import { combineReducers } from "redux";

import { UPDATE_POPUP_INFO } from "./actions";

const popupInfo = (popupInfo = {}, { type, payload }) => {
  let newpopupInfo = popupInfo;
  if (type === UPDATE_POPUP_INFO) {
    newpopupInfo = payload;
  }

  return newpopupInfo;
};

export default combineReducers({
  popupInfo,
});
