import gql from "graphql-tag";

const GET_DEVICES_QUERY = gql`
  query DevicesPaginated(
    $customerIds: [Int]
    $pageNumber: Int
    $pageSize: Int
    $searchText: String
    $sort: DeviceSortInput
    $paginate: Boolean
    $withoutAssign: Boolean
  ) {
    devicesPaginated(
      customerIds: $customerIds
      pageNumber: $pageNumber
      pageSize: $pageSize
      searchText: $searchText
      sort: $sort
      paginate: $paginate
      withoutAssign: $withoutAssign
    ) {
      count
      items {
        id
        deviceIdentifier
        deviceType {
          id
          description
        }
        expirationPassword
        firmwareVersion
        scriptVersion
        hasCanBusy
        tempConversionFactor
        model
        serialNumber
        line {
          id
          lineNumber
          email
        }
        imei
        createdDate
      }
    }
  }
`;

export default GET_DEVICES_QUERY;
