import classNames from "classnames";
import Leaflet from "leaflet";
import React, { useEffect } from "react";
import { Marker, Tooltip } from "react-leaflet";

import NavigationIcon from "./resources/navigationSvg";

// import NavigationPopup from "./NavigationPopup";
import ConnectedNavigationPopup from "../../containers/ConnectedNavigationPopup";

import "./NavigationMarker.scss";
// eslint-disable react/forbid-prop-types
const ICON_SIZE = 40;
const ANCHOR_POSITION = 20; // ICON_SIZE / 2
const POPUP_ANCHOR_POSITION = -25; // ICON_SIZE / 2
const IconSettings = {
  //  shadowUrl: require('../public/marker-shadow.png'),
  iconSize: [ICON_SIZE, ICON_SIZE], // size of the icon
  // shadowSize:   [50, 64], // size of the shadow
  iconAnchor: [ANCHOR_POSITION, ANCHOR_POSITION], // point of the icon which will correspond to marker's location
  // shadowAnchor: [4, 62],  // the same for the shadow
  popupAnchor: [0, POPUP_ANCHOR_POSITION], // point from which the popup should open relative to the iconAnchor
};

const NavigationMarker = (props) => {
  // console.log(
  //   "🚀 ~ file: NavigationMarker.js ~ line 26 ~ NavigationMarker ~ props",
  //   props
  // );

  const { markerInfo, markerSelected, setTrace, shouldDisplay } = props;
  // const { markerInfo, isMarkerSelected } = props;
  const { pos, typ } = markerInfo;

  let markerRef = null;

  const initMarker = (ref) => {
    markerRef = ref;
  };

  const markerClassNames = classNames("marker", {
    [`marker--${typ}`]: typ,
  });

  const NavigationIconWithCurso = NavigationIcon(markerInfo.cur);

  const icon = Leaflet.divIcon({
    className: `${markerClassNames}`,
    ...IconSettings,
    html: NavigationIconWithCurso,
  });

  useEffect(() => {
    if (markerSelected === markerInfo.mid) {
      // if (isMarkerSelected) {
      const setTimeoutID = setTimeout(() => {
        if (markerRef) {
          //console.log("parent: ", markerRef.__parent);
          //markerRef.__parent.fire("click");
          markerRef.openPopup();
          const btnEl = document.getElementById(`btnMarker_${markerInfo.mid}`);
          btnEl && btnEl.click();
          props.setMarkerSelected(null);
        }
      }, 500);

      return () => {
        clearTimeout(setTimeoutID);
      };
    }
  }, [markerSelected]);
  // }, [isMarkerSelected]);

  return shouldDisplay ? (
    <Marker
      classNames="marker-container"
      icon={props.icon || icon}
      position={pos}
      ref={initMarker}
      autoClose={false}
      variant={typ}
    >
      <Tooltip
        direction="bottom"
        offset={[0, 16]}
        opacity={0.9}
        permanent
        className={"tooltip"}
      >
        {markerInfo.pat}
      </Tooltip>
      <ConnectedNavigationPopup
        variant={typ}
        markerInfo={markerInfo}
        viewDetail={props?.viewDetail}
        // setTrace={setTrace}
      />
    </Marker>
  ) : null;
};

export default React.memo(NavigationMarker);

// class NavigationMarker2 extends Component {
//   static propTypes = {
//     markerInfo: PropTypes.object,
//     autoOpenMarker: PropTypes.bool,
//     setMarkerRefs: PropTypes.func,
//   };

//   static defaultProps = {
//     markerInfo: {},
//     autoOpenMarker: false,
//     markerSelected: () => {},
//   };

//   constructor(props) {
//     super(props);

//     //this.state = { ref: null };
//     this.markerRef = null;
//   }

//   componentDidUpdate(prevProps) {
//     if (prevProps.markerSelected !== this.props.markerSelected) {
//       if (this.props.markerSelected === this.props.markerInfo.mid) {
//         setTimeout(() => {
//           this.markerRef.openPopup();
//           const btnEl = document.getElementById(
//             `btnMarker_${this.props.markerInfo.mid}`
//           );
//           btnEl.click();
//         }, 500);
//       }
//     }
//   }

//   render() {
//     const { markerInfo, autoOpenMarker } = this.props;

//     const { pos, typ } = markerInfo;

//     const initMarker = (ref) => {
//       this.markerRef = ref;
//     };

//     const markerClassNames = classNames("marker", {
//       [`marker--${typ}`]: typ,
//     });

//     const NavigationIconWithCurso = NavigationIcon(markerInfo.cur);

//     const icon = Leaflet.divIcon({
//       className: `${markerClassNames}`,
//       ...IconSettings,
//       html: NavigationIconWithCurso,
//     });

//     return (
//       <Marker
//         classNames="marker-container"
//         icon={icon}
//         position={pos}
//         ref={initMarker}
//         autoClose={false}
//         variant={typ}
//       >
//         <ConnectedNavigationPopup variant={typ} markerInfo={markerInfo} />
//       </Marker>
//     );
//   }
// }
// export default NavigationMarker;
