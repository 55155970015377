import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
} from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import React, { useEffect, useState } from "react";
import { useTranslation } from "../../../lang";
import { getRadiusDefaultValueByDistanceUnit } from "./AssetInAreaUtils";
import { convertDistanceUnit, DISTANCE_UNITS } from "./DistanceUnit";
import "./style.scss";

const theme = createMuiTheme({});

const AssetInRadiusNearPointSearchModalWindow = ({
  onCloseModal,
  onAssetSelected,
  onRadiusChange,
  onSearch,
  data,
  isOpen,
  radius,
  distanceUnit,
  isSearchInAreaEnabled,
  onIsSearchInAreaEnabled,
}) => {
  const [t] = useTranslation();
  const defaultRadius = getRadiusDefaultValueByDistanceUnit(distanceUnit);
  const [_radius, setRadius] = useState(radius || defaultRadius);

  useEffect(() => {
    if (isSearchInAreaEnabled) {
      onIsSearchInAreaEnabled();
    }
  }, [isSearchInAreaEnabled]);

  const handleSelectAsset = (asset) => {
    onAssetSelected(asset);
  };

  const handleRadiusChange = (event) => {
    const newRadius = parseInt(event.target.value) || 0;
    setRadius(newRadius);
    onRadiusChange(newRadius);
  };

  const handleSearch = () => {
    onSearch();
  };
  return (
    <>
      <ThemeProvider theme={theme}>
        <Dialog open={isOpen} onClose={onCloseModal} maxWidth="md" fullWidth>
          <DialogTitle className="dialogTitle">
            {t("Busqueda de moviles cercanos")}

            <Button onClick={onCloseModal} className="closeWindow">
              x
            </Button>
          </DialogTitle>
          <Grid container={true}>
            <div className="search-in-area-modal-filter">
              <TextField
                label={t("Radio de busqueda")}
                variant="standard"
                placeholder={t("Radio de busqueda")}
                value={_radius}
                onChange={handleRadiusChange}
                InputProps={{
                  endAdornment: (
                    <>
                      <InputAdornment position="end">
                        [{t(distanceUnit.symbol)}]
                      </InputAdornment>
                      <IconButton onClick={handleSearch}>
                        <SearchIcon className="SearchIconColor" />
                      </IconButton>
                    </>
                  ),
                }}
              />
            </div>
          </Grid>

          <DialogContent>
            {data.length > 0 && (
              <table className="result-table">
                <thead>
                  <tr>
                    <th>{t("Patente")}</th>
                    <th>
                      {t("Distancia")} [{t(distanceUnit.symbol)}]
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((row) => {
                    return (
                      <tr key={row.id}>
                        <td>{row.patente}</td>
                        <td>
                          {convertDistanceUnit(
                            row.distance,
                            DISTANCE_UNITS.METER,
                            distanceUnit.unit
                          ).toFixed(0)}
                        </td>
                        <td>
                          <Link onClick={() => handleSelectAsset(row)}>
                            {t("Seleccionar movil")}
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
            {data.length <= 0 && t("Sin informacion disponible")}
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

export default AssetInRadiusNearPointSearchModalWindow;
