import { useTranslation } from "../lang";

const SearchBarTypes = {
  FUNCTIONS: "Funciones",
  ASSETS: "Vehículos",
  GEOFENCES: "Geocercas",
  DIRECTIONS: "Direcciones",
};

export const useTranslatedSearchBarTypes = () => {
  const [t] = useTranslation();

  const translatedSearchBarTypes = {};
  for (const [key, value] of Object.entries(SearchBarTypes)) {
    translatedSearchBarTypes[key] = t(value);
  }

  console.log("translatedSearchBarTypes: ", translatedSearchBarTypes);
  return [translatedSearchBarTypes];
};

export default SearchBarTypes;
