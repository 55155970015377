import React, { useState, useEffect } from "react";

import { useLazyQuery } from "@apollo/client";

import { Field } from "formik";

import {
  FormGroup,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
} from "@material-ui/core";

import GenericSelector from "../../../components/Selectors/GenericSelector";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import USER_BYCUSTOMER_QUERY from "../../../graphql/queries/usersByCustomerQuery";

import { useTranslation } from "../../../lang";
const theme = createMuiTheme({});

const FormNotification = ({ errors, setFieldValue, values }) => {
  const [t] = useTranslation();
  const [expanded, setExpanded] = useState(true);
  const [users, setUsers] = useState([]);

  const [fetchUsers] = useLazyQuery(USER_BYCUSTOMER_QUERY, {
    onCompleted(d) {
      setUsers(d.usersByCustomer);
    },
  });

  useEffect(() => {
    if (values?.customerId) {
      fetchUsers({
        variables: {
          customerId: values.customerId,
        },
      });
    }
  }, [values]);

  const columns = [
    {
      dataField: "firstName",
      text: t("Nombre"),
      sort: true,
    },
    {
      dataField: "lastName",
      text: t("Apellido"),
      sort: true,
    },
    {
      dataField: "userName",
      text: t("Usuario"),
      sort: true,
    },
    {
      dataField: "email",
      text: t("Email"),
      sort: true,
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className="summaryTitleForm"
        >
          <Typography>{t("Notificar via email a")}</Typography>
        </AccordionSummary>
        <AccordionDetails className="reportPlanningSummaryDetail">
          <Grid container>
            <Grid item xs={5}>
              <FormGroup>
                {users && (
                  <GenericSelector
                    callbackOptionSelected={(usersSelected) =>
                      setFieldValue(
                        "usersDestinationsId",
                        usersSelected.map((us) => us.value)
                      )
                    }
                    columns={columns}
                    keyField="id"
                    labelField="userName"
                    isMulti
                    title={t("Usuarios")}
                    data={users}
                    defaultValue={values?.usersDestinationsId}
                  />
                )}
                {errors?.usersDestinationsId && (
                  <div className="input-feedback">
                    {errors?.usersDestinationsId}
                  </div>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={5}>
              <FormGroup>
                <label>{t("Otras direcciones")}</label>
                <Field
                  as="textarea"
                  name="mailList"
                  className={
                    errors.mailList
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                />
                {errors.mailList && (
                  <div className="input-feedback">{errors.mailList}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label>{t("Otras direcciones Cco")}</label>
                <Field
                  as="textarea"
                  name="mailListCco"
                  className={
                    errors.mailListCco
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                />
                {errors.mailListCco && (
                  <div className="input-feedback">{errors.mailListCco}</div>
                )}
              </FormGroup>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </ThemeProvider>
  );
};

export default FormNotification;
