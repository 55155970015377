export const UPDATE_ISLOADING_MARKERS_INITIAL =
  "UPDATE_ISLOADING_MARKERS_INITIAL";

const _updateIsloadingMarkersInitial = (content) => ({
  type: UPDATE_ISLOADING_MARKERS_INITIAL,
  payload: content,
});

export const isloadingMarkersInitialUpdate = ({ isLoading } = {}) => (
  dispatch,
  getState
) => {
  // const {
  //   loading: { isLoadingMarkersInitial },
  // } = getState();
  getState();

  let newState = isLoading;

  return dispatch(_updateIsloadingMarkersInitial(newState));
};
