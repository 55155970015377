import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import PageTitle from "../../../components/Base/PageTitle";
import Structure from "../../../components/Base/Structure";
import CREATE_USER_MUTATION from "../../../graphql/mutations/createUserMutation";
import DELETE_USER_MUTATION from "../../../graphql/mutations/deleteUserMutation";
import UPDATE_USER_MUTATION from "../../../graphql/mutations/updateUserMutation";
import USERS_QUERY from "../../../graphql/queries/getUsersQuery";
import TableUser from "./List";
import "./User.scss";
import { useTranslation } from "../../../lang";

const ManageUserPage = ({ loggedUser }) => {
  const [t] = useTranslation();
  const paginationSizes = [10, 25, 50];
  const [total, setTotal] = useState(0);
  const [userList, setUserList] = useState([]);
  const [paginationState, setPaginationState] = useState({
    pageSize: paginationSizes[0],
    pageNumber: 1,
    sort: undefined,
    filter: null,
  });

  const [resultState, setResultState] = useState({
    isOpen: false,
    type: "success",
    msg: "",
  });

  const [getUsers] = useLazyQuery(USERS_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      if (d.users) {
        setUserList(d.users);
        setTotal(d.users.length);
      }
    },
  });

  const handleGetUsers = () => {
    getUsers({
      variables: {
        ...paginationState,
        paginate: true,
      },
    });
  };

  useEffect(() => {
    handleGetUsers();
  }, [paginationState]);

  const [createUser] = useMutation(CREATE_USER_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Usuario creado correctamente"),
      });
      handleGetUsers();
    },
    onError(err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: err.message,
      });
    },
  });

  const [updateUser] = useMutation(UPDATE_USER_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Usuario actualizado correctamente"),
      });
      handleGetUsers();
    },
    onError(err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: err.message,
      });
    },
  });

  const [deleteUser] = useMutation(DELETE_USER_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Usuario eliminado correctamente"),
      });
      handleGetUsers();
    },
    onError(d) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: t("Ocurrió un error"),
      });
    },
  });

  const handleDeleteUser = (id) => {
    deleteUser({
      variables: { userId: id },
    });
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setResultState({
      ...resultState,
      isOpen: false,
    });
  };

  return (
    <Structure>
      <Snackbar
        open={resultState.isOpen}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleCloseNotification}
          severity={resultState.type}
          variant="filled"
        >
          {resultState.msg}
        </MuiAlert>
      </Snackbar>
      <PageTitle sector={t("Administración")} title={t("Usuarios")} />
      <TableUser
        getUsers={handleGetUsers}
        userList={userList}
        paginationSizes={paginationSizes}
        paginationState={paginationState}
        setPaginationState={setPaginationState}
        total={total}
        setResultState={setResultState}
        createUser={createUser}
        updateUser={updateUser}
        deleteUser={handleDeleteUser}
        loggedUser={loggedUser}
      />
    </Structure>
  );
};

const _mapStateToProps = ({ user }) => {
  return {
    loggedUser: user,
  };
};

export default connect(_mapStateToProps, null)(ManageUserPage);
