import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const MantenimientoIcon = function () {
  return (
    <SvgIcon viewBox="0 0 26 26">
      <path
        d="M52.17,33.041a1.33,1.33,0,0,0-2.232-.617L46.82,35.541l-2.16-.359-.36-2.16L47.417,29.9a1.331,1.331,0,0,0-.623-2.233,7.336,7.336,0,0,0-8.877,9.007l-9.439,9.437a3.71,3.71,0,1,0,5.247,5.247l9.429-9.429a7.347,7.347,0,0,0,9.015-8.891Zm-3.045,5.893a5.777,5.777,0,0,1-5.945,1.378l-.458-.153-10.1,10.1a2.2,2.2,0,0,1-3.037,0,2.153,2.153,0,0,1,0-3.037L39.69,37.112l-.151-.456A5.775,5.775,0,0,1,46.011,29.1l-3.385,3.386.674,4.052,4.055.675,3.384-3.386a5.761,5.761,0,0,1-1.614,5.105ZM31.3,47.76a.781.781,0,1,0,.781.781A.781.781,0,0,0,31.3,47.76Z"
        transform="translate(-27.392 -27.448)"
      />
    </SvgIcon>
  );
};

export default MantenimientoIcon;
