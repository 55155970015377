import gql from "graphql-tag";

const CREATE_ROAD_MUTATION = gql`
  mutation CreateRoad(
    $name: String!
    $description: String
    $hasDetourAlarm: Boolean
    $detourMeters: Float
    $maxVelocity: Float
    $hasVelocityAlarm: Boolean
    $customerId: Int!
    $assets: [Int]
    $geofences: [Int]
  ) {
    createRoad(
      name: $name
      description: $description
      hasDetourAlarm: $hasDetourAlarm
      detourMeters: $detourMeters
      maxVelocity: $maxVelocity
      hasVelocityAlarm: $hasVelocityAlarm
      customerId: $customerId
      assets: $assets
      geofences: $geofences
    ) {
      id
    }
  }
`;

export default CREATE_ROAD_MUTATION;
