import React from "react";
import Select from "react-select";
import { FixedSizeList } from "react-window";
import { useTranslation } from "../../lang";

const height = 20;

const CustomerSelect = ({
  customerOptions,
  handleChangeCustomer,
  defaultValue,
}) => {
  const [t] = useTranslation();
  const List = (props) => {
    const { options, children, maxHeight, getValue } = props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * height;

    return (
      <FixedSizeList
        height={maxHeight}
        itemCount={children.length}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </FixedSizeList>
    );
  };

  const ListMemo = React.memo(List);
  return (
    <Select
      classNamePrefix="CustomerSelect"
      placeholder={t("Clientes")}
      isSearchable
      isMulti
      name="customers"
      options={customerOptions}
      onChange={(values) => handleChangeCustomer(values, false)}
      defaultValue={defaultValue}
      components={{ ListMemo }}
    />
  );
};

export default CustomerSelect;
