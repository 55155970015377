import gql from "graphql-tag";

const CLONE_EVENT_MUTATION = gql`
  mutation CloneEvent($deviceIdSource: Int, $deviceIdDestination: Int) {
    cloneEvent(
      deviceIdSource: $deviceIdSource
      deviceIdDestination: $deviceIdDestination
    ) {
      id
    }
  }
`;

export default CLONE_EVENT_MUTATION;
