import React from "react";

/** @description Componente para subir foto al perfil del usuario. Utilizada en el account button
 */

const Upload = ({ file, title, onChange }) => {
  //const [newFile, setNewFile] = useState();

  const handleChange = (fileData) => {
    fileData.persist();
    const fr = new FileReader();

    fr.addEventListener("load", (e) => {
      onChange(e.target.result);
    });
    if (fileData && fileData.target && fileData.target.files) {
      const fileContent = URL.createObjectURL(fileData.target.files[0]);

      //setNewFile(fileContent);

      fr.readAsDataURL(fileData.target.files[0]);
    }
  };

  return (
    <div className="image_upload">
      <label
        id="lblProfilePicture"
        htmlFor="fileProfilePicture"
        className="btn btn-primary"
      >
        {title}
        <input
          id="fileProfilePicture"
          type="file"
          accept="image/*"
          onChange={(e) => handleChange(e)}
          onClick={(event) => {
            event.target.value = null;
          }}
          hidden
        />
      </label>
      {file && (
        <img
          src={file}
          alt="Profile"
          style={{
            objectFit: "contain",
            // display: file ? "inline" : "none",
            width: "128px",
            height: "128px",
            marginLeft: "20px",
            // marginRight: "20px",
          }}
        />
      )}
    </div>
  );
};

// Upload.propTypes = {
//   onChange: PropTypes.func,
//   t: PropTypes.func,
// };

export default Upload;
