import gql from "graphql-tag";

const SERVICE_PERFORMED_QUERY = gql`
  query GetServicePerformed(
    $assetIds: [Int]
    $startDate: Datetime
    $endDate: Datetime
    $days: [WeekDaysEnum]
  ) {
    getServicePerformed(
      assetIds: $assetIds
      startDate: $startDate
      endDate: $endDate
      days: $days
    ) {
      id
      asset {
        id
        name
        licensePlate
      }
      serviceType {
        id
        description
      }
      date
      kms
      marca
      supplier
      notes
    }
  }
`;

export default SERVICE_PERFORMED_QUERY;
