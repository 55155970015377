import React from "react";

import { IconButton } from "@material-ui/core";

import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import { GetType } from "../../utils/generalUtils";

const AssetState = ({ point }) => {
  // if (point.mid === 13672) {
  //   console.log("Point: ", point);
  // }

  return (
    <IconButton
      style={{ marginTop: "25%" }}
      className={`assetState ${GetType(point.typ)}`}
    >
      <LocalShippingIcon />
    </IconButton>
  );
};

export default AssetState;
