import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Structure from "../../../components/Base/Structure";
import ASSIGN_GUIDE_NUMBER_MUTATION from "../../../graphql/mutations/assignGuideNumber";
import DELETE_GUIDE_NUMBER_MUTATION from "../../../graphql/mutations/deleteGuideNumber";
import GUIDE_NUMBERS_QUERY from "../../../graphql/queries/guideNumersQuery";
import TableGuides from "./List";
import "./Role.scss";
import PageTitle from "../../../components/Base/PageTitle";
import { useTranslation } from "../../../lang";

const ManageGuidNumberPage = ({}) => {
  const [t] = useTranslation();
  const paginationSizes = [10, 25, 50];
  const [total, setTotal] = useState(0);
  const [guideNumbersList, setGuideNumbersList] = useState([]);
  const [paginationState, setPaginationState] = useState({
    pageSize: paginationSizes[0],
    pageNumber: 1,
    sort: undefined,
    filter: null,
  });

  const [resultState, setResultState] = useState({
    isOpen: false,
    type: "success",
    msg: "",
  });

  const [guidNumbers] = useLazyQuery(GUIDE_NUMBERS_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      if (d.guideNumbers) {
        setGuideNumbersList(d.guideNumbers);
        setTotal(d.guideNumbers.length);
      }
    },
  });

  const handleGetGuideNumbers = () => {
    guidNumbers({
      variables: {
        ...paginationState,
        paginate: true,
      },
    });
  };

  useEffect(() => {
    handleGetGuideNumbers();
  }, [paginationState]);

  const [assignGuideNumber] = useMutation(ASSIGN_GUIDE_NUMBER_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Número de guía asignado correctamente"),
      });
      handleGetGuideNumbers();
    },
    onError(err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: err.message,
      });
    },
  });

  const [deleteGuideNumber] = useMutation(DELETE_GUIDE_NUMBER_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: "Número de guía desasignado correctamente",
      });
      handleGetGuideNumbers();
    },
    onError(d) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: t("Ocurrió un error"),
      });
    },
  });

  const handleDeleteGuideNumber = (id) => {
    deleteGuideNumber({
      variables: { id },
    });
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setResultState({
      ...resultState,
      isOpen: false,
    });
  };

  return (
    <Structure>
      <Snackbar
        open={resultState.isOpen}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleCloseNotification}
          severity={resultState.type}
          variant="filled"
        >
          {resultState.msg}
        </MuiAlert>
      </Snackbar>
      <PageTitle
        sector={t("Administración")}
        title={t("Asignar números de guía")}
      />
      <TableGuides
        paginationSizes={paginationSizes}
        total={total}
        paginationState={paginationState}
        setPaginationState={setPaginationState}
        guideNumbersList={guideNumbersList}
        assignGuideNumber={assignGuideNumber}
        getGuideNumbers={handleGetGuideNumbers}
        deleteGuideNumber={handleDeleteGuideNumber}
      />
    </Structure>
  );
};

export default ManageGuidNumberPage;
