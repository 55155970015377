import gql from "graphql-tag";

const CREATE_USER_MUTATION = gql`
  mutation CreateUser(
    $userName: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $photo: Upload
    $email: String!
    $contactNumber: String
    $phone: String
    $celPhone: String
    $status: Boolean
    $timeSession: Int
    $allowGMaps: Boolean
    $roles: [Int!]
    $customerId: Int!
    $groupId: Int
  ) {
    createUser(
      userName: $userName
      password: $password
      firstName: $firstName
      lastName: $lastName
      photo: $photo
      email: $email
      contactNumber: $contactNumber
      phone: $phone
      celPhone: $celPhone
      status: $status
      timeSession: $timeSession
      allowGMaps: $allowGMaps
      roles: $roles
      customerId: $customerId
      groupId: $groupId
    ) {
      id
    }
  }
`;

export default CREATE_USER_MUTATION;
