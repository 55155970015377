import gql from "graphql-tag";

const DRIVERS_PAGINATED_QUERY = gql`
  query DriversPaginated(
    $pageSize: Int
    $pageNumber: Int
    $sort: DriverSortInput
    $searchText: String
    $paginate: Boolean
    $customerId: Int
  ) {
    driversPaginated(
      pageSize: $pageSize
      pageNumber: $pageNumber
      sort: $sort
      searchText: $searchText
      paginate: $paginate
      customerId: $customerId
    ) {
      count
      items {
        id
        firstName
        lastName
        fullName
      }
    }
  }
`;

export default DRIVERS_PAGINATED_QUERY;
