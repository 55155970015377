import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ManageStructure from '../../components/Base/ManageStructure';

import './Base.scss';

export default class MapBasePage extends Component {
    static propTypes = {
        // eslint-disable-next-line react/forbid-prop-types
        match: PropTypes.object
    };

    static defaultProps = {
        match: {}
    };

    render() {
        const { match } = this.props;

        return (
            <ManageStructure matchProps={match}>
                <div>Hello! this is the manage page!</div>
            </ManageStructure>
        );
    }
}
