import React from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import "./DropdownList.scss";

const DropdownList = ({
  dropdownId,
  dropdownHeader,
  dropdownItems,
  anchorEl,
  handleClose,
}) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    id={dropdownId}
    anchorEl={anchorEl}
    keepMounted
    open={Boolean(anchorEl)}
    onClose={handleClose}
    className="dropdown-list"
  >
    {!isEmpty(dropdownHeader) && (
      <MenuItem className="dropdown-list__header">{dropdownHeader}</MenuItem>
    )}

    {!isEmpty(dropdownItems) &&
      dropdownItems.map(({ item: dropdownItem, handleOnClick }, index) => (
        <MenuItem
          key={`dropdown-item-${index}`}
          className="dropdown-list__item" // "dropdown-item"
          onClick={handleOnClick}
        >
          {dropdownItem}
        </MenuItem>
      ))}
  </Menu>
);

DropdownList.propTypes = {
  dropdownId: PropTypes.string,
  dropdownHeader: PropTypes.node,
  dropdownItems: PropTypes.array,
  anchorEl: PropTypes.object,
  handleClose: PropTypes.func,
};

DropdownList.defaultProps = {
  dropdownId: "",
  dropdownHeader: null,
  dropdownItems: [],
  anchorEl: {},
  handleClose: () => {},
};

export default DropdownList;
