// eslint-disable-next-line
// export const BASE_API_URL = 'http://52.152.96.229';
// export const BASE_API_URL = 'http://localhost:3500';

export const BASE_API_URL = process.env.REACT_APP_GRAPHQL_URI;
export const BASE_SUBSCRIPTIONS_URL =
  process.env.REACT_APP_GRAPHQL_SUBSCRIPTION_URI;

export const POSITION_DAY_NAME = {
  DOMINGO: 0,
  LUNES: 1,
  MARTES: 2,
  MIERCOLES: 3,
  JUEVES: 4,
  VIERNES: 5,
  SABADO: 6,
};
