import * as React from "react";

function VanIcon(props) {
  return (
    <div style={{ justifyContent: "center" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height={24}
        width={24}
        viewBox="0 0 24 24"
        {...props}
      >
        <path fill="none" />
        <path d="M17 5H3a2 2 0 00-2 2v9h2c0 1.65 1.34 3 3 3s3-1.35 3-3h5.5c0 1.65 1.34 3 3 3s3-1.35 3-3H23v-5l-6-6zM3 11V7h4v4H3zm3 6.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm7-6.5H9V7h4v4zm4.5 6.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zM15 11V7h1l4 4h-5z" />
      </svg>
    </div>
  );
}

export default React.memo(VanIcon);
