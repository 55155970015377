import React from "react";

import { roundNumber, secondsToTime } from "../../utils/generalUtils";

import Table from "../Base/Table";

import { ExpandLess, ExpandMore } from "@material-ui/icons";

import { useTranslation } from "../../lang";

const AssetTable = ({ data, assetRowEvents }) => {
  const [t] = useTranslation();
  const { driverProms, summary, totals } = data;
  const assetSummary = summary.filter((s) => s.assetId);

  const headerFormatter = (column, colIndex, { sortElement }) => {
    const { order } = sortElement.props;
    let promValue;
    let totalValue;
    switch (colIndex) {
      case 0:
        promValue = t("Promedio");
        totalValue = t("Total");
        break;
      case 1:
        promValue = roundNumber(driverProms.scoreProm, 2);
        totalValue = roundNumber(totals.scoreTot, 2);
        break;
      case 2:
        promValue = roundNumber(driverProms.kmsRecProm, 2);
        totalValue = roundNumber(totals.kmsRectot, 2);
        break;
      case 3:
        promValue = secondsToTime(driverProms.conductionTimeProm);
        totalValue = secondsToTime(totals.conductionTimeTot);
        break;
      case 4:
        promValue = roundNumber(driverProms.cmbConsumedProm, 2);
        totalValue = roundNumber(totals.cmbConsumedTot, 2);
        break;
      case 5:
        promValue = roundNumber(driverProms.eficiencyProm, 2);
        totalValue = roundNumber(totals.eficiencyTot, 2);
        break;
      default:
        promValue = "";
        break;
    }
    return (
      <>
        <div className="colDBHeader">
          {column.text}
          {order === "desc" ? (
            <ExpandMore
              className="sortIconDB"
              style={{ color: order ? "white" : "gray" }}
            />
          ) : (
            <ExpandLess
              className="sortIconDB"
              style={{ color: order ? "white" : "gray" }}
            />
          )}
        </div>
        <div className="colDBProm">{promValue}</div>
        <div className="colDBProm">{totalValue}</div>
      </>
    );
  };

  const itemStyle = (status) => {
    let style = {};
    switch (status) {
      case 1:
        style.backgroundColor = "#348500";
        break;
      case 2:
        style.backgroundColor = "#DB8700";
        break;
      case 3:
        style.backgroundColor = "#E91C1C";
        break;
      case 4:
        style.backgroundColor = "#47565E";
        break;
      default:
        break;
    }
    style.opacity = "0.48";
    style.borderLeft = "1px solid #121212";
    style.borderBottom = "1px solid #121212";
    return style;
  };

  const columns = [
    {
      dataField: "assetName",
      text: t("Nombre"),
      headerFormatter: headerFormatter,
      headerStyle: () => ({ width: "15%" }),
      style: () => ({
        backgroundColor: "#ffffff0d",
        opacity: "0.48",
        borderBottom: "1px solid #121212",
        cursor: "pointer",
      }),
      sort: true,
    },
    {
      dataField: "scoreProm",
      text: t("Puntaje"),
      headerFormatter: headerFormatter,
      headerStyle: () => ({ width: "10%" }),
      style: (cell, row, rowIndex, colIndex) => itemStyle(row.scoreStatus),
      formatter: (cell, row) => roundNumber(row.scoreProm, 2),
      sort: true,
    },
    {
      dataField: "kmsRecSum",
      text: t("Km Rec"),
      headerFormatter: headerFormatter,
      headerStyle: () => ({ width: "10%" }),
      style: (cell, row, rowIndex, colIndex) => itemStyle(row.kmsRecStatus),
      formatter: (cell, row) => {
        return roundNumber(row.kmsRecSum, 2);
      },
      sort: true,
    },
    {
      dataField: "conductionTimeSum",
      text: t("Conducción"),
      headerFormatter: headerFormatter,
      headerStyle: () => ({ width: "15%" }),
      formatter: (cell, row) => secondsToTime(row.conductionTimeSum),
      style: (cell, row, rowIndex, colIndex) =>
        itemStyle(row.conductionTimeStatus),
      sort: true,
    },
    {
      dataField: "cmbConsumedSum",
      text: t("Combustible"),
      headerFormatter: headerFormatter,
      headerStyle: () => ({ width: "15%" }),
      style: (cell, row, rowIndex, colIndex) =>
        itemStyle(row.cmbConsumedStatus),
      sort: true,
    },
    {
      dataField: "eficiencyProm",
      text: t("Eficiencia"),
      headerFormatter: headerFormatter,
      headerStyle: () => ({ width: "10%" }),
      style: (cell, row, rowIndex, colIndex) => itemStyle(row.eficiencyStatus),
      sort: true,
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        data={assetSummary}
        keyField="assetId"
        hideSearch
        headerClasses="remoteTableHeader DBTableHeader"
        bodyClasses="remoteTableBody DBTableBody"
        rowEvents={assetRowEvents}
      />
    </>
  );
};

export default AssetTable;
