// eslint-disable react/forbid-prop-types
import React from "react";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useLocation, useHistory } from "react-router-dom";

import PropTypes from "prop-types";
import StepsList from "./StepsList";
import "./DaySteps.scss";
import { format, parse } from "date-fns";
import { GetDateFormat } from "../../../utils/generalUtils";
import { ContactsOutlined, ContactSupportOutlined } from "@material-ui/icons";
import { useTranslation } from "../../../lang";

//Es un contenedor de steps diarios
const DaySteps = ({
  classDayStep,
  classStep,
  selectStep,
  markerJourney = {},
  markerInfo = {},
  toggleFillMarkerJourney2,
  toggleFillGenericPopup,
  dateOptionSelected = null,
}) => {
  const [t] = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const handleAccordionDetailClick = (
    indexDayStep,
    markerJourney,
    toggleFillMarkerJourney2
  ) => {
    if (location.pathname !== "/map") {
      history.push({
        pathname: "/map",
        state: {
          journeyData: markerJourney,
          markerInfo: markerInfo,
          lateralPanel: {
            panelType: "GeneralInfo",
            panelInitialStatus: {
              firstTimeAfterRedirect: true,
              selectedSearchRange: dateOptionSelected,
              selectStep: indexDayStep,
            },
          },
        },
      });
    } else {
      selectStep({
        dayStep: -1,
        indexDayStep,
        markerJourney,
        toggleFillMarkerJourney2,
      });
      toggleFillGenericPopup({ popupInfo: {} });
    }
  };
  const daySteps = (
    <div className="dayStepContainer">
      {markerJourney.daySteps.map((dayStep, indexDayStep) => (
        <div key={indexDayStep}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon color="primary" />}
              aria-controls={`panel-content-${indexDayStep}`}
              id={`panel-header-${indexDayStep}`}
              className="infoBlockDark"
            >
              {/* <div className="expandable-panel__tabs-item expandable-panel__info-block expandable-panel__info-block--dark"> */}
              <div
                className={classDayStep({ indexDayStep })}
                onClick={() =>
                  handleAccordionDetailClick(
                    indexDayStep,
                    markerJourney,
                    toggleFillMarkerJourney2
                  )
                }
              >
                <span className="day-step-pointer">
                  {format(
                    new Date(parse(dayStep.date, "yyyy-MM-dd", new Date())),
                    GetDateFormat(t("userLanguage"), true)
                  )}
                </span>
              </div>
              {/* </div> */}
            </AccordionSummary>
            <AccordionDetails className="infoBlockDark">
              <StepsList
                dayStep={dayStep.date}
                classStep={classStep}
                selectStep={selectStep}
                indexDayStep={indexDayStep}
                markerJourney={markerJourney}
                toggleFillMarkerJourney2={toggleFillMarkerJourney2}
                toggleFillGenericPopup={toggleFillGenericPopup}
              />
            </AccordionDetails>
          </Accordion>
        </div>
      ))}
    </div>
  );
  return daySteps;
};

DaySteps.propTypes = {
  classStep: PropTypes.func,
  selectStep: PropTypes.func,
  markerJourney: PropTypes.object,
  toggleFillMarkerJourney2: PropTypes.func,
  classDayStep: PropTypes.func,
  toggleFillGenericPopup: PropTypes.func,
};

DaySteps.defaultProps = {
  classStep: () => {},
  selectStep: () => {},
  markerJourney: {},
  toggleFillMarkerJourney2: () => {},
  classDayStep: () => {},
  toggleFillGenericPopup: () => {},
};

export default DaySteps;
