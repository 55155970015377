import React, { useEffect, useState } from "react";

import { Grid, IconButton } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { format } from "date-fns";

import GenericSelector from "../Selectors/GenericSelector";
import DateSelector from "../Selectors/DateSelector";
import PlusSquare from "../../public/icons/plus-square.png";

import { useTranslation } from "../../lang";
import { GetDateFormat } from "../../utils/generalUtils";

const FilterRevision = ({
  mid,
  setMidSelected,
  assetList,
  openPanelAux,
  changePanelAuxType,
  changePanelAuxTitle,
  handleGetRevisions,
  language,
}) => {
  const [t] = useTranslation();
  const [assetsSelected, setAssetsSelected] = useState([]);

  const dateFormat = GetDateFormat(language);
  const [fechas, setFechas] = useState({
    fechaDesde: format(new Date(new Date() - 60 * 60 * 1000), dateFormat),
    fechaHasta: format(new Date(), dateFormat),
  });
  const [dias, setDias] = useState();

  const columns = [
    {
      dataField: "name",
      text: t("Nombre"),
      sort: true,
    },
    {
      dataField: "licensePlate",
      text: t("Patente"),
      sort: true,
    },
    {
      dataField: "internalNumber",
      text: t("Interno"),
      sort: true,
    },
    {
      dataField: "driver.firstName",
      text: t("Chofer"),
      sort: true,
    },
    {
      dataField: "typeMobile.description",
      text: t("Tipo"),
      sort: true,
    },
  ];

  const onAssetSelect = (assets) => {
    setAssetsSelected(assets?.map((a) => a.value));
  };

  const handleChangePeriod = (evt) => {
    if (evt.fechaDesde && evt.fechaHasta) {
      setFechas({
        fechaDesde: evt.fechaDesde,
        fechaHasta: evt.fechaHasta,
      });
    }

    if (evt.dias) {
      setDias(evt.dias);
    }
  };

  useEffect(() => {
    changePanelAuxTitle(t("Nueva Revisión"));
  }, [language]);

  return (
    <Grid container className="revisionFilterContainer">
      <Grid item xs={5} style={{ marginTop: "-25px" }}>
        {!mid && (
          <GenericSelector
            callbackOptionSelected={(value) => onAssetSelect(value)}
            columns={columns}
            isMulti
            keyField="id"
            labelField="name"
            title={t("Móviles")}
            data={assetList}
          />
        )}
      </Grid>

      <Grid item xs={1} />

      <Grid item xs={4}>
        <DateSelector onChange={handleChangePeriod} />
      </Grid>
      <Grid item xs={1}>
        <IconButton
          onClick={() => {
            handleGetRevisions(
              mid ? [mid] : assetsSelected,
              fechas.fechaDesde,
              fechas.fechaHasta,
              dias
            );
          }}
        >
          <Search />
        </IconButton>
      </Grid>
      <Grid item xs={1}>
        <IconButton
          onClick={() => {
            changePanelAuxTitle(t("Nueva Revisión"));
            changePanelAuxType(t("Revision"));
            openPanelAux();
            mid && setMidSelected(mid);
          }}
        >
          <img src={PlusSquare} style={{ width: "20px", height: "20px" }} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default FilterRevision;
