import React, { useState } from "react";
import { useMutation } from "@apollo/client";

import { Button, FormGroup, Grid, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import * as Yup from "yup";
import { Field, Formik } from "formik";

import CHANGE_PASSWORD from "../../graphql/mutations/changePassword";
import { useTranslation } from "../../lang";

const Form = ({ closeModal }) => {
  const [t] = useTranslation();
  const [resultState, setResultState] = useState({
    isOpen: false,
    type: "success",
    msg: t(""),
  });

  const [changePassword] = useMutation(CHANGE_PASSWORD, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Usuario actualizado correctamente"),
      });
    },
    onError(err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: t(err.message),
      });
    },
  });

  const initialValues = {
    currentPassword: "",
    newPassword1: "",
    newPassword2: "",
  };

  const yupSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .required(t("Requerido"))
      .min(6, t("Debe tener 6 o más caracteres")),
    newPassword1: Yup.string()
      .required(t("Requerido"))
      .min(6, t("Debe tener 6 o más caracteres")),
    newPassword2: Yup.string()
      .required(t("Requerido"))
      .min(6, t("Debe tener 6 o más caracteres")),
  });

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setResultState({
      ...resultState,
      isOpen: false,
    });
  };

  return (
    <>
      <Snackbar
        open={resultState.isOpen}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleCloseNotification}
          severity={resultState.type}
          variant="filled"
        >
          {resultState.msg}
        </MuiAlert>
      </Snackbar>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={yupSchema}
        onSubmit={async (values) => {
          const user = JSON.parse(localStorage.getItem("loggedUser"));

          await changePassword({
            variables: {
              userId: user.id,
              currentPassword: values.currentPassword,
              newPassword1: values.newPassword1,
              newPassword2: values.newPassword2,
            },
          });
        }}
      >
        {(props) => {
          const { errors, handleSubmit, touched } = props;

          return (
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <label className="formLabelFormik">
                  {t("Contraseña Actual")}
                </label>
                <Field
                  type="password"
                  name="currentPassword"
                  tabIndex="0"
                  autoFocus
                  className={
                    errors.currentPassword && touched.currentPassword
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  placeholder={t("Contraseña Actual")}
                />
                {(errors.currentPassword || touched.currentPassword) && (
                  <div className="input-feedback">{errors.currentPassword}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">
                  {t("Nueva Contraseña")}
                </label>
                <Field
                  type="password"
                  name="newPassword1"
                  tabIndex="0"
                  className={
                    errors.newPassword1
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  placeholder={t("Nueva Contraseña")}
                />
                {errors.newPassword1 && (
                  <div className="input-feedback">{errors.newPassword1}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">
                  {t("Confirmación Nueva Contraseña")}
                </label>
                <Field
                  type="password"
                  name="newPassword2"
                  tabIndex="0"
                  className={
                    errors.newPassword2
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  placeholder={t("Confirmación Nueva Contraseña")}
                />
                {errors.newPassword2 && (
                  <div className="input-feedback">{errors.newPassword2}</div>
                )}
              </FormGroup>

              <Grid
                item
                lg={12}
                style={{
                  marginTop: "50px",
                  textAlign: "center",
                }}
              >
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  tabIndex="0"
                >
                  {t("Guardar")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  tabIndex="0"
                  style={{ marginLeft: "20px" }}
                  onClick={() => {
                    closeModal();
                  }}
                >
                  {t("Cancelar")}
                </Button>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default Form;
