import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useLazyQuery, useQuery, useMutation } from "@apollo/client";

import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import Structure from "../../../components/Base/Structure";
import PageTitle from "../../../components/Base/PageTitle";
import List from "./List";

import PLANNING_REPORTS_QUERY from "../../../graphql/queries/getPlanningReportsQuery";
import GET_REPORTTYPES_QUERY from "../../../graphql/queries/getReportTypesQuery";
import CUSTOMERS_QUERY from "../../../graphql/queries/getCustomersQuery";
import GROUPS_BYCUSTOMERMIN_QUERY from "../../../graphql/queries/groupsByCustomerMinQuery";

import CREATE_PLANNINGREPORT_MUTATION from "../../../graphql/mutations/createPlanningReportMutation";
import UPDATE_PLANNINGREPORT_MUTATION from "../../../graphql/mutations/updatePlanningReportMutation";
import DELETE_PLANNINGREPORT_MUTATION from "../../../graphql/mutations/deletePlanningReportMutation";

import "./style.scss";

import { useTranslation } from "../../../lang";

const ReportPlanning = ({ selectedCustomers, user }) => {
  const [t] = useTranslation();
  const [planningReportsList, setPlanningReportList] = useState([]);
  const [reportTypes, setReportTypes] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [groups, setGroups] = useState([]);

  const [resultState, setResultState] = useState({
    isOpen: false,
    type: "success",
    msg: "",
  });

  useEffect(() => {
    const translatedReportTypes = reportTypes.map((type) => ({
      ...type,
      label: t(type.description),
    }));
    setReportTypes(translatedReportTypes);
  }, [user.language]);

  const {} = useQuery(GET_REPORTTYPES_QUERY, {
    onCompleted(d) {
      setReportTypes(
        d.getReportTypes.map((tr) => ({
          value: tr.value,
          label: t(tr.description),
          description: tr.description,
        }))
        // d.__type.enumValues.map((tr) => ({
        //   value: tr.value,
        //   label: t(tr.description),
        //   description: tr.description,
        // }))
      );
    },
  });

  const [fetchGroups] = useLazyQuery(GROUPS_BYCUSTOMERMIN_QUERY, {
    onCompleted(d) {
      setGroups(d.groupsByCustomer);
    },
  });

  const handleGetGroups = (customerId) => {
    fetchGroups({
      variables: {
        customerId,
      },
    });
  };

  const {} = useQuery(CUSTOMERS_QUERY, {
    onCompleted(d) {
      setCustomers(d.customers.items);
    },
  });

  const [fetchPlanningReports] = useLazyQuery(PLANNING_REPORTS_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      console.debug("dplanningReport: ", d.planningReport);
      const customers = selectedCustomers.map((c) => c.value);
      const planningsThisCustomer = customers.includes(-1)
        ? d.planningReport
        : d.planningReport.filter((p) => customers.includes(p.customerId));

      const translatedPlanningsThisCustomer = planningsThisCustomer.map(
        (row) => {
          return {
            ...row,
            typeReportID: t(row.typeReportID),
          };
        }
      );
      setPlanningReportList(translatedPlanningsThisCustomer);
    },
  });

  const [createPlanningReport] = useMutation(CREATE_PLANNINGREPORT_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Reporte planificado creado correctamente"),
      });
      fetchPlanningReports();
    },
    onError(err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: err.message,
      });
    },
  });

  const [updatePlanningReport] = useMutation(UPDATE_PLANNINGREPORT_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Reporte planificado actualizado correctamente"),
      });
      fetchPlanningReports();
    },
    onError(err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: err.message,
      });
    },
  });

  const [deletePlanningMutation] = useMutation(DELETE_PLANNINGREPORT_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Reporte planificado eliminado correctamente"),
      });
      fetchPlanningReports();
    },
    onError(err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: err.message,
      });
    },
  });

  const handleCreatePlanningReport = (params) => {
    //Validations non representable with yup
    createPlanningReport({
      variables: params,
    });
  };

  const handleUpdatePlanningReport = (params) => {
    //Validations non representable with yup
    updatePlanningReport({
      variables: params,
    });
  };

  const handleDeletePlanningReport = (id) => {
    deletePlanningMutation({
      variables: {
        id: id,
      },
    });
  };

  useEffect(() => {
    if (Array.isArray(selectedCustomers) && selectedCustomers.length > 0) {
      fetchPlanningReports();
    } else {
      setPlanningReportList([]);
    }
  }, [selectedCustomers]);

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setResultState({
      ...resultState,
      isOpen: false,
    });
  };

  return (
    <Structure>
      <Snackbar
        open={resultState.isOpen}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleCloseNotification}
          severity={resultState.type}
          variant="filled"
        >
          {resultState.msg}
        </MuiAlert>
      </Snackbar>
      <PageTitle
        sector={t("Administración")}
        title={t("Planificador de Reportes")}
      />
      <List
        planningReportList={planningReportsList}
        reportTypes={reportTypes}
        customers={customers}
        groups={groups}
        getGroups={handleGetGroups}
        handleCreatePlanningReport={handleCreatePlanningReport}
        handleUpdatePlanningReport={handleUpdatePlanningReport}
        handleDeletePlanningReport={handleDeletePlanningReport}
      />
    </Structure>
  );
};

const _mapStateToProps = ({ groups, user }) => {
  return {
    selectedCustomers: groups.selectedCustomers,
    user,
  };
};

export default connect(_mapStateToProps, null)(ReportPlanning);
