import gql from "graphql-tag";

const DELETE_SERVICE_MUTATION = gql`
  mutation DeleteService($id: Int!) {
    deleteService(id: $id) {
      id
    }
  }
`;

export default DELETE_SERVICE_MUTATION;
