import gql from "graphql-tag";

const FUELCONSUMPTION_REPORT_QUERY = gql`
  query consumptionReport(
    $assetsId: [Int]!
    $dateFrom: Datetime!
    $dateTo: Datetime!
  ) {
    consumptionReport(
      assetsId: $assetsId
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      cid
      consumption
      hDet
      km
      mid
      nInt
      nMov
      pat
    }
  }
`;

export default FUELCONSUMPTION_REPORT_QUERY;
