import gql from "graphql-tag";

const CREATE_ASSET_MUTATION = gql`
  mutation CreateAsset(
    $name: String!
    $licensePlate: String
    $brand: String
    # $patent: String # ??
    $color: String
    $driverId: Int
    $typeMobileId: Int
    $customerId: Int
    $internalNumber: String
    $fuelCutWiring: Boolean
    $fuelCutWiringDate: Datetime
    $cutSolenoidValve: Boolean
    $electroValveInstallationDate: Datetime
    $mainTension: String
    # $workingHoursId: Int
    $maximumSpeed: Float
    $initialRealOdometer: Float
    $photo: Upload
    # $icon: String
    # $mobileImageType: String # ??
    # $photoFull: String # ??
    $model: String
    $year: Int
    $fuelTankCapacity: Float
    $fuelTankCapacityMax: Float
    # $invalidMaximumSpeed: Float # ??
    # $controlInvalidSpeed: Boolean # ??
    # $imei: String # ??
    $initialRealHorometer: Int
    $satPatAlerts: Boolean # $operationNumber: String # ?? # $daysStopMaximum: Int # ?? # $averageDailyKmTheoretical: Float # ?? # $internalCustomer: String # ?? # $theoreticalAverageDailyHorometer: Int # ??
    $itSupportsOBD2: Boolean # $MaxSpeed: [MaximumSpeed] # ??
  ) {
    createAsset(
      name: $name
      licensePlate: $licensePlate
      brand: $brand
      # patent: $patent # ??
      color: $color
      driverId: $driverId
      typeMobileId: $typeMobileId
      customerId: $customerId
      internalNumber: $internalNumber
      fuelCutWiring: $fuelCutWiring
      fuelCutWiringDate: $fuelCutWiringDate
      cutSolenoidValve: $cutSolenoidValve
      electroValveInstallationDate: $electroValveInstallationDate
      mainTension: $mainTension
      # workingHoursId: $workingHoursId
      maximumSpeed: $maximumSpeed
      initialRealOdometer: $initialRealOdometer
      photo: $photo
      # icon: $icon
      # mobileImageType: $mobileImageType # ??
      # photoFull: $photoFull # ??
      model: $model
      year: $year
      fuelTankCapacity: $fuelTankCapacity
      fuelTankCapacityMax: $fuelTankCapacityMax
      # invalidMaximumSpeed: $invalidMaximumSpeed # ??
      # controlInvalidSpeed: $controlInvalidSpeed # ??
      # imei: $imei # ??
      initialRealHorometer: $initialRealHorometer
      satPatAlerts: $satPatAlerts # operationNumber: $operationNumber # ?? # daysStopMaximum: $daysStopMaximum # ?? # averageDailyKmTheoretical: $averageDailyKmTheoretical # ?? # internalCustomer: $internalCustomer # ?? # theoreticalAverageDailyHorometer: $theoreticalAverageDailyHorometer # ??
      itSupportsOBD2: $itSupportsOBD2 # MaxSpeed: [MaximumSpeed] # ??
    ) {
      id
    }
  }
`;

export default CREATE_ASSET_MUTATION;
