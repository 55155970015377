// eslint-disable react/forbid-prop-types
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import IconButton from "../../../public/icons/FileExportIcon";
import InputIcon from "@material-ui/icons/Input";
import TabPanelContent from "../../Base/TabPanelContent";
import SubHeader from "./SubHeader";
import DaySteps from "./DaySteps";
import { useTranslation } from "../../../lang";
import "./Journeys.scss";
import { isDictionaryEmpty } from "../../../utils/misc";

const JOURNEY_TAB_INDEX = 1;
const DEFAULT_DATE_OPTION_SELECTED = null;

const SummaryRow = ({ title, info }) => (
  <div className="expandable-panel__tabs-item flex between expandable-panel-journeys">
    <div>{title}</div>
    <div>{info}</div>
  </div>
);

SummaryRow.propTypes = {
  title: PropTypes.string,
  info: PropTypes.string,
};

SummaryRow.defaultProps = {
  title: "",
  info: "",
};

const Journey = ({
  // value2,
  value,
  markerJourney = {},
  markerInfo = {},
  index,
  toggleActivePanelAux,
  toggleFillMarkerJourney2,
  toggleFillMarkerJourney,
  toggleFillMarkerJourneyPersonalizado,
  toggleFillGenericPopup,
  isGeneralJourney,
  updateMarkerJourney,
  dateOptionSelected = null,
  onSelectedSearchRange,
}) => {
  const [t] = useTranslation();
  const [stepIndexState, setStepIndexState] = useState(-1);
  const [stepIndexDayState, setStepIndexDayState] = useState(-1);
  const [isStepSelected, setIsStepSelected] = useState(false);

  // When component is unmounted, remove all journeys components
  useEffect(() => {
    return () => {
      updateMarkerJourney && updateMarkerJourney({});
    };
  }, []);

  useEffect(() => {
    if (value === JOURNEY_TAB_INDEX) {
      // To be executed when this tab is opened
    } else {
      // To be executed when this tab is closed
      onSelectedSearchRange(DEFAULT_DATE_OPTION_SELECTED);
    }
  }, [value]);

  const selectStep = ({
    indexStep,
    indexDayStep,
    markerJourney,
    toggleFillMarkerJourney2,
  }) => {
    /*console.log("indexStep: ", indexStep);
    console.log("indexDayStep: ", indexDayStep);
    console.log("Marker journey: ", markerJourney);
    console.log("Toggle: ", toggleFillMarkerJourney2);*/
    if (stepIndexDayState == indexDayStep && indexStep == stepIndexState) {
      setStepIndexState(-1);
    } else {
      setStepIndexState(indexStep);
      setStepIndexDayState(indexDayStep);
    }
    const newDaySteps = markerJourney.daySteps.map((d, i) => {
      let newDayStep = d;
      if (indexDayStep === i) {
        newDayStep = Object.assign({}, d, { visible: true });
        const newSteps = newDayStep.steps.map((s, sI) => {
          let newStep = s;
          if (sI == indexStep) {
            newStep = s.visible
              ? Object.assign({}, s, { visible: !s.visible })
              : Object.assign({}, s, { visible: true });
          } else {
            newStep = Object.assign({}, s, { visible: false });
          }
          return newStep;
        });
        newDayStep = Object.assign({}, newDayStep, {
          steps: newSteps,
        });
      } else {
        newDayStep = Object.assign({}, d, { visible: false });
        const newSteps = newDayStep.steps.map((s, sI) => {
          return Object.assign({}, s, { visible: false });
        });
        newDayStep = Object.assign({}, newDayStep, {
          steps: newSteps,
        });
      }
      return newDayStep;
    });
    setIsStepSelected(true);
    const newMarkerJourney = Object.assign({}, markerJourney, {
      daySteps: newDaySteps,
    });

    //console.log("New journey: ", newMarkerJourney);
    toggleFillMarkerJourney2({ markerJourney: newMarkerJourney });
  };

  const classStep = ({ indexStep, indexDayStep }) => {
    const className = classNames("row_journeys", {
      "row_journeys--selected":
        indexStep === stepIndexState && indexDayStep === stepIndexDayState,
    });
    return className;
  };

  const classDayStep = ({ indexDayStep }) => {
    const className = classNames(
      "expandable-panel__tabs-item expandable-panel__info-block-content day-step",
      {
        "expandable-panel__tabs-item expandable-panel__info-block-content day-step--selected":
          indexDayStep === stepIndexDayState,
      }
    );
    return className;
  };

  const handleSubHeaderDateSelected = (selected) => {
    onSelectedSearchRange(selected);
  };

  const periodContent = (
    <SubHeader
      parentId="Journey"
      markerId={markerInfo.mid}
      toggleFillMarkerJourney={toggleFillMarkerJourney}
      toggleFillMarkerJourneyPersonalizado={
        toggleFillMarkerJourneyPersonalizado
      }
      customPeriod={true}
      subComponent={
        <div className="input-icon-journeys">
          <IconButton
            onClick={toggleActivePanelAux}
            className="expandable-panel__actions__button"
          >
            <InputIcon />
          </IconButton>
        </div>
      }
      toggleFillGenericPopup={toggleFillGenericPopup}
      onDateOptionSelected={handleSubHeaderDateSelected}
      dateOptionSelected={dateOptionSelected}
    />
  );

  let journeyContent = (
    <div>
      {/* tab-panel__content-scrollable */}
      <div className="journeys-padding-top">{periodContent}</div>
    </div>
  );

  if (
    Object.keys(markerJourney).length !== 0 && //hay recorridos
    value == 1 && //es el apartado de recorridos
    Object.keys(markerInfo).length !== 0 && //hay info en el apartado de detalle de movil
    markerJourney.mid == markerInfo.mid //coinciden los elementos o debo buscar los recorridos del nuevo movil seleccionado
  ) {
    //Busqueda peresoza para buscar los recorridos
    if (!isGeneralJourney || (isGeneralJourney && !isStepSelected))
      toggleFillMarkerJourney2({ markerJourney: markerJourney });

    const { summary = {}, steps } = markerJourney;

    journeyContent = (
      <div className="tab-panel__content-scrollable journeys-padding-top">
        {!isGeneralJourney && periodContent}

        <div className="expandable-panel__tabs-item expandable-panel__info-block">
          <div className="expandable-panel__tabs-item expandable-panel__info-block-content">
            {/* <span className="text-color--title-component span-padding-journeys">
              Resumen
            </span>*/}
            <SummaryRow title={t("Ubicación inicial")} info={summary.uIni} />
            <SummaryRow title={t("Ubicación final")} info={summary.uFin} />
            <SummaryRow title={t("Duración de trabajo")} info={summary.tIng} />
            <SummaryRow
              title={t("Duración de la parada")}
              info={summary.tDet}
            />
            <SummaryRow title={t("Kilometraje")} info={summary.odo} />
            <SummaryRow
              title={t("Kilometraje (Cuenta kilómetros virtual)")}
              info={summary.sOdo}
            />
          </div>
        </div>

        <DaySteps
          classDayStep={classDayStep}
          classStep={classStep}
          selectStep={selectStep}
          markerInfo={markerInfo}
          markerJourney={markerJourney}
          toggleFillMarkerJourney2={toggleFillMarkerJourney2}
          toggleFillGenericPopup={toggleFillGenericPopup}
          dateOptionSelected={dateOptionSelected}
        />
      </div>
    );
  } else if (value == 1 && Object.keys(markerInfo).length !== 0) {
    toggleFillMarkerJourney({ markerId: markerInfo.eid, period: 1 });
  }

  return isGeneralJourney ? (
    <div style={{ width: "100%" }}>
      {journeyContent}
      <div className="alert alert-info">
        {dateOptionSelected &&
          isDictionaryEmpty(markerJourney) &&
          t(
            "No se han encontrado resultados para el rango de busqueda establecido"
          )}
      </div>
    </div>
  ) : (
    <TabPanelContent value={value} index={index} subtitle={t("Recorridos")}>
      {journeyContent}
      <div className="alert alert-info">
        {dateOptionSelected &&
          isDictionaryEmpty(markerJourney) &&
          t(
            "No se han encontrado resultados para el rango de busqueda establecido"
          )}
      </div>
    </TabPanelContent>
  );
};

Journey.propTypes = {
  value: PropTypes.number,
  markerJourney: PropTypes.object,
  markerInfo: PropTypes.object,
  index: PropTypes.number,
  toggleActivePanelAux: PropTypes.func,
  toggleFillMarkerJourney2: PropTypes.func,
  toggleFillMarkerJourney: PropTypes.func,
  toggleFillMarkerJourneyPersonalizado: PropTypes.func,
  toggleFillGenericPopup: PropTypes.func,
  isGeneralJourney: PropTypes.bool,
  updateMarkerJourney: PropTypes.func,
};
Journey.defaultProps = {
  value: 0,
  markerInfo: {},
  markerJourney: {},
  index: 0,
  toggleActivePanelAux: () => {},
  toggleFillMarkerJourney2: () => {},
  toggleFillMarkerJourney: () => {},
  toggleFillMarkerJourneyPersonalizado: () => {},
  toggleFillGenericPopup: () => {},
  isGeneralJourney: false,
  updateMarkerJourney: () => {},
};

export default Journey;
