import React, { useState } from "react";

import { FormGroup, Button, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import * as Yup from "yup";
import { Field, withFormik } from "formik";
import { useQuery, useMutation } from "@apollo/client";
import { connect } from "react-redux";

import GenericSelector from "../Selectors/GenericSelector";
import CustomFormikSelect from "../Base/CustomFormikSelect";

import { ReduxDateTimeField as KeyboardDateField } from "../../components/Base/ReduxFormFields";
import ModalConfirmacion from "../Base/ModalConfirmacion";

import {
  closePanelAux,
  setRevisionList,
} from "../../redux/lateralPanel/actions";

import SERVICE_TYPE_QUERY from "../../graphql/queries/getServiceTypeQuery";
import CREATE_SERVICE_PERFORMED_MUTATION from "../../graphql/mutations/createServicePerformedMutation";
import UPDATE_SERVICEPERFORMED_MUTATION from "../../graphql/mutations/updateServicePerformed";
import DELETE_SERVICEPERFORMED_MUTATION from "../../graphql/mutations/deleteServicePerformedMutation";

import { useTranslation } from "../../lang";
import { GetDateFormat } from "../../utils/generalUtils";

const FormRevision = ({
  mid,
  selectedRevision,
  assetList,
  revisionList,
  setRevisionList,
  user,
}) => {
  //console.log("Selected revision: ", selectedRevision);
  const [t] = useTranslation();
  const [serviceTypes, setServiceTypes] = useState([]);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const closeModalConfirm = () => setIsOpenConfirm(false);
  const [confirmText, setConfirmText] = useState();

  const [resultState, setResultState] = useState({
    isOpen: false,
    type: "success",
    msg: "",
  });

  const initialValues = {
    id: selectedRevision?.id,
    assetId: mid, //mid ? [mid] : null,
    serviceTypeId: selectedRevision?.serviceType?.id || "",
    date: selectedRevision?.date || new Date(),
    kms: selectedRevision?.kms || 0,
    marca: selectedRevision?.marca || "",
    supplier: selectedRevision?.supplier || "",
    notes: selectedRevision?.notes || "",
  };

  const yupSchema = Yup.object().shape({
    assetId: Yup.string().required(t("Requerido")),
    serviceTypeId: Yup.number().required(t("Requerido")),
    date: Yup.date().required(t("Requerido")),
  });

  const {} = useQuery(SERVICE_TYPE_QUERY, {
    onCompleted(d) {
      setServiceTypes(
        d.serviceType.map((st) => ({
          label: t(st.description),
          value: st.id,
        }))
      );
    },
  });

  const columns = [
    {
      dataField: "name",
      text: t("Nombre"),
      sort: true,
    },
    {
      dataField: "licensePlate",
      text: t("Patente"),
      sort: true,
    },
    {
      dataField: "internalNumber",
      text: t("Interno"),
      sort: true,
    },
    {
      dataField: "driver.firstName",
      text: t("Chofer"),
      sort: true,
    },
    {
      dataField: "typeMobile.description",
      text: t("Tipo"),
      sort: true,
    },
  ];

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setResultState({
      ...resultState,
      isOpen: false,
    });
  };

  const [updateRevision] = useMutation(UPDATE_SERVICEPERFORMED_MUTATION);

  const handleUpdateRevision = async (values) => {
    try {
      const updatedRevision = await updateRevision({ variables: values });
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Revisión actualizada correctamente"),
      });

      const updatedData = updatedRevision.data.updateServicePerformed;
      const index = revisionList.findIndex((rn) => rn.id === updatedData.id);

      const newList = [
        ...revisionList.slice(0, index),
        updatedData,
        ...revisionList.slice(index + 1),
      ];
      setRevisionList(newList);
    } catch (err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: err.message,
      });
    }
  };

  const [deleteRevision] = useMutation(DELETE_SERVICEPERFORMED_MUTATION);

  const handleDeleteRevision = async () => {
    try {
      await deleteRevision({ variables: { id: selectedRevision?.id } });
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Revision eliminada correctamente"),
      });
      setRevisionList(
        revisionList.filter((x) => x.id !== selectedRevision?.id)
      );
      closePanelAux();
    } catch (err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: err.message,
      });
    }
  };

  const [saveServicePerformed] = useMutation(CREATE_SERVICE_PERFORMED_MUTATION);

  const handleSaveServicePerformed = async (values, resetForm) => {
    try {
      await saveServicePerformed({
        variables: {
          ...values,
          kms: values.kms || null,
        },
      });
      resetForm();
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Revisión creado correctamente"),
      });
    } catch (err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: err.message,
      });
    }
  };

  const BaseForm = (props) => {
    const { values, errors, handleSubmit, setFieldValue } = props;
    return (
      <form onSubmit={handleSubmit} style={{ padding: "10px 20px" }}>
        {!mid && (
          <FormGroup>
            <GenericSelector
              callbackOptionSelected={(assetsSelected) =>
                setFieldValue("assetId", assetsSelected?.value)
              }
              columns={columns}
              keyField="id"
              labelField="name"
              title={t("Móviles")}
              data={assetList}
              defaultValue={values?.assetId}
            />
          </FormGroup>
        )}
        <FormGroup>
          <label className="formLabelFormik">{t("Tipo de Revisión")}</label>
          <Field
            name="serviceTypeId"
            component={CustomFormikSelect}
            options={serviceTypes}
          />
          {errors.serviceTypeId && (
            <div className="input-feedback">{errors.serviceTypeId}</div>
          )}
        </FormGroup>
        <FormGroup>
          <label className="formLabelFormik">{t("Fecha")}</label>
          <Field
            component={KeyboardDateField}
            id="date"
            name="date"
            placeholder={t("Seleccionar")}
            disableToolbar
            variant="inline"
            // format="dd/MM/yyyy HH:mm:ss"
            format={GetDateFormat(user.language)}
            margin="normal"
            value={values.date}
            onChange={(value) => {
              setFieldValue("date", value);
            }}
          />
        </FormGroup>
        <FormGroup>
          <Field
            type="number"
            name="kms"
            tabIndex="0"
            className={
              errors.kms
                ? "form-control text-input error"
                : "form-control text-input"
            }
            autoComplete="off"
          />
          {errors.kms && <div className="input-feedback">{errors.kms}</div>}
        </FormGroup>
        <FormGroup>
          <label className="formLabelFormik">{t("Marca")}</label>
          <Field
            type="text"
            name="marca"
            className={
              errors.marca
                ? "form-control text-input error"
                : "form-control text-input"
            }
          />
        </FormGroup>
        <FormGroup>
          <label className="formLabelFormik">{t("Proveedor")}</label>
          <Field
            type="text"
            name="supplier"
            className={
              errors.supplier
                ? "form-control text-input error"
                : "form-control text-input"
            }
          />
        </FormGroup>
        <FormGroup>
          <label className="formLabelFormik">{t("Notas")}</label>
          <Field
            as="textarea"
            name="notes"
            className={
              errors.notes
                ? "form-control text-input error"
                : "form-control text-input"
            }
          />
        </FormGroup>
        <div className="buttonContainerFrm">
          <Button color="primary" variant="contained" type="submit">
            {t("Guardar")}
          </Button>
          {selectedRevision && (
            <Button
              color="secondary"
              variant="contained"
              type="button"
              style={{ marginLeft: "15px" }}
              onClick={() => {
                setIsOpenConfirm(true);
                setConfirmText(
                  t("¿Está seguro que desea eliminar la revisión?")
                );
              }}
            >
              {t("Eliminar")}
            </Button>
          )}
        </div>
      </form>
    );
  };

  const WrappedForm = withFormik({
    mapPropsToValues: () => initialValues,
    validationSchema: yupSchema,
    validateOnChange: false,
    validateOnBlur: false,
    handleSubmit: async (values, { resetForm }) => {
      if (selectedRevision) {
        handleUpdateRevision(values);
      } else {
        handleSaveServicePerformed(values, resetForm);
      }
    },
  })(BaseForm);

  return (
    <>
      <ModalConfirmacion
        isOpen={isOpenConfirm}
        buttonConfirmation={t("Confirmar eliminación")}
        closeModal={closeModalConfirm}
        confirmationText={confirmText}
        handleConfirm={() => {
          handleDeleteRevision();
        }}
        title={t("IMPORTANTE")}
      />
      <Snackbar
        open={resultState.isOpen}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleCloseNotification}
          severity={resultState.type}
          variant="filled"
        >
          {resultState.msg}
        </MuiAlert>
      </Snackbar>
      <WrappedForm />
    </>
  );
};

const _mapStateToProps = ({ groups, lateralPanel, user }) => {
  return {
    selectedCustomers: groups.selectedCustomers,
    assetList: lateralPanel.newPanelReducer.assetList,
    mid: lateralPanel.newPanelReducer.mid,
    selectedRevision: lateralPanel.newPanelReducer.revisionSelected,
    revisionList: lateralPanel.newPanelReducer.revisionList,
    user,
  };
};

const _mapDispatchToProps = (dispatch) => {
  return {
    closePanelAux: () => dispatch(closePanelAux()),
    setRevisionList: (list) => dispatch(setRevisionList(list)),
  };
};

export default connect(_mapStateToProps, _mapDispatchToProps)(FormRevision);
