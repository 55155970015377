import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { Button, FormGroup, Grid } from "@material-ui/core";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import GenericSelector from "../../../components/Selectors/GenericSelector";
import ASSETS_BYCUSTOMER_QUERY from "../../../graphql/queries/getAssetsByCustomerQuery";
import { useTranslation } from "../../../lang";

const Form = ({ setIsOpen, assignGuideNumber, selectedGuideNumber }) => {
  const [t] = useTranslation();
  const [assets, setAssets] = useState([]);

  const initialValues = {
    id: selectedGuideNumber?.id,
    assetId: selectedGuideNumber?.assetId,
  };

  const yupSchema = Yup.object().shape({
    GuideNumber: Yup.string().required(t("Requerido")),
    assetId: Yup.number().required(t("Requerido")),
  });

  const [fetchAssets] = useLazyQuery(ASSETS_BYCUSTOMER_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      setAssets(d.getAssetsByCustomer);
    },
  });

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("loggedUser"));
    // console.log("🚀 ~ file: Form.jsx ~ line 31 ~ useEffect ~ user", user);
    fetchAssets({ variables: { customerId: user.customer.id } });
  }, []);

  const columnsAssets = [
    {
      dataField: "name",
      text: t("Nombre"),
      sort: true,
    },
    {
      dataField: "licensePlate",
      text: t("Patente"),
      sort: true,
    },
    {
      dataField: "internalNumber",
      text: t("Interno"),
      sort: true,
    },
    {
      dataField: "driver.firstName",
      text: t("Chofer"),
      sort: true,
    },
    {
      dataField: "typeMobile.description",
      text: t("Tipo"),
      sort: true,
    },
  ];

  return (
    <>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={yupSchema}
        onSubmit={async (values) => {
          assignGuideNumber({
            variables: { ...values, assetId: +values.assetId },
          });
          setIsOpen(false);
        }}
      >
        {(props) => {
          const { errors, handleSubmit, setFieldValue } = props;

          return (
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <FormGroup>
                  <GenericSelector
                    callbackOptionSelected={(assetSelected) => {
                      // console.log("assetSelected", assetSelected);
                      setFieldValue(
                        "assetId",
                        assetSelected.value
                        // assetsSelected.map((a) => a.value)
                      );
                    }}
                    columns={columnsAssets}
                    data={assets}
                    keyField="id"
                    labelField="licensePlate"
                    title={t("Móviles")}
                    isMulti={false}
                    // defaultValue={
                    //   values.assetId
                    //   // Array.isArray(values.assetsId) &&
                    //   // values.assetsId.length > 0
                    //   //   ? values.assetsId
                    //   //   : null
                    // }
                  />
                </FormGroup>
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">{t("Número de Guía")}</label>
                <Field
                  type="text"
                  name="GuideNumber"
                  tabIndex="0"
                  className={
                    errors.GuideNumber
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                />
                {errors.GuideNumber && (
                  <div className="input-feedback">{errors.GuideNumber}</div>
                )}
              </FormGroup>

              <Grid item lg={12} style={{ marginTop: "50px" }}>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  tabIndex="0"
                >
                  {selectedGuideNumber ? t("Actualizar") : t("Asignar")}
                </Button>
                <Button
                  variant="contained"
                  type="button"
                  tabIndex="0"
                  style={{ marginLeft: "20px" }}
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  {t("Cancelar")}
                </Button>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default Form;
