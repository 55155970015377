import gql from "graphql-tag";

const CREATE_GEOFENCE_MUTATION = gql`
  mutation CreateGeofence(
    $name: String
    $customerId: Int
    $showOnMap: Boolean
    $maxSpeed: Int
    $alertsInputOutput: Boolean
    $geom: String
    $geofenceType: GeofenceTypeEnum
  ) {
    createGeofence(
      name: $name
      customerId: $customerId
      showOnMap: $showOnMap
      maxSpeed: $maxSpeed
      alertsInputOutput: $alertsInputOutput
      geom: $geom
      geofenceType: $geofenceType
    ) {
      id
    }
  }
`;

export default CREATE_GEOFENCE_MUTATION;
