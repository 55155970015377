import { BASE_API_URL } from "../../constants";

const LOGIN_URL = `${BASE_API_URL}/points/users/login`;

export const loginApi = ({ username, password }) => {
  fetch(LOGIN_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email: username, password }),
  })
    .then((response) => {
      if (response.status !== 200) {
        // Will jump to the catch skipping all .then
        throw new Error("Error al intentar ingresar con su usuario");
      } else {
        return response.json().then(({ user, token }) => {
          localStorage.setItem("user", JSON.stringify({ user, token }));

          return { user, token };
        });
      }
    })
    .catch((error) => {
      throw error;
    });
};

export const registerApi = () => {};
export const deleteApi = () => {};
