import React from "react";
import "./style.scss";

const OBD2DisplayItem = ({title="---", value="---"}) => {
    return (
        <div class="obd2-display-item">
            <div class="title">
                {title}
            </div>
            <div class="value">
                {value}
            </div>
        </div>
    )
}

export default OBD2DisplayItem;