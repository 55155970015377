import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { Typography, Snackbar } from "@material-ui/core";

import { useMutation, useLazyQuery } from "@apollo/client";

import MuiAlert from "@material-ui/lab/Alert";

import Structure from "../../../components/Base/Structure";
import List from "./List";
import PageTitle from "../../../components/Base/PageTitle";

import GET_GEOFENCE_QUERY from "../../../graphql/queries/getGeofencesQuery";
import CREATE_GEOFENCE_MUTATION from "../../../graphql/mutations/createGeofenceMutation";
import UPDATE_GEOFENCE_MUTATION from "../../../graphql/mutations/updateGeofenceMutation";
import DELETE_GEOFENCE_MUTATION from "../../../graphql/mutations/deleteGeofenceMutation";

import { useTranslation } from "../../../lang";

const paginationSizes = [10, 25, 50];
const GeofencesABM = ({ selectedCustomers }) => {
  const [t] = useTranslation();
  const [geofenceList, setGeofenceList] = useState([]);
  const [tableState, setTableState] = useState({
    pageSize: paginationSizes[0],
    pageNumber: 1,
    sort: undefined,
    searchText: "",
    paginate: true,
  });
  const [total, setTotal] = useState(0);

  const [resultState, setResultState] = useState({
    isOpen: false,
    type: "success",
    msg: "",
  });

  const [fetchGeofences] = useLazyQuery(GET_GEOFENCE_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      setTotal(d.geofence.count);
      setGeofenceList(d.geofence.items);
    },
  });

  useEffect(() => {
    let customerId;

    if (Array.isArray(selectedCustomers) && selectedCustomers.length > 0) {
      customerId = selectedCustomers[selectedCustomers.length - 1].value;
    }

    if (customerId && tableState) {
      fetchGeofences({
        variables: {
          customerId: customerId,
          ...tableState,
        },
      });
    }
  }, [selectedCustomers, tableState]);

  const [createGeofence] = useMutation(CREATE_GEOFENCE_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Geocerca creada correctamente"),
      });

      setTableState({
        pageSize: paginationSizes[0],
        pageNumber: 1,
        sort: undefined,
        searchText: tableState.searchText,
        paginate: true,
      });
    },
    onError(err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: err.message,
      });
    },
  });

  const [updateGeofence] = useMutation(UPDATE_GEOFENCE_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Geocerca actualizada correctamente"),
      });

      setTableState({
        pageSize: paginationSizes[0],
        pageNumber: 1,
        sort: undefined,
        searchText: tableState.searchText,
        paginate: true,
      });
    },
    onError(err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: err.message,
      });
    },
  });

  const [deleteGeofence] = useMutation(DELETE_GEOFENCE_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Geocerca eliminada correctamente"),
      });

      setTableState({
        pageSize: paginationSizes[0],
        pageNumber: 1,
        sort: undefined,
        searchText: tableState.searchText,
        paginate: true,
      });
    },
    onError(err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: err.message,
      });
    },
  });

  const handleCreateGeofence = (geofencePayload) => {
    //Validaciones que no se puedan poner en Yup

    createGeofence({
      variables: geofencePayload,
    });
  };

  const handleUpdateGeofence = (geofencePayload) => {
    updateGeofence({
      variables: geofencePayload,
    });
  };

  const handleDeleteGeofence = (id) => {
    deleteGeofence({
      variables: {
        id: id,
      },
    });
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setResultState({
      ...resultState,
      isOpen: false,
    });
  };

  return (
    <Structure>
      <Snackbar
        open={resultState.isOpen}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleCloseNotification}
          severity={resultState.type}
          variant="filled"
        >
          {resultState.msg}
        </MuiAlert>
      </Snackbar>
      {/* <Typography
        component="h3"
        variant="h3"
        className="pad-vert-4"
        style={{ color: "white", marginLeft: "20px" }}
      >
        Geocercas
      </Typography>*/}
      <PageTitle sector={t("Administración")} title={t("Geocercas")} />
      <List
        geofenceList={geofenceList}
        getGeofences={() => {}}
        createGeofence={handleCreateGeofence}
        updateGeofence={handleUpdateGeofence}
        deleteGeofence={handleDeleteGeofence}
        paginationSizes={paginationSizes}
        total={total}
        tableState={tableState}
        setTableState={setTableState}
      />
    </Structure>
  );
};

const _mapStateToProps = ({ groups }) => {
  return {
    ...groups,
  };
};

export default connect(_mapStateToProps, null)(GeofencesABM);
