import React from "react";
import PropTypes from "prop-types";

import TabPanelContent from "../../Base/TabPanelContent";
import SubHeader from "./SubHeader";

import "./GeneralInfo.scss";
import "./FuelRefill.scss";

import { useTranslation } from "../../../lang";

const FuelRefill = ({ value, index }) => {
  const [t] = useTranslation();
  return (
    <TabPanelContent value={value} index={index} subtitle={t("Combustibles")}>
      <SubHeader />
    </TabPanelContent>
  );
};

FuelRefill.propTypes = {
  value: PropTypes.number,
  index: PropTypes.number,
};

FuelRefill.defaultProps = {
  value: 0,
  index: 0,
};

export default FuelRefill;
