import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import * as Yup from "yup";
import { Field, Formik } from "formik";
import { useLazyQuery, useQuery } from "@apollo/client";

import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Grid,
  Button,
} from "@material-ui/core";

import Select from "react-select";

import GenericSelector from "../../../components/Selectors/GenericSelector";
import { useTranslation } from "../../../lang";
import USER_BYCUSTOMER_QUERY from "../../../graphql/queries/usersByCustomerQuery";
import GROUPS_BYCUSTOMERMIN_QUERY from "../../../graphql/queries/groupsByCustomerMinQuery";
import GET_CONFNOTIFICATION_BYID from "../../../graphql/queries/getConfNotificationByIdQuery";
import CUSTOMERS_QUERY from "../../../graphql/queries/getCustomersQuery";
import ASSETS_BYGROUPS_QUERY from "../../../graphql/queries/assetByGroupsQuery";
import GET_EVENTS_ALARM_QUERY from "../../../graphql/queries/getEventsAlarmQuery";
import GET_GEOFENCES_QUERY from "../../../graphql/queries/getGeofencesQuery";
import ALL_ROADS_BY_CUSTOMER_IDS_QUERY from "../../../graphql/queries/allRoadsByCustomersIDs";

import {
  default as CustomSelectStyle,
  default as CustomSelectStyles,
} from "../../../utils/reactSelectStyle";

const _ = require("lodash");

const Form = ({
  closeModal,
  createConfNotification,
  selectedConfNotification,
  updateConfNotification,
  loggedUser,
  isOpen,
}) => {
  const [t] = useTranslation();
  const [confNotification, setConfNotification] = useState();
  const [assets, setAssets] = useState([]);
  const [roads, setRoads] = useState([]);
  const [users, setUsers] = useState([]);
  const [events, setEvents] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [geofenceList, setGeofenceList] = useState([]);

  const initialValues = {
    id: confNotification?.id,
    name: confNotification?.name,
    customerId: confNotification?.customer?.id || loggedUser?.customer?.id,
    enabled: confNotification ? confNotification?.enabled : true,
    mailList: confNotification?.mailList,
    mailListCco: confNotification?.mailListCco,
    assetsId: confNotification?.assets?.map((a) => a.id),
    usersDestinationsId:
      Array.isArray(confNotification?.usersDestinations) &&
      confNotification.usersDestinations.length > 0
        ? confNotification.usersDestinations?.map((ud) => ud.id)
        : [],
    typeEventsId: confNotification?.typeEvents?.map((te) => te.id),
    speeding: confNotification?.speeding || false,
    geofenceEvents: confNotification?.geofenceEvents || false,
    geofenceSpeeding: confNotification?.geofenceSpeeding || false,
    withoutTransmision: confNotification?.withoutTransmision || false,
    geofencesId: confNotification?.geofences?.map((g) => g.id),
    roadEvents: confNotification?.roadEvents || false,
    roadsId: confNotification?.roads?.map((r) => r.id),
  };

  Yup.addMethod(Yup.object, "atLeastOneEmail", function (list, message) {
    return this.shape(
      list.reduce(
        (acc, field) => ({
          ...acc,
          [field]: this.fields[field].when(_.without(list, field), {
            is: (...values) => {
              const newValues = values.map((oldValue) => {
                if (oldValue === undefined || oldValue === null) {
                  return false;
                }

                if (Array.isArray(oldValue) && oldValue.length === 0) {
                  return false;
                }

                return oldValue;
              });

              return !newValues.some((item) => item);
            },
            then: this.fields[field].required(message),
          }),
        }),
        {}
      ),
      list.reduce(
        (acc, item, idx, all) => [
          ...acc,
          ...all.slice(idx + 1).map((i) => [item, i]),
        ],
        []
      )
    );
  });

  const buildYupShapeFields = (confNotification) => {
    return {
      name: Yup.string().required(t("Requerido")),
      customerId: Yup.number().required(t("Requerido")),
      usersDestinationsId: Yup.array().of(Yup.number()),
      mailList: Yup.string(),
      mailListCco: Yup.string(),
    };
  };

  const yupShapeFields = buildYupShapeFields(confNotification);

  const yupSchema = Yup.object()
    .shape(yupShapeFields)
    .atLeastOneEmail(
      ["usersDestinationsId", "mailList", "mailListCco"],
      t("Debe ingresar al menos un destinatario")
    );

  const [getConfNotificationById] = useLazyQuery(GET_CONFNOTIFICATION_BYID, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      console.log(
        "getConfigurationNotificationById",
        d.getConfigurationNotificationById
      );
      const customerId = d.getConfigurationNotificationById.customer.id;
      setConfNotification(d.getConfigurationNotificationById);
      setAssets(d.getConfigurationNotificationById.assets);
      // setGeofenceList(d.getConfigurationNotificationById.geofences || []);
      console.log("getConfNotificationById", customerId);
      handleGetGroups(customerId);
      fetchGeofences({
        variables: { customerId: customerId, paginate: false },
      });
      fetchRoads({ variables: { customerIDs: [customerId] } });
    },
  });

  const handleGetConfNotificationById = (id) => {
    getConfNotificationById({
      variables: {
        id,
      },
    });
  };

  const [fetchGeofences] = useLazyQuery(GET_GEOFENCES_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      setGeofenceList(d.geofence.items);
    },
  });

  const [fetchRoads] = useLazyQuery(ALL_ROADS_BY_CUSTOMER_IDS_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      console.log("fetchRoads", d.allRoadsByCustomersIDs);
      setRoads(
        d.allRoadsByCustomersIDs.map((r) => ({ value: r.id, label: r.name }))
      );
    },
  });

  useEffect(() => {
    if (!selectedConfNotification) {
      setConfNotification(null);
      return;
    }

    if (isOpen) {
      handleGetConfNotificationById(selectedConfNotification.id);
    }
  }, [isOpen, selectedConfNotification]);

  const [fetchUsers] = useLazyQuery(USER_BYCUSTOMER_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      setUsers(d.usersByCustomer);
    },
  });

  const [fetchGroups] = useLazyQuery(GROUPS_BYCUSTOMERMIN_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      console.log("fetchGroups", d.groupsByCustomer);
      setGroups(d.groupsByCustomer);
      handleFetchAssets(d.groupsByCustomer.map((g) => g.id));
    },
  });

  const handleGetGroups = (customerId) => {
    console.log("handleGetGroups ", customerId);
    fetchGroups({
      variables: {
        customerId,
      },
    });
  };

  useEffect(() => {
    if (loggedUser) {
      const customerId = initialValues.customerId || loggedUser.customer?.id;
      console.log(
        "useEffect - initialValues.customerId:",
        initialValues.customerId
      );
      console.log(
        "useEffect - loggedUser.customer?.id:",
        loggedUser.customer?.id
      );
      console.log("useEffect - customer:", customerId);
      fetchUsers({
        variables: {
          customerId: customerId,
        },
      });
      console.log("useEffect - antes de handleGetGroups");
      handleGetGroups(customerId);
      console.log("useEffect - antes de fetchGeofences");
      fetchGeofences({
        variables: {
          customerId: customerId,
          paginate: false,
        },
      });
      //console.log("antes de fetchRoads");
      fetchRoads({
        variables: {
          customerIDs: [customerId],
        },
      });
      //console.log("despues de fetchRoads");
    }
  }, [loggedUser]);

  useQuery(CUSTOMERS_QUERY, {
    onCompleted(d) {
      console.log("setCustomers", d.customers.items);
      setCustomers(d.customers.items);
    },
  });

  useQuery(GET_EVENTS_ALARM_QUERY, {
    onCompleted(d) {
      setEvents(d.getEventsAlarm);
    },
  });

  const [fetchAssets] = useLazyQuery(ASSETS_BYGROUPS_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      const { items } = d.assetsByGroups;
      setAssets(items);
    },
  });

  const handleFetchAssets = (groupsIds) => {
    //console.log("groupsids fetchAssets ", groupsIds);
    fetchAssets({
      fetchPolicy: "network-only",
      variables: {
        groupIDs: groupsIds,
      },
    });
  };

  const itemStyle = {
    fontSize: "12px",
    color: "white",
  };

  const columnsGeofence = [
    {
      dataField: "name",
      text: t("Nombre"),
      sort: true,
    },
    {
      dataField: "customerName",
      text: t("Cliente"),
      sort: true,
    },
    {
      dataField: "showOnMap",
      text: t("Mostrar en mapa"),
      formatter: (cell, row) => {
        return row.showOnMap ? t("Si") : t("No");
      },
    },
    {
      dataField: "maxSpeed",
      text: t("Velocidad Máxima"),
    },
  ];

  const columnsGroups = [
    {
      dataField: "name",
      text: t("Nombre"),
      headerStyle: () => ({ width: "50%", fontSize: "12px" }),
      sort: true,
    },
    {
      dataField: "customer.name",
      text: t("Cliente"),
      headerStyle: () => ({ width: "50%", fontSize: "12px" }),
      sort: true,
    },
  ];

  const columnsAssets = [
    {
      dataField: "name",
      text: t("Nombre"),
      sort: true,
    },
    {
      dataField: "licensePlate",
      text: t("Patente"),
      sort: true,
    },
    {
      dataField: "internalNumber",
      text: t("Interno"),
      sort: true,
    },
    {
      dataField: "driver.firstName",
      text: t("Chofer"),
      sort: true,
    },
    {
      dataField: "typeMobile.description",
      text: t("Tipo"),
      sort: true,
    },
  ];

  const columnsCustomers = [
    {
      dataField: "name",
      text: t("Nombre"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      sort: true,
    },
    {
      dataField: "taxId",
      text: t("CUIT"),
      headerStyle: () => ({ width: "15%", fontSize: "12px" }),
      sort: true,
    },
    {
      dataField: "phone",
      text: t("Telefonos"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      sort: true,
    },
    {
      dataField: "celPhone",
      text: t("Celular"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      sort: true,
    },
    {
      dataField: "address",
      text: "Domicilio",
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      sort: true,
    },
  ];

  const columnsUsers = [
    {
      dataField: "firstName",
      text: t("Nombre"),
      sort: true,
    },
    {
      dataField: "lastName",
      text: t("Apellido"),
      sort: true,
    },
    {
      dataField: "userName",
      text: t("Usuario"),
      sort: true,
    },
    {
      dataField: "email",
      text: t("Email"),
      sort: true,
    },
  ];

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={yupSchema}
      onSubmit={async (values) => {
        if (!confNotification) {
          createConfNotification({
            variables: values,
          });
        } else {
          updateConfNotification({
            variables: values,
          });
        }
      }}
    >
      {(props) => {
        const { errors, handleSubmit, setFieldValue, values } = props;

        return (
          <form onSubmit={handleSubmit}>
            <FormGroup>
              <label className="formLabelFormik">{t("Nombre")}</label>
              <Field
                type="text"
                name="name"
                tabIndex="0"
                className={
                  errors.name
                    ? "form-control text-input error"
                    : "form-control text-input"
                }
                autoComplete="off"
              />
              {errors.name && (
                <div className="input-feedback">{errors.name}</div>
              )}
            </FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  className="reportPlanningCheckBox"
                  checked={values.enabled}
                  onChange={(evt) =>
                    setFieldValue("enabled", evt.target.checked)
                  }
                  name="enabled"
                />
              }
              label={t("Habilitado")}
            />
            <FormGroup>
              {users && (
                <GenericSelector
                  callbackOptionSelected={(usersSelected) =>
                    setFieldValue(
                      "usersDestinationsId",
                      usersSelected.map((us) => us.value)
                    )
                  }
                  columns={columnsUsers}
                  keyField="id"
                  labelField="userName"
                  isMulti
                  title={t("Usuarios")}
                  data={users}
                  defaultValue={values?.usersDestinationsId}
                />
              )}
              {errors?.usersDestinationsId && (
                <div className="input-feedback">
                  {errors?.usersDestinationsId}
                </div>
              )}
            </FormGroup>
            <FormGroup>
              <label>{t("Otras direcciones")}</label>
              <Field
                as="textarea"
                name="mailList"
                className={
                  errors.mailList
                    ? "form-control text-input error"
                    : "form-control text-input"
                }
              />
              {errors.mailList && (
                <div className="input-feedback">{errors.mailList}</div>
              )}
            </FormGroup>
            <FormGroup>
              <label>{t("Otras direcciones Cco")}</label>
              <Field
                as="textarea"
                name="mailListCco"
                className={
                  errors.mailListCco
                    ? "form-control text-input error"
                    : "form-control text-input"
                }
              />
              {errors.mailListCco && (
                <div className="input-feedback">{errors.mailListCco}</div>
              )}
            </FormGroup>
            <FormGroup>
              {customers && customers.length > 0 && (
                <GenericSelector
                  callbackOptionSelected={(customer) => {
                    setFieldValue("customerId", customer.value);
                    console.log("callbackOptionSelected ", customer.value);
                    handleGetGroups(customer.value);
                    fetchGeofences({
                      variables: {
                        customerId: customer.value,
                        paginate: false,
                      },
                    });
                    //console.log("CustomerValue", customer.value);
                    let cusomid = customer.value;
                    fetchRoads({
                      variables: {
                        customerIDs: [cusomid],
                      },
                    });
                    /*fetchRoads({
                      variables: { customerIDs: [customer.value] },
                    });*/
                  }}
                  columns={columnsCustomers}
                  keyField="id"
                  labelField="name"
                  title={t("Cliente")}
                  data={customers}
                  defaultValue={initialValues?.customerId}
                />
              )}
              {errors.customerId && (
                <div className="input-feedback">{errors.customerId}</div>
              )}
            </FormGroup>
            <FormGroup>
              <GenericSelector
                callbackOptionSelected={(groups) => {
                  const groupsSelected = groups.map((g) => g.value);
                  //console.log("combo groups ", groups);
                  //console.log("combo groupsSelected ", groupsSelected);
                  handleFetchAssets(groupsSelected);
                }}
                isMulti
                columns={columnsGroups}
                keyField="id"
                labelField="name"
                title={t("Grupos")}
                data={groups}
              />
            </FormGroup>
            <FormGroup>
              <GenericSelector
                callbackOptionSelected={(assetsSelected) =>
                  setFieldValue(
                    "assetsId",
                    assetsSelected.map((a) => a.value)
                  )
                }
                columns={columnsAssets}
                data={assets}
                keyField="id"
                labelField="licensePlate"
                title={t("Móviles")}
                isMulti
                defaultValue={
                  Array.isArray(values.assetsId) && values.assetsId.length > 0
                    ? values.assetsId
                    : null
                }
              />
            </FormGroup>
            <FormGroup>
              <label className="formLabelFormik">{t("EventosHardware")}</label>
            </FormGroup>
            <Select
              placeholder={t("Seleccionar_")}
              options={events}
              value={events.filter((x) =>
                values.typeEventsId?.includes(x.value)
              )}
              isMulti
              onChange={(opt) => {
                setFieldValue(
                  "typeEventsId",
                  opt?.map((x) => x.value)
                );
              }}
              styles={CustomSelectStyles}
            />
            <FormGroup>
              <label className="formLabelFormik">{t("EventosSoftware")}</label>
            </FormGroup>
            {process.env.REACT_APP_CUSTOMER !== "Reaxium" && (
              <FormControlLabel
                control={
                  <Checkbox
                    className="reportPlanningCheckBox"
                    checked={values.speeding}
                    onChange={(evt) =>
                      setFieldValue("speeding", evt.target.checked)
                    }
                    name="speeding"
                  />
                }
                label={t("ExcesoVelocidad")}
              />
            )}
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    className="reportPlanningCheckBox"
                    checked={values.geofenceSpeeding}
                    onChange={(evt) => {
                      setFieldValue("geofenceSpeeding", evt.target.checked);
                      if (!evt.target.checked)
                        setFieldValue("geofencesId", null);
                    }}
                    name="geofenceSpeeding"
                  />
                }
                label={t("ExcesoVelocidadEnGeocerca")}
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    className="reportPlanningCheckBox"
                    checked={values.geofenceEvents}
                    onChange={(evt) => {
                      setFieldValue("geofenceEvents", evt.target.checked);
                      if (!evt.target.checked)
                        setFieldValue("geofencesId", null);
                    }}
                    name="geofenceEvents"
                  />
                }
                label={t("EventosESGeocerca")}
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    className="reportPlanningCheckBox"
                    checked={values.withoutTransmision}
                    onChange={(evt) =>
                      setFieldValue("withoutTransmision", evt.target.checked)
                    }
                    name="withoutTransmision"
                  />
                }
                label={t("Sin transmisión")}
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    className="reportPlanningCheckBox"
                    checked={values.roadEvents}
                    onChange={(evt) =>
                      setFieldValue("roadEvents", evt.target.checked)
                    }
                    name="roadEvents"
                  />
                }
                label={t("EventosDeRuta")}
              />
            </FormGroup>
            {(values.geofenceEvents || values.geofenceSpeeding) && (
              <FormGroup>
                <GenericSelector
                  callbackOptionSelected={(geoFencesSelected) =>
                    setFieldValue(
                      "geofencesId",
                      geoFencesSelected?.map((d) => d.value)
                    )
                  }
                  columns={columnsGeofence}
                  keyField="id"
                  data={geofenceList}
                  labelField="name"
                  title={t("Geocercas")}
                  isMulti
                  defaultValue={
                    Array.isArray(values.geofencesId) &&
                    values.geofencesId.length > 0
                      ? values.geofencesId
                      : null
                  }
                />
              </FormGroup>
            )}
            {values.roadEvents && (
              <Select
                placeholder={t("Seleccionar_")}
                options={roads}
                value={roads.filter((x) => values.roadsId?.includes(x.value))}
                isMulti
                onChange={(opt) => {
                  setFieldValue(
                    "roadsId",
                    opt?.map((x) => x.value)
                  );
                }}
                styles={CustomSelectStyles}
              />
            )}
            <Grid item lg={12} className="footerSlidePanel">
              <Button
                variant="contained"
                type="submit"
                color="primary"
                tabIndex="0"
              >
                {selectedConfNotification ? t("Actualizar") : t("Crear")}
              </Button>
              <Button
                variant="contained"
                type="button"
                tabIndex="0"
                style={{ marginLeft: "20px" }}
                onClick={() => {
                  closeModal();
                }}
              >
                {t("Cancelar")}
              </Button>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

const _mapStateToProps = ({ groups, user }) => {
  return {
    loggedUser: user,
  };
};

export default connect(_mapStateToProps, null)(Form);
