import React, { useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Toolbar, TextField } from "@material-ui/core";

import GET_AVAILABLELINES_QUERY from "../../graphql/queries/getAvailableLines";

import { useQuery } from "@apollo/client";

import { useTranslation } from "../../lang";

const LineSelector = ({ callbackLineSelected, ownLines = [] }) => {
  const [t] = useTranslation();
  const [availableLines, setAvailableLines] = useState([]);

  useQuery(GET_AVAILABLELINES_QUERY, {
    onCompleted(d) {
      setAvailableLines([...d.availableLines, ...ownLines]);
    },
  });

  return (
    <Toolbar className="navbar__content">
      <Autocomplete
        style={{ width: "100%" }}
        multiple
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        options={availableLines}
        defaultValue={ownLines}
        //options={options.sort((a, b) => -b.type.localeCompare(a.type))}
        //groupBy={(opt) => opt.type}
        getOptionLabel={(opt) => opt.lineNumber}
        /*classes={{
          listbox: "searchListbox",
          clearIndicator: "searchSeparator",
        }}
        renderOption={(opt) => {
          return (
            <div className="searchOption" key={opt.id}>
              <label>{opt.lineNumber}</label>
            </div>
          );
        }}*/
        onChange={(evt, value) => {
          callbackLineSelected(value);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            id="navbar-search"
            //className="navbar__content-search"
            label={t("Buscar líneas")}
            margin="normal"
          />
        )}
      />
    </Toolbar>
  );
};

export default LineSelector;
