import { useLazyQuery } from "@apollo/client";
import { Button } from "@material-ui/core";
import React, { useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import Table from "../../../components/Base/Table";
import ThreeDotMenu from "../../../components/Base/ThreeDotMenu";
import GET_USER_PHOTO_QUERY from "../../../graphql/queries/getUserPhotoQuery";
import { useTranslation } from "../../../lang";
import { hasPermission } from "../../../utils/generalUtils";
import ModalAsignarRoles from "./ModalAsignarRoles";
import UserModal from "./UserForm";

const List = ({
  paginationSizes,
  total,
  userList,
  createUser,
  updateUser,
  getUsers,
  deleteUser,
  loggedUser,
}) => {
  const [t] = useTranslation();
  const [openSide, setOpenSide] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [photo, setPhoto] = useState();

  const [openModalRoles, setOpenModalRoles] = useState(false);
  const closeModalRoles = () => setOpenModalRoles(false);

  const [textToSearch, setTextToSearch] = useState("");

  const [getUserPhoto] = useLazyQuery(GET_USER_PHOTO_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      setPhoto(d?.getUser?.photo);
    },
  });

  const confirmDelete = (id, name) => {
    confirmAlert({
      title: t("Eliminar usuario"),
      message: t("pages_manage_users_seguro_eliminar_user", { name }),
      buttons: [
        {
          label: t("Si"),
          onClick: () => {
            deleteUser(id);
          },
        },
        {
          label: t("No"),
          onClick: () => console.log("cancelar"),
        },
      ],
    });
  };

  const itemStyle = {
    fontSize: "12px",
  };

  const callbackSelectOption = (option, row) => {
    switch (option) {
      case 1:
        getUserPhoto({ variables: { userId: row.id } });
        setSelectedItem(row);
        setOpenSide(true);
        break;
      case 2:
        confirmDelete(row.id, `${row.firstName} ${row.lastName}`);
        break;
      case 3:
        setSelectedItem(row);
        setOpenModalRoles(true);
        break;
    }
  };

  const hightlightIfMatch = (text) => {
    if (
      text &&
      textToSearch &&
      text.toLocaleLowerCase().includes(textToSearch.toLocaleLowerCase())
    ) {
      const newText = text.replace(
        new RegExp(textToSearch, "gi"),
        (match) => `<mark>${match}</mark>`
      );
      return <div dangerouslySetInnerHTML={{ __html: newText }} />;
    }

    return text;
  };

  const columns = [
    {
      dataField: "id",
      text: "",
      headerStyle: () => ({ width: "50px", fontSize: "12px" }),
      formatter: (cell, row) => {
        return (
          <>
            <ThreeDotMenu
              row={row}
              callback={callbackSelectOption}
              options={[
                {
                  label: t("Editar"),
                  value: 1,
                },
                hasPermission("AsignarRolUsuario", loggedUser.roles) && {
                  label: t("Roles"),
                  value: 3,
                },
                {
                  label: t("Eliminar"),
                  value: 2,
                },
              ]}
            />
          </>
        );
      },
    },
    {
      dataField: "userName",
      text: t("Usuario"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      style: () => itemStyle,
      formatter: hightlightIfMatch,
      sort: true,
    },
    {
      dataField: "firstName",
      text: t("Nombre"),
      headerStyle: () => ({ width: "15%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
      formatter: hightlightIfMatch,
      // filter: textFilter(),
    },
    {
      dataField: "lastName",
      text: t("Apellido"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
      formatter: hightlightIfMatch,
      // filter: textFilter(),
    },
    {
      dataField: "email",
      text: t("Email"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
      formatter: hightlightIfMatch,
      // filter: textFilter(),
    },
    {
      dataField: "contactNumber",
      text: t("Teléfono"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
      formatter: hightlightIfMatch,
      // filter: textFilter(),
    },
    {
      dataField: "status",
      text: t("Estado"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
      formatter: (cell, row) => {
        return cell ? t("Activo") : t("Inactivo");
      },
      // filter: textFilter(),
    },
    {
      dataField: "customer.name",
      text: t("Cliente"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
      formatter: hightlightIfMatch,
      // filter: textFilter(),
    },
  ];

  const customTotal = (from, to, size) => (
    <span
      className="react-bootstrap-table-pagination-total customTotalDisplay"
      style={{ marginLeft: "15px" }}
    ></span>
  );

  const options = {
    // Tooltips botones de paginación
    prePageTitle: t("Página previa"),
    nextPageTitle: t("Próxima página"),
    firstPageTitle: t("Primera página"),
    lastPageTitle: t("Última página"),
    showTotal: true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: paginationSizes,
    totalSize: total,
  };

  return (
    <div className="listContainer">
      <ModalAsignarRoles
        isOpen={openModalRoles}
        closeModal={closeModalRoles}
        selectedUser={selectedItem}
      />

      <SlidingPane
        className="slidingPaneRight"
        isOpen={openSide}
        title={selectedItem ? t("Actualizar Usuario") : t("Crear Usuario")}
        width="400px"
        onRequestClose={() => {
          setOpenSide(false);
        }}
      >
        <UserModal
          setIsOpen={() => setOpenSide(false)}
          createUser={createUser}
          selectedUser={selectedItem}
          updateUser={updateUser}
          photo={photo}
        />
      </SlidingPane>
      <Table
        columns={columns}
        data={userList}
        keyField="id"
        paginationOptions={options}
        //total={total}
        afterSearch={(value) => setTextToSearch(value)}
        lateralComponent={
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setSelectedItem(null);
              setOpenSide(true);
            }}
            style={{ float: "right" }}
          >
            {t("+ Usuario")}
          </Button>
        }
      />
    </div>
  );
};

export default List;
