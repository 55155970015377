import React, { useRef, useLayoutEffect } from "react";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import darkTheme from "@amcharts/amcharts4/themes/dark";
import { useTranslation } from "../../../lang";

const Chart = ({ data, variableOBD2, variableLabel }) => {
  const [t] = useTranslation();
  const chart = useRef(null);

  useLayoutEffect(() => {
    if (chart.current) {
      chart.current.series.each((serie) => {
        serie.data = data;
      });
    }
  }, [data]);

  useLayoutEffect(() => {
    if (chart.current) {
      const serie = chart.current.series.getIndex(0);
      serie.dataFields.valueY = variableOBD2;
      serie.data = data;
    }
  }, [variableOBD2]);

  useLayoutEffect(() => {
    if (chart.current) {
      const title = chart.current.titles.getIndex(0);
      title.text = variableLabel;
      const serie = chart.current.series.getIndex(0);
      serie.tooltipText = `Fecha: {dateX}
      ${variableLabel}: {valueY} [${t("Km/h")}]
    `;
    }
  }, [variableLabel]);

  useLayoutEffect(() => {
    let x = am4core.create("obd2ChartContainer", am4charts.XYChart);

    x.dateFormatter.inputDateFormat = "dd/MM/yyyy HH:mm:ss";

    const title = x.titles.create();
    title.text = variableLabel;
    title.fontSize = 25;
    title.marginBottom = 30;
    title.align = "left";

    //Create axis
    const dateAxis = x.xAxes.push(new am4charts.DateAxis());
    dateAxis.dateFormats.setKey("hour", "dd/MM/YYYY hh:mm");
    dateAxis.periodChangeDateFormats.setKey("hour", "dd/MM/YYYY hh:mm");
    dateAxis.tooltipDateFormat = "dd/MM/YYYY hh:mm";
    dateAxis.baseInterval = {
      count: 10,
      timeUnit: "second",
    };
    x.yAxes.push(new am4charts.ValueAxis());

    let serie = x.series.push(new am4charts.LineSeries());
    serie.dataFields.valueY = variableOBD2;
    serie.dataFields.dateX = "Time";
    serie.name = "SerieOBD2";
    serie.tooltipText = `Fecha: {dateX.formatDate("dd/MM/YYYY hh:mm")}
      ${variableLabel}: {valueY} [${t("Km/h")}]
    `;
    serie.stroke = am4core.color("#A20F0F");
    serie.strokeWidth = 2;

    let segmentFS = serie.segments.template;
    segmentFS.interactionsEnabled = true;

    serie.data = data;

    x.cursor = new am4charts.XYCursor();
    x.cursor.maxTooltipDistance = 0;

    chart.current = x;

    return () => {
      x.dispose();
    };
  }, []);

  return (
    <div id="obd2ChartContainer" style={{ width: "100%", height: "600px" }} />
  );
};

export default Chart;
