import Client from "../../components/Base/apiApolloClient";
import GET_GROUPS_QUERY from "../../graphql/queries/groupsByUser";

export const UPDATE_GROUPS = "UPDATE_GROUPS";
export const SET_SELECTED_GROUPS = "SET_SELECTED_GROUPS";
export const SET_SELECTED_CUSTOMER = "SET_SELECTED_CUSTOMER";

const client = Client;

const _updateGroups = (content) => ({
  type: UPDATE_GROUPS,
  payload: content,
});

const _setSelectedGroups = (content) => ({
  type: SET_SELECTED_GROUPS,
  payload: content,
});

export const setSelectedGroups = (groups) => (dispatch, getState) => {
  return dispatch(_setSelectedGroups(groups));
};

export const setSelectedCustomer = (customer) => ({
  type: SET_SELECTED_CUSTOMER,
  payload: customer,
});

export const getGroupsByUser = (userId) => (dispatch, getState) => {
  client
    .query({
      query: GET_GROUPS_QUERY,
      variables: { userId: userId },
    })
    .then((res) => {
      /*const mockData = [
        {
          id: 1,
          name: "Grupo 1A",
          parentGroupId: null,
          customer: {
            id: 1,
            name: "Cliente A",
          },
        },
        {
          id: 2,
          name: "Grupo 1-1 A",
          parentGroupId: 1,
          customer: {
            id: 1,
            name: "Cliente A",
          },
        },
        {
          id: 3,
          name: "Grupo 1-2 A",
          parentGroupId: 1,
          customer: {
            id: 1,
            name: "Cliente A",
          },
        },
        {
          id: 4,
          name: "Grupo 2A",
          parentGroupId: null,
          customer: {
            id: 1,
            name: "Cliente A",
          },
        },
        {
          id: 5,
          name: "Grupo 2-1 A",
          parentGroupId: 4,
          customer: {
            id: 1,
            name: "Cliente A",
          },
        },
        {
          id: 6,
          name: "Grupo 1B",
          parentGroupId: null,
          customer: {
            id: 2,
            name: "Cliente B",
          },
        },
        {
          id: 7,
          name: "Grupo 1-1 B",
          parentGroupId: 6,
          customer: {
            id: 2,
            name: "Cliente B",
          },
        },
        {
          id: 3,
          name: "Grupo 1-2 B",
          parentGroupId: 6,
          customer: {
            id: 2,
            name: "Cliente B",
          },
        },
      ];*/

      if (res && res.data && res.data.groups) {
        return dispatch(_updateGroups(res.data.groups));
        //return dispatch(_updateGroups(mockData));
      }
    })
    .catch((err) => {
      console.log("Error: ", err);
    });
};
