import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

// // Get the current location.
// const location = history.location;

// // Listen for changes to the current location.
// history.listen((location, action) => {
//     debugger;
//     //Do your logic here and dispatch if needed
// });

export default history;
