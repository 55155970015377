import React, { useState, useEffect } from "react";

import { format } from "date-fns";

import Table from "../../../components/Base/Table";
import { hasPermission } from "../../../utils/generalUtils";

import { useTranslation } from "../../../lang";

const ReportResult = ({ reportData, loggedUser }) => {
  const [t] = useTranslation();
  const greenColumn = {
    backgroundColor: "green",
  };

  const columns = [
    {
      dataField: "nMov",
      text: t("Móvil"),
      sort: true,
    },
    {
      dataField: "pat",
      text: t("Placa"),
      sort: true,
    },
    {
      dataField: "iTime",
      text: t("Inicio"),
      sort: true,
      formatter: (cell, row) =>
        format(new Date(row.iTime), "dd/MM/yyyy HH:mm:ss"),
    },
    {
      dataField: "fTime",
      text: t("Fin"),
      sort: true,
      formatter: (cell, row) =>
        format(new Date(row.fTime), "dd/MM/yyyy HH:mm:ss"),
    },
    //Permiso Especial
    hasPermission("ExtraColumnsEcoTracking", loggedUser.roles) && {
      dataField: "kmsRec",
      text: t("Kms Recorridos"),
      sort: true,
    },
    //Permiso Especial
    hasPermission("ExtraColumnsEcoTracking", loggedUser.roles) && {
      dataField: "consumptionRangeOne",
      text: t("Consumo Baja"),
      sort: true,
    },
    //Permiso Especial
    hasPermission("ExtraColumnsEcoTracking", loggedUser.roles) && {
      dataField: "consumptionRangeTwo",
      text: t("Consumo Media"),
      sort: true,
    },
    //Permiso Especial
    hasPermission("ExtraColumnsEcoTracking", loggedUser.roles) && {
      dataField: "consumptionRangeThree",
      text: t("Consumo Alta"),
      sort: true,
    },
    //Permiso Especial
    hasPermission("ExtraColumnsEcoTracking", loggedUser.roles) && {
      dataField: "consumptionIdle",
      text: t("Consumo Ralenti"),
      sort: true,
    },
    //Permiso Especial
    hasPermission("ExtraColumnsEcoTracking", loggedUser.roles) && {
      dataField: "cO2Emissions",
      text: t("cO2"),
      sort: true,
    },
    //Permiso Especial
    hasPermission("ExtraColumnsEcoTracking", loggedUser.roles) && {
      dataField: "totalLiters",
      text: t("Litros Total [l]"),
      sort: true,
    },
    {
      dataField: "co2NoxEmission",
      text: t("Emision CO2 - NOx Total[g]"),
      sort: true,
      headerStyle: () => ({ backgroundColor: "#027812" }),
      style: () => greenColumn,
    },
  ];

  return (
    <div style={{ padding: "20px" }}>
      <Table columns={columns} data={reportData} keyField="opNumber" />
    </div>
  );
};

export default ReportResult;
