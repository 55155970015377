export const AVAILABLE_VARIANTS = [
  "success",
  "danger",
  "alert",
  "inactive",
  "person",
  "low-connection",
  "generic",
];

// Mapas para PERU
export const AVAILABLE_MAP_TILES = {
  // para zoom > 16
  openGeo16: {
    tile:
      "https://maps.satellitalpatrol.com/geoserver/gwc/service/wmts/?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=satpatrol%3Amapabase&STYLE=&TILEMATRIXSET=EPSG%3A900913&TILEMATRIX=EPSG%3A900913%3A{z}&TILEROW={y}&TILECOL={x}&FORMAT=image%2Fpng8",
    layer: "satpatrol:mapabase",
  },
  // para zoom < 16
  openGeo: {
    // eslint-disable-next-line no-template-curly-in-string
    tile: "https://maps.satellitalpatrol.com:8444/{z}/{x}/{y}.png",
    layer: "opengeo",
  },
  argis: {
    tile:
      "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png",
    layer: "opengeo",
  },
  // Layer de superposicion, son rutas
  openGeoKm: {
    tile: "https://maps.satellitalpatrol.com/geoserver/gwc/service/wms?",
    layer: "satpatrol:kmfinal",
  },
  gebco: {
    tile:
      "https://www.gebco.net/data_and_products/gebco_web_services/web_map_service/mapserv?",
    layer: "GEBCO_LATEST",
    format: "image/png",
    transparent: true,
  },
  default: {
    tile:
      "https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png",
  },
};
