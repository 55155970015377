import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import Table from "../../../components/Base/Table";
import { useTranslation } from "../../../lang";
import { GetDateFormat } from "../../../utils/generalUtils";
import CustomStyleSelect from "../../../utils/reactSelectStyle";
import Chart from "./Chart";

const ReportResult = ({
  reportData,
  assetList,
  variableList,
  getVariableFieldName,
  user,
}) => {
  const [t] = useTranslation();
  const [assetSelected, setAssetSelected] = useState({
    label: reportData[0].name,
    value: reportData[0].mid,
  });
  const [variableSelected, setVariableSelected] = useState(variableList[0]);
  const [filteredData, setFilteredData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (!assetSelected) return;

    const newData = reportData.filter((rd) => rd.mid === assetSelected.value);
    setFilteredData(newData);
  }, [assetSelected, reportData]);

  useEffect(() => {
    if (!variableList) return;

    let aux = [];

    const coltime = {
      dataField: "Time",
      text: "Time",
      formatter: (cell, row) =>
        format(new Date(row.Time), GetDateFormat(user.language)),
    };
    aux.push(coltime);
    variableList.forEach(({ value, label }) => {
      let col = {
        dataField: getVariableFieldName(value),
        text: label,
      };
      if (value == "Odometro") {
        col = {
          dataField: getVariableFieldName(value),
          text: label,
          formatter: (cell, row) => row.odo.toFixed(2),
        };
      }
      aux.push(col);
    });
    setColumns(aux);
  }, [variableList]);

  return (
    <div style={{ padding: "20px" }}>
      <div style={{ width: "250px", marginBottom: "20px" }}>
        <Select
          placeholder={t("Móviles")}
          options={assetList}
          value={assetSelected}
          onChange={(opt) => {
            setAssetSelected(opt);
          }}
          styles={CustomStyleSelect}
        />
      </div>
      {Array.isArray(columns) && columns.length > 0 && (
        <Table columns={columns} data={filteredData} keyField="Time" />
      )}
      <div style={{ width: "250px", marginTop: "20px", marginBottom: "20px" }}>
        <Select
          placeholder={t("Variable OBD2")}
          options={variableList}
          value={variableSelected}
          onChange={(opt) => {
            setVariableSelected(opt);
          }}
          styles={CustomStyleSelect}
        />
      </div>
      <Chart
        data={filteredData}
        variableOBD2={getVariableFieldName(variableSelected.value)}
        variableLabel={variableSelected.label}
      />
    </div>
  );
};

export default ReportResult;
