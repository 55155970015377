import { t } from "../lang";

export const REVISIONS_TYPE = [
  {
    value: "",
    text: t("Seleccionar"),
  },
  {
    value: 10,
    text: t("Revisión técnica"),
  },
  {
    value: 20,
    text: t("Frenos"),
  },
  {
    value: 30,
    text: t("Llantas"),
  },
  {
    value: 40,
    text: t("Batería"),
  },
  {
    value: 50,
    text: t("Cambio de aceite"),
  },
  {
    value: 60,
    text: t("Tren delantero"),
  },
  {
    value: 70,
    text: t("Correa de transmición"),
  },
  {
    value: 80,
    text: t("Electricidad"),
  },
];

export const REVISIONS_FREQUENCY = [
  {
    value: "",
    text: t("Unidad"),
  },
  {
    value: 10,
    text: t("Horas"),
  },
  {
    value: 20,
    text: t("Dias"),
  },
  {
    value: 30,
    text: t("Meses"),
  },
  {
    value: 40,
    text: t("Años"),
  },
  {
    value: 50,
    text: t("Km"),
  },
];

export const ALERTS_PERCENT = [
  {
    value: "",
    text: t("Seleccionar"),
  },
  {
    value: 10,
    text: t("10% antes del límite"),
  },
  {
    value: 20,
    text: t("20% antes del límite"),
  },
  {
    value: 30,
    text: t("30% antes del límite"),
  },
  {
    value: 40,
    text: t("40% antes del límite"),
  },
  {
    value: 50,
    text: t("50% antes del límite"),
  },
];
