import gql from "graphql-tag";

const TOP_NOTIFICATIONS_QUERY = gql`
  query Notifications(
    # $onlyUnreaded: Boolean
    $typeNotificationId: Int
    $assetid: Int
    $typeAlarm: [TypeAlarm]
    $event: String
    $pageNumber: Int
    $pageSize: Int
  ) {
    notifications(
      # onlyUnreaded: $onlyUnreaded
      typeNotificationId: $typeNotificationId
      assetid: $assetid
      typeAlarm: $typeAlarm
      event: $event
      pageNumber: $pageNumber
      pageSize: $pageSize
    ) {
      cantUnACK
      cantUnACKAlarms
      cantUnACKReport
      cantUnACKMaintenance
      cantUnACKHardware
      cantUnACKSoftware
      cantUnACKWithoutTransmision
      total
      notifications {
        id
        datereaded
        event
        source
        date
        point {
          id
          dir
          lat
          lon
          vel
          nCli
          fGPS
        }
        typeNotification {
          id
        }
        # id
        # point {
        #   id
        #   fGPS
        #   evtDesc
        #   tevtid
        #   pat
        #   lat
        #   lon
        #   vel
        #   mid
        #   dir
        #   nCli
        # }
        # assetid
        # source
        # event
        # datereaded
        # date
        # typeAlarm
        # typeNotificationid
        # typeNotification {
        #   id
        #   description
        # }
        # userRead {
        #   id
        #   userName
        # }
      }
    }
  }
`;

export default TOP_NOTIFICATIONS_QUERY;
