import gql from "graphql-tag";

const DETENTIONREPORT_EXPORT_QUERY = gql`
  query DetentionReportExport(
    $assetsSelected: [Int]!
    $driversSelected: [Int]
    $fechaInicio: Datetime
    $fechaFin: Datetime
    $detentionStatus: DetentionStatus
    $minimumTime: Int
    $days: [WeekDaysEnum]
    $format: FormatEnum
  ) {
    detentionReportExport(
      assetsSelected: $assetsSelected
      driversSelected: $driversSelected
      fechaInicio: $fechaInicio
      fechaFin: $fechaFin
      detentionStatus: $detentionStatus
      minimumTime: $minimumTime
      days: $days
      format: $format
    )
  }
`;

export default DETENTIONREPORT_EXPORT_QUERY;
