import gql from "graphql-tag";

const DELETE_USER_MUTATION = gql`
  mutation DeleteUser($userId: Int!) {
    deleteUser(userId: $userId) {
      id
    }
  }
`;

export default DELETE_USER_MUTATION;
