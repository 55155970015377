import gql from "graphql-tag";

const HISTORY_ASSETDEVICE_QUERY = gql`
  query HistoryAssetDevice($assetId: Int!) {
    historyAssetDevice(assetId: $assetId) {
      id
      deviceIdentifier
      deviceModel
      deviceType
      deviceSerialNumber
      devicePhoneNumber
      technicianFirstname
      technicianLastname
      date
      typeOperation
    }
  }
`;

export default HISTORY_ASSETDEVICE_QUERY;
