import gql from "graphql-tag";

const GET_PERMISSION_QUERY = gql`
  query Permission {
    permission {
      id
      name
      description
      category
    }
  }
`;

export default GET_PERMISSION_QUERY;
