import React, { Component } from "react";
import { Route, Router } from "react-router-dom";
import { ToastContainer, Zoom } from "react-toastify";
import Structure from "../components/Base/Structure";
import CargoTrackingPage from "../components/CargoTracking/Page";
// import MapBasePage from "../pages/Map/Base";
import MultipleTracking from "../components/MultipleTracking/MultipleTracking";
// import BaseMap from "../components/Map/BaseMap";
import BaseMap from "../containers/ConnectedBaseMap";
import AutoLogin from "../pages/AutoLogin";
import Forbidden from "../pages/Forbidden";
import LoginPage from "../pages/LoginPage";
import UserPages from "../pages/UserPages";
import history from "../utils/history";
import ManageRoutes from "./ManageRoutes";
import MapRoutes from "./MapRoutes";
import PrivateRoute from "./PrivateRoute";
import PublicOnlyRoute from "./PublicOnlyRoute";
import ReportRoutes from "./StatisticsRoutes";

/**
 * Each route will have to be explicitly declared with the component you will render
 * on each one.
 * As we are using webpack as our dev server, each one of these routes will also have
 * to be declared in the config file.
 * For production environments some other configuration will be necessary (to be completed later).
 * Let's go to webpack.config.js and find the `vizionAppResponse` object.
 */
export default class PageRoutes extends Component {
  render() {
    /**
     * Manage pages needs to be on top, UserPages is blocking the access
     * to them if they are put below it.
     */

    return (
      <Router history={history}>
        <ToastContainer
          transition={Zoom}
          position="top-right"
          autoClose={2500}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
          closeButton={false}
          bodyClassName="toaster-container"
        />

        <UserPages>
          <Route
            path="/"
            exact
            render={(routerParams) => (
              <PublicOnlyRoute
                path="/"
                exact
                component={LoginPage}
                {...routerParams}
              />
            )}
          />

          <Route path="/cargoTracking" component={CargoTrackingPage} />

          <Route path="/autologin/:token" component={AutoLogin} />

          <Route
            path="/login"
            exact
            component={LoginPage}
            /*render={(routerParams) => (
                            <PublicOnlyRoute
                                path="/login"
                                exact
                                component={LoginPage}
                                {...routerParams}
                            />
                        )}*/
          />

          <Route
            path="/map"
            render={(routerParams) => <MapRoutes {...routerParams} />}
          />

          <Route
            path="/tracking/:assetId"
            exact
            component={(routerProps) => (
              <Structure>
                <PrivateRoute component={BaseMap} {...routerProps} />
              </Structure>
            )}
          />
          {/* <Route
            path="/tracking/:assetId"
            component={BaseMap}
            // render={(routerParams) => <MapRoutes {...routerParams} />}
          /> */}

          <Route
            path="/mtracking"
            component={MultipleTracking}
            // render={(routerParams) => <MapRoutes {...routerParams} />}
          />
        </UserPages>

        {/* <Route
          path="/mtracking"
          render={({ match: { path } }) => <ManageRoutes path={path} />}
        /> */}

        <Route
          path="/manage"
          render={({ match: { path } }) => <ManageRoutes path={path} />}
        />

        <Route
          path="/report"
          render={({ match: { path } }) => <ReportRoutes path={path} />}
        />

        <Route path="/forbidden" render={() => <Forbidden />} />
      </Router>
    );
  }
}
