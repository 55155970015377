import gql from "graphql-tag";

const STOP_HISTORY_QUERY = gql`
  query StopHistoryReport(
    $assetsSelected: [Int]
    $dateFrom: Datetime
    $dateTo: Datetime
    $roadId: Int
  ) {
    stopHistoryComparisonReport(
      assetsSelected: $assetsSelected
      dateFrom: $dateFrom
      dateTo: $dateTo
      roadId: $roadId
    ) {
      openArmReport {
        order
        pat
        nMov
        lat
        lon
        fGPS
        nInt
        stop_time
      }
      roadReport {
        name
        id
        description
        overviewPolyline
        bufferedDecodedOverviewPolyline
        roadGeofence {
          geofence {
            id
            name
            geomText
          }
        }
      }
    }
  }
`;

export default STOP_HISTORY_QUERY;
