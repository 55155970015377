import React from "react";
import { connect } from "react-redux";

import ConnectedBaseMap from "../../containers/ConnectedBaseMap";
import Structure from "../Base/Structure";
import { useTranslation } from "../../lang";

const MultipleTracking = ({ assetsMultipleTracking }) => {
  const [t] = useTranslation();

  return (
    <Structure>
      <div>
        {assetsMultipleTracking && assetsMultipleTracking.length > 0 ? (
          assetsMultipleTracking.map((m, index) => {
            if (index <= 3)
              return (
                <div className="divSquare" key={m}>
                  <ConnectedBaseMap mapId={m} />
                </div>
              );

            return null;
          })
        ) : (
          <div
            style={{
              display: "flex",
              "padding-top": "10%",
              "justify-content": "center",
              height: "100%",
            }}
          >
            <h3 style={{ color: "#D8D0CD" }}>
              {t("Aún no ha agregado ningún móvil al Seguimiento Múltiple")}
            </h3>
          </div>
        )}
      </div>
    </Structure>
  );
};

const _mapStateToProps = ({ base }) => {
  return {
    assetsMultipleTracking: base.assetsMultipleTracking,
  };
};

const _mapDispatchToProps = {};

export default connect(_mapStateToProps, _mapDispatchToProps)(MultipleTracking);
