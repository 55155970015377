import React from "react";

import Table from "../../../components/Base/Table";
import { useTranslation } from "../../../lang";

const ReportResult = ({ reportData }) => {
  const [t] = useTranslation();
  const columns = [
    {
      dataField: "typeMobile",
      text: t("Tipo Movil"),
      sort: true,
      headerStyle: () => ({ width: "10%" }),
    },
    {
      dataField: "name",
      text: t("Nombre Móvil"),
      sort: true,
      headerStyle: () => ({ width: "15%" }),
    },
    {
      dataField: "pat",
      text: t("Placa"),
      sort: true,
      headerStyle: () => ({ width: "10%" }),
    },
    {
      dataField: "kmsRec",
      text: t("Kms Recorridos"),
      sort: true,
      headerStyle: () => ({ width: "10%" }),
      formatter: (cell, row) => row.kmsRec.toFixed(2),
    },
    {
      dataField: "velProm",
      text: t("Vel_ Maxima Promedio"),
      sort: true,
      headerStyle: () => ({ width: "15%" }),
      formatter: (cell, row) => Math.round(row.velProm),
    },
    {
      dataField: "velMax",
      text: t("Velocidad Máxima"),
      sort: true,
      headerStyle: () => ({ width: "10%" }),
      formatter: (cell, row) => Math.round(row.velMax),
    },
    {
      dataField: "dir",
      text: t("Ubicación Velocidad Máxima"),
      sort: true,
      headerStyle: () => ({ width: "30%" }),
    },
  ];

  return (
    <div className="resultPart">
      <Table columns={columns} data={reportData} keyField="pat" />
    </div>
  );
};

export default ReportResult;
