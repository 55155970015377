import React from 'react';
import PropTypes from 'prop-types';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import './ExpansionPanel.scss';

const CustomExpansionPanel = ({ index, summary, details }) => (
    <ExpansionPanel className="custom-expansion-panel">
        <ExpansionPanelSummary
            expandIcon={
                <ExpandMoreIcon className="custom-expansion-panel__expand-icon" />
            }
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
        >
            {summary}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>{details}</ExpansionPanelDetails>
    </ExpansionPanel>
);

CustomExpansionPanel.propTypes = {
    index: PropTypes.number,
    summary: PropTypes.node,
    details: PropTypes.node
};

CustomExpansionPanel.defaultProps = {
    index: 0,
    summary: null,
    details: null
};

export default CustomExpansionPanel;
