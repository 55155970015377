import gql from "graphql-tag";

const UPDATE_USER_LANGUAGE_MUTATION = gql`
  mutation UpdateUserLanguage($userId: Int!, $language: String!) {
    updateUser(userId: $userId, language: $language) {
      id
    }
  }
`;

export default UPDATE_USER_LANGUAGE_MUTATION;
