import React, { useState } from "react";

import RemoteTable from "../../../components/Base/RemoteTable";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Button } from "@material-ui/core";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

import ThreeDotMenu from "../../../components/Base/ThreeDotMenu";
import PoiForm from "./Form";
import MiniMap from "../../../components/Base/MiniMap";
import ModalConfirmacion from "../../../components/Base/ModalConfirmacion";

import { useTranslation } from "../../../lang";

const List = ({
  poiList,
  getPoi,
  createPoi,
  updatePoi,
  deletePoi,
  paginationSizes,
  total,
  tableState,
  setTableState,
}) => {
  const [t] = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [selectedPoi, setSelectedPoi] = useState();
  const [miniMapOpen, setMiniMapOpen] = useState(false);
  const closeMiniMap = () => setMiniMapOpen(false);

  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const closeModalConfirm = () => setIsOpenConfirm(false);
  const [confirmText, setConfirmText] = useState();
  const [idToDelete, setIdToDelete] = useState();

  React.useEffect(() => {
    getPoi && getPoi();
  }, []);

  const confirmDelete = (id, name) => {
    confirmAlert({
      title: t("Eliminar Punto de Interés"),
      message: t("pages_manage_pois_seguro_eliminar_poi", { name }),
      buttons: [
        {
          label: t("Si"),
          onClick: () => {
            deletePoi(id);
          },
        },
        {
          label: t("No"),
          onClick: () => console.log("cancelar"),
        },
      ],
    });
  };

  const callbackSelectOption = (option, row) => {
    switch (option) {
      case 1: // View POI
        const poiShow = poiList.find((x) => x.id === row.id);
        setSelectedPoi(poiShow);
        setMiniMapOpen(true);
        break;
      case 2: // Edit
        const poi = poiList.find((x) => x.id === row.id);
        setSelectedPoi(poi);
        setOpenModal(true);
        break;
      case 3: // Delete
        //confirmDelete(row.id, row.name);
        setIsOpenConfirm(true);
        setConfirmText(
          t("pages_manage_pois_seguro_eliminar_poi", { name: row.name })
        );
        setIdToDelete(row.id);
        break;
    }
  };

  const itemStyle = {
    fontSize: "12px",
  };

  const columns = [
    {
      dataField: "id",
      text: "",
      headerStyle: () => ({ width: "50px", fontSize: "12px" }),
      formatter: (cell, row) => {
        return (
          <>
            <ThreeDotMenu
              key={row.id}
              row={row}
              callback={callbackSelectOption}
              options={[
                {
                  label: t("Editar"),
                  value: 2,
                },
                {
                  label: t("Ver Puntos de Interés"),
                  value: 1,
                },
                {
                  label: t("Eliminar"),
                  value: 3,
                },
              ]}
            />
          </>
        );
      },
    },
    {
      dataField: "name",
      text: t("Nombre"),
      style: () => itemStyle,
      sort: true,
    },
    {
      dataField: "customer.name",
      text: t("Cliente"),
      style: () => itemStyle,
      sort: true,
    },
    {
      dataField: "latitude",
      text: t("Latitud"),
      style: () => itemStyle,
      sort: true,
    },
    {
      dataField: "longitude",
      text: t("Longitud"),
      style: () => itemStyle,
      sort: true,
    },
    {
      dataField: "radio",
      text: t("Radio"),
      style: () => itemStyle,
      sort: true,
    },

    {
      dataField: "PoiCategory.poiDescription",
      text: t("Categoría"),
      style: () => itemStyle,
    },
  ];

  const options = {
    // Tooltips botones de paginación
    prePageTitle: t("pagina previa"),
    nextPageTitle: t("próxima página"),
    firstPageTitle: t("primer página"),
    lastPageTitle: t("última página"),
    showTotal: true,
    //paginationTotalRenderer: customTotal,
    sizePerPageList: paginationSizes,
    totalSize: total,
  };

  const onSearch = (searchText) => {
    if (!searchText) {
      setTableState({
        ...tableState,
        pageNumber: 1,
        searchText: undefined,
      });
    }

    if (searchText.length >= 3) {
      setTableState({
        ...tableState,
        pageNumber: 1,
        searchText: searchText,
      });
    }
  };

  const onPaginate = (page, sizePerPage) => {
    setTableState({
      ...tableState,
      pageNumber: page,
      pageSize: sizePerPage,
    });
  };

  const onSort = (sortObj) => {
    setTableState({
      ...tableState,
      sort: sortObj,
    });
  };

  return (
    <div className="listContainer">
      <ModalConfirmacion
        isOpen={isOpenConfirm}
        buttonConfirmation={t("Confirmar eliminación")}
        closeModal={closeModalConfirm}
        confirmationText={confirmText}
        handleConfirm={() => {
          deletePoi(idToDelete);
        }}
        title={t("IMPORTANTE")}
      />
      {selectedPoi && (
        <MiniMap
          closeModal={closeMiniMap}
          title={t("Ver Punto de Interés")}
          lat={selectedPoi.latitude}
          lon={selectedPoi.longitude}
          tooltipText={selectedPoi.name}
          geofence={JSON.stringify({
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [selectedPoi.longitude, selectedPoi.latitude],
            },
            properties: {
              name: selectedPoi.name,
            },
          })}
          isOpen={miniMapOpen}
        />
      )}
      <SlidingPane
        className="slidingPaneRight"
        isOpen={openModal}
        title={
          selectedPoi
            ? t("Actualizar Punto de Interés")
            : t("Crear Punto de Interés")
        }
        width="600px"
        onRequestClose={() => {
          setOpenModal(false);
        }}
      >
        <PoiForm
          closeModal={() => setOpenModal(false)}
          createPoi={createPoi}
          selectedPoi={selectedPoi}
          updatePoi={updatePoi}
        />
      </SlidingPane>

      <RemoteTable
        columns={columns}
        data={poiList}
        keyField="id"
        paginationOptions={options}
        onPaginate={onPaginate}
        onSearch={onSearch}
        onSort={onSort}
        total={total}
        lateralComponent={
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setSelectedPoi(null);
              setOpenModal(true);
            }}
            style={{ float: "right" }}
          >
            {t("+ Punto de Interés")}
          </Button>
        }
      />
    </div>
  );
};

export default List;
