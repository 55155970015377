import React, { useEffect, useRef, memo } from "react";

import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory, {
  PaginationProvider,
  // PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
// import useWindowDimensions from "../../utils/useWindowDimensions";

import RemoteTable from "../Base/RemoteTable";

import NotificationItem from "./NotificationItem";
import { useTranslation } from "../../lang";
// import { notification } from "antd";

const ListNotificationsPaginated = ({
  notificationList,
  getNotificationsPaginated,
  typeSel,
  onlyUnreaded,
  assetid,
  typeAlarm,
  setMarkerSelected,
  ackAlarms,
  selectNotification,
  unselectNotification,
  selectedNotifications,
  loggedUser,
  pageSize,
  total,
}) => {
  const containerRef = useRef();
  const [t] = useTranslation();

  // const [paginationOptions, setPaginationOptions] = useState();
  // const [currentPage, setCurrentPage] = useState(1);
  // const { height } = useWindowDimensions();

  // const handleResize = () => {
  //   const elementsPerPage = Math.floor((height - 110) / 90);

  //   setPaginationOptions({
  //     prePageTitle: t("pagina previa"),
  //     nextPageTitle: t("próxima página"),
  //     firstPageTitle: t("primer página"),
  //     lastPageTitle: t("última página"),
  //     sizePerPage: elementsPerPage,
  //     hideSizePerPage: true,
  //     // custom: true,
  //     totalSize: notificationList.length,
  //     onPageChange: (newPage) => {
  //       setCurrentPage(newPage);
  //     },
  //     page:
  //       paginationOptions &&
  //       (paginationOptions.sizePerPage !== elementsPerPage ||
  //         paginationOptions.totalSize !== notificationList.length)
  //         ? 1
  //         : currentPage,
  //   });
  // };

  // useEffect(() => {
  //   handleResize();
  // }, [notificationList, height]);

  // useEffect(() => {
  //   setPaginationOptions({ ...paginationOptions, page: currentPage });
  // }, [currentPage]);

  useEffect(() => {
    if (assetid) {
      getNotificationsPaginated({
        // get saved params on localstorage
        typeNotification: typeSel === -1 ? undefined : typeSel,
        onlyUnreaded: onlyUnreaded,
        assetid: assetid,
        typeAlarm: typeSel != 1 || typeAlarm == -1 ? undefined : [typeAlarm],
        pageSize: pageSize,
        pageNumber: 1,
      });
    }
  }, [assetid]);

  useEffect(() => {
    if (!assetid) {
      const typeAlarmFilter =
        typeSel != 1 || typeAlarm == -1 ? undefined : [typeAlarm];

      getNotificationsPaginated({
        // get saved params on localstorage
        typeNotification: typeSel === -1 ? undefined : typeSel,
        onlyUnreaded: onlyUnreaded,
        typeAlarm: typeAlarmFilter,
        pageSize: pageSize,
        pageNumber: 1,
      });
    }

    // window.addEventListener("resize", handleResize);

    // return (_) => window.removeEventListener("resize", handleResize);
  }, []);

  const columns = [
    {
      dataField: "id",
      headerAttrs: {
        hidden: true,
      },
      text: "",
      formatExtraData: {
        setMarkerSelected,
        ackAlarms,
        selectNotification,
        unselectNotification,
        selectedNotifications,
        loggedUser,
      },
      formatter: (
        cell,
        row,
        rowIndex,
        {
          setMarkerSelected,
          ackAlarms,
          selectNotification,
          unselectNotification,
          selectedNotifications,
          loggedUser,
        }
      ) => {
        return (
          <NotificationItem
            key={row.id}
            item={row}
            setMarkerSelected={setMarkerSelected}
            ackAlarms={ackAlarms}
            selectNotification={selectNotification}
            unselectNotification={unselectNotification}
            selectedNotifications={[...selectedNotifications]}
            loggedUser={loggedUser}
          />
        );
      },
    },
  ];

  const rowStyle = { border: 0 };

  const handlePaginate = (page, sizePerPage) => {
    const typeAlarmFilter = typeAlarm == -1 ? undefined : [typeAlarm];

    const eventStorage = localStorage.getItem(`event_${loggedUser.id}`);

    getNotificationsPaginated({
      // get saved params on localstorage
      // typeNotification: typeSel === -1 ? undefined : typeSel,
      typeNotification:
        typeAlarm !== -1 && typeAlarm !== "EVENTOS" ? 1 : typeSel,
      onlyUnreaded: onlyUnreaded,
      typeAlarm: typeAlarmFilter,
      pageSize: sizePerPage,
      pageNumber: page,
      event:
        typeAlarm !== -1 && typeAlarm !== "EVENTOS" ? undefined : eventStorage,
    });
  };

  const pageButtonRenderer = ({ page, active, onPageChange }) => {
    const handleClick = (e) => {
      e.preventDefault();
      onPageChange(page);
    };
    const activeStyle = {};
    if (active) {
      activeStyle.backgroundColor = "black";
      activeStyle.color = "white";
    } else {
      activeStyle.backgroundColor = "gray";
      activeStyle.color = "black";
    }
    if (typeof page === "string") {
      activeStyle.backgroundColor = "white";
      activeStyle.color = "black";
    }
    return (
      <li className="page-item">
        <a href="#" onClick={handleClick} style={activeStyle}>
          {page}
        </a>
      </li>
    );
  };

  const pageListRenderer = ({ pages, onPageChange }) => {
    return (
      <div id="listNotificationPaginationContainer">
        <div style={{ paddingTop: "7px" }}>
          {pages.map((p, index) => (
            <button
              className={`btnCustomPagination ${
                p.active && "btnCustomPaginationActive"
              }`}
              onClick={() => onPageChange(p.page)}
              key={index}
            >
              {p.page}
            </button>
          ))}
        </div>
      </div>
    );
  };

  const handleTableChange = (type, { page, sizePerPage }) => {
    if (type === "pagination") {
      handlePaginate(page, sizePerPage);
    }
  };

  const paginationOptions = {
    prePageTitle: t("pagina previa"),
    nextPageTitle: t("próxima página"),
    firstPageTitle: t("primer página"),
    lastPageTitle: t("última página"),
    pageButtonRenderer,
    pageListRenderer,
    hideSizePerPage: true,
    sizePerPageList: [pageSize],
    totalSize: total,
  };

  return (
    <div
      className="list-notif-paginated"
      style={{
        height: "75vh",
        overflowY: "auto !important",
      }}
      ref={containerRef}
    >
      {/* <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => (
          <>
            <BootstrapTable
              keyField="id"
              data={notificationList || []}
              columns={columns}
              bootstrap4
              bordered={false}
              rowStyle={rowStyle}
              {...paginationTableProps}
            />
          </>
        )}
      </PaginationProvider> */}

      {/* <RemoteTable
        keyField="id"
        data={notificationList || []}
        columns={columns}
        rowStyle={rowStyle}
        paginationOptions={paginationOptions}
        total={total}
        onPaginate={handlePaginate}
      /> */}

      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => (
          <>
            <BootstrapTable
              keyField="id"
              data={notificationList || []}
              columns={columns}
              bootstrap4
              remote
              bordered={false}
              rowStyle={rowStyle}
              totalSize={total}
              onTableChange={handleTableChange}
              noDataIndication={() => (
                <div className="noData">
                  {t("No se encontraron datos para su consulta")}
                </div>
              )}
              {...paginationTableProps}
            />
          </>
        )}
      </PaginationProvider>

      {/* <BootstrapTable
        keyField="id"
        data={notificationList || []}
        columns={columns}
        bootstrap4
        bordered={false}
        rowStyle={rowStyle}
        remote
        pagination={paginationFactory(paginationOptions)}
        totalSize={total}
        onTableChange={handleTableChange}
      /> */}
    </div>
  );
};

export default memo(ListNotificationsPaginated);
