import { ApolloClient, InMemoryCache } from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { BASE_SUBSCRIPTIONS_URL } from "../../constants";

const LOGIN_URL = `${BASE_SUBSCRIPTIONS_URL}`;
const token = localStorage.getItem("access_token");

const defaultOptions = {
  subscribe: {
    fetchPolicy: "no-cache",
  },
};

const client = new SubscriptionClient(LOGIN_URL, {
  reconnect: true,
  connectionParams: {
    Authorization: token ? `Bearer ${token}` : "",
  },
});

const link = new WebSocketLink(client);

const clientSubscription = new ApolloClient({
  link,
  cache: new InMemoryCache({
    typePolicies: {
      Point: {
        keyFields: false,
      },
    },
  }),
  defaultOptions: defaultOptions,
  connectToDevTools: true,
  fetchPolicy: "no-cache",
});

export const closeAll = () => {
  client.unsubscribeAll();
};

export default clientSubscription;
