import lang, { getFixedT, t } from "i18next";
import {
  I18nextProvider,
  useTranslation,
  withTranslation,
} from "react-i18next";
import { resources as resourcesEN } from "./en/global";
import { resources as resourcesES_US } from "./es-us/global";
import { resources as resourcesES } from "./es/global";
import LangFromLocalStorage from "./LangFromLocalStorage";

const resources = {
  en: resourcesEN,
  es: resourcesES,
  es_us: resourcesES_US,
};

export const AVAILABLE_LANGS = {
  EN_US: "en-us",
  ES_SP: "es-spain",
  ES_US: "es_us",
  DEFAULT: "en-us",
};

export const LangProvider = I18nextProvider;

const user = JSON.parse(localStorage.getItem("loggedUser"));

let defaultLang = AVAILABLE_LANGS.ES_SP;

if (user && user.language) {
  switch (user.language) {
    case "US":
      defaultLang = AVAILABLE_LANGS.EN_US;
      break;
    case "ES":
      defaultLang = AVAILABLE_LANGS.ES_SP;
      break;
    case "ES_US":
      defaultLang = AVAILABLE_LANGS.ES_US;
      break;
    default:
      defaultLang = AVAILABLE_LANGS.ES_SP;
      break;
  }
}

lang.init({
  resources,
  lng: defaultLang,
  interpolation: {
    escapeValue: false,
  },
});

export const useChangeLanguage = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lang) => i18n.changeLanguage(lang);
  return [AVAILABLE_LANGS, changeLanguage];
};

export const getLabelFromLangID = (langId) => {
  switch (langId) {
    case "US":
      return "English";
    case "ES":
      return "Español";
    case "ES_US":
      return "Español (US)";
    default:
      return "Español";
  }
};

export {
  lang,
  t,
  getFixedT,
  useTranslation,
  LangFromLocalStorage,
  withTranslation,
};
