import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import {
  Button,
  FormGroup,
  FormLabel,
  Grid,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { Formik } from "formik";
import * as Yup from "yup";

import FileUpload, {
  FILE_UPLOAD_BUTTON_VARIANTS,
} from "../../components/Base/FileUpload";
import { useTranslation } from "../../lang";
import IMPORT_KML_FILE_GEOFENCE_MUTATION, {
  KMLTypes,
} from "../../graphql/mutations/importKMLFileGeofenceMutation";

const DEFAULT_AUTO_HIDE_DURATION = 5000;

const Form = ({ closeModal, selectedCustomers }) => {
  const [t] = useTranslation();
  const user = JSON.parse(localStorage.getItem("loggedUser"));

  const [resultState, setResultState] = useState({
    isOpen: false,
    type: "success",
    msg: "",
  });

  const yupSchema = Yup.object().shape({
    kml: Yup.string().required(t("Requerido")),
    customerId: Yup.number().required(t("Requerido")),
  });

  const initialValues = {
    kml: "",
    customerId:
      selectedCustomers && selectedCustomers.length === 1
        ? selectedCustomers[0].value
        : selectedCustomers,
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setResultState({
      ...resultState,
      isOpen: false,
    });
  };

  const [importKMLFile] = useMutation(IMPORT_KML_FILE_GEOFENCE_MUTATION, {
    onCompleted(d) {
      console.info("importKMLFile - Successfully uploaded file");
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Archivo KML importado correctamente"),
      });
    },
    onError(error) {
      console.info("importKMLFile - Error when trying to upload file", error);
      setResultState({
        isOpen: true,
        type: "error",
        msg: t("Ocurrió un error"),
      });
    },
  });

  const importGeofences = (kml, customerId) => {
    importKMLFile({
      variables: { kml, customerId, type: KMLTypes.DPWORLD },
    });
  };

  return (
    <>
      <Snackbar
        open={resultState.isOpen}
        autoHideDuration={DEFAULT_AUTO_HIDE_DURATION}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleCloseNotification}
          severity={resultState.type}
          variant="filled"
        >
          {resultState.msg}
        </MuiAlert>
      </Snackbar>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={yupSchema}
        onSubmit={async (values) => {
          importGeofences(values.kml, values.customerId);
        }}
      >
        {(props) => {
          const { values, handleSubmit, setFieldValue, errors } = props;
          return (
            <form onSubmit={handleSubmit}>
              <FormGroup component="language">
                <FormLabel component="legend">
                  {t("Seleccione archivo KML")}
                </FormLabel>
                <FileUpload
                  onChange={(newKML) => {
                    setFieldValue("kml", newKML);
                  }}
                  variant={FILE_UPLOAD_BUTTON_VARIANTS.PRIMARY}
                  title={t("Seleccione KML")}
                  file={values?.kml}
                  key="kml"
                />
                {errors.kml && (
                  <div className="input-feedback">{errors.kml}</div>
                )}

                {errors.customerId && (
                  <>
                    <hr />
                    <div className="input-feedback">
                      {t("Cliente invalido")}
                    </div>
                  </>
                )}
              </FormGroup>

              <Grid
                item
                lg={12}
                style={{
                  marginTop: "50px",
                  textAlign: "center",
                }}
              >
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  tabIndex="0"
                  style={{ marginRight: "20px" }}
                >
                  {t("Importar")}
                </Button>
                <Button
                  variant="contained"
                  type="button"
                  tabIndex="1"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  {t("Cancelar")}
                </Button>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

const _mapStateToProps = ({ groups }) => {
  return {
    selectedCustomers: groups.selectedCustomers,
  };
};

export default connect(_mapStateToProps, null)(Form);
