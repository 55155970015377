import gql from "graphql-tag";

const DELETE_ASSET_ECOTRACKING_MUTATION = gql`
  mutation DeleteAssetEcoTracking($id: Int!, $assetId: Int!) {
    deleteEcoTracking(id: $id, assetId: $assetId) {
      id
    }
  }
`;

export default DELETE_ASSET_ECOTRACKING_MUTATION;
