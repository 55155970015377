import { Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import Table from "../../../components/Base/Table";
import ThreeDotMenu from "../../../components/Base/ThreeDotMenu";
import ModalAsignarPermisos from "./ModalAsignarPermisos";
import RoleModal from "./RoleForm";
import { useTranslation } from "../../../lang";

const List = ({
  paginationSizes,
  total,
  roleList,
  createRole,
  updateRole,
  getRoles,
  deleteRole,
  handleAssignPermission,
}) => {
  const [t] = useTranslation();
  const [openSide, setOpenSide] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [textToSearch] = useState("");

  const [openModalPermisos, setOpenModalPermisos] = useState(false);
  const closeModalPermisos = () => setOpenModalPermisos(false);

  useEffect(() => {
    getRoles();
  }, []);

  const confirmDelete = (id, name) => {
    confirmAlert({
      title: t("Eliminar Rol"),
      message: t("pages_manage_roles_seguro_eliminar_rol", { name }),
      buttons: [
        {
          label: t("Si"),
          onClick: () => {
            deleteRole(id);
          },
        },
        {
          label: t("No"),
          onClick: () => console.log("cancelar"),
        },
      ],
    });
  };

  const itemStyle = {
    fontSize: "12px",
  };

  const callbackSelectOption = (option, row) => {
    switch (option) {
      case 1:
        setSelectedItem(row);
        setOpenSide(true);
        break;
      case 2:
        confirmDelete(row.id, `${row.name}`);
        break;
      case 3:
        setSelectedItem(row);
        setOpenModalPermisos(true);
        break;
    }
  };

  const hightlightIfMatch = (text) => {
    if (
      text &&
      textToSearch &&
      text.toLocaleLowerCase().includes(textToSearch.toLocaleLowerCase())
    ) {
      const newText = text.replace(
        new RegExp(textToSearch, "gi"),
        (match) => `<mark>${match}</mark>`
      );
      return <div dangerouslySetInnerHTML={{ __html: newText }} />;
    }

    return text;
  };

  const columns = [
    {
      dataField: "id",
      text: "",
      headerStyle: () => ({ width: "50px", fontSize: "12px" }),
      formatter: (cell, row) => {
        return (
          <>
            <ThreeDotMenu
              row={row}
              callback={callbackSelectOption}
              options={[
                {
                  label: t("Editar"),
                  value: 1,
                },
                {
                  label: t("Asignar Permisos"),
                  value: 3,
                },
                {
                  label: t("Eliminar"),
                  value: 2,
                },
              ]}
            />
          </>
        );
      },
    },
    {
      dataField: "name",
      text: t("Rol"),
      //headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      style: () => itemStyle,
      formatter: hightlightIfMatch,
      sort: true,
    },
    {
      dataField: "category",
      text: t("Categoría"),
      //headerStyle: () => ({ width: "15%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
      formatter: hightlightIfMatch,
      // filter: textFilter(),
    },
  ];

  const customTotal = (from, to, size) => (
    <span
      className="react-bootstrap-table-pagination-total customTotalDisplay"
      style={{ marginLeft: "15px" }}
    >
      {t("pages_manage_roles_mostrar_resultados", { from, to, size })}
    </span>
  );

  const options = {
    // Tooltips botones de paginación
    prePageTitle: t("Página previa"),
    nextPageTitle: t("Próxima página"),
    firstPageTitle: t("Primera página"),
    lastPageTitle: t("Última página"),
    showTotal: true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: paginationSizes,
    totalSize: total,
  };

  return (
    <div className="listContainer">
      <ModalAsignarPermisos
        closeModal={closeModalPermisos}
        roleSelected={selectedItem}
        handleAssignPermission={handleAssignPermission}
        isOpen={openModalPermisos}
      />
      <SlidingPane
        className="slidingPaneRight"
        isOpen={openSide}
        title={selectedItem ? t("Actualizar Rol") : t("Crear Rol")}
        width="400px"
        onRequestClose={() => {
          setOpenSide(false);
        }}
      >
        <RoleModal
          setIsOpen={() => setOpenSide(false)}
          createRole={createRole}
          selectedRole={selectedItem}
          updateRole={updateRole}
        />
      </SlidingPane>
      <Table
        columns={columns}
        data={roleList}
        keyField="id"
        paginationOptions={options}
        //total={total}
        lateralComponent={
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setSelectedItem(null);
              setOpenSide(true);
            }}
            style={{ float: "right" }}
          >
            {t("+ Rol")}
          </Button>
        }
      />
    </div>
  );
};

export default List;
