import gql from "graphql-tag";

const ASSIGN_GUIDE_NUMBER_MUTATION = gql`
  mutation AssignGuideNumber($GuideNumber: String, $assetId: Int) {
    assignGuideNumber(GuideNumber: $GuideNumber, assetId: $assetId) {
      id
      GuideNumber
      assetId
    }
  }
`;

export default ASSIGN_GUIDE_NUMBER_MUTATION;
