import gql from "graphql-tag";

const GET_GEOFENCES_QUERY = gql`
  query Geofence(
    $customerId: Int
    $paginate: Boolean
    $searchText: String
    $pageNumber: Int
    $pageSize: Int
    $sort: GeofenceSortInput
  ) {
    geofence(
      customerId: $customerId
      paginate: $paginate
      searchText: $searchText
      pageNumber: $pageNumber
      pageSize: $pageSize
      sort: $sort
    ) {
      count
      items {
        id
        name
        customerId
        customerName
        showOnMap
        geomText
        geomJson
        maxSpeed
        alertsInputOutput
        geofenceType
      }
    }
  }
`;

export default GET_GEOFENCES_QUERY;
