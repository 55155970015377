/* eslint-disable max-len */

const NavigationIcon = (param) => {
  switch (param) {
    case "N":
      return IconWithParam('transform="translate(-12.5 30) rotate(-45)"');
    case "NE":
      return IconWithParam('transform="translate(0 0) rotate(0)"');
    case "E":
      return IconWithParam('transform="translate(30 -12.5) rotate(45)"');
    case "SE":
      return IconWithParam('transform="translate(60 0) rotate(90)"');
    case "S":
      return IconWithParam('transform="translate(72.5 30) rotate(135)"');
    case "SO":
    case "SW":
      return IconWithParam('transform="translate(60 60) rotate(180)"');
    case "O":
    case "W":
      return IconWithParam('transform="translate(30 72.5) rotate(225)"');
    case "NO":
    case "NW":
      return IconWithParam('transform="translate(0 60) rotate(270)"');
    default:
      return Icon;
  }
};

const IconWithParam = (param) => `
    <svg 
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 60 60"
        style="enable-background:new 0 0 60 60;"
    >
        <g>
            <circle 
                cx="29.745764"
                cy="29.999998"
                r="28.728813"
            />
            <path
                class="navigation-svg__drawing"
                d="M 28.299962,58.665156 C 24.523408,58.488445 20.461607,57.423538 17.022925,55.708585 15.672093,55.034893 15.182467,54.752086 13.903776,53.906962 10.668906,51.768945 8.0509093,49.162743 5.894286,45.933556 1.8100206,39.818044 0.24267673,32.506748 1.4524064,25.213217 1.8729488,22.677743 2.7317921,19.977156 3.8860338,17.560808 7.3505402,10.308032 13.928061,4.723553 21.663204,2.4675458 c 1.592957,-0.4645968 3.211972,-0.79128 4.984216,-1.0057102 1.162987,-0.140714 4.708284,-0.1598176 5.879237,-0.03168 6.071853,0.6644445 11.396318,2.9388369 15.898036,6.7909861 0.867922,0.7426862 2.571883,2.4580053 3.273223,3.2950413 3.908273,4.664448 6.208706,10.330502 6.67066,16.430085 0.09364,1.236392 0.04207,4.04154 -0.09582,5.211865 -1.000901,8.495286 -5.492384,15.902792 -12.522146,20.651949 -3.696811,2.497487 -7.997286,4.10543 -12.493022,4.671129 -1.521357,0.191432 -3.348648,0.259231 -4.957627,0.183945 z"                
            />
        </g>
        <g ${param}>
            <path
                class="navigation-svg__circle"
                d="M 30,-1e-7 C 13.458,-1e-7 -1.5e-7,13.458 -1.5e-7,30 -1.5e-7,46.542 13.458,60 30,60 46.542,60 60,46.542 60,30 60,13.458 46.542,-9.9999998e-8 30,-1e-7 Z M 45.706,14.444 28.415,49.326 c -0.17,0.345 -0.521,0.556 -0.896,0.556 -0.056,0 -0.112,-0.005 -0.169,-0.015 -0.436,-0.074 -0.771,-0.427 -0.824,-0.865 L 24.717,34.052 8.8850001,31.398 c -0.434,-0.072 -0.769,-0.419 -0.826,-0.854 -0.057,-0.435 0.175,-0.857 0.574,-1.041 L 44.394,13.091 c 0.383,-0.177 0.84,-0.091 1.135,0.213 0.295,0.304 0.366,0.76 0.177,1.14 z"
            />
        </g>
    </svg>
`;

const Icon = `
    <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 60 60"
        style="enable-background:new 0 0 60 60;"
    >
        <g>
            <circle
                cx="29.745764"
                cy="29.999998"
                r="28.728813"
            />
            <path
                class="navigation-svg__drawing"
                d="M 28.299962,58.665156 C 24.523408,58.488445 20.461607,57.423538 17.022925,55.708585 15.672093,55.034893 15.182467,54.752086 13.903776,53.906962 10.668906,51.768945 8.0509093,49.162743 5.894286,45.933556 1.8100206,39.818044 0.24267673,32.506748 1.4524064,25.213217 1.8729488,22.677743 2.7317921,19.977156 3.8860338,17.560808 7.3505402,10.308032 13.928061,4.723553 21.663204,2.4675458 c 1.592957,-0.4645968 3.211972,-0.79128 4.984216,-1.0057102 1.162987,-0.140714 4.708284,-0.1598176 5.879237,-0.03168 6.071853,0.6644445 11.396318,2.9388369 15.898036,6.7909861 0.867922,0.7426862 2.571883,2.4580053 3.273223,3.2950413 3.908273,4.664448 6.208706,10.330502 6.67066,16.430085 0.09364,1.236392 0.04207,4.04154 -0.09582,5.211865 -1.000901,8.495286 -5.492384,15.902792 -12.522146,20.651949 -3.696811,2.497487 -7.997286,4.10543 -12.493022,4.671129 -1.521357,0.191432 -3.348648,0.259231 -4.957627,0.183945 z"
            />
        </g>
        <g>
            <path
                class="navigation-svg__circle"
                d="M 30,-1e-7 C 13.458,-1e-7 -1.5e-7,13.458 -1.5e-7,30 -1.5e-7,46.542 13.458,60 30,60 46.542,60 60,46.542 60,30 60,13.458 46.542,-9.9999998e-8 30,-1e-7 Z M 45.706,14.444 28.415,49.326 c -0.17,0.345 -0.521,0.556 -0.896,0.556 -0.056,0 -0.112,-0.005 -0.169,-0.015 -0.436,-0.074 -0.771,-0.427 -0.824,-0.865 L 24.717,34.052 8.8850001,31.398 c -0.434,-0.072 -0.769,-0.419 -0.826,-0.854 -0.057,-0.435 0.175,-0.857 0.574,-1.041 L 44.394,13.091 c 0.383,-0.177 0.84,-0.091 1.135,0.213 0.295,0.304 0.366,0.76 0.177,1.14 z"
            />
        </g>
    </svg>
`;

export default NavigationIcon;
