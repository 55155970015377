import gql from "graphql-tag";

const JOURNEY_REPORT_QUERY = gql`
  query JourneyReport(
    $movilesID: [Int]!
    $fechaInicio: Datetime
    $fechaFin: Datetime
    $groupedBy: JourneyGroupEnum
    $days: [WeekDaysEnum]
  ) {
    journeyReport(
      movilesID: $movilesID
      fechaInicio: $fechaInicio
      fechaFin: $fechaFin
      groupedBy: $groupedBy
      days: $days
    ) {
      mid
      name
      pat
      nInt
      velMax
      tiempoRecorrido
      kmsRec
      nroJourneys
      odo
      horo
      tRal
      velMaxProm
      excededPackages
      journeyReports {
        uIni
        iTime
        uFin
        fTime
        kmsRec
        tIgn
        odo
        horo
        tRal
        velMax
        excededPackages
        nroPos
        nroStops
      }
    }
  }
`;

export default JOURNEY_REPORT_QUERY;
