import { BASE_API_URL } from "../../constants";

import markersExtendedInfo from "../../__fixtures__/markersInfo.json";
import markersJourney from "../../__fixtures__/markersJourney.json";
import markersNotifications from "../../__fixtures__/markersNotifications.json";
import markersRevisions from "../../__fixtures__/markersRevisions.json";

import { fetchMarkersActions } from "./actions";

const MARKERS_URL = `${BASE_API_URL}/points/points?page=1&limit=30`;

// Actual api call here
export const fetchMarkerInfo = ({ markerId }) => {
  return markersExtendedInfo.find(({ id }) => id === markerId) || {};
};

export const fetchMarkerJourney = ({ markerId }) => {
  return markersJourney.find(({ id }) => id === markerId) || {};
};

export const fetchMarkerNotification = ({ markerId }) => {
  return markersNotifications.find(({ id }) => id === markerId) || {};
};

export const fetchMarkerRevisions = ({ markerId }) => {
  return markersRevisions.find(({ id }) => id === markerId) || {};
};

export const fetchMarkers = () => (dispatch, getState) => {
  const {
    user: { token },
  } = getState();

  fetch(MARKERS_URL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        // Will jump to the catch skipping all .then
        throw new Error("Token inválido");
      } else {
        return response.json().then((jsonResponse) => {
          dispatch(fetchMarkersActions(jsonResponse));
        });
      }
    })
    .catch((error) => {
      throw error;
    });
};
