import React, { useState } from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
} from "@material-ui/core";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import Table from "../../../components/Base/Table";

import { useTranslation } from "../../../lang";
const theme = createMuiTheme({});

const ModalUsers = ({ usersList, onSelect, isOpen, closeModal }) => {
  const [t] = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const columns = [
    {
      dataField: "firstName",
      text: t("Nombre"),
    },
    {
      dataField: "lastName",
      text: t("Apellido"),
    },
    {
      dataField: "userName",
      text: t("Usuario"),
    },
  ];

  const handleSelectMultiple = (row, isSelected) => {
    setSelectedOptions(
      isSelected
        ? [...selectedOptions, row]
        : selectedOptions.filter((x) => x.id !== row.id)
    );
  };

  const handleSelectMultipleAll = (isSelected, rows) => {
    setSelectedOptions(isSelected ? rows : []);
  };

  const multipleSelectionOption = {
    mode: "checkbox",
    clickToSelect: true,
    //selected: defaultValue,
    onSelect: handleSelectMultiple,
    onSelectAll: handleSelectMultipleAll,
    selectionHeaderRenderer: ({ checked, indeterminate }) => (
      <ThemeProvider theme={theme}>
        <Checkbox
          checked={checked}
          indeterminate={indeterminate}
          color="primary"
          className="checkboxSelectRow"
        />
      </ThemeProvider>
    ),
    selectionRenderer: (props) => {
      const { checked } = props;
      return (
        <ThemeProvider theme={theme}>
          <Checkbox
            checked={checked}
            color="primary"
            className="checkboxSelectRow"
          />
        </ThemeProvider>
      );
    },
    headerColumnStyle: { width: "50px" },
  };

  return (
    <Dialog open={isOpen} onClose={closeModal} maxWidth="sm" fullWidth>
      <DialogTitle className="dialogTitle">
        {t("Búsqueda de Usuarios")}
      </DialogTitle>
      <DialogContent>
        <Table
          columns={columns}
          data={usersList}
          keyField="id"
          selectRowOptions={multipleSelectionOption}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            closeModal();
            setSelectedOptions([]);
          }}
        >
          {t("Cancelar")}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            closeModal();
            onSelect(selectedOptions);
            setSelectedOptions([]);
          }}
        >
          {t("Seleccionar")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalUsers;
