import React, { useEffect, useState } from "react";

import Table from "../../../components/Base/Table";

import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

import { Checkbox, Button } from "@material-ui/core";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import ThreeDotMenu from "../../../components/Base/ThreeDotMenu";
import RoadForm from "./Form";
import ModalConfirmacion from "../../../components/Base/ModalConfirmacion";

import { useTranslation } from "../../../lang";
const theme = createMuiTheme({});

const List = ({
  roadList,
  assetList,
  geofenceList,
  createRoad,
  updateRoad,
  deleteRoad,
}) => {
  const [t] = useTranslation();
  const [openSide, setOpenSide] = useState(false);
  const [selectedItem, setSelectedItem] = useState();

  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const closeModalConfirm = () => setIsOpenConfirm(false);
  const [confirmText, setConfirmText] = useState();
  const [idToDelete, setIdToDelete] = useState();

  const callbackSelectOption = (option, row) => {
    switch (option) {
      case 1:
        setSelectedItem(row);
        setOpenSide(true);
        break;
      case 2:
        setIsOpenConfirm(true);
        setConfirmText(
          t("pages_manage_routes_seguro_eliminar_ruta", { name: row.name })
        );
        setIdToDelete(row.id);
        break;
    }
  };

  useEffect(() => {
    if (!openSide) {
      setSelectedItem(null);
    }
  }, [openSide]);

  const columns = [
    {
      dataField: "id",
      text: "",
      headerStyle: () => ({ width: "50px", fontSize: "12px" }),
      formatter: (cell, row) => {
        return (
          <>
            <ThreeDotMenu
              row={row}
              callback={callbackSelectOption}
              options={[
                {
                  label: t("Editar"),
                  value: 1,
                },
                {
                  label: t("Eliminar"),
                  value: 2,
                },
              ]}
            />
          </>
        );
      },
    },
    {
      dataField: "name",
      text: t("Nombre"),
      sort: true,
      headerStyle: () => ({ width: "20%" }),
    },
    {
      dataField: "hasDetourAlarm",
      text: t("Aviso Desvío"),
      sort: true,
      headerStyle: () => ({ width: "20%" }),
      formatter: (cell, row) => {
        return (
          <ThemeProvider theme={theme}>
            <Checkbox color="primary" checked={row.hasDetourAlarm} disabled />
          </ThemeProvider>
        );
      },
    },
    {
      dataField: "detourMeters",
      text: t("Mts Desvío"),
      sort: true,
      headerStyle: () => ({ width: "15%" }),
    },
    {
      dataField: "hasVelocityAlarm",
      text: t("Aviso Velocidad"),
      sort: true,
      formatter: (cell, row) => {
        return (
          <ThemeProvider theme={theme}>
            <Checkbox color="primary" checked={row.hasVelocityAlarm} disabled />
          </ThemeProvider>
        );
      },
      headerStyle: () => ({ width: "15%" }),
    },
    {
      dataField: "maxVelocity",
      text: t("Máx Velocidad [Km/h]"),
      sort: true,
      headerStyle: () => ({ width: "15%" }),
    },
  ];

  return (
    <div style={{ padding: "20px" }}>
      <ModalConfirmacion
        isOpen={isOpenConfirm}
        buttonConfirmation="Confirmar"
        closeModal={closeModalConfirm}
        confirmationText={confirmText}
        handleConfirm={() => {
          deleteRoad(idToDelete);
        }}
        title={t("IMPORTANTE")}
      />
      <SlidingPane
        className="slidingPaneRight"
        isOpen={openSide}
        title={selectedItem ? t("Actualizar Ruta") : t("Crear Ruta")}
        width="600px"
        onRequestClose={() => {
          setOpenSide(false);
          setSelectedItem(null);
        }}
      >
        <RoadForm
          closeModal={() => setOpenSide(false)}
          createRoad={createRoad}
          updateRoad={updateRoad}
          selectedRoad={selectedItem}
          assetList={assetList}
          geofenceList={geofenceList}
        />
      </SlidingPane>
      <Table
        columns={columns}
        data={roadList}
        keyField="id"
        lateralComponent={
          <Button
            color="primary"
            variant="contained"
            style={{ float: "right" }}
            onClick={() => setOpenSide(true)}
          >
            {t("+ Ruta")}
          </Button>
        }
      />
    </div>
  );
};

export default List;
