import { toast } from "react-toastify";

import {
  getClientsApi,
  insertClientApi,
  updateClientApi,
  deleteClientApi,
} from "./api";

export const SUBMIT_CLIENT_FORM = "SUBMIT_CLIENT_FORM";
export const SUBMIT_CLIENT_FORM_STARTED = "SUBMIT_CLIENT_FORM_STARTED";
export const SUBMIT_CLIENT_FORM_SUCCESS = "SUBMIT_CLIENT_FORM_SUCCESS";
export const SUBMIT_CLIENT_FORM_ERROR = "SUBMIT_CLIENT_FORM_ERROR";
export const CLIENT_GET_STARTED = "CLIENT_GET_STARTED";
export const CLIENT_GET_SUCCESS = "CLIENT_GET_SUCCESS";
export const CLIENT_GET_ERROR = "CLIENT_GET_ERROR";
export const CLIENT_SHOW_ADD_MODAL = "CLIENT_SHOW_ADD_MODAL";
export const CLIENT_SHOW_EDIT_MODAL = "CLIENT_SHOW_EDIT_MODAL";
export const CLIENT_ADD_STARTED = "CLIENT_ADD_STARTED";
export const CLIENT_ADD_SUCCESS = "CLIENT_ADD_SUCCESS";
export const CLIENT_ADD_ERROR = "CLIENT_ADD_ERROR";
export const CLIENT_EDIT_STARTED = "CLIENT_EDIT_STARTED";
export const CLIENT_EDIT_SUCCESS = "CLIENT_EDIT_SUCCESS";
export const CLIENT_EDIT_ERROR = "CLIENT_EDIT_ERROR";
export const CLIENT_DELETE_STARTED = "CLIENT_DELETE_STARTED";
export const CLIENT_DELETE_SUCCESS = "CLIENT_DELETE_SUCCESS";
export const CLIENT_DELETE_ERROR = "CLIENT_DELETE_ERROR";

export const handleShowAddModal = () => ({
  type: CLIENT_SHOW_ADD_MODAL,
});

export const handleShowEditModal = () => ({
  type: CLIENT_SHOW_EDIT_MODAL,
});

export const handleSubmitClientStarted = () => ({
  type: SUBMIT_CLIENT_FORM_STARTED,
});

export const handleSubmitClientSuccess = (payload) => ({
  type: SUBMIT_CLIENT_FORM_SUCCESS,
  payload,
});

export const handleSubmitClientError = (payload) => ({
  type: SUBMIT_CLIENT_FORM_ERROR,
  payload,
});

export const handleGetClientStarted = () => ({
  type: CLIENT_GET_STARTED,
});

export const handleGetClientSuccess = (payload) => ({
  type: CLIENT_GET_SUCCESS,
  payload,
});

export const handleGetClientError = (payload) => ({
  type: CLIENT_GET_ERROR,
  payload,
});

export const handleAddClientStarted = (payload) => ({
  type: CLIENT_ADD_STARTED,
  payload,
});

export const handleAddClientSuccess = (payload) => ({
  type: CLIENT_ADD_SUCCESS,
  payload,
});

export const handleAddClientError = (payload) => ({
  type: CLIENT_ADD_ERROR,
  payload,
});

export const handleEditClientStarted = (payload) => ({
  type: CLIENT_EDIT_STARTED,
  payload,
});

export const handleEditClientSuccess = (payload) => ({
  type: CLIENT_EDIT_SUCCESS,
  payload,
});

export const handleEditClientError = (payload) => ({
  type: CLIENT_EDIT_ERROR,
  payload,
});

export const handleDeleteClientStarted = (payload) => ({
  type: CLIENT_DELETE_STARTED,
  payload,
});

export const handleDeleteClientSuccess = (payload) => ({
  type: CLIENT_DELETE_SUCCESS,
  payload,
});

export const handleDeleteClientError = (payload) => ({
  type: CLIENT_DELETE_ERROR,
  payload,
});

export const getClientAction = () => (dispatch) => {
  dispatch(handleGetClientStarted());
  getClientsApi().then(
    (d) => dispatch(handleGetClientSuccess(d.docs)),
    (error) => dispatch(handleGetClientError(error))
  );
};

export const updateClientAction = () => (dispatch, getState) => {
  const {
    form: {
      ClientForm: {
        values: {
          _id,
          Name,
          Telefonos,
          Domicilio,
          NivelDeZoom,
          CUIT,
          Celular,
          Email,
          Suspendido,
        } = {},
      } = {},
    } = {},
  } = getState();
  dispatch(handleEditClientStarted());
  updateClientApi({
    _id,
    Name,
    Telefonos,
    Domicilio,
    NivelDeZoom,
    CUIT,
    Celular,
    Email,
    Suspendido,
  }).then(
    (d) => {
      if (d.message) {
        toast.error(d.message);
        dispatch(handleEditClientError(d.message));
      } else if (d.result && d.result.nModified === 1) {
        toast.success("El cliente se guardó correctamente");
        dispatch(
          handleEditClientSuccess({
            _id,
            Name,
            Telefonos,
            Domicilio,
            NivelDeZoom,
            CUIT,
            Celular,
            Email,
            Suspendido,
          })
        );
      }
    },
    (error) => dispatch(handleEditClientError(error))
  );
};

export const deleteClientAction = (id) => (dispatch) => {
  dispatch(handleDeleteClientStarted());
  deleteClientApi(id).then(
    (d) => {
      if (d.result && d.result.deletedCount === 1) {
        toast.success("Cliente eliminado correctamente");
        dispatch(handleDeleteClientSuccess(id));
      } else {
        toast.error("Error al eliminar cliente");
        dispatch(handleDeleteClientError("Error al eliminar cliente"));
      }
    },
    (error) => dispatch(handleDeleteClientError(error))
  );
};

export const insertClientAction = () => (dispatch, getState) => {
  const {
    form: {
      ClientForm: {
        values: {
          Name,
          Telefonos,
          Domicilio,
          NivelDeZoom,
          CUIT,
          Celular,
          Email,
          Suspendido,
        } = {},
      } = {},
    } = {},
  } = getState();
  dispatch(handleAddClientStarted());
  insertClientApi({
    Name,
    Telefonos,
    Domicilio,
    NivelDeZoom,
    CUIT,
    Celular,
    Email,
    Suspendido,
  }).then(
    (d) => {
      if (d.message) {
        toast.error(d.message);
        dispatch(handleAddClientError(d.message));
      } else {
        toast.success("El cliente se guardó correctamente");
        dispatch(handleAddClientSuccess(d));
      }
    },
    (error) => dispatch(handleAddClientError(error))
  );
};
