import GET_GENERAL_INFO_QUERY_COMPLETE from "../../graphql/queries/generalInfoExtraCompleteQuery";
import GET_GENERAL_INFO_EXTRA_QUERY from "../../graphql/queries/generalInfoExtraQuery";
import GET_GENERAL_INFO_QUERY from "../../graphql/queries/generalInfoQuery";
import Client from "../../components/Base/apiApolloClient";

export const UPDATE_MARKER_INFO = "UPDATE_MARKER_INFO";

const client = Client;

const _updateMarkerInfo = (content) => ({
  type: UPDATE_MARKER_INFO,
  payload: content,
});

export const toggleFillMarkerInfo = ({ markerInfo } = {}) => (
  dispatch,
  getState
) => {
  const {
    lateralPanel: { isPanelAuxOpen },
  } = getState();
  const eid = markerInfo.eid;
  const query = isPanelAuxOpen
    ? GET_GENERAL_INFO_QUERY_COMPLETE
    : GET_GENERAL_INFO_QUERY;
  client
    .query({
      query: query,
      variables: { equipoID: eid },
    })
    .then(
      (res) => {
        if (res != null && res.data != null && res.data.points.length > 0) {
          let dat = res.data.points[0];
          markerInfo.dir = dat.dir;
          markerInfo.cmb = dat.cmb;
          markerInfo.odo = dat.odo;
          markerInfo.sOdo = dat.sOdo;
          markerInfo.horo = dat.horo;
          markerInfo.utx = dat.utx;
          markerInfo.mFo = ""; //dat.mFo;
          markerInfo.nCh = dat.nCh == "" ? "Ramiro Fuentes" : dat.nCh;
          markerInfo.cFo = ""; //dat.cFo;
        }

        return dispatch(_updateMarkerInfo(markerInfo));
      },
      (error) => {
        return null;
      }
    );
};

export const toggleFillMarkerInfoAux = ({
  isOpen,
  markerInfo,
  panelAuxComponent,
  toggleActivePanelAux,
} = {}) => (dispatch, getState) => {
  /*const {
    lateralPanel: { isPanelAuxOpen },
  } = getState();*/

  if (isOpen && markerInfo) {
    const eid = markerInfo.eid;
    client
      .query({
        query: GET_GENERAL_INFO_EXTRA_QUERY,
        variables: { equipoID: eid },
      })
      .then(
        (res) => {
          if (res != null && res.data != null && res.data.points.length > 0) {
            let dat = res.data.points[0];
            markerInfo.mFo = ""; //dat.mFo;
            markerInfo.nCh = dat.nCh == "" ? "N/A" : dat.nCh;
            markerInfo.cFo = ""; //dat.cFo;
          }
          dispatch(_updateMarkerInfo(markerInfo));

          if (toggleActivePanelAux) {
            toggleActivePanelAux({
              panelAuxComponent: panelAuxComponent,
              isOpen: isOpen,
            });
          }
        },
        (error) => {
          return null;
        }
      );
  }
};
