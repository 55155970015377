import gql from "graphql-tag";

const GET_CONFNOTIFICATION_BYID = gql`
  query ConfNotificationById($id: Int!) {
    getConfigurationNotificationById(id: $id) {
      id
      name
      customer {
        id
        name
      }
      userCreator {
        id
        userName
      }
      enabled
      mailList
      mailListCco
      timezone
      assets {
        id
        name
        licensePlate
      }
      usersDestinations {
        id
        userName
        email
      }
      typeEvents {
        id
        description
      }
      speeding
      geofenceSpeeding
      geofences {
        id
        name
      }
      withoutTransmision
      roadEvents
      roads {
        id
        name
      }
    }
  }
`;

export default GET_CONFNOTIFICATION_BYID;
