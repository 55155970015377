import gql from "graphql-tag";

const GET_ASSET_IN_ROAD_QUERY = gql`
  query AssetInRoad(
    $dateTo: Datetime!
    $dateFrom: Datetime!
    $roadId: Int
    $assetId: Int
  ) {
    assetInRoad(
      roadId: $roadId
      assetId: $assetId
      dateTo: $dateTo
      dateFrom: $dateFrom
    ) {
      roadStart {
        fechaGPS
        latitud
        longitud
      }
      roadEnd {
        fechaGPS
        latitud
        longitud
      }
      pointRoad {
        type
        points {
          latitud
          longitud
          evtDesc
          fechaGPS
        }
      }
      road
    }
  }
`;

export default GET_ASSET_IN_ROAD_QUERY;
