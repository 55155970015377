import React from "react";

import { Grid } from "@material-ui/core";

import ScoreChart from "./ScoreChart";
import ConductionTimeChart from "./ConductionTimeChart";
import ConsumeEficiencyChart from "./ConsumeEficiencyChart";
import FuelConsumedChart from "./FuelConsumedChart";
import InfractionsChart from "./InfractionsChart";
import KmsRecChart from "./KmsRecChart";
import ScoreGaugeChart from "./ScoreGaugeChart";
import { useTranslation } from "../../lang";

const ChartsPanel = ({ title, selectedData, scoreProm, infractions }) => {
  const [t] = useTranslation();
  return (
    <div>
      {/* <div className="driverBehaviorFilter titleChart">
        <span className="titleDB">{title}</span>
      </div>*/}
      <Grid container>
        <Grid item xs={4} className="chartDBContainer">
          <ScoreChart data={selectedData} />
        </Grid>
        <Grid item xs={4} className="chartDBContainer">
          <KmsRecChart data={selectedData} />
        </Grid>
        <Grid item xs={4} className="chartDBContainer">
          <ConductionTimeChart data={selectedData} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={4} className="chartDBContainer">
          <FuelConsumedChart data={selectedData} />
        </Grid>
        <Grid item xs={4} className="chartDBContainer">
          <ConsumeEficiencyChart data={selectedData} />
        </Grid>
        <Grid item xs={4} className="chartDBContainer">
          <ScoreGaugeChart data={scoreProm} />
          <InfractionsChart data={infractions} />
        </Grid>
      </Grid>
      <Grid container>
        <div className="legendContainer">
          <div className="fleetLegend" />
          <span>{t("Promedio Flota")}</span>
          <div className="selectedLegend" />
          <span>{t("Conductor seleccionado")}</span>
        </div>
      </Grid>
    </div>
  );
};

export default ChartsPanel;
