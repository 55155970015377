import React, { useRef } from "react";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import darkTheme from "@amcharts/amcharts4/themes/dark";
import { useLayoutEffect } from "react";
import { useTranslation } from "../../../lang";
import { GetDateFormat } from "../../../utils/generalUtils";
import { APP_COLORS } from "../../../Theme";

am4core.useTheme(darkTheme);
am4core.useTheme(am4themes_animated);

const VelocityChart = ({ data, language }) => {
  const [t] = useTranslation();
  const chart = useRef(null);

  const chartData = data.map((d) => ({
    vel: d.vel,
    fecha: new Date(d.fecha),
    velMaxConfig: d.velMaxConfig,
  }));

  /*const velMaxConfigSerieData = data.map(d => ({
    velMaxConfig: d.velMaxConfig,
    fecha: new Date(d.fecha)
  }));*/

  const processOver = (hoveredSerie) => {
    hoveredSerie.toFront();

    hoveredSerie.segments.each((segment) => {
      segment.setState("hover");
    });

    hoveredSerie.legendDataItem.marker.setState("default");
    hoveredSerie.legendDataItem.label.setState("default");

    chart.current.series.each((serie) => {
      if (serie != hoveredSerie) {
        serie.segments.each((segment) => {
          segment.setState("dimmed");
        });
        serie.bulletsContainer.setState("dimmed");
        serie.legendDataItem.marker.setState("dimmed");
        serie.legendDataItem.label.setState("dimmed");
      }
    });
  };

  const processOut = () => {
    chart.current.series.each((serie) => {
      serie.segments.each((segment) => {
        segment.setState("default");
      });
      serie.bulletsContainer.setState("default");
      serie.legendDataItem.marker.setState("default");
      serie.legendDataItem.label.setState("default");
    });
  };

  useLayoutEffect(() => {
    if (chart.current) {
      chart.current.series.each((serie) => {
        serie.data = chartData;
      });
    }
  }, [data]);

  useLayoutEffect(() => {
    let x = am4core.create("chartContainer", am4charts.XYChart);

    const title = x.titles.create();
    title.text = t("Reporte de Velocidades");
    title.fontSize = 25;
    title.marginBottom = 30;
    title.align = "left";
    title.fill = am4core.Color(APP_COLORS.APP_BASE_TEXT_COLOR);

    //Create axis
    let dateAxis = x.xAxes.push(new am4charts.DateAxis());
    let valueAxis = x.yAxes.push(new am4charts.ValueAxis());

    x.dateFormatter = new am4core.DateFormatter();
    x.dateFormatter.dateFormat = GetDateFormat(language, true);

    //Velocity serie
    let velSerie = x.series.push(new am4charts.LineSeries());
    velSerie.dataFields.valueY = "vel";
    velSerie.dataFields.dateX = "fecha";
    velSerie.name = t("Velocidad");
    velSerie.tooltipText = `${t("Fecha")}: {dateX}
      ${t("Velocidad")}: {valueY} [${t("Km/h")}]
    `;
    velSerie.stroke.width = 2;

    const bulletVel = velSerie.bullets.push(new am4charts.CircleBullet());
    bulletVel.circle.strokeWidth = 2;

    let segmentVel = velSerie.segments.template;
    segmentVel.interactionsEnabled = true;

    let hoverStateVel = segmentVel.states.create("hover");
    hoverStateVel.properties.strokeWidth = 3;

    let dimmedVel = segmentVel.states.create("dimmed");
    dimmedVel.properties.stroke = am4core.color("#dadada");

    segmentVel.events.on("over", (evt) => {
      processOver(evt.target.parent.parent.parent);
    });

    segmentVel.events.on("out", (evt) => {
      processOut();
    });

    velSerie.data = chartData;

    //Max movil velocity serie
    let maxVelSerie = x.series.push(new am4charts.LineSeries());
    maxVelSerie.dataFields.valueY = "velMaxConfig";
    maxVelSerie.dataFields.dateX = "fecha";
    maxVelSerie.name = t("Velocidad Máxima Movil");
    maxVelSerie.stroke.width = 2;
    // maxVelSerie.stroke.fill = am4core.Color(APP_COLORS.APP_BASE_TEXT_COLOR);
    maxVelSerie.tooltip.disabled = true;

    let segmentVelMaxConfig = maxVelSerie.segments.template;
    segmentVelMaxConfig.interactionsEnabled = true;

    let hoverStateVelMaxConfig = segmentVelMaxConfig.states.create("hover");
    hoverStateVelMaxConfig.properties.strokeWidth = 3;

    let dimmedVelMaxConfig = segmentVelMaxConfig.states.create("dimmed");
    dimmedVelMaxConfig.properties.stroke = am4core.color("#dadada");

    segmentVelMaxConfig.events.on("over", (evt) => {
      processOver(evt.target.parent.parent.parent);
    });

    segmentVelMaxConfig.events.on("out", (evt) => {
      processOut();
    });

    maxVelSerie.data = chartData;

    x.legend = new am4charts.Legend();
    x.legend.position = "right";

    x.legend.markers.template.states.create("dimmed").properties.opacity = 0.3;
    x.legend.labels.template.states.create("dimmed").properties.opacity = 0.3;

    x.legend.itemContainers.template.events.on("over", (event) => {
      processOver(event.target.dataItem.dataContext);
    });

    x.legend.itemContainers.template.events.on("out", (event) => {
      processOut();
    });

    x.cursor = new am4charts.XYCursor();
    x.cursor.maxTooltipDistance = 0;

    x.scrollbarX = new am4core.Scrollbar();
    x.scrollbarX.parent = x.bottomAxesContainer;
    x.exporting.menu = new am4core.ExportMenu();

    x.exporting.backgroundColor = "rgba(0, 0, 0, 0.8)";

    chart.current = x;

    return () => {
      x.dispose();
    };
  }, []);

  return <div id="chartContainer" style={{ width: "100%", height: "500px" }} />;
};

export default VelocityChart;
