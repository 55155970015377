import gql from "graphql-tag";

const GET_ROAD_BY_ID_QUERY = gql`
  query GetRoad($id: Int) {
    getRoad(id: $id) {
      id
      name
      description
      hasDetourAlarm
      hasVelocityAlarm
      detourMeters
      maxVelocity
      customerId
      customer {
        id
        name
      }
      assets {
        id
        name
        licensePlate
      }
      roadGeofence {
        id
        idToRoad
        geofence {
          id
          name
        }
      }
      overviewPolyline
      bufferedDecodedOverviewPolyline
    }
  }
`;

export default GET_ROAD_BY_ID_QUERY;
