import React from "react";

import { Dialog, DialogTitle, DialogContent, Button } from "@material-ui/core";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import "./ModalConfirmacion.scss";

import { useTranslation } from "../../lang";

const theme = createMuiTheme({});

const ModalConfirmacion = ({
  isOpen,
  closeModal,
  handleConfirm,
  confirmationText,
  title,
  buttonConfirmation,
}) => {
  const [t] = useTranslation();
  return (
    <ThemeProvider theme={theme}>
      <Dialog open={isOpen} onClose={closeModal} maxWidth="sm" fullWidth>
        <DialogTitle className="dialogConfirmationTitle">{title}</DialogTitle>
        <DialogContent className="dialogConfirmationContent">
          <span className="dialogConfirmationText">{confirmationText}</span>
          <div className="dialogConfirmationButtonContainer">
            <Button
              color="primary"
              variant="contained"
              style={{ marginRight: "15px" }}
              onClick={() => {
                closeModal();
              }}
            >
              {t("Cancelar")}
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                handleConfirm();
                closeModal();
              }}
            >
              {buttonConfirmation}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
};

export default ModalConfirmacion;
