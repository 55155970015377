import React, { useState } from "react";

import { useQuery, useLazyQuery } from "@apollo/client";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  Button,
  FormGroup,
} from "@material-ui/core";

import { ExpandMore, ChevronRight } from "@material-ui/icons";

import { TreeView, TreeItem } from "@material-ui/lab";

import Select from "react-select";
import CustomSelectStyle from "../../../utils/reactSelectStyle";

import CUSTOMERS_QUERY from "../../../graphql/queries/getCustomersQuery";
import GROUPS_OWN_BYCUSTOMER_QUERY from "../../../graphql/queries/groupsOwnByCustomerQuery";

import { useTranslation } from "../../../lang";

const ModalAssociateFleet = ({ isOpen, closeModal, onSelect }) => {
  const [t] = useTranslation();
  const [customers, setCustomers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [groupSelected, setGroupSelected] = useState();

  const {} = useQuery(CUSTOMERS_QUERY, {
    onCompleted(d) {
      setCustomers(
        d.customers.items.map((c) => ({
          label: c.name,
          value: c.id,
        }))
      );
    },
  });

  const [fetchGroups] = useLazyQuery(GROUPS_OWN_BYCUSTOMER_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      setGroups(d.groupsOwnByCustomer);
    },
  });

  const handleSaveChanges = () => {
    const thisGroup = groups.find((x) => x.id === groupSelected);
    const extCustomers = thisGroup.externalCustomers
      ? thisGroup.externalCustomers.map((c) => c.id)
      : [];
    // console.log("Ext customers: ", extCustomers);
    onSelect(groupSelected, extCustomers);
    closeModal();
  };

  const handleSelectedGroup = (g) => {
    setGroupSelected(g.id);
  };

  const renderNodes = (node) => {
    const childs = groups.filter((x) => x.parentGroupId === node.id);
    return childs.map((ch) => (
      <TreeItem
        nodeId={ch.id}
        label={ch.name}
        onClick={() => handleSelectedGroup(ch)}
      >
        {renderNodes(ch)}
      </TreeItem>
    ));
  };

  const renderRoots = () => {
    const roots = groups.filter((x) => !x.parentGroupId);
    const treeRoots = roots.map((r) => (
      <TreeItem
        nodeId={r.id}
        label={r.name}
        onClick={() => handleSelectedGroup(r)}
      >
        {renderNodes(r)}
      </TreeItem>
    ));
    return treeRoots;
  };

  return (
    <Dialog open={isOpen} onClose={closeModal} maxWidth="sm" fullWidth>
      <DialogTitle className="dialogTitle">
        {t("Asociar otras flotas")}
      </DialogTitle>
      <DialogContent>
        <FormGroup>
          <label>{t("Grupos")}</label>
          <Select
            options={customers}
            onChange={(opt) => {
              fetchGroups({
                variables: {
                  customerId: opt.value,
                },
              });
            }}
            styles={CustomSelectStyle}
          />
          <Paper elevation={3} className="groupPaper">
            <TreeView
              defaultCollapseIcon={<ExpandMore />}
              defaultExpandIcon={<ChevronRight />}
            >
              {groups && renderRoots()}
            </TreeView>
          </Paper>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => closeModal()}
        >
          {t("Cancelar")}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            if (!groupSelected) {
              alert("Seleccione un grupo");
            } else {
              handleSaveChanges();
            }
          }}
        >
          {t("Guardar cambios")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalAssociateFleet;
