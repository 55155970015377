import React from "react";
import { Field, reduxForm } from "redux-form";

import IconButton from "@material-ui/core/IconButton";

import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";

import {
  ReduxStyledSelectField,
  ReduxTextField,
  ReduxDateTimeField,
} from "../../Base/ReduxFormFields";

import PanelForm, { InlineFieldsGroup } from "../../Base/PanelForm";

import {
  REVISIONS_TYPE,
  REVISIONS_FREQUENCY,
  ALERTS_PERCENT,
} from "../../../constants/revisions";

import "./RevisionsExtra.scss";

import { useTranslation } from "../../../lang";

const RevisionsExtra = () => {
  const [t] = useTranslation();
  const handleOnSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <PanelForm onSubmit={handleOnSubmit}>
      <Field
        component={ReduxStyledSelectField}
        name="revisionType"
        id="new-revision-type"
        textColor="white"
        shrinkLabel
        displayEmpty
        label="Tipo de revisión que desea agregar"
        lateralPanelDepth={2}
        options={REVISIONS_TYPE}
      />

      <Field
        component={ReduxDateTimeField}
        id="new-revision-date"
        name="date"
        label="Fecha"
        placeholder={t("Seleccionar")}
        variant="inline"
      />

      <InlineFieldsGroup>
        <>
          <Field
            component={ReduxStyledSelectField}
            name="revisionFrequency"
            id="new-revision-frequency"
            textColor="white"
            shrinkLabel
            displayEmpty
            label="Frecuencia"
            lateralPanelDepth={2}
            options={REVISIONS_FREQUENCY}
          />

          <Field
            component={ReduxTextField}
            id="new-revision-frecuency-amount"
            name="new-revision-frecuency-amount"
            label="Cantidad"
            placeholder=""
            className="marker-info__revisions-extra__small-input"
          />
        </>
      </InlineFieldsGroup>

      <Field
        component={ReduxStyledSelectField}
        name="notify"
        id="new-revision-notify"
        textColor="white"
        shrinkLabel
        displayEmpty
        label="Porcentaje de alerta"
        lateralPanelDepth={2}
        options={ALERTS_PERCENT}
      />
      <InlineFieldsGroup>
        <Field
          component={ReduxTextField}
          id="new-revision-email"
          name="email"
          label="Enviar email de aviso"
          placeholder={t("Correo")}
        />

        <div>
          <IconButton
            onClick={() => {}}
            className="marker-info__revisions-extra__action"
          >
            <AddBoxOutlinedIcon />
          </IconButton>
        </div>
      </InlineFieldsGroup>
      <InlineFieldsGroup>
        <Field
          component={ReduxTextField}
          id="new-revision-unregistered-email"
          name="unregisteredEmail"
          label="Agregar un email no registrado para aviso"
          placeholder={t("Correo")}
        />
        <div>
          <IconButton
            onClick={() => {}}
            className="marker-info__revisions-extra__action"
          >
            <AddBoxOutlinedIcon />
          </IconButton>
        </div>
      </InlineFieldsGroup>
    </PanelForm>
  );
};

export default reduxForm({
  form: "newRevision",
})(RevisionsExtra);
