import React, { useState } from "react";
import classNames from "classnames";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

import { useQuery } from "@apollo/client";

import InfoContent from "../../components/Map/MarkerInfo/InfoContent";
import InfoContentExtra from "../../components/Map/MarkerInfo/InfoContentExtra";
import ChartsPanel from "../DrivingBehaviorReport/ChartsPanel";
import FormRevisionNotice from "../RevisionsPanel/FormRevisionNotice";
import FormRevision from "../RevisionsPanel/FormRevision";

import assetExtraInfoQuery from "../../graphql/queries/assetExtraInfoQuery";

const PanelAux = ({
  closePanel,
  markerInfo,
  // lateralAuxInfo,
  typeContent,
  markerJourney,
  openPanelAux,
  onChangeTab,
  toggleFillMarkerInfoAux,
  toggleFillMarkerJourneyPersonalizado,
  toggleFillMarkerJourney,
  toggleFillMarkerJourney2,
  toggleFillGenericPopup,
  panelType,
  tabActive,
  title,
}) => {
  // console.log("Panel type secondary: ", panelType);
  // console.log("Tab active secondary: ", tabActive);

  const [assetInfo, setAssetInfo] = useState();

  useQuery(assetExtraInfoQuery, {
    variables: { assetId: markerInfo.markerInfo.mid },
    onCompleted(d) {
      setAssetInfo(d.asset);
    },
  });

  const expandablePanelClassnames = classNames("expandable-panel", {
    "expandable-panel--open": true,
    "expandable-panel--openNotification":
      panelType === "EstadoFlota" && tabActive === 2,
    "expandable-panel--left--level-3":
      typeContent === "DrivingBehaviorChartPanel",
    [`expandable-panel--left--level-2`]:
      typeContent !== "DrivingBehaviorChartPanel",
    "expandable-panelAux-Revision":
      panelType === "Mantenimiento" ||
      (panelType === "GeneralInfo" && tabActive === 3),
  });

  const _closeButton = (
    <div onClick={closePanel}>
      <IconButton className="expandable-panel__close-btn">
        <CloseIcon />
      </IconButton>
    </div>
  );

  const renderContent = () => {
    // console.log("Type content: ", typeContent);
    let content = <div />;
    switch (typeContent) {
      case "DrivingBehaviorChartPanel":
        content = <ChartsPanel />;
        break;
      case "RevisionNotice":
        content = <FormRevisionNotice />;
        break;
      case "Revision":
        content = <FormRevision />;
        break;
      case "GeneralInfo":
        content = (
          <InfoContent
            markerInfo={markerInfo.markerInfo}
            markerJourney={markerJourney.markerJourney}
            toggleActivePanelAux={openPanelAux}
            onChangeTab={onChangeTab}
            toggleFillMarkerInfoAux={toggleFillMarkerInfoAux}
            toggleFillMarkerJourney2={toggleFillMarkerJourney2}
            toggleFillMarkerJourney={toggleFillMarkerJourney}
            toggleFillMarkerJourneyPersonalizado={
              toggleFillMarkerJourneyPersonalizado
            }
            toggleFillGenericPopup={toggleFillGenericPopup}
          />
        );
        break;
      default:
        content = (
          <InfoContentExtra
            markerInfo={markerInfo.markerInfo}
            toggleActivePanelAux={closePanel}
            panelAuxComponent="GeneralInfoExtra"
            assetInfo={assetInfo}
          />
        );
        break;
    }

    return content;
  };

  return (
    <section className={expandablePanelClassnames}>
      <div
        className={
          "expandable-panel__content" &&
          panelType === "EstadoFlota" &&
          tabActive === 2
            ? "expandable-panel--openNotification"
            : ""
        }
      >
        <div className="expandable-panel__title">
          <Typography
            component="div"
            className="expandable-panel__title-text title-format"
          >
            {title}
          </Typography>
          {_closeButton}
        </div>

        {/*children*/}
        {renderContent()}
      </div>
    </section>
  );
};

export default PanelAux;
