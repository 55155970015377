// import GET_GENERAL_INFO_EXTRA_QUERY from "../../graphql/queries/generalInfoExtraQuery";
import Client from "../../components/Base/apiApolloClient";

export const UPDATE_ACTIVE_PANEL = "UPDATE_ACTIVE_PANEL";
export const UPDATE_ACTIVE_PANEL_COMPONENT = "UPDATE_ACTIVE_PANEL_COMPONENT";
export const UPDATE_ACTIVE_PANEL_AUX = "UPDATE_ACTIVE_PANEL_AUX";
export const UPDATE_ACTIVE_PANEL_AUX_COMPONENT =
  "UPDATE_ACTIVE_PANEL_AUX_COMPONENT";
export const TAB_CHANGED = "TAB_CHANGED";
export const UPDATE_PANEL_TYPE = "UPDATE_PANEL_TYPE";
export const CHANGE_PANELAUXTYPE = "CHANGE_PANELAUXTYPE";
export const CHANGE_PANELAUXTITLE = "CHANGE_PANELAUXTITLE";

const _updateActivePanel = (content) => ({
  type: UPDATE_ACTIVE_PANEL,
  payload: content,
});

const _updateActivePanelAux = (content) => ({
  type: UPDATE_ACTIVE_PANEL_AUX,
  payload: content,
});

const _updateActivePanelAuxContent = (content) => ({
  type: UPDATE_ACTIVE_PANEL_AUX_COMPONENT,
  payload: content,
});

const _updateActivePanelType = (activePanel) => ({
  type: UPDATE_PANEL_TYPE,
  payload: activePanel,
});

export const toggleActivePanel = ({ isOpen, activePanel } = {}) => {
  return (dispatch, getState) => {
    const {
      lateralPanel: { isPanelOpen },
    } = getState();

    let newState = !isPanelOpen;

    if (isOpen !== undefined) {
      newState = isOpen;
    }

    if (!newState) {
      dispatch(_updateActivePanelAux(false));
    }

    dispatch(_updateActivePanel(newState));
    dispatch(_updateActivePanelType(activePanel));
  };
};

export const toogleNotification = () => (dispatch) => {
  dispatch(_updateActivePanel(true));
  dispatch(_updateActivePanelType("Notification"));
};

export const toggleActivePanelAux = ({
  panelAuxTitle,
  panelAuxComponent,
  isOpen,
} = {}) => (dispatch, getState) => {
  const {
    lateralPanel: { isPanelAuxOpen },
  } = getState();

  let newState = !isPanelAuxOpen;

  if (isOpen !== undefined) {
    newState = isOpen;
  }
  //Activa la pestaña auxiliar
  dispatch(_updateActivePanelAux(newState));

  if (typeof panelAuxComponent === "string") {
    //Activa el content dentro de la pestaña auxiliar
    dispatch(
      _updateActivePanelAuxContent({ panelAuxTitle, panelAuxComponent })
    );
  }
};

export const onChangeTab = (tab) => ({
  type: TAB_CHANGED,
  payload: tab,
});

export const OPEN_PANEL = "OPEN_PANEL";
export const CLOSE_PANEL = "CLOSE_PANEL";
export const OPEN_PANELAUX = "OPEN_PANELAUX";
export const CLOSE_PANELAUX = "CLOSE_PANELAUX";
export const CHANGE_PANELTYPE = "CHANGE_PANELTYPE";
export const SEE_ALL_FLEET = "SEE_ALL_FLEET";
export const SET_MID_SELECTED = "SET_MID_SELECTED";
export const SET_ASSET_LIST = "SET_ASSET_LIST";
export const SET_REVISIONNOTICE_SELECTED = "SET_REVISIONNOTICE_SELECTED";
export const SET_REVISION_SELECTED = "SET_REVISION_SELECTED";
export const SET_REVISIONNOTICE_LIST = "SET_REVISIONNOTICE_LIST";
export const SET_REVISION_LIST = "SET_REVISION_LIST";
export const SET_JOURNEY_REPORT_PANEL_INITIAL_STATUS =
  "SET_JOURNEY_REPORT_PANEL_INITIAL_STATUS";
export const SET_FLEET_STATUS_PANEL_INITIAL_STATUS =
  "SET_FLEET_STATUS_PANEL_INITIAL_STATUS";

export const openPanel = () => ({
  type: OPEN_PANEL,
});

export const closePanel = () => ({
  type: CLOSE_PANEL,
});

export const openPanelAux = () => ({
  type: OPEN_PANELAUX,
});

export const closePanelAux = () => ({
  type: CLOSE_PANELAUX,
});

export const changePanelType = (panelType) => ({
  type: CHANGE_PANELTYPE,
  payload: panelType,
});

export const changePanelAuxType = (panelType) => ({
  type: CHANGE_PANELAUXTYPE,
  payload: panelType,
});

export const changePanelAuxTitle = (title) => ({
  type: CHANGE_PANELAUXTITLE,
  payload: title,
});

export const seeAllFleet = (payload) => ({
  type: SEE_ALL_FLEET,
  payload,
});

export const setMidSelected = (mid) => ({
  type: SET_MID_SELECTED,
  payload: mid,
});

export const setAssetList = (list) => ({
  type: SET_ASSET_LIST,
  payload: list,
});

export const setRevisionNoticeSelected = (revisionNotice) => ({
  type: SET_REVISIONNOTICE_SELECTED,
  payload: revisionNotice,
});

export const setRevisionSelected = (revision) => ({
  type: SET_REVISION_SELECTED,
  payload: revision,
});

export const setRevisionNoticeList = (list) => ({
  type: SET_REVISIONNOTICE_LIST,
  payload: list,
});

export const setRevisionList = (list) => ({
  type: SET_REVISION_LIST,
  payload: list,
});

export const setJourneyReportPanelInitialStatus = (status) => {
  return {
    type: SET_JOURNEY_REPORT_PANEL_INITIAL_STATUS,
    payload: status,
  };
};

export const setFleetStatusPanelInitialStatus = (status) => {
  return {
    type: SET_FLEET_STATUS_PANEL_INITIAL_STATUS,
    payload: status,
  };
};
