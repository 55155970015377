import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";

import CloseIcon from "@material-ui/icons/Close";

import {
  SUCCESS_NOTIFICATION,
  ALERT_NOTIFICATION,
  DANGER_NOTIFICATION,
  INFO_NOTIFICATION,
} from "../../redux/notifications/actions";

import "./Notification.scss";

const Notification = ({
  notificationType,
  notificationMessage,
  clearNotification,
}) => {
  let component = null;

  const notificationClassName = classNames({
    "notification-message--success": notificationType === SUCCESS_NOTIFICATION,
    "notification-message--alert": notificationType === ALERT_NOTIFICATION,
    "notification-message--danger": notificationType === DANGER_NOTIFICATION,
    "notification-message--info": notificationType === INFO_NOTIFICATION,
  });

  // eslint-disable-next-line no-unused-vars
  const handleClose = (event, reason) => {
    clearNotification();
  };

  if (notificationMessage) {
    component = (
      <div className="notification">
        <Snackbar
          className={notificationClassName}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open
          autoHideDuration={6000}
          onClose={handleClose}
          message={<>{notificationMessage}</>}
          action={
            <>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          }
        />
      </div>
    );
  }

  return component;
};

Notification.propTypes = {
  notificationType: PropTypes.string,
  notificationMessage: PropTypes.string,
  clearNotification: PropTypes.func,
};

Notification.defaultProps = {
  notificationType: INFO_NOTIFICATION,
  notificationMessage: "",
  clearNotification: () => {},
};

export default Notification;
