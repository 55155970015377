import React, { Component } from "react";
import PropTypes from "prop-types";

import Structure from "../../components/Base/Structure";

import "./Base.scss";

export default class MapBasePage extends Component {
  static propTypes = {
    match: PropTypes.object,
    lateralBar: PropTypes.any,
  };

  static defaultProps = {
    match: {},
    lateralBar: null,
  };

  render() {
    /**
     * `match` is a param coming from the routerProps.
     * It has the information of the url and the params in it.
     */
    const { match, lateralBar } = this.props;

    return (
      <Structure lateralBar={lateralBar} matchProps={match}>
        <></>
      </Structure>
    );
  }
}
