import React, { useEffect, useState } from "react";

import { useMutation, useQuery, useLazyQuery } from "@apollo/client";

import Structure from "../../../components/Base/Structure";

import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import ROLES_QUERY from "../../../graphql/queries/getRolesQuery";
import CREATE_ROLE_MUTATION from "../../../graphql/mutations/createRoleMutation";
import UPDATE_ROLE_MUTATION from "../../../graphql/mutations/updateRoleMutation";
import DELETE_ROLE_MUTATION from "../../../graphql/mutations/deleteRoleMutation";
import ASSIGN_PERMISSION_TO_ROLE_MUTATION from "../../../graphql/mutations/assignPermissionToRoleMutation";

import PageTitle from "../../../components/Base/PageTitle";

import TableRole from "./List";
import "./Role.scss";

import { useTranslation } from "../../../lang";

const ManageRolePage = ({}) => {
  const [t] = useTranslation();
  const paginationSizes = [10, 25, 50];
  const [total, setTotal] = useState(0);
  const [roleList, setRoleList] = useState([]);
  const [paginationState, setPaginationState] = useState({
    pageSize: paginationSizes[0],
    pageNumber: 1,
    sort: undefined,
    filter: null,
  });

  const [resultState, setResultState] = useState({
    isOpen: false,
    type: "success",
    msg: "",
  });

  const [assignPermission] = useMutation(ASSIGN_PERMISSION_TO_ROLE_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Permisos asignados correctamente"),
      });
      handleGetRoles();
    },
    onError(err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: err.message,
      });
    },
  });

  const handleAssignPermission = (roleId, permissions) => {
    assignPermission({
      variables: {
        roleId,
        permissions,
      },
    });
  };

  const [getRoles] = useLazyQuery(ROLES_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      if (d.roles) {
        setRoleList(d.roles.map((r) => ({ ...r, category: t(r.category) })));
        setTotal(d.roles.length);
      }
    },
  });

  const handleGetRoles = () => {
    getRoles({
      variables: {
        ...paginationState,
        paginate: true,
      },
    });
  };

  useEffect(() => {
    handleGetRoles();
  }, [paginationState]);

  const [createRole] = useMutation(CREATE_ROLE_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Rol creado correctamente"),
      });
      handleGetRoles();
    },
    onError(err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: err.message,
      });
    },
  });

  const [updateRole] = useMutation(UPDATE_ROLE_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Rol actualizado correctamente"),
      });
      handleGetRoles();
    },
    onError(err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: err.message,
      });
    },
  });

  const [deleteRole] = useMutation(DELETE_ROLE_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Rol eliminado correctamente"),
      });
      handleGetRoles();
    },
    onError(d) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: t("Ocurrió un error"),
      });
    },
  });

  const handleDeleteRole = (id) => {
    deleteRole({
      variables: { id },
    });
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setResultState({
      ...resultState,
      isOpen: false,
    });
  };

  return (
    <Structure>
      <Snackbar
        open={resultState.isOpen}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleCloseNotification}
          severity={resultState.type}
          variant="filled"
        >
          {resultState.msg}
        </MuiAlert>
      </Snackbar>
      <PageTitle sector={t("Administración")} title={t("Roles")} />
      <TableRole
        getRoles={handleGetRoles}
        roleList={roleList}
        paginationSizes={paginationSizes}
        paginationState={paginationState}
        setPaginationState={setPaginationState}
        total={total}
        setResultState={setResultState}
        createRole={createRole}
        updateRole={updateRole}
        deleteRole={handleDeleteRole}
        handleAssignPermission={handleAssignPermission}
      />
    </Structure>
  );
};

export default ManageRolePage;
