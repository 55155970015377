import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const ArchivoIcon = function () {
  return (
    <SvgIcon>
      <g>
        <path
          d="M18.058,4.684l-4.1-4.1A2.343,2.343,0,0,0,12.307-.1H2.343A2.351,2.351,0,0,0,0,2.248V22.557A2.344,2.344,0,0,0,2.343,24.9H16.4a2.344,2.344,0,0,0,2.343-2.343V6.344a2.355,2.355,0,0,0-.688-1.66Zm-1.1,1.108a.768.768,0,0,1,.205.361H12.5V1.491a.768.768,0,0,1,.361.205ZM16.4,23.338H2.343a.783.783,0,0,1-.781-.781V2.248a.783.783,0,0,1,.781-.781h8.592V6.544a1.169,1.169,0,0,0,1.172,1.172h5.077V22.557A.783.783,0,0,1,16.4,23.338ZM14.06,11.426v.391a.588.588,0,0,1-.586.586h-8.2a.588.588,0,0,1-.586-.586v-.391a.588.588,0,0,1,.586-.586h8.2A.588.588,0,0,1,14.06,11.426Zm0,3.124v.391a.588.588,0,0,1-.586.586h-8.2a.588.588,0,0,1-.586-.586V14.55a.588.588,0,0,1,.586-.586h8.2A.588.588,0,0,1,14.06,14.55Zm0,3.124v.391a.588.588,0,0,1-.586.586h-8.2a.588.588,0,0,1-.586-.586v-.391a.588.588,0,0,1,.586-.586h8.2A.588.588,0,0,1,14.06,17.675Z"
          transform="translate(0 0.1)"
        />
      </g>
    </SvgIcon>
  );
};

export default ArchivoIcon;
