import gql from "graphql-tag";

const GET_GEOFENCES_ASSET_QUERY = gql`
  query getGeofencesByAsset($assetId: Int!) {
    getGeofencesByAsset(assetId: $assetId) {
      id
      name
    }
  }
`;

export default GET_GEOFENCES_ASSET_QUERY;
