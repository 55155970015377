import React, { useState, useEffect } from "react";
import {
  Button,
  FormGroup,
  Grid,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import * as Yup from "yup";
import { Field, Formik } from "formik";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { useQuery } from "@apollo/client";
import GenericSelector from "../../../components/Selectors/GenericSelector";
import CUSTOMERS_QUERY from "../../../graphql/queries/getCustomersQuery";
import ImageUpload from "../../../components/Base/ImageUpload";
import { useTranslation } from "../../../lang";

const theme = createMuiTheme({});

const DriverForm = ({
  setIsOpen,
  createDriver,
  selectedDriver,
  updateDriver,
  photo,
}) => {
  const [t] = useTranslation();
  const [customers, setCustomers] = useState([]);
  const [driverPhoto, setDriverPhoto] = useState();

  const initialValues = {
    id: selectedDriver?.id,
    firstName: selectedDriver?.firstName,
    lastName: selectedDriver?.lastName,
    ibutton: selectedDriver?.ibutton,
    phone: selectedDriver?.phone,
    companyPhone: selectedDriver?.companyPhone,
    cellPhone: selectedDriver?.cellPhone,
    companyCellPhone: selectedDriver?.companyCellPhone,
    suspended: selectedDriver?.suspended,
    comments: selectedDriver?.comments,
    customerId: selectedDriver?.customerId,
    photo: driverPhoto,
  };

  useEffect(() => {
    setDriverPhoto(photo);
  }, [photo]);

  const yupSchema = Yup.object().shape({
    firstName: Yup.string().required(t("Requerido")),
    lastName: Yup.string().required(t("Requerido")),
    customerId: !selectedDriver
      ? Yup.number().required(t("Requerido"))
      : undefined,
  });

  const {} = useQuery(CUSTOMERS_QUERY, {
    onCompleted(d) {
      setCustomers(d.customers.items);
      if (selectedDriver?.customerId) {
        const defaultC = d.customers.items.find(
          (c) => c.id === selectedDriver.customerId
        );
      }
    },
  });

  const itemStyle = {
    fontSize: "12px",
    color: "white",
  };

  const columnsCustomers = [
    {
      dataField: "name",
      text: t("Nombre"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
    },
    {
      dataField: "taxId",
      text: t("CUIT"),
      headerStyle: () => ({ width: "15%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
    },
    {
      dataField: "phone",
      text: t("Telefonos"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
    },
    {
      dataField: "celPhone",
      text: t("Celular"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
    },
    {
      dataField: "address",
      text: t("Domicilio"),
      headerStyle: () => ({ width: "25%", fontSize: "12px" }),
      style: () => itemStyle,
      sort: true,
    },
  ];

  return (
    <>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={yupSchema}
        onSubmit={async (values) => {
          if (selectedDriver) {
            updateDriver({
              variables: { ...values, photo: driverPhoto },
            });
          } else {
            createDriver({
              variables: { ...values },
            });
          }
        }}
      >
        {(props) => {
          const { values, errors, handleSubmit, setFieldValue } = props;

          return (
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <label className="formLabelFormik">{t("Nombre")}</label>
                <Field
                  type="text"
                  name="firstName"
                  tabIndex="0"
                  className={
                    errors.firstName
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.firstName && (
                  <div className="input-feedback">{errors.firstName}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">{t("Apellido")}</label>
                <Field
                  type="text"
                  name="lastName"
                  tabIndex="0"
                  className={
                    errors.lastName
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.lastName && (
                  <div className="input-feedback">{errors.lastName}</div>
                )}
              </FormGroup>
              <FormGroup>
                <ImageUpload
                  onChange={(newPhoto) => {
                    setDriverPhoto(newPhoto);
                    setFieldValue("photo", newPhoto);
                  }}
                  title={t("Foto")}
                  file={driverPhoto}
                />
              </FormGroup>
              <FormGroup>
                {/* <label className="formLabelFormik">Cliente</label>
                <Field
                  name="customerId"
                  component={CustomFormikSelect}
                  options={customers}
                />
                {errors.customers && (
                  <div className="input-feedback">{errors.customers}</div>
                )} */}

                {customers && customers.length > 0 && (
                  <GenericSelector
                    callbackOptionSelected={(customer) =>
                      setFieldValue("customerId", customer.value)
                    }
                    columns={columnsCustomers}
                    keyField="id"
                    labelField="name"
                    title={t("Cliente")}
                    data={customers}
                    defaultValue={selectedDriver?.customerId}
                  />
                )}
                {errors.customerId && (
                  <div className="input-feedback">{errors.customerId}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">{t("IButton")}</label>
                <Field
                  type="text"
                  name="ibutton"
                  tabIndex="0"
                  className={
                    errors.ibutton
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.ibutton && (
                  <div className="input-feedback">{errors.ibutton}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">{t("Teléfono")}</label>
                <Field
                  type="text"
                  name="phone"
                  tabIndex="0"
                  className={
                    errors.phone
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.phone && (
                  <div className="input-feedback">{errors.phone}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">
                  {t("Compañia Teléfono")}
                </label>
                <Field
                  type="text"
                  name="companyPhone"
                  tabIndex="0"
                  className={
                    errors.name
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.companyPhone && (
                  <div className="input-feedback">{errors.companyPhone}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">{t("Celular")}</label>
                <Field
                  type="text"
                  name="cellPhone"
                  tabIndex="0"
                  className={
                    errors.cellPhone
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.cellPhone && (
                  <div className="input-feedback">{errors.cellPhone}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">
                  {t("Compañia Celular")}
                </label>
                <Field
                  type="text"
                  name="companyCellPhone"
                  tabIndex="0"
                  className={
                    errors.companyCellPhone
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.name && (
                  <div className="input-feedback">
                    {errors.companyCellPhone}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <ThemeProvider theme={theme}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={values.suspended}
                        onChange={(evt) =>
                          setFieldValue("suspended", evt.target.checked)
                        }
                        name="suspended"
                      />
                    }
                    label={t("Suspendido")}
                  />
                </ThemeProvider>
                {errors.suspended && (
                  <div className="input-feedback">{errors.suspended}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">{t("Observaciones")}</label>
                <Field
                  as="textarea"
                  name="comments"
                  rows="5"
                  tabIndex="0"
                  className={
                    errors.comments
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.name && (
                  <div className="input-feedback">{errors.comments}</div>
                )}
              </FormGroup>
              <Grid item lg={12} style={{ marginTop: "50px" }}>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  tabIndex="0"
                >
                  {selectedDriver ? t("Actualizar") : t("Crear")}
                </Button>
                <Button
                  variant="contained"
                  type="button"
                  tabIndex="0"
                  style={{ marginLeft: "20px" }}
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  {t("Cancelar")}
                </Button>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default DriverForm;
