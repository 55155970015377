import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { useQuery, useLazyQuery } from "@apollo/client";
import {
  downloadExcel,
  downloadKML,
  downloadPDF,
} from "../../../utils/generalUtils";

import Structure from "../../../components/Base/Structure";

import Filter from "./Filter";
import ReportResult from "./ReportResult";

import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import PageTitle from "../../../components/Base/PageTitle";

import ASSETS_BYGROUPS_QUERY from "../../../graphql/queries/assetByGroupsQuery";
import FUELCONSUMPTION_REPORT_QUERY from "../../../graphql/queries/fuelConsumptionReportQuery";
import FUELCONSUMPTION_REPORT_EXPORT_QUERY from "../../../graphql/queries/fuelConsumptionReportExportQuery";
import { useFilterValidation } from "../useFilterValidation";

import { useTranslation } from "../../../lang";
import { localDateTimeStringToUTC } from "../../../utils/dates";

const FuelConsumption = ({ selectedGroups, selectedCustomers }) => {
  const [t] = useTranslation();
  const [assetList, setAssetList] = useState([]);
  const [exportSelected, setExportSelected] = useState("EXCEL");
  const [assetTableState] = useState({
    searchText: "",
    sort: undefined,
    paginate: false,
  });

  const [reportResult, setReportResult] = useState([]);

  const [resultState, setResultState] = useState({
    isOpen: false,
    type: "success",
    msg: "",
  });

  const {} = useQuery(ASSETS_BYGROUPS_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      groupIDs: selectedGroups.filter(
        (g) => g && !Number.isNaN(Number.parseInt(g))
      ),
      ...assetTableState,
    },
    onCompleted(d) {
      const { count, items } = d.assetsByGroups;
      //setTotal(count);
      setAssetList(items);
    },
  });

  // useEffect(() => {
  //   let customerId;
  //   if (Array.isArray(selectedCustomers) && selectedCustomers.length > 0) {
  //     customerId = selectedCustomers[selectedCustomers.length - 1].value;
  //     fetchGeofences({
  //       variables: {
  //         customerId: customerId,
  //       },
  //     });
  //   }
  // }, [selectedCustomers]);

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setResultState({
      ...resultState,
      isOpen: false,
    });
  };

  const [fetchReport] = useLazyQuery(FUELCONSUMPTION_REPORT_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      if (d.consumptionReport) setReportResult(d.consumptionReport);
      else {
        setReportResult([]);
        setResultState({
          isOpen: true,
          type: "info",
          msg: t("No se encontraron datos para su consulta"),
        });
      }
    },
    onError(err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: err.message,
      });
    },
  });

  const [getExport] = useLazyQuery(FUELCONSUMPTION_REPORT_EXPORT_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      const filename = t("ReporteConsumoCombustible");
      switch (exportSelected) {
        case "EXCEL":
          downloadExcel(d.consumptionReportExport, filename);
          break;
        case "PDF":
          downloadPDF(d.consumptionReportExport, filename);
          break;
        case "KML":
          downloadKML(d.consumptionReportExport, filename);
          break;
      }
    },
  });

  const handleSearchReportFuelConsumption = ({
    assetsSelected,
    dateFrom,
    dateTo,
    dias,
  }) => {
    fetchReport({
      variables: {
        assetsId: assetsSelected,
        dateFrom: localDateTimeStringToUTC(dateFrom),
        dateTo: localDateTimeStringToUTC(dateTo),
        days: dias,
      },
    });
  };

  const { handleSearchReport, errors: filterErrors } = useFilterValidation(
    handleSearchReportFuelConsumption
  );

  const handleExportExcel = (assetsSelected, fechaInicio, fechaFin, dias) => {
    setExportSelected("EXCEL");
    getExport({
      variables: {
        assetsId: assetsSelected,
        dateFrom: localDateTimeStringToUTC(fechaInicio),
        dateTo: localDateTimeStringToUTC(fechaFin),
        days: dias,
        format: "EXCEL",
      },
    });
  };

  const handleExportPDF = (assetsSelected, fechaInicio, fechaFin, dias) => {
    console.debug("PDF - Dates: ", {
      fechaInicio,
      fechaFin,
    });
    setExportSelected("PDF");
    getExport({
      variables: {
        assetsId: assetsSelected,
        dateFrom: localDateTimeStringToUTC(fechaInicio),
        dateTo: localDateTimeStringToUTC(fechaFin),
        days: dias,
        format: "PDF",
      },
    });
  };

  return (
    <Structure>
      <Snackbar
        open={resultState.isOpen}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleCloseNotification}
          severity={resultState.type}
          variant="filled"
        >
          {resultState.msg}
        </MuiAlert>
      </Snackbar>
      <PageTitle
        sector={t("Estadísticas")}
        title={t("Reporte de Eficiencia de Consumo Combustible")}
      />
      <Filter
        assets={assetList}
        searchReport={handleSearchReport}
        handleExportExcel={handleExportExcel}
        handleExportPDF={handleExportPDF}
        errors={filterErrors}
        reportResult={Array.isArray(reportResult) && reportResult.length > 0}
      />
      {Array.isArray(reportResult) && reportResult.length > 0 && (
        <ReportResult reportData={reportResult} />
      )}
    </Structure>
  );
};

const _mapStateToProps = ({ groups }) => {
  return {
    selectedGroups: groups.selectedGroups,
    selectedCustomers: groups.selectedCustomers,
  };
};

export default connect(_mapStateToProps, null)(FuelConsumption);
