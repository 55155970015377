import gql from "graphql-tag";

const UPDATE_ROLE_MUTATION = gql`
  mutation UpdateRole(
    $id: Int!
    $name: String!
    $categoryId: Int! # $isRoot: Boolean
  ) # $isOwner: Boolean
  # $isAdmin: Boolean
  {
    updateRole(
      id: $id
      name: $name
      categoryId: $categoryId # isRoot: $isRoot
    ) # isOwner: $isOwner
    # isAdmin: $isAdmin
    {
      id
    }
  }
`;

export default UPDATE_ROLE_MUTATION;
