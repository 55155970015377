import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { dangerNotification } from "../redux/notifications/actions";
import { hasPermission } from "../utils/generalUtils";

const PrivateRoute = ({
  component: Component,
  //loggedIn,
  user,
  dangerNotificationAction,
  permission,
  ...rest
}) => {
  //const history = useHistory();
  //const loggedUser = localStorage.getItem("loggedUser");

  const { path } = rest;
  let _securedComponent = (
    <Redirect
      to={{
        pathname: "/login",
        // state: { from: routerProps.location }
      }}
    />
  );

  if (user && user.loggedIn) {
    if (path && !hasPermission(permission, user.roles)) {
      _securedComponent = <Redirect to={{ pathname: "/forbidden" }} />;
    } else {
      _securedComponent = <Component {...rest} />;
    }
  } else if (dangerNotificationAction) {
    dangerNotificationAction("Área restringida para usuarios logueados");
  }

  return _securedComponent;
};

const _mapStateToProps = ({ user }) => ({
  //loggedIn: user.loggedIn,
  user,
});

const _mapDispatchToProps = {
  dangerNotificationAction: dangerNotification,
};

export default connect(_mapStateToProps, _mapDispatchToProps)(PrivateRoute);
