import gql from "graphql-tag";

const JOURNEY_REPORTPOSITION_QUERY = gql`
  query JourneyReportPosition(
    $movilID: Int!
    $fechaInicio: Datetime!
    $fechaFin: Datetime!
  ) {
    journeyReportPositions(
      movilID: $movilID
      fechaInicio: $fechaInicio
      fechaFin: $fechaFin
    ) {
      nCh
      fGPS
      evt
      lat
      lon
      dir
      cur
      vel
      course
      odo
    }
  }
`;

export default JOURNEY_REPORTPOSITION_QUERY;
