import gql from "graphql-tag";

const ALL_ROADS_BY_CUSTOMER_IDS_QUERY = gql`
  query AllRoadsByCustomersIDs($customerIDs: [Int]) {
    allRoadsByCustomersIDs(customerIDs: $customerIDs) {
      id
      name
      customerId
    }
  }
`;

export default ALL_ROADS_BY_CUSTOMER_IDS_QUERY;
