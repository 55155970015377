import React from "react";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router";

import "./Forbidden.scss";

import { useTranslation } from "../lang";

const Forbidden = () => {
  const [t] = useTranslation();
  const history = useHistory();

  return (
    <div className="forbContainer">
      <div className="forbVAlign">
        <p className="forbMsg">{t("Acceso Denegado")}</p>
        <p className="forbMsg">
          {t("No tiene permisos para acceder a esta página")}
        </p>
        <Button
          color="primary"
          variant="contained"
          onClick={() => history.push("/")}
        >
          {t("Volver a página principal")}
        </Button>
      </div>
    </div>
  );
};

export default Forbidden;
