import React, { useEffect, useState } from "react";
import { Button, FormGroup, Grid } from "@material-ui/core";

import * as Yup from "yup";
import { Field, Formik } from "formik";
import GenericSelector from "../../../components/Selectors/GenericSelector";

import GET_GEOFENCES_QUERY from "../../../graphql/queries/getGeofencesQuery";
import { useLazyQuery, useQuery } from "@apollo/client";
import GET_GEOFENCES_ASSET_QUERY from "../../../graphql/queries/getGeofencesByAssetQuery";

import { useTranslation } from "../../../lang";

const TrackingDashboardForm = ({
  setIsOpen,
  selectedAsset,
  updateGeofencesAsset,
}) => {
  const [t] = useTranslation();
  const [textToSearch] = useState("");
  const [geofencesList, setGeofencesList] = useState([]);
  const [assetGeofences, setAssetGeofences] = useState();
  const [total, setTotal] = useState(0);

  const initialValues = {
    assetId: selectedAsset?.id,
    operationNumber: selectedAsset?.operationNumber || "",
    daysStopMaximum: selectedAsset?.daysStopMaximum || "",
    averageDailyKm: selectedAsset?.averageDailyKm || "",
    averageDailyHorometer: selectedAsset?.averageDailyHorometer || "",
    internalCustomer: selectedAsset?.internalCustomer,
    // geofencesId: [], // todo: add geofences
  };

  const yupSchema = Yup.object().shape({
    // assetId: Yup.number().required("Requerido"),
    operationNumber: Yup.string(),
    daysStopMaximum: Yup.number().min(
      0,
      t("Este campo bebe ser mayor que cero")
    ),
    averageDailyKm: Yup.number().min(
      0,
      t("Este campo bebe ser mayor que cero")
    ),
    averageDailyHorometer: Yup.number(t("Este campo debe ser numérico")).min(
      0,
      t("Este campo bebe ser mayor que cero")
    ),
    internalCustomer: Yup.string(),
    // geofences
  });

  const itemStyle = {
    fontSize: "12px",
    color: "white",
  };

  useQuery(GET_GEOFENCES_ASSET_QUERY, {
    variables: { assetId: selectedAsset?.id },
    fetchPolicy: "network-only",
    onCompleted(d) {
      setAssetGeofences(d.getGeofencesByAsset.map((g) => g.id));
    },
  });

  const hightlightIfMatch = (text) => {
    if (
      text &&
      textToSearch &&
      text.toLocaleLowerCase().includes(textToSearch.toLocaleLowerCase())
    ) {
      const newText = text.replace(
        new RegExp(textToSearch, "gi"),
        (match) => `<mark>${match}</mark>`
      );
      return <div dangerouslySetInnerHTML={{ __html: newText }} />;
    }

    return text;
  };

  const [getGeofences] = useLazyQuery(GET_GEOFENCES_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      if (d.geofence) {
        setGeofencesList(d.geofence.items);
        setTotal(d.geofence.count);
      }
    },
  });

  useEffect(() => {
    getGeofences();
  }, [getGeofences]);

  const geofencesColumns = [
    {
      dataField: "name",
      text: "Nombre",
      headerStyle: () => ({ width: "50%", fontSize: "12px" }),
      style: () => itemStyle,
      formatter: hightlightIfMatch,
      sort: true,
    },
    {
      dataField: "showOnMap",
      text: "Mostrar en mapa",
      headerStyle: () => ({ width: "50%", fontSize: "12px" }),
      style: () => itemStyle,
      formatter: (_, row) => {
        return row.showOnMap ? "Si" : "No";
      },
      sort: true,
    },
  ];

  return (
    <>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={yupSchema}
        onSubmit={async (values) => {
          updateGeofencesAsset({
            variables: {
              ...values,
              daysStopMaximum: values.daysStopMaximum || 0,
              averageDailyKm: values.averageDailyKm || 0,
              averageDailyHorometer: values.averageDailyHorometer || 0,
              name: selectedAsset.name,
            },
          });
        }}
      >
        {(props) => {
          const { errors, handleSubmit, setFieldValue } = props;

          return (
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <label className="formLabelFormik">
                  {t("Numero de Operación")}
                </label>
                <Field
                  type="text"
                  name="operationNumber"
                  tabIndex="0"
                  className={
                    errors.operationNumber
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.operationNumber && (
                  <div className="input-feedback">{errors.operationNumber}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">
                  {t("Dias de Detención Maximos")}
                </label>
                <Field
                  type="number"
                  name="daysStopMaximum"
                  tabIndex="0"
                  className={
                    errors.daysStopMaximum
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.daysStopMaximum && (
                  <div className="input-feedback">{errors.daysStopMaximum}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">
                  {t("Promedio Teórico de Kilómetros diarios")}
                </label>
                <Field
                  type="number"
                  name="averageDailyKm"
                  tabIndex="0"
                  className={
                    errors.averageDailyKm
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.averageDailyKm && (
                  <div className="input-feedback">{errors.averageDailyKm}</div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">
                  {t("Promedio Horómetro Diario Teórico")}
                </label>
                <Field
                  type="number"
                  name="averageDailyHorometer"
                  tabIndex="0"
                  className={
                    errors.averageDailyHorometer
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.averageDailyHorometer && (
                  <div className="input-feedback">
                    {errors.averageDailyHorometer}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <label className="formLabelFormik">{t("Cliente")}</label>
                <Field
                  type="text"
                  name="internalCustomer"
                  tabIndex="0"
                  className={
                    errors.internalCustomer
                      ? "form-control text-input error"
                      : "form-control text-input"
                  }
                  autoComplete="off"
                />
                {errors.internalCustomer && (
                  <div className="input-feedback">
                    {errors.internalCustomer}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <GenericSelector
                  key="geofences"
                  data={geofencesList}
                  columns={geofencesColumns}
                  title={t("Geocercas")}
                  isMulti
                  keyField="id"
                  isRemote
                  labelField="name"
                  defaultValue={
                    Array.isArray(assetGeofences) && assetGeofences.length > 0
                      ? assetGeofences
                      : null
                  }
                  callbackOptionSelected={(geofence) =>
                    setFieldValue(
                      "geofencesId",
                      geofence.map((g) => g.value)
                    )
                  }
                />
              </FormGroup>

              <Grid item lg={12} style={{ marginTop: "50px" }}>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  tabIndex="0"
                >
                  {t("Actualizar")}
                </Button>
                <Button
                  variant="contained"
                  type="button"
                  tabIndex="0"
                  style={{ marginLeft: "5px" }}
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  {t("Cancelar")}
                </Button>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default TrackingDashboardForm;
