import gql from "graphql-tag";

const ECOTRACKING_EXPORT_QUERY = gql`
  query TrackingBoardReportExport(
    $assetsSelected: [Int]!
    $fechaInicio: Datetime
    $fechaFin: Datetime
    $days: [WeekDaysEnum]
    $format: FormatEnum
  ) {
    ecoTrackingReportExport(
      assetsSelected: $assetsSelected
      fechaInicio: $fechaInicio
      fechaFin: $fechaFin
      days: $days
      format: $format
    )
  }
`;

export default ECOTRACKING_EXPORT_QUERY;
