import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { Typography, Snackbar } from "@material-ui/core";

import { useMutation, useLazyQuery, useQuery } from "@apollo/client";

import MuiAlert from "@material-ui/lab/Alert";

import Structure from "../../../components/Base/Structure";
import List from "./List";
import PageTitle from "../../../components/Base/PageTitle";

import ROAD_LIST_SUMMARY_QUERY from "../../../graphql/queries/getRoadListSummaryQuery";
import ASSETS_BYGROUPS_QUERY from "../../../graphql/queries/assetByGroupsQuery";
import GET_GEOFENCE_QUERY from "../../../graphql/queries/getGeofencesQuery";

import CREATE_ROAD_MUTATION from "../../../graphql/mutations/createRoadMutation";
import UPDATE_ROAD_MUTATION from "../../../graphql/mutations/updateRoadMutation";
import DELETE_ROAD_MUTATION from "../../../graphql/mutations/deleteRoadMutation";

import { useTranslation } from "../../../lang";

const Routes = ({ selectedCustomers, selectedGroups }) => {
  const [t] = useTranslation();
  const [geofenceList, setGeofenceList] = useState([]);
  const [assetList, setAssetList] = useState([]);
  const [roadList, setRoadList] = useState([]);

  const [resultState, setResultState] = useState({
    isOpen: false,
    type: "success",
    msg: "",
  });

  const [fetchGeofences] = useLazyQuery(GET_GEOFENCE_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      setGeofenceList(d.geofence.items);
    },
  });

  const {} = useQuery(ASSETS_BYGROUPS_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      groupIDs: selectedGroups.filter(
        (g) => g && !Number.isNaN(Number.parseInt(g))
      ),
      searchText: "",
      sort: undefined,
      paginate: false,
    },
    onCompleted(d) {
      const { items } = d.assetsByGroups;
      setAssetList(items);
    },
  });

  const [fetchRoads] = useLazyQuery(ROAD_LIST_SUMMARY_QUERY, {
    fetchPolicy: "network-only",
    onCompleted(d) {
      setRoadList(d.road);
    },
  });

  const handleFetchRoad = (customerId) => {
    fetchRoads({
      variables: {
        customerId: customerId,
      },
    });
  };

  useEffect(() => {
    let customerId;
    if (Array.isArray(selectedCustomers) && selectedCustomers.length > 0) {
      customerId = selectedCustomers[selectedCustomers.length - 1].value;
      fetchGeofences({
        variables: {
          customerId: customerId,
        },
      });
      handleFetchRoad(customerId);
    }
  }, [selectedCustomers]);

  const [createRoad] = useMutation(CREATE_ROAD_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Ruta creada correctamente"),
      });
      let customerId;
      if (Array.isArray(selectedCustomers) && selectedCustomers.length > 0) {
        customerId = selectedCustomers[selectedCustomers.length - 1].value;
        handleFetchRoad(customerId);
      }
    },
    onError(err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: t("Ocurrió un error"),
      });
    },
  });

  const handleCreateRoad = (values) => {
    delete values.id;
    createRoad({
      variables: values,
    });
  };

  const [updateRoad] = useMutation(UPDATE_ROAD_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Ruta actualizada correctamente"),
      });
      let customerId;
      if (Array.isArray(selectedCustomers) && selectedCustomers.length > 0) {
        customerId = selectedCustomers[selectedCustomers.length - 1].value;
        handleFetchRoad(customerId);
      }
    },
    onError(err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: t("Ocurrió un error"),
      });
    },
  });

  const handleUpdateRoad = (values) => {
    updateRoad({
      variables: values,
    });
  };

  const [deleteRoad] = useMutation(DELETE_ROAD_MUTATION, {
    onCompleted(d) {
      setResultState({
        isOpen: true,
        type: "success",
        msg: t("Ruta eliminada correctamente"),
      });
      let customerId;
      if (Array.isArray(selectedCustomers) && selectedCustomers.length > 0) {
        customerId = selectedCustomers[selectedCustomers.length - 1].value;
        handleFetchRoad(customerId);
      }
    },
    onError(err) {
      setResultState({
        isOpen: true,
        type: "error",
        msg: t("Ocurrió un error"),
      });
    },
  });

  const handleDeleteRoad = (id) => {
    deleteRoad({
      variables: {
        id: id,
      },
    });
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setResultState({
      ...resultState,
      isOpen: false,
    });
  };

  return (
    <Structure>
      <Snackbar
        open={resultState.isOpen}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleCloseNotification}
          severity={resultState.type}
          variant="filled"
        >
          {resultState.msg}
        </MuiAlert>
      </Snackbar>
      <PageTitle sector={t("Administración")} title={t("Rutas")} />
      <List
        roadList={roadList}
        assetList={assetList}
        geofenceList={geofenceList}
        createRoad={handleCreateRoad}
        updateRoad={handleUpdateRoad}
        deleteRoad={handleDeleteRoad}
      />
    </Structure>
  );
};

const _mapStateToProps = ({ groups }) => {
  return {
    ...groups,
  };
};

export default connect(_mapStateToProps, null)(Routes);
