import React from "react";

const SedanIcon = () => {
  return (
    <div style={{ justifyContent: "center" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="98.967"
        height="98.967"
        x="0"
        y="0"
        enableBackground="new 0 0 98.967 98.967"
        version="1.1"
        viewBox="0 0 98.967 98.967"
        xmlSpace="preserve"
      >
        <path d="M17.275 52.156a7.468 7.468 0 00-7.468 7.468c0 .318.026.631.066.938.463 3.681 3.596 6.528 7.401 6.528a7.467 7.467 0 007.437-6.83c.017-.209.031-.422.031-.637a7.466 7.466 0 00-7.467-7.467zm-3.738 4.654l1.522 1.523a2.578 2.578 0 00-.271.656h-2.146c.11-.812.421-1.554.895-2.179zm-.905 3.472h2.163c.061.23.151.448.271.648l-1.526 1.525a4.672 4.672 0 01-.908-2.173zm3.997 3.981a4.652 4.652 0 01-2.166-.899l1.518-1.519c.2.117.419.203.648.263v2.155zm0-7.123a2.594 2.594 0 00-.66.275l-1.521-1.521a4.64 4.64 0 012.181-.902v2.148zm1.293-2.15a4.648 4.648 0 012.181.903l-1.52 1.521a2.594 2.594 0 00-.66-.275l-.001-2.149zm0 9.271v-2.152a2.55 2.55 0 00.647-.264l1.519 1.519a4.654 4.654 0 01-2.166.897zm3.092-1.799l-1.531-1.533c.12-.201.217-.416.278-.646h2.146a4.659 4.659 0 01-.893 2.179zm-1.25-3.473a2.615 2.615 0 00-.271-.656l1.524-1.523c.471.625.782 1.367.894 2.18h-2.147zM79.284 52.156a7.468 7.468 0 00-7.468 7.468c0 .318.026.631.066.938.463 3.681 3.596 6.528 7.4 6.528 3.908 0 7.112-3.004 7.438-6.83.017-.209.031-.422.031-.637a7.465 7.465 0 00-7.467-7.467zm-3.738 4.654l1.521 1.523a2.578 2.578 0 00-.271.656H74.65a4.624 4.624 0 01.896-2.179zm-.904 3.472h2.163c.061.23.151.448.271.648l-1.525 1.525a4.633 4.633 0 01-.909-2.173zm3.996 3.981a4.652 4.652 0 01-2.166-.899l1.518-1.519c.2.117.419.203.648.263v2.155zm0-7.123a2.594 2.594 0 00-.66.275l-1.521-1.521a4.636 4.636 0 012.181-.902v2.148zm1.294-2.15a4.648 4.648 0 012.181.903l-1.521 1.521a2.524 2.524 0 00-.66-.275V54.99zm0 9.271v-2.152a2.55 2.55 0 00.647-.264l1.519 1.519a4.658 4.658 0 01-2.166.897zm3.091-1.799l-1.531-1.531c.12-.202.218-.416.278-.647h2.146a4.65 4.65 0 01-.893 2.178zm-1.25-3.473a2.578 2.578 0 00-.271-.656l1.523-1.523c.472.625.782 1.367.895 2.18h-2.147z"></path>
        <path d="M97.216 48.29v-5.526c0-.889-.646-1.642-1.524-1.779-2.107-.33-5.842-.953-7.52-1.47-2.406-.742-11.702-4.678-14.921-5.417-3.22-.739-17.738-4.685-31.643.135-2.353.815-12.938 5.875-19.162 8.506-1.833.04-19.976 3.822-20.942 6.414C.538 51.746.235 53.004.057 53.662c-.178.658 0 3.807 1.348 6 1.374.777 4.019 1.299 7.077 1.649-.035-.187-.073-.371-.097-.56a8.6 8.6 0 01-.078-1.125c0-4.945 4.022-8.969 8.968-8.969s8.968 4.023 8.968 8.969c0 .254-.017.506-.036.754a8.728 8.728 0 01-.292 1.613h.024l44.516-.896c-.02-.115-.046-.229-.061-.346a8.565 8.565 0 01-.078-1.125c0-4.945 4.022-8.968 8.968-8.968s8.969 4.022 8.969 8.968c0 .019-.002.035-.003.053l.19-.016 7.611-1.433s2.915-1.552 2.915-5.822c.001-2.848-1.75-4.118-1.75-4.118zm-44.159-5.239l-16.625.509c.306-2.491-1.169-3.05-1.169-3.05 6.609-5.999 19.929-6.202 19.929-6.202l-2.135 8.743zm18.658-.761l-15.15.509 1.373-8.49c7.83-.102 12.303 1.626 12.303 1.626l2.237 3.61-.763 2.745zm8.541-.052h-4.221l-4.22-5.795a79.895 79.895 0 017.209 3.287 2.354 2.354 0 011.232 2.508z"></path>
      </svg>
    </div>
  );
};

export default React.memo(SedanIcon);
