import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
const IniEndIcon = function (props) {
  return (
    <SvgIcon
      viewBox="0 0 17 50"
      width="17px"
      height="50px"
      className="journeyIniEndIcon"
    >
      <path
        fill="#ffffff"
        id="Trazado_526"
        data-name="Trazado 526"
        d="M415.354,3838c-4.541-6.583-5.384-7.258-5.384-9.677a6,6,0,0,1,12,0c0,2.419-.843,3.094-5.383,9.677a.75.75,0,0,1-1.233,0Zm.616-7.177a2.5,2.5,0,1,0-2.5-2.5A2.5,2.5,0,0,0,415.97,3830.828Zm.045-35.07a5.922,5.922,0,1,0,5.922,5.923A5.923,5.923,0,0,0,416.015,3795.758Zm0,8.423a2.5,2.5,0,1,1,2.5-2.5A2.5,2.5,0,0,1,416.015,3804.181Zm1.411,9.127v-2.7a.67.67,0,0,0-.67-.67h-1.483a.67.67,0,0,0-.67.67v2.7a.67.67,0,0,0,.67.67h1.483A.67.67,0,0,0,417.426,3813.308Zm0,6.187V3816.8a.67.67,0,0,0-.67-.671h-1.483a.67.67,0,0,0-.67.671v2.694a.67.67,0,0,0,.67.671h1.483A.67.67,0,0,0,417.426,3819.5Z"
        transform="translate(-409.97 -3795.758)"
      />
    </SvgIcon>
  );
};

export default IniEndIcon;
