import React from "react";

const StationIcon = () => {
  return (
    <div style={{ justifyContent: "center" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="99.488"
        height="99.488"
        x="0"
        y="0"
        enableBackground="new 0 0 99.488 99.488"
        version="1.1"
        viewBox="0 0 99.488 99.488"
        xmlSpace="preserve"
      >
        <path d="M98.587 52.231l-.68-.106c.244-9.324-1.22-11.202-2.858-16.912h.901a.773.773 0 00.773-.774v-.889a.774.774 0 00-.773-.773H41.123c-.805 0-1.594.216-2.286.625L22.22 43.218s-16.635.671-19.213 3.222C1.704 47.729.526 50.272.096 53.207c-.43 2.934.693 6.062 1.331 6.737.639.675 1.663.998 1.663.998l.406.771c.262.496.78.801 1.341.787l2.674-.064v-7.383a5.19 5.19 0 015.189-5.188h7.099a4.548 4.548 0 014.548 4.549v7.612l.121-.003 43.416-.667.625.063v-6.367a5.19 5.19 0 015.188-5.188h7.101a4.546 4.546 0 014.547 4.549v8.323l5.991-1.272c.315-.067.585-.276.729-.566l.438-.872 6.059-.793c.53-.069.927-.522.927-1.058v-4.893a1.07 1.07 0 00-.902-1.051zm-52.152-8.534a.705.705 0 01-.515.223H32.695s.812-.417 1.237-1.012c.426-.593.19-1.998-.288-2.472-.439-.434-1.322-.729-1.322-.729l6.651-4.095a2.695 2.695 0 011.417-.401h5.628a.701.701 0 01.702.712l-.097 7.244a.704.704 0 01-.188.53zm16.61-.588a2.406 2.406 0 01-1.803.873l-10.585.23a.706.706 0 01-.72-.703v-7.595c0-.388.314-.702.702-.702h11.748a.7.7 0 01.699.64l.474 5.323a2.407 2.407 0 01-.515 1.934zm31.313.006a.347.347 0 01-.297.169l-21.502.162a2.698 2.698 0 01-2.298-1.255l-2.254-6.031a.701.701 0 01.657-.948H91.41c.306 0 .574.196.669.486l2.288 7.074a.347.347 0 01-.009.343z"></path>
        <path d="M15.926 51.364a7.673 7.673 0 00-7.673 7.672c0 .329.027.647.067.966a7.67 7.67 0 0015.249-.311 7.52 7.52 0 00.033-.655 7.671 7.671 0 00-7.676-7.672zm-3.843 4.782l1.568 1.563a2.559 2.559 0 00-.279.678h-2.207c.114-.838.434-1.6.918-2.241zm-.926 3.569h2.224c.063.234.154.459.279.666l-1.571 1.567a4.804 4.804 0 01-.932-2.233zm4.105 4.088a4.729 4.729 0 01-2.225-.92l1.561-1.562c.203.12.43.207.664.27v2.212zm0-7.318a2.708 2.708 0 00-.678.282l-1.561-1.563a4.76 4.76 0 012.238-.929l.001 2.21zm1.328-2.209c.84.116 1.602.438 2.244.931l-1.563 1.561a2.58 2.58 0 00-.682-.281l.001-2.211zm0 9.527V61.59a2.61 2.61 0 00.669-.271l1.559 1.564a4.749 4.749 0 01-2.228.92zm3.181-1.851l-1.572-1.573c.12-.205.222-.426.284-.664h2.204a4.742 4.742 0 01-.916 2.237zm-1.288-3.565a2.631 2.631 0 00-.275-.678l1.564-1.563a4.76 4.76 0 01.92 2.241h-2.209zM76.924 51.364a7.674 7.674 0 00-7.675 7.672c0 .329.026.647.068.966.476 3.782 3.696 6.709 7.605 6.709a7.67 7.67 0 007.643-7.02c.02-.219.033-.434.033-.655a7.668 7.668 0 00-7.674-7.672zm-3.843 4.782l1.566 1.563c-.123.213-.22.438-.278.678h-2.206c.113-.838.433-1.6.918-2.241zm-.927 3.569h2.224c.063.234.153.459.278.666l-1.569 1.567a4.795 4.795 0 01-.933-2.233zm4.105 4.088a4.728 4.728 0 01-2.226-.92l1.562-1.562c.203.12.431.207.664.27v2.212zm0-7.318a2.746 2.746 0 00-.679.282l-1.561-1.563a4.752 4.752 0 012.238-.929l.002 2.21zm1.33-2.209a4.719 4.719 0 012.243.931l-1.563 1.561a2.587 2.587 0 00-.681-.281l.001-2.211zm0 9.527V61.59c.235-.062.461-.149.668-.271l1.559 1.564a4.754 4.754 0 01-2.227.92zm3.18-1.851l-1.573-1.573c.12-.205.223-.426.285-.664h2.204a4.752 4.752 0 01-.916 2.237zm-1.288-3.565a2.739 2.739 0 00-.274-.678l1.563-1.563a4.78 4.78 0 01.92 2.241h-2.209z"></path>
      </svg>
    </div>
  );
};

export default React.memo(StationIcon);
