import { combineReducers } from "redux";

import { UPDATE_MARKER_JOURNEY } from "./actions";

const markerJourney = (markerJourney = {}, { type, payload }) => {
  let newMarkerJourney = markerJourney;
  if (type === UPDATE_MARKER_JOURNEY) {
    newMarkerJourney = payload;
  }

  return newMarkerJourney;
};

export default combineReducers({
  markerJourney,
});
