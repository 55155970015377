import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { DatePicker } from "antd";
import "antd/dist/antd.css";
import { format } from "date-fns";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { useTranslation } from "../../lang";
import {
  GetDateFormat,
  GetDateFormatDateSelector,
} from "../../utils/generalUtils";
import CustomSelectStyles from "../../utils/reactSelectStyle";
import "./DateSelector.scss";

const theme = createMuiTheme({});

const { RangePicker } = DatePicker;

export const useDateSelectorOptions = () => {
  const [t] = useTranslation();
  const options = [
    {
      label: t("Última hora"),
      value: 1,
    },
    {
      label: t("Última 6 horas"),
      value: 2,
    },
    {
      label: t("Ayer"),
      value: 3,
    },
    {
      label: t("Hoy"),
      value: 4,
    },
    {
      label: t("Semana pasada"),
      value: 5,
    },
    {
      label: t("Mes pasado"),
      value: 6,
    },
    {
      label: t("Personalizado"),
      value: 7,
    },
  ];

  return [options];
};

const DateSelector = ({ onChange, user, selectedOption }) => {
  const [t] = useTranslation();
  const [options] = useDateSelectorOptions();

  const [dias, setDias] = useState([1, 1, 1, 1, 1, 1, 1]);
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => setIsOpen(false);
  const [dateDesc, setDateDesc] = useState("");
  const [dateEndDesc, setDateEndDesc] = useState("");
  const [daysDesc, setDaysDesc] = useState("");
  const [fechaDesde, setFechaDesde] = useState();
  const [fechaHasta, setFechaHasta] = useState();

  useEffect(() => {
    onChange({
      fechaDesde,
      fechaHasta,
      dias: convertDays(),
      selectedOption,
    });
  }, [fechaDesde, fechaHasta, dias, selectedOption]);

  const dateFormatDateSelector = GetDateFormatDateSelector(
    user.language,
    false
  );
  const dateFormat = GetDateFormat(user.language, false);

  const applyFormat = (date) => {
    return format(date, "yyyy-MM-dd HH:mm:ss");
  };

  const convertDays = () => {
    const dayNames = [];
    dias[0] === 1 && dayNames.push("LUNES");
    dias[1] === 1 && dayNames.push("MARTES");
    dias[2] === 1 && dayNames.push("MIERCOLES");
    dias[3] === 1 && dayNames.push("JUEVES");
    dias[4] === 1 && dayNames.push("VIERNES");
    dias[5] === 1 && dayNames.push("SABADO");
    dias[6] === 1 && dayNames.push("DOMINGO");

    return dayNames;
  };

  const setDaysMessages = (sD, eD) => {
    setDateDesc(`${t("Desde")}: ${sD}`);
    setDateEndDesc(`${t("Hasta")}: ${eD}`);
    let daysNames = [];
    dias.forEach((d, index) => {
      switch (index) {
        case 0:
          d === 1 && daysNames.push(t("Lunes"));
          break;
        case 1:
          d === 1 && daysNames.push(t("Martes"));
          break;
        case 2:
          d === 1 && daysNames.push(t("Miercoles"));
          break;
        case 3:
          d === 1 && daysNames.push(t("Jueves"));
          break;
        case 4:
          d === 1 && daysNames.push(t("Viernes"));
          break;
        case 5:
          d === 1 && daysNames.push(t("Sabado"));
          break;
        case 6:
          d === 1 && daysNames.push(t("Domingo"));
          break;
        default:
          break;
      }
    });
    setDaysDesc(
      `${t("dias")}: ${!dias.includes(0) ? t("Todos") : daysNames.join(",")}`
    );
  };

  const calculatePeriod = (param) => {
    let eD;
    let sD;
    switch (param) {
      case 1: // Ultima hora
        const ONE_HOUR = 60 * 60 * 1000;
        eD = applyFormat(new Date());
        const sDResta = new Date(new Date() - ONE_HOUR);
        sD = applyFormat(sDResta);
        break;
      case 2: // Ultima 6 horas
        const SIX_HOUR = 360 * 60 * 1000;
        eD = applyFormat(new Date());
        const sixHoursAgo = new Date(new Date() - SIX_HOUR);
        sD = applyFormat(sixHoursAgo);
        break;
      case 3: // Ayer
        const yesterday2 = new Date();
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        yesterday.setHours(0);
        yesterday.setMinutes(0);
        yesterday.setSeconds(0);

        yesterday2.setDate(yesterday2.getDate() - 1);
        yesterday2.setHours(23);
        yesterday2.setMinutes(59);
        yesterday2.setSeconds(59);

        sD = applyFormat(yesterday);
        eD = applyFormat(yesterday2);
        break;
      case 4: // Hoy
        const today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);

        sD = applyFormat(today);
        eD = applyFormat(new Date());
        break;
      case 5: // Ultima Semana
        let hoy = new Date();
        let lunes = new Date(
          hoy.getFullYear(),
          hoy.getMonth(),
          hoy.getDate() - hoy.getDay() + 1
        );
        let lunesPasado = new Date(
          lunes.getFullYear(),
          lunes.getMonth(),
          lunes.getDate() - 7
        );
        let domingoPasado = new Date(
          hoy.getFullYear(),
          hoy.getMonth(),
          hoy.getDate() - hoy.getDay(),
          23,
          59,
          59
        );
        sD = applyFormat(lunesPasado);
        eD = applyFormat(domingoPasado);
        break;
      case 6: // Ultimo Mes
        let nuevaFecha = new Date();
        nuevaFecha = new Date(
          nuevaFecha.getFullYear(),
          nuevaFecha.getMonth(),
          0
        );
        sD = applyFormat(
          new Date(nuevaFecha.getFullYear(), nuevaFecha.getMonth(), 1, 0, 0, 0)
        );
        eD = applyFormat(
          new Date(
            nuevaFecha.getFullYear(),
            nuevaFecha.getMonth() + 1,
            0,
            23,
            59,
            59
          )
        );
        break;
      default:
        break;
    }
    setFechaDesde(sD);
    setFechaHasta(eD);
    setDaysMessages(sD, eD);
  };

  React.useEffect(() => {
    if (selectedOption) {
      calculatePeriod(selectedOption.value);
    }
  }, []);

  const onOK = (date) => {
    if (
      typeof date != "undefined" &&
      typeof date[0] != "undefined" &&
      typeof date[1] != "undefined" &&
      date[0] != null &&
      date[1] != null
    ) {
      setFechaDesde(format(new Date(date[0]._d), dateFormat));
      setFechaHasta(format(new Date(date[1]._d), dateFormat));
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog open={isOpen} onClose={closeModal} maxWidth="sm" fullWidth>
        <DialogTitle className="dialogTitle">
          {t("Fechas personalizadas")}
        </DialogTitle>
        <DialogContent>
          <RangePicker
            onOk={onOK}
            showTime={{
              hideDisabledOptions: true,
              defaultValue: [
                moment("00:00:00", "HH:mm:ss"),
                moment("23:59:59", "HH:mm:ss"),
              ],
            }}
            format={dateFormatDateSelector}
          />
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={dias[0] === 1}
                  onChange={(evt) =>
                    setDias([evt.target.checked ? 1 : 0, ...dias.slice(1)])
                  }
                  color="primary"
                />
              }
              label={t("Lunes")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={dias[1] === 1}
                  onChange={(evt) =>
                    setDias([
                      dias[0],
                      evt.target.checked ? 1 : 0,
                      ...dias.slice(2),
                    ])
                  }
                  color="primary"
                />
              }
              label={t("Martes")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={dias[2] === 1}
                  onChange={(evt) =>
                    setDias([
                      ...dias.slice(0, 2),
                      evt.target.checked ? 1 : 0,
                      ...dias.slice(3),
                    ])
                  }
                  color="primary"
                />
              }
              label={t("Miercoles")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={dias[3] === 1}
                  onChange={(evt) =>
                    setDias([
                      ...dias.slice(0, 3),
                      evt.target.checked ? 1 : 0,
                      ...dias.slice(4),
                    ])
                  }
                  color="primary"
                />
              }
              label={t("Jueves")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={dias[4] === 1}
                  onChange={(evt) =>
                    setDias([
                      ...dias.slice(0, 4),
                      evt.target.checked ? 1 : 0,
                      ...dias.slice(5),
                    ])
                  }
                  color="primary"
                />
              }
              label={t("Viernes")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={dias[5] === 1}
                  onChange={(evt) =>
                    setDias([
                      ...dias.slice(0, 5),
                      evt.target.checked ? 1 : 0,
                      ...dias.slice(6),
                    ])
                  }
                  name="checkedB"
                  color="primary"
                />
              }
              label={t("Sabado")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={dias[6] === 1}
                  onChange={(evt) =>
                    setDias([...dias.slice(0, 6), evt.target.checked ? 1 : 0])
                  }
                  color="primary"
                />
              }
              label={t("Domingo")}
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => closeModal()}
          >
            {t("Cancelar")}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setDaysMessages(fechaDesde, fechaHasta);
              onChange({
                fechaDesde,
                fechaHasta,
                dias: convertDays(),
                selectedOption,
              });
              closeModal();
            }}
          >
            {t("Confirmar")}
          </Button>
        </DialogActions>
      </Dialog>
      <Select
        placeholder={t("Seleccionar rango de busqueda")}
        options={options}
        value={selectedOption}
        onChange={(opt) => {
          onChange({
            fechaDesde,
            fechaHasta,
            dias: convertDays(),
            selectedOption: opt,
          });
          if (opt.value === 7) {
            setIsOpen(true);
          } else {
            calculatePeriod(opt.value);
          }
        }}
        styles={CustomSelectStyles}
      />
      {selectedOption === 7 && (
        <>
          <label className="descDates">{dateDesc}</label>
          <br />
          <label className="descDates">{dateEndDesc}</label>
          <br />
          <label className="descDates">{daysDesc}</label>
        </>
      )}
    </ThemeProvider>
  );
};

const _mapStateToProps = ({ user }) => {
  return {
    user,
  };
};

const _mapDispatchToProps = (dispatch) => {
  return {
    // _setCurrentUser: (param) => dispatch(setCurrentUser(param)),
  };
};

export default connect(_mapStateToProps, _mapDispatchToProps)(DateSelector);
