import gql from "graphql-tag";

const GET_MARKERS_SUBSCRIPTION_BY_ASSETID = gql`
  subscription($assetId: Int) {
    newPointByAssetId(assetId: $assetId) {
      eid
      id
      pat
      nMov
      nCli
      nCh
      lat
      lon
      vel
      cur
      fGPS
      tIgn
      tDet
      typ
      dir
      mid
      asset {
        itSupportsOBD2
      }
    }
  }
`;

export default GET_MARKERS_SUBSCRIPTION_BY_ASSETID;
